import React from "react";
import Moment from "moment";

// import Footer from "../common/footer";
import TopBar from "../common/topBar";
import edit from "../../include/images/edit.svg";
import deleteIcon from "../../include/images/delete1.svg";

import ModalPopup from "../common/modal";

import {
  loadCoupons,
  getCoupon,
  loadCategory,
  loadAllCoupons,
  deletedCoupon,
} from "../../store/coupons";
import { connect } from "react-redux";
import Pagination from "../common/Pagination";

import AddCoupon from "./addCoupon";
import CouponSearch from "./couponSearch";

import image1 from "../../include/images/no-result-img.svg";
import { couponDeleted, failed } from "../common/misc";

import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";

class Coupons extends React.Component {
  ref = React.createRef(null);

  state = {
    settingDropdown: false,
    profileDropdown: false,
    editShow: false,
    isShow: false,
    data: "",
    totalRecords: "",
    totalPages: "",
    pageLimit: "",
    currentPage: "1",
    modal: false,
    delete_id: "",
  };

  componentDidMount = () => {
    this.ref.current.continuousStart();
    // this.props.loadCoupons();
    this.props.loadCategory();
    this.props.loadCoupons({ page: this.state.currentPage });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.couponLoading &&
      this.props.couponLoading !== prevProps.couponLoading
    ) {
      this.ref.current.complete();
    }
  };

  deletedCoupon = (id) => {
    this.ref.current.continuousStart();
    this.props.deletedCoupon(id, this.callback);
  };

  callback = (res) => {
    this.ref.current.complete();

    if (res && res.status === 200) {
      this.props.loadCoupons({ page: this.state.currentPage });
      this.setState({
        modal: !this.state.modal,
      });
      toast(<AlertSuccess message={couponDeleted} />);
    } else {
      toast(<AlertError message={failed} />);
      this.setState({
        modal: !this.state.modal,
      });
    }
  };

  toggleEdit = (data) => {
    this.setState({
      editShow: !this.state.editShow,
      data: data,
      isShow: false,
    });
  };

  toggleAdd = () => {
    this.setState({
      isShow: !this.state.isShow,
      editShow: false,
      data: "",
    });
  };

  toggle = (id) => {
    this.setState({
      modal: !this.state.modal,
      delete_id: id,
    });
  };

  onChangePage = (data) => {
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
    });
    if (data.page !== this.state.currentPage) {
      this.props.loadCoupons({ page: data.page });
    }
  };

  handleSettingDropdown = () => {
    if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
      this.setState({ settingDropdown: !this.state.settingDropdown });
    } else {
      this.setState({ settingDropdown: !this.state.settingDropdown });
    }
  };

  handleProfileDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
      this.setState({ profileDropdown: !this.state.profileDropdown });
    } else {
      this.setState({ profileDropdown: !this.state.profileDropdown });
    }
  };

  setSettingDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
    } else if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
    }
  };

  render() {
    const {
      record_per_page,
      total_page,
      current_page,
      filtered_record,
      total_record,
      total_record_on_current_page,
    } = this.props.pagination;

    return (
      <>
        <div
          onClick={this.setSettingDropdown}
          className={
            this.state.editShow === true || this.state.isShow === true
              ? "toggled-tab"
              : ""
          }
        >
          <TopBar
            handleProfileDropdown={this.handleProfileDropdown}
            handleSettingDropdown={this.handleSettingDropdown}
            settingDropdown={this.state.settingDropdown}
            profileDropdown={this.state.profileDropdown}
            loaDerRef={this.ref}
          />
          {!this.props.couponLoading && (
            <>
              {" "}
              <main id="main">
                {this.props.coupons.length === 0 ? (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="no-result-content">
                          <div className="no-result-box d-inline-block">
                            <div className="no-result-img">
                              <img src={image1} alt="" />
                            </div>
                            <div className="no-result-text">
                              <h2>Welcome Aboard!</h2>
                              <p>
                                You have not added any coupon yet. Let's get
                                started.
                              </p>
                              <p style={{ marginTop: "15px" }}>
                                <a
                                  href="javascript:void(0)"
                                  className="btn btn-default btn-xs"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.toggleAdd();
                                  }}
                                >
                                  + Add Coupon
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <CouponSearch
                      toggleAdd={this.toggleAdd}
                      record_per_page={record_per_page}
                      filtered_record={filtered_record}
                      total_record_on_current_page={
                        total_record_on_current_page
                      }
                      total_record={total_record}
                      currentPage={current_page}
                    />
                    {filtered_record === 0 && total_record > 0 ? (
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="no-result-content">
                              <div className="no-result-box d-inline-block">
                                <div className="no-result-img">
                                  <img src={image1} alt="" />
                                </div>
                                <div className="no-result-text">
                                  <h2>No results found </h2>

                                  <p>
                                    There are no results matching your search
                                    criteria. Please modify your search
                                    parameters and try again.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="product-list-item-container ">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="product-list-item-content">
                                <div className="table-responsive">
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th style={{ width: "150px" }}>
                                          Coupon Code
                                        </th>
                                        {/* <th></th> */}
                                        <th style={{ width: "115px" }}>
                                          Start Date
                                        </th>
                                        <th style={{ width: "115px" }}>
                                          End Date
                                        </th>
                                        <th
                                          style={{
                                            width: "150px",
                                            textAlign: "left",
                                          }}
                                        >
                                          Discount
                                        </th>
                                        <th
                                          style={{
                                            width: "115px",
                                            textAlign: "left",
                                          }}
                                        >
                                          Categories
                                        </th>
                                        <th
                                          style={{
                                            width: "125px",
                                            textAlign: "left",
                                          }}
                                        >
                                          Max Quantities
                                        </th>
                                        <th
                                          style={{
                                            width: "125px",
                                            textAlign: "left",
                                          }}
                                        >
                                          Used Quantities
                                        </th>
                                        <th
                                          style={{
                                            width: "125px",
                                            textAlign: "left",
                                          }}
                                        >
                                          Per Customer
                                        </th>
                                        <th style={{ width: "105px" }}>
                                          Actions
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.props.coupons.length === 0
                                        ? "No Coupons Found"
                                        : this.props.coupons.map((e, i) => (
                                            <tr key={i}>
                                              <td>
                                                <b>{e.code && e.code}</b>
                                              </td>
                                              <td>
                                                <b>
                                                  {" "}
                                                  {Moment(e.startDate).format(
                                                    "LL"
                                                  )}
                                                </b>
                                              </td>
                                              <td>
                                                <b>
                                                  {" "}
                                                  {Moment(e.endDate).format(
                                                    "LL"
                                                  )}
                                                </b>
                                              </td>
                                              <td style={{ textAlign: "left" }}>
                                                <b>
                                                  {e.discountType ===
                                                  "fixAmount"
                                                    ? "$" + e.discount &&
                                                      e.discount
                                                    : e.discount &&
                                                      e.discount + "%"}
                                                </b>
                                                <div className="code">
                                                  Minimum Amount :{" "}
                                                  {e.minOrderAmount &&
                                                    e.minOrderAmount}
                                                </div>
                                              </td>
                                              <td style={{ textAlign: "left" }}>
                                                <b>
                                                  {e.category_title &&
                                                    e.category_title}
                                                </b>
                                              </td>

                                              <td style={{ textAlign: "left" }}>
                                                <b>
                                                  {e.maxRedeemCount &&
                                                    e.maxRedeemCount}
                                                </b>
                                                <div className="code">
                                                  {e.maxRedeemCount &&
                                                    e.maxRedeemCount -
                                                      e.redeemCount}{" "}
                                                  Remaining Quantities
                                                </div>
                                              </td>

                                              <td style={{ textAlign: "left" }}>
                                                <b>{e.redeemCount}</b>
                                                <div className="code">
                                                  Used Quantities
                                                </div>
                                              </td>
                                              <td style={{ textAlign: "left" }}>
                                                <b>
                                                  {e.maxRedeemCountPerCustomer &&
                                                    e.maxRedeemCountPerCustomer}
                                                </b>
                                              </td>
                                              <td className="editable">
                                                <a
                                                  href="javascript:void(0)"
                                                  className="physicalVisit"
                                                  onClick={() =>
                                                    this.toggleEdit(e)
                                                  }
                                                >
                                                  <img src={edit} alt="edit" />
                                                </a>
                                                <a
                                                  href="javascript:void(0)"
                                                  className="delete"
                                                  onClick={() =>
                                                    this.toggle(e._id)
                                                  }
                                                >
                                                  <img
                                                    src={deleteIcon}
                                                    alt="delete"
                                                  />
                                                </a>
                                              </td>
                                            </tr>
                                          ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <Pagination
                                totalRecords={filtered_record}
                                total_page={total_page}
                                pageLimit={record_per_page}
                                initialPage={current_page}
                                pagesToShow={5}
                                onChangePage={this.onChangePage}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                <AddCoupon
                  toggleEdit={this.toggleEdit}
                  toggleAdd={this.toggleAdd}
                  editShow={this.state.editShow}
                  isShow={this.state.isShow}
                  data={this.state.data}
                />

                {/* <Footer /> */}
              </main>
            </>
          )}
        </div>

        <ModalPopup
          show={this.state.modal}
          onHide={this.toggle}
          delete_id={this.state.delete_id}
          deleted={this.deletedCoupon}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  coupons: getCoupon(state).coupons.data,
  category: getCoupon(state).category,
  pagination: getCoupon(state).coupons.pagination,
  couponLoading: getCoupon(state).couponsLoading,
});

const mapDispatchToProps = (dispatch) => ({
  loadCoupons: (params) => dispatch(loadCoupons(params)),
  loadCategory: () => dispatch(loadCategory()),
  // loadAllCoupons: (params) => dispatch(loadAllCoupons(params)),
  deletedCoupon: (id, callback) => dispatch(deletedCoupon(id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Coupons);
