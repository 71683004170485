import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import { Link } from "react-router-dom";
import {
  getCart,
  loadCart,
  prepareOrderForPayment,
  placeOrder,
} from "../../store/cart";
import Header from "../product/header";
import OrderSuccess from "./orderSuccess";
import _ from "lodash";
import { toast } from "react-toastify";
import AlertSuccess from "../common/alertSuccess";
import AlertError from "../common/alertError";
import loadingIcon from "../../include/images/loading-icon.svg";
import { getCustomer, loadCustomerProfile } from "../../store/customer";
import { getCustomerToken } from "../services/localStorageServices";
import Footer from "../common/footer";
import Truncate from "react-truncate";

class Review extends Component {
  ref = createRef(null);
  stripe = null;

  state = {
    payNow: true,
    newCard: false,
    oldCard: true,
    cardElement: {},
    model: false,
    paymentMethod: {},
    customer_information: JSON.parse(
      localStorage.getItem("customer_information")
    ),
    customer_address: JSON.parse(localStorage.getItem("customer_address")),
    deliveryAddress:
      localStorage.getItem("deliveryAddress") === "true" ? true : false,
  };

  modelToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ model: !this.state.model });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.profileLoading &&
      this.props.profileLoading !== prevProps.profileLoading &&
      this.props.customerProfile.cards.length === 0
    ) {
      if (
        this.props.getCart.cartDetail.sellers[
          this.props.history.location.state.seller_no
        ].seller_stripe_account_id
      )
        this.renderCrdElement();
    }

    if (
      this.props.getCart.cartDetail.sellers &&
      this.props.getCart.cartDetail.sellers.length === 0
    ) {
      this.props.history.push("/cart");
    } else {
      if (
        this.state.payNow &&
        !this.props.getCart.cartDetail.sellers[
          this.props.history.location.state.seller_no
        ].seller_stripe_account_id
      )
        this.setState({ payNow: false });
    }
  };

  renderCrdElement = () => {
    var elements = this.stripe.elements();
    var style = {
      base: {
        lineHeight: "1.429",
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "##6c757d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };
    var cardElement = elements.create("card", {
      hidePostalCode: true,
      style: style,
    });
    cardElement.mount("#card-element");
    this.setState({ cardElement });
  };

  doSubmit = async (e) => {
    e.preventDefault();
    this.ref.current.continuousStart();
    this.setState({ loader: true });
    const { data } = this.state;
    if (this.state.payNow) {
      if (
        !this.state.newCard &&
        this.props.customerProfile.cards &&
        this.props.customerProfile.cards.length > 0
      )
        return this.stripePaymentMethodHandler({
          paymentMethod: this.props.customerProfile.cards[0],
        });

      const result = await this.stripe.createPaymentMethod({
        type: "card",
        card: this.state.cardElement,
        billing_details: {
          name: data.first_name + " " + data.last_name,
        },
      });

      this.stripePaymentMethodHandler(result);
    } else {
      this.stripePaymentMethodHandler({ error: false });
    }
  };
  stripePaymentMethodHandler = async (result) => {
    if (result.error) {
      toast(<AlertError message={result.error.message} />);
      this.ref.current.complete();
      this.setState({ loader: false });
    } else {
      this.setState(
        { paymentMethod: result.paymentMethod },
        this.prepareOrderForPayment
      );
    }
  };

  prepareOrderForPayment = (e) => {
    const deliveryAddress = JSON.parse(localStorage.getItem("customerDddress"));
    const deliveryAddressArr = deliveryAddress.address.split(",");
    if (e) e.preventDefault();
    const { first_name, last_name, email, mobile, lattitude, longitude } =
      this.state.customer_information;
    const { address1, address2, zip, state, city } =
      this.state.customer_address;
    const pLoad = {
      user_device_id: localStorage.getItem("user_device_id"),

      seller_id:
        this.props.getCart.cartDetail.sellers[
          this.props.history.location.state.seller_no
        ].seller_id,
      customer_information: {
        first_name,
        last_name,
        email,
        mobile,
        billing_address: {
          address_line1: address1,
          address_line2: address2,
          zipcode: zip,
          city,
          state,
        },
        shipping_address: {
          address_line1: deliveryAddressArr[0],

          address_line2:
            deliveryAddressArr.lrngth > 4 ? deliveryAddressArr[1] : "",
          zipcode: deliveryAddress.postal_code.long_name,
          city: deliveryAddressArr[deliveryAddressArr.length - 3],
          state: deliveryAddressArr[deliveryAddressArr.length - 2],
          lat: deliveryAddress.lat ? deliveryAddress.lat : lattitude,
          lng: deliveryAddress.lng ? deliveryAddress.lng : longitude,
        },
      },
    };
    if (!this.state.payNow) pLoad.pay_later = true;
    if (this.state.payNow)
      pLoad.payment_method_id = this.state.paymentMethod.id;

    this.props.prepareOrderForPayment(pLoad, this.callBack);
  };

  callBack = async (res) => {
    if (res.status === 200) {
      const {
        first_name,
        last_name,
        email,
        mobile,

        delivery_address1,
        delivery_address2,
        delivery_city,
        delivery_state,
        delivery_zipcode,
      } = this.state.customer_information;
      const { address1, address2, zip, state, city, lattitude, longitude } =
        this.state.customer_address;
      const deliveryAddress = JSON.parse(
        localStorage.getItem("customerDddress")
      );
      if (res.data.requires_action) {
        this.stripe
          .confirmCardPayment(res.data.client_secret, {
            payment_method: !res.data.payment_method_id
              ? { card: this.state.cardElement }
              : res.data.payment_method_id,
          })
          .then((result) => {
            if (result.error) {
              this.ref.current.complete();
              this.setState({ loader: false });
              return toast(<AlertError message={result.error.message} />);
            }
            const pLoad = {
              user_device_id: localStorage.getItem("user_device_id"),
              payment_intent_id: res.data.intent_id,
              seller_id: res.data.seller_id,
              customer_information: {
                first_name,
                last_name,
                email,
                mobile,
                billing_address: {
                  address_line1: address1,
                  address_line2: address2,
                  zipcode: zip,
                  city,
                  state,
                },
                shipping_address: {
                  address_line1: this.state.deliveryAddress
                    ? delivery_address1
                    : address1,
                  address_line2: this.state.deliveryAddress
                    ? delivery_address2
                    : address2,
                  zipcode: this.state.deliveryAddress ? delivery_zipcode : zip,
                  city: this.state.deliveryAddress ? delivery_city : city,
                  state: this.state.deliveryAddress ? delivery_state : state,
                  lat: deliveryAddress.lat,
                  lng: deliveryAddress.lng,
                },
              },
            };
            this.props.prepareOrderForPayment(pLoad, this.callBack);
          });
      } else {
        this.props.history.push({
          pathname: "/order-success",
          state: { order_id: res.data.order.order_id },
        });
      }
    } else {
      this.setState({ loader: false });
      toast(<AlertError message={res.data.error} />);
      this.ref.current.complete();
      // this.props.history.push({
      //   pathname: "/order-success",
      //   state: { order_id: res.data.order.order_id },
      // });
    }
  };

  callBackPlaceOrder = async (res) => {
    this.ref.current.complete();
    this.setState({ loader: false });
    if (res.status === 200) {
      this.props.history.push("/order-success");
    } else {
      toast(<AlertError message={res.data.error} />);
      this.props.history.push("/order-failed");
    }
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);
    if (getCustomerToken("auth")) this.props.loadCustomerProfile();
    if (
      !this.props.history.location.state ||
      !this.props.getCart.cartDetail.sellers
    )
      return this.props.history.push("/cart");

    this.stripe = window.Stripe(process.env.REACT_APP_STRIPEKEY, {
      stripeAccount: this.props.getCart.cartDetail.sellers[
        this.props.history.location.state.seller_no
      ].seller_stripe_account_id
        ? this.props.getCart.cartDetail.sellers[
            this.props.history.location.state.seller_no
          ].seller_stripe_account_id
        : "",
    });

    if (!getCustomerToken("auth")) {
      var elements = this.stripe.elements();
      var style = {
        base: {
          lineHeight: "1.429",

          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "##6c757d",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      };
      var cardElement = elements.create("card", {
        hidePostalCode: true,
        style: style,
      });
      cardElement.mount("#card-element");
      this.setState({ cardElement });
    }
    const data = localStorage.getItem("customer_information");
    if (data) this.setState({ data: JSON.parse(data) });
  };
  toggleNewCard = (e, newCard) => {
    e.preventDefault();
    if (newCard === this.state.newCard) return false;
    this.setState({ newCard, oldCard: !newCard }, () => {
      if (this.state.newCard) {
        var elements = this.stripe.elements();
        var style = {
          base: {
            lineHeight: "1.429",

            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "##6c757d",
            },
          },
          invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
          },
        };
        var cardElement = elements.create("card", {
          hidePostalCode: true,
          style: style,
        });
        cardElement.mount("#card-element");
        this.setState({ cardElement });
      }
    });
  };
  render() {
    const deliveryAddress = JSON.parse(localStorage.getItem("customerDddress"));
    const customer_address = JSON.parse(
      localStorage.getItem("customer_address")
    );
    const deliveryAddressArr = deliveryAddress.address.split(",");
    const { customer_information } = this.state;
    // console.log(
    //   "ddddd",
    //   this.props.getCart.cartDetail.sellers[
    //     this.props.history.location.state.seller_no
    //   ].pay_later
    // );
    return (
      <>
        <LoadingBar color="#0b4474" ref={this.ref} />
        <Header cart={"review"} toggleContact={this.toggleContact} />
        <main id="main">
          <div className="content-container mob-no-border mob-pb-0 mob-pt-0">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="cc-box d-lg-flex d-lg-wrap">
                    <div className="cc-left-content">
                      <div className="table-responsive table_wrap">
                        <table className="table2 table-wrap">
                          <thead>
                            <tr>
                              <th>Products</th>
                              <th></th>
                              <th
                                style={{ width: "180px", textAlign: "right" }}
                              >
                                Quantity
                              </th>
                              <th
                                style={{ width: "120px", textAlign: "right" }}
                              >
                                Unit Price
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.getCart.cartDetail.sellers &&
                              this.props.history.location.state &&
                              this.props.getCart.cartDetail.sellers.length >
                                0 && (
                                <>
                                  <tr>
                                    <td colspan="5" className="table-heading">
                                      {
                                        this.props.getCart.cartDetail.sellers[
                                          this.props.history.location.state
                                            .seller_no
                                        ].seller_company
                                      }
                                    </td>
                                  </tr>
                                  {this.props.getCart.cartDetail.sellers[
                                    this.props.history.location.state.seller_no
                                  ].products.map((ep) => (
                                    <tr>
                                      <td className="td-image text-md-center">
                                        <span>
                                          <img
                                            src={
                                              process.env.REACT_APP_S3URL +
                                              ep.image[0].small
                                            }
                                            alt=""
                                          />
                                        </span>
                                      </td>
                                      <td>
                                        <b>
                                          <Link
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={
                                              "/product-detail/" + ep.product_id
                                            }
                                          >
                                            <Truncate lines={1} width={300}>
                                              {ep.product_name}
                                            </Truncate>
                                          </Link>
                                        </b>
                                        <div className="table-dropdown btn-group dropdown">
                                          {_.capitalize(ep.delivery_type)}
                                          {ep.delivery_type === "delivery" &&
                                            ", " +
                                              _.capitalize(
                                                _.replace(
                                                  ep.delivery_option,
                                                  RegExp("_", "g"),
                                                  " "
                                                )
                                              )}
                                        </div>
                                      </td>
                                      <td style={{ textAlign: "right" }}>
                                        {ep.quantity}
                                      </td>
                                      <td style={{ textAlign: "right" }}>
                                        <b>
                                          $
                                          {ep.price.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                          })}
                                        </b>
                                      </td>
                                    </tr>
                                  ))}

                                  <tr>
                                    <td colspan="2">
                                      <b>Total</b>
                                    </td>
                                    <td
                                      colspan="2"
                                      style={{ textAlign: "right" }}
                                    >
                                      <div>
                                        <b>
                                          $
                                          {this.props.getCart.cartDetail.sellers[
                                            this.props.history.location.state
                                              .seller_no
                                          ].summary.subtotal.toLocaleString(
                                            undefined,
                                            { minimumFractionDigits: 2 }
                                          )}
                                        </b>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td colspan="5">
                                      <div className="table-add-dropdown d-flex align-items-center justify-content-between">
                                        <div className="table-notes-text">
                                          <b>Note : </b>
                                          {this.props.getCart.cartDetail
                                            .sellers &&
                                            this.props.getCart.cartDetail
                                              .sellers.length > 0 &&
                                            this.props.getCart.cartDetail
                                              .sellers[
                                              this.props.history.location.state
                                                .seller_no
                                            ].note}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="cc-right-content">
                      <h3>Payment Information</h3>
                      <div className="d-flex flex-wrap">
                        {this.props.getCart.cartDetail.sellers &&
                          this.props.getCart.cartDetail.sellers[
                            this.props.history.location.state.seller_no
                          ] &&
                          this.props.getCart.cartDetail.sellers[
                            this.props.history.location.state.seller_no
                          ].pay_later &&
                          localStorage.getItem("x-refresh-token-customer") &&
                          this.props.getCart.cartDetail.sellers[
                            this.props.history.location.state.seller_no
                          ].seller_stripe_account_id && (
                            <div className="form-group mr-5">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  value=""
                                  id="payNow"
                                  name="payNow"
                                  key={this.state.payNow}
                                  checked={this.state.payNow}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        payNow: e.target.checked,
                                        newCard: false,
                                      },
                                      () => {
                                        if (
                                          this.state.payNow &&
                                          (!this.props.customerProfile.cards ||
                                            this.props.customerProfile.cards
                                              .length === 0)
                                        )
                                          this.renderCrdElement();
                                      }
                                    );
                                  }}
                                />

                                <label
                                  style={{ color: "#000" }}
                                  className="form-check-label"
                                  for="payNow"
                                >
                                  Pay now
                                </label>
                              </div>
                            </div>
                          )}

                        {this.props.getCart.cartDetail.sellers &&
                          this.props.getCart.cartDetail.sellers[
                            this.props.history.location.state.seller_no
                          ] &&
                          this.props.getCart.cartDetail.sellers[
                            this.props.history.location.state.seller_no
                          ].pay_later &&
                          localStorage.getItem("x-refresh-token-customer") && (
                            <>
                              {" "}
                              <div className="form-group">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value=""
                                    id="payLater"
                                    name="payLater"
                                    key={!this.state.payNow}
                                    checked={!this.state.payNow}
                                    onChange={(e) => {
                                      this.setState({
                                        payNow: !e.target.checked,
                                      });
                                    }}
                                  />
                                  <label
                                    style={{ color: "#000" }}
                                    className="form-check-label"
                                    for="payLater"
                                  >
                                    Pay on delivery/pickup
                                  </label>
                                </div>
                              </div>
                              {!this.state.payNow && (
                                <div style={{ minWidth: "500px" }}>
                                  <div className="form-group">
                                    <b>
                                      <label className="mr-2">
                                        Available options:{" "}
                                      </label>
                                      {Object.keys(
                                        _.pickBy(
                                          this.props.getCart.cartDetail.sellers[
                                            this.props.history.location.state
                                              .seller_no
                                          ].pay_later,
                                          _.identity
                                        )
                                      )
                                        .map((key) =>
                                          this.props.getCart.cartDetail.sellers[
                                            this.props.history.location.state
                                              .seller_no
                                          ].pay_later[key] === true
                                            ? _.capitalize(key) ===
                                              "Americanexpress"
                                              ? "American Express"
                                              : _.capitalize(key)
                                            : ""
                                        )
                                        .join(", ")}

                                      {
                                        "                                           "
                                      }
                                    </b>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                      </div>
                      {this.state.payNow && (
                        <>
                          {this.props.customerProfile.cards &&
                          this.props.customerProfile.cards.length > 0 ? (
                            <div className="form-group">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  value=""
                                  id="cardType1"
                                  name="cardType1"
                                  key={this.state.newCard}
                                  checked={!this.state.newCard}
                                  onChange={(e) => {
                                    this.toggleNewCard(e, false);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  for="cardType1"
                                >
                                  Card number : XXXX XXXX XXXX{" "}
                                  {this.props.customerProfile.cards[0].last4}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <div className="form-group">
                              <div
                                id="card-element"
                                className="form-control"
                                style={{ paddingTop: ".7em" }}
                              ></div>
                            </div>
                          )}
                          {this.props.customerProfile.cards &&
                            this.props.customerProfile.cards.length > 0 && (
                              <div className="form-group">
                                <div className="form-check">
                                  <input
                                    key={this.state.newCard}
                                    className="form-check-input"
                                    type="radio"
                                    value=""
                                    id="cardType2"
                                    name="cardType"
                                    checked={this.state.newCard}
                                    onChange={(e) =>
                                      this.toggleNewCard(e, true)
                                    }
                                  />
                                  <label
                                    className="form-check-label w-100"
                                    for="cardType2"
                                  >
                                    {!this.state.newCard && "New Card"}
                                    {this.state.newCard && (
                                      <div
                                        id="card-element"
                                        className="form-control"
                                        style={{ paddingTop: ".7em" }}
                                      ></div>
                                    )}
                                  </label>
                                </div>
                              </div>
                            )}
                        </>
                      )}
                      <div className="checkout-btn">
                        {this.state.loader ? (
                          <button className="btn btn-default btn-block btn-loading">
                            <img src={loadingIcon} alt="" />
                          </button>
                        ) : (
                          <a
                            href=""
                            onClick={this.doSubmit}
                            className="btn btn-default btn-block"
                          >
                            Place your order
                          </a>
                        )}
                      </div>
                      {this.props.getCart.cartDetail.sellers &&
                        this.props.getCart.cartDetail.sellers[
                          this.props.history.location.state.seller_no
                        ].summary && (
                          <>
                            <h3 style={{ marginTop: "25px" }}>Order Summary</h3>
                            <div className="table-box">
                              <table className="table-responsive2">
                                <tbody>
                                  <tr>
                                    <td>Order Total</td>
                                    <td align="right">
                                      $
                                      {this.props.getCart.cartDetail.sellers[
                                        this.props.history.location.state
                                          .seller_no
                                      ].summary.subtotal.toLocaleString(
                                        undefined,
                                        { minimumFractionDigits: 2 }
                                      )}
                                    </td>
                                  </tr>
                                  {this.props.getCart.cartDetail.sellers[
                                    this.props.history.location.state.seller_no
                                  ].summary.discount > 0 ? (
                                    <tr>
                                      <td>Coupon discount</td>
                                      <td align="right">
                                        -$
                                        {
                                          this.props.getCart.cartDetail.sellers[
                                            this.props.history.location.state
                                              .seller_no
                                          ].summary.discount
                                        }
                                      </td>
                                    </tr>
                                  ) : (
                                    <tr>
                                      <td>Discount</td>
                                      <td align="right"> $0</td>
                                    </tr>
                                  )}
                                  {this.props.getCart.cartDetail.sellers[
                                    this.props.history.location.state.seller_no
                                  ].summary.final_total_before_tax && (
                                    <tr>
                                      <td>Total after discount</td>
                                      <td align="right">
                                        $
                                        {this.props.getCart.cartDetail.sellers[
                                          this.props.history.location.state
                                            .seller_no
                                        ].summary.final_total_before_tax.toLocaleString(
                                          undefined,
                                          { minimumFractionDigits: 2 }
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                  <tr>
                                    <td>Tax Total</td>
                                    <td align="right">
                                      +$
                                      {this.props.getCart.cartDetail.sellers[
                                        this.props.history.location.state
                                          .seller_no
                                      ].summary.tax.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                      })}
                                    </td>
                                  </tr>
                                  {this.props.getCart.cartDetail.sellers[
                                    this.props.history.location.state.seller_no
                                  ].summary.delivery_charge !== 0 && (
                                    <tr>
                                      <td>Delivery Charge</td>
                                      <td align="right">
                                        +$
                                        {this.props.getCart.cartDetail.sellers[
                                          this.props.history.location.state
                                            .seller_no
                                        ].summary.delivery_charge.toLocaleString(
                                          undefined,
                                          {
                                            minimumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                  )}{" "}
                                  {this.props.getCart.cartDetail.sellers[
                                    this.props.history.location.state.seller_no
                                  ].summary.shipping_charge !== 0 && (
                                    <tr>
                                      <td>Shipping Charge</td>
                                      <td align="right">
                                        +$
                                        {this.props.getCart.cartDetail.sellers[
                                          this.props.history.location.state
                                            .seller_no
                                        ].summary.shipping_charge.toLocaleString(
                                          undefined,
                                          {
                                            minimumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td>Final Amount</td>
                                    <td align="right">
                                      $
                                      {this.props.getCart.cartDetail.sellers[
                                        this.props.history.location.state
                                          .seller_no
                                      ].summary.final_total.toLocaleString(
                                        undefined,
                                        { minimumFractionDigits: 2 }
                                      )}
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </>
                        )}
                      <h3>Customer Information</h3>
                      <div className="ccr-text-box d-flex flex-wrap flex-column">
                        <h5>
                          {customer_information.first_name +
                            " " +
                            customer_information.last_name}
                        </h5>
                        <a>{customer_information.email}</a>
                        <a>{customer_information.mobile}</a>
                      </div>
                      <h3>Billing Address</h3>
                      <div className="ccr-text-box d-flex flex-wrap flex-column">
                        {`${customer_address.address1} ${
                          customer_address.address2
                            ? ", " + customer_address.address2
                            : ""
                        } `}
                        <br></br>
                        {`${customer_address.city && customer_address.city}, ${
                          customer_address.state && customer_address.state
                        } ${
                          customer_address && customer_address.zip
                            ? customer_address.zip
                            : ""
                        }`}
                      </div>
                      <h3>Delivery Address</h3>
                      {`${deliveryAddressArr[0]} ${
                        deliveryAddressArr.length > 4
                          ? ", " + deliveryAddressArr[1]
                          : ""
                      } `}
                      <br></br>
                      {`${deliveryAddressArr[deliveryAddressArr.length - 3]}, ${
                        deliveryAddressArr[deliveryAddressArr.length - 2]
                      } ${deliveryAddress.postal_code.long_name}`}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </main>
        <OrderSuccess
          modelToggle={this.modelToggle}
          modelShow={this.state.model}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  getCart: getCart(state),
  customerProfile: getCustomer(state).profile,
  profileLoading: getCustomer(state).profileLoading,
});
const mapDispatchToProps = (dispatch) => ({
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
  loadCart: (id, data) => dispatch(loadCart(id, data)),
  prepareOrderForPayment: (data, callback) =>
    dispatch(prepareOrderForPayment(data, callback)),
  placeOrder: (data, callback) => dispatch(placeOrder(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Review);
