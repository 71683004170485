import React, { Component } from "react";
import { Link } from "react-router-dom";
import StarRatings from "react-star-ratings";
import dummySeller from "../../include/images/dummySeller.png";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";

class SellerDetail extends Component {
  render() {
    console.log("seller-detail", this.props.sellerDetail);
    const { sellerDetail } = this.props;
    return sellerDetail && sellerDetail.about ? (
      <div class="media-block-container">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-7">
              <div class="mdc-text-box">
                <div class="mdc-logo-area d-flex flex-wrap align-items-center">
                  <div class="mdc-log">
                    <Link to={`/store/${sellerDetail.seller_id}`}>
                      <img
                        src={
                          sellerDetail && sellerDetail.profilePictureURLs
                            ? process.env.REACT_APP_S3URL +
                              sellerDetail.profilePictureURLs.small
                            : dummySeller
                        }
                        alt=""
                      />
                    </Link>
                  </div>
                  <div class="mdc-text">
                    <>
                      <Link to={`/store/${sellerDetail.seller_id}`}>
                        <h5>{sellerDetail && sellerDetail.company}</h5>
                      </Link>
                    </>

                    {sellerDetail.average_ratings ? (
                      <div
                        style={{ cursor: "pointer" }}
                        class="star-list mb-0 d-flex align-items-end"
                        onClick={this.props.toggleSellerReviews}
                      >
                        <StarRatings
                          rating={sellerDetail.average_ratings}
                          starDimension="20px"
                          starSpacing="3px"
                          isSelectable="false"
                          starEmptyColor="#C4C4C4"
                          starRatedColor="#ffa51e"
                          starHoverColor="#ffa51e"
                          svgIconViewBox="0 0 14 13"
                          svgIconPath="M6.29782 0.816984C6.66463 0.0737383 7.72447 0.07374 8.09129 0.816986L9.3702 3.40835C9.51586 3.70349 9.79743 3.90806 10.1231 3.95539L12.9829 4.37093C13.8031 4.49011 14.1306 5.49809 13.5371 6.07662L11.4678 8.09371C11.2321 8.32345 11.1245 8.65445 11.1802 8.97885L11.6687 11.827C11.8088 12.6439 10.9513 13.2669 10.2177 12.8812L7.65989 11.5365C7.36857 11.3833 7.02054 11.3833 6.72921 11.5365L4.17139 12.8812C3.43776 13.2669 2.58033 12.6439 2.72044 11.827L3.20894 8.97884C3.26458 8.65445 3.15703 8.32345 2.92134 8.09371L0.852018 6.07662C0.258501 5.49809 0.586013 4.49011 1.40623 4.37093L4.26597 3.95539C4.59168 3.90806 4.87324 3.70349 5.0189 3.40834L6.29782 0.816984Z"
                        />

                        <span class="reviews-text">
                          {sellerDetail.average_ratings
                            ? sellerDetail.average_ratings
                            : "0"}{" "}
                          <small>({sellerDetail.total_reviews})</small>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <h5>About</h5>
                <p>
                  <HTMLEllipsis
                    unsafeHTML={
                      sellerDetail && sellerDetail.about
                        ? sellerDetail.about
                        : "No Information to show"
                    }
                    maxLine="4"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                  {/* {sellerDetail && sellerDetail.about
                    ? sellerDetail.about
                    : "No Information to show"} */}
                </p>
              </div>
            </div>
            <div class="col-lg-5 ">
              <div class="mdc-media-box d-flex justify-content-end">
                {/* <img src="include/images/mdc-img.jpg" alt="" />
                <div class="mdc-play-btn">
                  <img src="include/images/mdc-play-btn.svg" alt="" />
                </div> */}
                {sellerDetail.video && (
                  <video
                    width="500"
                    controls
                    key={sellerDetail.video}
                    style={{ borderRadius: "5px" }}
                  >
                    <source
                      src={process.env.REACT_APP_S3URL + sellerDetail.video}
                      type="video/mp4"
                    />
                  </video>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }
}
export default SellerDetail;
