import React from "react";
import Joi from "joi-browser";
import Form from "../common/form/form";
import SubmitBtn from "../common/form/submitBtn";
// import Footer from "../common/footer";
import TopBar from "../common/topBar";

import {
  informationSaved,
  failed,
  passwordInvalid,
  passwordInvalidMax,
  passwordRequired,
  oldPassworddRequired,
} from "../common/misc";
import { getSeller, sellerUpdate } from "../../store/seller";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";

const initialState = {
  settingDropdown: false,
  profileDropdown: false,
  data: {
    oldPassword: "",
    password: "",
    confirmPassword: "",
  },
  errors: {},
};

class ChangePassword extends Form {
  state = initialState;

  schema = {
    confirmPassword: Joi.string()
      .required()
      .label("Confirm Password")
      .valid(Joi.ref("password"))
      .options({
        language: {
          any: {
            allowOnly: "!!Confirm Password and Password fields must be same",
            empty: "field is required",
          },
        },
      }),
    password: Joi.string()
      .min(8)
      .max(25)
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "password"
      )
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.regex.name":
              err.message = passwordInvalid;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;

            case "string.max":
              err.message = passwordInvalidMax;
              break;

            default:
          }
        });
        return errors;
      }),

    oldPassword: Joi.string()
      .required()
      .label("Old Password")
      .error(() => {
        return { message: oldPassworddRequired };
      }),
  };
  doSubmit = () => {
    const { password, oldPassword } = this.state.data;
    const formData = {
      old_password: oldPassword,
      password: password,
    };
    this.props.sellerUpdate(formData, this.callback);
  };
  callback = (res) => {
    if (res && res.status === 200) {
      this.setState(initialState);
      toast(<AlertSuccess message={informationSaved} />);
    } else {
      toast(<AlertError message={res.data.error} />);
      this.setState(initialState);
    }
  };

  handleSettingDropdown = () => {
    if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
      this.setState({ settingDropdown: !this.state.settingDropdown });
    } else {
      this.setState({ settingDropdown: !this.state.settingDropdown });
    }
  };

  handleProfileDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
      this.setState({ profileDropdown: !this.state.profileDropdown });
    } else {
      this.setState({ profileDropdown: !this.state.profileDropdown });
    }
  };

  setSettingDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
    } else if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
    }
  };

  render() {
    return (
      <>
        <div
          onClick={this.setSettingDropdown}
          className="dashboard-container custom-top"
        >
          <TopBar
            handleProfileDropdown={this.handleProfileDropdown}
            handleSettingDropdown={this.handleSettingDropdown}
            settingDropdown={this.state.settingDropdown}
            profileDropdown={this.state.profileDropdown}
            sellerProfile={this.props.sellerProfile}
          />
          <div className="form-container">
            <div className="container">
              <div className="row">
                <div className="col-md-5">
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      {this.renderLoginPasswordInput(
                        "oldPassword",
                        "Old Password"
                      )}
                    </div>
                    <div className="form-group">
                      {this.renderPasswordInput("password", "New Password")}
                    </div>
                    <div className="form-group">
                      {this.renderPasswordInput(
                        "confirmPassword",
                        "Confirm Password"
                      )}
                    </div>
                    <div className="form-group">
                      <div className="edit-info mt-4">
                        <SubmitBtn
                          label="Update"
                          loading={this.state.loading}
                          btnClass="btn btn-default"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  sellerProfile: getSeller(state).profile,
});

const mapDispatchToProps = (dispatch) => ({
  sellerUpdate: (data, callback) => dispatch(sellerUpdate(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
