import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "seller",
  initialState: {
    stripeAccountDetails: { details_submitted: true },
    stripeAccountLoading: false,
    stripeAccountLastFetch: null,
    seller: {
      sellers: [],
      sallerData: [],
    },
    sellerNew: {
      sellers: [],
      sallerData: [],
    },
    orders: {
      data: [],
      pagination: {},
    },
    profile: {},
    sellers: {
      data: [],
      pagination: {
        current_page: 1,
        total_page: 0,
        total_record: 0,
        total_record_on_current_page: 0,
        filtered_record: 0,
        record_per_page: 0,
      },
    },

    orderDetails: {},
    orderDetailsLastFetch: null,
    orderDetailsLoading: false,

    transactions: {
      data: [],
      overall: [],
      pagination: {},
    },
    transactionsLoading: false,
    transactionsLastFetch: null,

    disputeReturnOrders: {
      data: [],
      pagination: {},
    },
    disputeReturnOrdersLoading: false,
    disputeReturnOrdersLastFetch: null,

    profilePhoto: {},
    profilePhotoLoading: false,
    profilePhotoLastFetch: null,

    stockHistory: {
      data: [],
      pagination: {},
    },
    stockHistoryLoading: false,
    stockHistoryLastFetch: null,

    profileLoading: false,
    profileLastFetch: null,
    ordersLoading: false,
    ordersLastFetch: null,
    sellerLoading: false,
    sellerLastFetch: null,
    sellerLoadingNew: false,
    sellerLastFetchNew: null,
    lat_lng: {},
  },
  reducers: {
    disputeOrdersRequested: (seller, action) => {
      seller.disputeReturnOrdersLoading = true;
    },

    disputeOrdersReceived: (seller, action) => {
      seller.disputeReturnOrders = action.payload;
      seller.disputeReturnOrdersLoading = false;
      seller.disputeReturnOrdersLastFetch = Date.now();
    },
    disputeOrdersRequestFailed: (seller, action) => {
      seller.disputeReturnOrdersLoading = false;
    },
    profileRequested: (seller, action) => {
      seller.profileLoading = true;
    },

    profileReceived: (seller, action) => {
      seller.profile = action.payload.data;
      seller.profileLoading = false;
      seller.profileLastFetch = Date.now();
    },

    profileRequestFailed: (seller, action) => {
      seller.profileLoading = false;
    },

    stripeAccountRequested: (seller, action) => {
      seller.stripeAccountLoading = true;
    },

    stripeAccountReceived: (seller, action) => {
      seller.stripeAccountDetails = action.payload.data;
      seller.stripeAccountLoading = false;
      seller.stripeAccountLastFetch = Date.now();
    },

    stripeAccountRequestFailed: (seller, action) => {
      seller.stripeAccountDetails = { details_submitted: false };
      seller.stripeAccountLoading = false;
    },

    sellerRequested: (seller, action) => {
      seller.sellerLoading = true;
    },
    sellerReceived: (seller, action) => {
      seller.sellers = action.payload;
      seller.sellerLoading = false;
      seller.sellerLastFetch = Date.now();
    },
    sellerRequestFailed: (seller, action) => {
      seller.sellerLoading = false;
    },

    sellerMapRequested: (doctor, action) => {
      doctor.sellerLoading = true;
    },
    sellerMapReceived: (doctor, action) => {
      doctor.seller = action.payload;
      doctor.sellerLoading = false;
      doctor.sellerLastFetch = Date.now();
    },
    sellerMapRequestFailed: (doctor, action) => {
      doctor.sellerLoading = false;
    },

    sellerMapRequestedNew: (doctor, action) => {
      doctor.sellerLoadingNew = true;
    },

    sellerMapReceivedNew: (doctor, action) => {
      doctor.sellerNew = action.payload;
      doctor.sellerLoadingNew = false;
      doctor.sellerLastFetchNew = Date.now();
    },
    sellerMapRequestFailedNew: (doctor, action) => {
      doctor.sellerLoadingNew = false;
    },

    ordersRequested: (seller, action) => {
      seller.ordersLoading = true;
    },

    ordersReceived: (seller, action) => {
      seller.orders = action.payload;
      seller.ordersLoading = false;
      seller.ordersLastFetch = Date.now();
    },
    ordersRequestFailed: (seller, action) => {
      seller.ordersLoading = false;
    },

    orderDetailsRequested: (seller, action) => {
      seller.orderDetailsLoading = true;
    },

    orderDetailsReceived: (seller, action) => {
      seller.orderDetails = action.payload;
      seller.orderDetailsLoading = false;
      seller.orderDetailsLastFetch = Date.now();
    },
    orderDetailsRequestFailed: (seller, action) => {
      seller.orderDetailsLoading = false;
    },

    transactionsRequested: (seller, action) => {
      seller.transactionsLoading = true;
    },

    transactionsReceived: (seller, action) => {
      seller.transactions = action.payload;
      seller.transactionsLoading = false;
      seller.transactionsLastFetch = Date.now();
    },
    transactionsRequestFailed: (seller, action) => {
      seller.transactionsLoading = false;
    },

    profilePhotoRequested: (seller, action) => {
      seller.profilePhotoLoading = true;
    },
    profilePhotoReceived: (seller, action) => {
      seller.profilePhoto = action.payload;
      seller.profilePhotoLoading = false;
      seller.profilePhotoLastFetch = Date.now();
    },

    profilePhotoRequestFailed: (seller, action) => {
      seller.profilePhotoLoading = false;
    },

    stockHistoryRequested: (seller, action) => {
      seller.stockHistoryLoading = true;
    },
    stockHistoryReceived: (seller, action) => {
      seller.stockHistory = action.payload;
      seller.stockHistoryLoading = false;
      seller.stockHistoryLastFetch = Date.now();
    },

    stockHistoryRequestFailed: (seller, action) => {
      seller.stockHistoryLoading = false;
    },
  },
});

export const {
  profilePhotoReceived,
  profilePhotoRequestFailed,
  profilePhotoRequested,
  profileRequested,
  profileReceived,
  profileRequestFailed,
  sellerReceived,
  sellerRequestFailed,
  sellerRequested,
  sellerMapRequested,
  sellerMapReceived,
  sellerMapRequestFailed,
  sellerMapReceivedNew,

  sellerMapRequestedNew,
  sellerMapRequestFailedNew,

  stripeAccountRequested,
  stripeAccountReceived,
  stripeAccountRequestFailed,

  ordersRequested,
  ordersReceived,
  ordersRequestFailed,
  orderDetailsRequested,
  orderDetailsReceived,
  orderDetailsRequestFailed,

  transactionsRequested,
  transactionsReceived,
  transactionsRequestFailed,

  disputeOrdersRequested,
  disputeOrdersReceived,
  disputeOrdersRequestFailed,

  stockHistoryRequested,
  stockHistoryReceived,
  stockHistoryRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "seller/";
const otpUrl = "otp/";

export const loadTransactions = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: url + "transactions",
      params,
      onStart: transactionsRequested.type,
      onSuccess: transactionsReceived.type,
      onError: transactionsRequestFailed.type,
    })
  );
};

export const loadOrders = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: "order",
      params,
      onStart: ordersRequested.type,
      onSuccess: ordersReceived.type,
      onError: ordersRequestFailed.type,
    })
  );
};

export const loadOrderDetails = (id) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: "order/" + id,
      onStart: orderDetailsRequested.type,
      onSuccess: orderDetailsReceived.type,
      onError: orderDetailsRequestFailed.type,
    })
  );
};

export const uploadProfileVideo = (data, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      method: "PUT",
      url: "seller/uploadVideo",
      data,
      callback,
      onStart: profilePhotoRequested.type,
      onSuccess: profilePhotoReceived.type,
      onError: profilePhotoRequestFailed.type,
    })
  );
};

export const uploadProfilePictures =
  (data, callback) => (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        method: "PUT",
        url: "seller/uploadProfilePicture",
        data,
        callback,
        onStart: profilePhotoRequested.type,
        onSuccess: profilePhotoReceived.type,
        onError: profilePhotoRequestFailed.type,
      })
    );
  };

export const updateProductStatus =
  (data, id, callback) => (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        method: "PUT",
        url: "order/updateStatus/" + id,
        data,
        callback,
      })
    );
  };

export const updateFullfilStatus =
  (id, callback) => (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        method: "PUT",
        url: "order/fulfill/" + id,
        callback,
      })
    );
  };

export const loadStockHistory = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: "seller/stockHistory",
      params,
      onStart: stockHistoryRequested.type,
      onSuccess: stockHistoryReceived.type,
      onError: stockHistoryRequestFailed.type,
    })
  );
};

export const loadDisputeReturnOrder = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: "order/list/disputeAndReturn",
      params,
      onStart: disputeOrdersRequested.type,
      onSuccess: disputeOrdersReceived.type,
      onError: disputeOrdersRequestFailed.type,
    })
  );
};

export const resolveDispute = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      method: "PUT",
      url: "order/resolveDispute/" + id,
      callback,
    })
  );
};

export const addDisputeMessage =
  (data, id, callback) => (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        method: "PUT",
        url: "order/dispute/" + id,
        data,
        callback,
      })
    );
  };

export const zipValidation = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "zipValidate",
      data,
      method: "POST",
      callback,
    })
  );
};

export const logoutSeller = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "auth/logout",
      method: "POST",
      callback,
    })
  );
};

export const mobileValidation = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: otpUrl + "checktMobileOTP",
      data,
      method: "POST",
      callback,
    })
  );
};
export const emailValidation = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: otpUrl + "checktEmailOTP",
      data,
      method: "POST",
      callback,
    })
  );
};

export const emailAvailability = (data, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + "emailAvailability",
      method: "POST",
      data,
      callback,
    })
  );
};

export const sendMobileOtp = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: otpUrl + "requestMobileOTP",
      method: "POST",
      data,
      callback,
    })
  );
};

export const mobileAvailability = (data, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + "mobileAvailability",
      method: "POST",
      data,
      callback,
    })
  );
};

export const sellerSignUp = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "signUp",
      method: "POST",
      data,
      callback,
    })
  );
};

export const addressValidation = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "addressValidate",
      data,
      method: "POST",
      callback,
    })
  );
};

export const reviewOrder = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "cart/reviewOrder",
      data,
      method: "POST",
      callback,
    })
  );
};

export const sendEmailOtp = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: otpUrl + "requestEmailOTP",
      method: "POST",
      data,
      callback,
    })
  );
};

export const sellerSignIn = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "signIn",
      method: "POST",
      data,
      callback,
    })
  );
};

export const updateMobile = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "updateSellerMobile",
      method: "PUT",
      data,
      callback,
    })
  );
};

export const updateEmail = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "updateSellerEmail",
      method: "PUT",
      data,
      callback,
    })
  );
};

export const resetPasswordMobile = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "resetPasswordMobile",
      method: "PUT",
      data,
      callback,
    })
  );
};

export const resetPasswordEmail = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "resetPasswordEmail",
      method: "PUT",
      data,
      callback,
    })
  );
};

export const checkMobileOtp = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "otp/checkMobileOTP",
      method: "POST",
      data,
      callback,
    })
  );
};

export const checkEmailOtp = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "otp/checkEmailOTP",
      method: "POST",
      data,
      callback,
    })
  );
};

export const sellerUpdate = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "me",
      method: "PUT",
      data,
      callback,
    })
  );
};

export const linkStripeAccount = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "generateStripeAccountLink",
      method: "POST",
      callback,
    })
  );
};

export const loadStripeAccount = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "stripeAccountDetails",
      onStart: stripeAccountRequested.type,
      onSuccess: stripeAccountReceived.type,
      onError: stripeAccountRequestFailed.type,
    })
  );
};

export const loadSellerProfile = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + "me",
      onStart: profileRequested.type,
      onSuccess: profileReceived.type,
      onError: profileRequestFailed.type,
    })
  );
};

export const loadSellerData = (params) => (dispatch, getState) => {
  const { sellerLoading } = getState().entities.seller;
  if (sellerLoading) return;

  // const diffInMinutes = moment().diff(moment(lastFetchState), "minutes");
  // if (diffInMinutes < 10) return;

  return dispatch(
    apiCallBegan({
      url: url + "list",
      method: "GET",
      params,
      onStart: sellerMapRequested.type,
      onSuccess: sellerMapReceived.type,
      onError: sellerMapRequestFailed.type,
    })
  );
};

export const loadSellerDataNew = (params) => (dispatch, getState) => {
  const { sellerLoadingNew } = getState().entities.seller;
  if (sellerLoadingNew) return;

  return dispatch(
    apiCallBegan({
      url: url + "listNew",
      method: "GET",
      params,
      onStart: sellerMapRequestedNew.type,
      onSuccess: sellerMapReceivedNew.type,
      onError: sellerMapRequestFailedNew.type,
    })
  );
};

export const getSeller = createSelector(
  (state) => state.entities.seller,
  (seller) => seller
);
