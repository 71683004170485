import React, { Component } from "react";
import { Link } from "react-router-dom";
import Copyright from "../common/copyright";
import {
  getCustomerToken,
  getSellerToken,
} from "../services/localStorageServices";
export default class Footer extends Component {
  render() {
    return (
      // <footer className="footer sticky" id="footer">
      //   <div className="container">
      //     <div className="row">
      //       <div className="col-md-12">
      //         <div className="footer-content text-center">
      //           <Copyright />
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </footer>
      <footer id="footer3" class="text-white">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 d-lg-flex align-items-lg-center justify-content-lg-between">
              <ul class="footer-nav-link-list d-lg-flex align-items-lg-center">
                <li>
                  <a
                    href="https://effisim.info/terms-conditions"
                    target="_blank"
                  >
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a href="https://effisim.info/privacy-policy" target="_blank">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="https://effisim.info/FAQ" target="_blank">
                    FAQ
                  </a>
                </li>
                {getCustomerToken("auth") ? (
                  <></>
                ) : (
                  <>
                    {getSellerToken("auth") ? (
                      ""
                    ) : (
                      <>
                        <li>
                          <Link to="/seller/login" target="_blank">
                            Seller Login
                          </Link>
                        </li>
                        <li>
                          <Link to="/seller/registration" target="_blank">
                            Seller Registration
                          </Link>
                        </li>
                      </>
                    )}
                  </>
                )}
              </ul>
              <div class="copyright">
                &#169;{new Date().getFullYear()} Efffisim Corp. All rights
                reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
