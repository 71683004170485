import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import logo from "../../include/images/logo.svg";
import closeicon from "../../include/images/close_icon-1.svg";
import cartIcon from "../../include/images/dark-cart-icon.svg";
import homeIcon from "../../include/images/home-icon.svg";
import userIcon from "../../include/images/user-icon.svg";
import user from "../../include/images/user.svg";
import closeIcon from "./../../include/images/close-icon.svg";
import phone from "../../include/images/phone-icon.svg";
import enter from "../../include/images/enter-icon.svg";
import Vector from "../../include/images/search-3.svg";
import aimWh from "../../include/images/aim-white.svg";
import aim1 from "../../include/images/aim 1.svg";
import downArow from "../../include/images/user-down-arrow.svg";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import { getCart, loadCart } from "../../store/cart";
import Contact from "./../common/contact";
import {
  logoutCustomer,
  loadCustomerProfile,
  getCustomer,
} from "../../store/customer";
import logoutIcon from "../../include/images/logout.svg";
import about from "../../include/images/about-us.svg";

import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import {
  getCustomerName,
  getCustomerToken,
} from "../services/localStorageServices";
import _ from "lodash";
import AddressFlyer from "./addressFlyer";
import UserSideBar from "./userSideBar";
import CategoryMenu from "./categoryMenu";
import { getProduct, loadCategories } from "../../store/product";
import AutoComplete from "./autoComplete";
import Searchbar from "./searchbar";
import { isMobile } from "react-device-detect";

class Header extends Component {
  state = {
    alertShow: !localStorage.getItem("alertShow"),
    mobileMenu: false,
    userSideBar: false,
    seratchOpen: false,
    adressChange: false,
    isContact: false,
    isShow: false,
    user_device_id: localStorage.getItem("user_device_id"),
    searchText: "",
    mobadd: false,
    width: 0,
    param: JSON.parse(localStorage.getItem("customerDddress")),
  };

  toggleUserSideBar = (e) => {
    e.preventDefault();
    this.setState({ userSideBar: !this.state.userSideBar });
  };
  toggleMobileMenu = (e) => {
    e.preventDefault();
    this.setState({ mobileMenu: !this.state.mobileMenu });
  };
  toggleSearch = () => {
    this.setState({ seratchOpen: !this.state.seratchOpen }, () => {
      if (this.state.seratchOpen) {
        document.body.classList.add("search-open");
      } else {
        document.body.classList.remove("search-open");
      }
    });
  };

  togglemobAdd = (e) => {
    if (e) e.preventDefault();
    if (!isMobile) {
      this.setState({ mobadd: false });
    } else if (isMobile) {
      this.setState({ mobadd: true, adressChange: false });
      document.body.classList.add("toggled-tab");
    }
  };

  //search-open
  handleadressChange = (e) => {
    if (e) e.preventDefault();
    localStorage.setItem("alertShow", true);
    this.setState({ adressChange: !this.state.adressChange, alertShow: false });
    this.togglemobAdd();
  };
  handleClose = () => {
    this.setState(
      {
        adressChange: false,
      },
      () => this.props.loadCart(this.state.user_device_id)
    );
    document.body.classList.remove("toggled-tab");
  };
  toggle = () => {
    this.setState({
      isShow: !this.state.isShow,
    });
  };
  toggleContact = () => {
    this.setState({
      isContact: !this.state.isContact,
    });
    document.body.classList.add("toggled-tab");
  };

  removeContact = () => {
    this.setState({
      isContact: !this.state.isContact,
    });
    document.body.classList.remove("toggled-tab");
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.keyword !== prevProps.keyword) {
      this.setState({ searchText: this.props.keyword });
    }
  };

  componentDidMount = () => {
    //this.props.loadCategories();
    this.setState({ width: document.body.clientWidth });
    localStorage.setItem("redirectUrl", this.props.location.pathname);

    if (this.state.user_device_id) {
      // this.props.loadCustomerProfile();
      this.props.loadCart(this.state.user_device_id, {
        address: this.state.param.address,
        zipCode: this.state.param.postal_code,
        lat: this.state.param.lat,
        lon: this.state.param.lng,
      });
    } else {
      const uuid = uuidv4();
      localStorage.setItem("user_device_id", uuid);
      this.props.loadCart(uuid);
    }
  };

  logout = (e) => {
    e.preventDefault();
    this.props.logoutCustomer(this.callback);
  };

  callback = (res) => {
    if (res.status === 200) {
      window.localStorage.removeItem("x-auth-token-customer");
      window.localStorage.removeItem("x-refresh-token-customer");
      window.localStorage.removeItem("customer-name");
      window.localStorage.removeItem("redirectUrl");
      localStorage.removeItem("customer_information");
      window.localStorage.removeItem("customerDddress");
      window.localStorage.removeItem("customer_address");
      localStorage.removeItem("customerAddress");
      window.location.href = "/";
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };

  // componentDidMount = () => {
  //   console.log("componentDidMount", this.props);
  // };

  render() {
    const adressArr = this.props.address ? this.props.address.split(",") : [];
    const { final_total, total_products } =
      this.props.getCart.cartDetail.orderSummary;
    const pathname = this.props.location.pathname.split("/")[1];
    return (
      <>
        <header id="header3" class="has-header-top has-blue-header">
          <div class="header-top">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="ht-box  d-flex align-items-center">
                    <div class="user-intro-box d-none d-lg-block">
                      Welcome,{" "}
                      {localStorage.getItem("customer-name")
                        ? localStorage.getItem("customer-name")
                        : localStorage.getItem("x-auth-token-seller")
                        ? JSON.parse(localStorage.getItem("seller-profile"))
                            .company
                        : "Guest"}
                    </div>
                    {localStorage.getItem("x-auth-token-seller") ? (
                      <ul class="query-list d-lg-flex flex-lg-wrap align-items-lg-center ml-lg-auto d-none d-lg-block">
                        <li>
                          <Link to={"/product-listing"}>
                            Back to seller dashboard{" "}
                          </Link>
                        </li>
                      </ul>
                    ) : (
                      <ul className="query-list d-lg-flex flex-lg-wrap align-items-lg-center ml-lg-auto d-none d-lg-block">
                        {getCustomerToken("refresh") ? (
                          <>
                            <li>
                              <i
                                onClick={() =>
                                  this.props.history.push("/products-listing")
                                }
                              >
                                <img src={homeIcon} alt="" />
                              </i>
                              <Link to="/products-listing">Home</Link>
                            </li>
                            <li>
                              <i onClick={this.toggleContact}>
                                <img src={phone} alt="" />
                              </i>
                              <a
                                onClick={this.toggleContact}
                                href="javascript:void(0)"
                              >
                                Contact Us
                              </a>
                            </li>
                            <li>
                              <i>
                                <img src={about} alt="" />
                              </i>
                              <a
                                href="https://effisim.info/FAQ"
                                target="_blank"
                              >
                                About
                              </a>
                            </li>

                            <li>
                              <i
                                onClick={() =>
                                  this.props.history.push(
                                    "/customer/account-details"
                                  )
                                }
                              >
                                <img src={userIcon} alt="" />
                              </i>
                              <Link to={"/customer/account-details"}>
                                My account
                              </Link>
                            </li>
                            <li>
                              <i onClick={this.logout}>
                                <img src={logoutIcon} alt="" />
                              </i>
                              <a
                                href="javascript:void(0)"
                                onClick={this.logout}
                              >
                                Logout
                              </a>
                            </li>
                          </>
                        ) : (
                          <>
                            <li>
                              <i
                                onClick={() =>
                                  this.props.history.push("/products-listing")
                                }
                              >
                                <img src={homeIcon} alt="" />
                              </i>
                              <Link to="/products-listing">Home</Link>
                            </li>
                            <li>
                              <i onClick={this.toggleContact}>
                                <img src={phone} alt="" />
                              </i>
                              <a
                                onClick={this.toggleContact}
                                href="javascript:void(0)"
                              >
                                Contact Us
                              </a>
                            </li>
                            <li>
                              <i>
                                <img src={about} alt="" />
                              </i>
                              <a
                                href="https://effisim.info/FAQ"
                                target="_blank"
                              >
                                About
                              </a>
                            </li>
                            <li>
                              <i
                                onClick={() =>
                                  this.props.history.push("/customer/login")
                                }
                              >
                                <img src={enter} alt="" />
                              </i>
                              <Link to="/customer/login">Login</Link>
                            </li>
                            <li>
                              <i
                                onClick={() =>
                                  this.props.history.push(
                                    "/customer/registration"
                                  )
                                }
                              >
                                <img src={userIcon} alt="" />
                              </i>
                              <Link to="/customer/registration">Sign up</Link>
                            </li>
                          </>
                        )}
                      </ul>
                    )}
                    <div class="new-search-row d-flex align-items-center d-block d-lg-none">
                      {this.props.address ? (
                        <div class="basic-addon-box d-flex align-items-center">
                          {this.state.adressChange ? (
                            <Searchbar
                              handleClose={this.handleClose}
                              updateLatLng={this.props.updateLatLng}
                              total_products={total_products}
                            />
                          ) : (
                            <>
                              <img src={aimWh} alt="" />
                              <span>
                                {adressArr.length > 4
                                  ? `${adressArr[0]}, ${adressArr[1]}, ${adressArr[2]}, ${adressArr[3]} ${this.props.postal_code}`
                                  : `${adressArr[0]}, ${adressArr[1]}, ${adressArr[2]} ${this.props.postal_code}`}
                              </span>
                              {this.props.updateLatLng && (
                                <div
                                  className={
                                    this.state.alertShow
                                      ? "edit-text-dropdown dropdown show"
                                      : "edit-text-dropdown dropdown"
                                  }
                                >
                                  <a
                                    href="#!"
                                    class="edit-text dropdown-toggle"
                                    onClick={this.handleadressChange}
                                  >
                                    Edit
                                  </a>

                                  <div
                                    class={
                                      this.state.alertShow
                                        ? "dropdown-menu with-close show"
                                        : "dropdown-menu with-close"
                                    }
                                  >
                                    <a
                                      href="#"
                                      class="dropdown-close"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        localStorage.setItem("alertShow", true);
                                        this.setState({ alertShow: false });
                                      }}
                                    >
                                      <img
                                        class="tag-close-icon"
                                        src={closeIcon}
                                        alt=""
                                      />
                                    </a>
                                    <p>
                                      Please make sure to choose correct
                                      delivery address here
                                    </p>
                                  </div>
                                </div>
                              )}{" "}
                            </>
                          )}
                        </div>
                      ) : (
                        <div class="basic-addon-box d-flex align-items-center">
                          Welcome,{" "}
                          {localStorage.getItem("customer-name")
                            ? localStorage.getItem("customer-name")
                            : localStorage.getItem("x-auth-token-seller")
                            ? JSON.parse(localStorage.getItem("seller-profile"))
                                .company
                            : "Guest"}
                        </div>
                      )}
                    </div>
                    <div
                      class="header-dropdown-menu d-block d-lg-none ml-auto sidebar_toggler"
                      data-toggle="loginSidebarpannel"
                      onClick={this.toggleUserSideBar}
                    >
                      <img src={user} alt="" />
                      <i>
                        <img src={downArow} alt="" />
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="header-main">
            <nav class="navbar">
              <div class="container-fluid">
                <div class="nav-inside d-flex align-items-center">
                  {/* <button
                    class="navbar-toggler collapsed sidebar_toggler"
                    data-toggle="homeMenuSiderbar"
                    type="button"
                  >
                    <span class="navbar-toggler-icon"></span>
                  </button> */}
                  <Link className="navbar-brand" to="/products-listing">
                    <img src={logo} alt="" />
                  </Link>
                  {this.props.setKeyword && (
                    <div class="new-search-row d-lg-flex align-items-lg-center d-none d-lg-block">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">
                            <img src={Vector} alt="" />
                          </span>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search here"
                          aria-label="search"
                          aria-describedby="basic-addon1"
                          value={this.state.searchText}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              this.props.setKeyword(this.state.searchText);
                            }
                          }}
                          onChange={(e) =>
                            this.setState({ searchText: e.target.value })
                          }
                        />
                        <img
                          onClick={(e) => {
                            this.props.setKeyword("");
                            this.setState({ searchText: "" });
                          }}
                          class="close"
                          src={closeicon}
                          alt=""
                        />
                      </div>
                      <div class="basic-addon-box d-flex align-items-center">
                        {this.state.adressChange ? (
                          <div style={{ width: "80%", position: "relative" }}>
                            <Searchbar
                              handleClose={this.handleClose}
                              updateLatLng={this.props.updateLatLng}
                              total_products={total_products}
                            />
                          </div>
                        ) : (
                          <>
                            <img src={aim1} alt="" />
                            <span>
                              {" "}
                              {adressArr.length > 4
                                ? `${adressArr[0]}, ${adressArr[1]}, ${adressArr[2]}, ${adressArr[3]} ${this.props.postal_code}`
                                : `${adressArr[0]}, ${adressArr[1]}, ${adressArr[2]} ${this.props.postal_code}`}
                            </span>
                            {this.props.updateLatLng && (
                              <div
                                className={
                                  this.state.alertShow
                                    ? "edit-text-dropdown dropdown show"
                                    : "edit-text-dropdown dropdown"
                                }
                              >
                                <a
                                  href="#"
                                  class="edit-text dropdown-toggle"
                                  data-toggle="dropdown"
                                  onClick={this.handleadressChange}
                                >
                                  Edit
                                </a>
                                <div
                                  class={
                                    this.state.alertShow
                                      ? "dropdown-menu with-close show"
                                      : "dropdown-menu with-close"
                                  }
                                >
                                  <a
                                    href="#"
                                    class="dropdown-close"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      localStorage.setItem("alertShow", true);
                                      this.setState({ alertShow: false });
                                    }}
                                  >
                                    <img
                                      class="tag-close-icon"
                                      src={closeIcon}
                                      alt=""
                                    />
                                  </a>
                                  <p>
                                    Please make sure to choose correct delivery
                                    address here
                                  </p>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  <div class="header-right-content ml-auto">
                    <div
                      class="cart-icon d-flex align-items-center"
                      onClick={(e) => {
                        this.props.history.push("/cart");
                      }}
                    >
                      <img src={cartIcon} alt="" />
                      <span class="price-icon">
                        {total_products ? total_products : 0}
                      </span>
                    </div>
                  </div>
                </div>
                {this.props.setKeyword && (
                  <div class="mob-search-box d-block d-lg-none">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">
                          <img src={Vector} alt="" />
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search here"
                        aria-label="search"
                        aria-describedby="basic-addon1"
                        value={this.state.searchText}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            this.props.setKeyword(this.state.searchText);
                          }
                        }}
                        onChange={(e) =>
                          this.setState({ searchText: e.target.value })
                        }
                      />
                      <img class="close" src={closeicon} alt="" />
                    </div>
                  </div>
                )}
              </div>
            </nav>
          </div>
        </header>
        {/* <CategoryMenu
          toggleMobileMenu={this.toggleMobileMenu}
          mobileMenu={this.state.mobileMenu}
        /> */}
        <UserSideBar
          toggleUserSideBar={this.toggleUserSideBar}
          userSideBar={this.state.userSideBar}
          toggleContact={this.toggleContact}
        />

        <Contact
          isContact={this.state.isContact}
          toggleContact={this.toggleContact}
          removeContact={this.removeContact}
        />
        {this.state.mobadd && (
          <AddressFlyer
            handleClose={this.handleClose}
            updateLatLng={this.props.updateLatLng}
            mobadd={this.state.mobadd}
            total_products={total_products}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  getCart: getCart(state),
  customerProfile: getCustomer(state).profile,
  pagination: getProduct(state).pagination,
  loading: getProduct(state).loading,
});
const mapDispatchToProps = (dispatch) => ({
  loadCart: (id, data) => dispatch(loadCart(id, data)),
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
  logoutCustomer: (callback) => dispatch(logoutCustomer(callback)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
