import React, { Component } from "react";
import upload from "../../../include/images/upload.svg";

class VideoInput extends Component {
  state = {};
  render() {
    const {
      label,
      name,
      text,
      status,
      onchange,
      error,
      flag,
      totalUploaded,
      disabled,
      optional,
    } = this.props;
    return (
      <div className="form-group custom-upload">
        <label>
          {label}
          {optional && <span>Optional</span>}
        </label>
        <label
          key={disabled}
          style={{
            backgroundColor: this.props.disabled === true ? "#e9ecef" : "auto",
          }}
          className={
            error
              ? "btn btn-upload btn-block pull-left errorColor image"
              : "btn btn-upload btn-block pull-left image"
          }
          htmlFor={name}
        >
          <span>
            <img src={upload} alt="" />
            {status ? <strong> {status} </strong> : <strong>Add file</strong>}
          </span>{" "}
        </label>
        <input
          accept="video/*"
          type="file"
          id={name}
          name={name}
          className="d-none"
          onChange={onchange}
          multiple={flag}
          disabled={disabled}
        />
        {!flag ? (
          <>
            {text && (
              <a
                className="add-more-field"
                href={text && process.env.REACT_APP_S3URL + text}
                style={{ fontSize: "11px" }}
                target="_blank"
                download
              >
                Click here to view your uploaded {label}
              </a>
            )}

            {/* <span className="note">Image Format - JPEG</span> */}
          </>
        ) : (
          <span className="note"></span>
        )}
        {error && (
          <label
            className="error"
            style={{ color: "#ff0c3e", fontWeight: "400" }}
          >
            {error}
          </label>
        )}
      </div>
    );
  }
}

export default VideoInput;
