import React, { Component } from "react";
import botArrow from "../../include/images/bott-arrow.svg";

export default class SortingIconDown extends Component {
  render() {
    return (
      <span
        className="svg-icon svg-icon-primary svg-icon-2x"
        onClick={() => {
          this.props.sorting();
        }}
      >
        <i>
          <img src={botArrow} alt="" />
        </i>
      </span>
    );
  }
}
