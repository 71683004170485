import React from "react";
import Form from "../common/form/form";
import Sidebar from "../common/sideBar";
import SubmitBtn from "../common/form/submitBtn";
import AlertError from "../common/alertError";

import { Link, withRouter } from "react-router-dom";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import { sellerSignIn } from "../../store/seller";
import {
  passwordInvalid,
  passwordInvalidMax,
  passwordRequired,
  loginRequired,
} from "../common/misc";

import { setSellerToken } from "../services/localStorageServices";

class LoginForm extends Form {
  state = {
    btnClass: "btn btn-default",
    loading: false,
    multi: false,
    data: {
      username: "",
      password: "",
    },
    errors: {
      username: "",
      password: "",
    },
  };

  componentDidMount = () => {
    document.body.classList.add("pt-0");
  };

  componentWillUnmount = () => {
    document.body.classList.remove("pt-0");
  };

  schema = {
    password: Joi.string()
      .min(8)
      .max(25)
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;

            case "string.max":
              err.message = passwordInvalidMax;
              break;

            default:
              break;
          }
        });
        return errors;
      }),
    username: Joi.string()
      .required()
      .label("Username")
      .error(() => {
        return { message: loginRequired };
      }),
  };

  doSubmit = () => {
    if (!this.state.loading) {
      this.setState({
        btnClass: "btn btn-default disabled",
        loading: true,
      });
      const { data } = this.state;
      this.props.sellerSignIn(data, this.callback);
    }
  };

  callback = (res) => {
    if (res.status === 200) {
      localStorage.setItem("seller-profile", JSON.stringify(res.data.data));
      const { xAuthToken, xRefreshToken } = res.data;
      setSellerToken(xRefreshToken, xAuthToken);
      this.props.history.push("/product-listing");
    } else {
      this.setState({
        loading: false,
        btnClass: "btn btn-default",
      });
      toast(<AlertError message={"Incorrect login credentials"} />);
    }
  };

  render() {
    return (
      <div className="member-container">
        <Sidebar />
        <div className="member-right">
          <div className="signin-form-container">
            <div className="signin-form">
              <h2>Seller Login</h2>

              <div className="already-login">
                <Link to="/seller/registration" className="sep-login">
                  Register as a seller
                </Link>
              </div>

              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  {this.renderInput("username", "Email ID / Mobile Number")}
                </div>

                <div className="form-group">
                  {this.renderLoginPasswordInput("password", "Password")}
                  <div className="forgot-password">
                    <Link to="/seller/forgot-password">Forgot password?</Link>
                  </div>
                </div>

                <div className="form-group form-button">
                  <SubmitBtn
                    label="Login"
                    loading={this.state.loader}
                    btnClass={this.state.btnClass}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  sellerSignIn: (param, callback) => dispatch(sellerSignIn(param, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(LoginForm));
