import React from "react";
import Form from "../common/form/form";
import Sidebar from "../common/sideBar";
import SubmitBtn from "../common/form/submitBtn";

import {
  sendMobileOtp,
  mobileAvailability,
  emailAvailability,
  sendEmailOtp,
} from "../../store/seller";

import { accountNotExist, loginRequired } from "../common/misc";

import { Link, withRouter } from "react-router-dom";
import Joi from "joi-browser";
import AlertSuccess from "../common/alertSuccess";
import AlertError from "../common/alertError";
import { toast } from "react-toastify";
import { connect } from "react-redux";

class VerifyAccount extends Form {
  state = {
    type: 1,
    btnClass: "btn btn-lg btn-primary btn-block",
    loader: false,
    data: {
      username: "",
    },
    errors: { username: "" },
  };

  componentWillUnmount = () => {
    document.body.classList.remove("pt-0");
  };

  componentDidMount = () => {
    if (this.props.verifyMobile.mobile) {
      this.setState({
        data: {
          username: this.props.verifyMobile.mobile,
        },
      });
    } else {
      this.setState({
        data: {
          username: this.props.verifyEmail.email,
        },
      });
    }

    document.body.classList.add("pt-0");
    localStorage.removeItem("x-auth-token-doctor");
    localStorage.removeItem("x-refresh-token-doctor");
  };

  doSubmit = () => {
    const phoneRegex = /^\d{10}$/;

    if (!this.state.loader) {
      this.setState({
        loader: true,
        btnClass: "btn btn-lg btn-primary btn-block disabled",
      });
      if (this.state.data.username.match(phoneRegex)) {
        const mobileParams = {
          mobile: this.state.data.username,
        };
        this.props.mobileAvailability(mobileParams, this.callbackMobile);
      } else {
        const emailParams = {
          email: this.state.data.username,
        };
        this.props.emailAvailability(emailParams, this.callbackEmail);
      }
    }
  };

  callbackMobile = (res) => {
    this.setState({
      loader: false,
      btnClass: "btn btn-lg btn-primary btn-block",
    });
    if (res.status === 200) {
      toast(<AlertError message={accountNotExist} />);
    } else {
      const params = { mobile: this.state.data.username };
      this.props.sendMobileOtp(params, this.otpMobileCallBack);
    }
  };

  otpMobileCallBack = (res) => {
    if (res.status === 200) {
      toast(
        <AlertSuccess message={res.data.otp ? res.data.otp : "OTP sent."} />
      );
      this.props.updateStep(2);
      this.props.updateVerifyMobile({
        otp: res.data.otp,
        mobile: this.state.data.username,
        type: 1,
        code: "+1",
      });
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  callbackEmail = (res) => {
    this.setState({
      loader: false,
      btnClass: "btn btn-lg btn-primary btn-block",
    });
    if (res.status === 200) {
      toast(<AlertError message={accountNotExist} />);
    } else {
      const emailParams = { email: this.state.data.username };
      this.props.sendEmailOtp(emailParams, this.otpEmailCallBack);
    }
  };

  otpEmailCallBack = (res) => {
    if (res.status === 200) {
      this.props.updateStep(3);

      toast(
        <AlertSuccess message={res.data.otp ? res.data.otp : "OTP Sent."} />
      );
      this.props.updateVerifyEmail({
        otp: res.data.otp,
        email: this.state.data.username,
      });
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  schema = {
    username: Joi.string()
      .required()
      .label("Username")
      .error(() => {
        return { message: loginRequired };
      }),
  };

  render() {
    return (
      <div className="member-container">
        <Sidebar />
        <div className="member-right">
          <div className="signin-form-container">
            <div className="signin-form login-form">
              <h2>Forgot Password</h2>

              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  {this.renderInput("username", "Email ID / Mobile Number")}
                </div>

                <div className="form-group form-button">
                  <SubmitBtn
                    label={this.state.loader === true ? "" : "Request OTP"}
                    loading={this.state.loader}
                    btnClass="btn btn-default"
                  />
                </div>
              </form>

              <div className="back-link">
                <Link to="/seller/login" className="btn-link">
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns-xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 31.494 31.494"
                    xml-space="preserve"
                  >
                    <path
                      d="M10.273,5.009c0.444-0.444,1.143-0.444,1.587,0c0.429,0.429,0.429,1.143,0,1.571l-8.047,8.047h26.554
                                c0.619,0,1.127,0.492,1.127,1.111c0,0.619-0.508,1.127-1.127,1.127H3.813l8.047,8.032c0.429,0.444,0.429,1.159,0,1.587
                                c-0.444,0.444-1.143,0.444-1.587,0l-9.952-9.952c-0.429-0.429-0.429-1.143,0-1.571L10.273,5.009z"
                    ></path>
                  </svg>
                  Back to Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  mobileAvailability: (param, callback) =>
    dispatch(mobileAvailability(param, callback)),
  sendMobileOtp: (param, callback) => dispatch(sendMobileOtp(param, callback)),

  emailAvailability: (param, callback) =>
    dispatch(emailAvailability(param, callback)),
  sendEmailOtp: (param, callback) => dispatch(sendEmailOtp(param, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(VerifyAccount));
