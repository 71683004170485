import React from "react";
import ModalHeader from "./modalHeader";

import UploadModal3 from "./uploadModal3";
import UploadModal2 from "./uploadModal2";
import UploadModal1 from "./uploadModal1";

class UploadBulkFile extends React.Component {
  state = {
    fileUploaded: false,
    uploadData: [],
    step: 1,
    mapContent: false,
    formData: {},
    file: "",
    errors: {},
  };

  updateFormData = (data) => {
    this.setState({
      formData: data,
    });
  };

  setStep = (step) => {
    this.setState({ step });
  };

  updateFile = (data) => {
    this.setState({
      file: data,
    });
  };

  loadStep = (step) => {
    switch (step) {
      case 1:
        return (
          <UploadModal1
            formData={this.state.formData}
            updateFormData={this.updateFormData}
            file={this.state.file}
            updateFile={this.updateFile}
            setStep={this.setStep}
            step={this.state.step}
          />
        );

      case 2:
        return (
          <UploadModal2
            formData={this.state.formData}
            updateFormData={this.updateFormData}
            file={this.state.file}
            updateFile={this.updateFile}
            setStep={this.setStep}
            step={this.state.step}
          />
        );

      case 3:
        return (
          <UploadModal3
            closeUploadProducts={this.props.closeUploadProducts}
            formData={this.state.formData}
            updateFormData={this.updateFormData}
            file={this.state.file}
            updateFile={this.updateFile}
            setStep={this.setStep}
            step={this.state.step}
          />
        );

      default:
        break;
    }
  };

  render() {
    return (
      <>
        <div
          className={
            this.props.uploadProducts
              ? "modal fade custom-modal custom-pop-up show"
              : "modal fade custom-modal"
          }
          id="uploadProduct"
          tabindex="-1"
          aria-labelledby="uploadProductLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content file-upload-modal">
              <ModalHeader
                handleClose={this.props.closeUploadProducts}
                setStep={this.setStep}
                step={this.state.step}
              />
              {this.loadStep(
                // this.props.setStep === 1 ? this.props.setStep :
                  this.state.step
              )}
            </div>
          </div>
        </div>
        <div
          className={
            this.props.uploadProducts ? "modal-backdrop fade show" : ""
          }
        ></div>
      </>
    );
  }
}

export default UploadBulkFile;
