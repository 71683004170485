import React, { Component } from "react";

import VerifyAccount from "./verifyAccount";
import ResetPassword from "./resetPasswordMobile";
import ResetPasswordEmail from "./resetPasswordEmail";

import ForgetPasswordCompleted from "./forgotPasswordCompleted";
import RequestMobileOtp from "./verify/mobileOtp";
import RequestEmailOtp from "./verify/emailOtp";

class ForgotPassword extends Component {
  state = {
    step: 1,
    verifyMobile: {},
    verifyEmail: {},
  };
  componentWillUnmount = () => {
    document.body.classList.remove("pt-0");
  };

  componentDidMount = () => {
    document.body.classList.add("pt-0");
  };

  updateStep = (step) => {
    this.setState({ step });
  };

  updateVerifyMobile = (verifyMobile) => {
    this.setState({ verifyMobile });
  };

  updateVerifyEmail = (verifyEmail) => {
    this.setState({ verifyEmail });
  };

  leftSideStep = () => {
    switch (this.state.step) {
      case 1:
        return (
          <VerifyAccount
          updateStep={this.updateStep}
          updateVerifyMobile={this.updateVerifyMobile}
          verifyMobile={this.state.verifyMobile}
          updateVerifyEmail={this.updateVerifyEmail}
          verifyEmail={this.state.verifyEmail}
          />
        );

      case 2:
        return (
          <RequestMobileOtp
            updateStep={this.updateStep}
            verifyMobile={this.state.verifyMobile}
            updateVerifyMobile={this.updateVerifyMobile}
          />
        );

      case 3:
        return (
          <RequestEmailOtp
            updateStep={this.updateStep}
            verifyEmail={this.state.verifyEmail}
            updateVerifyEmail={this.updateVerifyEmail}
          />
        );

      case 4:
        return (
          <ResetPassword
            updateStep={this.updateStep}
            updateVerifyMobile={this.updateVerifyMobile}
            verifyMobile={this.state.verifyMobile}
          />
        );

      case 5:
        return (
          <ResetPasswordEmail
            updateStep={this.updateStep}
            updateVerifyEmail={this.updateVerifyEmail}
            verifyEmail={this.state.verifyEmail}
          />
        );

      case 6:
        return <ForgetPasswordCompleted />;

      default:
        break;
    }
  };

  render() {
    return <div className="member-container">{this.leftSideStep()}</div>;
  }
}

export default ForgotPassword;
