import React, { Component } from "react";
import closeIcon from "./../../include/images/close.svg";
import homeIcon from "../../include/images/home-icon2.svg";
import catIcon from "../../include/images/categories-icon.svg";
import { getProduct } from "../../store/product";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
class CategoryMenu extends React.Component {
  render() {
    console.log("this.props.categories", this.props.match.params);
    return (
      <div
        class={
          this.props.mobileMenu
            ? "tab-modify-slidebar advance-modify-search-sidebar small-tab-modify-sidebar show"
            : "tab-modify-slidebar advance-modify-search-sidebar small-tab-modify-sidebar"
        }
        id="homeMenuSiderbar"
      >
        <div className={"new-sidemenu-box d-lg-flex align-items-lg-center"}>
          <div class="close d-block d-lg-none">
            <a href="#!" onClick={this.props.toggleMobileMenu}>
              <img src={closeIcon} alt="" />
            </a>
          </div>
          <div class="nsb-header">
            <Link to="/products-listing">
              <img src={homeIcon} alt="" />
              <span>Home</span>
            </Link>
          </div>
          <div class="nsb-box d-lg-flex align-items-lg-center">
            <div class="nsb-head d-block d-lg-none">
              <a href="#!">
                <img src={catIcon} alt="" />
                <b>All Categories</b>
              </a>
            </div>
            <div class="nsb-body">
              <ul class="nsb-menu-list d-lg-flex">
                {this.props.categories.map((e) => (
                  <li
                    class={
                      e.sub_categories && e.sub_categories.length > 0
                        ? "nsb-menu-item has-menu"
                        : "nsb-menu-item"
                    }
                  >
                    <Link
                      to={
                        "/products/" +
                        e.title.toLowerCase().split(" ").join("-")
                      }
                    >
                      {e.title}
                    </Link>
                    {e.sub_categories && e.sub_categories.length > 0 && (
                      <ul
                        class="sub-menu"
                        style={{
                          display: "block",
                        }}
                      >
                        {e.sub_categories.map((es) => (
                          <li
                            class={
                              es.sub_categories && es.sub_categories.length > 0
                                ? "sm-item has-inner-menu has-menu"
                                : "sm-item"
                            }
                          >
                            <Link
                              className={
                                this.props.match.params.cat2 ===
                                es.title.toLowerCase()
                                  ? "sm-link active"
                                  : "sm-link"
                              }
                              to={
                                "/products/" +
                                e.title.toLowerCase().split(" ").join("-") +
                                "/" +
                                es.title.toLowerCase().split(" ").join("-")
                              }
                            >
                              {es.title}
                            </Link>

                            {es.sub_categories && es.sub_categories.length > 0 && (
                              <ul
                                class="inner-sub-menu"
                                style={{
                                  display: "block",
                                }}
                              >
                                {es.sub_categories.map((ess) => (
                                  <li class="ism-item">
                                    <Link
                                      className={
                                        this.props.match.params.cat3 ===
                                        ess.title.toLowerCase()
                                          ? "ism-link active"
                                          : "ism-link"
                                      }
                                      to={
                                        "/products/" +
                                        e.title
                                          .toLowerCase()
                                          .split(" ")
                                          .join("-") +
                                        "/" +
                                        es.title
                                          .toLowerCase()
                                          .split(" ")
                                          .join("-") +
                                        "/" +
                                        ess.title
                                          .toLowerCase()
                                          .split(" ")
                                          .join("-")
                                      }
                                    >
                                      {ess.title}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  categories: getProduct(state).categories,
});
// const mapDispatchToProps = (dispatch) => ({
//   loadCart: (id) => dispatch(loadCart(id)),
//   loadCustomerProfile: () => dispatch(loadCustomerProfile()),
//   logoutCustomer: (callback) => dispatch(logoutCustomer(callback)),
//   loadCategories: () => dispatch(loadCategories()),
// });

export default withRouter(connect(mapStateToProps, null)(CategoryMenu));
