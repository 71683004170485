import React from "react";

const AlertSuccess = (props) => {
  return (
    <>
      <span className="alert-icon">
        <i className="fas fa-check-circle" aria-hidden="true"></i>
      </span>
      {props.message}
    </>
  );
};

export default AlertSuccess;
