import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";
import LinesEllipsis from "react-lines-ellipsis";
import { Link } from "react-router-dom";
import ProductGridB from "./productGridB";

export default class ProductListB extends Component {
  render() {
    return (
      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={this.props.loadFunc}
        hasMore={
          !this.props.productsLoading &&
          this.props.pagination.filtered_record !==
            this.props.pagination.total_record_on_current_page
        }
        loader={<div className="loader" key={0}></div>}
      >
        <ul
          className="grid-content d-flex  flex-wrap align-items-center"
          style={{ paddingTop: "20px" }}
        >
          {" "}
          {this.props.products.map((e) => (
            <ProductGridB
              seller_id={this.props.seller_id}
              data={e}
              setProductId={this.props.setProductId}
              productId={this.props.productId}
            />
          ))}
        </ul>
      </InfiniteScroll>
    );
  }
}
