import React, { Component } from "react";
import { withRouter } from "react-router";
import closeIcon from "./../../include/images/close.svg";
import deliverIcon from "./../../include/images/deliver-icon.svg";
import { Range } from "react-range";
import LinesEllipsis from "react-lines-ellipsis";
import _ from "lodash";
import { getProduct } from "../../store/product";
import { connect } from "react-redux";

class FilterMobile extends React.Component {
  state = {
    tab: "mode",
    priceMax: "",
    priceMin: "",
  };

  changeTab = (e, tab) => {
    this.setState({ tab });
    if (e) e.preventDefault();
  };

  render() {
    const { filters } = this.props;
    const { cat1, cat2, cat3 } = this.props.match.params;
    return (
      <div
        class={
          this.props.mobileFilter
            ? "tab-modify-slidebar filter-cat-box show"
            : "tab-modify-slidebar filter-cat-box"
        }
        id="filter-box"
      >
        <div class="tab-header d-flex justify-content-between">
          <a href="#!" class="close" onClick={this.props.toggleMobileFilter}>
            <img src={closeIcon} alt="" />
          </a>
        </div>
        <div class="mob-menu">
          <div class="mb-filter-head d-flex justify-content-between align-items-center">
            <div class="filter-text">Filters</div>
            <div class="filter-clear">
              <a href="#" onClick={this.props.clearFilter}>
                Clear all
              </a>
            </div>
          </div>
          <div class="mob-menu-body">
            <div class="mb-filter d-flex flex-wrap">
              <div class="mb-filter-left">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      class={
                        this.state.tab === "mode"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      id="tab-1"
                      data-toggle="tab"
                      href="#pane-1"
                      role="tab"
                      aria-controls="mode"
                      aria-selected="true"
                      onClick={(e) => this.changeTab(e, "mode")}
                    >
                      Mode
                    </a>
                  </li>
                  {(filters.mode === "delivery" || filters.mode === "") && (
                    <li class="nav-item" role="presentation">
                      <a
                        class={
                          this.state.tab === "delivery"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        id="tab-2"
                        data-toggle="tab"
                        href="#pane-2"
                        role="tab"
                        aria-controls="delivery"
                        aria-selected="false"
                        onClick={(e) => this.changeTab(e, "delivery")}
                      >
                        Delivery option
                      </a>
                    </li>
                  )}
                  {(filters.mode === "pickup" || filters.mode === "") && (
                    <li class="nav-item" role="presentation">
                      <a
                        class={
                          this.state.tab === "pickup"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        id="tab-3"
                        data-toggle="tab"
                        href="#pane-3"
                        role="tab"
                        aria-controls="pickup"
                        aria-selected="false"
                        onClick={(e) => this.changeTab(e, "pickup")}
                      >
                        Pickup
                      </a>
                    </li>
                  )}
                  {this.props.categories.length > 0 && (
                    <li class="nav-item" role="presentation">
                      <a
                        class={
                          this.state.tab === "category"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        id="tab-4"
                        data-toggle="tab"
                        href="#pane-4"
                        role="tab"
                        aria-controls="category"
                        aria-selected="false"
                        onClick={(e) => this.changeTab(e, "category")}
                      >
                        Categories
                      </a>
                    </li>
                  )}

                  {filters.category &&
                    this.props.categories.find(
                      (eds) => eds._id === filters.category
                    ) &&
                    this.props.categories.find(
                      (eds) => eds._id === filters.category
                    ).sub_categories.length > 0 && (
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.tab === "subcategory"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="tab-4"
                          data-toggle="tab"
                          href="#pane-4"
                          role="tab"
                          aria-controls="category"
                          aria-selected="false"
                          onClick={(e) => this.changeTab(e, "subcategory")}
                        >
                          Sub Categories
                        </a>
                      </li>
                    )}
                  {!this.props.match.params.seller_id && (
                    <li class="nav-item" role="presentation">
                      <a
                        class={
                          this.state.tab === "seller"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        id="tab-5"
                        data-toggle="tab"
                        href="#pane-5"
                        role="tab"
                        aria-controls="seller"
                        aria-selected="false"
                        onClick={(e) => this.changeTab(e, "seller")}
                      >
                        Sellers
                      </a>
                    </li>
                  )}
                  <li class="nav-item" role="presentation">
                    <a
                      class={
                        this.state.tab === "price"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      id="tab-6"
                      data-toggle="tab"
                      href="#pane-6"
                      role="tab"
                      aria-controls="price"
                      aria-selected="false"
                      onClick={(e) => this.changeTab(e, "price")}
                    >
                      Price
                    </a>
                  </li>

                  <li class="nav-item" role="presentation">
                    <a
                      class={
                        this.state.tab === "averageRating"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      id="tab-7"
                      data-toggle="tab"
                      href="#pane-7"
                      role="tab"
                      aria-controls="averageRating"
                      aria-selected="false"
                      onClick={(e) => this.changeTab(e, "averageRating")}
                    >
                      Average Ratings
                    </a>
                  </li>

                  <li class="nav-item" role="presentation">
                    <a
                      class={
                        this.state.tab === "numberOfReview"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      id="tab-8"
                      data-toggle="tab"
                      href="#pane-8"
                      role="tab"
                      aria-controls="berOfReview"
                      aria-selected="false"
                      onClick={(e) => this.changeTab(e, "numberOfReview")}
                    >
                      Number of Reviews
                    </a>
                  </li>
                </ul>
              </div>
              <div class="mb-filter-right">
                <div class="tab-content" id="myTabContent">
                  <div
                    class={
                      this.state.tab === "numberOfReview"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pane-8"
                    role="tabpane8"
                    aria-labelledby="tab-8"
                  >
                    <div class="filter-tab-content">
                      <div class="form-radio-group mt-0">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="numberOfReviewsMobile"
                            id="numberOfReviews6Mobile"
                            value="1000"
                            onChange={(e) =>
                              this.props.handleRadio({
                                target: {
                                  name: "numberOfReviews",
                                  value: "1000",
                                },
                              })
                            }
                            checked={filters.numberOfReviews == "1000"}
                          />
                          <label
                            class="form-check-label"
                            for="numberOfReviews6Mobile"
                          >
                            1000+
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="numberOfReviewsMobile"
                            id="numberOfReviews5Mobile"
                            value="500"
                            onChange={(e) =>
                              this.props.handleRadio({
                                target: {
                                  name: "numberOfReviews",
                                  value: "500",
                                },
                              })
                            }
                            checked={filters.numberOfReviews == "500"}
                          />
                          <label
                            class="form-check-label"
                            for="numberOfReviews5Mobile"
                          >
                            500+
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="numberOfReviewsMobile"
                            id="numberOfReviews4Mobile"
                            value="200"
                            onChange={(e) =>
                              this.props.handleRadio({
                                target: {
                                  name: "numberOfReviews",
                                  value: "200",
                                },
                              })
                            }
                            checked={filters.numberOfReviews == "200"}
                          />
                          <label
                            class="form-check-label"
                            for="numberOfReviews4Mobile"
                          >
                            200+
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="numberOfReviewsMobile"
                            id="numberOfReviews3Mobile"
                            value="100"
                            onChange={(e) =>
                              this.props.handleRadio({
                                target: {
                                  name: "numberOfReviews",
                                  value: "100",
                                },
                              })
                            }
                            checked={filters.numberOfReviews == "100"}
                          />
                          <label
                            class="form-check-label"
                            for="numberOfReviews3Mobile"
                          >
                            100+
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="numberOfReviewsMobile"
                            id="numberOfReviews2Mobile"
                            value="50"
                            onChange={(e) =>
                              this.props.handleRadio({
                                target: {
                                  name: "numberOfReviews",
                                  value: "50",
                                },
                              })
                            }
                            checked={filters.numberOfReviews == "50"}
                          />
                          <label
                            class="form-check-label"
                            for="numberOfReviews2Mobile"
                          >
                            50+
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="numberOfReviewsMobile"
                            id="numberOfReviews1Mobile"
                            value="25"
                            onChange={(e) =>
                              this.props.handleRadio({
                                target: {
                                  name: "numberOfReviews",
                                  value: "25",
                                },
                              })
                            }
                            checked={filters.numberOfReviews == "25"}
                          />
                          <label
                            class="form-check-label"
                            for="numberOfReviews1Mobile"
                          >
                            25+
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class={
                      this.state.tab === "averageRating"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pane-7"
                    role="tabpane7"
                    aria-labelledby="tab-7"
                  >
                    <div class="filter-tab-content">
                      <div class="form-radio-group mt-0">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="averageRatingMobile"
                            id="averageRating4Mobile"
                            value="4"
                            onChange={(e) =>
                              this.props.handleRadio({
                                target: { name: "averageRating", value: "4" },
                              })
                            }
                            checked={filters.averageRating == "4"}
                          />
                          <label
                            class="form-check-label"
                            for="averageRating4Mobile"
                          >
                            4 stars & up
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="averageRatingMobile"
                            id="averageRating3Mobile"
                            value="3"
                            onChange={(e) =>
                              this.props.handleRadio({
                                target: { name: "averageRating", value: "3" },
                              })
                            }
                            checked={filters.averageRating == "3"}
                          />
                          <label
                            class="form-check-label"
                            for="averageRating3Mobile"
                          >
                            3 stars & up
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="averageRatingMobile"
                            id="averageRating2Mobile"
                            value="2"
                            onChange={(e) =>
                              this.props.handleRadio({
                                target: { name: "averageRating", value: "2" },
                              })
                            }
                            checked={filters.averageRating == "2"}
                          />
                          <label
                            class="form-check-label"
                            for="averageRating2Mobile"
                          >
                            2 stars & up
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="averageRatingMobile"
                            id="averageRating1Mobile"
                            value="1"
                            onChange={(e) =>
                              this.props.handleRadio({
                                target: { name: "averageRating", value: "1" },
                              })
                            }
                            checked={filters.averageRating == "1"}
                          />
                          <label
                            class="form-check-label"
                            for="averageRating1Mobile"
                          >
                            1 star & up
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class={
                      this.state.tab === "mode"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pane-1"
                    role="tabpanel"
                    aria-labelledby="tab-1"
                  >
                    <div class="filter-tab-content">
                      <div class="form-radio-group mt-0">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="modeMobile"
                            id="mode1"
                            value=""
                            onChange={(e) =>
                              this.props.handleRadio({
                                target: { name: "mode", value: "" },
                              })
                            }
                            checked={!filters.mode}
                          />
                          <label class="form-check-label" for="mode1">
                            Pickup or Delivery
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="modeMobile"
                            id="mode2"
                            value="pickup"
                            onChange={(e) =>
                              this.props.handleRadio({
                                target: { name: "mode", value: "pickup" },
                              })
                            }
                            checked={filters.mode === "pickup"}
                          />
                          <label class="form-check-label" for="mode2">
                            Pickup only
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="modeMobile"
                            id="mode3"
                            value="delivery"
                            onChange={(e) =>
                              this.props.handleRadio({
                                target: { name: "mode", value: "delivery" },
                              })
                            }
                            checked={filters.mode === "delivery"}
                          />
                          <label class="form-check-label" for="mode3">
                            Delivery only
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class={
                      this.state.tab === "delivery"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pane-2"
                    role="tabpanel"
                    aria-labelledby="tab-2"
                  >
                    <div class="filter-tab-content">
                      <div class="form-radio-group mt-0">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="deliveryOptionMobile"
                            id="delivery-op1"
                            onChange={(e) =>
                              this.props.handleRadio({
                                target: {
                                  name: "deliveryOption",
                                  value: "today",
                                },
                              })
                            }
                            checked={filters.deliveryOption === "today"}
                          />
                          <label class="form-check-label" for="delivery-op1">
                            <span>
                              <img src={deliverIcon} alt="" />
                            </span>{" "}
                            Deliver Today *
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="deliveryOptionMobile"
                            id="delivery-op2"
                            onChange={(e) =>
                              this.props.handleRadio({
                                target: {
                                  name: "deliveryOption",
                                  value: "week",
                                },
                              })
                            }
                            checked={filters.deliveryOption === "week"}
                          />
                          <label class="form-check-label" for="delivery-op2">
                            Within a week
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="deliveryOptionMobile"
                            id="delivery-op3"
                            onChange={(e) =>
                              this.props.handleRadio({
                                target: {
                                  name: "deliveryOption",
                                  value: "more",
                                },
                              })
                            }
                            checked={filters.deliveryOption === "more"}
                          />
                          <label class="form-check-label" for="delivery-op3">
                            More than a week
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class={
                      this.state.tab === "pickup"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pane-3"
                    role="tabpanel"
                    aria-labelledby="tab-3"
                  >
                    <div class="filter-tab-content">
                      <div class="form-radio-group mt-0">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="radio"
                            id="pickup1"
                            onChange={(e) =>
                              this.props.handleRadio({
                                target: {
                                  name: "pickupOption",
                                  value: "1",
                                },
                              })
                            }
                            checked={filters.pickupOption === "1"}
                          />
                          <label class="form-check-label" for="pickup1">
                            Within 1 mile
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="radio"
                            id="pickup2"
                            onChange={(e) =>
                              this.props.handleRadio({
                                target: {
                                  name: "pickupOption",
                                  value: "3",
                                },
                              })
                            }
                            checked={filters.pickupOption === "3"}
                          />
                          <label class="form-check-label" for="pickup2">
                            Within 3 miles
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="radio"
                            id="pickup3"
                            onChange={(e) =>
                              this.props.handleRadio({
                                target: {
                                  name: "pickupOption",
                                  value: "5",
                                },
                              })
                            }
                            checked={filters.pickupOption === "5"}
                          />
                          <label class="form-check-label" for="pickup3">
                            Within 5 miles
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="radio"
                            id="pickup4"
                            onChange={(e) =>
                              this.props.handleRadio({
                                target: {
                                  name: "pickupOption",
                                  value: "10",
                                },
                              })
                            }
                            checked={filters.pickupOption === "10"}
                          />
                          <label class="form-check-label" for="pickup4">
                            Within 10 miles
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="radio"
                            id="pickup5"
                            onChange={(e) =>
                              this.props.handleRadio({
                                target: {
                                  name: "pickupOption",
                                  value: "30",
                                },
                              })
                            }
                            checked={filters.pickupOption === "30"}
                          />
                          <label class="form-check-label" for="pickup5">
                            Within 30 miles
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class={
                      this.state.tab === "category"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pane-4"
                    role="tabpanel"
                    aria-labelledby="tab-4"
                  >
                    <div class="filter-tab-content">
                      <div class="form-radio-group mt-0">
                        {this.props.categories.length > 0 && (
                          <>
                            {this.props.categories.map((ecat) => (
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  value={ecat._id}
                                  name="categorym"
                                  id={ecat._id + "mob"}
                                  onChange={(e) => {
                                    this.props.handleRadio({
                                      target: {
                                        name: "category",
                                        value: ecat._id,
                                      },
                                    });
                                  }}
                                  checked={filters.category === ecat._id}
                                />
                                <label
                                  class="form-check-label"
                                  for={ecat._id + "mob"}
                                >
                                  {ecat.title}
                                </label>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    class={
                      this.state.tab === "subcategory"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pane-4"
                    role="tabpanel"
                    aria-labelledby="tab-4"
                  >
                    <div class="filter-tab-content">
                      <div class="form-radio-group mt-0">
                        {this.props.categories.length > 0 && (
                          <>
                            {filters.category &&
                              this.props.categories.find(
                                (eds) => eds._id === filters.category
                              ) &&
                              this.props.categories.find(
                                (eds) => eds._id === filters.category
                              ).sub_categories.length > 0 &&
                              this.props.categories
                                .find((eds) => eds._id === filters.category)
                                .sub_categories.map((ecat) => (
                                  <div class="form-check">
                                    <input
                                      class="form-check-input"
                                      type="radio"
                                      value={ecat._id}
                                      name="subcategorym"
                                      id={ecat._id + "mob"}
                                      onChange={(e) => {
                                        this.props.handleRadio({
                                          target: {
                                            name: "sub_category",
                                            value: ecat._id,
                                          },
                                        });
                                      }}
                                      checked={
                                        filters.sub_category === ecat._id
                                      }
                                    />
                                    <label
                                      class="form-check-label"
                                      for={ecat._id + "mob"}
                                    >
                                      {ecat.title}
                                    </label>
                                  </div>
                                ))}
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    class={
                      this.state.tab === "seller"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pane-5"
                    role="tabpanel"
                    aria-labelledby="tab-5"
                  >
                    <div class="filter-tab-content">
                      <div class="form-check-group mt-0">
                        {this.props.sellers.map((es) => (
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value={es._id}
                              id={es._id + "mob"}
                              onChange={(e) =>
                                this.props.onChangeSeller(es._id)
                              }
                              checked={filters.sellers.find(
                                (ef) => ef === es._id
                              )}
                            />
                            <label
                              class="form-check-label"
                              for={es._id + "mob"}
                            >
                              {_.startCase(_.toLower(es.company))}
                            </label>{" "}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div
                    class={
                      this.state.tab === "price"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pane-6"
                    role="tabpanel"
                    aria-labelledby="tab-6"
                  >
                    <div class="filter-tab-content">
                      <div class="form-check-group mt-0">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value={filters.priceMax == 10 ? 0 : 10}
                            id={"priceMaxm"}
                            name="priceMax"
                            onChange={this.props.handleRadio}
                            checked={filters.priceMax == 10}
                          />
                          <label class="form-check-label" for={"priceMaxm"}>
                            Under $10
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value={0}
                            id={"prices0m"}
                            onChange={(e) => this.props.onChangePrices("0")}
                            checked={filters.prices.find((ef) => ef == "0")}
                          />
                          <label class="form-check-label" for={"prices0m"}>
                            $10 - $50
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value={1}
                            id={"prices1m"}
                            onChange={(e) => this.props.onChangePrices("1")}
                            checked={filters.prices.find((ef) => ef == "1")}
                          />
                          <label class="form-check-label" for={"prices1m"}>
                            $50 - $100
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value={2}
                            id={"prices2m"}
                            onChange={(e) => this.props.onChangePrices("2")}
                            checked={filters.prices.find((ef) => ef == "2")}
                          />
                          <label class="form-check-label" for={"prices2m"}>
                            $100 - $200
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value={filters.priceMin == 200 ? 0 : 200}
                            id={"priceMinm"}
                            name="priceMin"
                            onChange={this.props.handleRadio}
                            checked={filters.priceMin == 200}
                          />
                          <label class="form-check-label" for={"priceMinm"}>
                            Over $200
                          </label>
                        </div>
                        <div class=" mt-2 price-mm-box d-flex align-items-center justify-content-between">
                          <div class="input-group">
                            <input
                              min={0}
                              type="number"
                              class="form-control"
                              id="minValue"
                              placeholder={"Min"}
                              onChange={(e) =>
                                this.setState({ priceMin: e.target.value })
                              }
                              value={this.state.priceMin}
                            />
                          </div>

                          <div
                            style={{
                              margin: "0px 8px",
                            }}
                            class="input-group"
                          >
                            <input
                              //min={parseInt(this.state.priceMin)}
                              type="number"
                              class="form-control"
                              id="maxValue"
                              placeholder={"Max"}
                              onChange={(e) => {
                                this.setState({ priceMax: e.target.value });
                              }}
                              value={this.state.priceMax}
                            />
                          </div>
                          <div class="input-group">
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.changePriceMinMax(
                                  this.state.priceMin,
                                  this.state.priceMax
                                );
                                this.props.toggleMobileFilter();
                              }}
                              style={{ minWidth: "auto", margin: 0 }}
                              className="btn btn-default btn-sm"
                            >
                              Go
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div
                    class={
                      this.state.tab === "averageReview"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pane-7"
                    role="tabpanel"
                    aria-labelledby="tab-7"
                  >
                    <div class="filter-tab-content">
                      <div class="form-check-group mt-0">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            name="seller-name"
                            type="checkbox"
                            value=""
                            id="avg-review1"
                          />
                          <label class="form-check-label" for="avg-review1">
                            4 starts & up
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            name="seller-name"
                            type="checkbox"
                            value=""
                            id="avg-review2"
                          />
                          <label class="form-check-label" for="avg-review2">
                            3 starts & up
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            name="seller-name"
                            type="checkbox"
                            value=""
                            id="avg-review3"
                          />
                          <label class="form-check-label" for="avg-review3">
                            2 starts & up
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            name="seller-name"
                            type="checkbox"
                            value=""
                            id="avg-review4"
                          />
                          <label class="form-check-label" for="avg-review4">
                            2 starts & up
                          </label>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div
                    class={
                      this.state.tab === "noOfReview"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pane-8"
                    role="tabpanel"
                    aria-labelledby="tab-8"
                  >
                    <div class="filter-tab-content">
                      <div class="form-check-group mt-0">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            name="review"
                            type="checkbox"
                            value=""
                            id="review1"
                          />
                          <label class="form-check-label" for="review1">
                            1000+
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            name="review"
                            type="checkbox"
                            value=""
                            id="review2"
                          />
                          <label class="form-check-label" for="review2">
                            500+
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            name="review"
                            type="checkbox"
                            value=""
                            id="review3"
                          />
                          <label class="form-check-label" for="review3">
                            200+
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            name="review"
                            type="checkbox"
                            value=""
                            id="review4"
                          />
                          <label class="form-check-label" for="review4">
                            100+
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            name="review"
                            type="checkbox"
                            value=""
                            id="review5"
                          />
                          <label class="form-check-label" for="review5">
                            50+
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            name="review"
                            type="checkbox"
                            value=""
                            id="review6"
                          />
                          <label class="form-check-label" for="review6">
                            25+
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mob-filter-footer d-flex flex-wrap">
            <div
              class="footer-btn close"
              onClick={this.props.toggleMobileFilter}
            >
              <a href="#">Cancel</a>
            </div>
            <div
              class="footer-btn filter-btn-apply"
              onClick={this.props.toggleMobileFilter}
            >
              <a href="#">Apply</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productCategory: getProduct(state).productCategory,
  categories: getProduct(state).categories,
});
// const mapDispatchToProps = (dispatch) => ({
//   loadCart: (id) => dispatch(loadCart(id)),
//   loadCustomerProfile: () => dispatch(loadCustomerProfile()),
//   logoutCustomer: (callback) => dispatch(logoutCustomer(callback)),
//   loadCategories: () => dispatch(loadCategories()),
// });

export default withRouter(connect(mapStateToProps, null)(FilterMobile));
