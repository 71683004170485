import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class DateInput extends Component {
  state = {
    formclass: "form-control show",
  };

  handleFocus = () => {
    this.setState({ formclass: "form-control show" });
  };

  handleBlur = (e) => {
    const classname = e.target.value ? "form-control show" : "form-control";
    this.setState({ formclass: classname });
  };

  componentDidMount() {
    //this.initDatepicker();
    const classname = "form-control show";
    this.setState({ formclass: classname });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      const classname = this.props.value ? "form-control show" : "form-control";
      this.setState({ formclass: classname });
    }
  }

  //initDatepicker() {}
  render() {
    const { name, label, error, ...rest } = this.props;
    return (
      <>
        <div className="custom-form-box">
          <label className="label-placeholder" htmlFor={name}>
            {label}
          </label>
          <div className="form-group custom-input">
            <DatePicker
              yearItemNumber="10"
              // maxDate={new Date()}
              name={name}
              id={label}
              className={error ? "form-control" : this.state.formclass}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              dateFormat="yyyy-MM-dd"
              {...rest}
            />

            {error && <label className="error">{error}</label>}
          </div>
        </div>
      </>
    );
  }
}

export default DateInput;
