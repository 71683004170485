import React, { Component } from "react";
import SidebarCustomer from "../../common/customer/sideBarCustomer";
import { Link } from "react-router-dom";
import SubmitBtn from "../../common/form/submitBtn";

import checkImg from "../../../include/images/tick-bubble.svg";

class CustomerVerified extends Component {
  componentWillUnmount = () => {
    document.body.classList.remove("pt-0");
  };

  componentDidMount = () => {
    document.body.classList.add("pt-0");
  };
  render() {
    return (
      <div className="member-container">
        <SidebarCustomer />
        <div className="member-right">
          <div className="signin-form-container">
            <div className="signin-form">
              <div className="mr-box text-center">
                <div className="mr-icon">
                  <img src={checkImg} alt="" />
                </div>
                <h2>Thank you for signing up with us</h2>
                {/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipising elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p> */}
                <div className="form-button">
                  <Link to="/products-listing">
                    <SubmitBtn
                      label="Continue Shopping"
                      btnClass="btn btn-default"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerVerified;
