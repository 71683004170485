import React from "react";
import Form from "./form/form";
import close from "../../include/images/close.svg";
import Joi from "joi-browser";
import _ from "lodash";

import Moment from "moment";

import { loadOrders } from "../../store/seller";
import { connect } from "react-redux";

class AdvanceSearch extends Form {
  state = {
    data: {
      order: "",
      from: "",
      email: "",
      phone: "",
      status: "",
      delivery: "",
      startDate: "",
      endDate: "",
    },
    errors: {},
  };

  schema = {
    order: Joi.string().allow("").optional(),
    from: Joi.string().allow("").optional(),
    email: Joi.string().allow("").optional(),
    phone: Joi.string().allow("").optional(),
    status: Joi.string().allow("").optional(),
    delivery: Joi.string().allow("").optional(),
    endDate: Joi.date().allow("").optional(),
    startDate: Joi.date().allow("").optional(),
  };

  doSubmit = () => {
    const { order, from, email, status, delivery, endDate, startDate } =
      this.state.data;

    const fStartDate = startDate === "" ? "" : Moment(startDate).format("yyyy-MM-DD");
    const fEndDate =endDate ==="" ? "" : Moment(endDate).format("yyyy-MM-DD");

    let params = {
      order_id: order,
      from,
      email,
      status,
      delivery_type: delivery,
      startDate: fStartDate,
      endDate: fEndDate,
    };
    const searchParams = _.omitBy(
      params,
      (v) => _.isUndefined(v) || _.isNull(v) || v === ""
    );
    this.props.applyAdvanceSearch(searchParams);
    this.props.closeSearch();
  };

  render() {
    return (
      <>
        <div
          className={
            this.props.isAdvanceSearch
              ? "tab-modify-slidebar advance-modify-search-sidebar show"
              : "tab-modify-slidebar advance-modify-search-sidebar"
          }
          id="advancedSearch"
        >
          <div className="tab-modify-box">
            <div className="tab-header d-flex justify-content-between">
              <h3>Advanced Search</h3>
              <a
                href="javascript:void(0)"
                onClick={this.props.closeSearch}
                className="close"
              >
                <img src={close} alt="" />
              </a>
            </div>
            <form>
              <div className="qps-group  ams-group d-flex">
                <div className="form-group price ">
                  {this.renderInput("order", "Order")}
                </div>
                <div className="form-group price mr-0">
                  {this.renderInput("from", "From")}
                </div>
              </div>
              <div className="qps-group  ams-group d-flex">
                <div className="form-group price ">
                  {this.renderInput("email", "Email")}
                </div>
                <div className="form-group price mr-0">
                  {this.renderInput("phone", "Phone")}
                </div>
              </div>
              <div className="qps-group  ams-group d-flex">
                <div className="form-group price">
                  {this.renderSelect("status", "Status", [
                    {
                      id: "",
                      name: "Select",
                    },
                    {
                      id: "pending",
                      name: "Pending",
                    },
                    { id: "fulfilled", name: "Fulfilled" },
                  ])}
                </div>
                <div className="form-group price mr-0">
                  {this.renderSelect("delivery", "Delivery option", [
                    {
                      id: "",
                      name: "Select",
                    },
                    {
                      id: "delivery",
                      name: "Delivery",
                    },
                    { id: "pickup", name: "Pick Up" },
                  ])}
                </div>
              </div>
              <div className="qps-group  ams-group d-flex">
                <div className="form-group price ">
                  {this.renderDateInput("startDate", "Start Date")}
                </div>
                <div className="form-group price mr-0">
                  {this.renderDateInput("endDate", "End Date")}
                </div>
              </div>
              <div className="qps-group  ams-group d-flex">
                <div className="form-group search_price ">
                  <a
                    href="javascript:void(0)"
                    onClick={this.doSubmit}
                    className="btn btn-default btn-block"
                  >
                    Search
                  </a>
                  {/* <SubmitBtn
                    label={"Search"}
                    btnClass={"btn btn-default btn-block"}
                  />{" "} */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadOrders: (params) => dispatch(loadOrders(params)),
});
export default connect(null, mapDispatchToProps)(AdvanceSearch);
