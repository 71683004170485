import React, { createRef } from "react";
import Form from "../form/form";
import close from "../../../include/images/close.svg";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Joi from "joi-browser";
import LoadingBar from "react-top-loading-bar";

import FetchBtn from "../../common/form/fetchBtn";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  loadCustomerProfile,
  getCustomer,
  updateAddress,
} from "../../../store/customer";

import {
  Address1Required,
  cityRequired,
  stateRequired,
  zipRequired,
} from "../../common/misc";
import { zipValidation, addressValidation } from "../../../store/seller";
import AlertSuccess from "../../common/alertSuccess";
import AlertError from "../../common/alertError";
import { toast } from "react-toastify";
import SubmitBtn from "../form/submitBtn";

const searchOptions = {
  types: ["address"],
  componentRestrictions: { country: "us" },
};

class EditAddress extends Form {
  ref = createRef(null);

  state = {
    labelFetch: "Fetch city & state",
    zipLoader: false,
    loader: false,
    labelRegister: "Save Changes",
    // btnClass: "sidebar-default-btn",
    btnClass: "btn btn-default",
    formatted_address: "",
    add: {
      street1: "",
      street2: "",
      zip: "",
      city: "",
      state: "",
      country: "",
      latitude: "",
      longitude: "",
    },
    // data: {
    //   // street1: "",
    //   // street2: "",
    //   // zip: "",
    //   // city: "",
    //   // state: "",
    // },
    errors: {},
  };

  handleChange1 = (address) => {
    this.setState({ formatted_address: address });
  };

  handleSelect = (address) => {
    this.setState({ formatted_address: address });
    geocodeByAddress(address)
      .then((results) => {
        console.log("result", results);
        const zipCode = results[0].address_components.find(
          (ra) => ra.types[0] === "postal_code"
        );
        var street1;
        if (
          results[0].address_components.find(
            (ra) => ra.types[0] === "street_number"
          ) &&
          results[0].address_components.find(
            (ra) => ra.types[0] === "street_number"
          ).long_name
        ) {
          street1 = `${
            results[0].address_components.find(
              (ra) => ra.types[0] === "street_number"
            ).long_name
          } ${
            results[0].address_components.find((ra) => ra.types[0] === "route")
              .long_name
          }`;
        } else {
          street1 = results[0].address_components.find(
            (ra) => ra.types[0] === "route"
          ).long_name;
        }
        const street2 = results[0].address_components.find(
          (ra) => ra.types[0] === "administrative_area_level_2"
        );
        const city = results[0].address_components.find(
          (ra) => ra.types[0] === "locality"
        );
        const state = results[0].address_components.find(
          (ra) => ra.types[0] === "administrative_area_level_1"
        );
        const country = results[0].address_components.find(
          (ra) => ra.types[0] === "country"
        );
        const latitude = results[0].geometry.location.lat();
        const longitude = results[0].geometry.location.lng();
        const formatted_address = results[0].formatted_address;
        this.setState({
          add: {
            zip: zipCode && zipCode.long_name,
            street1,
            street2: street2 && street2.long_name,
            city: city && city.long_name,
            state: state && state.short_name,
            country: country && country.short_name,
            latitude,
            longitude,
          },
          formatted_address,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  schema = {
    // street1: Joi.string()
    //   .required()
    //   .error(() => {
    //     return { message: Address1Required };
    //   }),
    // street2: Joi.string().allow("").optional(),
    // city: Joi.string()
    //   .required()
    //   .error(() => {
    //     return { message: cityRequired };
    //   }),
    // state: Joi.string()
    //   .required()
    //   .error(() => {
    //     return { message: stateRequired };
    //   }),
    // zip: Joi.number()
    //   .required()
    //   .label("Zip Code")
    //   .error(() => {
    //     return { message: zipRequired };
    //   }),
  };

  componentDidMount = () => {
    this.props.loadCustomerProfile();
    this.ref.current.continuousStart();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.profileLoading &&
      this.props.profileLoading !== prevProps.profileLoading
    ) {
      this.ref.current.complete();

      const { billing_address, shipping_address } = this.props.customerProfile;
      const { add } = this.state;
      if (billing_address !== null && this.props.editBillingAddress === true) {
        add.street1 = billing_address.address_line1;
        add.street2 = billing_address.address_line2;
        add.zip = billing_address.zipcode;
        add.city = billing_address.city;
        add.state = billing_address.state;
        add.country = billing_address.country;
      } else if (
        shipping_address !== null &&
        this.props.editShippingAddress === true
      ) {
        add.street1 = shipping_address.address_line1;
        add.street2 = shipping_address.address_line2;
        add.zip = shipping_address.zipcode;
        add.city = shipping_address.city;
        add.state = shipping_address.state;
        add.country = shipping_address.country;
      } else {
        add.street1 = this.state.add.street1;
        add.street2 = this.state.add.street2;
        add.zip = this.state.add.zip;
        add.city = this.state.add.city;
        add.state = this.state.add.state;
        add.country = this.state.add.country;
      }
      this.setState({ add });
    }
  };

  doSubmit = () => {
    if (!this.state.loader) {
      this.setState({
        labelRegister: "",
        loader: true,
        btnClass: "btn btn-default disabled",
        // btnClass: "sidebar-default-btn disabled",
      });

      // const addressParams = {
      //   street1: this.state.add.street1,
      //   street2: this.state.add.street2,
      //   zip: this.state.add.zip,
      //   city: this.state.add.city,
      //   state: this.state.add.state,
      //   country: this.state.add.country,
      //   latitude: this.state.add.latitude,
      //   longitude: this.state.add.longitude,
      // };
      // this.props.addressValidation(addressParams, this.addressCallback);
      if (this.props.editShippingAddress === true) {
        const updateAddressParams = {
          shipping_address: {
            address_line1: this.state.add.street1,
            address_line2: this.state.add.street2,
            zipcode: this.state.add.zip,
            city: this.state.add.city,
            state: this.state.add.state,
            latitude: this.state.add.latitude,
            longitude: this.state.add.longitude,
          },
        };
        this.props.updateAddress(
          updateAddressParams,
          this.callbackUpdateAddress
        );
      } else {
        const updateAddressParams = {
          billing_address: {
            address_line1: this.state.add.street1,
            address_line2: this.state.add.street2,
            zipcode: this.state.add.zip,
            city: this.state.add.city,
            state: this.state.add.state,
            latitude: this.state.add.latitude,
            longitude: this.state.add.longitude,
          },
        };
        this.props.updateAddress(
          updateAddressParams,
          this.callbackUpdateAddress
        );
      }
    }
  };

  addressCallback = (res) => {
    if (res.status === 200) {
      if (this.props.editShippingAddress === true) {
        const updateAddressParams = {
          shipping_address: {
            address_line1: this.state.add.street1,
            address_line2: this.state.add.street2,
            zipcode: this.state.add.zip,
            city: this.state.add.city,
            state: this.state.add.state,
          },
        };
        this.props.updateAddress(
          updateAddressParams,
          this.callbackUpdateAddress
        );
      } else {
        const updateAddressParams = {
          billing_address: {
            address_line1: this.state.add.street1,
            address_line2: this.state.add.street2,
            zipcode: this.state.add.zip,
            city: this.state.add.city,
            state: this.state.add.state,
          },
        };
        this.props.updateAddress(
          updateAddressParams,
          this.callbackUpdateAddress
        );
      }
      this.props.handleClose();
    } else {
      this.setState({
        loader: false,
        labelRegister: "Save Changes",
        btnClass: "btn btn-default",
        // btnClass: "sidebar-default-btn",
      });
      toast(
        <AlertError message="Invalid address. Please enter your correct address." />
      );
    }
  };

  callbackUpdateAddress = (res) => {
    this.setState({
      loader: false,
      labelRegister: "Save Changes",
      btnClass: "btn btn-default",
    });
    if (res.status === 200) {
      this.props.handleClose();
      this.props.loadCustomerProfile();
      toast(<AlertSuccess message={"Address Updated Successfully"} />);
    } else {
      toast(
        <AlertError message={"Address Updation failed. Please try again."} />
      );
    }
  };

  zipCallback = (res) => {
    if (res.status === 200) {
      this.setState({
        labelFetch: "Fetch city & state",
        zipLoader: false,
        btnClass: "btn btn-default",
      });
      const { data, errors } = this.state;
      data.city = res.data.data.city;
      data.state = res.data.data.state;
      errors.city = "";
      errors.state = "";
      this.setState({ data, errors });
    } else {
      this.setState({
        labelFetch: "Fetch city & state",
        zipLoader: false,
        btnClass: "btn btn-default",
      });

      toast(<AlertError message={res.data.error.Description} />);
    }
  };

  doSubmitZip = () => {
    if (!this.state.zipLoader) {
      this.setState({
        labelFetch: "",
        zipLoader: true,
        btnClass: "btn btn-default disabled",
        // btnClass: "sidebar-default-btn disabled",
      });
    }
    const params = {
      zip: this.state.data.zip,
    };
    this.props.zipValidation(params, this.zipCallback);
  };

  render() {
    console.log("errors", this.state.errors);
    return (
      <>
        <LoadingBar color="#0b4474" ref={this.ref} />
        {!this.props.profileLoading && (
          <>
            <div className="tab-modify-slidebar tab-modify-slidebar2">
              <div className="tab-modify-box">
                <form onSubmit={this.handleSubmit}>
                  <div className="tab-header">
                    <h3>
                      {this.props.editShippingAddress === true
                        ? "Delivery Address"
                        : "Billing Address"}
                    </h3>
                    <a
                      href="javascript:void(0)"
                      onClick={this.props.handleClose}
                      className="close"
                    >
                      <img src={close} alt="" />
                    </a>

                    <div className="row">
                      <div className="col-lg-12">
                        <PlacesAutocomplete
                          value={this.state.formatted_address}
                          onChange={this.handleChange1}
                          onSelect={this.handleSelect}
                          searchOptions={searchOptions}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <div className="form-group not-empty">
                                <label>Address</label>
                                <input
                                  onChange={this.handleChange}
                                  value={this.state.formatted_address}
                                  type="text"
                                  class="form-control"
                                  aria-label=""
                                  aria-describedby="basic-addon1"
                                  {...getInputProps({
                                    placeholder: "Enter your address",
                                    className: "form-control",
                                  })}
                                />
                              </div>
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        {/* {this.renderInput("street1", "Address line 1")} */}
                      </div>

                      {/* <div className="col-lg-6">
                        {this.renderAddress2("street2", "Address line 2")}
                      </div> */}
                    </div>
                    {/* <div className="row">
                      <div className="col-md-6">
                        {this.renderInput("zip", "Zip Code")}
                      </div>

                      <div className="col-lg-6 d-lg-flex align-items-lg-center justify-content-lg-center">
                        <FetchBtn
                          disabled={
                            this.state.data.zip.length !== 5 ? "true" : ""
                          }
                          onClickHandle={this.handleSubmitZip}
                          loading={this.state.zipLoader}
                          label={this.state.labelFetch}
                          btnClass="btn fetch-btn"
                        />
                      </div>
                    </div> */}

                    {/* <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          {this.renderInputCityState("city", "City")}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          {this.renderInputCityState("state", "State")}
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="form-group sidebar-btn">
                    {/* <a href="#!" className="sidebar-default-btn">
                Add
              </a> */}
                    <SubmitBtn
                      label={this.state.labelRegister}
                      loading={this.state.loader}
                      btnClass={this.state.btnClass}
                    />
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customerProfile: getCustomer(state).profile,
  profileLoading: getCustomer(state).profileLoading,
});

const mapDispatchToProps = (dispatch) => ({
  updateAddress: (data, callback) => dispatch(updateAddress(data, callback)),
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
  zipValidation: (data, callback) => dispatch(zipValidation(data, callback)),

  addressValidation: (data, callback) =>
    dispatch(addressValidation(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditAddress)
);
