import React from "react";
import Joi from "joi-browser";

import closeIcon from "../../../include/images/close.svg";
import deleteIcon from "../../../include/images/delete1.svg";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Moment from "moment";

import {
  returnRequest,
  loadOrders,
  loadCustomerProfile,
  uploadReturnPictures,
  getCustomer,
} from "../../../store/customer";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Form from "../../common/form/form";
import seUploadFun from "../../services/s3Services";

import FileInput from "../form/fileInput";
import SubmitBtn from "../form/submitBtn";

import { toast } from "react-toastify";
import AlertError from "../../common/alertError";
import AlertSuccess from "../../common/alertSuccess";

class ReturnRequest extends Form {
  state = {
    data: { notes: "", pics: [] },
    photoStatus: "",
    errors: {},
    isOpen: false,
  };

  schema = {
    notes: Joi.string()
      .required()
      .error(() => {
        return {
          message: "Required",
        };
      }),
    pics: Joi.array().items(Joi.object().optional()).optional(),
  };

  remoVeImg = (e, i) => {
    e.preventDefault();
    const data = this.state.data;
    const pics = data.pics;
    data.pics = pics.filter((p) => p !== i);
    this.setState({ data });
  };

  fileUpload = (e) => {
    this.setState({ photoStatus: "Please wait..." });
    const files = e.target.files;
    for (var i = 0; i < files.length; i++) {
      const element = files[i];
      let formData = new FormData();
      formData.append("image", element);
      this.props.uploadReturnPictures(formData, this.imageCallback);
    }
  };

  imageCallback = (res) => {
    if (res && res.status === 200) {
      const { data, errors } = this.state;
      errors.photo = "";
      data.pics.push(this.props.returnPhoto.urls);
      this.setState({
        data,
        errors,
        photoStatus: "",
      });
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  doSubmit = () => {
    const { notes, pics } = this.state.data;
    const { ep, eo } = this.props;

    const data = {
      order: eo._id,
      product: ep.product_id,
      notes: notes,
      images: pics,
    };
    this.props.returnRequest(data, this.callback);
  };

  callback = (res) => {
    if (res && res.status === 200) {
      this.props.setReturnProductId({
        returnProduct: "",
        returnOrderId: "",
      });
      this.props.loadOrders();
      this.props.loadCustomerProfile();
      toast(<AlertSuccess message={"Return Requested"} />);
    } else {
      toast(<AlertError message={"Failed"} />);
    }
  };

  render() {
    const { ep, eo } = this.props;
    const { photoIndex, isOpen } = this.state;

    return (
      this.props.returnProduct === ep.product_id &&
      this.props.returnOrderId === eo.order_id && (
        <div
          className={
            this.props.returnProduct === ep.product_id &&
            this.props.returnOrderId === eo.order_id
              ? "dropdown-menu show"
              : "dropdown-menu"
          }
          aria-labelledby={ep.product_id && eo.order_id}
        >
          {ep.returnRequest && Object.entries(ep.returnRequest).length !== 0 ? (
            <>
              <div
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  this.props.setReturnProductId(ep.product_id, eo.order_id);
                }}
                className="dm-header-text d-flex align-items-center justify-content-between"
              >
                <span>Return Requested</span>
                <div
                  className="close-icon"
                  onClick={() => {
                    this.props.setReturnProductId({
                      returnProduct: "",
                      returnOrderId: "",
                    });
                  }}
                >
                  <img src={closeIcon} alt="" />
                </div>
              </div>
              
              <div className="form-group-box">
                {ep.returnRequest && (
                  <>
                    <p>{ep.returnRequest.notes}</p>
                    <ul className="tpi-list custom-img d-flex flex-wrap">
                      {ep.returnRequest.images &&
                        ep.returnRequest.images.map((img) => (
                          <>
                            <li>
                              <img
                                onClick={() =>
                                  this.setState({
                                    isOpen: true,
                                  })
                                }
                                src={
                                  img && process.env.REACT_APP_S3URL + img.small
                                }
                                alt=""
                              />
                            </li>
                            {isOpen && (
                              <Lightbox
                                mainSrc={
                                  img
                                    ? process.env.REACT_APP_S3URL + img.original
                                    : ""
                                }
                                onCloseRequest={() =>
                                  this.setState({
                                    isOpen: false,
                                  })
                                }
                                onMovePrevRequest={() =>
                                  this.setState({
                                    photoIndex: photoIndex - 1,
                                  })
                                }
                                onMoveNextRequest={() =>
                                  this.setState({
                                    photoIndex: photoIndex + 1,
                                  })
                                }
                              />
                            )}
                          </>
                        ))}
                    </ul>
                    <p>
                      <span>{Moment(ep.returnRequest.date).format("LL")}</span>
                    </p>
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  this.props.setReturnProductId(ep.product_id, eo.order_id);
                }}
                className="dm-header-text d-flex align-items-center justify-content-between"
              >
                <span>Request Return</span>
                <div
                  className="close-icon"
                  onClick={() => {
                    this.props.setReturnProductId({
                      returnProduct: "",
                      returnOrderId: "",
                    });
                  }}
                >
                  <img src={closeIcon} alt="" />
                </div>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  {this.renderText("notes", "Notes")}
                </div>

                <div className="form-group custom-upload">
                  <FileInput
                    totalUploaded={this.state.data.pics.length}
                    flag="files"
                    error={this.state.errors["pics"]}
                    label="Upload Image"
                    name="pics"
                    status={this.state.photoStatus}
                    onchange={this.fileUpload}
                    text={this.state.data["pics"]}
                  />
                  <span className="note">Max file size allowed 1 MB</span>
                </div>
                <ul className="delete-img-container d-flex">
                  {this.state.data.pics.map((e, i) => (
                    <li key={i}>
                      <div className="delete-img-box">
                        <img
                          width="180px"
                          src={e ? process.env.REACT_APP_S3URL + e.small : ""}
                          alt="img"
                        />
                        <div
                          className="delete-img"
                          onClick={(ele) => this.remoVeImg(ele, e)}
                        >
                          <img src={deleteIcon} alt="" />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="form-group">
                  <SubmitBtn
                    label="Submit"
                    loading={this.state.loading}
                    btnClass="btn btn-lg btn-default dispute-btn"
                  />
                </div>
              </form>
            </>
          )}
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  returnPhoto: getCustomer(state).returnPhoto,
});

const mapDispatchToProps = (dispatch) => ({
  loadOrders: (params) => dispatch(loadOrders(params)),
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
  uploadReturnPictures: (data, callback) =>
    dispatch(uploadReturnPictures(data, callback)),
  returnRequest: (data, callback) => dispatch(returnRequest(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReturnRequest)
);
