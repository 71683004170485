import React, { Component } from "react";
import closeIcon from "../../../include/images/close.svg";

import { logoutCustomer } from "../../../store/customer";

import { toast } from "react-toastify";
import AlertSuccess from "../../common/alertSuccess";
import AlertError from "../../common/alertError";

import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

class CustomerSidebar extends Component {
  logout = (e) => {
    e.preventDefault();
    this.props.logoutCustomer(this.callback);
  };

  callback = (res) => {
    if (res.status === 200) {
      window.localStorage.removeItem("x-auth-token-customer");
      window.localStorage.removeItem("customer-name");
      window.localStorage.removeItem("x-refresh-token-customer");
      window.localStorage.removeItem("redirectUrl");
      localStorage.removeItem("customer_information");
      window.localStorage.removeItem("customerDddress");
      window.location.href = "/";
    } else {
      toast(<AlertError message={"Failed"} />);
    }
  };

  render() {
    return (
      <>
        <div className="sidebar">
          <div className="sidebar-menu">
            <h3>My Account</h3>
            <a href="javascript:void(0)" className="close-menu">
              <img src={closeIcon} alt="" />
            </a>
            <ul className="menu-list">
              <li
                className={
                  window.location.pathname === "/customer/account-details"
                    ? "menu-item active"
                    : "menu-item"
                }
              >
                <Link to="/customer/account-details" className="menu-link">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7.00006 0C5.21883 0 3.76929 1.44954 3.76929 3.23077C3.76929 5.012 5.21883 6.46154 7.00006 6.46154C8.78129 6.46154 10.2308 5.012 10.2308 3.23077C10.2308 1.44954 8.78129 0 7.00006 0ZM7.00006 5.38461C5.81221 5.38461 4.84621 4.41862 4.84621 3.23077C4.84621 2.04292 5.81221 1.07692 7.00006 1.07692C8.18791 1.07692 9.15391 2.04292 9.15391 3.23077C9.15391 4.41862 8.18791 5.38461 7.00006 5.38461Z" />
                    <path d="M13.4573 10.2083C13.4552 10.1696 13.4444 10.134 13.4347 10.0974C13.4261 10.0662 13.4218 10.0339 13.4078 10.0048C13.3949 9.97573 13.3733 9.95204 13.354 9.92512C13.3313 9.89389 13.312 9.86266 13.2829 9.83681C13.2764 9.83143 13.2743 9.82389 13.2678 9.8185C9.63534 6.77404 4.36165 6.77404 0.72919 9.8185C0.722728 9.82389 0.720574 9.83143 0.714113 9.83681C0.686113 9.86266 0.665651 9.89389 0.643036 9.92619C0.624728 9.95312 0.60319 9.97681 0.590266 10.0048C0.576267 10.0339 0.571959 10.0662 0.563343 10.0974C0.553651 10.134 0.542882 10.1696 0.540728 10.2083C0.542882 10.2159 0.538574 10.2223 0.538574 10.231V12.9233C0.538574 13.5167 1.02211 14.0002 1.6155 14.0002H12.3847C12.9781 14.0002 13.4617 13.5167 13.4617 12.9233V10.231C13.4617 10.2223 13.4573 10.2159 13.4573 10.2083ZM12.3847 12.9233H1.6155V10.497C4.77519 7.99312 9.22504 7.99312 12.3847 10.497V12.9233Z" />
                  </svg>
                  Account Details
                </Link>
              </li>
              <li
                className={
                  window.location.pathname === "/customer/order-history"
                    ? "menu-item active"
                    : "menu-item"
                }
              >
                <Link to="/customer/order-history" className="menu-link">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12.9841 12.9553L12.1863 4.17656C12.1638 3.93469 11.9613 3.75 11.7184 3.75H10.3122V2.8125C10.3122 2.05875 10.0206 1.35094 9.49094 0.82125C8.96125 0.291562 8.25344 0 7.49969 0C5.94907 0 4.68719 1.26187 4.68719 2.8125V3.75H3.28094C3.03813 3.75 2.83563 3.93469 2.81407 4.17656L2.01625 12.9553C1.96844 13.4784 2.14469 14.0016 2.49907 14.3897C2.85344 14.7778 3.35782 15 3.88375 15H11.1166C11.6425 15 12.1469 14.7778 12.5013 14.3897C12.8556 14.0016 13.0309 13.4784 12.9841 12.9553ZM5.62469 2.8125C5.62469 1.77844 6.46563 0.9375 7.49969 0.9375C8.00313 0.9375 8.47469 1.13156 8.82813 1.48406C9.18157 1.83656 9.37469 2.30906 9.37469 2.8125V3.75H5.62469V2.8125ZM11.8084 13.7578C11.6284 13.9538 11.3828 14.0625 11.1156 14.0625H3.88375C3.6175 14.0625 3.37094 13.9537 3.19188 13.7569C3.01188 13.56 2.92657 13.3059 2.95094 13.0397L3.70938 4.6875H4.68719V6.09375C4.68719 6.3525 4.89719 6.5625 5.15594 6.5625C5.41469 6.5625 5.62469 6.3525 5.62469 6.09375V4.6875H9.37469V6.09375C9.37469 6.3525 9.58469 6.5625 9.84344 6.5625C10.1022 6.5625 10.3122 6.3525 10.3122 6.09375V4.6875H11.29L12.0494 13.0406C12.0738 13.3059 11.9884 13.5609 11.8084 13.7578Z" />
                  </svg>
                  Order History
                </Link>
              </li>
              <li
                className={
                  window.location.pathname === "/customer/wishlist"
                    ? "menu-item active"
                    : "menu-item"
                }
              >
                <Link to="/customer/wishlist" className="menu-link">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7.5 14.3652C7.21252 14.3652 6.93878 14.2514 6.74 14.0515C6.18439 13.6252 0 8.79524 0 4.74461C0 2.46769 1.85623 0.615234 4.1375 0.615234C5.48996 0.615234 6.73496 1.26961 7.5 2.33585C8.26504 1.26961 9.51004 0.615234 10.8625 0.615234C13.1438 0.615234 15 2.46769 15 4.74461C15 8.79524 8.81561 13.6252 8.26 14.0515C8.06122 14.2514 7.78748 14.3652 7.5 14.3652ZM4.1375 1.55273C2.37316 1.55273 0.9375 2.98462 0.9375 4.74461C0.9375 8.43464 7.27318 13.279 7.33692 13.3278C7.36061 13.3452 7.38247 13.3659 7.40192 13.3878C7.44873 13.4409 7.55001 13.4409 7.59693 13.3878C7.61684 13.3659 7.6387 13.3459 7.66182 13.3278C7.72682 13.279 14.0625 8.43143 14.0625 4.74461C14.0625 2.98462 12.6268 1.55273 10.8625 1.55273C9.58626 1.55273 8.43567 2.30461 7.92995 3.4671C7.7813 3.80894 7.2187 3.80894 7.07005 3.4671C6.56433 2.30461 5.41317 1.55273 4.1375 1.55273Z" />
                  </svg>
                  My Wishlist
                </Link>
              </li>
              <li
                className={
                  window.location.pathname === "/customer/addresses"
                    ? "menu-item active"
                    : "menu-item"
                }
              >
                <Link to="/customer/addresses" className="menu-link">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7.5 0C4.39781 0 1.875 2.52281 1.875 5.625C1.875 9.52406 6.9525 14.6466 7.16813 14.8631C7.26 14.9541 7.38 15 7.5 15C7.62 15 7.74 14.9541 7.83187 14.8631C8.0475 14.6466 13.125 9.52406 13.125 5.625C13.125 2.52281 10.6022 0 7.5 0ZM7.5 13.8534C6.38062 12.6553 2.8125 8.60344 2.8125 5.625C2.8125 3.04031 4.91531 0.9375 7.5 0.9375C10.0847 0.9375 12.1875 3.04031 12.1875 5.625C12.1875 8.60063 8.61938 12.6553 7.5 13.8534Z" />
                    <path d="M7.5 2.8125C5.94937 2.8125 4.6875 4.07437 4.6875 5.625C4.6875 7.17562 5.94937 8.4375 7.5 8.4375C9.05062 8.4375 10.3125 7.17562 10.3125 5.625C10.3125 4.07437 9.05062 2.8125 7.5 2.8125ZM7.5 7.5C6.46594 7.5 5.625 6.65906 5.625 5.625C5.625 4.59094 6.46594 3.75 7.5 3.75C8.53406 3.75 9.375 4.59094 9.375 5.625C9.375 6.65906 8.53406 7.5 7.5 7.5Z" />
                  </svg>
                  My Addresses
                </Link>
              </li>
              <li
                className={
                  window.location.pathname === "/customer/card-details"
                    ? "menu-item active"
                    : "menu-item"
                }
              >
                <Link to="/customer/card-details" className="menu-link">
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M17.0208 3.16699H1.97915C0.88825 3.16699 0 4.05524 0 5.14618V13.8545C0 14.9454 0.88825 15.8337 1.97915 15.8337H17.0208C18.1117 15.8337 19 14.9454 19 13.8545V5.14618C19 4.05524 18.1117 3.16699 17.0208 3.16699ZM18.2083 13.8545C18.2083 14.5092 17.6756 15.042 17.0208 15.042H1.97915C1.32443 15.042 0.791654 14.5092 0.791654 13.8545V5.14618C0.791654 4.49146 1.32443 3.95868 1.97915 3.95868H17.0208C17.6755 3.95868 18.2083 4.49146 18.2083 5.14618V13.8545H18.2083Z" />
                    <path d="M18.6042 5.54199H0.395846C0.177346 5.54199 0 5.71934 0 5.93784V8.31284C0 8.53134 0.177346 8.70868 0.395846 8.70868H18.6042C18.8227 8.70868 19 8.53134 19 8.31284V5.93784C19 5.71934 18.8227 5.54199 18.6042 5.54199ZM18.2083 7.91699H0.791654V6.33365H18.2083V7.91699H18.2083Z" />
                    <path d="M5.9375 11.083H2.77085C2.55235 11.083 2.375 11.2604 2.375 11.4789C2.375 11.6974 2.55235 11.8747 2.77085 11.8747H5.9375C6.156 11.8747 6.33335 11.6973 6.33335 11.4788C6.33335 11.2603 6.156 11.083 5.9375 11.083Z" />
                    <path d="M8.3125 12.667H2.77085C2.55235 12.667 2.375 12.8443 2.375 13.0628C2.375 13.2813 2.55235 13.4587 2.77085 13.4587H8.3125C8.531 13.4587 8.70835 13.2813 8.70835 13.0628C8.70835 12.8443 8.531 12.667 8.3125 12.667Z" />
                  </svg>
                  Card Info
                </Link>
              </li>
              <li
                className={
                  window.location.pathname === "/customer/change-password"
                    ? "menu-item active"
                    : "menu-item"
                }
              >
                <Link to="/customer/change-password" className="menu-link">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7.03125 12.5H1.40625C0.63125 12.5 0 11.8688 0 11.0938V5.78125C0 5.00625 0.63125 4.375 1.40625 4.375H8.59375C9.36875 4.375 10 5.00625 10 5.78125V7.65625C10 7.915 9.79 8.125 9.53125 8.125C9.2725 8.125 9.0625 7.915 9.0625 7.65625V5.78125C9.0625 5.52313 8.85188 5.3125 8.59375 5.3125H1.40625C1.14813 5.3125 0.9375 5.52313 0.9375 5.78125V11.0938C0.9375 11.3519 1.14813 11.5625 1.40625 11.5625H7.03125C7.29 11.5625 7.5 11.7725 7.5 12.0312C7.5 12.29 7.29 12.5 7.03125 12.5Z" />
                    <path d="M7.65625 5.3125C7.3975 5.3125 7.1875 5.1025 7.1875 4.84375V3.125C7.1875 1.91875 6.20625 0.9375 5 0.9375C3.79375 0.9375 2.8125 1.91875 2.8125 3.125V4.84375C2.8125 5.1025 2.6025 5.3125 2.34375 5.3125C2.085 5.3125 1.875 5.1025 1.875 4.84375V3.125C1.875 1.40188 3.27688 0 5 0C6.72312 0 8.125 1.40188 8.125 3.125V4.84375C8.125 5.1025 7.915 5.3125 7.65625 5.3125Z" />
                    <path d="M14.5312 11.25H13.2812C13.0225 11.25 12.8125 11.04 12.8125 10.7812C12.8125 10.5225 13.0225 10.3125 13.2812 10.3125H14.0625V9.53125C14.0625 9.2725 14.2725 9.0625 14.5312 9.0625C14.79 9.0625 15 9.2725 15 9.53125V10.7812C15 11.04 14.79 11.25 14.5312 11.25Z" />
                    <path d="M9.21875 14.6875C8.96 14.6875 8.75 14.4775 8.75 14.2187V12.9687C8.75 12.71 8.96 12.5 9.21875 12.5H10.4687C10.7275 12.5 10.9375 12.71 10.9375 12.9687C10.9375 13.2275 10.7275 13.4375 10.4687 13.4375H9.68749V14.2187C9.68749 14.4775 9.47749 14.6875 9.21875 14.6875Z" />
                    <path d="M9.33763 11.5625C9.29263 11.5625 9.24638 11.5556 9.20138 11.5425C8.95389 11.4669 8.81389 11.205 8.88889 10.9575C9.29076 9.63687 10.4908 8.75 11.8751 8.75C13.0314 8.75 14.0857 9.38438 14.627 10.405C14.7476 10.6338 14.6607 10.9175 14.432 11.0394C14.2014 11.1588 13.9195 11.0731 13.7976 10.8444C13.4207 10.1306 12.6833 9.6875 11.8751 9.6875C10.9064 9.6875 10.067 10.3075 9.78638 11.23C9.72513 11.4325 9.53888 11.5625 9.33763 11.5625Z" />
                    <path d="M11.875 15.0003C10.7188 15.0003 9.66442 14.3659 9.12317 13.3453C9.00255 13.1165 9.08942 12.8328 9.31817 12.7109C9.54817 12.5909 9.83067 12.6771 9.95255 12.9059C10.3294 13.6196 11.0669 14.0628 11.875 14.0628C12.8382 14.0628 13.6782 13.4421 13.965 12.5171C14.0419 12.2702 14.3038 12.1315 14.5513 12.209C14.7988 12.2852 14.9363 12.5484 14.8594 12.7953C14.4507 14.114 13.2513 15.0003 11.875 15.0003Z" />
                  </svg>
                  Change Password
                </Link>
              </li>
              <li className="menu-item">
                <Link to="#!" className="menu-link" onClick={this.logout}>
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M14.5311 7.03123H8.59374C8.33499 7.03123 8.125 6.82123 8.125 6.56249C8.125 6.30374 8.33499 6.09375 8.59374 6.09375H14.5311C14.7898 6.09375 14.9998 6.30374 14.9998 6.56249C14.9998 6.82123 14.7898 7.03123 14.5311 7.03123Z" />
                    <path d="M12.1869 9.37495C12.0668 9.37495 11.9469 9.32929 11.8556 9.23751C11.6725 9.0543 11.6725 8.75745 11.8556 8.57435L13.8681 6.56197L11.8556 4.54948C11.6725 4.36638 11.6725 4.06953 11.8556 3.88643C12.0388 3.70322 12.3357 3.70322 12.5188 3.88643L14.8624 6.23011C15.0455 6.4132 15.0455 6.71005 14.8624 6.89315L12.5188 9.23682C12.4269 9.32929 12.3069 9.37495 12.1869 9.37495Z" />
                    <path d="M4.99988 15.0005C4.8661 15.0005 4.73919 14.9817 4.6124 14.9424L0.851185 13.6893C0.339421 13.5105 0 13.0337 0 12.5006V1.25098C0 0.561605 0.560629 0.000976562 1.25 0.000976562C1.38366 0.000976562 1.51057 0.0197443 1.63748 0.0591107L5.39858 1.3122C5.91046 1.49095 6.24977 1.96781 6.24977 2.50086V13.7505C6.24977 14.4399 5.68925 15.0005 4.99988 15.0005ZM1.25 0.938447C1.07811 0.938447 0.937471 1.07909 0.937471 1.25098V12.5006C0.937471 12.6337 1.02685 12.7574 1.15433 12.8018L4.89792 14.0493C4.92481 14.058 4.95983 14.063 4.99988 14.063C5.17177 14.063 5.3123 13.9224 5.3123 13.7505V2.50086C5.3123 2.36777 5.22292 2.24406 5.09544 2.19966L1.35185 0.95218C1.32496 0.943483 1.28994 0.938447 1.25 0.938447Z" />
                    <path d="M9.5309 5.00086C9.27216 5.00086 9.06217 4.79087 9.06217 4.53212V1.71971C9.06217 1.28908 8.71164 0.938447 8.28102 0.938447H1.24999C0.991243 0.938447 0.78125 0.728455 0.78125 0.469712C0.78125 0.210969 0.991243 0.000976562 1.24999 0.000976562H8.28102C9.22913 0.000976562 9.99964 0.771598 9.99964 1.71971V4.53212C9.99964 4.79087 9.78964 5.00086 9.5309 5.00086Z" />
                    <path d="M8.28074 13.1249H5.78075C5.522 13.1249 5.31201 12.9149 5.31201 12.6561C5.31201 12.3974 5.522 12.1874 5.78075 12.1874H8.28074C8.71137 12.1874 9.06189 11.8368 9.06189 11.4061V8.59373C9.06189 8.33499 9.27189 8.125 9.53063 8.125C9.78937 8.125 9.99936 8.33499 9.99936 8.59373V11.4061C9.99936 12.3542 9.22886 13.1249 8.28074 13.1249Z" />
                  </svg>
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  logoutCustomer: (callback) => dispatch(logoutCustomer(callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(CustomerSidebar));
