import React, { Component } from "react";
import { Link } from "react-router-dom";

class FooterNew extends Component {
  render() {
    return (
      <footer id="footer1">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="footer-content d-lg-flex d-wrap align-items-center justify-content-between">
                <div class="footer-content-left">
                  <ul class="footer-nav text-center d-md-flex">
                    <li>
                      <a
                        href="https://effisim.info/terms-conditions"
                        target="_blank"
                      >
                        Terms & Conditions
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://effisim.info/privacy-policy"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a href="https://effisim.info/FAQ" target="_blank">
                        FAQ
                      </a>
                    </li>
                    <li>
                      <Link to="/seller/login" target="_blank">
                        Seller Login
                      </Link>
                    </li>
                    <li>
                      <Link to="/seller/registration" target="_blank">
                        Seller Registration
                      </Link>
                    </li>
                  </ul>
                </div>
                <div class="footer-content-right">
                  <p>
                    &#169;{new Date().getFullYear()} Efffisim Corp. All rights
                    reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default FooterNew;
