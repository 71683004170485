import React, { createRef } from "react";
import Form from "../../common/form/form";
import Joi from "joi-browser";

import SubmitBtn from "../../common/form/submitBtn";
import CustomerSidebar from "../../common/customer/customerSidebar";
import Header from "../../productV2/header";

import {
  informationSaved,
  failed,
  passwordInvalid,
  passwordInvalidMax,
  passwordRequired,
  oldPassworddRequired,
} from "../../common/misc";
import Footer from "../../common/footer";

import {
  getCustomer,
  customerUpdate,
  loadCustomerProfile,
} from "../../../store/customer";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AlertError from "../../common/alertError";
import AlertSuccess from "../../common/alertSuccess";

import LoadingBar from "react-top-loading-bar";

const initialState = {
  data: {
    oldPassword: "",
    password: "",
    confirmPassword: "",
  },
  loading: false,
  errors: {},
};

class CustomerChangePassword extends Form {
  ref = createRef(null);

  state = initialState;

  schema = {
    confirmPassword: Joi.string()
      .required()
      .label("Confirm Password")
      .valid(Joi.ref("password"))
      .options({
        language: {
          any: {
            allowOnly: "!!Confirm Password and Password fields must be same",
            empty: "field is required",
          },
        },
      }),
    password: Joi.string()
      .min(8)
      .max(25)
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "password"
      )
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.regex.name":
              err.message = passwordInvalid;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;

            case "string.max":
              err.message = passwordInvalidMax;
              break;

            default:
          }
        });
        return errors;
      }),

    oldPassword: Joi.string()
      .required()
      .label("Old Password")
      .error(() => {
        return { message: oldPassworddRequired };
      }),
  };

  componentDidMount = () => {
    this.ref.current.continuousStart();
    this.props.loadCustomerProfile();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.complete();
    }
  };

  doSubmit = () => {
    this.ref.current.continuousStart();
    const { password, oldPassword } = this.state.data;
    const formData = {
      old_password: oldPassword,
      password: password,
    };
    this.props.customerUpdate(formData, this.callback);
  };
  callback = (res) => {
    this.ref.current.complete();

    if (res && res.status === 200) {
      this.setState(initialState);
      toast(<AlertSuccess message={informationSaved} />);
    } else {
      toast(<AlertError message={res.data.error} />);
      this.setState(initialState);
    }
  };

  render() {
    return (
      <>
        <LoadingBar color="#0b4474" ref={this.ref} />
        <Header />
        <main id="main">
          <div className="content-container">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="conetnt-box d-md-flex flex-md-wrap">
                    <CustomerSidebar />
                    <div className="main-content-box">
                      <div className="account-details-box">
                        <h3>Change Password</h3>
                        <form onSubmit={this.handleSubmit}>
                          <div className="form-group">
                            {this.renderLoginPasswordInput(
                              "oldPassword",
                              "Old Password"
                            )}
                          </div>
                          <div className="form-group">
                            {this.renderPasswordInput(
                              "password",
                              "New Password"
                            )}
                          </div>

                          <div className="form-group">
                            {this.renderPasswordInput(
                              "confirmPassword",
                              "Confirm Password"
                            )}
                          </div>

                          <div className="form-group sidebar-btn">
                            <SubmitBtn
                              label="Save Changes"
                              loading={this.state.loading}
                              btnClass=" btn btn-default"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customerProfile: getCustomer(state).profile,
  loading: getCustomer(state).profileLoading,
});

const mapDispatchToProps = (dispatch) => ({
  customerUpdate: (data, callback) => dispatch(customerUpdate(data, callback)),
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerChangePassword);
