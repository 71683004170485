import React, { Component } from "react";
// import Footer from "../common/footer";
import TopBar from "../common/topBar";
import { loadSellerProfile, getSeller } from "../../store/seller";
import { connect } from "react-redux";
import OperationGrid from "./operationGrid";

class HourOperation extends Component {
  ref = React.createRef(null);
  state = {
    settingDropdown: false,
    profileDropdown: false,
  };
  componentDidMount = () => {
    this.props.loadSellerProfile();
    this.ref.current.continuousStart();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.profileLoading &&
      this.props.profileLoading !== prevProps.profileLoading
    ) {
      this.ref.current.complete();
    }
  };

  handleSettingDropdown = () => {
    if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
      this.setState({ settingDropdown: !this.state.settingDropdown });
    } else {
      this.setState({ settingDropdown: !this.state.settingDropdown });
    }
  };

  handleProfileDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
      this.setState({ profileDropdown: !this.state.profileDropdown });
    } else {
      this.setState({ profileDropdown: !this.state.profileDropdown });
    }
  };

  setSettingDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
    } else if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
    }
  };
  render() {
    const { schedules } = this.props.seller;
    return (
      <>
        <div id="main" onClick={this.setSettingDropdown}>
          <div className="dashboard-container custom-top">
            <TopBar
              handleProfileDropdown={this.handleProfileDropdown}
              handleSettingDropdown={this.handleSettingDropdown}
              settingDropdown={this.state.settingDropdown}
              profileDropdown={this.state.profileDropdown}
              loaDerRef={this.ref}
              sellerProfile={this.props.seller}
            />
            <div className="operation-container">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <OperationGrid schedules={schedules} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  seller: getSeller(state).profile,
  profileLoading: getSeller(state).profileLoading,
});
const mapDispatchToProps = (dispatch) => ({
  loadSellerProfile: () => dispatch(loadSellerProfile()),
});
export default connect(mapStateToProps, mapDispatchToProps)(HourOperation);
