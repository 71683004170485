import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getCustomer,
  loadCustomerProfile,
  logoutCustomer,
} from "../../store/customer";
import AlertError from "../common/alertError";
import { getCustomerToken } from "../services/localStorageServices";
import closeIcon from "./../../include/images/close.svg";
import user_00 from "./../../include/images/user_00.svg";
class UserSideBar extends React.Component {
  logout = (e) => {
    e.preventDefault();
    this.props.logoutCustomer(this.callback);
  };

  callback = (res) => {
    if (res.status === 200) {
      window.localStorage.removeItem("x-auth-token-customer");
      window.localStorage.removeItem("x-refresh-token-customer");
      window.localStorage.removeItem("customer-name");
      window.localStorage.removeItem("redirectUrl");
      localStorage.removeItem("customer_information");
      window.localStorage.removeItem("customerDddress");
      localStorage.removeItem("customerAddress");
      window.location.href = "/";
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };
  render() {
    const { first_name, last_name } = this.props.customerProfile;
    const pathname = this.props.location.pathname;
    return (
      <>
        {" "}
        <div
          class={
            this.props.userSideBar
              ? "tab-slidebar tab-modify-slidebar3 small-tab-modify-sidebar show"
              : "tab-slidebar tab-modify-slidebar3 small-tab-modify-sidebar"
          }
          id="loginpannel"
        >
          {getCustomerToken("refresh") ? (
            <div class="account-member-box">
              <div class="close">
                <a href="#!" onClick={this.props.toggleUserSideBar}>
                  <img src={closeIcon} alt="" />
                </a>
              </div>
              <div class="am-head text-center">
                <div class="nml-head-img">
                  <img src={user_00} alt="" />
                </div>
                <p>Hi, {first_name + " " + last_name}</p>
              </div>
              <div class="am-body">
                <ul class="am-menu-list">
                  <li
                    className={
                      pathname === "/customer/account-details"
                        ? "active"
                        : "inactive"
                    }
                  >
                    <Link to="/customer/account-details">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M6.99994 0C5.21871 0 3.76917 1.44954 3.76917 3.23077C3.76917 5.012 5.21871 6.46154 6.99994 6.46154C8.78117 6.46154 10.2307 5.012 10.2307 3.23077C10.2307 1.44954 8.78117 0 6.99994 0ZM6.99994 5.38461C5.81209 5.38461 4.84609 4.41862 4.84609 3.23077C4.84609 2.04292 5.81209 1.07692 6.99994 1.07692C8.18779 1.07692 9.15379 2.04292 9.15379 3.23077C9.15379 4.41862 8.18779 5.38461 6.99994 5.38461Z"></path>
                        <path d="M13.4572 10.2083C13.4551 10.1696 13.4443 10.134 13.4346 10.0974C13.426 10.0662 13.4217 10.0339 13.4077 10.0048C13.3948 9.97573 13.3732 9.95204 13.3538 9.92512C13.3312 9.89389 13.3118 9.86266 13.2828 9.83681C13.2763 9.83143 13.2741 9.82389 13.2677 9.8185C9.63522 6.77404 4.36153 6.77404 0.729068 9.8185C0.722606 9.82389 0.720452 9.83143 0.713991 9.83681C0.685991 9.86266 0.665529 9.89389 0.642914 9.92619C0.624606 9.95312 0.603068 9.97681 0.590144 10.0048C0.576144 10.0339 0.571837 10.0662 0.563221 10.0974C0.553529 10.134 0.54276 10.1696 0.540606 10.2083C0.54276 10.2159 0.538452 10.2223 0.538452 10.231V12.9233C0.538452 13.5167 1.02199 14.0002 1.61538 14.0002H12.3846C12.978 14.0002 13.4615 13.5167 13.4615 12.9233V10.231C13.4615 10.2223 13.4572 10.2159 13.4572 10.2083ZM12.3846 12.9233H1.61538V10.497C4.77507 7.99312 9.22491 7.99312 12.3846 10.497V12.9233Z"></path>
                      </svg>
                      Account Details
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === "/customer/order-history"
                        ? "active"
                        : "inactive"
                    }
                  >
                    <Link to="/customer/order-history">
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12.9841 12.9553L12.1863 4.17656C12.1638 3.93469 11.9613 3.75 11.7184 3.75H10.3122V2.8125C10.3122 2.05875 10.0206 1.35094 9.49094 0.82125C8.96125 0.291562 8.25344 0 7.49969 0C5.94907 0 4.68719 1.26187 4.68719 2.8125V3.75H3.28094C3.03813 3.75 2.83563 3.93469 2.81407 4.17656L2.01625 12.9553C1.96844 13.4784 2.14469 14.0016 2.49907 14.3897C2.85344 14.7778 3.35782 15 3.88375 15H11.1166C11.6425 15 12.1469 14.7778 12.5013 14.3897C12.8556 14.0016 13.0309 13.4784 12.9841 12.9553ZM5.62469 2.8125C5.62469 1.77844 6.46563 0.9375 7.49969 0.9375C8.00313 0.9375 8.47469 1.13156 8.82813 1.48406C9.18157 1.83656 9.37469 2.30906 9.37469 2.8125V3.75H5.62469V2.8125ZM11.8084 13.7578C11.6284 13.9538 11.3828 14.0625 11.1156 14.0625H3.88375C3.6175 14.0625 3.37094 13.9537 3.19188 13.7569C3.01188 13.56 2.92657 13.3059 2.95094 13.0397L3.70938 4.6875H4.68719V6.09375C4.68719 6.3525 4.89719 6.5625 5.15594 6.5625C5.41469 6.5625 5.62469 6.3525 5.62469 6.09375V4.6875H9.37469V6.09375C9.37469 6.3525 9.58469 6.5625 9.84344 6.5625C10.1022 6.5625 10.3122 6.3525 10.3122 6.09375V4.6875H11.29L12.0494 13.0406C12.0738 13.3059 11.9884 13.5609 11.8084 13.7578Z"></path>
                      </svg>
                      My Orders
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === "/customer/wishlist" ? "active" : "inactive"
                    }
                  >
                    <Link to="/customer/wishlist">
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7.5 14.3652C7.21252 14.3652 6.93878 14.2514 6.74 14.0515C6.18439 13.6252 0 8.79524 0 4.74461C0 2.46769 1.85623 0.615234 4.1375 0.615234C5.48996 0.615234 6.73496 1.26961 7.5 2.33585C8.26504 1.26961 9.51004 0.615234 10.8625 0.615234C13.1438 0.615234 15 2.46769 15 4.74461C15 8.79524 8.81561 13.6252 8.26 14.0515C8.06122 14.2514 7.78748 14.3652 7.5 14.3652ZM4.1375 1.55273C2.37316 1.55273 0.9375 2.98462 0.9375 4.74461C0.9375 8.43464 7.27318 13.279 7.33692 13.3278C7.36061 13.3452 7.38247 13.3659 7.40192 13.3878C7.44873 13.4409 7.55001 13.4409 7.59693 13.3878C7.61684 13.3659 7.6387 13.3459 7.66182 13.3278C7.72682 13.279 14.0625 8.43143 14.0625 4.74461C14.0625 2.98462 12.6268 1.55273 10.8625 1.55273C9.58626 1.55273 8.43567 2.30461 7.92995 3.4671C7.7813 3.80894 7.2187 3.80894 7.07005 3.4671C6.56433 2.30461 5.41317 1.55273 4.1375 1.55273Z"></path>
                      </svg>
                      My Wishlist
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === "/customer/addresses" ? "active" : "inactive"
                    }
                  >
                    <Link to="/customer/addresses">
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7.5 0C4.39781 0 1.875 2.52281 1.875 5.625C1.875 9.52406 6.9525 14.6466 7.16813 14.8631C7.26 14.9541 7.38 15 7.5 15C7.62 15 7.74 14.9541 7.83187 14.8631C8.0475 14.6466 13.125 9.52406 13.125 5.625C13.125 2.52281 10.6022 0 7.5 0ZM7.5 13.8534C6.38062 12.6553 2.8125 8.60344 2.8125 5.625C2.8125 3.04031 4.91531 0.9375 7.5 0.9375C10.0847 0.9375 12.1875 3.04031 12.1875 5.625C12.1875 8.60063 8.61938 12.6553 7.5 13.8534Z"></path>
                        <path d="M7.5 2.8125C5.94937 2.8125 4.6875 4.07437 4.6875 5.625C4.6875 7.17562 5.94937 8.4375 7.5 8.4375C9.05062 8.4375 10.3125 7.17562 10.3125 5.625C10.3125 4.07437 9.05062 2.8125 7.5 2.8125ZM7.5 7.5C6.46594 7.5 5.625 6.65906 5.625 5.625C5.625 4.59094 6.46594 3.75 7.5 3.75C8.53406 3.75 9.375 4.59094 9.375 5.625C9.375 6.65906 8.53406 7.5 7.5 7.5Z"></path>
                      </svg>
                      My Addresses
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === "/customer/card-details"
                        ? "active"
                        : "inactive"
                    }
                  >
                    <Link to="/customer/card-details">
                      <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M17.0208 3.1665H1.97915C0.88825 3.1665 0 4.05475 0 5.14569V13.854C0 14.9449 0.88825 15.8332 1.97915 15.8332H17.0208C18.1117 15.8332 19 14.9449 19 13.854V5.14569C19 4.05475 18.1117 3.1665 17.0208 3.1665ZM18.2083 13.854C18.2083 14.5087 17.6756 15.0415 17.0208 15.0415H1.97915C1.32443 15.0415 0.791654 14.5087 0.791654 13.854V5.14569C0.791654 4.49097 1.32443 3.95819 1.97915 3.95819H17.0208C17.6755 3.95819 18.2083 4.49097 18.2083 5.14569V13.854H18.2083Z"></path>
                        <path d="M18.6042 5.5415H0.395846C0.177346 5.5415 0 5.71885 0 5.93735V8.31235C0 8.53085 0.177346 8.7082 0.395846 8.7082H18.6042C18.8227 8.7082 19 8.53085 19 8.31235V5.93735C19 5.71885 18.8227 5.5415 18.6042 5.5415ZM18.2083 7.9165H0.791654V6.33316H18.2083V7.9165H18.2083Z"></path>
                        <path d="M5.9375 11.0835H2.77085C2.55235 11.0835 2.375 11.2608 2.375 11.4793C2.375 11.6978 2.55235 11.8752 2.77085 11.8752H5.9375C6.156 11.8752 6.33335 11.6978 6.33335 11.4793C6.33335 11.2608 6.156 11.0835 5.9375 11.0835Z"></path>
                        <path d="M8.3125 12.6665H2.77085C2.55235 12.6665 2.375 12.8438 2.375 13.0623C2.375 13.2808 2.55235 13.4582 2.77085 13.4582H8.3125C8.531 13.4582 8.70835 13.2808 8.70835 13.0623C8.70835 12.8438 8.531 12.6665 8.3125 12.6665Z"></path>
                      </svg>
                      Card Info
                    </Link>
                  </li>
                  <li
                    className={
                      pathname === "/customer/change-password"
                        ? "active"
                        : "inactive"
                    }
                  >
                    <Link to="/customer/change-password">
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7.03125 12.5005H1.40625C0.63125 12.5005 0 11.8692 0 11.0942V5.78174C0 5.00674 0.63125 4.37549 1.40625 4.37549H8.59375C9.36875 4.37549 10 5.00674 10 5.78174V7.65674C10 7.91549 9.79 8.12549 9.53125 8.12549C9.2725 8.12549 9.0625 7.91549 9.0625 7.65674V5.78174C9.0625 5.52361 8.85188 5.31299 8.59375 5.31299H1.40625C1.14813 5.31299 0.9375 5.52361 0.9375 5.78174V11.0942C0.9375 11.3524 1.14813 11.563 1.40625 11.563H7.03125C7.29 11.563 7.5 11.773 7.5 12.0317C7.5 12.2905 7.29 12.5005 7.03125 12.5005Z"></path>
                        <path d="M7.65625 5.3125C7.3975 5.3125 7.1875 5.1025 7.1875 4.84375V3.125C7.1875 1.91875 6.20625 0.9375 5 0.9375C3.79375 0.9375 2.8125 1.91875 2.8125 3.125V4.84375C2.8125 5.1025 2.6025 5.3125 2.34375 5.3125C2.085 5.3125 1.875 5.1025 1.875 4.84375V3.125C1.875 1.40188 3.27688 0 5 0C6.72312 0 8.125 1.40188 8.125 3.125V4.84375C8.125 5.1025 7.915 5.3125 7.65625 5.3125Z"></path>
                        <path d="M14.5312 11.2505H13.2812C13.0225 11.2505 12.8125 11.0405 12.8125 10.7817C12.8125 10.523 13.0225 10.313 13.2812 10.313H14.0625V9.53174C14.0625 9.27299 14.2725 9.06299 14.5312 9.06299C14.79 9.06299 15 9.27299 15 9.53174V10.7817C15 11.0405 14.79 11.2505 14.5312 11.2505Z"></path>
                        <path d="M9.21875 14.687C8.96 14.687 8.75 14.477 8.75 14.2183V12.9683C8.75 12.7095 8.96 12.4995 9.21875 12.4995H10.4687C10.7275 12.4995 10.9375 12.7095 10.9375 12.9683C10.9375 13.227 10.7275 13.437 10.4687 13.437H9.68749V14.2183C9.68749 14.477 9.47749 14.687 9.21875 14.687Z"></path>
                        <path d="M9.33751 11.562C9.29251 11.562 9.24626 11.5551 9.20126 11.542C8.95376 11.4664 8.81376 11.2045 8.88876 10.957C9.29064 9.63639 10.4906 8.74951 11.875 8.74951C13.0313 8.74951 14.0856 9.38389 14.6269 10.4045C14.7475 10.6333 14.6606 10.917 14.4319 11.0389C14.2013 11.1583 13.9194 11.0726 13.7975 10.8439C13.4206 10.1301 12.6831 9.68701 11.875 9.68701C10.9063 9.68701 10.0669 10.307 9.78626 11.2295C9.72501 11.432 9.53876 11.562 9.33751 11.562Z"></path>
                        <path d="M11.875 15.0003C10.7188 15.0003 9.66442 14.3659 9.12317 13.3453C9.00255 13.1165 9.08942 12.8328 9.31817 12.7109C9.54817 12.5909 9.83067 12.6771 9.95255 12.9059C10.3294 13.6196 11.0669 14.0628 11.875 14.0628C12.8382 14.0628 13.6782 13.4421 13.965 12.5171C14.0419 12.2702 14.3038 12.1315 14.5513 12.209C14.7988 12.2852 14.9363 12.5484 14.8594 12.7953C14.4507 14.114 13.2513 15.0003 11.875 15.0003Z"></path>
                      </svg>
                      Change Password
                    </Link>
                  </li>
                  <li class="line"></li>
                  <li>
                    <a href="#!" onClick={this.props.toggleContact}>
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M14.0196 10.6714C12.8582 10.5654 11.7279 10.2696 10.6607 9.79286C10.1346 9.55821 9.52071 9.77143 9.26357 10.2814L8.20607 12.3825C5.83393 11.1236 3.87536 9.165 2.61643 6.79393L4.7175 5.73643C5.22643 5.48036 5.44179 4.86643 5.20607 4.33929C4.72929 3.27214 4.43357 2.14286 4.32857 0.981429C4.27714 0.422143 3.82179 0 3.26786 0H0.535714C0.24 0 0 0.24 0 0.535714C0 2.71714 0.475714 4.82036 1.41321 6.78964C2.83714 9.74893 5.25107 12.1629 8.2125 13.5868C10.1796 14.5243 12.2829 15 14.4643 15C14.76 15 15 14.76 15 14.4643V11.7321C15 11.1782 14.5779 10.7229 14.0196 10.6714ZM1.08214 1.0725L3.26036 1.07786C3.37714 2.35714 3.70286 3.60214 4.23536 4.77857L2.15464 5.8275C1.50536 4.31357 1.14536 2.71929 1.08214 1.0725ZM13.9286 13.9179C12.2818 13.8536 10.6864 13.4936 9.17357 12.8464L10.2236 10.7721C11.3979 11.2971 12.6429 11.6229 13.9286 11.7321V13.9179Z"></path>
                      </svg>
                      Contact us
                    </a>
                  </li>
                  <li>
                    <a href="#!" onClick={this.logout}>
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.531 7.03123H8.59361C8.33487 7.03123 8.12488 6.82123 8.12488 6.56249C8.12488 6.30374 8.33487 6.09375 8.59361 6.09375H14.531C14.7897 6.09375 14.9997 6.30374 14.9997 6.56249C14.9997 6.82123 14.7897 7.03123 14.531 7.03123Z"
                          fill="black"
                        ></path>
                        <path d="M12.187 9.37495C12.067 9.37495 11.947 9.32929 11.8557 9.23751C11.6726 9.0543 11.6726 8.75745 11.8557 8.57435L13.8682 6.56197L11.8557 4.54948C11.6726 4.36638 11.6726 4.06953 11.8557 3.88643C12.0389 3.70322 12.3358 3.70322 12.5189 3.88643L14.8626 6.23011C15.0457 6.4132 15.0457 6.71005 14.8626 6.89315L12.5189 9.23682C12.427 9.32929 12.3071 9.37495 12.187 9.37495Z"></path>
                        <path d="M4.99988 15C4.8661 15 4.73919 14.9813 4.6124 14.9419L0.851185 13.6888C0.339421 13.51 0 13.0332 0 12.5001V1.25049C0 0.561117 0.560629 0.000488281 1.25 0.000488281C1.38366 0.000488281 1.51057 0.019256 1.63748 0.0586225L5.39858 1.31171C5.91046 1.49046 6.24977 1.96732 6.24977 2.50037V13.75C6.24977 14.4394 5.68925 15 4.99988 15ZM1.25 0.937959C1.07811 0.937959 0.937471 1.0786 0.937471 1.25049V12.5001C0.937471 12.6332 1.02685 12.7569 1.15433 12.8013L4.89792 14.0488C4.92481 14.0575 4.95983 14.0625 4.99988 14.0625C5.17177 14.0625 5.3123 13.9219 5.3123 13.75V2.50037C5.3123 2.36728 5.22292 2.24357 5.09544 2.19917L1.35185 0.951691C1.32496 0.942994 1.28994 0.937959 1.25 0.937959Z"></path>
                        <path d="M9.53078 5.00086C9.27204 5.00086 9.06204 4.79087 9.06204 4.53212V1.71971C9.06204 1.28908 8.71152 0.938447 8.28089 0.938447H1.24986C0.99112 0.938447 0.781128 0.728455 0.781128 0.469712C0.781128 0.210969 0.99112 0.000976562 1.24986 0.000976562H8.28089C9.22901 0.000976562 9.99951 0.771598 9.99951 1.71971V4.53212C9.99951 4.79087 9.78952 5.00086 9.53078 5.00086Z"></path>
                        <path d="M8.28099 13.1249H5.78099C5.52225 13.1249 5.31226 12.9149 5.31226 12.6561C5.31226 12.3974 5.52225 12.1874 5.78099 12.1874H8.28099C8.71162 12.1874 9.06214 11.8368 9.06214 11.4061V8.59373C9.06214 8.33499 9.27213 8.125 9.53087 8.125C9.78962 8.125 9.99961 8.33499 9.99961 8.59373V11.4061C9.99961 12.3542 9.2291 13.1249 8.28099 13.1249Z"></path>
                      </svg>
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <div class="new-memberlogin-box">
              <a onClick={this.props.toggleUserSideBar} href="#!" class="close">
                <img src={closeIcon} alt="" />
              </a>
              <div class="nml-head">
                <div class="nml-head-img">
                  <img src={user_00} alt="" />
                </div>
                <h4>Hi, Guest</h4>
              </div>
              <div class="nml-body">
                <div class="nml-body-btn">
                  <Link to="/customer/registration" class="btn btn-default">
                    Sign up
                  </Link>
                  <Link to="/customer/login" class="btn btn-primary btn-login">
                    Login
                  </Link>
                </div>
              </div>
              <div class="nml-footer">
                <a
                  href="#"
                  class="btn btn-white"
                  onClick={this.props.toggleContact}
                >
                  Contact us
                </a>
              </div>
            </div>
          )}
        </div>
        {/* <div class={this.props.userSideBar ? "overlay show" : "overlay"}></div> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  customerProfile: getCustomer(state).profile,
});
const mapDispatchToProps = (dispatch) => ({
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
  logoutCustomer: (callback) => dispatch(logoutCustomer(callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserSideBar)
);
