import React from "react";
import Form from "../form/form";
import Joi from "joi-browser";

import closeIcon from "../../../include/images/close.svg";
import StarRatings from "react-star-ratings";

import {
  loadOrders,
  getCustomer,
  postProductReview,
  postSellerReview,
} from "../../../store/customer";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import AlertSuccess from "../../common/alertSuccess";
import AlertError from "../../common/alertError";
import { toast } from "react-toastify";
import SubmitBtn from "../form/submitBtn";

const initialState = {
  rating: 1,
  data: {
    subject: "",
    writeReview: "",
  },
  errors: {},
};

class PostReview extends Form {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.changeRating = this.changeRating.bind(this);
  }

  schema = {
    subject: Joi.string()
      .required()
      .error(() => {
        return {
          message: "Required",
        };
      }),
    writeReview: Joi.string()
      .required()
      .error(() => {
        return {
          message: "Required",
        };
      }),
  };

  doSubmit = () => {
    const { subject, writeReview } = this.state.data;
    const { rating } = this.state;

    if (this.props.productReview === true) {
      if (this.props._id) {
        const productParam = {
          order_id: this.props._id,
          product_id: this.props.product_id,
          rating,
          content: writeReview,
          subject,
        };
        this.props.postProductReview(productParam, this.productCallback);
      } else {
        const productParamnew = {
          order_id: null,
          product_id: this.props.product_id,
          rating,
          content: writeReview,
          subject,
        };
        this.props.postProductReview(productParamnew, this.productCallback);
      }
    } else {
      const sellerParam = {
        order_id: this.props._id,
        rating,
        content: writeReview,
        subject,
      };
      this.props.postSellerReview(sellerParam, this.sellerCallback);
    }
  };

  sellerCallback = (res) => {
    this.setState(initialState);
    if (res.status === 200) {
      toast(<AlertSuccess message={"Review Posted Successfully"} />);
      this.props.handleClose();
      this.props.loadOrders();
    } else {
      toast(<AlertError message={"Failed"} />);
    }
  };

  productCallback = (res) => {
    this.setState(initialState);
    if (res.status === 200) {
      const ddres = JSON.parse(localStorage.getItem("customerDddress"));
      toast(<AlertSuccess message={"Review Posted Successfully"} />);
      this.props.handleClose();
      if (this.props.detail) {
        this.props.loadProductDetail(this.props.product_det_Id, {
          address: ddres.address,
          lat: ddres.lat,
          lon: ddres.lng,
          zipCode: ddres.postal_code.long_name,
        });
      }
      this.props.loadOrders();
    } else {
      toast(<AlertError message={"Failed"} />);
    }
  };

  changeRating(rating) {
    this.setState({
      rating: rating,
    });
  }

  render() {
    console.log("prod", this.props.product_id);
    return (
      <>
        {(this.props.productReview || this.props.sellerReview) && (
          <>
            <div className="tab-modify-slidebar large-modify-sidebar">
              <div className="tab-modify-box">
                <div className="tab-header">
                  <h3>Write a Review</h3>
                  <a
                    href="javascript:void(0)"
                    onClick={this.props.handleClose}
                    className="close"
                  >
                    <img src={closeIcon} alt="" />
                  </a>

                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <label>Rating</label>
                      <div className="stars-icon d-flex align-items-center">
                        <StarRatings
                          rating={this.state.rating}
                          starDimension=" 18px"
                          starSpacing="3px"
                          starEmptyColor="#C4C4C4"
                          starRatedColor="#ffa51e"
                          starHoverColor="#ffa51e"
                          changeRating={this.changeRating}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      {this.renderInput("subject", "Subject")}
                    </div>
                    <div className="form-group">
                      {this.renderText("writeReview", "Write a Review")}
                    </div>
                    <div className="form-group sidebar-btn">
                      <SubmitBtn label="Submit" btnClass="btn btn-default" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customerOrders: getCustomer(state).orders.data,
  profileLoading: getCustomer(state).profileLoading,
});

const mapDispatchToProps = (dispatch) => ({
  loadOrders: (params) => dispatch(loadOrders(params)),
  postProductReview: (params, callback) =>
    dispatch(postProductReview(params, callback)),
  postSellerReview: (params, callback) =>
    dispatch(postSellerReview(params, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PostReview)
);
