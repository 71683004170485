import React, { Component } from "react";
import x from "../../include/images/x.svg";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import plus from "../../include/images/accor-plus-icon.svg";
import minus from "../../include/images/accor-minus-icon.svg";
import deliverIcon from "../../include/images/deliver-icon.svg";
import closeIcon from "../../include/images/close-icon.svg";
import { Range } from "react-range";
import _, { filter } from "lodash";

class FilterModal extends Component {
  state = {
    productId: "",
    category: "",
    mode: false,
    deliveryOption: false,
    pickup: false,
    category: false,
    seller: false,
    price: false,
    subcategory: false,
    averageRating: false,
    numberOfReviews: false,
    priceMax: "",
    priceMin: "",
  };
  render() {
    const { cat1, cat2, cat3 } = this.props.match;
    const { filters } = this.props;
    return (
      <div
        class={
          this.props.filterModal
            ? "modal fade left-collapse filtermenu-modal show d-block"
            : "modal fade left-collapse filtermenu-modal d-none"
        }
        id="sideCollapseMenu"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <SimpleBar
              class="filtermenu-box w-100 h-100 overflow-auto"
              data-simplebar
            >
              <div class="filtermenu-head position-relative d-flex align-items-center justify-content-between">
                <h5>Filters</h5>
                <div
                  onClick={this.props.toggleFilterModal}
                  class="close-sidemenu"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img src={x} alt="" />
                </div>
              </div>
              <div class="filtermenu-tags">
                <div class="filtermenu-tags-head d-flex align-items-center justify-content-between">
                  <h6>Selected Filters</h6>
                  <a
                    href="javascript:void(0)"
                    class="filtermenu-clear-btn"
                    onClick={this.props.clearFilter}
                  >
                    Clear all
                  </a>
                </div>
                <div class="tags">
                  {filters.deliveryOption && (
                    <span class="badge">
                      {filters.deliveryOption === "today"
                        ? "Delivery option: Deliver today"
                        : filters.deliveryOption === "week"
                        ? "Delivery option: Within a week"
                        : filters.deliveryOption === "more"
                        ? "Delivery option: More than a week"
                        : ""}
                      <img
                        class="tag-close-icon"
                        src={closeIcon}
                        alt=""
                        onClick={(e) =>
                          this.props.handleRadio({
                            target: {
                              name: "deliveryOption",
                              value: "",
                            },
                          })
                        }
                      />
                    </span>
                  )}
                  {this.props.sellers && this.props.sellers.length > 0 && (
                    <>
                      {this.props.sellers.map(
                        (e) =>
                          filters.sellers.find((eAll) => eAll === e._id) && (
                            <span class="badge">
                              Seller: {e.company}
                              <img
                                class="tag-close-icon"
                                src={closeIcon}
                                alt=""
                                onClick={(e1) =>
                                  this.props.onChangeSeller(e._id)
                                }
                              />
                            </span>
                          )
                      )}
                    </>
                  )}
                  {filters.prices && filters.prices.length > 0 && (
                    <>
                      {filters.prices.map(
                        (e) =>
                          filters.prices.find((el) => el === e) && (
                            <span class="badge">
                              {e === "0"
                                ? "Price $10 - $50"
                                : e === "1"
                                ? "Price $50 - $100"
                                : e === "2"
                                ? "Price $100 - $200"
                                : ""}
                              <img
                                class="tag-close-icon"
                                src={closeIcon}
                                alt=""
                                onClick={(e1) => {
                                  this.props.onChangePrices(e);
                                }}
                              />
                            </span>
                          )
                      )}
                    </>
                  )}
                  {filters.mode && (
                    <span class="badge">
                      {filters.mode === "delivery"
                        ? "Mode: Delivery only"
                        : filters.mode === "pickup"
                        ? "Mode: Pickup only"
                        : ""}
                      <img
                        class="tag-close-icon"
                        src={closeIcon}
                        alt=""
                        onClick={(e) =>
                          this.props.handleRadio({
                            target: {
                              name: "mode",
                              value: "",
                            },
                          })
                        }
                      />
                    </span>
                  )}

                  {filters.pickupOption && (
                    <span class="badge">
                      {`Pickup : Within ${filters.pickupOption} ${
                        filters.pickupOption == 1 ? "mile" : "miles"
                      }`}
                      <img
                        class="tag-close-icon"
                        src={closeIcon}
                        alt=""
                        onClick={(e) =>
                          this.props.handleRadio({
                            target: {
                              name: "pickupOption",
                              value: "",
                            },
                          })
                        }
                      />
                    </span>
                  )}
                  {filters.priceMin != 0 && (
                    <span class="badge">
                      Min Price : {filters.priceMin}
                      <img
                        class="tag-close-icon"
                        src={closeIcon}
                        alt=""
                        onClick={(e) =>
                          this.props.setPrice(0, filters.priceMax)
                        }
                      />
                    </span>
                  )}
                  {filters.priceMax != 0 && (
                    <span class="badge">
                      Max Price : {filters.priceMax}
                      <img
                        class="tag-close-icon"
                        src={closeIcon}
                        alt=""
                        onClick={(e) =>
                          this.props.setPrice(filters.priceMin, 0)
                        }
                      />
                    </span>
                  )}
                  {filters.category && (
                    <span class="badge">
                      Category :{" "}
                      {
                        this.props.categories.find(
                          (ec) => ec._id === filters.category
                        ).title
                      }
                      <img
                        class="tag-close-icon"
                        src={closeIcon}
                        alt=""
                        onClick={(e) => {
                          this.props.handleRadio({
                            target: {
                              name: "category",
                              value: "",
                            },
                          });
                        }}
                      />
                    </span>
                  )}
                  {filters.sub_category && (
                    <span class="badge">
                      Sub Category :{" "}
                      {
                        this.props.categories
                          .find((ec) => ec._id === filters.category)
                          .sub_categories.find(
                            (ecs) => ecs._id === filters.sub_category
                          ).title
                      }
                      <img
                        class="tag-close-icon"
                        src={closeIcon}
                        alt=""
                        onClick={(e) => {
                          this.props.handleRadio({
                            target: {
                              name: "sub_category",
                              value: "",
                            },
                          });
                        }}
                      />
                    </span>
                  )}
                  {cat3 && (
                    <span class="badge">
                      Category level 3 :{" "}
                      {_.capitalize(cat3.split("-").join(" "))}
                      <img
                        class="tag-close-icon"
                        src={closeIcon}
                        alt=""
                        onClick={(e) =>
                          this.props.history.push(
                            "/products/" + cat1 + "/" + cat2
                          )
                        }
                      />
                    </span>
                  )}
                  {filters.averageRating && (
                    <span class="badge">
                      {`Average Ratings : ${filters.averageRating} ${
                        filters.averageRating === 1 ? "star" : "stars"
                      } & up `}
                      <img
                        class="tag-close-icon"
                        src={closeIcon}
                        alt=""
                        onClick={(e) =>
                          this.props.handleRadio({
                            target: {
                              name: "averageRating",
                              value: "",
                            },
                          })
                        }
                      />
                    </span>
                  )}
                </div>
              </div>
              <div class="filtermenu-body">
                {/* <div class="filtermenu-card-item">
                  <div
                    class={
                      this.state.mode
                        ? "filtermenu-card-item-header d-flex align-items-center"
                        : "filtermenu-card-item-header d-flex align-items-center collapsed"
                    }
                    data-toggle="collapse"
                    data-target="#menuItemOne"
                    aria-expanded="false"
                  >
                    <h5
                      onClick={() => this.setState({ mode: !this.state.mode })}
                    >
                      Mode
                    </h5>
                    <div class="filtermenu-right d-flex align-items-center ml-auto">
                      <a href="#!" class="filtermenu-clear-btn">
                        Clear all
                      </a>
                      <div
                        class="card-icon-box"
                        onClick={() =>
                          this.setState({ mode: !this.state.mode })
                        }
                      >
                        <img
                          class="accor-plus-icon"
                          src={this.state.mode ? minus : plus}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    id="menuItemOne"
                    class={this.state.mode ? "collapse show" : "collapse"}
                  >
                    <div class="filtermenu-card-item-body">
                      <div class="form-check text-black-color">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="mode"
                          id="mode1"
                          value=""
                          checked={!filters.mode}
                          onChange={this.props.handleRadio}
                        />
                        <label class="form-check-label" for="mode1">
                          Pickup or Delivery or Shipping
                        </label>
                      </div>
                      <div class="form-check text-black-color">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="mode"
                          id="mode2"
                          value="pickup"
                          checked={filters.mode === "pickup"}
                          onChange={this.props.handleRadio}
                        />
                        <label class="form-check-label" for="mode2">
                          Pickup only
                        </label>
                      </div>
                      <div class="form-check text-black-color">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="mode"
                          id="mode3"
                          value="delivery"
                          checked={filters.mode === "delivery"}
                          onChange={this.props.handleRadio}
                        />
                        <label class="form-check-label" for="mode3">
                          Delivery only
                        </label>
                      </div>
                      <div class="form-check text-black-color">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="mode"
                          id="mode4"
                          value="shipping"
                          checked={filters.mode === "shipping"}
                          onChange={this.props.handleRadio}
                        />
                        <label class="form-check-label" for="mode4">
                          Shipping only
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}
                {(filters.mode === "delivery" || filters.mode === "") && (
                  <div class="filtermenu-card-item">
                    <div
                      class={
                        this.state.deliveryOption
                          ? "filtermenu-card-item-header d-flex align-items-center"
                          : "filtermenu-card-item-header d-flex align-items-center collapsed"
                      }
                      data-toggle="collapse"
                      data-target="#menuItemOne"
                      aria-expanded="false"
                    >
                      <h5
                        onClick={() =>
                          this.setState({
                            deliveryOption: !this.state.deliveryOption,
                          })
                        }
                      >
                        Delivery option
                      </h5>
                      <div class="filtermenu-right d-flex align-items-center ml-auto">
                        <a href="#!" class="filtermenu-clear-btn">
                          Clear all
                        </a>
                        <div
                          class="card-icon-box"
                          onClick={() =>
                            this.setState({
                              deliveryOption: !this.state.deliveryOption,
                            })
                          }
                        >
                          <img
                            class="accor-plus-icon"
                            src={this.state.deliveryOption ? minus : plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      id="menuItemOne"
                      class={
                        this.state.deliveryOption ? "collapse show" : "collapse"
                      }
                    >
                      <div class="filtermenu-card-item-body">
                        <div class="form-check text-black-color">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="deliveryOption"
                            id="delivery1"
                            value="today"
                            onChange={this.props.handleRadio}
                            checked={filters.deliveryOption === "today"}
                          />
                          <label class="form-check-label" for="delivery1">
                            <img src={deliverIcon} alt="" /> Deliver Today *
                          </label>
                        </div>
                        <div class="form-check text-black-color">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="deliveryOption"
                            id="delivery2"
                            value="week"
                            onChange={this.props.handleRadio}
                            checked={filters.deliveryOption === "week"}
                          />
                          <label class="form-check-label" for="delivery2">
                            Within a week
                          </label>
                        </div>
                        <div class="form-check text-black-color">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="deliveryOption"
                            id="delivery3"
                            value="more"
                            onChange={this.props.handleRadio}
                            checked={filters.deliveryOption === "more"}
                          />
                          <label class="form-check-label" for="delivery3">
                            More than a week
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {(filters.mode === "pickup" || filters.mode === "") && (
                  <div class="filtermenu-card-item">
                    <div
                      class={
                        this.state.pickup
                          ? "filtermenu-card-item-header d-flex align-items-center"
                          : "filtermenu-card-item-header d-flex align-items-center collapsed"
                      }
                      data-toggle="collapse"
                      data-target="#menuItemTwo"
                      aria-expanded="false"
                    >
                      <h5
                        onClick={() =>
                          this.setState({
                            pickup: !this.state.pickup,
                          })
                        }
                      >
                        Pickup
                      </h5>
                      <div class="filtermenu-right d-flex align-items-center ml-auto">
                        <div
                          class="card-icon-box"
                          onClick={() =>
                            this.setState({
                              pickup: !this.state.pickup,
                            })
                          }
                        >
                          <img
                            class="accor-plus-icon"
                            src={this.state.pickup ? minus : plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      id="menuItemTwo"
                      class={this.state.pickup ? "collapse show" : "collapse"}
                    >
                      <div class="filtermenu-card-item-body">
                        <div class="single-range-slider">
                          <div class="range-slider">
                            <Range
                              step={1}
                              min={0}
                              max={100}
                              onMouseDown={() => alert(123)}
                              values={[
                                filters.pickupOption
                                  ? parseInt(filters.pickupOption)
                                  : 100,
                              ]}
                              onFinalChange={(values1) => {
                                this.props.handleRadio({
                                  target: {
                                    name: "pickupOption",
                                    value: values1[0],
                                  },
                                });
                              }}
                              onChange={(values) => {
                                values[0] &&
                                  this.props.handleRadio({
                                    target: {
                                      name: "pickupOption",
                                      value: values[0],
                                      noLoad: true,
                                    },
                                  });
                              }}
                              renderTrack={({ props, children }) => (
                                <div
                                  {...props}
                                  style={{
                                    ...props.style,
                                    height: "6px",
                                    width: "100%",
                                    backgroundColor: "#ccc",
                                  }}
                                >
                                  {children}
                                </div>
                              )}
                              renderThumb={({ props }) => (
                                <div
                                  {...props}
                                  style={{
                                    ...props.style,
                                    width: "20px",
                                    height: "20px",
                                    background: "#f8f9fa",
                                    border: "1px solid #297AB9",
                                    borderRadius: "15%",
                                    backgroundColor: "#f8f9fa",
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div class="extra-controls">
                            <input
                              type="text"
                              class="js-input"
                              id="minValue"
                              placeholder={filters.pickupOption}
                              value={
                                filters.pickupOption
                                  ? parseInt(filters.pickupOption)
                                  : 100
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.props.categories && this.props.categories.length > 0 && (
                  <div class="filtermenu-card-item">
                    <div
                      class={
                        this.state.category
                          ? "filtermenu-card-item-header d-flex align-items-center"
                          : "filtermenu-card-item-header d-flex align-items-center collapsed"
                      }
                      data-toggle="collapse"
                      data-target="#menuItemThree"
                      aria-expanded="false"
                    >
                      <h5
                        onClick={() =>
                          this.setState({ category: !this.state.category })
                        }
                      >
                        Categories
                      </h5>
                      <div class="filtermenu-right d-flex align-items-center ml-auto">
                        <a href="#!" class="filtermenu-clear-btn">
                          Clear all
                        </a>
                        <div
                          class="card-icon-box"
                          onClick={() =>
                            this.setState({ category: !this.state.category })
                          }
                        >
                          <img
                            class="accor-plus-icon"
                            src={this.state.category ? minus : plus}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      id="menuItemThree"
                      class={this.state.category ? "collapse show" : "collapse"}
                    >
                      <div class="filtermenu-card-item-body">
                        {this.props.categories.map((ecat) => (
                          <div class="form-check text-black-color">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="category"
                              value={ecat._id}
                              id={ecat._id}
                              onChange={(e) => {
                                this.props.handleRadio(e);
                              }}
                              checked={filters.category === ecat._id}
                            />
                            <label class="form-check-label" for={ecat._id}>
                              {ecat.title}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                {filters.category &&
                  this.props.categories.find(
                    (eds) => eds._id === filters.category
                  ) &&
                  this.props.categories.find(
                    (eds) => eds._id === filters.category
                  ).sub_categories.length > 0 && (
                    <div class="filtermenu-card-item">
                      <div
                        class={
                          this.state.subcategory
                            ? "filtermenu-card-item-header d-flex align-items-center"
                            : "filtermenu-card-item-header d-flex align-items-center collapsed"
                        }
                        data-toggle="collapse"
                        data-target="#menuItemThree"
                        aria-expanded="false"
                      >
                        <h5
                          onClick={() =>
                            this.setState({
                              subcategory: !this.state.subcategory,
                            })
                          }
                        >
                          Sub Categories
                        </h5>
                        <div class="filtermenu-right d-flex align-items-center ml-auto">
                          <a href="#!" class="filtermenu-clear-btn">
                            Clear all
                          </a>
                          <div
                            class="card-icon-box"
                            onClick={() =>
                              this.setState({
                                subcategory: !this.state.subcategory,
                              })
                            }
                          >
                            <img
                              class="accor-plus-icon"
                              src={this.state.subcategory ? minus : plus}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        id="menuItemThree"
                        class={
                          this.state.subcategory ? "collapse show" : "collapse"
                        }
                      >
                        <div class="filtermenu-card-item-body">
                          {this.props.categories
                            .find((eds) => eds._id === filters.category)
                            .sub_categories.map((ecat) => (
                              <div class="form-check text-black-color">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="sub_category"
                                  value={ecat._id}
                                  id={ecat._id}
                                  onChange={(e) => {
                                    this.props.handleRadio(e);
                                  }}
                                  checked={filters.sub_category === ecat._id}
                                />
                                <label class="form-check-label" for={ecat._id}>
                                  {ecat.title}
                                </label>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                {!(this.props.match && this.props.match.seller_id) &&
                  this.props.sellers &&
                  this.props.sellers.length > 0 && (
                    <div class="filtermenu-card-item">
                      <div
                        class={
                          this.state.seller
                            ? "filtermenu-card-item-header d-flex align-items-center"
                            : "filtermenu-card-item-header d-flex align-items-center collapsed"
                        }
                        data-toggle="collapse"
                        data-target="#menuItemFour"
                        aria-expanded="false"
                      >
                        <h5
                          onClick={() => {
                            this.setState({ seller: !this.state.seller });
                          }}
                        >
                          Sellers
                        </h5>
                        <div class="filtermenu-right d-flex align-items-center ml-auto">
                          <a href="#!" class="filtermenu-clear-btn">
                            Clear all
                          </a>
                          <div
                            class="card-icon-box"
                            onClick={() =>
                              this.setState({ seller: !this.state.seller })
                            }
                          >
                            <img
                              class="accor-plus-icon"
                              src={this.state.seller ? minus : plus}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        id="menuItemFour"
                        class={this.state.seller ? "collapse show" : "collapse"}
                      >
                        <div class="filtermenu-card-item-body">
                          {this.props.sellers.map((es) => (
                            <div class="form-group">
                              <div class="form-check text-black-color">
                                <input
                                  key={es._id}
                                  class="form-check-input"
                                  type="checkbox"
                                  value={es._id}
                                  id={es._id}
                                  onChange={(e) =>
                                    this.props.onChangeSeller(es._id)
                                  }
                                  checked={
                                    filters.sellers.find(
                                      (ef) => ef === es._id
                                    ) || false
                                  }
                                />
                                <label
                                  class="form-check-label"
                                  for={es._id}
                                  title={_.startCase(_.toLower(es.company))}
                                >
                                  {_.startCase(_.toLower(es.company))}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                <div class="filtermenu-card-item">
                  <div
                    class={
                      this.state.price
                        ? "filtermenu-card-item-header d-flex align-items-center"
                        : "filtermenu-card-item-header d-flex align-items-center collapsed"
                    }
                    data-toggle="collapse"
                    data-target="#menuItemFive"
                    aria-expanded="false"
                  >
                    <h5
                      onClick={() =>
                        this.setState({ price: !this.state.price })
                      }
                    >
                      Price
                    </h5>
                    <div class="filtermenu-right d-flex align-items-center ml-auto">
                      <a href="#!" class="filtermenu-clear-btn">
                        Clear all
                      </a>
                      <div
                        class="card-icon-box"
                        onClick={() =>
                          this.setState({ price: !this.state.price })
                        }
                      >
                        <img
                          class="accor-plus-icon"
                          src={this.state.price ? minus : plus}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    id="menuItemFive"
                    class={this.state.price ? "collapse show" : "collapse"}
                  >
                    <div class="filtermenu-card-item-body">
                      <div class="form-group">
                        <div class="form-check text-black-color">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value={filters.priceMax == 10 ? 0 : 10}
                            id="priceMax"
                            name="priceMax"
                            onChange={this.props.handleRadio}
                            checked={filters.priceMax == 10}
                          />
                          <label class="form-check-label" for="priceMax">
                            Under $10
                          </label>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-check text-black-color">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value={0}
                            id={"prices0"}
                            onChange={(e) => this.props.onChangePrices("0")}
                            checked={
                              filters.prices.find((ef) => ef == "0") || false
                            }
                          />
                          <label class="form-check-label" for="prices0">
                            $10 - $50
                          </label>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-check text-black-color">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value={1}
                            id={"prices1"}
                            onChange={(e) => this.props.onChangePrices("1")}
                            checked={
                              filters.prices.find((ef) => ef == "1") || false
                            }
                          />
                          <label class="form-check-label" for="prices1">
                            $50 - $100
                          </label>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-check text-black-color">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value={2}
                            id={"prices2"}
                            onChange={(e) => this.props.onChangePrices("2")}
                            checked={
                              filters.prices.find((ef) => ef == "2") || false
                            }
                          />
                          <label class="form-check-label" for="prices2">
                            $100 - $200
                          </label>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-check text-black-color">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value={filters.priceMin == 200 ? 0 : 200}
                            id={"priceMin"}
                            name="priceMin"
                            onChange={this.props.handleRadio}
                            checked={filters.priceMin == 200}
                          />
                          <label class="form-check-label" for="priceMin">
                            Over $200
                          </label>
                        </div>
                      </div>
                      <div class="multiple-input-group d-flex align-items-center">
                        <div class="mig-input flex-fill w-auto h-100">
                          <input
                            type="number"
                            min="0"
                            id="minValue"
                            placeholder={"Min"}
                            onChange={(e) =>
                              this.setState({ priceMin: e.target.value })
                            }
                            value={this.state.priceMin}
                          />
                        </div>
                        <div class="mig-input flex-fill w-auto h-100">
                          <input
                            type="number"
                            id="maxValue"
                            placeholder={"Max"}
                            onChange={(e) => {
                              this.setState({ priceMax: e.target.value });
                            }}
                            value={this.state.priceMax}
                          />
                        </div>
                        <div
                          class="mig-btn h-100"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.changePriceMinMax(
                              this.state.priceMin,
                              this.state.priceMax
                            );
                          }}
                        >
                          Go
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="filtermenu-card-item">
                  <div
                    class={
                      this.state.averageRating
                        ? "filtermenu-card-item-header d-flex align-items-center"
                        : "filtermenu-card-item-header d-flex align-items-center collapsed"
                    }
                    data-toggle="collapse"
                    data-target="#menuItemSix"
                    aria-expanded="false"
                  >
                    <h5
                      onClick={() =>
                        this.setState({
                          averageRating: !this.state.averageRating,
                        })
                      }
                    >
                      Average review rating
                    </h5>
                    <div class="filtermenu-right d-flex align-items-center ml-auto">
                      <a href="#!" class="filtermenu-clear-btn">
                        Clear all
                      </a>
                      <div
                        class="card-icon-box"
                        onClick={() =>
                          this.setState({
                            averageRating: !this.state.averageRating,
                          })
                        }
                      >
                        <img
                          class="accor-plus-icon"
                          src={this.state.averageRating ? minus : plus}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    id="menuItemSix"
                    class={
                      this.state.averageRating ? "collapse show" : "collapse"
                    }
                  >
                    <div class="filtermenu-card-item-body">
                      <div class="form-group">
                        <div class="form-check text-black-color">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="averageRating"
                            id="averageRating4"
                            value="4"
                            onChange={this.props.handleRadio}
                            checked={filters.averageRating == "4"}
                          />
                          <label class="form-check-label" for="averageRating4">
                            4 Stars & up
                          </label>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-check text-black-color">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="averageRating"
                            id="averageRating3"
                            value="3"
                            onChange={this.props.handleRadio}
                            checked={filters.averageRating == "3"}
                          />
                          <label class="form-check-label" for="averageRating3">
                            3 Stars & up
                          </label>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-check text-black-color">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="averageRating"
                            id="averageRating2"
                            value="2"
                            onChange={this.props.handleRadio}
                            checked={filters.averageRating == "2"}
                          />
                          <label class="form-check-label" for="averageRating2">
                            2 Stars & up
                          </label>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-check text-black-color">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="averageRating"
                            id="averageRating1"
                            value="1"
                            onChange={this.props.handleRadio}
                            checked={filters.averageRating == "1"}
                          />
                          <label class="form-check-label" for="averageRating1">
                            1 Stars & up
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="filtermenu-card-item">
                  <div
                    class={
                      this.state.numberOfReviews
                        ? "filtermenu-card-item-header d-flex align-items-center"
                        : "filtermenu-card-item-header d-flex align-items-center collapsed"
                    }
                    data-toggle="collapse"
                    data-target="#menuItemSevan"
                    aria-expanded="false"
                  >
                    <h5
                      onClick={() =>
                        this.setState({
                          numberOfReviews: !this.state.numberOfReviews,
                        })
                      }
                    >
                      Number of reviews
                    </h5>
                    <div class="filtermenu-right d-flex align-items-center ml-auto">
                      <a href="#!" class="filtermenu-clear-btn">
                        Clear all
                      </a>
                      <div
                        class="card-icon-box"
                        onClick={() =>
                          this.setState({
                            numberOfReviews: !this.state.numberOfReviews,
                          })
                        }
                      >
                        <img
                          class="accor-plus-icon"
                          src={this.state.numberOfReviews ? minus : plus}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    id="menuItemSevan"
                    class={
                      this.state.numberOfReviews ? "collapse show" : "collapse"
                    }
                  >
                    <div class="filtermenu-card-item-body">
                      <div class="form-group">
                        <div class="form-check text-black-color">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="numberOfReviews"
                            id="numberOfReviews6"
                            value="1000"
                            onChange={this.props.handleRadio}
                            checked={filters.numberOfReviews == "1000"}
                          />
                          <label
                            class="form-check-label"
                            for="numberOfReviews6"
                          >
                            1000+
                          </label>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-check text-black-color">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="numberOfReviews"
                            id="numberOfReviews5"
                            value="500"
                            onChange={this.props.handleRadio}
                            checked={filters.numberOfReviews == "500"}
                          />
                          <label
                            class="form-check-label"
                            for="numberOfReviews5"
                          >
                            500+
                          </label>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-check text-black-color">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="numberOfReviews"
                            id="numberOfReviews4"
                            value="200"
                            onChange={this.props.handleRadio}
                            checked={filters.numberOfReviews == "200"}
                          />
                          <label
                            class="form-check-label"
                            for="numberOfReviews4"
                          >
                            200+
                          </label>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-check text-black-color">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="numberOfReviews"
                            id="numberOfReviews3"
                            value="100"
                            onChange={this.props.handleRadio}
                            checked={filters.numberOfReviews == "100"}
                          />
                          <label
                            class="form-check-label"
                            for="numberOfReviews3"
                          >
                            100+
                          </label>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-check text-black-color">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="numberOfReviews"
                            id="numberOfReviews2"
                            value="50"
                            onChange={this.props.handleRadio}
                            checked={filters.numberOfReviews == "50"}
                          />
                          <label
                            class="form-check-label"
                            for="numberOfReviews2"
                          >
                            50+
                          </label>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-check text-black-color">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="numberOfReviews"
                            id="numberOfReviews1"
                            value="25"
                            onChange={this.props.handleRadio}
                            checked={filters.numberOfReviews == "25"}
                          />
                          <label
                            class="form-check-label"
                            for="numberOfReviews1"
                          >
                            25+
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SimpleBar>
          </div>
        </div>
      </div>
    );
  }
}

export default FilterModal;
