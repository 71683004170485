import { combineReducers } from "redux";

import sellerReducer from "./seller";
import productReducer from "./product";
import contactReducer from "./contact";
import citiesReducer from "./cities";
import couponsReducer from "./coupons";
import customerReducer from "./customer";
import cartReducer from "./cart";

export default combineReducers({
  customer: customerReducer,
  seller: sellerReducer,
  product: productReducer,
  contact: contactReducer,
  cities: citiesReducer,
  coupons: couponsReducer,
  cart: cartReducer,
});
