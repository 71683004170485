import React, { Component } from "react";
import Vector from "../../include/images/Vector.svg";
import close from "../../include/images/close.svg";
import thumb1 from "../../include/images/aim 1.svg";
import { GoogleApiWrapper } from "google-maps-react";
import map from "../../include/images/aim 1.svg";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import ReactTooltip from "react-tooltip";
import AddressFlyer from "./addressFlyer";

class GridSearch extends Component {
  state = {
    adressChange: false,
    param: JSON.parse(localStorage.getItem("customerDddress")),
  };
  handleSelect = (address) => {
    geocodeByAddress(address).then((results) => {
      const postal_code = results[0].address_components.find(
        (es) => es.types[0] === "postal_code"
      );

      getLatLng(results[0])
        .then((latLng) => {
          this.props.updateLatLng(latLng.lat, latLng.lng, address, postal_code);
          this.props.handleChange(address);
        })
        .catch((error) => console.error("Error", error));
    });
  };
  handleadressChange = () => {
    this.setState({ adressChange: !this.state.adressChange });
    document.body.classList.add("toggled-tab");
  };
  handleClose = () => {
    this.setState(
      {
        adressChange: !this.state.adressChange,
      },
      () =>
        this.props.loadCart(this.state.user_device_id, {
          address: this.state.param && this.state.param.address,
          zipCode: this.state.param && this.state.param.postal_code.long_name,
          lat: this.state.param && this.state.param.lat,
          lon: this.state.param && this.state.param.lng,
        })
    );
    document.body.classList.remove("toggled-tab");
  };
  render() {
    const addReddArr = this.props.address.split(",");
    return (
      <>
        <ReactTooltip className="highIndex" />
        <div className="search-bar-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="sbc-box d-flex align-items-center justify-content-between">
                  <div className="form-check-outer flex-wrap d-flex align-items-center">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="pickup"
                        checked={this.props.pickup}
                        onChange={() =>
                          this.props.updateCheck("pickup", !this.props.pickup)
                        }
                      />
                      <label className="form-check-label" for="pickup">
                        Pickup
                      </label>
                    </div>
                    <div
                      className="form-check"
                      data-tip="For orders placed by noon"
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="deliverToday"
                        checked={this.props.delivery_option_value.find(
                          (e) => e === "Same day delivery"
                        )}
                        onChange={() =>
                          this.props.updateCheck(
                            "Same day delivery",
                            this.props.delivery_option_value.find(
                              (e) => e === "Same day delivery"
                            )
                          )
                        }
                      />
                      <label className="form-check-label" for="deliverToday">
                        Deliver today{" "}
                      </label>
                      <button
                        id="pover-second"
                        className="btn-info"
                        data-toggle="popover"
                        data-trigger="focus"
                        data-placement="top"
                      >
                        *
                      </button>
                      <div className="popover-content">
                        <div id="popover-second">Order placed by 12:00 PM</div>
                      </div>
                    </div>
                    <div
                      className="form-check home-filter"
                      onClick={() => this.handleadressChange()}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      <img src={map} alt="" className="mr-2" />
                      {addReddArr.length > 4
                        ? `${addReddArr[0]}, ${addReddArr[1]}, ${addReddArr[2]}, ${addReddArr[3]} ${this.props.postal_code}`
                        : `${addReddArr[0]}, ${addReddArr[1]}, ${addReddArr[2]} ${this.props.postal_code}`}
                    </div>
                  </div>
                  <ul className="view-type">
                    <li className={this.props.tab === "A" ? "active" : ""}>
                      <a
                        href="javascript:void(0)"
                        className="lest-type"
                        onClick={this.props.changeTabA}
                      >
                        <b></b>
                      </a>
                    </li>
                    <li className={this.props.tab === "D" ? "active" : ""}>
                      <a
                        href="javascript:void(0)"
                        className="grid-type"
                        onClick={this.props.changeTabD}
                      >
                        <b></b>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.adressChange && (
          <AddressFlyer
            handleClose={this.handleClose}
            updateLatLng={this.props.updateLatLng}
          />
        )}
      </>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyDHZd1NTh7pJw6-zGe1Mf_cUBx2cIhz46Y",
})(GridSearch);
