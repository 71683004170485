import React, { createRef } from "react";
import CustomerSidebar from "../../common/customer/customerSidebar";
import Header from "../../productV2/header";
import loadingIcon from "../../../include/images/loading-icon.svg";
import delete1 from "../../../include/images/delete1.svg";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Footer from "../../common/footer";
import closeBtn from "../../../include/images/close.svg";

import {
  loadCustomerProfile,
  getCustomer,
  customerUpdate,
  addCard,
  removeCard,
} from "../../../store/customer";

import {
  firstNameRequired,
  lastNameRequired,
  emailRequired,
} from "../../common/misc";
import SubmitBtn from "../../common/form/submitBtn";

import { toast } from "react-toastify";
import Joi from "joi-browser";

import AlertSuccess from "../../common/alertSuccess";
import AlertError from "../../common/alertError";
import Form from "../../common/form/form";

import LoadingBar from "react-top-loading-bar";
import { Modal } from "react-bootstrap";

class CardDetails extends Form {
  ref = createRef(null);
  stripe = null;

  state = {
    popup: false,
    payment_method_id: null,
    data: {
      firstName: "",
      lastName: "",
      email: "",
      code: 0,
      mobile: "",
    },
    loading: false,
    errors: {},
  };

  schema = {
    firstName: Joi.string()
      .required()
      .label("First Name")
      .error(() => {
        return { message: firstNameRequired };
      }),

    lastName: Joi.string()
      .required()
      .label("Last Name")
      .error(() => {
        return { message: lastNameRequired };
      }),

    email: Joi.string()
      .email()
      .max(50)
      .required()
      .label("Email")
      .error(() => {
        return { message: emailRequired };
      }),
    code: Joi.number().allow("").optional(),
    mobile: Joi.number().allow("").optional(),
  };
  doSubmit = async (e) => {
    e.preventDefault();
    this.ref.current.continuousStart();
    this.setState({ loader: true });
    const { data } = this.state;
    const result = await this.stripe.createPaymentMethod({
      type: "card",
      card: this.state.cardElement,
      billing_details: {
        name: data.first_name + " " + data.last_name,
      },
    });
    this.stripePaymentMethodHandler(result);
  };
  stripePaymentMethodHandler = async (result) => {
    if (result.error) {
      toast(<AlertError message={result.error.message} />);
      this.ref.current.complete();
      this.setState({ loader: false });
    } else {
      console.log(result.paymentMethod.id);
      this.props.addCard(
        { payment_method_id: result.paymentMethod.id },
        this.callback
      );
    }
  };

  componentDidMount = () => {
    this.props.loadCustomerProfile();
    this.ref.current.continuousStart();
    this.stripe = window.Stripe(process.env.REACT_APP_STRIPEKEY);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.profileLoading &&
      this.props.profileLoading !== prevProps.profileLoading &&
      this.props.customerProfile.cards.length === 0
    ) {
      var elements = this.stripe.elements();
      var style = {
        base: {
          lineHeight: "1.429",

          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "##6c757d",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      };
      var cardElement = elements.create("card", {
        hidePostalCode: true,
        style: style,
      });
      cardElement.mount("#card-element");
      this.setState({ cardElement });
    }
    if (
      !this.props.profileLoading &&
      this.props.profileLoading !== prevProps.profileLoading
    ) {
      const {
        first_name: firstName,
        last_name: lastName,
        mobile,
        email,
        code,
      } = this.props.customerProfile;

      const mobileNumber = code + JSON.stringify(mobile);

      const data = {
        firstName,
        lastName,
        mobile: mobileNumber,
        email,
      };

      this.setState({ data });
    }
    this.ref.current.complete();
  };

  deleteCard = (e, id) => {
    e.preventDefault();
    this.setState({ payment_method_id: id });
    this.handlePopup();
  };

  confirmDelete = (e) => {
    e.preventDefault();
    this.props.removeCard(
      { payment_method_id: this.state.payment_method_id },
      this.callback
    );
    this.handlePopup();
  };

  callback = (res) => {
    this.props.loadCustomerProfile();
    this.setState({
      loader: false,
      label: "Save Changes",
      btnClass: "btn btn-default",
    });
    if (res.status === 200) {
      toast(<AlertSuccess message={"Information Updated"} />);
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };
  handlePopup = (e) => {
    if (e) e.preventDefault();
    this.setState({ popup: !this.state.popup });
  };

  render() {
    return (
      <>
        <LoadingBar color="#0b4474" ref={this.ref} />
        <Header />
        <>
          <main id="main">
            <div className="content-container">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="conetnt-box d-md-flex flex-md-wrap">
                      <CustomerSidebar />
                      <div className="main-content-box">
                        <h3>Card info</h3>
                        <div className="table-responsive expand-table">
                          {this.props.customerProfile.cards &&
                          this.props.customerProfile.cards.length > 0 ? (
                            <table className="table small mb-0 no-total-td ">
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      width: "50%",
                                    }}
                                  >
                                    Card no
                                  </th>
                                  <th>Expiry</th>
                                  <th style={{ width: "10%" }}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.customerProfile.cards.map((e) => (
                                  <tr>
                                    <td style={{ paddingLeft: "0" }}>
                                      XXXX XXXX XXXX {e.last4}
                                    </td>
                                    <td
                                      style={{
                                        paddingLeft: "15px",
                                      }}
                                    >
                                      {e.exp_month + "/" + e.exp_year}
                                    </td>
                                    <td>
                                      <a
                                        href="#"
                                        onClick={(e) =>
                                          this.deleteCard(
                                            e,
                                            this.props.customerProfile.cards[0]
                                              .id
                                          )
                                        }
                                        className="delete"
                                      >
                                        <img
                                          width="30px"
                                          src={delete1}
                                          alt="delete"
                                        />
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <>
                              <div className="form-group">
                                <div
                                  id="card-element"
                                  className="form-control"
                                  style={{
                                    paddingTop: ".7em",
                                  }}
                                ></div>
                              </div>
                              <div className="form-group sidebar-btn">
                                {this.state.loader ? (
                                  <button className="sidebar-default-btn btn btn-default btn-loading disabled">
                                    <img src={loadingIcon} alt="" />
                                  </button>
                                ) : (
                                  <a
                                    href=""
                                    onClick={this.doSubmit}
                                    className="btn btn-default"
                                  >
                                    Save
                                  </a>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </main>
          <Modal
            show={this.state.popup}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <div className="modal-header">
              <button
                type="button"
                className="close mt-2"
                data-dismiss="modal"
                onClick={this.handlePopup}
              >
                {" "}
                <img src={closeBtn} alt="close popup" />
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-alert-box">
                <h3 className="mb-6">Alert!</h3>
                <p>Are you sure you want to delete this card?</p>
                <p>This action cannot be reverted.</p>
              </div>
              <div className="modal-action-btn">
                <a
                  href="javascript:void(0)"
                  data-dismiss="modal"
                  className="btn btn-delete"
                  onClick={this.confirmDelete}
                >
                  Remove
                </a>
                <a
                  href="#!"
                  className="btn btn-cancel"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.handlePopup}
                >
                  Cancel
                </a>
              </div>
            </div>
          </Modal>
        </>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customerProfile: getCustomer(state).profile,
  profileLoading: getCustomer(state).profileLoading,
});

const mapDispatchToProps = (dispatch) => ({
  customerUpdate: (data, callback) => dispatch(customerUpdate(data, callback)),
  addCard: (data, callback) => dispatch(addCard(data, callback)),
  removeCard: (data, callback) => dispatch(removeCard(data, callback)),
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CardDetails)
);
