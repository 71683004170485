import React from "react";

import uploadIcon from "../../../include/images/upload-csv-icon.svg";
import csvFileImg from "../../../include/images/csv-file.svg";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { toast } from "react-toastify";
import AlertError from "../alertError";

const buttonRef = React.createRef();

class UploadModal1 extends React.Component {
  state = {
    fileUploaded: false,
    uploadData: {
      product_name: "",
      description: "",
      category: "",
      quantity: "",
      price: "",
      product_id: "",
    },
  };

  handleMapContent = () => {
    if (this.state.uploadData.length !== 6) {
      toast(<AlertError message={"Please upload a CSV with correct format"} />);
    } else {
      this.props.setStep(2);
      this.props.updateFormData(this.state.uploadData);
    }
  };

  handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  handleOnFileLoad = (data, file) => {
    this.setState({
      fileUploaded: true,
      uploadData: data[0].data.map((d) => d),
    });
    this.props.updateFile(file);
  };

  handleOnError = (err, data, file, inputElem, reason) => {
    toast(<AlertError message={err} />);
  };

  render() {
    return (
      <>
        <div className="modal-body">
          <div className="mob-product-nav d-block d-md-none">
            <ul className="product-nav-list d-flex align-items-center">
              <li className="active">
                <a href="#!" className="pn-link">
                  Choose
                </a>
              </li>
              <li>
                <a href="#!" className="pn-link">
                  Map
                </a>
              </li>
              <li>
                <a href="#!" className="pn-link">
                  Setup
                </a>
              </li>
            </ul>
          </div>

          <CSVReader
            ref={buttonRef}
            configOptions={{ header: true }}
            onFileLoad={this.handleOnFileLoad}
            onError={this.handleOnError}
            noClick
            noDrag
          >
            {({ file }) =>
              !file ? (
                <>
                  <div className="modal-icon-box">
                    <img src={uploadIcon} alt="" />
                  </div>
                  <h4>Upload a .csv file</h4>
                  <div className="modal-text-box">
                    <p>
                      Please upload a CSV file as per specified format. Make
                      sure to download a sample file to learn more about the
                      correct file format.
                    </p>
                  </div>
                  <div className="modal-btn-box">
                    <button
                      className="btn btn-default"
                      type="button"
                      id="choosefile"
                      onClick={this.handleOpenDialog}
                    >
                      Choose a .csv file
                    </button>
                    <CSVDownloader
                      type="button"
                      data={[
                        {
                          "Product name":
                            "New Apple iPhone 12 (128GB) - (Product) RED",
                          Description:
                            "6.1-inch (15.5 cm diagonal) Super Retina XDR display Ceramic Shield, tougher than any smartphone glass A14 Bionic chip, the fastest chip ever in a smartphone Advanced dual-camera system with 12MP Ultra Wide and Wide cameras; Night mode, Deep Fusion, Smart HDR 3, 4K Dolby Vision HDR recording 12MP TrueDepth front camera with Night mode, 4K Dolby Vision HDR recording Industry-leading IP68 water resistance Supports MagSafe accessories for easy attach and faster wireless charging iOS with redesigned widgets on the Home screen, all-new App Library, App Clips and more",
                          "Product ID": "APPLEIPRED",
                          Category: "Electronics",
                          Quantity: "10",
                          "Price(In $)": "1200",
                        },
                      ]}
                      className="btn btn-outline"
                      filename={"Sample"}
                      bom={true}
                    >
                      Download sample
                    </CSVDownloader>
                  </div>
                </>
              ) : (
                <div className="modal-body">
                  <div className="modal-icon-box">
                    <img src={csvFileImg} alt="" />
                  </div>
                  <h5> {file && file.name}</h5>
                  <div className="modal-btn-box">
                    <button
                      className="btn btn-outline"
                      id="choosefile"
                      onClick={this.handleOpenDialog}
                    >
                      Choose different file
                      <input
                        type="file"
                        className="form-control-file"
                        id="chooseFile"
                      />
                    </button>
                  </div>
                </div>
              )
            }
          </CSVReader>
        </div>

        <div className="modal-footer">
          <button
            onClick={() => {
              this.handleMapContent();
            }}
            type="button"
            className={
              this.state.fileUploaded
                ? "btn btn-default btn-xs"
                : "btn btn-default disabled btn-xs"
            }
          >
            Next
          </button>
        </div>
      </>
    );
  }
}

export default UploadModal1;
