import React, { Component } from "react";
import vector from "../../include/images/Vector.svg";
import { getProduct, loadAllProduct } from "../../store/product";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";

const initialState = {
  search: "",
};

class Search extends Component {
  state = initialState;
  onSearch = (e) => {
    if (this.state.search) {
      this.props.loadAllProduct({ search: this.state.search });
    }
  };
  onKeyPress = (e) => {
    if (e.which === 13 && this.state.search) {
      this.props.loadAllProduct({ search: this.state.search });
    }
  };
  reset = () => {
    this.props.loadAllProduct({ page: this.props.currentPage });
    this.setState(initialState);
  };
  render() {
    return (
      <div className="search-item-container">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="search-item-content d-flex flex-column flex-md-row align-items-md--center justify-content-end justify-content-md-between">
                <div className="d-md-flex align-items-center">
                  <div
                    className="input-group"
                    onKeyPress={this.onKeyPress}
                    onClick={this.onSearch}
                  >
                    <div
                      className="input-group-prepend"
                      onKeyPress={this.onKeyPress}
                      onClick={this.onSearch}
                    >
                      <span className="input-group-text" id="basic-addon1">
                        <img src={vector} alt="" />
                      </span>
                    </div>
                    <input
                      name="search"
                      id="search"
                      className="form-control"
                      placeholder="Search here"
                      autoComplete="off"
                      value={this.state.search}
                      onChange={(e) =>
                        this.setState({ search: e.target.value })
                      }
                    />
                  </div>
                  <div className="showing-result">
                    {this.props.filtered_record > 50 ? (
                      <>
                        Showing <strong></strong>
                        {this.props.total_record_on_current_page} of{" "}
                        <strong>{this.props.filtered_record}</strong> result
                      </>
                    ) : (
                      <>
                        Showing <strong></strong>
                        {this.props.filtered_record} results
                      </>
                    )}
                  </div>
                </div>

                <div className="showing-result d-flex align-items-center justify-content-end">
                  <a
                    href="javascript:void(0)"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.toggleUploadProducts();
                    }}
                    className="btn btn-outline btn-sm"
                    data-toggle="modal"
                    data-target="#uploadProduct"
                  >
                    + Upload Products
                  </a>

                  <a
                    href="javascript:void(0)"
                    className="btn btn-default btn-xs"
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        this.props.total_record >= this.props.totalItemsAllowed
                      ) {
                        toast(
                          <AlertError
                            message={`You cannot add more than ${this.props.totalItemsAllowed} products.`}
                          />
                        );
                      } else {
                        this.props.toggleAdd();
                      }
                    }}
                  >
                    + Add Product
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  product: getProduct(state),
});
const mapDispatchToProps = (dispatch) => ({
  loadAllProduct: (params) => dispatch(loadAllProduct(params)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Search);
