import React from "react";
import Joi from "joi-browser";
import Form from "../../common/form/form";
import SubmitBtn from "../../common/form/submitBtn";

import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import SidebarCustomer from "../../common/customer/sideBarCustomer";
import { Link, withRouter } from "react-router-dom";
import {
  sendMobileOtp,
  mobileAvailability,
  emailAvailability,
} from "../../../store/customer";

import AlertSuccess from "../../common/alertSuccess";
import AlertError from "../../common/alertError";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import {
  confirmPasswordMatch,
  confirmPasswordRequired,
  passwordInvalid,
  passwordInvalidMax,
  passwordRequired,
  firstNameRequired,
  lastNameRequired,
  emailRequired,
  codeRequired,
  mobileRequired,
  mobileInvalid,
  emailInvalid,
} from "../../common/misc";

class SignUpForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      labelRegister: "Register",
      loader: false,
      btnClass: "btn btn-default",
      data: {
        firstName: "",
        lastName: "",
        email: "",
        code: "+1",
        mobile: "",
        password: "",
        confirmPassword: "",
      },
      errors: {},
    };
  }

  componentDidMount = () => {
    document.body.classList.add("pt-0");

    if (this.props.verifyMobile.mobile) {
      this.setState({
        data: {
          code: this.props.formData.code.toString(),
          mobile: this.props.formData.mobile.toString(),
          email: this.props.formData.email,
          password: this.props.formData.password,
          firstName: this.props.formData.firstName,
          lastName: this.props.formData.lastName,
          confirmPassword: this.props.formData.confirmPassword,
        },
      });
    }
  };

  componentWillUnmount = () => {
    document.body.classList.remove("pt-0");
  };

  handleOnChange = (value, country) => {
    const { data } = this.state;
    // data.mobile = value;
    // data.mobile = value.replace(country.dialCode, "");
    data.code = country.dialCode;
    this.setState({ data });
  };

  schema = {
    confirmPassword: Joi.string()
      .required()
      .label("Confirm Password")
      .valid(Joi.ref("password"))
      .options({
        language: {
          any: {
            allowOnly: "!!" + confirmPasswordMatch,
            empty: "!!" + confirmPasswordRequired,
          },
        },
      }),

    password: Joi.string()
      .min(8)
      .max(25)
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "password"
      )
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.regex.name":
              err.message = passwordInvalid;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;

            case "string.max":
              err.message = passwordInvalidMax;
              break;

            default:
          }
        });
        return errors;
      }),
    firstName: Joi.string()
      .required()
      .label("First Name")
      .error(() => {
        return { message: firstNameRequired };
      }),

    lastName: Joi.string()
      .required()
      .label("Last Name")
      .error(() => {
        return { message: lastNameRequired };
      }),

    email: Joi.string()
      .email()
      .max(50)
      .required()
      .regex(/\S+@\S+\.\S+/, "email")
      .label("Email")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.required":
              err.message = emailRequired;
              break;

            case "any.empty":
              err.message = emailRequired;
              break;

            case "string.email":
              err.message = emailInvalid;
              break;

            case "string.regex.name":
              err.message = emailInvalid;
              break;

            case "string.min":
              err.message = emailInvalid;
              break;

            default:
          }
        });
        return errors;
      }),
    code: Joi.number()
      .required()
      .error(() => {
        return { message: codeRequired };
      }),
    mobile: Joi.number()
      .required()
      .label("Mobile Number")
      .min(1000000000)
      .max(999999999999999)
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "number.base":
              err.message = mobileRequired;
              break;

            case "number.max":
              err.message = mobileInvalid;
              break;

            case "number.min":
              err.message = mobileInvalid;
              break;

            default:
          }
        });
        return errors;
      }),
  };

  doSubmit = () => {
    if (!this.state.loader) {
      this.setState({
        labelRegister: "",
        loader: true,
        btnClass: "btn btn-default disabled",
      });
      const emailParams = {
        email: this.state.data.email,
      };
      this.props.emailAvailability(emailParams, this.callbackEmail);
    }
  };

  callbackEmail = (res) => {
    this.setState({
      loader: false,
      labelRegister: "Register",
      btnClass: "btn btn-default",
    });
    if (res.status === 200) {
      const mobileParams = {
        mobile: this.state.data.mobile,
      };
      this.props.mobileAvailability(mobileParams, this.callbackMobile);
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };
  callbackMobile = (res) => {
    this.setState({
      loader: false,
      labelRegister: "Register",
      btnClass: "btn btn-default",
    });
    if (res.status === 200) {
      const params = {
        mobile: this.state.data.mobile,
        code: this.state.data.code,
      };
      this.props.sendMobileOtp(params, this.otpMobileCallBack);
    } else {
      toast(<AlertError message={"Please re-check the mobile number"} />);
    }
  };

  otpMobileCallBack = (res) => {
    this.setState({
      loader: false,
      labelRegister: "Register",
      btnClass: "btn btn-default",
    });
    if (res.status === 200) {
      if (res.data.otp) {
        toast(<AlertSuccess message={"Mobile OTP:" + res.data.otp} />);
      } else {
        toast(
          <AlertSuccess message={"We have sent OTP to your mobile number."} />
        );
      }
      this.props.updateStep(2);
      this.props.updateFormData(this.state.data);
      this.props.updateVerifyMobile({
        otp: res.data.otp,
        mobile: this.state.data.mobile,
        code: this.state.data.code,
      });
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  render() {
    return (
      <>
        <SidebarCustomer />
        <div className="member-right">
          <div className="signin-form-container">
            <div className="signin-form">
              <h2>Sign Up</h2>

              <div className="already-login">
                Already have an account?&nbsp;
                <Link to="/customer/login" className="sep-login">
                  Login
                </Link>
              </div>

              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      {this.renderInput("firstName", "First Name")}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      {this.renderInput("lastName", "Last Name")}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  {this.renderInput("email", "Email")}
                </div>

                <div className="row">
                  <div className="col-md-6 country-code-input">
                    <div className="form-group">
                      <label htmlFor="mobile">Country Code</label>
                      <ReactPhoneInput
                        placeholder=""
                        country={"us"}
                        value={this.state.data.code}
                        onChange={this.handleOnChange}
                        inputClass={"country-code"}
                      />
                      {/* {this.state.errors.mobile && (
                        <div className="mobile-error" style={{ color: "red" }}>
                          {this.state.errors.mobile}
                        </div>
                      )}{" "} */}
                    </div>
                  </div>

                  <div className="col-md-6 mobile-num">
                    <div className="form-group">
                      {this.renderInput("mobile", "Mobile Number")}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      {this.renderPasswordInput("password", "Password")}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      {this.renderPasswordInput(
                        "confirmPassword",
                        "Confirm Password"
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group agree">
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="agree">
                      By clicking register button, I agree to{" "}
                      <a
                        className="termsConditions"
                        href="https://effisim.info/terms-conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        terms &amp; conditions{" "}
                      </a>{" "}
                      and&nbsp;
                      <a
                        className="termsConditions"
                        href="https://effisim.info/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        privacy policy
                      </a>
                    </label>
                  </div>
                </div>

                <div className="form-group form-button">
                  <SubmitBtn
                    label={this.state.labelRegister}
                    loading={this.state.loader}
                    btnClass={this.state.btnClass}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  mobileAvailability: (param, callback) =>
    dispatch(mobileAvailability(param, callback)),
  emailAvailability: (param, callback) =>
    dispatch(emailAvailability(param, callback)),
  sendMobileOtp: (param, callback) => dispatch(sendMobileOtp(param, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(SignUpForm));
