import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  getCustomerToken,
  getSellerToken,
} from "../services/localStorageServices";

class Copyright extends Component {
  state = {};
  render() {
    return (
      <div className="copyright">
        &#169;2021-{new Date().getFullYear()} Effisim Corp. All rights reserved.{" "}
        <a href="https://effisim.info/terms-conditions" target="_blank">
          Terms & Conditions
        </a>{" "}
        |{" "}
        <a href="https://effisim.info/privacy-policy" target="_blank">
          Privacy Policy
        </a>{" "}
        |{" "}
        <a href="https://effisim.info/FAQ" target="_blank">
          FAQ
        </a>
        {getCustomerToken("auth") ? (
          <></>
        ) : (
          <>
            {getSellerToken("auth") ? (
              ""
            ) : (
              <>
                {" "}
                |{" "}
                <Link to="/seller/login" target="_blank">
                  Seller Login
                </Link>{" "}
                |{" "}
                <Link to="/seller/registration" target="_blank">
                  Seller Registration
                </Link>
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

export default Copyright;
