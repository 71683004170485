import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import InputCityState from "./inputCityState";
import DateInput from "./dateInput";
import Password from "./password";
import EditInput from "./editInput";
import EditSelect from "./editSelect";
import Select from "./select";
import LoginPassword from "./loginPassword";
import Textarea from "./textarea";
import Address2Input from "./address2Input";

class Form extends Component {
  state = {
    data: {},
    errors: {},
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema, { abortEarly: false });
    return error ? error.details[0].message : null;
  };

  setErrors = (input) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) {
      errors[input.name] = errorMessage;
    } else {
      delete errors[input.name];
    }

    if (input.name === "confirmPassword") {
      if (this.state.data.password === input.value) {
        delete errors[input.name];
      }
    }
    this.setState({ errors });
  };

  setData = (input) => {
    if (input.name) {
      const data = { ...this.state.data };
      if (input.name === "zipcode") {
        data[input.name] = input.value.replace(/\D/, "");
        this.setState({ data });
      } else {
        data[input.name] = input.value;
        this.setState({ data });
      }
    }
  };

  handleSubmitZip = (e) => {
    e.preventDefault();
    this.doSubmitZip();
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  handleChangeSelect = ({ currentTarget: input }) => {
    this.setErrors(input);
    this.setData(input);
  };

  handleChange = ({ currentTarget: input }) => {
    this.setData(input);
  };

  handleBlur = ({ currentTarget: input }) => {
    this.setErrors(input);
  };

  renderButton(label) {
    return (
      <button disabled={this.validate()} className="btn btn-primary">
        {label}
      </button>
    );
  }

  renderSelect(name, label, options, defaultValue, disabled) {
    const { data, errors } = this.state;

    return (
      <Select
        disabled={disabled}
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChangeSelect}
        error={errors[name]}
        defaultValue={defaultValue}
      />
    );
  }

  renderDateInput(name, label, options, defaultValue) {
    const { data, errors } = this.state;
    return (
      <DateInput
        name={name}
        showYearDropdown
        showMonthDropdown
        selected={data[name]}
        label={label}
        options={options}
        onChange={(date) =>
          this.handleChangeSelect({
            currentTarget: { value: date, name },
          })
        }
        error={errors[name]}
        defaultValue={defaultValue}
        type="text"
      />
    );
  }

  renderLoginPasswordInput(name, label, type = "password") {
    const { data, errors } = this.state;
    return (
      <LoginPassword
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
      />
    );
  }
  rendereditSelect(name, label, options) {
    const { data, errors } = this.state;
    return (
      <EditSelect
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChangeSelect}
        error={errors[name]}
      />
    );
  }

  renderInput(name, label, type = "text", readOnly = false) {
    const { data, errors } = this.state;

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
        readOnly={readOnly}
      />
    );
  }
  renderNumber(name, label, type = "number", readOnly = false) {
    const { data, errors } = this.state;

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
        readOnly={readOnly}
      />
    );
  }
  renderText(name, label, type = "text", readOnly = false) {
    const { data, errors } = this.state;

    return (
      <Textarea
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
        readOnly={readOnly}
      />
    );
  }

  renderInputCityState(name, label, type = "text", readOnly = true) {
    const { data, errors } = this.state;

    return (
      <InputCityState
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
        readOnly={readOnly}
      />
    );
  }

  renderAddress2(name, label, type = "text", readOnly = false) {
    const { data, errors } = this.state;

    return (
      <Address2Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
        readOnly={readOnly}
      />
    );
  }

  renderEdit(name, label, type = "text") {
    const { data, errors } = this.state;
    return (
      <EditInput
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderPasswordInput(name, label, type = "password") {
    const { data, errors } = this.state;
    return (
      <Password
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
      />
    );
  }
}

export default Form;
