import React, { Component, createRef } from "react";

import email from "../../include/images/mail.svg";
import phone from "../../include/images/phone.svg";
import heartIcon from "../../include/images/outline-heart.svg";
import location from "../../include/images/location.svg";
import Breadcrumb from "./breadcrumb";
import Header from "./header";
import PostReview from "../common/customer/postReview";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Contact from "../common/contact";
import { getProduct, loadProductDetail } from "../../store/product";
import { addToWishlist } from "../../store/customer";

import { connect } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import shipment from "./../../include/images/shipment-1.svg";
import packet from "./../../include/images/packet-1.svg";
import AddToCart from "./addToCart";

import { toast } from "react-toastify";
import AlertSuccess from "../common/alertSuccess";
import AlertError from "../common/alertError";
import { getCustomerToken } from "../services/localStorageServices";
import { getCustomer, loadCustomerProfile } from "./../../store/customer";

import StarRatings from "react-star-ratings";
import { Line } from "rc-progress";
import AllReviews from "./allReviews";
import SellerReviews from "./sellerReviews";

import wishlistIcon from "./../../include/images/fav-icon.svg";
import Copyright from "../common/copyright";
import LoadingBar from "react-top-loading-bar";
import { filter } from "lodash-es";
import { setExtra } from "@sentry/minimal";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
const customerDddress = JSON.parse(localStorage.getItem("customerDddress"));
class Product extends Component {
  ref = createRef(null);

  state = {
    filters: { keyword: "" },
    productReview: false,
    id: "",
    product_id: "",
    interVal: 0,
    productId: "",
    tabPane: "a",
    photoIndex: 0,
    sliderIndex: 0,
    isOpen: false,
    isContact: false,
    isHours: false,
    aboutShow: false,
    sellerURL: "",
    isReviews: false,
    isSellerReviews: false,
    addedToWishlist: false,
    address: localStorage.getItem("customerDddress")
      ? JSON.parse(localStorage.getItem("customerDddress")).address
      : "",
    postal_code: localStorage.getItem("customerDddress")
      ? JSON.parse(localStorage.getItem("customerDddress")).postal_code
          .long_name
      : "",
  };

  toggleReviews = (id, e) => {
    if (e) e.preventDefault();
    this.setState({
      isReviews: !this.state.isReviews,
    });
    this.props.loadProductDetail(id, {
      address: customerDddress.address,
      lat: customerDddress.lat,
      lon: customerDddress.lng,
      zipCode: customerDddress.postal_code.long_name,
    });
    document.body.classList.add("toggled-tab");
  };

  toggleCloseReviews = () => {
    this.setState({
      reviews: !this.state.reviews,
      isReviews: !this.state.isReviews,
    });
    document.body.classList.remove("toggled-tab");
  };

  toggleSellerReviews = (id) => {
    this.setState({
      isSellerReviews: !this.state.isSellerReviews,
    });
    this.props.loadProductDetail(id, {
      address: customerDddress.address,
      lat: customerDddress.lat,
      lon: customerDddress.lng,
      zipCode: customerDddress.postal_code.long_name,
    });
    document.body.classList.add("toggled-tab");
  };

  toggleCloseSellerReviews = () => {
    this.setState({
      // reviews: !this.state.reviews,
      isSellerReviews: !this.state.isSellerReviews,
    });
    document.body.classList.remove("toggled-tab");
  };

  setProductId = (productId) => this.setState({ productId });

  toggleContact = () => {
    this.setState({
      isContact: !this.state.isContact,
    });
  };

  toggleHours = () => {
    this.setState({
      isHours: !this.state.isHours,
    });
  };

  componentDidMount = () => {
    //window.scrollTo(0, 0);
    if (localStorage.getItem("customerDddress")) {
      const ddres = JSON.parse(localStorage.getItem("customerDddress"));
      this.props.loadProductDetail(this.props.match.params.id, {
        address: ddres.address,
        lat: ddres.lat,
        lon: ddres.lng,
        zipCode: ddres.postal_code.long_name,
      });
    } else {
      this.props.history.push({
        pathname: "/",
        state: { product_id: this.props.match.params.id },
      });
    }
    document.body.classList.remove("cart_page");
    document.body.classList.add("fixed");
    //document.body.classList.add("open-cart-menu");
    if (this.props.location.state && this.props.location.state.filters) {
      const filters = { ...this.state.filters };
      filters.keyword = this.props.location.state.filters.keyword;
      this.setState({ filters });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.detailLoading &&
      this.props.detailLoading !== prevProps.detailLoading
    ) {
      this.ref.current.complete();
      setTimeout(() => {
        this.setState({ interVal: this.state.interVal + 1 });
      }, 1000);
    }
  };

  nextClick = (e) => {
    this.setState({ photoIndex: e });
  };
  tConv24 = (time24) => {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h;
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  };

  handleWishlist = (id) => {
    if (getCustomerToken("refresh")) {
      const params = {
        product: id,
      };
      this.props.addToWishlist(params, this.callBack);
    } else {
      this.props.history.push("/customer/login");
    }
  };

  callBack = (res) => {
    if (res.status === 200) {
      toast(<AlertSuccess message={"Added to wishlist"} />);
      this.setState({
        addedToWishlist: !this.state.addedToWishlist,
      });
    } else {
      toast(<AlertError message={"Failed"} />);
    }
  };
  setKeyword = (keyword) => {
    const stateData = { ...this.props.location.state };
    stateData.filters.keyword = keyword;
    this.props.history.push({
      pathname:
        this.props.states && this.props.states.seller_id
          ? "/store/" + this.props.states.seller_id
          : "/",
      state: stateData,
    });
  };

  rateProduct = (product_id) => {
    document.body.classList.add("toggled-tab");
    this.setState({
      productReview: !this.state.productReview,
      product_id: product_id,
    });
  };

  handleClose = () => {
    document.body.classList.remove("toggled-tab");
    this.setState({ productReview: !this.state.productReview });
  };
  render() {
    const { photoIndex, isOpen } = this.state;
    const {
      name,
      videos,
      price,
      quantity,
      description,
      image,
      seller,
      _id,
      total_reviews,
      reviews,
      average_ratings,
      average_reviews,
      addedToWishList,
    } = this.props.detail;
    const settings = {
      lazyLoad: true,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      className:
        "home-services-list home-service-slider d-flex flex-wrap add-orange-shape slick-initialized slick-slider",
      afterChange: () =>
        this.setState((state) => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
    };
    var d = new Date();
    var days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    const day = days[d.getDay()];

    const toDay = days[new Date().getDay()];
    return (
      <>
        {this.state.productId && (
          <div
            class={this.state.productId ? "overlay d-none show" : "overlay"}
          ></div>
        )}
        <LoadingBar color="#0b4474" ref={this.ref} />

        <Header
          keyword={this.state.filters.keyword}
          setKeyword={this.setKeyword}
          productList={true}
          postal_code={this.state.postal_code}
          updateLatLng={this.updateLatLng}
          address={this.state.address}
        />
        <main id="main">
          <Breadcrumb
            states={this.props.location.state}
            history={this.props.history}
          />

          {!this.props.detailLoading && (
            <>
              <div class="product-container">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-6 d-none d-md-block">
                      <div className="product-img">
                        <div className="product-slide simple-slider">
                          <img
                            src={
                              process.env.REACT_APP_S3URL +
                              image[this.state.sliderIndex].large
                            }
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.setState({
                                isOpen: true,
                              })
                            }
                            alt="One"
                            id="#threes"
                          />
                        </div>
                        {isOpen && (
                          <Lightbox
                            mainSrc={
                              image[photoIndex]
                                ? process.env.REACT_APP_S3URL +
                                  image[photoIndex].original
                                : ""
                            }
                            nextSrc={
                              image.length === photoIndex + 1
                                ? false
                                : image[photoIndex + 1]
                            }
                            prevSrc={
                              photoIndex === 0 ? false : image[photoIndex - 1]
                            }
                            onCloseRequest={() =>
                              this.setState({ isOpen: false, photoIndex: 0 })
                            }
                            onMovePrevRequest={() =>
                              this.setState({
                                photoIndex: photoIndex - 1,
                              })
                            }
                            onMoveNextRequest={() =>
                              this.setState({
                                photoIndex: photoIndex + 1,
                              })
                            }
                          />
                        )}
                        {image && image.length > 1 && (
                          <div className="thumbnail-list  d-flex justify-content-center align-items-center">
                            {image.map((e1, i) => (
                              <div
                                className={
                                  this.state.sliderIndex === i
                                    ? "thumbnail-item active"
                                    : "thumbnail-item"
                                }
                                onClick={(e) => {
                                  this.setState({
                                    sliderIndex: i,
                                    photoIndex: i,
                                  });
                                }}
                              >
                                <img
                                  src={
                                    e1
                                      ? process.env.REACT_APP_S3URL + e1.small
                                      : ""
                                  }
                                  alt="One"
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="product-details">
                        <div class="product-list">
                          <div class="product-title">
                            <div class="d-none d-md-block">
                              <h4> {name}</h4>
                              <div
                                style={{ cursor: "pointer" }}
                                className="star-list d-flex align-items-center"
                                onClick={(e) => this.toggleReviews(_id, e)}
                              >
                                {average_ratings && average_ratings ? (
                                  <StarRatings
                                    rating={average_ratings}
                                    starDimension="20px"
                                    starSpacing="3px"
                                    isSelectable="false"
                                    starEmptyColor="#C4C4C4"
                                    starRatedColor="#ffa51e"
                                    starHoverColor="#ffa51e"
                                    svgIconViewBox="0 0 14 13"
                                    svgIconPath="M6.29782 0.816984C6.66463 0.0737383 7.72447 0.07374 8.09129 0.816986L9.3702 3.40835C9.51586 3.70349 9.79743 3.90806 10.1231 3.95539L12.9829 4.37093C13.8031 4.49011 14.1306 5.49809 13.5371 6.07662L11.4678 8.09371C11.2321 8.32345 11.1245 8.65445 11.1802 8.97885L11.6687 11.827C11.8088 12.6439 10.9513 13.2669 10.2177 12.8812L7.65989 11.5365C7.36857 11.3833 7.02054 11.3833 6.72921 11.5365L4.17139 12.8812C3.43776 13.2669 2.58033 12.6439 2.72044 11.827L3.20894 8.97884C3.26458 8.65445 3.15703 8.32345 2.92134 8.09371L0.852018 6.07662C0.258501 5.49809 0.586013 4.49011 1.40623 4.37093L4.26597 3.95539C4.59168 3.90806 4.87324 3.70349 5.0189 3.40834L6.29782 0.816984Z"
                                  />
                                ) : (
                                  <StarRatings
                                    rating={0}
                                    starDimension="20px"
                                    starSpacing="3px"
                                    isSelectable="false"
                                    starEmptyColor="#C4C4C4"
                                    starRatedColor="#ffa51e"
                                    starHoverColor="#ffa51e"
                                    svgIconViewBox="0 0 14 13"
                                    svgIconPath="M6.29782 0.816984C6.66463 0.0737383 7.72447 0.07374 8.09129 0.816986L9.3702 3.40835C9.51586 3.70349 9.79743 3.90806 10.1231 3.95539L12.9829 4.37093C13.8031 4.49011 14.1306 5.49809 13.5371 6.07662L11.4678 8.09371C11.2321 8.32345 11.1245 8.65445 11.1802 8.97885L11.6687 11.827C11.8088 12.6439 10.9513 13.2669 10.2177 12.8812L7.65989 11.5365C7.36857 11.3833 7.02054 11.3833 6.72921 11.5365L4.17139 12.8812C3.43776 13.2669 2.58033 12.6439 2.72044 11.827L3.20894 8.97884C3.26458 8.65445 3.15703 8.32345 2.92134 8.09371L0.852018 6.07662C0.258501 5.49809 0.586013 4.49011 1.40623 4.37093L4.26597 3.95539C4.59168 3.90806 4.87324 3.70349 5.0189 3.40834L6.29782 0.816984Z"
                                  />
                                )}
                                <span
                                  className="reviews-text custom-rating"
                                  style={{
                                    verticalAlign: "-webkit-baseline-middle",
                                  }}
                                >
                                  {average_ratings && average_ratings}
                                  <small>
                                    ({total_reviews && total_reviews})
                                  </small>{" "}
                                </span>
                                {getCustomerToken("refresh") && (
                                  <p
                                    onClick={() => {
                                      this.rateProduct(_id);
                                    }}
                                    className="rate-product ml-4"
                                    style={{ marginTop: "8px" }}
                                  >
                                    Rate this Product
                                  </p>
                                )}
                              </div>
                            </div>
                            <div class="d-block d-md-none">
                              {/* <div class="mb-product-rate d-flex justify-content-between">
                                <div class="mb-product-left">
                                  <p>
                                    {seller.company}{" "}
                                    <span class="star-icon">
                                      <i
                                        class="fas fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <span class="reviews-text">
                                      {seller.average_ratings}
                                    </span>
                                  </p>
                                </div>
                                <div class="mb-product-star">
                                  <div className="star-list">
                                    {seller.average_ratings &&
                                    seller.average_ratings ? (
                                      <StarRatings
                                        rating={seller.average_ratings}
                                        starDimension="20px"
                                        starSpacing="3px"
                                        isSelectable="false"
                                        starEmptyColor="#C4C4C4"
                                        starRatedColor="#ffa51e"
                                        starHoverColor="#ffa51e"
                                        svgIconViewBox="0 0 14 13"
                                        svgIconPath="M6.29782 0.816984C6.66463 0.0737383 7.72447 0.07374 8.09129 0.816986L9.3702 3.40835C9.51586 3.70349 9.79743 3.90806 10.1231 3.95539L12.9829 4.37093C13.8031 4.49011 14.1306 5.49809 13.5371 6.07662L11.4678 8.09371C11.2321 8.32345 11.1245 8.65445 11.1802 8.97885L11.6687 11.827C11.8088 12.6439 10.9513 13.2669 10.2177 12.8812L7.65989 11.5365C7.36857 11.3833 7.02054 11.3833 6.72921 11.5365L4.17139 12.8812C3.43776 13.2669 2.58033 12.6439 2.72044 11.827L3.20894 8.97884C3.26458 8.65445 3.15703 8.32345 2.92134 8.09371L0.852018 6.07662C0.258501 5.49809 0.586013 4.49011 1.40623 4.37093L4.26597 3.95539C4.59168 3.90806 4.87324 3.70349 5.0189 3.40834L6.29782 0.816984Z"
                                      />
                                    ) : (
                                      <StarRatings
                                        rating={0}
                                        starDimension="20px"
                                        starSpacing="3px"
                                        isSelectable="false"
                                        starEmptyColor="#C4C4C4"
                                        starRatedColor="#ffa51e"
                                        starHoverColor="#ffa51e"
                                        svgIconViewBox="0 0 14 13"
                                        svgIconPath="M6.29782 0.816984C6.66463 0.0737383 7.72447 0.07374 8.09129 0.816986L9.3702 3.40835C9.51586 3.70349 9.79743 3.90806 10.1231 3.95539L12.9829 4.37093C13.8031 4.49011 14.1306 5.49809 13.5371 6.07662L11.4678 8.09371C11.2321 8.32345 11.1245 8.65445 11.1802 8.97885L11.6687 11.827C11.8088 12.6439 10.9513 13.2669 10.2177 12.8812L7.65989 11.5365C7.36857 11.3833 7.02054 11.3833 6.72921 11.5365L4.17139 12.8812C3.43776 13.2669 2.58033 12.6439 2.72044 11.827L3.20894 8.97884C3.26458 8.65445 3.15703 8.32345 2.92134 8.09371L0.852018 6.07662C0.258501 5.49809 0.586013 4.49011 1.40623 4.37093L4.26597 3.95539C4.59168 3.90806 4.87324 3.70349 5.0189 3.40834L6.29782 0.816984Z"
                                      />
                                    )}
                                    <span className="reviews-text custom-rating">
                                      <small>
                                        (
                                        {seller.total_reviews &&
                                          seller.total_reviews}
                                        )
                                      </small>{" "}
                                    </span>
                                  </div>
                                </div>
                              </div> */}
                              <div class="mb-product-heading d-flex justify-content-between align-items-center">
                                <h4 style={{ width: "50%" }}>{name}</h4>
                                {total_reviews ? (
                                  <div
                                    class="mb-product-star"
                                    style={{
                                      width: "50%",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <div
                                      style={{ cursor: "pointer" }}
                                      className="star-list ml-4"
                                      onClick={(e) =>
                                        this.toggleReviews(_id, e)
                                      }
                                    >
                                      {average_ratings && average_ratings ? (
                                        <StarRatings
                                          rating={average_ratings}
                                          starDimension="20px"
                                          starSpacing="3px"
                                          isSelectable="false"
                                          starEmptyColor="#C4C4C4"
                                          starRatedColor="#ffa51e"
                                          starHoverColor="#ffa51e"
                                          svgIconViewBox="0 0 14 13"
                                          svgIconPath="M6.29782 0.816984C6.66463 0.0737383 7.72447 0.07374 8.09129 0.816986L9.3702 3.40835C9.51586 3.70349 9.79743 3.90806 10.1231 3.95539L12.9829 4.37093C13.8031 4.49011 14.1306 5.49809 13.5371 6.07662L11.4678 8.09371C11.2321 8.32345 11.1245 8.65445 11.1802 8.97885L11.6687 11.827C11.8088 12.6439 10.9513 13.2669 10.2177 12.8812L7.65989 11.5365C7.36857 11.3833 7.02054 11.3833 6.72921 11.5365L4.17139 12.8812C3.43776 13.2669 2.58033 12.6439 2.72044 11.827L3.20894 8.97884C3.26458 8.65445 3.15703 8.32345 2.92134 8.09371L0.852018 6.07662C0.258501 5.49809 0.586013 4.49011 1.40623 4.37093L4.26597 3.95539C4.59168 3.90806 4.87324 3.70349 5.0189 3.40834L6.29782 0.816984Z"
                                        />
                                      ) : (
                                        <StarRatings
                                          rating={0}
                                          starDimension="20px"
                                          starSpacing="3px"
                                          isSelectable="false"
                                          starEmptyColor="#C4C4C4"
                                          starRatedColor="#ffa51e"
                                          starHoverColor="#ffa51e"
                                          svgIconViewBox="0 0 14 13"
                                          svgIconPath="M6.29782 0.816984C6.66463 0.0737383 7.72447 0.07374 8.09129 0.816986L9.3702 3.40835C9.51586 3.70349 9.79743 3.90806 10.1231 3.95539L12.9829 4.37093C13.8031 4.49011 14.1306 5.49809 13.5371 6.07662L11.4678 8.09371C11.2321 8.32345 11.1245 8.65445 11.1802 8.97885L11.6687 11.827C11.8088 12.6439 10.9513 13.2669 10.2177 12.8812L7.65989 11.5365C7.36857 11.3833 7.02054 11.3833 6.72921 11.5365L4.17139 12.8812C3.43776 13.2669 2.58033 12.6439 2.72044 11.827L3.20894 8.97884C3.26458 8.65445 3.15703 8.32345 2.92134 8.09371L0.852018 6.07662C0.258501 5.49809 0.586013 4.49011 1.40623 4.37093L4.26597 3.95539C4.59168 3.90806 4.87324 3.70349 5.0189 3.40834L6.29782 0.816984Z"
                                        />
                                      )}
                                      <span className="reviews-text custom-rating">
                                        <small>
                                          ({total_reviews && total_reviews})
                                        </small>{" "}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div class="new-product-slider mobile-slider d-block d-md-none">
                              {image.map((ei, i) => (
                                <div class="np-item">
                                  <div class="np-img-box">
                                    <img
                                      onClick={() =>
                                        this.setState({
                                          isOpen: true,
                                        })
                                      }
                                      src={
                                        process.env.REACT_APP_S3URL + ei.large
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div class="d-block d-md-none mb-border"></div>
                            <div class="product-price d-none d-md-block ">
                              <small class="mr-1">$</small>
                              {price}{" "}
                              {/* <sub>
                                <del>$ 999</del>
                              </sub> */}
                            </div>
                          </div>
                          {/* <div class="avability d-none d-md-block">
                            {" "}
                            <span>Available</span> Qty {quantity}
                          </div> */}
                          <div class="d-block d-md-none">
                            <div class="mb-product-price d-flex justify-content-between">
                              <div class="product-price ">$ {price}</div>
                              {/* <div class="avability">
                                {" "}
                                <span>Available</span> Qty {quantity}
                              </div> */}
                            </div>
                          </div>
                          <div class="d-flex justify-content-between">
                            <ul className="available-list">
                              {seller && seller.delivery.pickup && (
                                <li>
                                  <div className="al-icon">
                                    <img src={packet} alt="" />
                                  </div>
                                  <div className="available-text-box">
                                    Pickup - Available
                                  </div>
                                </li>
                              )}
                              {seller && seller.delivery.delivery_option && (
                                <li>
                                  <div className="al-icon">
                                    <img src={shipment} alt="" />
                                  </div>
                                  <div className="available-text-box">
                                    {`Delivery - ${seller.delivery.delivery_option_value}`}
                                  </div>
                                </li>
                              )}
                            </ul>
                            {getCustomerToken("refresh") && (
                              <p
                                className="rate-product d-md-none"
                                onClick={() => {
                                  this.rateProduct(_id);
                                }}
                              >
                                Rate this Product
                              </p>
                            )}
                          </div>

                          <div class="d-block d-md-none mb-border"></div>
                        </div>
                        <div className="product-list">
                          <div className="product-description">
                            <h5>Product Description </h5>
                          </div>
                          <ul className="prod-desc">
                            <li>
                              {" "}
                              <HTMLEllipsis
                                unsafeHTML={description}
                                maxLine="30"
                                ellipsis="..."
                                trimRight
                                basedOn="letters"
                              />
                            </li>
                          </ul>
                          <div class="d-block d-md-none mb-border"></div>
                        </div>

                        <div class="add-to-cart-dropdown-box mob-sticky">
                          {this.props.detail.seller && (
                            <AddToCart
                              data={this.props.detail}
                              setProductId={this.setProductId}
                              productId={this.state.productId}
                            />
                          )}
                          {!localStorage.getItem("x-auth-token-seller") && (
                            <div
                              class="wishlist-btn d-block d-md-none"
                              onClick={() => {
                                this.handleWishlist(_id);
                              }}
                            >
                              <img
                                src={
                                  addedToWishList || this.state.addedToWishlist
                                    ? wishlistIcon
                                    : heartIcon
                                }
                                alt=""
                              />
                              <span>Wishlist</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="content-container product-details-container untab">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <ul
                        id="tabs"
                        className="nav nav-tabs product-details-tab"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <a
                            id="tab-a"
                            href="#pane-a"
                            className={
                              this.state.tabPane === "a"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            role="tab"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ tabPane: "a" });
                            }}
                          >
                            Seller Details
                          </a>
                        </li>
                        {seller && seller.about && (
                          <li className="nav-item">
                            <a
                              id="tab-e"
                              href="#pane-e"
                              className={
                                this.state.tabPane === "e"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-toggle="tab"
                              role="tab"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ tabPane: "e" });
                              }}
                            >
                              About Seller
                            </a>
                          </li>
                        )}
                        {!seller.schedules.tbd && (
                          <li className="nav-item">
                            <a
                              id="tab-B"
                              href="#pane-B"
                              className={
                                this.state.tabPane === "b"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-toggle="tab"
                              role="tab"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ tabPane: "b" });
                              }}
                            >
                              Hours of Operation
                            </a>
                          </li>
                        )}
                        {videos && videos.length > 0 && (
                          <li className="nav-item">
                            <a
                              id="tab-f"
                              href="#pane-f"
                              className={
                                this.state.tabPane === "f"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-toggle="tab"
                              role="tab"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ tabPane: "f" });
                              }}
                            >
                              Product Video
                            </a>
                          </li>
                        )}
                        {reviews && reviews.length !== 0 && (
                          <li className="nav-item">
                            <a
                              id="tab-C"
                              href="#pane-C"
                              className={
                                this.state.tabPane === "c"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-toggle="tab"
                              role="tab"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ tabPane: "c" });
                              }}
                            >
                              Product Reviews
                            </a>
                          </li>
                        )}
                        {seller &&
                          (seller.refundPolicy || seller.returnPolicy) && (
                            <li className="nav-item">
                              <a
                                id="tab-D"
                                href="#pane-D"
                                className={
                                  this.state.tabPane === "d"
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                data-toggle="tab"
                                role="tab"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({ tabPane: "d" });
                                }}
                              >
                                Refund/Return Policy
                              </a>
                            </li>
                          )}
                      </ul>
                      <div id="content" className="tab-content" role="tablist">
                        <div
                          id="pane-A"
                          className={
                            this.state.tabPane === "a"
                              ? "card tab-pane fade show active"
                              : "card tab-pane fade show"
                          }
                          role="tabpanel"
                          aria-labelledby="tab-A"
                        >
                          <div class="card-header" role="tab" id="heading-A">
                            <h5 class="mb-0">Seller Details</h5>
                          </div>
                          <div
                            id="collapse-A"
                            class="collapse show"
                            data-parent="#content"
                            role="tabpanel"
                            aria-labelledby="heading-A"
                          >
                            <div class="card-body">
                              <div
                                class="seller-man d-flex  align-items-center"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.props.history.push(
                                    `/store/${seller.seller_id}`
                                  )
                                }
                              >
                                <div class="pd-profile-box d-none d-md-block">
                                  {/* <img
                                    style={{ borderRadius: "15%" }}
                                    width="50px"
                                    src={
                                      process.env.REACT_APP_S3URL +
                                        seller.profilePictureURLs &&
                                      seller.profilePictureURLs.small
                                    }
                                    alt=""
                                  /> */}
                                </div>
                                <div class="seller-profile-name d-flex flex-column align-items-center">
                                  <span>{seller.company}</span>
                                </div>
                              </div>
                              {seller.total_reviews ? (
                                <div
                                  style={{ cursor: "pointer" }}
                                  class="star-list"
                                  onClick={() => {
                                    this.toggleSellerReviews(_id);
                                  }}
                                >
                                  {seller && seller.average_ratings ? (
                                    <StarRatings
                                      rating={seller.average_ratings}
                                      starDimension="20px"
                                      starSpacing="3px"
                                      isSelectable="false"
                                      starEmptyColor="#C4C4C4"
                                      starRatedColor="#ffa51e"
                                      starHoverColor="#ffa51e"
                                      svgIconViewBox="0 0 14 13"
                                      svgIconPath="M6.29782 0.816984C6.66463 0.0737383 7.72447 0.07374 8.09129 0.816986L9.3702 3.40835C9.51586 3.70349 9.79743 3.90806 10.1231 3.95539L12.9829 4.37093C13.8031 4.49011 14.1306 5.49809 13.5371 6.07662L11.4678 8.09371C11.2321 8.32345 11.1245 8.65445 11.1802 8.97885L11.6687 11.827C11.8088 12.6439 10.9513 13.2669 10.2177 12.8812L7.65989 11.5365C7.36857 11.3833 7.02054 11.3833 6.72921 11.5365L4.17139 12.8812C3.43776 13.2669 2.58033 12.6439 2.72044 11.827L3.20894 8.97884C3.26458 8.65445 3.15703 8.32345 2.92134 8.09371L0.852018 6.07662C0.258501 5.49809 0.586013 4.49011 1.40623 4.37093L4.26597 3.95539C4.59168 3.90806 4.87324 3.70349 5.0189 3.40834L6.29782 0.816984Z"
                                    />
                                  ) : (
                                    <StarRatings
                                      rating={0}
                                      starDimension="20px"
                                      starSpacing="3px"
                                      isSelectable="false"
                                      starEmptyColor="#C4C4C4"
                                      starRatedColor="#ffa51e"
                                      starHoverColor="#ffa51e"
                                      svgIconViewBox="0 0 14 13"
                                      svgIconPath="M6.29782 0.816984C6.66463 0.0737383 7.72447 0.07374 8.09129 0.816986L9.3702 3.40835C9.51586 3.70349 9.79743 3.90806 10.1231 3.95539L12.9829 4.37093C13.8031 4.49011 14.1306 5.49809 13.5371 6.07662L11.4678 8.09371C11.2321 8.32345 11.1245 8.65445 11.1802 8.97885L11.6687 11.827C11.8088 12.6439 10.9513 13.2669 10.2177 12.8812L7.65989 11.5365C7.36857 11.3833 7.02054 11.3833 6.72921 11.5365L4.17139 12.8812C3.43776 13.2669 2.58033 12.6439 2.72044 11.827L3.20894 8.97884C3.26458 8.65445 3.15703 8.32345 2.92134 8.09371L0.852018 6.07662C0.258501 5.49809 0.586013 4.49011 1.40623 4.37093L4.26597 3.95539C4.59168 3.90806 4.87324 3.70349 5.0189 3.40834L6.29782 0.816984Z"
                                    />
                                  )}
                                  <span
                                    class="reviews-text"
                                    style={{
                                      verticalAlign: "-webkit-baseline-middle",
                                    }}
                                  >
                                    {seller.average_ratings &&
                                      seller.average_ratings}
                                    <small>({seller.total_reviews})</small>
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                              <ul class="seller-details">
                                <li>
                                  <img src={email} />
                                  {seller.email}
                                </li>
                                <li>
                                  <img src={phone} />
                                  {seller.mobile}
                                </li>
                                <li>
                                  <img src={location} />
                                  {seller.address.street1}
                                  {seller.address.street2 &&
                                    ", " + seller.address.street2}
                                  {seller.address.city &&
                                    ", " + seller.address.city}

                                  {seller.address.state &&
                                    ", " + seller.address.state}
                                  {seller.address.zip &&
                                    ", " + seller.address.zip}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {seller && seller.about && (
                          <div
                            id="pane-E"
                            className={
                              this.state.tabPane === "e"
                                ? "card tab-pane fade show active"
                                : "card tab-pane fade show"
                            }
                            role="tabpanel"
                            aria-labelledby="tab-E"
                          >
                            <div
                              className="card-header"
                              role="tab"
                              id="heading-E"
                            >
                              <h5 className="mb-0">
                                <a
                                  className="collapsed"
                                  data-toggle="collapse"
                                  href="#collapse-B"
                                  aria-expanded="false"
                                  aria-controls="collapse-E"
                                >
                                  About Seller
                                </a>
                              </h5>
                            </div>
                            <div
                              id="collapse-D"
                              className="collapse"
                              role="tabpanel"
                              data-parent="#content"
                              aria-labelledby="heading-D"
                            >
                              <div className="card-body">
                                <p class="color-change">
                                  <HTMLEllipsis
                                    unsafeHTML={seller.about}
                                    maxLine="30"
                                    ellipsis="..."
                                    trimRight
                                    basedOn="letters"
                                  />
                                </p>

                                {seller.video && (
                                  <p>
                                    <video
                                      className="vid-width"
                                      //width="480"
                                      controls
                                      key={seller.video}
                                    >
                                      <source
                                        src={
                                          process.env.REACT_APP_S3URL +
                                          seller.video
                                        }
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {!seller.schedules.tbd && (
                          <div
                            id="pane-B"
                            className={
                              this.state.tabPane === "b"
                                ? "card tab-pane fade show active"
                                : "card tab-pane fade show"
                            }
                            role="tabpanel"
                            aria-labelledby="tab-B"
                          >
                            <div
                              className="card-header"
                              role="tab"
                              id="heading-B"
                            >
                              <h5 className="mb-0">
                                <a
                                  className="collapsed"
                                  data-toggle="collapse"
                                  href="#collapse-B"
                                  aria-expanded="false"
                                  aria-controls="collapse-B"
                                >
                                  Hours of Operation
                                </a>
                              </h5>
                            </div>
                            <div
                              id="collapse-B"
                              className="collapse"
                              data-parent="#content"
                              role="tabpanel"
                              aria-labelledby="heading-B"
                            >
                              <div className="card-body">
                                <ul className="hrs-operation">
                                  {seller &&
                                  seller.schedules &&
                                  (!seller.schedules.mon ||
                                    seller.schedules.mon.length === 0) ? (
                                    <li>
                                      <p className="day">Monday</p>
                                      <p className="date">Closed</p>
                                    </li>
                                  ) : (
                                    <li>
                                      <p className="day">Monday</p>
                                      <p className="date">
                                        <ul className="product-hors">
                                          {seller &&
                                            seller.schedules &&
                                            seller.schedules.mon.map((e, i) => (
                                              <li className="product-hors">
                                                {this.tConv24(
                                                  e.start_time
                                                    .toString()
                                                    .replace(/(..)/g, "$1:")
                                                    .slice(0, -1)
                                                )}{" "}
                                                -{" "}
                                                {this.tConv24(
                                                  e.end_time
                                                    .toString()
                                                    .replace(/(..)/g, "$1:")
                                                    .slice(0, -1)
                                                )}
                                              </li>
                                            ))}
                                        </ul>
                                      </p>
                                    </li>
                                  )}
                                  {seller &&
                                  seller.schedules &&
                                  (!seller.schedules.tue ||
                                    seller.schedules.tue.length === 0) ? (
                                    <li>
                                      <p className="day">Tuesday</p>
                                      <p className="date">closed</p>
                                    </li>
                                  ) : (
                                    <li>
                                      <p className="day">Tuesday</p>
                                      <p className="date">
                                        <ul className="product-hors">
                                          {seller &&
                                            seller.schedules &&
                                            seller.schedules.tue.map((e, i) => (
                                              <li className="product-hors">
                                                {this.tConv24(
                                                  e.start_time
                                                    .toString()
                                                    .replace(/(..)/g, "$1:")
                                                    .slice(0, -1)
                                                )}{" "}
                                                -{" "}
                                                {this.tConv24(
                                                  e.end_time
                                                    .toString()
                                                    .replace(/(..)/g, "$1:")
                                                    .slice(0, -1)
                                                )}
                                              </li>
                                            ))}
                                        </ul>
                                      </p>
                                    </li>
                                  )}
                                  {seller &&
                                  seller.schedules &&
                                  (!seller.schedules.wed ||
                                    seller.schedules.wed.length === 0) ? (
                                    <li>
                                      <p className="day">Wednesday</p>
                                      <p className="date">closed</p>
                                    </li>
                                  ) : (
                                    <li>
                                      <p className="day">Wednesday</p>
                                      <p className="date">
                                        <ul className="product-hors">
                                          {seller &&
                                            seller.schedules &&
                                            seller.schedules.wed.map((e, i) => (
                                              <li className="product-hors">
                                                {this.tConv24(
                                                  e.start_time
                                                    .toString()
                                                    .replace(/(..)/g, "$1:")
                                                    .slice(0, -1)
                                                )}{" "}
                                                -{" "}
                                                {this.tConv24(
                                                  e.end_time
                                                    .toString()
                                                    .replace(/(..)/g, "$1:")
                                                    .slice(0, -1)
                                                )}
                                              </li>
                                            ))}
                                        </ul>
                                      </p>
                                    </li>
                                  )}
                                  {seller &&
                                  seller.schedules &&
                                  (!seller.schedules.thu ||
                                    seller.schedules.thu.length === 0) ? (
                                    <li>
                                      <p className="day">Thursday</p>
                                      <p className="date">closed</p>
                                    </li>
                                  ) : (
                                    <li>
                                      <p className="day">Thursday</p>
                                      <p className="date">
                                        <ul className="product-hors">
                                          {seller &&
                                            seller.schedules &&
                                            seller.schedules.thu.map((e, i) => (
                                              <li className="product-hors">
                                                {this.tConv24(
                                                  e.start_time
                                                    .toString()
                                                    .replace(/(..)/g, "$1:")
                                                    .slice(0, -1)
                                                )}{" "}
                                                -{" "}
                                                {this.tConv24(
                                                  e.end_time
                                                    .toString()
                                                    .replace(/(..)/g, "$1:")
                                                    .slice(0, -1)
                                                )}
                                              </li>
                                            ))}
                                        </ul>
                                      </p>
                                    </li>
                                  )}
                                  {seller &&
                                  seller.schedules &&
                                  (!seller.schedules.fri ||
                                    seller.schedules.fri.length === 0) ? (
                                    <li>
                                      <p className="day">Friday</p>
                                      <p className="date">closed</p>
                                    </li>
                                  ) : (
                                    <li>
                                      <p className="day">Friday</p>
                                      <p className="date">
                                        <ul className="product-hors">
                                          {seller &&
                                            seller.schedules &&
                                            seller.schedules.fri.map((e, i) => (
                                              <li className="product-hors">
                                                {this.tConv24(
                                                  e.start_time
                                                    .toString()
                                                    .replace(/(..)/g, "$1:")
                                                    .slice(0, -1)
                                                )}{" "}
                                                -{" "}
                                                {this.tConv24(
                                                  e.end_time
                                                    .toString()
                                                    .replace(/(..)/g, "$1:")
                                                    .slice(0, -1)
                                                )}
                                              </li>
                                            ))}
                                        </ul>
                                      </p>
                                    </li>
                                  )}
                                  {seller &&
                                  seller.schedules &&
                                  (!seller.schedules.sat ||
                                    seller.schedules.sat.length === 0) ? (
                                    <li>
                                      <p className="day">Saturday</p>
                                      <p className="date">closed</p>
                                    </li>
                                  ) : (
                                    <li>
                                      <p className="day">Saturday</p>
                                      <p className="date">
                                        <ul className="product-hors">
                                          {seller &&
                                            seller.schedules &&
                                            seller.schedules.sat.map((e, i) => (
                                              <li className="product-hors">
                                                {this.tConv24(
                                                  e.start_time
                                                    .toString()
                                                    .replace(/(..)/g, "$1:")
                                                    .slice(0, -1)
                                                )}{" "}
                                                -{" "}
                                                {this.tConv24(
                                                  e.end_time
                                                    .toString()
                                                    .replace(/(..)/g, "$1:")
                                                    .slice(0, -1)
                                                )}
                                              </li>
                                            ))}
                                        </ul>
                                      </p>
                                    </li>
                                  )}
                                  {seller &&
                                  seller.schedules &&
                                  (!seller.schedules.sun ||
                                    seller.schedules.sun.length === 0) ? (
                                    <li>
                                      <p className="day">Sunday</p>
                                      <p className="date">closed</p>
                                    </li>
                                  ) : (
                                    <li>
                                      <p className="day">Sunday</p>
                                      <p className="date">
                                        <ul className="product-hors">
                                          {seller &&
                                            seller.schedules &&
                                            seller.schedules.sun.map((e, i) => (
                                              <li className="product-hors">
                                                {this.tConv24(
                                                  e.start_time
                                                    .toString()
                                                    .replace(/(..)/g, "$1:")
                                                    .slice(0, -1)
                                                )}{" "}
                                                -{" "}
                                                {this.tConv24(
                                                  e.end_time
                                                    .toString()
                                                    .replace(/(..)/g, "$1:")
                                                    .slice(0, -1)
                                                )}
                                              </li>
                                            ))}
                                        </ul>
                                      </p>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                        {average_ratings && (
                          <div
                            id="pane-C"
                            className={
                              this.state.tabPane === "c"
                                ? "card tab-pane fade show active"
                                : "card tab-pane fade show"
                            }
                            role="tabpanel"
                            aria-labelledby="tab-C"
                          >
                            <div
                              className="card-header"
                              role="tab"
                              id="heading-C"
                            >
                              <h5 className="mb-0">
                                <a
                                  className="collapsed"
                                  data-toggle="collapse"
                                  href="#collapse-C"
                                  aria-expanded="false"
                                  aria-controls="collapse-C"
                                >
                                  Product Reviews
                                </a>
                              </h5>
                            </div>
                            <div
                              id="collapse-C"
                              className="collapse"
                              role="tabpanel"
                              data-parent="#content"
                              aria-labelledby="heading-C"
                            >
                              <div className="card-body w-100">
                                <div class="customer-reviews-box">
                                  <div class="cr-head d-flex align-items-center justify-content-between">
                                    <div
                                      style={{ cursor: "pointer" }}
                                      class="star-list"
                                      onClick={(e) =>
                                        this.toggleReviews(_id, e)
                                      }
                                    >
                                      {average_ratings ? (
                                        <StarRatings
                                          rating={average_ratings}
                                          starDimension="20px"
                                          starSpacing="3px"
                                          isSelectable="false"
                                          starEmptyColor="#C4C4C4"
                                          starRatedColor="#ffa51e"
                                          starHoverColor="#ffa51e"
                                          svgIconViewBox="0 0 14 13"
                                          svgIconPath="M6.29782 0.816984C6.66463 0.0737383 7.72447 0.07374 8.09129 0.816986L9.3702 3.40835C9.51586 3.70349 9.79743 3.90806 10.1231 3.95539L12.9829 4.37093C13.8031 4.49011 14.1306 5.49809 13.5371 6.07662L11.4678 8.09371C11.2321 8.32345 11.1245 8.65445 11.1802 8.97885L11.6687 11.827C11.8088 12.6439 10.9513 13.2669 10.2177 12.8812L7.65989 11.5365C7.36857 11.3833 7.02054 11.3833 6.72921 11.5365L4.17139 12.8812C3.43776 13.2669 2.58033 12.6439 2.72044 11.827L3.20894 8.97884C3.26458 8.65445 3.15703 8.32345 2.92134 8.09371L0.852018 6.07662C0.258501 5.49809 0.586013 4.49011 1.40623 4.37093L4.26597 3.95539C4.59168 3.90806 4.87324 3.70349 5.0189 3.40834L6.29782 0.816984Z"
                                        />
                                      ) : (
                                        <StarRatings
                                          rating={0}
                                          starDimension="20px"
                                          starSpacing="3px"
                                          isSelectable="false"
                                          starEmptyColor="#C4C4C4"
                                          starRatedColor="#ffa51e"
                                          starHoverColor="#ffa51e"
                                          svgIconViewBox="0 0 14 13"
                                          svgIconPath="M6.29782 0.816984C6.66463 0.0737383 7.72447 0.07374 8.09129 0.816986L9.3702 3.40835C9.51586 3.70349 9.79743 3.90806 10.1231 3.95539L12.9829 4.37093C13.8031 4.49011 14.1306 5.49809 13.5371 6.07662L11.4678 8.09371C11.2321 8.32345 11.1245 8.65445 11.1802 8.97885L11.6687 11.827C11.8088 12.6439 10.9513 13.2669 10.2177 12.8812L7.65989 11.5365C7.36857 11.3833 7.02054 11.3833 6.72921 11.5365L4.17139 12.8812C3.43776 13.2669 2.58033 12.6439 2.72044 11.827L3.20894 8.97884C3.26458 8.65445 3.15703 8.32345 2.92134 8.09371L0.852018 6.07662C0.258501 5.49809 0.586013 4.49011 1.40623 4.37093L4.26597 3.95539C4.59168 3.90806 4.87324 3.70349 5.0189 3.40834L6.29782 0.816984Z"
                                        />
                                      )}
                                      <span class="reviews-text">
                                        {average_ratings}
                                        <small>
                                          {" "}
                                          ({total_reviews && total_reviews})
                                        </small>{" "}
                                      </span>
                                    </div>
                                    <div class="d-none d-lg-block">
                                      <a
                                        onClick={(e) =>
                                          this.toggleReviews(_id, e)
                                        }
                                        href="#"
                                        class="btn btn-link-text physicalVisit"
                                      >
                                        View all reviews
                                      </a>
                                    </div>
                                  </div>
                                  <div class="cr-body">
                                    <div class="cr-progress-box d-flex flex-wrap">
                                      <div class="cr-progress-left">
                                        <div class="crp-item d-flex flex-wrap align-items-center">
                                          <span>5 Star</span>
                                          <div class="progress">
                                            <div
                                              class="progress-bar"
                                              role="progressbar"
                                              style={{
                                                width: average_reviews
                                                  ? `${average_reviews.five.percentage}%`
                                                  : "0%",
                                              }}
                                              aria-valuenow="50"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                        </div>
                                        <div class="crp-item d-flex flex-wrap align-items-center">
                                          <span>4 Star</span>
                                          <div class="progress">
                                            <div
                                              class="progress-bar"
                                              role="progressbar"
                                              style={{
                                                width: average_reviews
                                                  ? `${average_reviews.four.percentage}%`
                                                  : "0%",
                                              }}
                                              aria-valuenow="45"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                        </div>
                                        <div class="crp-item d-flex flex-wrap align-items-center">
                                          <span>3 Star</span>
                                          <div class="progress">
                                            <div
                                              class="progress-bar"
                                              role="progressbar"
                                              style={{
                                                width: average_reviews
                                                  ? `${average_reviews.three.percentage}%`
                                                  : "0%",
                                              }}
                                              aria-valuenow="35"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                        </div>
                                        <div class="crp-item d-flex flex-wrap align-items-center">
                                          <span>2 Star</span>
                                          <div class="progress">
                                            <div
                                              class="progress-bar"
                                              role="progressbar"
                                              style={{
                                                width: average_reviews
                                                  ? `${average_reviews.two.percentage}%`
                                                  : "0%",
                                              }}
                                              aria-valuenow="25"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                        </div>
                                        <div class="crp-item d-flex flex-wrap align-items-center">
                                          <span>1 Star</span>
                                          <div class="progress">
                                            <div
                                              class="progress-bar"
                                              role="progressbar"
                                              style={{
                                                width: average_reviews
                                                  ? `${average_reviews.one.percentage}%`
                                                  : "0%",
                                              }}
                                              aria-valuenow="10"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                        </div>
                                      </div>

                                      {/* {reviews && reviews.length !== 0 && (
                                        <>
                                          <div class="cr-progress-right">
                                            <div class="star-list">
                                              {reviews[0].rating ? (
                                                <StarRatings
                                                  rating={
                                                    reviews && reviews[0].rating
                                                  }
                                                  starDimension="20px"
                                                  starSpacing="3px"
                                                  isSelectable="false"
                                                  starEmptyColor="#C4C4C4"
                                                  starRatedColor="#ffa51e"
                                                  starHoverColor="#ffa51e"
                                                />
                                              ) : (
                                                <StarRatings
                                                  rating={0}
                                                  starDimension="20px"
                                                  starSpacing="3px"
                                                  isSelectable="false"
                                                  starEmptyColor="#C4C4C4"
                                                  starRatedColor="#ffa51e"
                                                  starHoverColor="#ffa51e"
                                                />
                                              )}
                                            </div>
                                            <p>
                                              {" "}
                                              {reviews[0].customer_first_name +
                                                " " +
                                                reviews[0].customer_last_name}
                                            </p>
                                            <h6>
                                              {reviews[0].subject &&
                                                reviews[0].subject}
                                            </h6>
                                            <p>
                                              {reviews[0].content &&
                                                reviews[0].content}
                                            </p>
                                          </div>
                                        </>
                                      )} */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div
                          id="pane-D"
                          className={
                            this.state.tabPane === "d"
                              ? "card tab-pane fade show active"
                              : "card tab-pane fade show"
                          }
                          role="tabpanel"
                          aria-labelledby="tab-D"
                        >
                          <div class="card-header" role="tab" id="heading-D">
                            <h5 class="mb-0">Refund/Return Policy</h5>
                          </div>
                          <div
                            id="collapse-D"
                            class="collapse"
                            role="tabpanel"
                            data-parent="#content"
                            aria-labelledby="heading-D"
                          >
                            <div class="card-body">
                              <div class="product-description d-md-block mb-3">
                                <h5
                                  class="mb-3"
                                  style={{
                                    fontSize: "14px",
                                    color: "#4f4f4f",
                                    fontWeight: "400",
                                    lineHeight: "18px",
                                  }}
                                >
                                  Refund Policy
                                </h5>
                                <p
                                  style={{
                                    color: "#000",
                                    fontSize: "12px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {seller.returnPolicy && seller.returnPolicy}
                                </p>
                              </div>
                              <div class="product-description d-md-block mt-3 mb-3">
                                <h5
                                  class="mb-3"
                                  style={{
                                    fontSize: "14px",
                                    color: "#4f4f4f",
                                    fontWeight: "400",
                                    lineHeight: "18px",
                                  }}
                                >
                                  Return Policy
                                </h5>
                                <p
                                  style={{
                                    color: "#000",
                                    fontSize: "12px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {seller.refundPolicy && seller.refundPolicy}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {seller && seller.video && this.state.tabPane === "f" && (
                          <div
                            id="pane-D"
                            className={
                              this.state.tabPane === "f"
                                ? "card tab-pane fade show active"
                                : "card tab-pane fade show"
                            }
                            role="tabpanel"
                            aria-labelledby="tab-D"
                          >
                            <div
                              id="collapse-D"
                              className="collapse"
                              role="tabpanel"
                              data-parent="#content"
                              aria-labelledby="heading-D"
                            >
                              <div className="card-body">
                                {videos &&
                                  videos.length > 0 &&
                                  videos.map((ev) => (
                                    <p>
                                      <video
                                        className="vid-width"
                                        controls
                                        key={ev}
                                      >
                                        <source
                                          src={process.env.REACT_APP_S3URL + ev}
                                          type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                    </p>
                                  ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {reviews && reviews.length !== 0 && (
                <div class="tab-modify-slidebar large-modify-sidebar">
                  <div class="tab-modify-box">
                    <div class="tab-header d-flex justify-content-between">
                      <h3>Product Reviews</h3>
                      <a href="#!" class="close">
                        <img src="include/images/close.svg" alt="" />
                      </a>
                    </div>
                    <div class="feedback_ratings d-flex flex-wrap align-items-center">
                      <div class="fr-left">
                        <div
                          class="feedback_rating rating_active"
                          data-rate="4"
                        >
                          4 <i class="fas fa-star"></i>
                        </div>
                      </div>

                      <div class="fr-right d-flex align-items-center">
                        <div class="feedback_rating" data-rate="1">
                          1 <i class="fas fa-star"></i>
                        </div>
                        <div class="feedback_rating" data-rate="2">
                          2 <i class="fas fa-star"></i>
                        </div>
                        <div class="feedback_rating" data-rate="3">
                          3 <i class="fas fa-star"></i>
                        </div>
                        <div class="feedback_rating" data-rate="5">
                          5 <i class="fas fa-star"></i>
                        </div>
                      </div>
                    </div>
                    <div class="tab-body">
                      <div class="review-box">
                        <div class="star-list">
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="reviews-text">4.0 </span>
                        </div>
                        <p>By Alex</p>
                        <h6>Lorem ipsum dolor</h6>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.{" "}
                        </p>
                      </div>
                      <div class="review-box">
                        <div class="star-list">
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="reviews-text">4.0 </span>
                        </div>
                        <p>By Alex</p>
                        <h6>Lorem ipsum dolor</h6>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.{" "}
                        </p>
                      </div>
                      <div class="review-box">
                        <div class="star-list">
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="reviews-text">4.0 </span>
                        </div>
                        <p>By Alex</p>
                        <h6>Lorem ipsum dolor</h6>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.{" "}
                        </p>
                      </div>
                      <div class="review-box">
                        <div class="star-list">
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="reviews-text">4.0 </span>
                        </div>
                        <p>By Alex</p>
                        <h6>Lorem ipsum dolor</h6>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.{" "}
                        </p>
                      </div>
                      <div class="review-box">
                        <div class="star-list">
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="reviews-text">4.0 </span>
                        </div>
                        <p>By Alex</p>
                        <h6>Lorem ipsum dolor</h6>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.{" "}
                        </p>
                      </div>
                      <div class="review-box">
                        <div class="star-list">
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon filled">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="star-icon">
                            <i class="fas fa-star" aria-hidden="true"></i>
                          </span>
                          <span class="reviews-text">4.0 </span>
                        </div>
                        <p>By Alex</p>
                        <h6>Lorem ipsum dolor</h6>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          <footer class="footer d-none d-lg-block" id="footer">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="footer-content text-center">
                    <Copyright />
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </main>

        <AllReviews
          detail={this.props.detail}
          isReviews={this.state.isReviews}
          toggleReviews={this.toggleReviews}
          handleClose={this.toggleCloseReviews}
        />
        <PostReview
          productReview={this.state.productReview}
          sellerReview={this.state.sellerReview}
          handleClose={this.handleClose}
          _id={""}
          product_det_Id={this.props.match.params.id}
          product_id={this.state.product_id}
          detail={true}
          loadProductDetail={this.props.loadProductDetail}
        />
        <SellerReviews
          detail={this.props.detail}
          isSellerReviews={this.state.isSellerReviews}
          toggleReviews={this.toggleSellerReviews}
          handleClose={this.toggleCloseSellerReviews}
        />
        {this.state.isContact && <Contact toggleContact={this.toggleContact} />}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  detail: getProduct(state).detail,
  detailLoading: getProduct(state).detailLoading,
  customerProfile: getCustomer(state).profile,
  profileLoading: getCustomer(state).profileLoading,
});
const mapDispatchToProps = (dispatch) => ({
  addToWishlist: (data, callback) => dispatch(addToWishlist(data, callback)),
  loadProductDetail: (id, params) => dispatch(loadProductDetail(id, params)),
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Product);
