import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "cart",
  initialState: {
    cartDetail: { orderSummary: {} },
    loading: false,
  },
  reducers: {
    cartRequested: (product) => {
      product.cartLoading = true;
    },

    cartReceived: (product, action) => {
      product.cartDetail = action.payload;
      product.loading = false;
    },

    cartRequestFailed: (product) => {
      product.cartDetail = {
        orderSummary: {
          subtotal: 0,
          tax: 0,
          discount: 0,
          final_total: 0,
        },
      };
      product.loading = false;
    },
  },
});

export const { cartRequested, cartReceived, cartRequestFailed } = slice.actions;
export default slice.reducer;

// Action Creators
const url = "cart/";

export const loadCart = (id, params) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: url + id,
      params,
      onStart: cartRequested.type,
      onSuccess: cartReceived.type,
      onError: cartRequestFailed.type,
    })
  );
};

export const updateProductQuantity = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "updateProductQuantity",
      method: "POST",
      data,
      callback,
    })
  );
};

export const updateDeliveryForProduct = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "updateDeliveryForProduct",
      method: "POST",
      data,
      callback,
    })
  );
};

export const emptyCart = (id) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "empty/" + id,
      method: "POST",
    })
  );
};

export const addToCart = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "addToCart",
      method: "POST",
      data,
      callback,
    })
  );
};

export const removeFromCart = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "deleteProductFromCart",
      method: "POST",
      data,
      callback,
    })
  );
};

export const addCouponToSeller = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "addCouponToSeller",
      method: "POST",
      data,
      callback,
    })
  );
};

export const removeCouponFromSeller = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "removeCouponFromSeller",
      method: "POST",
      data,
      callback,
    })
  );
};

export const prepareOrderForPayment = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "placeOrderForSeller",
      method: "POST",
      data,
      callback,
    })
  );
};

export const placeOrder = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "placeOrder",
      method: "POST",
      data,
      callback,
    })
  );
};

export const addNotesToSeller = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "addNotesToSeller",
      method: "POST",
      data,
      callback,
    })
  );
};

export const getCart = createSelector(
  (state) => state.entities.cart,
  (cart) => cart
);
