export const setSellerToken = (refresh, auth) => {
  localStorage.setItem("x-refresh-token-seller", refresh);
  localStorage.setItem("x-auth-token-seller", auth);
};

export const getSellerToken = (type) => {
  if (type === "refresh") return localStorage.getItem("x-refresh-token-seller");
  if (type === "auth") return localStorage.getItem("x-auth-token-seller");
  return false;
};

export const setCustomerName = (customerName) => {
  localStorage.setItem("customer-name", customerName);
};

export const getCustomerName = () => {
  localStorage.getItem("customer-name");
};

export const setCustomerToken = (refresh, auth) => {
  localStorage.setItem("x-refresh-token-customer", refresh);
  localStorage.setItem("x-auth-token-customer", auth);
};

export const getCustomerToken = (type) => {
  if (type === "refresh")
    return localStorage.getItem("x-refresh-token-customer");
  if (type === "auth") return localStorage.getItem("x-auth-token-customer");
  return false;
};
