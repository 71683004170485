import React, { Component } from "react";

import { customerSignUp } from "../../../store/customer";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import SignUpForm from "./signUp";
import RequestMobileOtp from "./verify/mobileOtp";

class RequestOtpCustomer extends Component {
  state = {
    formData: {},
    step: 1,
    type: 1,
    verifyMobile: {},
  };

  componentWillUnmount = () => {
    document.body.classList.remove("pt-0");
  };

  componentDidMount = () => {
    document.body.classList.add("pt-0");
  };

  updateStep = (step) => {
    this.setState({ step });
  };

  updateVerifyMobile = (verifyMobile) => {
    this.setState({ verifyMobile });
  };

  updateFormData = (data) => {
    this.setState({
      formData: data,
    });
  };

  leftSideStep = () => {
    switch (this.state.step) {
      case 1:
        return (
          <SignUpForm
            formData={this.state.formData}
            updateStep={this.updateStep}
            updateFormData={this.updateFormData}
            updateVerifyMobile={this.updateVerifyMobile}
            verifyMobile={this.state.verifyMobile}
          />
        );

      case 2:
        return (
          <RequestMobileOtp
            formData={this.state.formData}
            updateFormData={this.updateFormData}
            updateStep={this.updateStep}
            verifyMobile={this.state.verifyMobile}
            updateVerifyMobile={this.updateVerifyMobile}
          />
        );

      default:
        break;
    }
  };

  render() {
    return <div className="member-container">{this.leftSideStep()}</div>;
  }
}

const mapDispatchToProps = (dispatch) => ({
  customerSignUp: (param, callback) =>
    dispatch(customerSignUp(param, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(RequestOtpCustomer));
