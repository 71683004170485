import React, { Component } from "react";

import { sellerSignUp } from "../../store/seller";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import SignUpForm from "./signUp";
import RequestMobileOtp from "./verify/mobileOtp";
import RequestEmailOtp from "./verify/emailOtp";

class RequestOtpSeller extends Component {
  state = {
    formData: {},
    add: {},
    step: 1,
    type: 1,
    verifyMobile: {},
    verifyEmail: {},
  };

  componentWillUnmount = () => {
    document.body.classList.remove("pt-0");
  };

  componentDidMount = () => {
    document.body.classList.add("pt-0");
  };

  updateStep = (step) => {
    this.setState({ step });
  };

  updateVerifyMobile = (verifyMobile) => {
    this.setState({ verifyMobile });
  };

  updateVerifyEmail = (verifyEmail) => {
    this.setState({ verifyEmail });
  };

  updateFormData = (data, add) => {
    this.setState({
      formData: data,
      add,
    });
  };

  leftSideStep = () => {
    switch (this.state.step) {
      case 1:
        return (
          <SignUpForm
            add={this.state.add}
            formData={this.state.formData}
            updateStep={this.updateStep}
            updateFormData={this.updateFormData}
            updateVerifyMobile={this.updateVerifyMobile}
            verifyMobile={this.state.verifyMobile}
            updateVerifyEmail={this.updateVerifyEmail}
            verifyEmail={this.state.verifyEmail}
          />
        );

      case 2:
        return (
          <RequestMobileOtp
            add={this.state.add}
            formData={this.state.formData}
            updateStep={this.updateStep}
            verifyMobile={this.state.verifyMobile}
            updateVerifyMobile={this.updateVerifyMobile}
          />
        );

      default:
        break;
    }
  };

  render() {
    return <div className="member-container">{this.leftSideStep()}</div>;
  }
}

const mapDispatchToProps = (dispatch) => ({
  sellerSignUp: (param, callback) => dispatch(sellerSignUp(param, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(RequestOtpSeller));
