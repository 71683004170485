import React, { Component } from "react";
import Select from "react-select";

class selectOptions extends Component {
  state = {};
  customStyles = {
    indicatorSeparator: (styles) => ({ display: "none" }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "#297ab9",
        color: "#e0e0e0",
      },

      color: "black",
      backgroundColor: state.isSelected
        ? "rgb(95, 50, 187, 10%)"
        : provided.backgroundColor,
      //backgroundColor: state.isSelected ? "rgb(95, 50, 187, 10%)" : "white",
    }),

    control: (base, state) => ({
      ...base,
      height: 49,
      minHeight: 49,
      fontSize: 16,
      width: this.props.width ? this.props.width : 120,
      borderColor: state.isFocused ? "#297ab9" : "#e0e0e0",
      boxShadow: "0 !important",
      "&:hover": {
        borderColor: "#297ab9",
      },
      "&:focus": {
        borderColor: "#297ab9",
      },
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#6119c0",
      },
    }),
  };

  render() {
    const { attrind, attrnum, name, isDisabled } = this.props;
    return (
      <Select
        isDisabled={isDisabled}
        styles={this.customStyles}
        value={this.props.value}
        onChange={(e) =>
          this.props.onChange({
            value: e.value,
            label: e.label,
            attrind,
            attrnum,
            name,
          })
        }
        options={this.props.options}
        placeholder= "Please Select"
      />
    );
  }
}

export default selectOptions;
