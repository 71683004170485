import React, { Component } from "react";
// import Footer from "../common/footer";
import TopBar from "../common/topBar";
import Pagination from "../common/Pagination";

import { connect } from "react-redux";
import Moment from "moment";

import image1 from "../../include/images/no-result-img.svg";
import { getSeller, loadStockHistory } from "../../store/seller";

import SortingIconUp from "../common/sortingIconUp";
import SortingIcon from "../common/sortingIconDown";
import vector from "../../include/images/Vector.svg";

class StockHistory extends Component {
  ref = React.createRef(null);

  state = {
    settingDropdown: false,
    profileDropdown: false,
    search: "",
    sort: "desc",
    sort_by: "date",
  };

  componentDidMount = () => {
    this.ref.current.continuousStart();
    this.props.loadStockHistory({
      sort_by: this.state.sort_by,
      sort: this.state.sort,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.sort_by !== prevState.sort_by ||
      this.state.sort !== prevState.sort
    ) {
      this.props.loadStockHistory({
        sort_by: this.state.sort_by,
        sort: this.state.sort,
      });
    }
    if (
      this.props.stockHistoryLoading &&
      this.props.stockHistoryLoading !== prevProps.stockHistoryLoading
    ) {
      this.ref.current.continuousStart();
    }
    if (
      !this.props.stockHistoryLoading &&
      this.props.stockHistoryLoading !== prevProps.stockHistoryLoading
    ) {
      this.ref.current.complete();
    }
  };

  onSearch = (e) => {
    if (this.state.search) {
      this.props.loadStockHistory({ keyword: this.state.search });
    }
  };

  onKeyPress = (e) => {
    if (e.which === 13 && this.state.search) {
      this.props.loadStockHistory({ keyword: this.state.search });
    }
  };

  sortBy = (param) => {
    let sort;
    if (this.state.sort === "asc") {
      sort = "desc";
    } else {
      sort = "asc";
    }
    this.setState({
      sort_by: param,
      sort,
    });
  };

  handleSettingDropdown = () => {
    if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
      this.setState({ settingDropdown: !this.state.settingDropdown });
    } else {
      this.setState({ settingDropdown: !this.state.settingDropdown });
    }
  };

  handleProfileDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
      this.setState({ profileDropdown: !this.state.profileDropdown });
    } else {
      this.setState({ profileDropdown: !this.state.profileDropdown });
    }
  };

  setSettingDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
    } else if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
    }
  };

  onChangePage = (data) => {
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
    });
    if (data.page !== this.state.currentPage) {
      this.props.loadStockHistory({ page: data.page });
    }
  };

  render() {
    const { record_per_page, total_page, current_page, filtered_record } =
      this.props.pagination;
    
    return (
      <div onClick={this.setSettingDropdown}>
        <div id="main">
          <div className="dashboard-container custom-top">
            <TopBar
              handleProfileDropdown={this.handleProfileDropdown}
              handleSettingDropdown={this.handleSettingDropdown}
              settingDropdown={this.state.settingDropdown}
              profileDropdown={this.state.profileDropdown}
              loaDerRef={this.ref}
              stockHistory={this.props.stockHistory}
            />
            {!this.props.stockHistoryLoading &&
            this.props.stockHistory &&
            this.props.stockHistory.length === 0 ? (
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="no-result-content">
                      <div className="no-result-box d-inline-block">
                        <div className="no-result-img">
                          <img src={image1} alt="" />
                        </div>
                        <div className="no-result-text">
                          <h2>Welcome Aboard!</h2>
                          <p>There is nothing to show yet.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {!this.props.stockHistoryLoading && (
                  <>
                    <div className="product-list-item-container ">
                      <div className="search-item-container order-search-item-container">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="search-item-content d-flex flex-column flex-md-row align-items-md--center justify-content-end justify-content-md-between">
                                <div className="flex-grow-1 d-flex align-items-center">
                                  <div
                                    className="input-group"
                                    onKeyPress={this.onKeyPress}
                                    onClick={this.onSearch}
                                  >
                                    <div
                                      className="input-group-prepend"
                                      onKeyPress={this.onKeyPress}
                                      onClick={this.onSearch}
                                    >
                                      {" "}
                                      <span
                                        className="input-group-text"
                                        id="basic-addon1"
                                      >
                                        <img src={vector} alt="" />
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search here"
                                      aria-label="search"
                                      aria-describedby="basic-addon1"
                                      value={this.state.search}
                                      onChange={(e) =>
                                        this.setState({
                                          search: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="showing-result d-flex align-items-center justify-content-end">
                                  <div className="result-number">
                                    Showing{" "}
                                    <strong>
                                      {this.props.pagination
                                        .total_record_on_current_page &&
                                        this.props.pagination
                                          .total_record_on_current_page}
                                    </strong>{" "}
                                    of{" "}
                                    <strong>
                                      {this.props.pagination &&
                                        this.props.pagination.filtered_record}
                                    </strong>{" "}
                                    results
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="product-list-item-content">
                              <div className="table-responsive">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          width: "90px",
                                        }}
                                      >
                                        Product
                                      </th>
                                      <th
                                        style={{
                                          width: "105px",
                                        }}
                                      >
                                        Before QTY
                                      </th>
                                      <th
                                        style={{
                                          width: "105px",
                                        }}
                                      >
                                        After QTY
                                      </th>
                                      <th
                                        style={{
                                          width: "100px",
                                        }}
                                      >
                                        Action
                                      </th>
                                      <th
                                        style={{
                                          width: "100px",
                                        }}
                                      >
                                        Action By
                                      </th>
                                      <th
                                        onClick={() => this.sortBy("date")}
                                        style={{
                                          width: "100px",
                                        }}
                                      >
                                        Action On{" "}
                                        {this.state.sort_by === "date" &&
                                        this.state.sort === "asc" ? (
                                          <SortingIcon sorting={() => {}} />
                                        ) : (
                                          <SortingIconUp sorting={() => {}} />
                                        )}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.props.stockHistory &&
                                      this.props.stockHistory.map((s, i) => (
                                        <tr key={i}>
                                          <td>
                                            <div className="d-flex align-items-center">
                                              <div className="symbol symbol-40 symbol-sm flex-shrink-0">
                                                <img
                                                  className=""
                                                  src={
                                                    s.product.image
                                                      ? process.env
                                                          .REACT_APP_S3URL +
                                                        s.product.image[0].small
                                                      : ""
                                                  }
                                                  alt="product"
                                                />
                                              </div>
                                              <div className="ml-4">
                                                <b>
                                                  {s.product.name &&
                                                    s.product.name}
                                                </b>
                                              </div>
                                            </div>{" "}
                                          </td>
                                          <td>
                                            <b>
                                              {s.quantityBefore &&
                                                s.quantityBefore}
                                            </b>
                                          </td>
                                          <td>
                                            {s.quantityAfter && s.quantityAfter}
                                          </td>

                                          <td>
                                            <b>
                                              {s.transaction && s.transaction}
                                            </b>
                                          </td>
                                          <td>
                                            <b>
                                              {s.created_by && s.created_by}
                                            </b>
                                          </td>
                                          <td>
                                            {Moment(s.created_on).format("LL")}{" "}
                                            <small>
                                              {" "}
                                              {Moment(s.created_on).format(
                                                "LT"
                                              )}
                                            </small>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <Pagination
                              totalRecords={filtered_record}
                              total_page={total_page}
                              pageLimit={record_per_page}
                              initialPage={current_page}
                              pagesToShow={5}
                              onChangePage={this.onChangePage}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  stockHistory: getSeller(state).stockHistory.data,
  stockHistoryLoading: getSeller(state).stockHistoryLoading,
  pagination: getSeller(state).stockHistory.pagination,
});
const mapDispatchToProps = (dispatch) => ({
  loadStockHistory: (params) => dispatch(loadStockHistory(params)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StockHistory);
