import React, { Component } from "react";
// import Footer from "../common/footer";
import TopBar from "../common/topBar";

import { connect } from "react-redux";

import image1 from "../../include/images/no-result-img.svg";
import { getSeller, loadSellerProfile } from "../../store/seller";

import StarRatings from "react-star-ratings";

class SellerReviews extends Component {
  ref = React.createRef(null);

  state = {
    settingDropdown: false,
    profileDropdown: false,
  };

  componentDidMount = () => {
    this.ref.current.continuousStart();
    this.props.loadSellerProfile();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.profileLoading &&
      this.props.profileLoading !== prevProps.profileLoading
    ) {
      this.ref.current.complete();
    }
  };

  // onChangePage = (data) => {
  //   this.setState({
  //     pageLimit: data.pageLimit,
  //     totalPages: data.totalPages,
  //     currentPage: data.page,
  //   });
  //   if (data.page !== this.state.currentPage) {
  //     this.props.loadAllProduct({ page: data.page });
  //   }
  // };

  handleSettingDropdown = () => {
    if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
      this.setState({ settingDropdown: !this.state.settingDropdown });
    } else {
      this.setState({ settingDropdown: !this.state.settingDropdown });
    }
  };

  handleProfileDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
      this.setState({ profileDropdown: !this.state.profileDropdown });
    } else {
      this.setState({ profileDropdown: !this.state.profileDropdown });
    }
  };

  setSettingDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
    } else if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
    }
  };

  render() {
    // const { product, loading } = this.props.product;
    // const {
    //   record_per_page,
    //   total_page,
    //   current_page,
    //   filtered_record,
    //   total_record,
    // } = this.props.pagination;
    return (
      <div onClick={this.setSettingDropdown}>
        <div id="main">
          <div className="dashboard-container custom-top">
            <TopBar
              handleProfileDropdown={this.handleProfileDropdown}
              handleSettingDropdown={this.handleSettingDropdown}
              settingDropdown={this.state.settingDropdown}
              profileDropdown={this.state.profileDropdown}
              loaDerRef={this.ref}
              sellerProfile={this.props.sellerProfile}
            />
            {this.props.sellerProfile.reviews &&
            this.props.sellerProfile.reviews.length === 0 ? (
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="no-result-content">
                      <div className="no-result-box d-inline-block">
                        <div className="no-result-img">
                          <img src={image1} alt="" />
                        </div>
                        <div className="no-result-text">
                          <h2>Welcome Aboard!</h2>
                          <p>You have no reviews yet.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="product-list-item-container ">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="product-list-item-content">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      width: "90px",
                                    }}
                                  >
                                    Rating
                                  </th>
                                  <th
                                    style={{
                                      width: "105px",
                                    }}
                                  >
                                    Title
                                  </th>
                                  <th
                                    style={{
                                      width: "180px",
                                    }}
                                  >
                                    Description
                                  </th>
                                  <th
                                    style={{
                                      width: "100px",
                                    }}
                                  >
                                    Customer Details
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.sellerProfile.reviews &&
                                  this.props.sellerProfile.reviews.map(
                                    (e, i) => (
                                      <tr key={i}>
                                        <td className="td-image">
                                          <StarRatings
                                            rating={e.rating}
                                            starDimension="15px"
                                            starSpacing="3px"
                                            isSelectable="false"
                                            starEmptyColor="#C4C4C4"
                                            starRatedColor="#ffa51e"
                                            starHoverColor="#ffa51e"
                                          />
                                        </td>
                                        <td>
                                          <div className="code star-list">
                                            {e.subject && e.subject}
                                          </div>
                                        </td>
                                        <td>
                                          <b>{e.content && e.content}</b>
                                        </td>
                                        <td>
                                          <b>
                                            {e.customer_first_name &&
                                              e.customer_last_name &&
                                              e.customer_first_name +
                                                " " +
                                                e.customer_last_name}
                                          </b>
                                        </td>
                                      </tr>
                                    )
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* <Pagination
                          totalRecords={filtered_record}
                          total_page={total_page}
                          pageLimit={record_per_page}
                          initialPage={current_page}
                          pagesToShow={5}
                          onChangePage={this.onChangePage}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  sellerProfile: getSeller(state).profile,
  profileLoading: getSeller(state).profileLoading,
});
const mapDispatchToProps = (dispatch) => ({
  loadSellerProfile: () => dispatch(loadSellerProfile()),
});
export default connect(mapStateToProps, mapDispatchToProps)(SellerReviews);
