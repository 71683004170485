import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../../include/images/effisim-logo-white.svg";

class SidebarCustomer extends Component {
  render() {
    return (
      <div className="member-left">
        <Link to="/products-listing" className="logo-area">
          <img src={logo} alt="" />
        </Link>

        {/* <h4>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </h4>

        <p>
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout. The point of
          using Lorem Ipsum is that it has a more-or-less normal distribution of
          letters, as opposed to using 'Content here, content here', making it
          look like readable English.
        </p> */}
      </div>
    );
  }
}

export default SidebarCustomer;
