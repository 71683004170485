import React, { Component } from "react";

import VerifyAccount from "./verifyAccount";
import CustomerResetPasswordMobile from "./customerResetPasswordMobile";

import ForgetPasswordCompleted from "./forgotPasswordCompleted";
import RequestMobileOtp from "./verify/mobileOtp";

class ForgotPassword extends Component {
  state = {
    step: 1,
    verifyMobile: {},
    verifyEmail: {},
  };
  componentWillUnmount = () => {
    document.body.classList.remove("pt-0");
  };

  componentDidMount = () => {
    document.body.classList.add("pt-0");
  };

  updateStep = (step) => {
    this.setState({ step });
  };

  updateVerifyMobile = (verifyMobile) => {
    this.setState({ verifyMobile });
  };

  leftSideStep = () => {
    switch (this.state.step) {
      case 1:
        return (
          <VerifyAccount
            updateStep={this.updateStep}
            updateVerifyMobile={this.updateVerifyMobile}
            verifyMobile={this.state.verifyMobile}
          />
        );

      case 2:
        return (
          <RequestMobileOtp
            updateStep={this.updateStep}
            verifyMobile={this.state.verifyMobile}
            updateVerifyMobile={this.updateVerifyMobile}
          />
        );

      case 3:
        return (
          <CustomerResetPasswordMobile
            updateStep={this.updateStep}
            updateVerifyMobile={this.updateVerifyMobile}
            verifyMobile={this.state.verifyMobile}
          />
        );

      case 4:
        return <ForgetPasswordCompleted />;

      default:
        break;
    }
  };

  render() {
    return <div className="member-container">{this.leftSideStep()}</div>;
  }
}

export default ForgotPassword;
