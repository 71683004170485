import React, { Component } from "react";
import topArrow from "../../include/images/top_arrow.svg";

export default class SortingIconUp extends Component {
  render() {
    return (
      <span
        className="svg-icon svg-icon-primary svg-icon-2x"
        onClick={() => {
          this.props.sorting();
        }}
      >
        <i>
          <img src={topArrow} alt="" />
        </i>
      </span>
    );
  }
}
