import React, { Component } from "react";
import Lottie from "react-lottie-player";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import AutoComplete from "./autoComplete";
import Geocode from "react-geocode";
import AlertError from "../common/alertError";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { emptyCart, getCart } from "../../store/cart";
import {
  loadCustomerProfile,
  getCustomer,
  updateAddress,
} from "../../store/customer";
import heroJson from "../../include/json/hero.json";

import { zipValidation, addressValidation } from "../../store/seller";
Geocode.setApiKey(process.env.REACT_APP_MAPKEY);
Geocode.setLanguage("en");
Geocode.setRegion("us");
Geocode.setLocationType("APPROXIMATE");
Geocode.enableDebug();

class Hero extends Component {
  state = {
    zipLoader: false,
    loader: false,
    data: {
      street1: "",
      street2: "",
      zip: "",
      city: "",
      state: "",
    },

    adress: "",
    isSelected: false,
    popup: false,
    errors: {},

    address: "",
    zipcode: "",
    latLng: {},
  };

  setAddress = (adress) => {
    this.setState({ adress });
  };

  selectAddress = (adress) => {
    this.setState({ adress, isSelected: true });
  };
  handlePopup = (e) => {
    if (e) e.preventDefault();
    this.setState({ popup: !this.state.popup });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.data.zip.length !== 5 &&
      this.state.data.zip !== prevState.data.zip
    ) {
      const { data } = this.state;
      data.city = "";
      data.state = "";
      this.setState({ data });
    }
  }

  componentDidMount = () => {
    const formData = localStorage.getItem("customerDddress")
      ? JSON.parse(localStorage.getItem("customerDddress"))
      : {};

    if (formData.address) {
      const addressArr = formData.address.split(",");
      console.log("addressArr", addressArr);

      const data = {
        street1: addressArr[0],
        street2: addressArr.length > 4 ? addressArr[1] : "",
        zip: formData.postal_code.long_name,
        city: addressArr[addressArr.length - 3],
        state: addressArr[addressArr.length - 2],
      };
      this.setState({ data });
    }
  };

  doSubmit = (address, zipcode, latLng) => {
    this.setState({ address, zipcode, latLng });
    this.handlePopup();
  };

  doSubmitFinal = (e) => {
    this.handlePopup();
    e.preventDefault();
    this.setState({
      labelRegister: "",
      loader: true,
      btnClass: "btn btn-default disabled",
    });
    const addressParams = {
      street1: this.state.data.street1,
      street2: this.state.data.street2,
      zip: this.state.data.zip,
    };
    this.props.addressValidation(addressParams, this.addressCallback);
  };

  changeAddress = (e) => {
    console.log("latlng", this.state.latLng);
    this.props.emptyCart(localStorage.getItem("user_device_id"));
    this.handlePopup();
    if (e) e.preventDefault();
    this.props.updateLatLng(
      this.state.latLng.lat,
      this.state.latLng.lng,
      this.state.address,
      {
        long_name: this.state.zipcode,
      },
      true,
      true
    );
    this.props.history.push("/products-listing");
  };

  addressCallback = async (res) => {
    this.setState({
      loader: false,
      labelRegister: "Save Changes",
      btnClass: "btn btn-default",
    });
    if (res.status === 200) {
      const updateAddressParams = {
        billing_address: {
          address_line1: this.state.data.street1,
          address_line2: this.state.data.street2,
          zipcode: this.state.data.zip,
          city: this.state.data.city,
          state: this.state.data.state,
        },
      };

      const latLng = {};

      try {
        this.props.emptyCart(localStorage.getItem("user_device_id"));
        const add = await Geocode.fromAddress(
          `${this.state.data.street1} ${this.state.data.street2} ${this.state.data.city} ${this.state.data.state} ${this.state.data.zip}`
        );
        latLng.lat = add.results[0].geometry.location.lat;
        latLng.lng = add.results[0].geometry.location.lng;
        console.log("latLng", latLng);
        this.props.updateLatLng(
          latLng.lat,
          latLng.lng,
          this.state.data.street2
            ? `${this.state.data.street1}, ${this.state.data.street2}, ${this.state.data.city}, ${this.state.data.state}, USA`
            : `${this.state.data.street1}, ${this.state.data.city}, ${this.state.data.state}, USA`,
          {
            long_name: this.state.data.zip,
          },
          true,
          true
        );

        this.props.handleClose();
      } catch (error) {
        console.log("errr", error);
        this.setState({
          loader: false,
        });
        return toast(
          <AlertError message="Invalid delivery address. Please enter your correct address." />
        );
      }
    } else {
      toast(
        <AlertError message="Invalid address. Please enter your correct address." />
      );
    }
  };

  zipCallback = (res) => {
    if (res.status === 200) {
      this.setState({
        labelFetch: "Fetch city & state",
        zipLoader: false,
        btnClass: "btn btn-default",
      });
      const { data, errors } = this.state;
      data.city = res.data.data.city;
      data.state = res.data.data.state;
      errors.city = "";
      errors.state = "";
      this.setState({ data, errors });
    } else {
      this.setState({
        labelFetch: "Fetch city & state",
        zipLoader: false,
        btnClass: "btn btn-default",
      });

      toast(<AlertError message={res.data.error.Description} />);
    }
  };

  doSubmitZip = () => {
    if (!this.state.zipLoader) {
      this.setState({
        labelFetch: "",
        zipLoader: true,
        btnClass: "btn btn-default disabled",
        // btnClass: "sidebar-default-btn disabled",
      });
    }
    const params = {
      zip: this.state.data.zip,
    };
    this.props.zipValidation(params, this.zipCallback);
  };
  render() {
    const { final_total, total_products } =
      this.props.getCart.cartDetail.orderSummary;
    return (
      <>
        {/* <div class="hero-container d-md-flex align-items-center">
          <div class="hero-img-outer">
            <div class="hero-img add-shadow">
              <Lottie
                loop
                animationData={heroJson}
                speed="1"
                play
                background="transparent"
              />
            </div>
          </div>
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-6 col-lg-7 order-md-0">
                <div class="intro-content">
                  <h2>
                    Shop Local <br /> Wherever you are!
                  </h2>
                  <p>
                    A marketplace designed to empower local businesses in your
                    neighbourhood !
                  </p>
                  <div style={{ position: "relative", color: "#000" }}>
                    <AutoComplete
                      doSubmit={this.doSubmit}
                      hero="hero"
                      total_products={total_products}
                      changeAddress={this.changeAddress}
                      history={this.props.history}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div class="hero-container d-md-flex align-items-center">
          <div class="hero-img-outer">
            <div class="hero-img add-shadow">
              <Lottie
                loop
                animationData={heroJson}
                speed="1"
                play
                background="transparent"
              />
            </div>
          </div>
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-6 order-md-0">
                <div class="intro-content">
                  <div class="intro-text-box">
                    <h2>
                      Shop Local <br /> Wherever you are!
                    </h2>
                    <p>
                      A marketplace designed to empower local businesses in your
                      neighbourhood!
                    </p>
                  </div>
                  <AutoComplete
                    doSubmit={this.doSubmit}
                    hero="hero"
                    total_products={total_products}
                    changeAddress={this.changeAddress}
                    history={this.props.history}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {total_products > 0 && (
          <Modal
            show={this.state.popup}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <div className="modal-header">
              <button
                type="button"
                className="close mt-2"
                data-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <div className="modal-alert-box">
                <h3 className="mb-6">Alert!</h3>
                <p>Modifying delivery address will clear your cart.</p>
              </div>
              <div className="modal-action-btn">
                <a
                  href="javascript:void(0)"
                  data-dismiss="modal"
                  className="btn btn-default"
                  onClick={(e) => {
                    this.changeAddress(e);
                  }}
                >
                  Ok
                </a>
                <a
                  href="#!"
                  className="btn btn-cancel"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.handlePopup}
                >
                  Cancel
                </a>
              </div>
            </div>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getCart: getCart(state),
  customerProfile: getCustomer(state).profile,
  profileLoading: getCustomer(state).profileLoading,
});

const mapDispatchToProps = (dispatch) => ({
  updateAddress: (data, callback) => dispatch(updateAddress(data, callback)),
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
  zipValidation: (data, callback) => dispatch(zipValidation(data, callback)),
  emptyCart: (id) => dispatch(emptyCart(id)),
  addressValidation: (data, callback) =>
    dispatch(addressValidation(data, callback)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Hero));
