import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import closeIcon from "../../include/images/close-icon.svg";
import deliverIcon from "../../include/images/deliver-icon.svg";
import empty from "../../include/images/empty-product-icon.svg";
import { getProduct } from "../../store/product";
import ProductGridA from "./productGridA";
import ProductGridB from "./productGridB";
import { Range } from "react-range";
import ReactPaginate from "react-paginate";
import Copyright from "../common/copyright";
import image1 from "../../include/images/no-result-img.svg";
import _ from "lodash";
import LinesEllipsis from "react-lines-ellipsis";
import SellerDetail from "./sellerDetail";

class ProductListMain extends React.Component {
  state = {
    productId: "",
    category: "",
    mode: false,
    deliveryOption: false,
    pickup: false,
    category: false,
    seller: false,
    price: false,
    subcategory: false,
    averageRating: false,
    numberOfReviews: false,
    priceMax: "",
    priceMin: "",
  };

  setProductId = (productId) =>
    this.setState({
      productId: productId === this.state.productId ? "" : productId,
    });
  render() {
    const { cat1, cat2, cat3 } = this.props.match.params;
    const { filters } = this.props;
    const pathname = this.props.location.pathname.split("/")[1];
    return (
      <>
        {this.props.product && this.props.product.length > 0 ? (
          <div class="content-container pt-0">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <>
                    {" "}
                    {this.props.keyword ? (
                      <div class="result-status-row">
                        <span>
                          {" "}
                          Showing {this.props.pagination.filtered_record}{" "}
                          results for
                        </span>
                        {` "${this.props.keyword}"`}
                      </div>
                    ) : (
                      <div class="result-status-row">
                        <span>
                          {" "}
                          Showing {this.props.pagination.filtered_record}{" "}
                          results{" "}
                        </span>
                      </div>
                    )}
                    <ul
                      class={
                        this.props.listType === "a"
                          ? "product-gridview-list large-device-item d-flex flex-wrap"
                          : "product-listview-list mob-full-width"
                      }
                    >
                      {this.props.listType === "a"
                        ? this.props.product &&
                          this.props.product.map((ep) => (
                            <ProductGridA
                              stateData={this.props.stateData}
                              data={ep}
                              setProductId={this.setProductId}
                              productId={this.state.productId}
                              addToWishlist={this.props.addToWishlist}
                              deleteWishlist={this.props.deleteWishlist}
                              callBack={this.props.callBack}
                              filters={this.props.filters}
                            />
                          ))
                        : this.props.product.map((ep) => (
                            <ProductGridB
                              stateData={this.props.stateData}
                              data={ep}
                              setProductId={this.setProductId}
                              productId={this.state.productId}
                              addToWishlist={this.props.addToWishlist}
                              deleteWishlist={this.props.deleteWishlist}
                              callBack={this.props.callBack}
                              filters={this.props.filters}
                            />
                          ))}
                    </ul>
                    {this.props.pagination.total_page > 0 && (
                      <div class="pagination-wrapper">
                        <ReactPaginate
                          forcePage={this.props.filters.page - 1}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          nextClassName={"page-item"}
                          previousLinkClassName={""}
                          nextLinkClassName={""}
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.props.pagination.total_page}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.props.handlePageClick}
                          containerClassName={
                            "new-pagination d-flex align-items-center justify-content-center"
                          }
                          activeClassName={"active"}
                        />
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div class="empty-product-container d-flex align-items-center justify-content-center">
            <div class="container">
              <div class="col-md-12">
                <div class="epc-box text-center">
                  <div class="epc-icon">
                    <img src={empty} alt="" />
                  </div>
                  <h4>No result found</h4>
                  <p>
                    Sorry! There are no results matching your search //
                    criteria. Please modify your search parameters and // try
                    again.it, sed do eiusmod tempor ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: getProduct(state).categories,
  product: getProduct(state).product,
  productCategory: getProduct(state).productCategory,
  loading: getProduct(state).loading,
  pagination: getProduct(state).pagination,
  sellers: getProduct(state).sellers,
  sellerDetail: getProduct(state).sellerDetail,
});

export default withRouter(connect(mapStateToProps, null)(ProductListMain));
