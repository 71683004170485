import React, { Component, createRef } from "react";
import TopBar from "../common/topBar";
// import Footer from "../common/footer";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

import image1 from "../../include/images/no-result-img.svg";
import Pagination from "../common/Pagination";

import vector from "../../include/images/Vector.svg";
import downArrow from "../../include/images/down-arrow-icon.svg";
import closeBtn from "../../include/images/close.svg";

import AlertSuccess from "../common/alertSuccess";
import AlertError from "../common/alertError";
import { toast } from "react-toastify";

import {
  loadOrders,
  getSeller,
  loadOrderDetails,
  updateFullfilStatus,
} from "../../store/seller";
import Moment from "moment";

import AdvanceSearch from "../common/advanceSearch";
import OrderDetails from "../common/orderDetails";

import SortingIconUp from "../common/sortingIconUp";
import SortingIcon from "../common/sortingIconDown";

class OrderListing extends Component {
  ref = createRef(null);

  state = {
    currentPage: "",
    productId: "",
    disputeProductId: "",
    returnProductId: "",
    isOrderDetails: false,
    isAdvanceSearch: false,
    search: "",
    sort: "desc",
    sort_by: "date",
    params: {},
    settingDropdown: false,
    profileDropdown: false,
    sorting: false,
    orderId: "",
    fulfillmodal: false,
    dropDownToggle: false,
    orderStatus: "",
  };

  handleDropdown = (id) => {
    this.setState({ orderId: id, dropDownToggle: !this.state.dropDownToggle });
  };

  handleFullfilStatus = (id) => {
    this.props.updateFullfilStatus(id, this.callback);
  };

  callback = (res) => {
    if (res.status === 200) {
      this.props.loadOrders({
        sort_by: this.state.sort_by,
        sort: this.state.sort,
        page: this.state.currentPage,
      });
      this.setState({ fulfillmodal: !this.state.fulfillmodal });
      toast(<AlertSuccess message={"Product Status Updated. "} />);
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  openAdvanceSearch = () => {
    this.setState({ isAdvanceSearch: !this.state.isAdvanceSearch });
  };

  openDetails = (id, stat) => {
    this.props.loadOrderDetails(id);
    this.setState({ isOrderDetails: !this.state.isOrderDetails });
  };

  closeDetails = () => {
    this.setState({ isOrderDetails: !this.state.isOrderDetails });
  };

  componentDidMount = () => {
    this.props.loadOrders({
      sort_by: this.state.sort_by,
      sort: this.state.sort,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.sort_by !== prevState.sort_by ||
      this.state.sort !== prevState.sort
    ) {
      this.props.loadOrders({
        sort_by: this.state.sort_by,
        sort: this.state.sort,
      });
    }
    if (this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.continuousStart();
    }
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.complete();
    }
  };

  onSearch = (e) => {
    if (this.state.search) {
      this.props.loadOrders({ keyword: this.state.search });
    }
  };

  onKeyPress = (e) => {
    if (e.which === 13 && this.state.search) {
      this.props.loadOrders({ keyword: this.state.search });
    }
  };

  setProductId = (productId) => {
    this.setState({
      productId: productId === this.state.productId ? "" : productId,
      disputeProductId: "",
      returnProductId: "",
    });
  };

  setDisputeProductId = (disputeProductId) => {
    this.setState({
      disputeProductId,
      productId: "",
    });
  };

  setReturnProductId = (returnProductId) => {
    this.setState({
      returnProductId,
      productId: "",
    });
  };

  onChangePage = (data) => {
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
    });
    if (data.page !== this.state.currentPage) {
      this.props.loadOrders({ page: data.page });
    }
  };

  sortBy = (param) => {
    let sort;
    if (this.state.sort === "asc") {
      sort = "desc";
    } else {
      sort = "asc";
    }
    this.setState({
      sort_by: param,
      sort,
      sorting: true,
    });
  };

  applyAdvanceSearch = (advanceSearchData) => {
    this.setState({ params: advanceSearchData });
    this.props.loadOrders(advanceSearchData);
  };

  handleSettingDropdown = () => {
    if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
      this.setState({ settingDropdown: !this.state.settingDropdown });
    } else {
      this.setState({ settingDropdown: !this.state.settingDropdown });
    }
  };

  handleProfileDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
      this.setState({ profileDropdown: !this.state.profileDropdown });
    } else {
      this.setState({ profileDropdown: !this.state.profileDropdown });
    }
  };

  setSettingDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
    } else if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
    }
  };

  render() {
    const { record_per_page, total_page, current_page, filtered_record } =
      this.props.pagination;
    console.log("order", this.props.orders);
    return (
      <>
        <main
          id="main"
          onClick={() => {
            this.setState({ productId: "" });
            this.setSettingDropdown();
          }}
        >
          <TopBar
            loaDerRef={this.ref}
            sellerProfile={this.props.sellerProfile}
            handleProfileDropdown={this.handleProfileDropdown}
            handleSettingDropdown={this.handleSettingDropdown}
            settingDropdown={this.state.settingDropdown}
            profileDropdown={this.state.profileDropdown}
          />
          {!this.props.loading && this.props.orders.length === 0 ? (
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="no-result-content">
                    <div className="no-result-box d-inline-block">
                      <div className="no-result-img">
                        <img src={image1} alt="" />
                      </div>
                      <div className="no-result-text">
                        <h2>Welcome Aboard!</h2>
                        <p>There are no orders placed yet.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="dashboard-container custom-top">
              <div className="search-item-container order-search-item-container">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="search-item-content d-flex flex-column flex-md-row align-items-md--center justify-content-end justify-content-md-between">
                        <div className="flex-grow-1 d-flex align-items-center">
                          <div
                            className="input-group"
                            onKeyPress={this.onKeyPress}
                            onClick={this.onSearch}
                          >
                            <div
                              className="input-group-prepend"
                              onKeyPress={this.onKeyPress}
                              onClick={this.onSearch}
                            >
                              {" "}
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                <img src={vector} alt="" />
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search here"
                              aria-label="search"
                              aria-describedby="basic-addon1"
                              value={this.state.search}
                              onChange={(e) =>
                                this.setState({ search: e.target.value })
                              }
                            />
                          </div>
                          <a
                            href="javascript:void(0)"
                            onClick={this.openAdvanceSearch}
                            className="search-text sidebar_toggler"
                            data-toggle="advancedSearch"
                          >
                            Advanced Search
                          </a>
                        </div>
                        <div className="showing-result d-flex align-items-center justify-content-end">
                          <div className="result-number">
                            Showing{" "}
                            <strong>
                              {this.props.pagination
                                .total_record_on_current_page &&
                                this.props.pagination
                                  .total_record_on_current_page}
                            </strong>{" "}
                            of{" "}
                            <strong>
                              {this.props.pagination.filtered_record}
                            </strong>{" "}
                            results
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="product-list-item-container ">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="product-list-item-content">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th
                                  onClick={() => this.sortBy("order_id")}
                                  style={{
                                    width: "250px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Orders{" "}
                                  {this.state.sorting === true &&
                                  this.state.sort_by === "order_id" ? (
                                    this.state.sort === "asc" ? (
                                      <SortingIcon sorting={() => {}} />
                                    ) : (
                                      <SortingIconUp sorting={() => {}} />
                                    )
                                  ) : (
                                    ""
                                  )}
                                </th>
                                <th
                                  onClick={() => this.sortBy("amount")}
                                  style={{
                                    width: "250px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Amount{" "}
                                  {this.state.sorting === true &&
                                  this.state.sort_by === "amount" ? (
                                    this.state.sort === "asc" ? (
                                      <SortingIcon sorting={() => {}} />
                                    ) : (
                                      <SortingIconUp sorting={() => {}} />
                                    )
                                  ) : (
                                    ""
                                  )}
                                </th>
                                <th
                                  onClick={() => this.sortBy("tax")}
                                  style={{
                                    width: "250px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Tax{" "}
                                  {this.state.sorting === true &&
                                  this.state.sort_by === "tax" ? (
                                    this.state.sort === "asc" ? (
                                      <SortingIcon sorting={() => {}} />
                                    ) : (
                                      <SortingIconUp sorting={() => {}} />
                                    )
                                  ) : (
                                    ""
                                  )}
                                </th>
                                <th style={{ width: "250px" }}>Type</th>
                                <th style={{ width: "250px" }}>
                                  {" "}
                                  Payment Status
                                </th>
                                <th
                                  onClick={() => this.sortBy("status")}
                                  style={{
                                    width: "250px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Status{" "}
                                  {this.state.sorting === true &&
                                  this.state.sort_by === "status" ? (
                                    this.state.sort === "asc" ? (
                                      <SortingIcon sorting={() => {}} />
                                    ) : (
                                      <SortingIconUp sorting={() => {}} />
                                    )
                                  ) : (
                                    ""
                                  )}
                                </th>
                                <th
                                  onClick={() => this.sortBy("date")}
                                  style={{
                                    width: "250px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Date{" "}
                                  {this.state.sorting === true &&
                                  this.state.sort_by === "date" ? (
                                    this.state.sort === "asc" ? (
                                      <SortingIcon sorting={() => {}} />
                                    ) : (
                                      <SortingIconUp sorting={() => {}} />
                                    )
                                  ) : (
                                    ""
                                  )}
                                </th>
                                <th style={{ width: "100px" }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.orders &&
                                this.props.orders.map((e, i) => (
                                  <tr key={i}>
                                    <td>{e.order_id && e.order_id}</td>
                                    <td>
                                      <b>
                                        $
                                        {e.summary &&
                                          e.summary.final_total.toLocaleString(
                                            undefined,
                                            { minimumFractionDigits: 2 }
                                          )}
                                      </b>
                                    </td>
                                    <td>
                                      <b>
                                        $
                                        {e.summary &&
                                          e.summary.tax_total.toLocaleString(
                                            undefined,
                                            { minimumFractionDigits: 2 }
                                          )}
                                      </b>
                                    </td>

                                    <td>
                                      <b>{e.order_type && e.order_type}</b>
                                    </td>
                                    <td>
                                      {e.status === "Pending" ? (
                                        e.pay_later ? (
                                          <b>Unpaid</b>
                                        ) : (
                                          <b>Paid</b>
                                        )
                                      ) : (
                                        <b>Paid</b>
                                      )}
                                    </td>

                                    <td>
                                      {e.status && e.status == "Pending" ? (
                                        <div
                                          className={
                                            this.state.dropDownToggle &&
                                            this.props.orderId === e._id
                                              ? "table-dropdown btn-group dropdown show"
                                              : "table-dropdown btn-group dropdown"
                                          }
                                        >
                                          <button
                                            style={{ color: "#de7777" }}
                                            id={e._id}
                                            class="dropdown-toggle"
                                            type="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            onClick={(event) => {
                                              event.preventDefault();
                                              event.stopPropagation();
                                              this.handleDropdown(e._id);
                                            }}
                                          >
                                            {e.status && e.status}
                                            <span class="caret ml-1">
                                              <img src={downArrow} alt="" />
                                            </span>
                                          </button>
                                          <div
                                            style={{
                                              minWidth: "100px",
                                              textAlign: "center",
                                              marginTop: "0",
                                            }}
                                            class={
                                              this.state.dropDownToggle &&
                                              this.state.orderId === e._id
                                                ? "dropdown-menu show custom-styles"
                                                : "dropdown-menu"
                                            }
                                            aria-labelledby={e._id}
                                          >
                                            <div
                                              class="drop-content-box"
                                              style={{ padding: "5px 0" }}
                                            >
                                              <ul class="drop-menu">
                                                <li class="drop-item">
                                                  <a
                                                    style={{
                                                      color: "#27ae60",
                                                      fontSize: "14px",
                                                    }}
                                                    href="javascript:void(0)"
                                                    class="drop-link"
                                                    onClick={() =>
                                                      this.setState({
                                                        fulfillmodal:
                                                          !this.state
                                                            .fulfillmodal,
                                                      })
                                                    }
                                                  >
                                                    Fulfilled
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            e.status === "Pending"
                                              ? "pending"
                                              : "fulfilled"
                                          }
                                        >
                                          {e.status && e.status}
                                        </div>
                                      )}
                                    </td>
                                    <td>
                                      {Moment(e.created_on).format("LL")}{" "}
                                      <small>
                                        {" "}
                                        {Moment(e.created_on).format("LT")}
                                      </small>
                                    </td>
                                    <td className="editable">
                                      {" "}
                                      <a
                                        onClick={() => {
                                          this.openDetails(
                                            e.order_id,
                                            e.status
                                          );
                                        }}
                                        href="javascript:void(0)"
                                        className=" btn btn-default outline sidebar_toggler"
                                        data-toggle="viewOrder"
                                      >
                                        view
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <Pagination
                        totalRecords={filtered_record}
                        total_page={total_page}
                        pageLimit={record_per_page}
                        initialPage={current_page}
                        pagesToShow={5}
                        onChangePage={this.onChangePage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <AdvanceSearch
            applyAdvanceSearch={this.applyAdvanceSearch}
            closeSearch={this.openAdvanceSearch}
            isAdvanceSearch={this.state.isAdvanceSearch}
          />
          <OrderDetails
            setReturnProductId={this.setReturnProductId}
            returnProductId={this.state.returnProductId}
            loadOrderDetails={this.props.loadOrderDetails}
            setProductId={this.setProductId}
            productId={this.state.productId}
            setDisputeProductId={this.setDisputeProductId}
            disputeProductId={this.state.disputeProductId}
            isOrderDetails={this.state.isOrderDetails}
            closeDetails={this.closeDetails}
            orderDetails={this.props.orderDetails}
          />
          {/* <Footer /> */}
        </main>
        <div
          className={
            this.state.isAdvanceSearch || this.state.isOrderDetails
              ? "overlay show"
              : "overlay"
          }
        ></div>
        <Modal
          show={this.state.fulfillmodal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="modal-header">
            <button
              type="button"
              className="close mt-2"
              data-dismiss="modal"
              onClick={() =>
                this.setState({
                  fulfillmodal: !this.state.fulfillmodal,
                })
              }
            >
              <img src={closeBtn} alt="close popup" />
            </button>
          </div>
          <div className="modal-body">
            <div className="modal-alert-box">
              <h3 className="mb-6">Alert!</h3>
              <p>Are you sure you want to change the status to fulfilled?</p>
              <p>This action cannot be reverted.</p>
            </div>
            <div className="modal-action-btn">
              <a
                href="javascript:void(0)"
                data-dismiss="modal"
                className="btn btn-delete"
                onClick={() => this.handleFullfilStatus(this.state.orderId)}
              >
                Confirm
              </a>
              <a
                href="#!"
                className="btn btn-cancel"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() =>
                  this.setState({
                    fulfillmodal: !this.state.fulfillmodal,
                  })
                }
              >
                Cancel
              </a>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  orders: getSeller(state).orders.data,
  orderDetails: getSeller(state).orderDetails,
  pagination: getSeller(state).orders.pagination,
  loading: getSeller(state).ordersLoading,
});
const mapDispatchToProps = (dispatch) => ({
  loadOrders: (params) => dispatch(loadOrders(params)),
  loadOrderDetails: (id) => dispatch(loadOrderDetails(id)),
  updateFullfilStatus: (id, callback) =>
    dispatch(updateFullfilStatus(id, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(OrderListing);
