import React, { Component, createRef } from "react";
import image1 from "../../include/images/no-result-img.svg";

import SortingIconUp from "../common/sortingIconUp";
import SortingIcon from "../common/sortingIconDown";
import TopBar from "../common/topBar";
// import Footer from "../common/footer";

import vector from "../../include/images/Vector.svg";
import Moment from "moment";
import Pagination from "../common/Pagination";
import { connect } from "react-redux";
import {
  loadTransactions,
  getSeller,
  loadOrderDetails,
} from "../../store/seller";
import OrderDetails from "../common/orderDetails";

class Sales extends Component {
  ref = createRef(null);

  state = {
    search: "",
    sort: "desc",
    sort_by: "date",
    sorting: false,
    params: {},
    settingDropdown: false,
    profileDropdown: false,
    isOrderDetails: false,
    productId: "",
    disputeProductId: "",
    returnProductId: "",
  };

  openDetails = (id) => {
    this.props.loadOrderDetails(id);
    this.setState({ isOrderDetails: !this.state.isOrderDetails });
  };

  closeDetails = () => {
    this.setState({ isOrderDetails: !this.state.isOrderDetails });
  };

  sortBy = (param) => {
    let sort;
    if (this.state.sort === "asc") {
      sort = "desc";
    } else {
      sort = "asc";
    }
    this.setState({
      sort_by: param,
      sort,
      sorting: true,
    });
  };

  componentDidMount = () => {
    this.props.loadTransactions({
      sort_by: this.state.sort_by,
      sort: this.state.sort,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.sort_by !== prevState.sort_by ||
      this.state.sort !== prevState.sort
    ) {
      this.props.loadTransactions({
        sort_by: this.state.sort_by,
        sort: this.state.sort,
      });
    }
    if (this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.continuousStart();
    }
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.complete();
    }
  };
  onSearch = (e) => {
    if (this.state.search) {
      this.props.loadTransactions({ keyword: this.state.search });
    }
  };

  onKeyPress = (e) => {
    if (e.which === 13 && this.state.search) {
      this.props.loadTransactions({ keyword: this.state.search });
    }
  };
  onChangePage = (data) => {
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
    });
    if (data.page !== this.state.currentPage) {
      this.props.loadTransactions({ page: data.page });
    }
  };

  handleSettingDropdown = () => {
    if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
      this.setState({ settingDropdown: !this.state.settingDropdown });
    } else {
      this.setState({ settingDropdown: !this.state.settingDropdown });
    }
  };

  handleProfileDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
      this.setState({ profileDropdown: !this.state.profileDropdown });
    } else {
      this.setState({ profileDropdown: !this.state.profileDropdown });
    }
  };

  setSettingDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
    } else if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
    }
  };

  setProductId = (productId) => {
    this.setState({
      productId: productId === this.state.productId ? "" : productId,
      disputeProductId: "",
      returnProductId: "",
    });
  };

  setDisputeProductId = (disputeProductId) => {
    this.setState({
      disputeProductId,
      productId: "",
    });
  };

  setReturnProductId = (returnProductId) => {
    this.setState({
      returnProductId,
      productId: "",
    });
  };

  render() {
    const { record_per_page, total_page, current_page, filtered_record } =
      this.props.pagination;

    return (
      <>
        <main id="main" onClick={this.setSettingDropdown}>
          <TopBar
            handleProfileDropdown={this.handleProfileDropdown}
            handleSettingDropdown={this.handleSettingDropdown}
            settingDropdown={this.state.settingDropdown}
            profileDropdown={this.state.profileDropdown}
            loaDerRef={this.ref}
            sellerProfile={this.props.sellerProfile}
          />
          {!this.props.loading && this.props.transactions.length === 0 ? (
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="no-result-content">
                    <div className="no-result-box d-inline-block">
                      <div className="no-result-img">
                        <img src={image1} alt="" />
                      </div>
                      <div className="no-result-text">
                        <h2>Welcome Aboard!</h2>
                        <p>There are no orders placed yet.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            !this.props.loading && (
              <div className="dashboard-container custom-top">
                <div className="search-item-container order-search-item-container">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="search-item-content d-flex flex-column flex-md-row align-items-md--center justify-content-end justify-content-md-between">
                          <div className="flex-grow-1 d-flex align-items-center">
                            <div
                              className="input-group"
                              onKeyPress={this.onKeyPress}
                              onClick={this.onSearch}
                            >
                              <div
                                className="input-group-prepend"
                                onKeyPress={this.onKeyPress}
                                onClick={this.onSearch}
                              >
                                {" "}
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <img src={vector} alt="" />
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search here"
                                aria-label="search"
                                aria-describedby="basic-addon1"
                                value={this.state.search}
                                onChange={(e) =>
                                  this.setState({ search: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div className="showing-result d-flex align-items-center justify-content-end">
                            <div className="result-number">
                              Showing{" "}
                              <strong>
                                {this.props.pagination
                                  .total_record_on_current_page &&
                                  this.props.pagination
                                    .total_record_on_current_page}
                              </strong>{" "}
                              of{" "}
                              <strong>
                                {this.props.pagination &&
                                  this.props.pagination.filtered_record}
                              </strong>{" "}
                              results
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-text-group">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        {this.props.overall &&
                          this.props.overall.map((o, i) => (
                            <ul
                              key={i}
                              className="table-text-list d-flex flex-wrap justify-content-end"
                            >
                              <li>
                                <div className="tt-box">
                                  <h4>Total Sales</h4>
                                  <h5>
                                    $
                                    {o.total &&
                                      o.total.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                      })}
                                  </h5>
                                </div>
                              </li>
                              <li>
                                <div className="tt-box">
                                  <h4>Total Refund</h4>
                                  <h5>
                                    $
                                    {o.refund &&
                                      o.refund.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                      })}
                                  </h5>
                                </div>
                              </li>
                              <li>
                                <div className="tt-box">
                                  <h4>Effisim fee</h4>
                                  <h5>
                                    $
                                    {o.fees &&
                                      o.fees.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                      })}
                                  </h5>
                                </div>
                              </li>
                              <li>
                                <div className="tt-box">
                                  <h4>Tax</h4>
                                  <h5>
                                    $
                                    {o.tax &&
                                      o.tax.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                      })}
                                  </h5>
                                </div>
                              </li>
                              <li>
                                <div className="tt-box">
                                  <h4>Discount</h4>
                                  <h5>
                                    $
                                    {o.discount &&
                                      o.discount.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                      })}
                                  </h5>
                                </div>
                              </li>
                            </ul>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="product-list-item-container ">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="product-list-item-content">
                          <div className="table-responsive">
                            <table className="table right-align-table">
                              <thead>
                                <tr>
                                  <th
                                    onClick={() => this.sortBy("order_id")}
                                    style={{
                                      width: "250px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Order#{" "}
                                    {this.state.sorting === true &&
                                    this.state.sort_by === "order_id" ? (
                                      this.state.sort === "asc" ? (
                                        <SortingIcon sorting={() => {}} />
                                      ) : (
                                        <SortingIconUp sorting={() => {}} />
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </th>
                                  <th
                                    onClick={() => this.sortBy("total")}
                                    style={{
                                      width: "250px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Total Amount{" "}
                                    {this.state.sorting === true &&
                                    this.state.sort_by === "total" ? (
                                      this.state.sort === "asc" ? (
                                        <SortingIcon sorting={() => {}} />
                                      ) : (
                                        <SortingIconUp sorting={() => {}} />
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </th>
                                  <th style={{ width: "250px" }}>Balance</th>
                                  <th
                                    onClick={() => this.sortBy("fees")}
                                    style={{
                                      width: "250px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Effisim fee{" "}
                                    {this.state.sorting === true &&
                                    this.state.sort_by === "fees" ? (
                                      this.state.sort === "asc" ? (
                                        <SortingIcon sorting={() => {}} />
                                      ) : (
                                        <SortingIconUp sorting={() => {}} />
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </th>
                                  <th
                                    onClick={() => this.sortBy("tax")}
                                    style={{
                                      width: "250px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Tax{" "}
                                    {this.state.sorting === true &&
                                    this.state.sort_by === "tax" ? (
                                      this.state.sort === "asc" ? (
                                        <SortingIcon sorting={() => {}} />
                                      ) : (
                                        <SortingIconUp sorting={() => {}} />
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </th>
                                  <th
                                    onClick={() => this.sortBy("discount")}
                                    style={{
                                      width: "250px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Discount{" "}
                                    {this.state.sorting === true &&
                                    this.state.sort_by === "discount" ? (
                                      this.state.sort === "asc" ? (
                                        <SortingIcon sorting={() => {}} />
                                      ) : (
                                        <SortingIconUp sorting={() => {}} />
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </th>
                                  <th
                                    onClick={() => this.sortBy("amount")}
                                    style={{
                                      width: "250px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Seller Amount{" "}
                                    {this.state.sorting === true &&
                                    this.state.sort_by === "amount" ? (
                                      this.state.sort === "asc" ? (
                                        <SortingIcon sorting={() => {}} />
                                      ) : (
                                        <SortingIconUp sorting={() => {}} />
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </th>
                                  <th
                                    onClick={() => this.sortBy("date")}
                                    style={{
                                      width: "250px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Order Date{" "}
                                    {this.state.sorting === true &&
                                    this.state.sort_by === "date" ? (
                                      this.state.sort === "asc" ? (
                                        <SortingIcon sorting={() => {}} />
                                      ) : (
                                        <SortingIconUp sorting={() => {}} />
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.transactions &&
                                  this.props.transactions.map((e, i) => (
                                    <tr key={i}>
                                      <td>
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => {
                                            this.openDetails(e.order_id);
                                          }}
                                        >
                                          {e.order_id && e.order_id}
                                        </a>
                                        {e.isRefund === true ? (
                                          <div className="code">Refund</div>
                                        ) : (
                                          ""
                                        )}
                                      </td>

                                      <td>
                                        <b
                                          className={
                                            e.isRefund === true
                                              ? "refund-red"
                                              : ""
                                          }
                                        >
                                          $
                                          {e.total &&
                                            e.total.toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                            })}
                                        </b>
                                      </td>
                                      <td>
                                        <b>
                                          $
                                          {e.balance &&
                                            e.balance.toLocaleString(
                                              undefined,
                                              {
                                                minimumFractionDigits: 2,
                                              }
                                            )}
                                        </b>
                                      </td>
                                      <td>
                                        <b>
                                          {e.isRefund === true
                                            ? "--"
                                            : e.fees &&
                                              "$" +
                                                e.fees.toLocaleString(
                                                  undefined,
                                                  {
                                                    minimumFractionDigits: 2,
                                                  }
                                                )}
                                        </b>
                                      </td>

                                      <td>
                                        <b>
                                          {e.isRefund === true
                                            ? "--"
                                            : e.tax &&
                                              "$" +
                                                e.tax.toLocaleString(
                                                  undefined,
                                                  {
                                                    minimumFractionDigits: 2,
                                                  }
                                                )}
                                        </b>
                                      </td>

                                      <td>
                                        <b>
                                          {e.isRefund === true
                                            ? "--"
                                            : e.discount &&
                                              "$" +
                                                e.discount.toLocaleString(
                                                  undefined,
                                                  {
                                                    minimumFractionDigits: 2,
                                                  }
                                                )}
                                        </b>
                                      </td>

                                      <td>
                                        <b>
                                          {e.isRefund === true
                                            ? "--"
                                            : e.seller_amount &&
                                              "$" +
                                                e.seller_amount.toLocaleString(
                                                  undefined,
                                                  {
                                                    minimumFractionDigits: 2,
                                                  }
                                                )}
                                        </b>
                                      </td>
                                      <td>
                                        {Moment(e.created_on).format("LL")}{" "}
                                        <small>
                                          {" "}
                                          {Moment(e.created_on).format("LT")}
                                        </small>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <Pagination
                          totalRecords={filtered_record}
                          total_page={total_page}
                          pageLimit={record_per_page}
                          initialPage={current_page}
                          pagesToShow={5}
                          onChangePage={this.onChangePage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
          <OrderDetails
            setReturnProductId={this.setReturnProductId}
            returnProductId={this.state.returnProductId}
            loadOrderDetails={this.props.loadOrderDetails}
            setProductId={this.setProductId}
            productId={this.state.productId}
            setDisputeProductId={this.setDisputeProductId}
            disputeProductId={this.state.disputeProductId}
            isOrderDetails={this.state.isOrderDetails}
            closeDetails={this.closeDetails}
            orderDetails={this.props.orderDetails}
          />
        </main>
        <div
          className={this.state.isOrderDetails ? "overlay show" : "overlay"}
        ></div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  transactions: getSeller(state).transactions.data,
  pagination: getSeller(state).transactions.pagination,
  overall: getSeller(state).transactions.overall,
  loading: getSeller(state).transactionsLoading,
  sellerProfile: getSeller(state).profile,
  orderDetails: getSeller(state).orderDetails,
});

const mapDispatchToProps = (dispatch) => ({
  loadTransactions: (params) => dispatch(loadTransactions(params)),
  loadOrderDetails: (id) => dispatch(loadOrderDetails(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sales);
