import React, { createRef } from "react";
import image1 from "../../include/images/no-result-img.svg";
import LoadingBar from "react-top-loading-bar";
import Header from "./header";
import SimpleMap from "./map";
import GridSearch from "./gridSearch";
import ProductListA from "./listProductA";
import ProductListB from "./listProductB";
import { connect } from "react-redux";

import {
  getSeller,
  loadSellerData,
  loadSellerDataNew,
} from "./../../store/seller";
import Geocode from "react-geocode";

import haversine from "haversine-distance";
import { getProduct, loadProducts } from "../../store/product";
import Copyright from "../common/copyright";
import { PureComponent } from "react";
import { getCustomer, loadCustomerProfile } from "../../store/customer";
import { geocodeByAddress } from "react-places-autocomplete";
import { GoogleApiWrapper } from "google-maps-react";
import { withRouter } from "react-router-dom";
import { loadCart } from "../../store/cart";
Geocode.setApiKey(process.env.REACT_APP_MAPKEY);
Geocode.setLanguage("en");
Geocode.setRegion("us");
Geocode.setLocationType("APPROXIMATE");
Geocode.enableDebug();

class ProductList extends PureComponent {
  ref = createRef(null);

  state = {
    productId: "",
    userLatLng: true,
    tab: "A",
    lng: -81.4915242,
    lat: 28.4716263,
    maxDistance: 16093,
    seller: [],
    page: 1,
    q: "",
    isContact: false,
    zoom: 12,
    address: localStorage.getItem("customerDddress")
      ? JSON.parse(localStorage.getItem("customerDddress")).address
      : "",
    postal_code: localStorage.getItem("customerDddress")
      ? JSON.parse(localStorage.getItem("customerDddress")).postal_code
          .long_name
      : "",
    pickup: false,
    delivery_option_value: ["Weekly once", "More than a week"],
  };

  setProductId = (productId) =>
    this.setState({
      productId: productId === this.state.productId ? "" : productId,
    });
  handleChange = (address) => {
    this.setState({ address });
  };

  toggleContact = (id) => {
    this.setState({
      isContact: !this.state.isContact,
    });
  };

  setQ = (q) => {
    this.setState({ q: q.target.value }, () =>
      this.props.loadProducts({
        seller: [
          ...this.props.getSeller.seller.sellers.map((e) => e._id),
          ...this.props.getSeller.sellerNew.sellers.map((e) => e._id),
        ],
        q: this.state.q,
      })
    );
  };

  updateCheck = (option, values) => {
    if (option === "pickup") {
      this.setState({ pickup: values }, () =>
        this.props.loadSellerData({
          seller_id: this.props.match.params.seller_id,
          postal_code: this.state.postal_code,
          address: this.state.address,
          pickup: this.state.pickup,
          delivery_option_value: this.state.delivery_option_value,
          lng: this.state.lat,
          lat: this.state.lng,
          maxDistance: this.state.maxDistance,
          userLatLng: true,
        })
      );
    } else {
      const { delivery_option_value } = this.state;
      if (!values) {
        delivery_option_value.push(option);
        this.setState({ delivery_option_value }, () =>
          this.props.loadSellerData({
            seller_id: this.props.match.params.seller_id,
            postal_code: this.state.postal_code,

            address: this.state.address,
            pickup: this.state.pickup,
            delivery_option_value: this.state.delivery_option_value,
            lng: this.state.lat,
            lat: this.state.lng,
            maxDistance: this.state.maxDistance,
            userLatLng: true,
          })
        );
      } else {
        const delivery_option_value1 = delivery_option_value.filter(
          (e) => e !== option
        );
        this.setState({ delivery_option_value: delivery_option_value1 }, () =>
          this.props.loadSellerData({
            seller_id: this.props.match.params.seller_id,
            postal_code: this.state.postal_code,
            address: this.state.address,
            pickup: this.state.pickup,
            delivery_option_value: this.state.delivery_option_value,
            lng: this.state.lat,
            lat: this.state.lng,
            maxDistance: this.state.maxDistance,
            userLatLng: true,
          })
        );
      }
    }
  };

  updateLatLng = (
    lat,
    lng,
    address,
    postal_code = "",
    repplace = false,
    customerAddress = false
  ) => {
    if (
      localStorage.getItem("customerDddress") &&
      (!repplace || !customerAddress)
    )
      return;
    localStorage.setItem(
      "customerDddress",
      JSON.stringify({ lat, lng, address, postal_code, repplace })
    );
    this.setState(
      {
        address,
        lat,
        lng,
        userLatLng: true,
        postal_code: postal_code ? postal_code.long_name : "",
      },
      () => {
        this.props.loadSellerData({
          seller_id: this.props.match.params.seller_id,
          postal_code: this.state.postal_code,
          address: this.state.address,
          pickup: this.state.pickup,
          delivery_option_value: this.state.delivery_option_value,
          lng: this.state.lat,
          lat: this.state.lng,
          maxDistance: this.state.maxDistance,
          userLatLng: true,
        });
      }
    );
  };
  loadFunc = () => {
    this.setState({ page: this.state.page + 1 }, () =>
      this.props.loadProducts({
        seller: this.state.seller,
        page: this.state.page,
        q: this.state.q,
      })
    );
  };

  changeTabD = () => {
    this.setState({
      tab: "D",
    });
  };
  changeTabA = () => {
    this.setState({
      tab: "A",
    });
  };

  getLatLong = () => {
    this.updateLatLng(
      "28.4716263",
      "-81.4915242",
      "Dr Phillips Blvd, Orlando, FL, USA",
      {
        long_name: "32819",
      }
    );
    navigator.geolocation.getCurrentPosition((position) => {
      Geocode.fromLatLng(
        position.coords.latitude,
        position.coords.longitude
      ).then(
        (response) => {
          const address = response.results[0].formatted_address;
          const postal_code = response.results[0].address_components.find(
            (es) => es.types[0] === "postal_code"
          );
          this.updateLatLng(
            position.coords.latitude,
            position.coords.longitude,
            address,
            postal_code
          );
        },
        (error) => {
          console.error("error", error);
        }
      );
    });
  };

  componentDidMount = () => {
    this.ref.current.continuousStart();

    if (localStorage.getItem("x-auth-token-customer"))
      this.props.loadCustomerProfile();
    if (localStorage.getItem("customerDddress")) {
      const address = JSON.parse(localStorage.getItem("customerDddress"));

      this.updateLatLng(
        address.lat,
        address.lng,
        address.address,
        address.postal_code,
        false,
        true
      );
      this.props.loadSellerData({
        seller_id: this.props.match.params.seller_id,
        postal_code: address.postal_code.long_name,
        address: address.address,
        pickup: this.state.pickup,
        delivery_option_value: this.state.delivery_option_value,
        lat: address.lng,
        lng: address.lat,
        maxDistance: this.state.maxDistance,
        userLatLng: true,
      });
    } else {
      this.getLatLong();
    }
    document.body.classList.add("has-map");
    document.body.classList.add("fixed");
    this.props.history.replace();
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (
      !this.props.getCustomer.profileLoading &&
      this.props.getCustomer.profileLoading !==
        prevProps.getCustomer.profileLoading
    ) {
      if (this.props.getCustomer.profile.shipping_address) {
        try {
          const add = await geocodeByAddress(
            `${this.props.getCustomer.profile.shipping_address.address_line1} ${this.props.getCustomer.profile.shipping_address.address_line2} ${this.props.getCustomer.profile.shipping_address.city} ${this.props.getCustomer.profile.shipping_address.state} ${this.props.getCustomer.profile.shipping_address.zipcode}`
          );

          const lat = add[0].geometry.location.lat();
          const lng = add[0].geometry.location.lng();
          const localStorageData = localStorage.getItem("customerDddress")
            ? JSON.parse(localStorage.getItem("customerDddress"))
            : {};

          if (!localStorageData.repplace) {
            this.updateLatLng(
              lat,
              lng,
              this.props.getCustomer.profile.shipping_address.address_line2
                ? `${this.props.getCustomer.profile.shipping_address.address_line1}, ${this.props.getCustomer.profile.shipping_address.address_line2}, ${this.props.getCustomer.profile.shipping_address.city}, ${this.props.getCustomer.profile.shipping_address.state}, USA`
                : `${this.props.getCustomer.profile.shipping_address.address_line1}, ${this.props.getCustomer.profile.shipping_address.city}, ${this.props.getCustomer.profile.shipping_address.state}, USA`,
              {
                long_name:
                  this.props.getCustomer.profile.shipping_address.zipcode,
              },
              true,
              true
            );
          }
        } catch (error) {
          console.log("Error");
        }
      }
    }
    if (
      !this.props.getSeller.sellerLoading &&
      this.props.getSeller.sellerLoading !== prevProps.getSeller.sellerLoading
    ) {
      setTimeout(() => {
        this.setState({
          seller: [
            ...this.props.getSeller.seller.sellers.map((e) => e._id),
            ...this.props.getSeller.sellerNew.sellers.map((e) => e._id),
          ],
        });
        this.props.loadProducts({
          seller: [
            ...this.props.getSeller.seller.sellers.map((e) => e._id),
            ...this.props.getSeller.sellerNew.sellers.map((e) => e._id),
          ],
          q: this.state.q,
          page: this.state.page,
        });
      }, 500);
    }
    if (
      !this.props.getSeller.sellerLoadingNew &&
      this.props.getSeller.sellerLoadingNew !==
        prevProps.getSeller.sellerLoadingNew
    ) {
      this.setState({
        seller: [
          ...this.props.getSeller.seller.sellers.map((e) => e._id),
          ...this.props.getSeller.sellerNew.sellers.map((e) => e._id),
        ],
      });
      this.props.loadProducts({
        seller: [
          ...this.props.getSeller.seller.sellers.map((e) => e._id),
          ...this.props.getSeller.sellerNew.sellers.map((e) => e._id),
        ],
        q: this.state.q,
        page: this.state.page,
      });
    }

    if (
      !this.props.getProduct.productsLoading &&
      this.props.getProduct.productsLoading !==
        prevProps.getProduct.productsLoading
    ) {
      this.ref.current.complete();
    }
  };

  mapChange = (e, e1) => {
    this.setState({ zoom: e1.zoom });
    const bound = e1.getBounds();
    const a = [bound.getSouthWest().lat(), bound.getSouthWest().lng()];
    const b = [bound.getNorthEast().lat(), bound.getNorthEast().lng()];

    const maxDistance = haversine(a, b) / 2;
    this.setState({ maxDistance }, () =>
      this.props.loadSellerData({
        seller_id: this.props.match.params.seller_id,
        postal_code: this.state.postal_code,
        pickup: this.state.pickup,
        delivery_option_value: this.state.delivery_option_value,
        address: this.state.address,
        lng: this.state.lat,
        lat: this.state.lng,
        maxDistance: this.state.maxDistance,
        userLatLng: this.state.userLatLng,
      })
    );
  };

  componentWillUnmount = () => {
    document.body.classList.remove("has-map");
  };

  render() {
    const { products, productsLoading } = this.props.getProduct;
    const { data, pagination } = products;
    return (
      <div
        onClick={(e) => {
          this.setProductId("");
        }}
        className={
          this.state.isContact === true ? "has-map  toggled-tab" : "has-map "
        }
      >
        <LoadingBar color="#0b4474" ref={this.ref} height={4} />
        <Header
          updateLatLng={this.updateLatLng}
          address={this.state.address}
          q={this.state.q}
          setQ={this.setQ}
          toggleContact={this.toggleContact}
          productList={true}
        />
        <main id="main">
          <div className="map">
            <SimpleMap
              zoom={this.state.zoom}
              mapChange={this.mapChange}
              sellers={this.props.getSeller.seller.sellers}
              sallerData={this.props.getSeller.seller.sallerData}
              center={{ lng: this.state.lng, lat: this.state.lat }}
              productCount={this.props.getSeller.seller.productCount}
            />
          </div>
          <GridSearch
            loadCart={this.props.loadCart}
            postal_code={this.state.postal_code}
            updateCheck={this.updateCheck}
            userLatLng={this.state.userLatLng}
            pickup={this.state.pickup}
            delivery_option_value={this.state.delivery_option_value}
            handleChange={this.handleChange}
            address={this.state.address}
            setQ={this.setQ}
            q={this.state.q}
            pagination={pagination}
            getLatLong={this.getLatLong}
            updateLatLng={this.updateLatLng}
            tab={this.state.tab}
            changeTabA={this.changeTabA}
            changeTabD={this.changeTabD}
          />

          <div className="grid-item-container">
            {!productsLoading && data.length === 0 ? (
              <div className="no-result-container pt-0">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="no-result-content">
                        <div className="no-result-box d-inline-block">
                          <div className="no-result-img">
                            <img src={image1} alt="" />
                          </div>
                          <div className="no-result-text">
                            <h2>No results found</h2>
                            <p>
                              Sorry! There are no results matching your search
                              criteria. Please modify your search parameters and
                              try again.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    {this.state.tab === "A" ? (
                      <ProductListA
                        seller_id={this.props.match.params.seller_id}
                        setProductId={this.setProductId}
                        productId={this.state.productId}
                        states={this.state}
                        loadFunc={this.loadFunc}
                        products={data}
                        pagination={pagination}
                        productsLoading={productsLoading}
                      />
                    ) : (
                      <ProductListB
                        seller_id={this.props.match.params.seller_id}
                        setProductId={this.setProductId}
                        productId={this.state.productId}
                        states={this.state}
                        loadFunc={this.loadFunc}
                        products={data}
                        pagination={pagination}
                        productsLoading={productsLoading}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          <footer className="footer sticky" id="footer">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="footer-content text-center">
                    <Copyright />
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getSeller: getSeller(state),
  getProduct: getProduct(state),
  getCustomer: getCustomer(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadSellerData: (data) => {
    dispatch(loadSellerData(data));
    dispatch(loadSellerDataNew(data));
  },
  loadProducts: (data) => dispatch(loadProducts(data)),
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
  loadCart: (id, data) => dispatch(loadCart(id, data)),
});

//export default connect(mapStateToProps, mapDispatchToProps)(ProductList);

export default GoogleApiWrapper({
  apiKey: "AIzaSyDHZd1NTh7pJw6-zGe1Mf_cUBx2cIhz46Y",
})(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductList)));
