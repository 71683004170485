import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../include/images/effisim-logo-white.svg";

class Sidebar extends Component {
  render() {
    return (
      <div className="member-left">
        <Link to="/products-listing" className="logo-area">
          <img src={logo} alt="" />
        </Link>

        <h4>
          Quick search, buy, pick up, and delivery is what customers are
          demanding. Let’s work together to make it happen.
        </h4>

        {/* <p>
          Sellers pay <del> $9/month/store</del>{" "}
          <span style={{ color: "#B94651" }}>free for 1 year</span> + 5% seller
          fees + credit card charges
        </p> */}
        {/* <p>Free for 1 year, after that pick a <a className="pricing" href="https://effisim.info/pricing" target="_blank">pricing</a> model that fits your need</p> */}
        <p>Pick a <a className="pricing" href="https://effisim.info/pricing" target="_blank">pricing</a> model that works for you</p>
      </div>

    );
  }
}

export default Sidebar;
