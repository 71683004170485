import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../product/header";
import empty_icon from "./../../include/images/tick-bubble.svg";
import { Link } from "react-router-dom";
import { getCart } from "../../store/cart";

class OrderSuccess extends Component {
  render() {
    console.log("history", this.props.history.location.state.order_id);
    return (
      <>
        <Header cart={"status"} toggleContact={null} />
        <main id="main">
          <div className="cart-container d-flex align-items-center">
            <div className="empty-cart-content">
              <div className="ec-img-box">
                <img src={empty_icon} alt="" />
              </div>
              <h4>
                Thank you for your order. Your order number is{" "}
                {this.props.history.location.state.order_id}
              </h4>

              {this.props.getCart.cartDetail.sellers &&
              this.props.getCart.cartDetail.sellers.length !== 0 ? (
                <Link to="/products-listing" className="btn btn-default">
                  Continue Shopping
                </Link>
              ) : (
                <>
                  <Link to="/products-listing" className="btn btn-default m-2">
                    Continue Shopping
                  </Link>
                  {/* <Link to="/cart" className="btn btn-default m-2">
                    Go to Cart
                  </Link> */}
                </>
              )}
            </div>
          </div>
          <footer className="footer " id="footer">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="footer-content text-center">
                    <div className="copyright">
                      &#169; 2021-{new Date().getFullYear()} Effisim Corp. All
                      rights reserved
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </main>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  getCart: getCart(state),
});
export default connect(mapStateToProps, null)(OrderSuccess);
