import React, { Component } from "react";
import LoadingBar from "react-top-loading-bar";

import logo from "../../include/images/effisim-logo.svg";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import group from "../../include/images/profileSVG.svg";
import carets from "../../include/images/toggler-icon.svg";
import {
  getSeller,
  loadSellerProfile,
  loadStripeAccount,
  logoutSeller,
} from "../../store/seller";

import { toast } from "react-toastify";
import AlertError from "../common/alertError";

class TopBar extends Component {
  state = {
    data: {
      photo: {},
      companyName: " ",
    },
    isShow: false,
  };

  componentDidMount = () => {
    this.props.loadSellerProfile();
    this.props.loadStripeAccount();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.profileLoading &&
      this.props.profileLoading !== prevProps.profileLoading
    ) {
      const { profilePictureURLs: photo, company: companyName } =
        this.props.sellerProfile;
      const data = {
        photo,
        companyName,
      };
      this.setState({
        data,
      });
    }
  };

  toggle = () => {
    this.setState({
      isShow: !this.state.isShow,
    });
  };

  logout = (e) => {
    e.preventDefault();
    this.props.logoutSeller(this.callback);
  };

  callback = (res) => {
    this.setState({ loading: false });
    if (res.status === 200) {
      window.localStorage.removeItem("x-auth-token-seller");
      window.localStorage.removeItem("x-refresh-token-seller");
      window.localStorage.removeItem("customerDddress");
      localStorage.removeItem("customer_information");
      window.location.href = "/seller/login";
    } else {
      toast(<AlertError message={"Failed"} />);
    }
  };

  checkChars = (str) => {
    var max = 12;
    return str ? (str.length > max ? str.substring(0, max) + "..." : str) : "";
  };

  render() {
    return (
      <header id="header" className="new-header flex-column">
        {!this.props.stripeAccountDetails.details_submitted && (
          <div className="header-intro-box d-none d-xl-block">
            <p>
              You have not linked your stripe account.{" "}
              <a
                href="javascript:void(0)"
                onClick={() => {
                  this.props.history.push("/seller/stripe");
                }}
              >
                Please click here to link the same.
              </a>
            </p>
          </div>
        )}
        <LoadingBar color="#0b4474" ref={this.props.loaDerRef} />
        <nav className="navbar navbar-expand-xl">
          <div className="container container1">
            <div className="nav-inside d-flex align-items-center justify-content-between">
              <Link className="navbar-brand" to="/products-listing">
                <img src={logo} alt="" />
              </Link>
              <button
                className={
                  this.state.isShow === true
                    ? "navbar-toggler"
                    : "navbar-toggler collapsed"
                }
                type="button"
                data-toggle="collapse"
                data-target="#mainNav"
                aria-controls="mainNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span
                  className="navbar-toggler-icon"
                  onClick={() => this.toggle()}
                ></span>
              </button>
              <div
                className={
                  this.state.isShow
                    ? "collapse navbar-collapse show"
                    : "collapse navbar-collapse"
                }
                id="mainNav"
              >
                <div className="navbar-inside">
                  <ul className="navbar-nav">
                    <li
                      className={
                        window.location.pathname === "/product-listing"
                          ? "nav-item active custom"
                          : "nav-item"
                      }
                    >
                      <Link className="nav-link" to={"/product-listing"}>
                        Products
                      </Link>
                    </li>

                    <li
                      className={
                        window.location.pathname === "/seller/orders"
                          ? "nav-item active custom"
                          : "nav-item"
                      }
                    >
                      <Link className="nav-link" to={"/seller/orders"}>
                        Orders
                      </Link>
                    </li>
                    <li
                      className={
                        window.location.pathname === "/seller/sales"
                          ? "nav-item active custom"
                          : "nav-item"
                      }
                    >
                      <Link className="nav-link" to={"/seller/sales"}>
                        Sales
                      </Link>
                    </li>
                    <li
                      className={
                        window.location.pathname ===
                        "/seller/dispute-return-requests"
                          ? "nav-item active custom"
                          : "nav-item"
                      }
                    >
                      <Link
                        className="nav-link"
                        to={"/seller/dispute-return-requests"}
                      >
                        Disputes/Returns
                      </Link>
                    </li>

                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.props.handleSettingDropdown();
                      }}
                      className={
                        this.props.settingDropdown
                          ? "nav-item dropdown show"
                          : "nav-item dropdown"
                      }
                    >
                      <a
                        className={
                          this.props.settingDropdown
                            ? "nav-link has-submenu highlighted"
                            : "nav-link dropdown-toggle"
                        }
                        href="javascript:void(0)"
                        id="about"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Settings
                        <span className="carets">
                          <img src={carets} alt="" />
                        </span>
                      </a>
                      <ul
                        className={
                          this.props.settingDropdown
                            ? "dropdown-menu setting-dropdown"
                            : "dropdown-menu"
                        }
                        aria-labelledby="about"
                      >
                        {this.props.sellerProfile &&
                          this.props.sellerProfile.delivery &&
                          this.props.sellerProfile.delivery.delivery_option &&
                          this.props.sellerProfile.delivery.delivery_area ===
                            "Custom area on a map" && (
                            <li>
                              <Link
                                className="dropdown-item"
                                to={"/seller/maps"}
                              >
                                Maps
                              </Link>
                            </li>
                          )}
                        <li>
                          <Link
                            className="dropdown-item"
                            to={"/seller/my-reviews"}
                          >
                            Reviews
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to={"/product-category"}
                          >
                            Categories
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to={"/seller/stock-history"}
                          >
                            Stock History
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to={"/seller/coupons"}
                          >
                            Coupons
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/seller/stripe">
                            Link Your Stripe Account
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="header-right mr-xl-0">
                <div className="hdr-logout dropdown d-flex justify-content-center align-items-center">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.props.handleProfileDropdown();
                    }}
                    className="dropdown-toggle d-flex justify-content-center align-items-center"
                    href="javascript:void(0)"
                    id={this.props.profileDropdown}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {/* <img src="include/images/profileSVG.svg" alt="" /> */}
                    {this.state.data.photo === undefined ? (
                      <img className="logout-profile-img" src={group} alt="" />
                    ) : (
                      <img
                        className="logout-profile-img"
                        src={
                          this.state.data.photo
                            ? process.env.REACT_APP_S3URL +
                              this.state.data.photo.small
                            : ""
                        }
                        alt=""
                      />
                    )}
                    <div
                      className={
                        this.props.profileDropdown
                          ? "hdr-info hdr-info d-flex flex-column align-items center show"
                          : "hdr-info hdr-info d-flex flex-column align-items center"
                      }
                    >
                      <span>
                        {this.checkChars(
                          this.state.data.companyName &&
                            this.state.data.companyName
                        )}
                      </span>
                    </div>
                    <span className="carets">
                      <img src={carets} alt="" />
                    </span>
                  </a>
                  <ul
                    className={
                      this.props.profileDropdown
                        ? "dropdown-menu show"
                        : "dropdown-menu"
                    }
                    aria-labelledby={this.props.profileDropdown}
                  >
                    <li>
                      <Link className="dropdown-item" to={"/seller/basic-info"}>
                        Seller Info
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/hours-of-operation"}
                      >
                        Hours of operation
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to={"/change-password"}>
                        Change Password
                      </Link>
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="javascript:void(0)"
                        onClick={this.logout}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  profileLoading: getSeller(state).profileLoading,
  sellerProfile: getSeller(state).profile,
  stripeAccountDetails: getSeller(state).stripeAccountDetails,
});

const mapDispatchToProps = (dispatch) => ({
  loadSellerProfile: () => dispatch(loadSellerProfile()),
  loadStripeAccount: () => dispatch(loadStripeAccount()),
  logoutSeller: (callback) => dispatch(logoutSeller(callback)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopBar));
