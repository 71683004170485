import React, { Component } from "react";
import { Link } from "react-router-dom";
import breadcrumbSVG from "../../include/images/breadcrumbSVG.svg";
export default class Breadcrumb extends Component {
  render() {
    return (
      <div className="breadcrumb-container">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="breadcrumbs">
                <li>
                  <Link
                    to={{
                      pathname:
                        this.props.states && this.props.states.seller_id
                          ? "/store/" + this.props.states.seller_id
                          : "/",
                      state: this.props.states,
                    }}
                  >
                    <img src={breadcrumbSVG} alt="" />
                    Back to results
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
