import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import logo from "../../include/images/effisim-logo.svg";
import closeicon from "../../include/images/close_icon-1.svg";
import cartIcon from "../../include/images/cart-icon_1.svg";
import homeIcon from "../../include/images/home-icon.svg";
import user from "../../include/images/user.svg";
import phone from "../../include/images/phone-icon.svg";
import enter from "../../include/images/enter-icon.svg";
import Vector from "../../include/images/Vector.svg";

import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import { getCart, loadCart } from "../../store/cart";
import Contact from "./../common/contact";
import {
  logoutCustomer,
  loadCustomerProfile,
  getCustomer,
} from "../../store/customer";
import userIcon from "../../include/images/user.svg";
import logoutIcon from "../../include/images/logout.svg";

import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import {
  getCustomerName,
  getCustomerToken,
} from "../services/localStorageServices";
import _ from "lodash";
import AddressFlyer from "./addressFlyer";

class Header extends Component {
  state = {
    seratchOpen: false,
    adressChange: false,
    isContact: false,
    isShow: false,
    user_device_id: localStorage.getItem("user_device_id"),
    param: JSON.parse(localStorage.getItem("customerDddress")),
  };
  toggleSearch = () => {
    this.setState({ seratchOpen: !this.state.seratchOpen }, () => {
      if (this.state.seratchOpen) {
        document.body.classList.add("search-open");
      } else {
        document.body.classList.remove("search-open");
      }
    });
  };

  //search-open
  handleadressChange = () => {
    this.setState({ adressChange: !this.state.adressChange });
    document.body.classList.add("toggled-tab");
  };
  handleClose = () => {
    this.setState(
      {
        adressChange: !this.state.adressChange,
      },
      () => this.props.loadCart(this.state.user_device_id)
    );
    document.body.classList.remove("toggled-tab");
  };
  toggle = () => {
    this.setState({
      isShow: !this.state.isShow,
    });
  };
  toggleContact = () => {
    this.setState({
      isContact: !this.state.isContact,
    });
    document.body.classList.add("toggled-tab");
  };

  removeContact = () => {
    this.setState({
      isContact: !this.state.isContact,
    });
    document.body.classList.remove("toggled-tab");
  };

  componentDidMount = () => {
    localStorage.setItem("redirectUrl", this.props.location.pathname);

    if (this.state.user_device_id) {
      // this.props.loadCustomerProfile();
      this.props.loadCart(this.state.user_device_id, {
        address: this.state.param && this.state.param.address,
        zipCode: this.state.param && this.state.param.postal_code.long_name,
        lat: this.state.param && this.state.param.lat,
        lon: this.state.param && this.state.param.lng,
      });
    } else {
      const uuid = uuidv4();
      localStorage.setItem("user_device_id", uuid);
      this.props.loadCart(uuid);
    }
  };

  logout = (e) => {
    e.preventDefault();
    this.props.logoutCustomer(this.callback);
  };

  callback = (res) => {
    if (res.status === 200) {
      window.localStorage.removeItem("x-auth-token-customer");
      window.localStorage.removeItem("x-refresh-token-customer");
      window.localStorage.removeItem("customer-name");
      window.localStorage.removeItem("redirectUrl");
      localStorage.removeItem("customer_information");
      window.localStorage.removeItem("customerDddress");
      window.location.href = "/";
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };

  render() {
    const adressArr = this.props.address ? this.props.address.split(",") : [];
    const { final_total, total_products } =
      this.props.getCart.cartDetail.orderSummary;
    return (
      <>
        <header id="header3">
          <div className="header-top">
            <div className="container container1">
              <div className="row">
                <div className="col-md-12">
                  <div className="ht-box  d-flex align-items-center">
                    <div className="user-intro-box">
                      Welcome,{" "}
                      <span>
                        {localStorage.getItem("customer-name")
                          ? localStorage.getItem("customer-name")
                          : localStorage.getItem("x-auth-token-seller")
                          ? JSON.parse(localStorage.getItem("seller-profile"))
                              .company
                          : "Guest"}
                      </span>
                    </div>
                    {localStorage.getItem("x-auth-token-seller") ? (
                      <ul className="query-list d-flex flex-wrap align-items-center ml-auto">
                        <li>
                          <Link to={"/product-listing"}>
                            Back to seller dashboard{" "}
                          </Link>
                        </li>
                      </ul>
                    ) : (
                      <ul className="query-list d-flex flex-wrap align-items-center ml-auto">
                        {getCustomerToken("refresh") ? (
                          <>
                            <li>
                              <i
                                onClick={() =>
                                  this.props.history.push("/products-listing")
                                }
                              >
                                <img src={homeIcon} alt="" />
                              </i>
                              <Link to="/products-listing">Home</Link>
                            </li>
                            <li>
                              <i onClick={this.toggleContact}>
                                <img src={phone} alt="" />
                              </i>
                              <a
                                onClick={this.toggleContact}
                                href="javascript:void(0)"
                              >
                                Contact Us
                              </a>
                            </li>

                            <li>
                              <i
                                onClick={() =>
                                  this.props.history.push(
                                    "/customer/account-details"
                                  )
                                }
                              >
                                <img src={userIcon} alt="" />
                              </i>
                              <Link to={"/customer/account-details"}>
                                My account
                              </Link>
                            </li>
                            <li>
                              <i onClick={this.logout}>
                                <img src={logoutIcon} alt="" />
                              </i>
                              <a
                                href="javascript:void(0)"
                                onClick={this.logout}
                              >
                                Logout
                              </a>
                            </li>
                          </>
                        ) : (
                          <>
                            <li>
                              <i
                                onClick={() =>
                                  this.props.history.push("/products-listing")
                                }
                              >
                                <img src={homeIcon} alt="" />
                              </i>
                              <Link to="/products-listing">Home</Link>
                            </li>
                            <li>
                              <i onClick={this.toggleContact}>
                                <img src={phone} alt="" />
                              </i>
                              <a
                                onClick={this.toggleContact}
                                href="javascript:void(0)"
                              >
                                Contact Us
                              </a>
                            </li>
                            <li>
                              <i
                                onClick={() =>
                                  this.props.history.push("/customer/login")
                                }
                              >
                                <img src={enter} alt="" />
                              </i>
                              <Link to="/customer/login">Login</Link>
                            </li>
                            <li>
                              <i
                                onClick={() =>
                                  this.props.history.push(
                                    "/customer/registration"
                                  )
                                }
                              >
                                <img src={user} alt="" />
                              </i>
                              <Link to="/customer/registration">Sign up</Link>
                            </li>
                          </>
                        )}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-main">
            <nav className="navbar">
              <div className="container container1">
                <div className="nav-inside d-flex align-items-center justify-content-between">
                  <Link className="navbar-brand" to="/products-listing">
                    <img src={logo} alt="" />
                  </Link>
                  {this.props.productList && (
                    <div className="new-search-row d-flex align-items-center">
                      <div
                        className="md-search-icon d-block d-md-none"
                        onClick={() => this.toggleSearch()}
                      >
                        <img src={Vector} alt="" />
                      </div>

                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="basic-addon1">
                            <img src={Vector} alt="" />
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search here"
                          aria-label="search"
                          aria-describedby="basic-addon1"
                          onChange={this.props.setQ}
                          value={this.props.q}
                        />
                        <img
                          className="close"
                          src={closeicon}
                          onClick={() =>
                            this.props.setQ({ target: { value: "" } })
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                  {this.props.cart && (
                    <div className="progressbar-box d-flex align-items-center justify-content-center">
                      <ul className="progressbar d-flex flex-wrap">
                        <li
                          className={
                            this.props.cart === "cart" ? "active" : "complete"
                          }
                        >
                          <Link to="/cart">
                            <i className="progressbar-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="14"
                                xmlnsv="https://vecta.io/nano"
                              >
                                <path d="M14.938 2c-.062-.062-.125-.125-.25-.125H2.813L2.5.25C2.5.125 2.312 0 2.187 0H.312C.125 0 0 .125 0 .312s.125.313.313.313h1.625l1.5 7.625c.188 1 1.125 1.75 2.125 1.75h7.187c.188 0 .313-.125.313-.313s-.125-.313-.312-.313H5.625c-.5 0-1-.25-1.25-.688l9.125-1.25a.27.27 0 0 0 .25-.25l1.25-5s0-.125-.062-.187zm-1.75 4.875L4.062 8.062 2.937 2.437H14.25l-1.062 4.438zm-7.876 3.75c-.875 0-1.562.688-1.562 1.563s.688 1.563 1.562 1.563 1.562-.687 1.562-1.562-.687-1.562-1.562-1.562zm0 2.5a.96.96 0 0 1-.937-.937c0-.5.437-.937.938-.937s.938.438.938.938-.437.938-.937.938zm6.251-2.5c-.875 0-1.562.688-1.562 1.563s.688 1.563 1.563 1.563 1.563-.687 1.563-1.562-.687-1.562-1.562-1.562zm0 2.5a.96.96 0 0 1-.937-.937c0-.5.438-.937.938-.937s.938.438.938.938-.437.938-.937.938z"></path>
                              </svg>
                            </i>
                            <span>Cart</span>
                          </Link>
                        </li>
                        <li
                          className={
                            this.props.cart === "checkout"
                              ? "active"
                              : this.props.cart === "review" ||
                                this.props.cart === "status"
                              ? "complete"
                              : ""
                          }
                        >
                          <i className="progressbar-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              xmlnsv="https://vecta.io/nano"
                            >
                              <path d="M14.063 13.125H.938A.94.94 0 0 1 0 12.188v-8.75A.94.94 0 0 1 .938 2.5h4.375c.173 0 .313.14.313.313s-.14.313-.312.313H.938c-.172 0-.312.141-.312.313v8.75c0 .172.141.313.313.313h13.125c.172 0 .313-.141.313-.312V9.063c0-.172.14-.312.313-.312s.313.14.313.313v3.125a.94.94 0 0 1-.937.938z"></path>
                              <path d="M9.687 15H5.312c-.126 0-.241-.076-.289-.193s-.021-.251.067-.341c.823-.822.834-1.619.834-1.652.001-.171.141-.314.313-.314H8.75c.172 0 .311.139.313.311.001.039.025.835.846 1.656.089.089.116.224.068.341s-.163.192-.29.192zm-3.706-.625h3.036c-.351-.501-.489-.961-.544-1.25H6.517a3.19 3.19 0 0 1-.536 1.25zm3.081-1.562h.006-.006zm5.626-1.563H.313c-.172 0-.312-.14-.312-.312s.14-.312.313-.312h14.375c.172 0 .313.14.313.313s-.14.313-.312.313zm-4.063-2.5A4.38 4.38 0 0 1 6.25 4.375 4.38 4.38 0 0 1 10.625 0 4.38 4.38 0 0 1 15 4.375a4.38 4.38 0 0 1-4.375 4.375zm0-8.125c-2.068 0-3.75 1.682-3.75 3.75s1.682 3.75 3.75 3.75 3.75-1.682 3.75-3.75-1.682-3.75-3.75-3.75zm.313 5.938H9.375c-.172 0-.312-.14-.312-.312s.14-.312.313-.312h1.563c.345 0 .625-.28.625-.625s-.28-.625-.625-.625h-.625a1.25 1.25 0 1 1 0-2.5h1.563c.172 0 .313.14.313.313s-.14.313-.312.313h-1.562c-.345 0-.625.28-.625.625s.28.625.625.625h.625a1.25 1.25 0 1 1 0 2.5z"></path>
                              <path d="M10.625 7.5c-.172 0-.312-.14-.312-.312V6.25c0-.173.14-.312.313-.312s.313.14.313.313v.937c0 .172-.14.313-.312.313zm0-4.687c-.172 0-.312-.14-.312-.312v-.937c0-.173.14-.312.313-.312s.313.14.313.313V2.5c0 .172-.14.313-.312.313z"></path>
                            </svg>
                          </i>
                          <span>Checkout</span>
                        </li>
                        <li
                          className={
                            this.props.cart === "review"
                              ? "active"
                              : this.props.cart === "status"
                              ? "complete"
                              : ""
                          }
                        >
                          <i className="progressbar-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              xmlnsv="https://vecta.io/nano"
                            >
                              <path d="M7.434 15c-.082 0-.162-.032-.221-.091-.074-.074-.106-.18-.086-.282l.442-2.209c.013-.061.041-.117.086-.16l4.861-4.861a.94.94 0 0 1 1.326 0l.884.884a.94.94 0 0 1 0 1.326l-4.861 4.861a.31.31 0 0 1-.16.086l-2.209.442c-.02.004-.041.006-.061.006zm.73-2.368l-.331 1.657 1.657-.331 4.794-4.794c.122-.122.122-.32 0-.442l-.884-.884c-.122-.122-.32-.122-.442 0l-4.794 4.794zm1.479 1.614h.006-.006zm-3.705-1.121H1.563C.701 13.125 0 12.424 0 11.563V3.438c0-.862.701-1.562 1.563-1.562h1.25c.172 0 .313.14.313.313s-.14.313-.312.313h-1.25a.94.94 0 0 0-.937.938v8.125a.94.94 0 0 0 .938.938H5.94c.173 0 .313.14.313.313s-.14.313-.312.313zM10.313 7.5c-.172 0-.313-.14-.313-.312v-3.75a.94.94 0 0 0-.937-.937h-1.25c-.173 0-.312-.14-.312-.312s.14-.312.313-.312h1.25c.862 0 1.563.701 1.563 1.563v3.75c0 .173-.14.313-.312.313z"></path>
                              <path d="M7.188 3.75h-3.75a.94.94 0 0 1-.937-.937v-1.25c0-.173.14-.312.313-.312h.969A1.56 1.56 0 0 1 5.313 0c.755 0 1.386.538 1.531 1.25h.969c.173 0 .313.14.313.313v1.25a.94.94 0 0 1-.937.938zM3.125 1.875v.938c0 .172.141.313.313.313h3.75c.172 0 .313-.141.313-.312v-.937h-.937c-.173 0-.312-.14-.312-.312a.94.94 0 0 0-.937-.937.94.94 0 0 0-.937.938c0 .173-.14.313-.312.313h-.937zm5.312 3.75h-6.25c-.172 0-.312-.14-.312-.313S2.015 5 2.188 5h6.25c.172 0 .313.14.313.313s-.14.313-.312.313zm0 1.875h-6.25c-.172 0-.312-.14-.312-.313s.14-.312.313-.312h6.25c.172 0 .313.14.313.313s-.14.313-.312.313zm0 1.875h-6.25c-.172 0-.312-.14-.312-.312s.14-.312.313-.312h6.25c.172 0 .313.14.313.313s-.14.312-.312.312z"></path>
                            </svg>
                          </i>
                          <span>Review</span>
                        </li>
                      </ul>
                    </div>
                  )}
                  {!localStorage.getItem("x-auth-token-seller") && (
                    <div className="header-right-content">
                      <div className="cart-icon">
                        <i
                          onClick={(e) => {
                            this.props.history.push("/cart");
                          }}
                        >
                          <img src={cartIcon} alt="" />
                          <span className="price-icon">
                            {total_products ? total_products : 0}
                          </span>
                        </i>
                        {/* <span>
                          $
                          {final_total
                            ? final_total.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })
                            : 0}
                        </span> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </nav>
          </div>
        </header>
        <Contact
          isContact={this.state.isContact}
          toggleContact={this.toggleContact}
          removeContact={this.removeContact}
        />
        {this.state.adressChange && (
          <AddressFlyer
            handleClose={this.handleClose}
            updateLatLng={this.props.updateLatLng}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  getCart: getCart(state),
  customerProfile: getCustomer(state).profile,
});
const mapDispatchToProps = (dispatch) => ({
  loadCart: (id, data) => dispatch(loadCart(id, data)),
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
  logoutCustomer: (callback) => dispatch(logoutCustomer(callback)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
