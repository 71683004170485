import React from "react";
import Joi from "joi-browser";
import Form from "../common/form/form";
import SubmitBtn from "../common/form/submitBtn";
import FetchBtn from "../common/form/fetchBtn";

import Sidebar from "../common/sideBar";
import { Link, withRouter } from "react-router-dom";
import {
  sendMobileOtp,
  mobileAvailability,
  emailAvailability,
  sendEmailOtp,
  zipValidation,
  addressValidation,
} from "../../store/seller";

import AlertSuccess from "../common/alertSuccess";
import AlertError from "../common/alertError";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  confirmPasswordMatch,
  confirmPasswordRequired,
  companyNameRequired,
  passwordInvalid,
  passwordInvalidMax,
  passwordRequired,
  firstNameRequired,
  lastNameRequired,
  Address1Required,
  cityRequired,
  stateRequired,
  emailRequired,
  zipRequired,
  mobileRequired,
  mobileInvalid,
  emailInvalid,
} from "../common/misc";
const searchOptions = {
  types: ["address"],
  componentRestrictions: { country: "us" },
};

class SignUpForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      labelFetch: "Fetch City & State",

      labelRegister: "Register",
      loader: false,
      zipLoader: false,
      btnClass: "btn btn-default",
      addr: {
        street1: "",
        street2: "",
        state: "",
        city: "",
        zip: "",
        country: "",
        latitude: "",
        longitude: "",
      },
      formatted_address: "",
      data: {
        companyName: "",
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        // street1: "",
        // street2: "",
        // state: "",
        // city: "",
        // zip: "",
        password: "",
        confirmPassword: "",
      },
      errors: {},
    };
  }
  handleChange1 = (address) => {
    this.setState({ formatted_address: address });
  };

  handleSelect = (address) => {
    this.setState({ formatted_address: address });
    geocodeByAddress(address)
      .then((results) => {
        console.log("result", results);
        const zipCode = results[0].address_components.find(
          (ra) => ra.types[0] === "postal_code"
        );
        var street1;
        if (
          results[0].address_components.find(
            (ra) => ra.types[0] === "street_number"
          ) &&
          results[0].address_components.find(
            (ra) => ra.types[0] === "street_number"
          ).long_name
        ) {
          street1 = `${
            results[0].address_components.find(
              (ra) => ra.types[0] === "street_number"
            ).long_name
          } ${
            results[0].address_components.find((ra) => ra.types[0] === "route")
              .long_name
          }`;
          console.log("if");
        } else {
          street1 = results[0].address_components.find(
            (ra) => ra.types[0] === "route"
          ).long_name;
          console.log("strr", street1);
        }
        const street2 = results[0].address_components.find(
          (ra) => ra.types[0] === "administrative_area_level_2"
        );
        const city = results[0].address_components.find(
          (ra) => ra.types[0] === "locality"
        );
        const state = results[0].address_components.find(
          (ra) => ra.types[0] === "administrative_area_level_1"
        );
        const country = results[0].address_components.find(
          (ra) => ra.types[0] === "country"
        );
        const latitude = results[0].geometry.location.lat();
        const longitude = results[0].geometry.location.lng();
        const formatted_address = results[0].formatted_address;
        this.setState({
          addr: {
            zip: zipCode && zipCode.long_name,
            street1,
            street2: street2 && street2.long_name,
            city: city && city.long_name,
            state: state && state.short_name,
            country: country && country.short_name,
            formatted_address,
            latitude,
            longitude,
          },
        });
      })
      .catch((error) => console.error("Error", error));
  };

  componentDidMount = () => {
    document.body.classList.add("pt-0");
    console.log("add", this.state.addr);

    if (this.props.verifyMobile.mobile) {
      this.setState({
        data: {
          companyName: this.props.formData.companyName,
          mobile: this.props.formData.mobile.toString(),
          email: this.props.formData.email,
          password: this.props.formData.password,
          firstName: this.props.formData.firstName,
          lastName: this.props.formData.lastName,
          // street1: this.props.formData.street1,
          // street2: this.props.formData.street2,
          // zip: this.props.formData.zip,
          // state: this.props.formData.state,
          // city: this.props.formData.city,
          confirmPassword: this.props.formData.confirmPassword,
        },
        addr: {
          street1: this.props.add.street1,
          street2: this.props.add.street2,
          zip: this.props.add.zip,
          state: this.props.add.state,
          city: this.props.add.city,
          country: this.props.add.country,
          latitude: this.props.add.latitude,
          longitude: this.props.add.latitude,
          formatted_address: this.props.add.formatted_address,
        },
      });
    }
  };

  componentWillUnmount = () => {
    document.body.classList.remove("pt-0");
  };

  schema = {
    companyName: Joi.string()
      .required()
      .label("Company Name")
      .error(() => {
        return { message: companyNameRequired };
      }),
    confirmPassword: Joi.string()
      .required()
      .label("Confirm Password")
      .valid(Joi.ref("password"))
      .options({
        language: {
          any: {
            allowOnly: "!!" + confirmPasswordMatch,
            empty: "!!" + confirmPasswordRequired,
          },
        },
      }),

    password: Joi.string()
      .min(8)
      .max(25)
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "password"
      )
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.regex.name":
              err.message = passwordInvalid;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;

            case "string.max":
              err.message = passwordInvalidMax;
              break;

            default:
          }
        });
        return errors;
      }),
    firstName: Joi.string()
      .required()
      .label("First Name")
      .error(() => {
        return { message: firstNameRequired };
      }),

    lastName: Joi.string()
      .required()
      .label("Last Name")
      .error(() => {
        return { message: lastNameRequired };
      }),

    email: Joi.string()
      .email()
      .max(50)
      .required()
      .label("Email")
      .regex(/\S+@\S+\.\S+/, "email")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.required":
              err.message = emailRequired;
              break;

            case "string.regex.name":
              err.message = emailInvalid;
              break;

            case "any.empty":
              err.message = emailRequired;
              break;

            case "string.email":
              err.message = emailInvalid;
              break;

            case "string.min":
              err.message = emailInvalid;
              break;

            default:
          }
        });
        return errors;
      }),

    mobile: Joi.number()
      .required()
      .label("Mobile Number")
      .min(1000000000)
      .max(999999999999999)
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "number.base":
              err.message = mobileRequired;
              break;

            case "number.max":
              err.message = mobileInvalid;
              break;

            case "number.min":
              err.message = mobileInvalid;
              break;

            default:
          }
        });
        return errors;
      }),

    // street1: Joi.string()
    //   .required()
    //   .label("Address 1")
    //   .error(() => {
    //     return { message: Address1Required };
    //   }),

    // street2: Joi.string().label("Address 2").allow("").optional(),

    // city: Joi.string()
    //   .required()
    //   .label("City")
    //   .error(() => {
    //     return { message: cityRequired };
    //   }),

    // state: Joi.string()
    //   .required()
    //   .label("State")
    //   .error(() => {
    //     return { message: stateRequired };
    //   }),

    // zip: Joi.number()
    //   .required()
    //   .label("Zip Code")
    //   .error(() => {
    //     return { message: zipRequired };
    //   }),
  };

  doSubmit = () => {
    if (!this.state.loader) {
      this.setState({
        labelRegister: "",
        loader: true,
        btnClass: "btn btn-default disabled",
      });

      // const addressParams = {
      //   street1: this.state.addr.street1,
      //   street2: this.state.addr.street2,
      //   zip: this.state.addr.zip,
      // };
      // this.props.addressValidation(addressParams, this.addressCallback);
      const mobileParams = {
        mobile: this.state.data.mobile,
        type: 1,
      };
      this.props.mobileAvailability(mobileParams, this.callbackMobile);
    }
  };

  addressCallback = (res) => {
    if (res.status === 200) {
      const mobileParams = {
        mobile: this.state.data.mobile,
        type: 1,
      };
      this.props.mobileAvailability(mobileParams, this.callbackMobile);
    } else {
      this.setState({
        loader: false,
        labelRegister: "Register",
        btnClass: "btn btn-default",
      });
      toast(
        <AlertError message="Invalid address. Please enter your correct address." />
      );
    }
  };

  callbackMobile = (res) => {
    if (res.status === 200) {
      const emailParams = {
        email: this.state.data.email,
      };
      this.props.emailAvailability(emailParams, this.callbackEmail);
    } else {
      this.setState({
        loader: false,
        labelRegister: "Register",
        btnClass: "btn btn-default",
      });
      toast(<AlertError message={res.data.error} />);
    }
  };

  otpMobileCallBack = (res) => {
    if (res.status === 200) {
      if (res.data.otp) {
        toast(<AlertSuccess message={"Mobile OTP:" + res.data.otp} />);
      } else {
        toast(
          <AlertSuccess
            message={
              "We have sent OTPs to your registered mobile number and email address"
            }
          />
        );
      }
      this.props.updateStep(2);
      this.props.updateFormData(this.state.data, this.state.addr);
      this.props.updateVerifyMobile({
        otp: res.data.otp,
        mobile: this.state.data.mobile,
        type: 1,
        code: "+1",
      });
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  callbackEmail = (res) => {
    if (res.status === 200) {
      const params = { mobile: this.state.data.mobile };
      this.props.sendMobileOtp(params, this.otpMobileCallBack);

      // const emailParams = { email: this.state.data.email };
      // this.props.sendEmailOtp(emailParams, this.otpEmailCallBack);
    } else {
      this.setState({
        loader: false,
        labelRegister: "Register",
        btnClass: "btn btn-default",
      });
      toast(<AlertError message="Email Id already exist." />);
    }
  };

  // otpEmailCallBack = (res) => {
  //   if (res.status === 200) {
  //     if (res.data.otp) {
  //       toast(<AlertSuccess message={"Email OTP:" + res.data.otp} />);
  //     }
  //     this.props.updateVerifyEmail({
  //       otp: res.data.otp,
  //       email: this.state.data.email,
  //     });
  //   } else {
  //     toast(<AlertError message={res.data.error} />);
  //   }
  // };

  zipCallback = (res) => {
    if (res.status === 200) {
      this.setState({
        labelFetch: "Fetch City & State",
        zipLoader: false,
        btnClass: "btn btn-default",
      });
      const { data, errors } = this.state;
      data.city = res.data.data.city;
      data.state = res.data.data.state;
      errors.city = "";
      errors.state = "";
      this.setState({ data, errors });
    } else {
      this.setState({
        labelFetch: "Fetch City & State",
        zipLoader: false,
        btnClass: "btn btn-default",
      });

      toast(<AlertError message={res.data.error.Description} />);
    }
  };

  doSubmitZip = () => {
    if (!this.state.zipLoader) {
      this.setState({
        labelFetch: "",
        zipLoader: true,
        btnClass: "btn btn-default disabled",
      });
    }
    const params = {
      zip: this.state.data.zip,
    };
    this.props.zipValidation(params, this.zipCallback);
  };

  render() {
    return (
      <>
        <Sidebar />
        <div className="member-right">
          <div className="signin-form-container">
            <div className="signin-form">
              <h2>Seller Registration</h2>

              <div className="already-login">
                Already have an account?&nbsp;
                <Link to="/seller/login" className="sep-login">
                  Login
                </Link>
              </div>

              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  {this.renderInput("companyName", "Company Name")}
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      {this.renderInput("firstName", "First Name")}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      {this.renderInput("lastName", "Last Name")}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  {this.renderInput("email", "Email")}
                </div>
                <div className="form-group">
                  {this.renderInput("mobile", "Mobile Number")}
                </div>

                <div className="form-group">
                  {/* {this.renderInput("street1", "Address 1")} */}
                  <PlacesAutocomplete
                    value={this.state.formatted_address}
                    onChange={this.handleChange1}
                    onSelect={this.handleSelect}
                    searchOptions={searchOptions}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <div className="form-group not-empty">
                          <label>Address</label>
                          <input
                            onChange={this.handleChange1}
                            value={this.state.formatted_address}
                            type="text"
                            class="form-control"
                            aria-label=""
                            aria-describedby="basic-addon1"
                            {...getInputProps({
                              placeholder: "Enter your address",
                              className: "form-control",
                            })}
                          />
                        </div>
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>

                {/* <div className="form-group">
                  {this.renderAddress2("street2", "Address 2")}
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      {this.renderInput("zip", "Zip Code")}
                    </div>
                  </div>

                  <div className="save-change-btn col-md-6 mt-4">
                    <FetchBtn
                      disabled={
                        this.state.data.zip.length < 5 ||
                        this.state.data.zip.length > 5
                          ? "true"
                          : ""
                      }
                      onClickHandle={this.handleSubmitZip}
                      loading={this.state.zipLoader}
                      label={this.state.labelFetch}
                      btnClass="btn fetch-btn"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      {this.renderInputCityState("city", "City")}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      {this.renderInputCityState("state", "State")}
                    </div>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      {this.renderPasswordInput("password", "Password")}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      {this.renderPasswordInput(
                        "confirmPassword",
                        "Confirm Password"
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group agree">
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="agree">
                      By clicking register button, I agree to{" "}
                      <a
                        className="termsConditions"
                        href="https://effisim.info/terms-conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        terms &amp; conditions{" "}
                      </a>{" "}
                      and&nbsp;
                      <a
                        className="termsConditions"
                        href="https://effisim.info/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        privacy policy
                      </a>
                    </label>
                  </div>
                </div>

                <div className="form-group form-button">
                  <SubmitBtn
                    label={this.state.labelRegister}
                    loading={this.state.loader}
                    btnClass={this.state.btnClass}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  mobileAvailability: (param, callback) =>
    dispatch(mobileAvailability(param, callback)),
  sendMobileOtp: (param, callback) => dispatch(sendMobileOtp(param, callback)),

  emailAvailability: (param, callback) =>
    dispatch(emailAvailability(param, callback)),
  sendEmailOtp: (param, callback) => dispatch(sendEmailOtp(param, callback)),

  zipValidation: (data, callback) => dispatch(zipValidation(data, callback)),

  addressValidation: (data, callback) =>
    dispatch(addressValidation(data, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(SignUpForm));
