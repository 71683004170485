import React from "react";
import Form from "../common/form/form";
import Sidebar from "../common/sideBar";
import SubmitBtn from "../common/form/submitBtn";
import { connect } from "react-redux";

import { toast } from "react-toastify";
import Joi from "joi-browser";
import AlertError from "../common/alertError";
import { withRouter } from "react-router-dom";

import { resetPasswordMobile } from "../../store/seller";

import {
  passwordInvalid,
  passwordRequired,
  passwordInvalidMax,
} from "../common/misc";

class ResetPassword extends Form {
  state = {
    btnClass: "btn btn-lg btn-primary btn-block",
    loading: false,
    data: { password: "" },
    errors: { password: "" },
  };
  componentWillUnmount = () => {
    document.body.classList.remove("pt-0");
  };

  componentDidMount = () => {
    document.body.classList.add("pt-0");
  };

  schema = {
    confirmPassword: Joi.string()
      .required()
      .label("Confirm Password")
      .valid(Joi.ref("password"))
      .options({
        language: {
          any: {
            allowOnly: "!!Confirm Password and Password fields must be same",
            empty: "field is required",
          },
        },
      }),
    password: Joi.string()
      .min(8)
      .max(25)
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "password"
      )
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.regex.name":
              err.message = passwordInvalid;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;

            case "string.max":
              err.message = passwordInvalidMax;
              break;

            default:
          }
        });
        return errors;
      }),
  };

  doSubmit = () => {
    if (!this.state.loading) {
      this.setState({
        btnClass: "btn btn-lg btn-primary btn-block disabled",
        loading: false,
      });

      const param = {
        mobile: this.props.verifyMobile.mobile,
        verification_id: this.props.verifyMobile.verification_id,
        password: this.state.data.password,
      };
      this.props.resetPasswordMobile(param, this.callback);
    }
  };

  callback = (res) => {
    this.setState({
      btnClass: "btn btn-lg btn-primary btn-block",
      loading: false,
    });
    if (res.status === 200) {
      this.props.updateStep(6);
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  render() {
    return (
      <div className="member-container">
        <Sidebar />
        <div className="member-right">
          <div className="signin-form-container">
            <div className="signin-form login-form">
              <h2>Reset password</h2>

              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  {this.renderPasswordInput("password", "Password")}
                </div>
                <div className="form-group">
                  {this.renderPasswordInput(
                    "confirmPassword",
                    "Confirm Password"
                  )}
                </div>
                <div className="form-group form-button">
                  <SubmitBtn
                    label={this.state.loader === true ? "" : "Reset"}
                    loading={this.state.loader}
                    btnClass="btn btn-default"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetPasswordMobile: (param, callback) =>
    dispatch(resetPasswordMobile(param, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(ResetPassword));
