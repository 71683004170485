import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "contact",
  initialState: {
    add: {},
    loading: true,
    lastFetch: null,
  },
  reducers: {
    contactAdded: (contact, action) => {
      contact.add = action.payload;
      contact.loading = false;
      contact.lastFetch = Date.now();
    },

    contactAddRequestFailed: (contact, action) => {
      contact.loading = false;
    },
  },
});

export const { contactAdded, contactAddRequestFailed } = slice.actions;
export default slice.reducer;

// Action Creators
const url = "contactUs/";

export const addContact = (param, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url,
      method: "post",
      data: param,
      onSuccess: contactAdded.type,
      onError: contactAddRequestFailed.type,
    })
  );
};

export const getContact = createSelector(
  (state) => state.entities.contact,
  (contact) => contact
);
