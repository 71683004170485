import React, { Component } from "react";
import { Link } from "react-router-dom";
import downArr from "./../../include/images/down-arrow-icon.svg";
import delIcon from "./../../include/images/delete1.svg";
import closeIcon from "./../../include/images/close.svg";
import delivery from "./../../include/images/delivery.svg";
import pickup from "./../../include/images/pickup.svg";
import Truncate from "react-truncate";
import packageIcon from "../../include/images/package-icon.svg";

import _ from "lodash";
class CartProduct extends Component {
  state = {};
  render() {
    const { ep, es } = this.props;
    console.log("es", es);
    return (
      <tr>
        <td className="td-image text-md-center">
          <span>
            <img src={process.env.REACT_APP_S3URL + ep.image[0].small} alt="" />
          </span>
        </td>
        <td>
          <div className="d-flex flex-column">
            <b>
              {" "}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={"/product-detail/" + ep.product_id}
              >
                <Truncate lines={1} width={300}>
                  {ep.product_name}
                </Truncate>
              </Link>
            </b>
          </div>

          <div
            className={
              this.props.selectedProductId === ep.product_id
                ? "table-dropdown btn-group dropdown show"
                : "table-dropdown btn-group dropdown"
            }
          >
            <button
              className="dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={(e) => {
                ((ep.isPickupAvailable && ep.isDeliveryAvailable) ||
                  (ep.isDeliveryAvailable && ep.isShippingAvailable) ||
                  (ep.isShippingAvailable && ep.isPickupAvailable) ||
                  (ep.isPickupAvailable &&
                    ep.isDeliveryAvailable &&
                    es.isShippingAvailable)) &&
                  this.props.setSelectedProductId(e, ep.product_id);
              }}
            >
              {_.capitalize(ep.delivery_type)}
              <span className="caret" style={{ marginLeft: "3px" }}>
                {((ep.isPickupAvailable && ep.isDeliveryAvailable) ||
                  (ep.isDeliveryAvailable && ep.isShippingAvailable) ||
                  (ep.isShippingAvailable && ep.isPickupAvailable) ||
                  (ep.isPickupAvailable &&
                    ep.isDeliveryAvailable &&
                    ep.isShippingAvailable)) && <img src={downArr} alt="" />}
              </span>
            </button>
            <div
              className={
                this.props.selectedProductId === ep.product_id
                  ? "dropdown-menu deliveryOptions show"
                  : "dropdown-menu deliveryOptions"
              }
            >
              <div className="drop-content-box">
                <div className="drop-content-header d-flex align-items-center justify-content-between">
                  <h4>Delivery option</h4>
                  <div
                    className="close-icon"
                    onClick={(e) => this.props.setSelectedProductId(e, "")}
                  >
                    <img src={closeIcon} alt="" />
                  </div>
                </div>
                <ul className="drop-menu">
                  {ep.isPickupAvailable && (
                    <li className="drop-item">
                      <a
                        href="javascript:void(0)"
                        className="drop-link"
                        onClick={(e) =>
                          this.props.updateDeliveryForProduct(
                            e,
                            ep.product_id,
                            "pickup"
                          )
                        }
                      >
                        <img style={{ marginRight: "10px" }} src={pickup} />
                        &nbsp;Pickup
                      </a>
                    </li>
                  )}
                  {ep.isDeliveryAvailable && (
                    <li className="drop-item">
                      <a
                        href="javascript:void(0)"
                        className="drop-link"
                        onClick={(e) =>
                          this.props.updateDeliveryForProduct(
                            e,
                            ep.product_id,
                            "delivery"
                          )
                        }
                      >
                        <img style={{ marginRight: "10px" }} src={delivery} />
                        &nbsp;Delivery
                      </a>
                    </li>
                  )}
                  {ep.isShippingAvailable && (
                    <li className="drop-item">
                      <a
                        href="javascript:void(0)"
                        className="drop-link"
                        onClick={(e) =>
                          this.props.updateDeliveryForProduct(
                            e,
                            ep.product_id,
                            "shipping"
                          )
                        }
                      >
                        <img
                          style={{ marginRight: "10px" }}
                          src={packageIcon}
                        />
                        &nbsp;Shipping
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          {ep.delivery_type === "delivery" && (
            <div
              className={
                this.props.selectedDeliveryProductId === ep.product_id
                  ? "table-dropdown btn-group dropdown show"
                  : "table-dropdown btn-group dropdown"
              }
            >
              <button
                className="dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={(e) =>
                  this.props.setSelectedDeliveryProductId(e, ep.product_id)
                }
              >
                {_.capitalize(
                  _.replace(ep.delivery_option, RegExp("_", "g"), " ")
                )}
                <span className="caret" style={{ marginLeft: "3px" }}>
                  <img src={downArr} alt="" />
                </span>
              </button>
              <div
                className={
                  this.props.selectedDeliveryProductId === ep.product_id
                    ? "dropdown-menu deliveryOptions show"
                    : "dropdown-menu deliveryOptions"
                }
              >
                <div className="drop-content-box">
                  <div className="drop-content-header d-flex align-items-center justify-content-between">
                    <h4>Delivery Preference</h4>
                    <div
                      className="close-icon"
                      onClick={(e) =>
                        this.props.setSelectedDeliveryProductId(e, "")
                      }
                    >
                      <img src={closeIcon} alt="" />
                    </div>
                  </div>
                  <ul className="drop-menu">
                    <li className="drop-item">
                      <a
                        href="javascript:void(0)"
                        className="drop-link"
                        onClick={(e) =>
                          this.props.updateDeliveryForProduct(
                            e,
                            ep.product_id,
                            "delivery",
                            "in_person"
                          )
                        }
                      >
                        <img src={pickup} />
                        &nbsp; In Person
                      </a>
                    </li>
                    <li className="drop-item">
                      <a
                        href="javascript:void(0)"
                        className="drop-link"
                        onClick={(e) =>
                          this.props.updateDeliveryForProduct(
                            e,
                            ep.product_id,
                            "delivery",
                            "leave_at_the_door"
                          )
                        }
                      >
                        <img src={delivery} />
                        &nbsp; Leave at the door
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </td>
        <td style={{ textAlign: "right" }}>
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="data-button"
              onClick={(e) =>
                this.props.updateProductQuantity(
                  e,
                  ep.product_id,
                  ep.quantity - 1
                )
              }
              data-decrease
            >
              -
            </button>
            <input
              className="number-input"
              data-value
              type="text"
              value={ep.quantity}
              disabled
            />
            <button
              onClick={(e) =>
                this.props.updateProductQuantity(
                  e,
                  ep.product_id,
                  ep.quantity + 1
                )
              }
              className="data-button"
              data-increase
            >
              +
            </button>
          </div>
        </td>
        <td style={{ textAlign: "right" }}>
          <b>
            ${ep.price.toLocaleString(undefined, { minimumFractionDigits: 2 })}
          </b>
        </td>
        <td className="editable">
          <a
            onClick={(e) =>
              this.props.updateProductQuantity(e, ep.product_id, 0)
            }
            href="javascript:void(0)"
            className="delete"
          >
            <img src={delIcon} alt="delete" />
          </a>
        </td>
      </tr>
    );
  }
}

export default CartProduct;
