import React, { Component } from "react";
import WhitePlus from "../../include/images/white-plus-icon.svg";
import { toast } from "react-toastify";
import AlertSuccess from "../common/alertSuccess";
import AlertError from "../common/alertError";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { addToCart, loadCart } from "../../store/cart";

class AddBtnNew extends Component {
  state = {
    user_device_id: localStorage.getItem("user_device_id"),
    loader: false,
    btnOpen: false,
    param: JSON.parse(localStorage.getItem("customerDddress")),
  };

  toggleBtn = () => {
    this.setState({ btnOpen: !this.state.btnOpen });
  };
  addToCart = (e, delivery_type) => {
    //this.ref.current.continuousStart();
    this.setState({ loader: true });
    e.preventDefault();

    if (window.location.pathname === "/customer/wishlist") {
      const payload = {
        user_device_id: localStorage.getItem("user_device_id"),
        product_id: this.props.wishlistProductId,
        quantity: 1,
        delivery_type,
        address: this.state.param && this.state.param.address,
        zipCode: this.state.param && this.state.param.postal_code.long_name,
        lat: this.state.param && this.state.param.lat,
        lon: this.state.param && this.state.param.lng,
      };
      this.props.addToCart(payload, this.callBack);
    } else {
      const payload = {
        user_device_id: localStorage.getItem("user_device_id"),
        product_id: this.props.data._id,
        quantity: 1,
        delivery_type,
        address: this.state.param && this.state.param.address,
        zipCode: this.state.param && this.state.param.postal_code.long_name,
        lat: this.state.param && this.state.param.lat,
        lon: this.state.param && this.state.param.lng,
      };
      this.props.addToCart(payload, this.callBack);
    }
  };

  callBack = (res) => {
    this.props.setProductId("");
    this.setState({ loader: false });
    if (res.status === 200) {
      localStorage.setItem("user_device_id", res.data.user_device_id);
      this.setState({ user_device_id: res.data.user_device_id }, () =>
        this.props.loadCart(res.data.user_device_id, {
          address: this.state.param && this.state.param.address,
          zipCode: this.state.param && this.state.param.postal_code.long_name,
          lat: this.state.param && this.state.param.lat,
          lon: this.state.param && this.state.param.lng,
        })
      );
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  render() {
    const { data } = this.props;
    return this.props.new ? (
      <div
        class={
          this.state.btnOpen
            ? "add-button-outer  text-white d-inline-block open"
            : "add-button-outer  text-white d-inline-block"
        }
        onClick={() => {
          this.toggleBtn();
        }}
      >
        <div
          class="add-button-box d-flex align-items-center justify-content-center"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.toggleBtn();
            this.props.setProductId(this.props.wishlistProductId);
          }}
        >
          <img src={WhitePlus} alt="" />
          Add
        </div>
        <div class="button-hover-content">
          <ul class="bhc-list d-flex align-items-center">
            {data.isPickupAvailable && (
              <li
                class="bhc-item w-auto flex-fill"
                onClick={(e) => {
                  this.addToCart(e, "pickup");
                }}
              >
                <a class="bhc-link w-100 text-center" href="javascript:void(0)">
                  Pickup
                </a>
              </li>
            )}
            {data.isDeliveryAvailable && (
              <li
                class="bhc-item w-auto flex-fill"
                onClick={(e) => {
                  this.addToCart(e, "delivery");
                }}
              >
                <a class="bhc-link w-100 text-center" href="javascript:void(0)">
                  Delivery
                </a>
              </li>
            )}
            {data.isShippingAvailable && (
              <li
                class="bhc-item w-auto flex-fill"
                onClick={(e) => {
                  this.addToCart(e, "shipping");
                }}
              >
                <a class="bhc-link w-100 text-center" href="javascript:void(0)">
                  Shipping
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    ) : (
      <div
        class={
          this.state.btnOpen
            ? "add-button-outer  text-white d-inline-block open"
            : "add-button-outer  text-white d-inline-block"
        }
        onClick={() => {
          this.toggleBtn();
        }}
      >
        <div
          class="add-button-box d-flex align-items-center justify-content-center"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (!this.props.filters.mode) {
              this.toggleBtn();
            } else if (this.props.filters.mode === "pickup") {
              this.addToCart(e, "pickup");
            } else if (this.props.filters.mode === "delivery") {
              this.addToCart(e, "delivery");
            } else if (this.props.filters.mode === "shipping") {
              this.addToCart(e, "shipping");
            }
            this.props.setProductId(this.props.wishlistProductId);
          }}
        >
          <img src={WhitePlus} alt="" />
          Add
        </div>
        <div class="button-hover-content">
          <ul class="bhc-list d-flex align-items-center">
            {data.isPickupAvailable && (
              <li
                class="bhc-item w-auto flex-fill"
                onClick={(e) => {
                  this.addToCart(e, "pickup");
                }}
              >
                <a class="bhc-link w-100 text-center" href="javascript:void(0)">
                  Pickup
                </a>
              </li>
            )}
            {data.isDeliveryAvailable && (
              <li
                class="bhc-item w-auto flex-fill"
                onClick={(e) => {
                  this.addToCart(e, "delivery");
                }}
              >
                <a class="bhc-link w-100 text-center" href="javascript:void(0)">
                  Delivery
                </a>
              </li>
            )}
            {data.isShippingAvailable && (
              <li
                class="bhc-item w-auto flex-fill"
                onClick={(e) => {
                  this.addToCart(e, "shipping");
                }}
              >
                <a class="bhc-link w-100 text-center" href="javascript:void(0)">
                  Shipping
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addToCart: (data, callback) => dispatch(addToCart(data, callback)),
  loadCart: (id, data) => dispatch(loadCart(id, data)),
});

export default withRouter(connect(null, mapDispatchToProps)(AddBtnNew));
