import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import breadcrumbSVG from "../../include/images/breadcrumbSVG.svg";
class Breadcrumb extends Component {
  render() {
    return (
      <div className="breadcrumb-container" style={{ marginTop: "47px" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="breadcrumbs">
                <li>
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      //window.close();
                      this.props.history.push({
                        pathname: "/products-listing",
                        state: this.props.states,
                      });
                    }}
                  >
                    <img src={breadcrumbSVG} alt="" />
                    Back to results
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Breadcrumb);
