export const mobileRequired = "Mobile Number field is required";
export const companyNameRequired = "Company Name field is required";
export const confirmPasswordRequired = "Confirm Password field is required";
export const confirmPasswordMatch =
  "Confirm Password and Password fields must be same";

export const mobileInvalid = "Mobile Number is invalid";
export const emailInvalid = "Email is invalid";
export const loginRequired = "Email ID / Mobile Number field is required";

export const emailUpdated = "Your email has been updated successfully";
export const mobileUpdated = "Your mobile has been updated successfully";

export const firstNameRequired = "First Name field is required";
export const lastNameRequired = "Last Name field is required";
export const nameRequired = "Name field is required";

export const emailRequired = "Email field is required";
export const codeRequired = "Code field is required";
export const oldPassworddRequired = "Old Password field is required";

export const passwordRequired = "Password field is required";
export const passwordInvalid = "Password is invalid";

export const passwordInvalidMax =
  "Password length cannot be more than 25 characters long.";

export const loginFailed = "Login failed!";
export const accountNotExist = "Sorry, this account does not exist.";

export const emailExist =
  "A user with this email already exists. Please use another email";
export const mobileExist =
  "A user with this mobile number already exists. Please use another mobile number.";

export const Address1Required = "Address 1 field is required";
export const invalidAddress =
  "Invalid address. Please enter your correct address.";
export const cityRequired = "City field is required";
export const stateRequired = "State field is required";

export const picsRequired = "Image field is required";
export const zipRequired = "Zip Code field is required";

//Product
export const message = "Message field is required";
export const informationSaved = "Information saved";
export const productDeleted = "Product has been deleted successfully";
export const failed = "Something went wrong.";
export const productName = "Product Name field is required";
export const description = "Description field is required";
export const quantity = "Quantity field is required";
export const price = "Price field is required";
export const tax = "Sales Tax field is required";

//Coupon
export const couponCode = "Coupon Code field is required";
export const startDate = "Start Date field is required";
export const endDate = "End Date field is required";
export const minAmount = "Minimum Amount field is required";
export const maxDiscount = "Maximum Discount field is required";
export const maxRedeem = "Maximum Quantities field is required";
export const discountAmount = "Discount Amount field is required";
export const couponDeleted = "Coupon has been deleted successfully";
