import React from "react";
import Form from "../common/form/form";
import SubmitBtn from "../common/form/submitBtn";
// import Footer from "../common/footer";
import TopBar from "../common/topBar";
import NumberFormat from "react-number-format";

import { informationSaved, failed } from "../common/misc";
import { getSeller, loadSellerProfile, sellerUpdate } from "../../store/seller";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import { getProduct, loadCategory } from "../../store/product";

const initialState = {
  settingDropdown: false,
  profileDropdown: false,
  data: [],
};

class Category extends Form {
  ref = React.createRef(null);

  state = initialState;

  componentDidMount() {
    this.props.loadCategory();
    this.ref.current.continuousStart();
  }

  doSubmit = (e) => {
    this.ref.current.continuousStart();

    e.preventDefault();
    const { data } = this.state;
    const formData = {
      category: data,
    };
    this.props.sellerUpdate(formData, this.callback);
  };
  callback = (res) => {
    this.ref.current.complete();

    this.props.loadSellerProfile();
    if (res && res.status === 200) {
      toast(<AlertSuccess message={informationSaved} />);
    } else {
      toast(<AlertError message={failed} />);
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.product.categoryLoading &&
      !this.props.profileLoading &&
      (this.props.profileLoading !== prevProps.profileLoading ||
        this.props.product.categoryLoading !==
          prevProps.product.categoryLoading)
    ) {
      this.ref.current.complete();
      const txtData = JSON.stringify(this.props.product.category);
      const jsonData = JSON.parse(txtData);
      this.props.sellerProfile.category.forEach((element, i) => {
        const selectedData = jsonData.find((ef) => ef._id === element._id);
        if (selectedData) {
          const indexOfData = jsonData.indexOf(selectedData);
          jsonData[indexOfData].value = element.value;
        }
      });
      this.setState({ data: jsonData });
    }
  };

  changeData = (e) => {
    const { data } = this.state;
    const selected = data.find((ed) => ed._id === e.target.name);
    const indexOf = data.indexOf(selected);
    const str = JSON.stringify(selected);
    const dataStr = JSON.stringify(data);
    const newData = JSON.parse(dataStr);
    const testObj = JSON.parse(str);
    testObj.value = e.target.value;
    newData[indexOf] = testObj;
    this.setState({ data: newData });
  };

  handleSettingDropdown = () => {
    if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
      this.setState({ settingDropdown: !this.state.settingDropdown });
    } else {
      this.setState({ settingDropdown: !this.state.settingDropdown });
    }
  };

  handleProfileDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
      this.setState({ profileDropdown: !this.state.profileDropdown });
    } else {
      this.setState({ profileDropdown: !this.state.profileDropdown });
    }
  };

  setSettingDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
    } else if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
    }
  };

  render() {
    return (
      <>
        <div
          onClick={this.setSettingDropdown}
          className="dashboard-container custom-top"
        >
          <TopBar
            handleProfileDropdown={this.handleProfileDropdown}
            handleSettingDropdown={this.handleSettingDropdown}
            settingDropdown={this.state.settingDropdown}
            profileDropdown={this.state.profileDropdown}
            loaDerRef={this.ref}
            sellerProfile={this.props.sellerProfile}
          />{" "}
          <main id="main">
            <div className="form-container">
              <div className="container">
                <div className="row">
                  <div className="col-md-5">
                    <form onSubmit={this.doSubmit}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Category</th>
                            <th scope="col">Tax %</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data.map((e, i) => (
                            <tr>
                              <th scope="row">{i + 1}</th>
                              <td>{e.title}</td>
                              <td>
                                <NumberFormat
                                  // thousandsGroupStyle="thousand"
                                  value={e.value ? e.value : 0.0}
                                  prefix=""
                                  decimalSeparator="."
                                  displayType="input"
                                  type="text"
                                  // thousandSeparator={false}
                                  allowNegative={false}
                                  suffix=""
                                  decimalScale={2}
                                  name={e._id}
                                  className="form-control"
                                  onChange={(e) => {
                                    this.changeData(e);
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="form-group"></div>
                      <div className="form-group"></div>
                      <div className="form-group"></div>
                      <div className="form-group">
                        <div className="edit-info mt-4">
                          <SubmitBtn
                            label="Update"
                            loading={this.state.loading}
                            btnClass="btn btn-default"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <Footer /> */}
          </main>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  sellerProfile: getSeller(state).profile,
  profileLoading: getSeller(state).profileLoading,
  product: getProduct(state),
});

const mapDispatchToProps = (dispatch) => ({
  sellerUpdate: (data, callback) => dispatch(sellerUpdate(data, callback)),
  loadSellerProfile: () => dispatch(loadSellerProfile()),
  loadCategory: () => dispatch(loadCategory()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Category);
