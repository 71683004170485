import React, { createRef } from "react";
import close from "../../include/images/close.svg";
import downArrow from "../../include/images/down-arrow-icon.svg";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { connect } from "react-redux";
import {
  updateProductStatus,
  loadOrders,
  addDisputeMessage,
  resolveDispute,
  getSeller,
} from "../../store/seller";

import { toast } from "react-toastify";
import AlertSuccess from "../common/alertSuccess";
import AlertError from "../common/alertError";

import Moment from "moment";
import Joi from "joi-browser";

import LoadingBar from "react-top-loading-bar";
import { Modal } from "react-bootstrap";
import closeBtn from "../../include/images/close.svg";

import Form from "../common/form/form";
import SubmitBtn from "../common/form/submitBtn";

class OrderDetails extends Form {
  ref = createRef(null);

  state = {
    popup: false,
    ids: [],
    isOpen: false,
    isReturnOpen: false,
    data: { response: "" },
    errors: {},
    currentId: null,
    product_status_id: "",
    productStatus: "",
    productId: "",
  };

  schema = {
    response: Joi.string()
      .required()
      .error(() => {
        return {
          message: "Required",
        };
      }),
  };

  // componentDidMount = () => {
  //   this.ref.current.continuousStart();
  // };

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.complete();
    }
  };

  handleProductStatus = (id, status, product_id) => {
    this.setState({
      product_status_id: id,
      productStatus: status,
      productId: product_id,
    });
    this.handlePopup();
  };

  confirmProductStatus = () => {
    const status = this.state.productStatus;
    const product_id = this.state.productId;
    const payload = {
      status,
      product_id,
    };
    // this.handlePopup();
    this.props.updateProductStatus(
      payload,
      this.state.product_status_id,
      this.callback
    );
  };

  callback = (res) => {
    if (res.status === 200) {
      this.props.loadOrders();
      this.handlePopup();
      this.props.loadOrderDetails(this.props.orderDetails._id);
      toast(<AlertSuccess message={"Product Status Updated. "} />);
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  doSubmit = () => {
    const id = this.state.currentId;
    const response = { notes: this.state.data.response };
    this.props.addDisputeMessage(response, id, this.disputeMessageCallback);
  };

  disputeMessageCallback = (res) => {
    if (res && res.status === 200) {
      this.props.setDisputeProductId({
        product_id: "",
      });
      this.setState({
        data: {
          response: "",
        },
      });
      this.props.loadOrders();
      this.props.loadOrderDetails(this.props.orderDetails._id);
      toast(<AlertSuccess message={"Your response added"} />);
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  handleMarkResolve = (id) => {
    this.props.resolveDispute(id, this.resolveDisputeCallback);
  };

  resolveDisputeCallback = (res) => {
    if (res && res.status === 200) {
      this.props.setDisputeProductId({
        product_id: "",
      });
      this.setState({
        data: {
          response: "",
        },
      });
      this.props.loadOrders();
      this.props.loadOrderDetails(this.props.orderDetails._id);
      toast(<AlertSuccess message={"Dispute Resolved"} />);
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  handlePopup = (e) => {
    if (e) e.preventDefault();
    this.setState({ popup: !this.state.popup });
  };

  render() {
    const { photoIndex, isOpen, isReturnOpen } = this.state;

    const { customer_information } = this.props.orderDetails;
    console.log("this.props.orderDetails.summary", this.props.orderDetails);
    return (
      <>
        <LoadingBar color="#0b4474" ref={this.ref} />
        <div
          className={
            this.props.isOrderDetails
              ? "tab-modify-slidebar tab-modify-slidebar2 view-order-modify-search-sidebar show"
              : "tab-modify-slidebar tab-modify-slidebar2 view-order-modify-search-sidebar"
          }
          id="viewOrder"
        >
          {!this.props.loading && (
            <div className="tab-modify-box">
              <div className="tab-header d-flex justify-content-between">
                <h3>
                  Order : #
                  {this.props.orderDetails && this.props.orderDetails.order_id}
                </h3>

                <a
                  onClick={this.props.closeDetails}
                  href="javascript:void(0)"
                  className="close"
                >
                  <img src={close} alt="" />
                </a>
              </div>
              <div className="tab-header d-flex justify-content-between">
                <h5 style={{ fontWeight: "400", fontSize: "16px" }}>
                  Payment Status:
                  <span
                    style={{
                      color:
                        this.props.orderDetails &&
                        this.props.orderDetails.status === "Pending"
                          ? this.props.orderDetails &&
                            this.props.orderDetails.pay_later
                            ? " red"
                            : "black"
                          : " black",
                    }}
                  >
                    {this.props.orderDetails &&
                    this.props.orderDetails.status === "Pending"
                      ? this.props.orderDetails &&
                        this.props.orderDetails.pay_later
                        ? " Unpaid"
                        : " Paid"
                      : " Paid"}
                  </span>
                </h5>
                <a
                  onClick={this.props.closeDetails}
                  href="javascript:void(0)"
                  className="close"
                ></a>
              </div>
              <div className={"table-responsive overflow-visible"}>
                <table className="table2 has-total-row">
                  <thead>
                    <tr>
                      <th>Products</th>
                      <th></th>
                      <th style={{ width: "100px" }}>Status</th>
                      <th style={{ width: "100px", textAlign: "right" }}>
                        Quantity
                      </th>
                      <th
                        className="custom-table-head"
                        style={{ width: "100px", textAlign: "right" }}
                      >
                        Subtotal
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.orderDetails &&
                      this.props.orderDetails.products &&
                      this.props.orderDetails.products.map((e, i) => (
                        <tr key={i}>
                          <td className="td-image text-center">
                            {e.image &&
                              e.image.map((img, k) => (
                                <span key={k}>
                                  <img
                                    src={
                                      img &&
                                      process.env.REACT_APP_S3URL + img.medium
                                    }
                                    alt=""
                                  />
                                </span>
                              ))}
                          </td>
                          <td>
                            <b>{e.product_name && e.product_name}</b>
                            <div>{e.delivery_type && e.delivery_type}</div>
                            <div>
                              {e.delivery_type && e.delivery_type === "Delivery"
                                ? e.delivery_option === "In_person"
                                  ? "In Person"
                                  : "Leave at the door"
                                : ""}
                            </div>
                            {e.dispute &&
                              (e.dispute.resolved_on === null ? (
                                <div
                                  className={
                                    this.props.disputeProductId === e.product_id
                                      ? "table-dropdown dispute-dropdown btn-group dropdown show"
                                      : "table-dropdown dispute-dropdown btn-group dropdown"
                                  }
                                >
                                  <button
                                    id={e.product_id}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      this.props.setDisputeProductId(
                                        e.product_id
                                      );
                                      this.setState({
                                        currentId: e.dispute._id,
                                      });
                                    }}
                                    className="disputed-text dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    Disputed
                                  </button>
                                  <div
                                    className={
                                      this.props.disputeProductId ===
                                      e.product_id
                                        ? "dropdown-menu show"
                                        : "dropdown-menu"
                                    }
                                  >
                                    <div className="drop-content-box">
                                      <div className="drop-content-header d-flex align-items-center justify-content-between">
                                        <h4>Disputed</h4>
                                        <div className="form-group-box ml-auto d-flex align-items-center">
                                          <button
                                            onClick={() => {
                                              this.handleMarkResolve(
                                                e.dispute._id
                                              );
                                            }}
                                            className="btn btn-default"
                                          >
                                            Mark as Resolved
                                          </button>
                                          <div
                                            onClick={() => {
                                              this.props.setDisputeProductId(
                                                ""
                                              );
                                            }}
                                            className="close-icon ml-4"
                                          >
                                            <img src={close} alt="" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group-box">
                                        <form onSubmit={this.handleSubmit}>
                                          <div className="form-group">
                                            {this.renderText(
                                              "response",
                                              "Response"
                                            )}
                                          </div>
                                          <div className="form-group">
                                            <SubmitBtn
                                              label="Submit"
                                              loading={this.state.loading}
                                              btnClass="btn btn-lg btn-default"
                                            />
                                          </div>
                                        </form>
                                        {e.dispute.messages &&
                                          e.dispute.messages.map((msg) => (
                                            <>
                                              <p>{msg.notes}</p>
                                              <ul className="tpi-list custom-img d-flex flex-wrap">
                                                {msg.images &&
                                                  msg.images.map((img) => (
                                                    <>
                                                      <li>
                                                        <img
                                                          src={
                                                            img &&
                                                            process.env
                                                              .REACT_APP_S3URL +
                                                              img.small
                                                          }
                                                          alt=""
                                                          onClick={() =>
                                                            this.setState({
                                                              isOpen: true,
                                                            })
                                                          }
                                                        />
                                                      </li>
                                                      {isOpen && (
                                                        <Lightbox
                                                          mainSrc={
                                                            img
                                                              ? process.env
                                                                  .REACT_APP_S3URL +
                                                                img.original
                                                              : ""
                                                          }
                                                          onCloseRequest={() =>
                                                            this.setState({
                                                              isOpen: false,
                                                            })
                                                          }
                                                          onMovePrevRequest={() =>
                                                            this.setState({
                                                              photoIndex:
                                                                photoIndex - 1,
                                                            })
                                                          }
                                                          onMoveNextRequest={() =>
                                                            this.setState({
                                                              photoIndex:
                                                                photoIndex + 1,
                                                            })
                                                          }
                                                        />
                                                      )}
                                                    </>
                                                  ))}
                                              </ul>
                                              <p>
                                                <span>
                                                  By{" "}
                                                  {msg.customer
                                                    ? msg.customer.first_name +
                                                      " " +
                                                      msg.customer.last_name
                                                    : msg.seller.first_name +
                                                      " " +
                                                      msg.seller.last_name}{" "}
                                                  on{" "}
                                                  {Moment(msg.date).format(
                                                    "LL"
                                                  )}{" "}
                                                </span>
                                              </p>
                                            </>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className={
                                    this.props.disputeProductId === e.product_id
                                      ? "table-dropdown dispute-dropdown btn-group dropdown show"
                                      : "table-dropdown dispute-dropdown btn-group dropdown"
                                  }
                                >
                                  <button
                                    id={e.product_id}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      this.props.setDisputeProductId(
                                        e.product_id
                                      );
                                    }}
                                    className="resolved-text green-imp dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    Resolved
                                  </button>
                                  <div
                                    className={
                                      this.props.disputeProductId ===
                                      e.product_id
                                        ? "dropdown-menu show"
                                        : "dropdown-menu"
                                    }
                                  >
                                    <div className="drop-content-box">
                                      <div className="drop-content-header d-flex align-items-center justify-content-between">
                                        <h4>Resolved</h4>
                                        <div className="form-group-box ml-auto d-flex align-items-center">
                                          <div
                                            onClick={() => {
                                              this.props.setDisputeProductId(
                                                ""
                                              );
                                            }}
                                            className="close-icon ml-4"
                                          >
                                            <img src={close} alt="" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group-box">
                                        {e.dispute.messages &&
                                          e.dispute.messages.map((msg) => (
                                            <>
                                              <p>{msg.notes}</p>
                                              <ul className="tpi-list custom-img d-flex flex-wrap">
                                                {msg.images &&
                                                  msg.images.map((img) => (
                                                    <>
                                                      <li>
                                                        <img
                                                          src={
                                                            img &&
                                                            process.env
                                                              .REACT_APP_S3URL +
                                                              img.small
                                                          }
                                                          onClick={() =>
                                                            this.setState({
                                                              isOpen: true,
                                                            })
                                                          }
                                                          alt=""
                                                        />
                                                      </li>
                                                      {isOpen && (
                                                        <Lightbox
                                                          mainSrc={
                                                            img
                                                              ? process.env
                                                                  .REACT_APP_S3URL +
                                                                img.original
                                                              : ""
                                                          }
                                                          onCloseRequest={() =>
                                                            this.setState({
                                                              isOpen: false,
                                                            })
                                                          }
                                                          onMovePrevRequest={() =>
                                                            this.setState({
                                                              photoIndex:
                                                                photoIndex - 1,
                                                            })
                                                          }
                                                          onMoveNextRequest={() =>
                                                            this.setState({
                                                              photoIndex:
                                                                photoIndex + 1,
                                                            })
                                                          }
                                                        />
                                                      )}
                                                    </>
                                                  ))}
                                              </ul>
                                              <p>
                                                <span>
                                                  By{" "}
                                                  {msg.customer
                                                    ? msg.customer.first_name +
                                                      " " +
                                                      msg.customer.last_name
                                                    : msg.seller.first_name +
                                                      " " +
                                                      msg.seller.last_name}{" "}
                                                  on{" "}
                                                  {Moment(msg.date).format(
                                                    "LL"
                                                  )}{" "}
                                                </span>
                                              </p>
                                            </>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            {e.returnRequest && (
                              <div
                                className={
                                  this.props.returnProductId === e.product_id
                                    ? "table-dropdown dispute-dropdown btn-group dropdown show"
                                    : "table-dropdown dispute-dropdown btn-group dropdown"
                                }
                              >
                                <button
                                  id={e.product_id}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    this.props.setReturnProductId(e.product_id);
                                  }}
                                  className="resolved-text green-imp dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  {e.returnRequest.status}
                                </button>
                                <div
                                  className={
                                    this.props.returnProductId === e.product_id
                                      ? "dropdown-menu show"
                                      : "dropdown-menu"
                                  }
                                >
                                  <div className="drop-content-box">
                                    <div className="drop-content-header d-flex align-items-center justify-content-between">
                                      <h4> {e.returnRequest.status}</h4>
                                      <div className="form-group-box ml-auto d-flex align-items-center">
                                        <div
                                          onClick={() => {
                                            this.props.setReturnProductId("");
                                          }}
                                          className="close-icon ml-4"
                                        >
                                          <img src={close} alt="" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group-box">
                                      {e.returnRequest.notes && (
                                        <>
                                          <p>{e.returnRequest.notes}</p>
                                          <ul className="tpi-list custom-img d-flex flex-wrap">
                                            {e.returnRequest.images &&
                                              e.returnRequest.images.map(
                                                (img) => (
                                                  <>
                                                    <li>
                                                      <img
                                                        src={
                                                          img &&
                                                          process.env
                                                            .REACT_APP_S3URL +
                                                            img.small
                                                        }
                                                        onClick={() =>
                                                          this.setState({
                                                            isReturnOpen: true,
                                                          })
                                                        }
                                                        alt=""
                                                      />
                                                    </li>
                                                    {isReturnOpen && (
                                                      <Lightbox
                                                        mainSrc={
                                                          img
                                                            ? process.env
                                                                .REACT_APP_S3URL +
                                                              img.original
                                                            : ""
                                                        }
                                                        onCloseRequest={() =>
                                                          this.setState({
                                                            isReturnOpen: false,
                                                          })
                                                        }
                                                        onMovePrevRequest={() =>
                                                          this.setState({
                                                            photoIndex:
                                                              photoIndex - 1,
                                                          })
                                                        }
                                                        onMoveNextRequest={() =>
                                                          this.setState({
                                                            photoIndex:
                                                              photoIndex + 1,
                                                          })
                                                        }
                                                      />
                                                    )}
                                                  </>
                                                )
                                              )}
                                          </ul>
                                          <p>
                                            <span>
                                              {Moment(
                                                e.returnRequest.date
                                              ).format("LL")}{" "}
                                            </span>
                                          </p>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </td>
                          <td>
                            {window.location.pathname === "/seller/sales" ? (
                              <p>{e.status && e.status}</p>
                            ) : (
                              <div
                                className={
                                  this.props.productId === e.product_id
                                    ? "table-dropdown btn-group dropdown show"
                                    : "table-dropdown btn-group dropdown"
                                }
                              >
                                <button
                                  id={e.product_id}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    this.props.setProductId(e.product_id);
                                  }}
                                  className="dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  {e.status && e.status}{" "}
                                  <span className="caret">
                                    <img src={downArrow} alt="" />
                                  </span>
                                </button>

                                <div
                                  className={
                                    this.props.productId === e.product_id
                                      ? "dropdown-menu show custom-styles"
                                      : "dropdown-menu"
                                  }
                                  aria-labelledby={e.product_id}
                                >
                                  <div className="drop-content-box">
                                    <ul className="drop-menu">
                                      {e.status && e.status === "Pending" ? (
                                        <>
                                          <li className="drop-item">
                                            <a
                                              onClick={() => {
                                                this.handleProductStatus(
                                                  this.props.orderDetails
                                                    .order_id,
                                                  "pending",
                                                  e.product_id
                                                );
                                              }}
                                              href="javascript:void(0)"
                                              className="drop-link"
                                            >
                                              Pending
                                            </a>
                                          </li>
                                          <li className="drop-item">
                                            {e.delivery_type &&
                                            e.delivery_type === "Pickup" ? (
                                              <a
                                                onClick={() => {
                                                  this.handleProductStatus(
                                                    this.props.orderDetails
                                                      .order_id,
                                                    "picked up",
                                                    e.product_id
                                                  );
                                                }}
                                                href="javascript:void(0)"
                                                className="drop-link"
                                              >
                                                Picked Up
                                              </a>
                                            ) : (
                                              <a
                                                onClick={() => {
                                                  this.handleProductStatus(
                                                    this.props.orderDetails
                                                      .order_id,
                                                    "delivered",
                                                    e.product_id
                                                  );
                                                }}
                                                href="javascript:void(0)"
                                                className="drop-link"
                                              >
                                                Delivered
                                              </a>
                                            )}
                                          </li>
                                          {e.delivery_type === "Shipping" && (
                                            <li className="drop-item">
                                              <a
                                                onClick={() => {
                                                  this.handleProductStatus(
                                                    this.props.orderDetails
                                                      .order_id,
                                                    "shipped",
                                                    e.product_id
                                                  );
                                                }}
                                                href="javascript:void(0)"
                                                className="drop-link"
                                              >
                                                Shipped
                                              </a>
                                            </li>
                                          )}

                                          <li className="drop-item">
                                            <a
                                              onClick={() => {
                                                this.handleProductStatus(
                                                  this.props.orderDetails
                                                    .order_id,
                                                  "cancelled and refunded",
                                                  e.product_id
                                                );
                                              }}
                                              href="javascript:void(0)"
                                              className="drop-link"
                                            >
                                              Cancelled and Refunded
                                            </a>
                                          </li>
                                        </>
                                      ) : (
                                        <>
                                          <li className="drop-item">
                                            {e.delivery_type &&
                                            e.delivery_type === "Pickup" ? (
                                              <a
                                                onClick={() => {
                                                  this.handleProductStatus(
                                                    this.props.orderDetails
                                                      .order_id,
                                                    "picked up",
                                                    e.product_id
                                                  );
                                                }}
                                                href="javascript:void(0)"
                                                className="drop-link"
                                              >
                                                Picked Up
                                              </a>
                                            ) : (
                                              <a
                                                onClick={() => {
                                                  this.handleProductStatus(
                                                    this.props.orderDetails
                                                      .order_id,
                                                    "delivered",
                                                    e.product_id
                                                  );
                                                }}
                                                href="javascript:void(0)"
                                                className="drop-link"
                                              >
                                                Delivered
                                              </a>
                                            )}
                                          </li>
                                          {e.delivery_type === "Shipping" && (
                                            <li className="drop-item">
                                              <a
                                                onClick={() => {
                                                  this.handleProductStatus(
                                                    this.props.orderDetails
                                                      .order_id,
                                                    "shipped",
                                                    e.product_id
                                                  );
                                                }}
                                                href="javascript:void(0)"
                                                className="drop-link"
                                              >
                                                Shipped
                                              </a>
                                            </li>
                                          )}

                                          <li className="drop-item">
                                            <a
                                              onClick={() => {
                                                this.handleProductStatus(
                                                  this.props.orderDetails
                                                    .order_id,
                                                  "cancelled and refunded",
                                                  e.product_id
                                                );
                                              }}
                                              href="javascript:void(0)"
                                              className="drop-link"
                                            >
                                              Cancelled and Refunded
                                            </a>
                                          </li>
                                          <li className="drop-item">
                                            <a
                                              onClick={() => {
                                                this.handleProductStatus(
                                                  this.props.orderDetails
                                                    .order_id,
                                                  "returned and refunded",
                                                  e.product_id
                                                );
                                              }}
                                              href="javascript:void(0)"
                                              className="drop-link"
                                            >
                                              Returned and Refunded
                                            </a>
                                          </li>
                                          <li className="drop-item">
                                            <a
                                              onClick={() => {
                                                this.handleProductStatus(
                                                  this.props.orderDetails
                                                    .order_id,
                                                  "only refunded",
                                                  e.product_id
                                                );
                                              }}
                                              href="javascript:void(0)"
                                              className="drop-link"
                                            >
                                              Only Refunded
                                            </a>
                                          </li>
                                        </>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            )}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {" "}
                            {e.quantity && e.quantity}
                          </td>
                          <td
                            className="tax-total"
                            style={{ textAlign: "right" }}
                          >
                            <b>
                              $
                              {e.subtotal &&
                                e.subtotal.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                })}
                            </b>
                            <div className="sub-code">
                              Tax : $
                              {e.tax_total &&
                                e.tax_total.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                })}
                            </div>
                          </td>
                        </tr>
                      ))}
                    <tr>
                      <td colSpan="3">
                        <b>Subtotal</b>
                      </td>
                      <td colSpan="2" style={{ textAlign: "right" }}>
                        <div>
                          {this.props.orderDetails &&
                            this.props.orderDetails.summary && (
                              <b>
                                $
                                {this.props.orderDetails.summary.subtotal.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                  }
                                )}
                              </b>
                            )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <b>Discount</b>
                      </td>
                      <td colSpan="2" style={{ textAlign: "right" }}>
                        <div>
                          <b>
                            -$
                            {this.props.orderDetails &&
                            this.props.orderDetails.summary &&
                            this.props.orderDetails.summary.discount_total
                              ? this.props.orderDetails.summary.discount_total.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                  }
                                )
                              : 0}
                          </b>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <b>Total</b>
                      </td>
                      <td colSpan="2" style={{ textAlign: "right" }}>
                        <div>
                          <b>
                            $
                            {this.props.orderDetails &&
                              this.props.orderDetails.summary &&
                              this.props.orderDetails.summary.final_total.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}
                          </b>
                          <div className="sub-code">
                            Tax : $
                            {this.props.orderDetails &&
                              this.props.orderDetails.summary &&
                              this.props.orderDetails.summary.tax_total.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}{" "}
                          </div>

                          {this.props.orderDetails &&
                            this.props.orderDetails.summary &&
                            this.props.orderDetails.summary.delivery_charge !==
                              0 && (
                              <div className="sub-code">
                                Delivery charge : $
                                {this.props.orderDetails &&
                                  this.props.orderDetails.summary &&
                                  this.props.orderDetails.summary.delivery_charge.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                    }
                                  )}
                              </div>
                            )}
                          {this.props.orderDetails &&
                            this.props.orderDetails.summary &&
                            this.props.orderDetails.summary.shipping_charge !==
                              0 && (
                              <div className="sub-code">
                                Shipping charge : $
                                {this.props.orderDetails &&
                                  this.props.orderDetails.summary &&
                                  this.props.orderDetails.summary
                                    .shipping_charge &&
                                  this.props.orderDetails.summary.shipping_charge.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                    }
                                  )}
                              </div>
                            )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="tab-query-box">
                {customer_information && (
                  <ul className="tab-query-list d-md-flex flex-md-wrap">
                    <li>
                      <div className="tql-box">
                        <h5>Customer Information</h5>
                        <h6>
                          {customer_information.first_name &&
                            customer_information.last_name &&
                            customer_information.first_name +
                              " " +
                              customer_information.last_name}
                        </h6>
                        {customer_information.email && (
                          <a href={`mailto:${customer_information.email}`}>
                            {customer_information.email}
                          </a>
                        )}
                        {customer_information.mobile && (
                          <a href={`tel:${customer_information.mobile}`}>
                            {customer_information.mobile}
                          </a>
                        )}
                      </div>
                    </li>

                    {customer_information.billing_address && (
                      <li>
                        <div className="tql-box">
                          <h5>Billing Address</h5>
                          <p>
                            {customer_information.billing_address.address_line1}
                            , &nbsp;
                            {
                              customer_information.billing_address.address_line2
                            }{" "}
                            <br />
                            {customer_information.billing_address.city}, &nbsp;
                            {customer_information.billing_address.state}
                            &nbsp;{" "}
                            {customer_information.billing_address.zipcode}
                          </p>
                        </div>
                      </li>
                    )}

                    {customer_information.shipping_address && (
                      <li>
                        <div className="tql-box">
                          <h5>Delivery Address</h5>
                          <p>
                            {
                              customer_information.shipping_address
                                .address_line1
                            }
                            , &nbsp;
                            {
                              customer_information.shipping_address
                                .address_line2
                            }{" "}
                            <br />
                            {customer_information.shipping_address.city}, &nbsp;
                            {customer_information.shipping_address.state}
                            &nbsp;{" "}
                            {customer_information.shipping_address.zipcode}
                          </p>
                        </div>
                      </li>
                    )}
                    {this.props.orderDetails.note && (
                      <li>
                        <div className="tql-box">
                          <h5>Additional details</h5>
                          <p>
                            {this.props.orderDetails.note &&
                              this.props.orderDetails.note}
                          </p>
                        </div>
                      </li>
                    )}
                  </ul>
                )}
              </div>
            </div>
          )}
        </div>
        <Modal
          show={this.state.popup}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="modal-header">
            <button
              type="button"
              className="close mt-2"
              data-dismiss="modal"
              onClick={this.handlePopup}
            >
              {" "}
              <img src={closeBtn} alt="close popup" />
            </button>
          </div>
          <div className="modal-body">
            <div className="modal-alert-box">
              <h3 className="mb-6">Alert!</h3>
              <p>Are you sure you want to change the status?</p>
              <p>This action cannot be reverted.</p>
            </div>
            <div className="modal-action-btn">
              <a
                href="javascript:void(0)"
                data-dismiss="modal"
                className="btn btn-delete"
                onClick={this.confirmProductStatus}
              >
                Confirm
              </a>
              <a
                href="#!"
                className="btn btn-cancel"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.handlePopup}
              >
                Cancel
              </a>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  //   orders: getSeller(state).orders.data,
  // orderDetails: getSeller(state).orderDetails,
  //   pagination: getSeller(state).orders.pagination,
  loading: getSeller(state).orderDetailsLoading,
});
const mapDispatchToProps = (dispatch) => ({
  updateProductStatus: (payload, id, callback) =>
    dispatch(updateProductStatus(payload, id, callback)),
  loadOrders: (params) => dispatch(loadOrders(params)),
  addDisputeMessage: (data, id, callback) =>
    dispatch(addDisputeMessage(data, id, callback)),
  resolveDispute: (id, callback) => dispatch(resolveDispute(id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
