import React, { Component } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import heartIcon from "./../../include/images/outline-heart.svg";
import wishlistIcon from "./../../include/images/fav-icon.svg";

import AddToCart from "./addToCart";
import StarRatings from "react-star-ratings";

import { Link, withRouter } from "react-router-dom";

import { addToWishlist } from "../../store/customer";
import { connect } from "react-redux";

import { toast } from "react-toastify";
import AlertSuccess from "../common/alertSuccess";
import AlertError from "../common/alertError";
import { getCustomerToken } from "../services/localStorageServices";

class ProductGridB extends Component {
  state = { addedToWishlist: false };

  handleWishlist = (id) => {
    if (getCustomerToken("refresh")) {
      const params = {
        product: id,
      };
      this.props.addToWishlist(params, this.callBack);
    } else {
      this.props.history.push("/customer/login");
    }
  };

  callBack = (res) => {
    // this.ref.current.complete();
    // this.setState({ loader: false });
    if (res.status === 200) {
      toast(<AlertSuccess message={"Added to wishlist"} />);
      this.setState({
        addedToWishlist: !this.state.addedToWishlist,
      });
    } else {
      toast(<AlertError message={"Failed"} />);
    }
  };

  render() {
    const e = this.props.data;
    return (
      <li className="grid-item">
        <Link
          to={{
            pathname: "/product-detail/" + e._id,
            state: {
              seller_id: this.props.seller_id,
            },
          }}
        >
          <a
            href="javascript:void(0)"
            className="grid-box d-flex flex-column align-items-center"
          >
            <div className="grid-img">
              <img
                src={
                  e.image[0]
                    ? process.env.REACT_APP_S3URL + e.image[0].medium
                    : ""
                }
                alt=""
              />
            </div>
            <div class="grid-details d-flex flex-column w-100 ">
              <div class="grid-details-header">
                <div class="grid-star d-flex align-items-center">
                  <p class="seller-name">{e.seller.company}</p>
                  {e.seller.average_ratings && (
                    <>
                      <i class="fas fa-star custom-star"></i>
                      <span class="reviews-text">
                        {e.seller.average_ratings}
                      </span>
                    </>
                  )}
                </div>
                <h3>
                  <LinesEllipsis
                    text={e.description}
                    maxLine="2"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                </h3>
              </div>
              <div className="star-list">
                {e.average_ratings ? (
                  <StarRatings
                    rating={e.average_ratings}
                    starDimension="20px"
                    starSpacing="3px"
                    isSelectable="false"
                    starEmptyColor="#C4C4C4"
                    starRatedColor="#ffa51e"
                    starHoverColor="#ffa51e"
                  />
                ) : (
                  <StarRatings
                    rating={0}
                    starDimension="20px"
                    starSpacing="3px"
                    isSelectable="false"
                    starEmptyColor="#C4C4C4"
                    starRatedColor="#ffa51e"
                    starHoverColor="#ffa51e"
                  />
                )}
                <span className="reviews-text custom-rating">
                  {/* {e.average_ratings && e.average_ratings}{" "} */}
                  <small>({e.total_reviews && e.total_reviews})</small>{" "}
                </span>
              </div>
              <div className="grid-price d-sm-flex">
                <h4>
                  ${" "}
                  {e.price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}{" "}
                </h4>
                <span className="avability">Available Qty {e.quantity}</span>
              </div>
            </div>
          </a>
        </Link>

        <div className="grid-hover-content d-flex">
          <AddToCart
            data={this.props.data}
            setProductId={this.props.setProductId}
            productId={this.props.productId}
          />
          {!localStorage.getItem("x-auth-token-seller") && (
            <a
              onClick={() => {
                this.handleWishlist(e._id);
              }}
              href="javascript:void(0)"
              className="border-btn"
            >
              <img
                src={
                  e.addedToWishList || this.state.addedToWishlist
                    ? wishlistIcon
                    : heartIcon
                }
                alt=""
              />
            </a>
          )}
        </div>
      </li>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addToWishlist: (data, callback) => dispatch(addToWishlist(data, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(ProductGridB));
