import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

import closeBtn from "../../../include/images/close.svg";

export default class DeleteAddressPopup extends Component {
  render() {
    return (
      <div
        className={
          this.props.removeBillingAddress || this.props.removeShippingAddress
            ? "modal fade show"
            : "modal fade"
        }
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <Modal
              {...this.props}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <div className="modal-header">
                <button
                  type="button"
                  className="close mt-2"
                  data-dismiss="modal"
                  onClick={this.props.onHide}
                >
                  <img src={closeBtn} alt="close popup" />
                </button>
              </div>
              <div className="modal-body">
                <div className="modal-alert-box">
                  <h3 className="mb-6">Alert!</h3>
                  <p>Are you sure you want to delete this address?</p>
                  <p> This action cannot be reverted.</p>
                </div>
                <div className="modal-action-btn">
                  <a
                    href="javascript:void (0)"
                    onClick={() => this.props.handleRemove()}
                    className="btn btn-delete"
                  >
                    Remove
                  </a>
                  <a
                    href="javascript:void (0)"
                    onClick={this.props.onHide}
                    className="btn btn-cancel"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </a>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}
