import React, { Component } from "react";

class Input extends Component {
  state = {
    formclass: "form-group custom-input",
  };

  handleFocus = () => {
    this.setState({ formclass: "form-group custom-input show on" });
  };

  handleBlur = (e) => {
    const classname = e.target.value
      ? "form-group custom-input show on"
      : "form-group custom-input";
    this.setState({ formclass: classname });
  };

  componentDidMount() {
    const classname = this.props.value
      ? "form-group custom-input show on"
      : "form-group custom-input";
    this.setState({ formclass: classname });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const classname = this.props.value
        ? "form-group custom-input show on"
        : "form-group custom-input";
      this.setState({ formclass: classname });
    }
  }
  render() {
    const { name, label, error, readOnly, ...rest } = this.props;
    return (
      <div>
        <label htmlFor={name}>{label}</label>
        <div className={this.state.formclass}>
          <input
            step={0.01}
            min={0}
            name={name}
            id={name}
            className={
              error
                ? "form-control input-data error"
                : "form-control input-data"
            }
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            readOnly={readOnly}
            // placeholder={label}
            {...rest}
          />
          {error && <label className="error">{error}</label>}
        </div>
      </div>
    );
  }
}

export default Input;
