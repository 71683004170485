import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "customer",
  initialState: {
    customer: {
      customers: [],
      customerData: [],
    },
    profile: {},
    customers: {
      data: [],
      pagination: {
        current_page: 1,
        total_page: 0,
        total_record: 0,
        total_record_on_current_page: 0,
        filtered_record: 0,
        record_per_page: 0,
      },
    },

    orders: {
      data: [],
      pagination: {},
    },
    orderDetails: {},

    disputeDetails: {},
    disputeDetailsLoading: false,
    disputeDetailsLastFetch: null,

    disputePhoto: {},
    disputePhotoLoading: false,
    disputePhotoLastFetch: null,

    returnPhoto: {},
    returnPhotoLoading: false,
    returnPhotoLastFetch: null,

    wishlist: [],
    wishlistLastFetch: null,
    wishlistLoading: false,

    profileLoading: false,
    profileLastFetch: null,

    customerLoading: false,
    customerLastFetch: null,

    ordersLoading: false,
    ordersLastFetch: null,

    orderDetailsLastFetch: null,
    orderDetailsLoading: false,
  },
  reducers: {
    profileRequested: (customer, action) => {
      customer.profileLoading = true;
    },

    profileReceived: (customer, action) => {
      customer.profile = action.payload.data;
      customer.profileLoading = false;
      customer.profileLastFetch = Date.now();
    },

    profileRequestFailed: (customer, action) => {
      customer.profileLoading = false;
    },

    customerRequested: (customer, action) => {
      customer.customerLoading = true;
    },
    customerReceived: (customer, action) => {
      customer.customers = action.payload;
      customer.customerLoading = false;
      customer.customerLastFetch = Date.now();
    },
    customerRequestFailed: (customer, action) => {
      customer.customerLoading = false;
    },

    ordersRequested: (customer, action) => {
      customer.ordersLoading = true;
    },

    ordersReceived: (customer, action) => {
      customer.orders = action.payload;
      customer.ordersLoading = false;
      customer.ordersLastFetch = Date.now();
    },
    ordersRequestFailed: (customer, action) => {
      customer.ordersLoading = false;
    },

    orderDetailsRequested: (customer, action) => {
      customer.orderDetailsLoading = true;
    },

    orderDetailsReceived: (customer, action) => {
      customer.orderDetails = action.payload;
      customer.orderDetailsLoading = false;
      customer.orderDetailsLastFetch = Date.now();
    },
    orderDetailsRequestFailed: (customer, action) => {
      customer.orderDetailsLoading = false;
    },
    wishlistRequested: (customer, action) => {
      customer.wishlistLoading = true;
    },

    wishlistReceived: (customer, action) => {
      customer.wishlist = action.payload;
      customer.wishlistLoading = false;
      customer.wishlistLastFetch = Date.now();
    },
    wishlistRequestFailed: (customer, action) => {
      customer.wishlistLoading = false;
    },
    disputeDetailsRequested: (customer, action) => {
      customer.disputeDetailsLoading = true;
    },

    disputeDetailsReceived: (customer, action) => {
      customer.disputeDetails = action.payload;
      customer.disputeDetailsLoading = false;
      customer.disputeDetailsLastFetch = Date.now();
    },
    disputeDetailsRequestFailed: (customer, action) => {
      customer.disputeDetailsLoading = false;
    },

    pictureRequested: (customer, action) => {
      customer.disputePhotoLoading = true;
    },
    pictureReceived: (customer, action) => {
      customer.disputePhoto = action.payload;
      customer.disputePhotoLoading = false;
      customer.disputePhotoLastFetch = Date.now();
    },

    pictureRequestFailed: (customer, action) => {
      customer.disputePhotoLoading = false;
    },

    returnPictureRequested: (customer, action) => {
      customer.returnPhotoLoading = true;
    },
    returnPictureReceived: (customer, action) => {
      customer.returnPhoto = action.payload;
      customer.returnPhotoLoading = false;
      customer.returnPhotoLastFetch = Date.now();
    },

    returnPictureRequestFailed: (customer, action) => {
      customer.returnPhotoLoading = false;
    },
  },
});

export const {
  pictureRequested,
  pictureRequestFailed,
  pictureReceived,
  profileRequested,
  profileReceived,
  profileRequestFailed,
  customerReceived,
  customerRequestFailed,
  customerRequested,
  ordersRequested,
  ordersReceived,
  ordersRequestFailed,
  orderDetailsRequested,
  orderDetailsReceived,
  orderDetailsRequestFailed,
  wishlistReceived,
  wishlistRequestFailed,
  wishlistRequested,
  disputeDetailsReceived,
  disputeDetailsRequested,
  disputeDetailsRequestFailed,
  returnPictureReceived,
  returnPictureRequestFailed,
  returnPictureRequested,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "customer/";
const otpUrl = "otp/";

export const postProductReview = (data, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: "product/addReview",
      method: "POST",
      data,
      callback,
    })
  );
};

export const postSellerReview = (data, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: "order/addSellerReview",
      method: "POST",
      data,
      callback,
    })
  );
};

export const uploadDisputePictures =
  (data, callback) => (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        method: "POST",
        url: "order/uploadPicture",
        data,
        callback,
        onStart: pictureRequested.type,
        onSuccess: pictureReceived.type,
        onError: pictureRequestFailed.type,
      })
    );
  };

export const uploadReturnPictures =
  (data, callback) => (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        method: "POST",
        url: "order/uploadPicture",
        data,
        callback,
        onStart: returnPictureRequested.type,
        onSuccess: returnPictureReceived.type,
        onError: returnPictureRequestFailed.type,
      })
    );
  };

export const emailAvailability = (data, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + "emailAvailability",
      method: "POST",
      data,
      callback,
    })
  );
};

export const sendMobileOtp = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: otpUrl + "requestMobileOTP",
      method: "POST",
      data,
      callback,
    })
  );
};

export const mobileAvailability = (data, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + "mobileAvailability",
      method: "POST",
      data,
      callback,
    })
  );
};

export const customerSignUp = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "signUp",
      method: "POST",
      data,
      callback,
    })
  );
};

// export const sendEmailOtp = (data, callback) => (dispatch) => {
//   return dispatch(
//     apiCallBegan({
//       url: otpUrl + "requestEmailOTP",
//       method: "POST",
//       data,
//       callback,
//     })
//   );
// };

export const logoutCustomer = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "auth/logout",
      method: "POST",
      callback,
    })
  );
};

export const customerSignIn = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "signIn",
      method: "POST",
      data,
      callback,
    })
  );
};

export const addOrderDispute = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "order/dispute",
      method: "POST",
      data,
      callback,
    })
  );
};

export const returnRequest = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "order/return",
      method: "POST",
      data,
      callback,
    })
  );
};

export const loadDisputeDetails = (id) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: "order/dispute/" + id,
      onStart: disputeDetailsRequested.type,
      onSuccess: disputeDetailsReceived.type,
      onError: disputeDetailsRequestFailed.type,
    })
  );
};

export const loadWishlist = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: "customer/wishList",
      params,
      onStart: wishlistRequested.type,
      onSuccess: wishlistReceived.type,
      onError: wishlistRequestFailed.type,
    })
  );
};

export const deleteWishlist = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "customer/removeFromWishlist",
      method: "PUT",
      data,
      callback,
    })
  );
};

export const addToWishlist = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "customer/addToWishlist",
      method: "PUT",
      data,
      callback,
    })
  );
};

export const updateMobile = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "updateCustomerMobile",
      method: "PUT",
      data,
      callback,
    })
  );
};

export const updateAddress = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "updateAddress",
      method: "PUT",
      data,
      callback,
    })
  );
};

export const loadOrders = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: "order",
      params,
      onStart: ordersRequested.type,
      onSuccess: ordersReceived.type,
      onError: ordersRequestFailed.type,
    })
  );
};

export const loadOrderDetails = (id) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: "order/" + id,
      onStart: orderDetailsRequested.type,
      onSuccess: orderDetailsReceived.type,
      onError: orderDetailsRequestFailed.type,
    })
  );
};

export const resetPasswordMobile = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "resetPasswordMobile",
      method: "PUT",
      data,
      callback,
    })
  );
};

export const addCard = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "addCard",
      method: "PUT",
      data,
      callback,
    })
  );
};
export const removeCard = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "removeCard",
      method: "PUT",
      data,
      callback,
    })
  );
};

export const customerUpdate = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "me",
      method: "PUT",
      data,
      callback,
    })
  );
};

export const loadCustomerProfile = () => (dispatch, getState) => {
  // const { profileLastFetch } = getState().entities.customer;
  // alert(profileLastFetch);

  // const diffInMinutes = moment().diff(moment(profileLastFetch), "seconds");
  // if (diffInMinutes < 10) return;
  return dispatch(
    apiCallBegan({
      url: url + "me",
      onStart: profileRequested.type,
      onSuccess: profileReceived.type,
      onError: profileRequestFailed.type,
    })
  );
};

export const getCustomer = createSelector(
  (state) => state.entities.customer,
  (customer) => customer
);
