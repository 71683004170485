import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertClose from "./components/common/alertClose";

import "./include/css/bootstrap.min.css";
import "./include/css/style.css";
import "./include/css/dev.css";
import configureStore from "./store/configureStore";

import { Provider } from "react-redux";

import CustomerVerified from "./components/customer/customerRegistration/customerVerified";
import CustLoginForm from "./components/customer/customerLogin/custLoginForm";
import CustForgotPassword from "./components/customer/customerLogin/custForgotPassword";
import CustomerResetPasswordMobile from "./components/customer/customerLogin/customerResetPasswordMobile";
import RequestOtpCustomer from "./components/customer/customerRegistration/otpVerification";
import AccountDetails from "./components/customer/dashboard/accountDetails";
import CustomerChangePassword from "./components/customer/dashboard/customerChangePassword";
import Address from "./components/customer/dashboard/address";
import OrderHistory from "./components/customer/dashboard/orderHistory";

import DisputesReturnRequests from "./components/dashboard/disputesReturnRequest";
import Sales from "./components/dashboard/sales";
import RequestOtpSeller from "./components/sellerRegistration/otpVerification";
import Verified from "./components/sellerRegistration/verified";
import Dashboard from "./components/dashboard/dashboard";
import ChangePassword from "./components/dashboard/changePassword";
import HourOperation from "./components/dashboard/hourOperation";
import HourOperationSecond from "./components/dashboard/hourOperationSec";
import ProductListing from "./components/dashboard/productListing";

import ForgotPassword from "./components/sellerLogin/forgotPassword";
import ResetPasswordEmail from "./components/sellerLogin/resetPasswordEmail";
import ResetPassword from "./components/sellerLogin/resetPasswordMobile";
import LoginForm from "./components/sellerLogin/login";
import Product from "./components/product/product";
import ProductV2 from "./components/productV2/product";
import ProductList from "./components/product/productList";
import ProductListV2 from "./components/productV2/productList";
import NoResult from "./components/product/noResult";
import MapDraw from "./components/dashboard/mapDraw";
import Category from "./components/dashboard/category";
import LinkStripe from "./components/dashboard/linkStripe";
import Coupons from "./components/coupons/coupons";
import Cart from "./components/cart/cart";
import Checkout from "./components/checkout/checkout";
import Review from "./components/review/review";
import OrderListing from "./components/dashboard/orderListing";
import Wishlist from "./components/customer/dashboard/wishlist";
import OrderSuccess from "./components/orderStatus/orderSuccess";
import OrderFailed from "./components/orderStatus/orderFailed";
import SellerReviews from "./components/dashboard/sellerReviews";
import CardDetails from "./components/customer/dashboard/cardDetails";
import StockHistory from "./components/dashboard/stockHistory";
import ProductListNew from "./components/productV2/productListNew";

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <ToastContainer
        hideProgressBar
        autoClose={5000}
        toastClassName="alert alert-success alert-white"
        closeButton={<AlertClose />}
      />
      <Router>
        <Switch>
          <Route exact path="/v1" component={ProductList} />
          <Route exact path="/" component={ProductListNew} />
          <Route exact path="/products-listing" component={ProductListV2} />
          <Route
            exact
            path="/products/:cat1?/:cat2?/:cat3?"
            component={ProductListV2}
          />
          <Route exact path="/store/:seller_id?" component={ProductListV2} />
          <Route
            exact
            path="/customer/registrationSuccess"
            component={CustomerVerified}
          />
          <Route
            exact
            path="/customer/order-history"
            component={OrderHistory}
          />
          <Route exact path="/customer/login" component={CustLoginForm} />
          <Route
            exact
            path="/customer/account-details"
            component={AccountDetails}
          />

          <Route exact path="/customer/card-details" component={CardDetails} />
          <Route exact path="/customer/addresses" component={Address} />
          <Route
            exact
            path="/customer/forgot-password"
            component={CustForgotPassword}
          />

          <Route
            exact
            path="/customer/reset-password/mobile"
            component={CustomerResetPasswordMobile}
          />

          <Route
            exact
            path="/customer/registration"
            component={RequestOtpCustomer}
          />

          <Route
            exact
            path="/customer/change-password"
            component={CustomerChangePassword}
          />
          <Route exact path="/customer/wishlist" component={Wishlist} />
          <Route
            exact
            path="/seller/registration"
            component={RequestOtpSeller}
          />
          <Route exact path="/seller/sales" component={Sales} />
          <Route
            exact
            path="/seller/dispute-return-requests"
            component={DisputesReturnRequests}
          />
          <Route exact path="/seller/stock-history" component={StockHistory} />
          <Route exact path="/v1/product-detail/:id" component={Product} />
          <Route exact path="/product-detail/:id" component={ProductV2} />

          <Route exact path="/product-category" component={Category} />

          <Route exact path="/seller/orders" component={OrderListing} />
          <Route exact path="/seller/maps" component={MapDraw} />
          <Route exact path="/no-result-found" component={NoResult} />
          <Route exact path="/seller/basic-info" component={Dashboard} />
          <Route exact path="/seller/my-reviews" component={SellerReviews} />
          <Route exact path="/change-password" component={ChangePassword} />
          <Route exact path="/hours-of-operation" component={HourOperation} />
          <Route
            exact
            path="/edit-hours-of-operation"
            component={HourOperationSecond}
          />

          <Route exact path="/seller/coupons" component={Coupons} />

          <Route exact path="/product-listing" component={ProductListing} />

          <Route
            exact
            path="/seller/successful-registration"
            component={Verified}
          />

          <Route exact path="/seller/login" component={LoginForm} />

          <Route
            exact
            path="/seller/forgot-password"
            component={ForgotPassword}
          />
          <Route
            exact
            path="/seller/stripe/:checkAccount?"
            component={LinkStripe}
          />

          <Route
            exact
            path="/seller/reset-password/mobile"
            component={ResetPassword}
          />

          <Route
            exact
            path="/seller/reset-password/email"
            component={ResetPasswordEmail}
          />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/review" component={Review} />

          <Route exact path="/order-success" component={OrderSuccess} />
          <Route exact path="/order-failed" component={OrderFailed} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
