import React from "react";
import Form from "../../common/form/form";
import SidebarCustomer from "../../common/customer/sideBarCustomer";
import SubmitBtn from "../../common/form/submitBtn";
import { connect } from "react-redux";

import { toast } from "react-toastify";
import Joi from "joi-browser";
import AlertError from "../../common/alertError";
import { withRouter, Link } from "react-router-dom";

import { resetPasswordMobile } from "../../../store/customer";

import {
  passwordInvalid,
  passwordRequired,
  passwordInvalidMax,
} from "../../common/misc";

class CustomerResetPasswordMobile extends Form {
  state = {
    btnClass: "btn btn-lg btn-primary btn-block",
    loading: false,
    data: { password: "" },
    errors: { password: "" },
  };
  componentWillUnmount = () => {
    document.body.classList.remove("pt-0");
  };

  componentDidMount = () => {
    document.body.classList.add("pt-0");
  };

  schema = {
    confirmPassword: Joi.string()
      .required()
      .label("Confirm Password")
      .valid(Joi.ref("password"))
      .options({
        language: {
          any: {
            allowOnly: "!!Confirm Password and Password fields must be same",
          },
        },
      }),
    password: Joi.string()
      .min(8)
      .max(25)
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "password"
      )
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.regex.name":
              err.message = passwordInvalid;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;

            case "string.max":
              err.message = passwordInvalidMax;
              break;

            default:
          }
        });
        return errors;
      }),
  };

  doSubmit = () => {
    if (!this.state.loading) {
      this.setState({
        btnClass: "btn btn-lg btn-primary btn-block disabled",
        loading: true,
      });

      const param = {
        mobile: this.props.verifyMobile.mobile,
        verification_id: this.props.verifyMobile.verification_id,
        password: this.state.data.password,
      };
      this.props.resetPasswordMobile(param, this.callback);
    }
  };

  callback = (res) => {
    this.setState({
      btnClass: "btn btn-lg btn-primary btn-block",
      loading: false,
    });
    if (res.status === 200) {
      this.props.updateStep(4);
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  render() {
    return (
      <div className="member-container">
        <SidebarCustomer />
        <div className="member-right">
          <div className="signin-form-container">
            <div className="signin-form login-form">
              <h2>Reset password</h2>

              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  {this.renderPasswordInput("password", "Password")}
                </div>
                <div className="form-group">
                  {this.renderPasswordInput(
                    "confirmPassword",
                    "Confirm Password"
                  )}
                </div>
                <div className="form-group form-button">
                  <SubmitBtn
                    label={this.state.loading === true ? "" : "Reset"}
                    loading={this.state.loading}
                    btnClass="btn btn-default"
                  />
                </div>
                <div className="back-link">
                  <Link to="/customer/login" className="btn-link">
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns-xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 31.494 31.494"
                      xml-space="preserve"
                    >
                      <path
                        d="M10.273,5.009c0.444-0.444,1.143-0.444,1.587,0c0.429,0.429,0.429,1.143,0,1.571l-8.047,8.047h26.554
                                c0.619,0,1.127,0.492,1.127,1.111c0,0.619-0.508,1.127-1.127,1.127H3.813l8.047,8.032c0.429,0.444,0.429,1.159,0,1.587
                                c-0.444,0.444-1.143,0.444-1.587,0l-9.952-9.952c-0.429-0.429-0.429-1.143,0-1.571L10.273,5.009z"
                      ></path>
                    </svg>
                    Back to Login
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetPasswordMobile: (param, callback) =>
    dispatch(resetPasswordMobile(param, callback)),
});

export default withRouter(
  connect(null, mapDispatchToProps)(CustomerResetPasswordMobile)
);
