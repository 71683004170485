import React, { createRef } from "react";
import Lottie from "react-lottie-player";
import LoadingBar from "react-top-loading-bar";
import { connect } from "react-redux";
import bannerImg from "../../include/images/banner-img.jpg";
import mobBannerImg from "../../include/images/mob-banner-img.png";
import postImg1 from "../../include/images/post-img1.jpg";
import postImg2 from "../../include/images/post-img2.jpg";
import postImg3 from "../../include/images/post-img3.jpg";
import cartCtaBg from "../../include/images/cart-cta-bg.svg";
import AddToCart from "./addToCart";
import { addToWishlist, deleteWishlist } from "../../store/customer";
import { getSeller } from "./../../store/seller";
import { getCustomerToken } from "../services/localStorageServices";

import {
  getProduct,
  loadAllProducts,
  loadCategories,
} from "../../store/product";
import { PureComponent } from "react";
import { getCustomer, loadCustomerProfile } from "../../store/customer";
import { Link, withRouter } from "react-router-dom";
import { loadCart } from "../../store/cart";
import Geocode from "react-geocode";
import HeaderHome from "./headerHome";
import Hero from "./hero";
import FooterNew from "./footerNew";
import Slider from "react-slick";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import WishList from "./wishlistNew";
import shopBanner from "../../include/json/shop-banner.json";
import sellBanner from "../../include/json/sell-banner.json";
import AddBtnNew from "./addBtnNew";

Geocode.setApiKey(process.env.REACT_APP_MAPKEY);
Geocode.setLanguage("en");
Geocode.setRegion("us");
Geocode.setLocationType("APPROXIMATE");
Geocode.enableDebug();
const pricArr = [
  { min: 10, max: 50 },
  { min: 50, max: 100 },
  { min: 100, max: 200 },
];

class ProductListNew extends PureComponent {
  ref = createRef(null);

  state = {
    listType: "a",
    productId: "",
    userLatLng: true,
    tab: "A",
    lng: -81.4915242,
    lat: 28.4716263,
    maxDistance: 16093,
    seller: [],
    page: 1,
    q: "",
    isContact: false,
    zoom: 12,
    address: localStorage.getItem("customerDddress")
      ? JSON.parse(localStorage.getItem("customerDddress")).address
      : "",
    postal_code: localStorage.getItem("customerDddress")
      ? JSON.parse(localStorage.getItem("customerDddress")).postal_code
          .long_name
      : "",
    pickup: false,
    delivery_option_value: ["Weekly once", "More than a week"],
    mobileFilter: false,
    sort: false,
    dropDown: false,
    adress: "",
    zipcode: "",
    latLng: {},
    productId: "",
    addedToWishlist: false,
    index: "",
  };

  setProductId = (productId) =>
    this.setState({
      productId: productId === this.state.productId ? "" : productId,
    });

  doSubmit = (adress, zipcode, latLng) => {
    this.setState({ adress, zipcode, latLng });
  };

  setProductId = (productId) =>
    this.setState({
      productId: productId === this.state.productId ? "" : productId,
    });

  updateLatLng = (
    lat,
    lng,
    address,
    postal_code = "",
    repplace = false,
    customerAddress = false
  ) => {
    if (
      localStorage.getItem("customerDddress") &&
      (!repplace || !customerAddress)
    )
      return;
    localStorage.setItem(
      "customerDddress",
      JSON.stringify({ lat, lng, address, postal_code, repplace })
    );
    this.setState(
      {
        address,
        lat,
        lng,
        userLatLng: true,
        postal_code: postal_code ? postal_code.long_name : "",
      },
      () => {
        this.props.loadAllProducts(this.filterData());
      }
    );
  };

  getLatLong = () => {
    this.updateLatLng(
      "28.4716263",
      "-81.4915242",
      "Dr Phillips Blvd, Orlando, FL, USA",
      {
        long_name: "32819",
      }
    );
    navigator.geolocation.getCurrentPosition((position) => {
      Geocode.fromLatLng(
        position.coords.latitude,
        position.coords.longitude
      ).then(
        (response) => {
          const address = response.results[0].formatted_address;
          const postal_code = response.results[0].address_components.find(
            (es) => es.types[0] === "postal_code"
          );
          this.updateLatLng(
            position.coords.latitude,
            position.coords.longitude,
            address,
            postal_code,
            !localStorage.getItem("customerAddress"),
            true
          );
        },
        (error) => {
          console.error(" updateLatLngerror--->", error);
        }
      );
    });
  };

  filterData = () => {
    this.ref.current.continuousStart();
    const customerDddress = localStorage.getItem("customerDddress")
      ? JSON.parse(localStorage.getItem("customerDddress"))
      : {};
    const data = {};
    const filters = { ...this.state.filters };
    const { address, postal_code, lat, lng } = this.state;
    if (address) data.address = address;
    if (postal_code) data.zipCode = postal_code;
    if (lat && lng) {
      data.lat = lat;
      data.lon = lng;
    }
    if (customerDddress.lat) {
      data.lat = customerDddress.lat;
      data.lon = customerDddress.lng;
    }
    data.page = 1;
    data.pickupOption = "100";
    data.includeOutofStock = false;
    return data;
  };

  componentDidMount = () => {
    this.ref.current.continuousStart();
    window.scrollTo({ top: 0, behavior: "smooth" });
    const address = localStorage.getItem("customerDddress")
      ? JSON.parse(localStorage.getItem("customerDddress"))
      : {};

    if (localStorage.getItem("x-auth-token-customer"))
      this.props.loadCustomerProfile();
    if (localStorage.getItem("customerDddress") && address.repplace) {
      this.updateLatLng(
        address.lat,
        address.lng,
        address.address,
        address.postal_code,
        false,
        true
      );
    } else {
      this.getLatLong();
    }
    // document.body.classList.add("home");
    // document.body.classList.add("fixed");
    document.body.classList.remove("cart_page");

    this.props.history.replace();
    this.props.loadAllProducts(this.filterData());
  };

  componentDidUpdate = async (prevProps, prevState) => {
    // if (this.props.match.params !== prevProps.match.params)
    //   this.props.loadAllProducts(this.filterData());
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.complete();
    }
    if (
      !this.props.getCustomer.profileLoading &&
      this.props.getCustomer.profileLoading !==
        prevProps.getCustomer.profileLoading
    ) {
      if (this.props.getCustomer.profile.shipping_address) {
        try {
          const add = await Geocode.fromAddress(
            `${this.props.getCustomer.profile.shipping_address.address_line1} ${this.props.getCustomer.profile.shipping_address.address_line2} ${this.props.getCustomer.profile.shipping_address.city} ${this.props.getCustomer.profile.shipping_address.state} ${this.props.getCustomer.profile.shipping_address.zipcode}`
          );

          const lat = add.results[0].geometry.location.lat;
          const lng = add.results[0].geometry.location.lng;
          const localStorageData = localStorage.getItem("customerDddress")
            ? JSON.parse(localStorage.getItem("customerDddress"))
            : {};

          if (!localStorageData.repplace) {
            localStorage.setItem("customerAddress", true);
            this.updateLatLng(
              lat,
              lng,
              this.props.getCustomer.profile.shipping_address.address_line2
                ? `${this.props.getCustomer.profile.shipping_address.address_line1}, ${this.props.getCustomer.profile.shipping_address.address_line2}, ${this.props.getCustomer.profile.shipping_address.city}, ${this.props.getCustomer.profile.shipping_address.state}, USA`
                : `${this.props.getCustomer.profile.shipping_address.address_line1}, ${this.props.getCustomer.profile.shipping_address.city}, ${this.props.getCustomer.profile.shipping_address.state}, USA`,
              {
                long_name:
                  this.props.getCustomer.profile.shipping_address.zipcode,
              },
              true,
              true
            );
          }
        } catch (error) {
          console.log("addddddError", error);
        }
      }
    }
  };

  next = () => {
    this.slider.slickNext();
  };

  previous = () => {
    this.slider.slickPrev();
  };

  handleWishlist = (id, p) => {
    if (getCustomerToken("refresh")) {
      const params = {
        product: id,
      };
      if (!p) this.props.addToWishlist(params, this.callBack);
      else this.props.deleteWishlist(params, this.callBack);
    } else {
      this.props.history.push("/customer/login");
    }
  };
  callBack = (res) => {
    // this.ref.current.complete();
    // this.setState({ loader: false });
    if (res.status === 200) {
      this.props.loadAllProducts(this.filterData());
      // toast(<AlertSuccess message={"Added to wishlist"} />);
      // this.setState({
      //   addedToWishlist: !this.state.addedToWishlist,
      // });
    } else {
      toast(<AlertError message={"Failed"} />);
    }
  };

  render() {
    const { product, pagination } = this.props;
    const settings = {
      dots: false,
      arrows: true,
      infinite: false,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 1,
      veriableWidth: true,
      adaptiveHeight: false,
      responsive: [
        {
          breakpoint: 1800,
          settings: {
            arrows: true,
            dots: false,
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            arrows: true,
            dots: false,
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 700,
          settings: {
            arrows: true,
            dots: false,
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 579,
          settings: {
            dots: false,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const adressArr = this.state.address ? this.state.address.split(",") : [];
    return (
      <>
        <LoadingBar color="#ffffff" ref={this.ref} />
        <HeaderHome />
        <Hero doSubmit={this.doSubmit} updateLatLng={this.updateLatLng} />
        {/* {product && product.length > 0 && (
          <div class="content-container home-prduct-container">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="hpc-heading-box d-lg-flex align-items-lg-center">
                    <h5>
                      Check out the best selling products near{" "}
                      <span>
                        (
                        {adressArr.length > 4
                          ? `${adressArr[0]}, ${adressArr[1]}, ${adressArr[2]}, ${adressArr[3]} ${this.state.postal_code}`
                          : `${adressArr[0]}, ${adressArr[1]}, ${adressArr[2]} ${this.state.postal_code}`}
                        )
                      </span>
                    </h5>
                    <div class="hpc-right d-flex align-items-center ml-md-auto">
                      <div class="add-custom-arrow d-flex align-items-center">
                        <div
                          class="custom-slick-arrow prev"
                          onClick={this.previous}
                        ></div>
                        <div
                          class="custom-slick-arrow next"
                          onClick={this.next}
                        ></div>
                      </div>
                      <Link to="/products-listing" class="showall-link">
                        Show all
                      </Link>
                    </div>
                  </div>
                  <div class="hpc-product-list-container">
                    <Slider
                      {...settings}
                      ref={(c) => (this.slider = c)}
                      className="grid-content has-four-column no-sm-border  hpc-product-slider d-flex flex-wrap"
                    >
                      {product &&
                        product.slice(0, 12).map((p, i) => (
                          <li
                            style={{ cursor: "pointer" }}
                            class="grid-item"
                            key={i}
                          >
                            <div
                              class="grid-img border-radius-6px overflow-hidden"
                              style={{
                                marginBottom: "12px",
                                paddingBottom: "0 0 85%",
                              }}
                            >
                              <a
                                class="grid-box d-flex flex-column"
                                onClick={(e) =>
                                  this.props.history.push(
                                    "/product-detail/" + p._id
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_S3URL +
                                    p.image[0].large
                                  }
                                  alt=""
                                />
                                <div class="grid-icon-box d-block d-md-none">
                                  <img src={heartIcon} alt="" />
                                </div>
                              </a>
                            </div>
                            <div class="grid-details d-flex flex-column w-100 ">
                              <div class="grid-details-header">
                                <div
                                  class="grid-star d-flex align-items-center"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.props.history.push(
                                      `/store/${p.seller.seller_id}`
                                    )
                                  }
                                >
                                  <p class="seller-name">
                                    {p.seller && p.seller.company}
                                  </p>
                                </div>
                                <h3>
                                  {" "}
                                  <Link
                                    to={{
                                      pathname: "/product-detail/" + p._id,
                                      // state: this.props.stateData,
                                    }}
                                  >
                                    {p && p.name}
                                  </Link>
                                </h3>
                              </div>

                              <div class="grid-price d-sm-flex">
                                <h4>
                                  ${" "}
                                  {p.price.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}{" "}
                                </h4>
                              </div>
                            </div>

                            <div class="grid-hover-content d-flex">
                              <div class="add-to-cart-dropdown dropdown">
                                <AddToCart
                                  data={p}
                                  setProductId={this.setProductId}
                                  productId={this.state.productId}
                                  new="new"
                                />
                              </div>
                              {!localStorage.getItem("x-auth-token-seller") && (
                                <WishList
                                  handleWishlist={this.handleWishlist}
                                  p={p}
                                  addedToWishlist={p.addedToWishList}
                                  addToWishlist={this.props.addToWishlist}
                                  deleteWishlist={this.props.deleteWishlist}
                                  callBack={this.callBack}
                                />
                              )}
                            </div>
                          </li>
                        ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}

        <div class="content-container home-prduct-container">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="hpc-header text-center">
                  <h4>Check out the best selling products near</h4>
                  <p>
                    {adressArr.length > 4
                      ? `${adressArr[0]}, ${adressArr[1]}, ${adressArr[2]}, ${adressArr[3]} ${this.state.postal_code}`
                      : `${adressArr[0]}, ${adressArr[1]}, ${adressArr[2]} ${this.state.postal_code}`}
                  </p>
                </div>
                <Slider
                  {...settings}
                  className="product-gridview-list product-gridview-slider add-custom-slick-arrow pb-0 d-flex flex-wrap full-height"
                >
                  {product &&
                    product.slice(0, 12).map((p) => (
                      <li class="pgl-item h-100">
                        <div class="pgl-box w-100 h-100 overflow-hidden position-relative">
                          <AddBtnNew
                            data={p}
                            setProductId={this.setProductId}
                            productId={this.state.productId}
                            new={true}
                          />
                          <div class="pgl-inner-box w-100 h-100">
                            <a href="javascript:void(0)" class="pgl-img-box">
                              <img
                                onClick={(e) =>
                                  this.props.history.push(
                                    "/product-detail/" + p._id
                                  )
                                }
                                src={
                                  process.env.REACT_APP_S3URL + p.image[0].large
                                }
                                alt=""
                              />
                              {!localStorage.getItem("x-auth-token-seller") && (
                                <WishList
                                  handleWishlist={this.handleWishlist}
                                  p={p}
                                  addedToWishlist={p.addedToWishList}
                                  addToWishlist={this.props.addToWishlist}
                                  deleteWishlist={this.props.deleteWishlist}
                                  callBack={this.callBack}
                                  history={this.props.history}
                                />
                              )}
                            </a>
                            <div class="pgl-content-box">
                              <div class="pgl-price d-flex align-items-center">
                                ${" "}
                                <span>
                                  {" "}
                                  {p.price.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                                </span>
                              </div>
                              <Link
                                to={{
                                  pathname: "/product-detail/" + p._id,
                                  // state: this.props.stateData,
                                }}
                                className="pgl-title"
                              >
                                {p && p.name}
                              </Link>
                              <a
                                href="javascript:void(0)"
                                class="pgl-sub-text"
                                onClick={() =>
                                  this.props.history.push(
                                    `/store/${p.seller.seller_id}`
                                  )
                                }
                              >
                                <span>{p.seller && p.seller.company}</span>
                              </a>
                              <ul class="pgl-tags-list d-flex flex-wrap align-items-center">
                                {p.isPickupAvailable && (
                                  <li class="w-auto text-center">
                                    <div class="pgl-tags-box">Pickup</div>
                                  </li>
                                )}
                                {p.isShippingAvailable && (
                                  <li class="w-auto text-center">
                                    <div class="pgl-tags-box">Shipping</div>
                                  </li>
                                )}
                                {p.isDeliveryAvailable && (
                                  <li class="w-auto text-center">
                                    <div class="pgl-tags-box">Delivery</div>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>

        <div class="content-container grey-bg text-block-container">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="text-block-content">
                  <div class="tbc-text text-center">
                    <p>
                      <strong>EffiSim</strong> is a location based marketplace
                      designed to empower local businesses and createa positive
                      impact in the society by connecting local vendors and
                      customers conveniently and efficiently.
                    </p>
                  </div>
                  <div class="tbc-img">
                    <img class="d-none d-md-block" src={bannerImg} alt="" />
                    <img class="d-block d-md-none" src={mobBannerImg} alt="" />
                  </div>
                  <div class="tbc-btn">
                    <Link to="/products-listing" class="btn btn-blue">
                      Start Shopping
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container home-post-container">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="post-heading-text text-center">
                  <p>
                    Your go to place for Quality products and guaranteed
                    delivery experience that you can count on!
                  </p>
                </div>
                <ul class="post-list d-flex flex-wrap">
                  <li class="post-item">
                    <div class="post-box">
                      <div class="post-img">
                        <img src={postImg2} alt="" />
                      </div>
                      <div class="post-content">
                        <h6>
                          Conviniently select local products on the online
                          catalog
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li class="post-item">
                    <div class="post-box">
                      <div class="post-img">
                        <img src={postImg3} alt="" />
                      </div>
                      <div class="post-content">
                        <h6>
                          Get a personalized dashboard to manage orders with
                          comfort
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li class="post-item">
                    <div class="post-box">
                      <div class="post-img">
                        <img src={postImg1} alt="" />
                      </div>
                      <div class="post-content">
                        <h6>
                          Safe transaction supported by Stripe one very order
                          from your favorite vendors
                        </h6>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="grey-cta-container position-relative grey-bg overflow-hidden">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="grey-cta-box d-flex justify-content-between align-items-center">
                  <div class="cta-content">
                    <h3>Ready to shop?</h3>
                    <p>Start shopping with us from your go to vendors!</p>
                    <div class="cta-btn">
                      <Link to="/products-listing" class="btn btn-default">
                        Shop Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grey-cta-inner">
            <Lottie
              loop
              animationData={shopBanner}
              speed="1"
              play
              background="transparent"
            />
          </div>
        </div>
        <div class="blue-cta-container position-relative overflow-hidden">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="blue-cta-box  d-flex justify-content-between align-items-center ml-auto">
                  <div class="cta-content text-right w-100">
                    <h3>Ready to sell?</h3>
                    <p>
                      Setup a sellers account and connect with your local
                      neighbourhood customers and consumers!
                    </p>
                    <div class="cta-btn">
                      <Link
                        to="/seller/registration"
                        target="_blank"
                        class="btn btn-white"
                      >
                        Sell Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cta-inner">
            <Lottie
              loop
              animationData={sellBanner}
              speed="1"
              play
              background="transparent"
            />
          </div>
        </div>

        <FooterNew />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: getProduct(state).categories,
  getSeller: getSeller(state),
  product: getProduct(state).product,
  pagination: getProduct(state).pagination,
  sellers: getProduct(state).sellers,
  getCustomer: getCustomer(state),
  loading: getProduct(state).loading,
});

const mapDispatchToProps = (dispatch) => ({
  loadAllProducts: (data) => dispatch(loadAllProducts(data)),
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
  loadCart: (id, data) => dispatch(loadCart(id, data)),
  loadCategories: (url, params) => dispatch(loadCategories(url, params)),
  addToWishlist: (data, callback) => dispatch(addToWishlist(data, callback)),
  deleteWishlist: (data, callback) => dispatch(deleteWishlist(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductListNew)
);

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyDHZd1NTh7pJw6-zGe1Mf_cUBx2cIhz46Y",
// })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductList)));
