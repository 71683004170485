import React, { Component, createRef } from "react";

import email from "../../include/images/mail.svg";
import phone from "../../include/images/phone.svg";
import heartIcon from "../../include/images/outline-heart.svg";
import location from "../../include/images/location.svg";
import Breadcrumb from "./breadcrumb";
import Header from "./header";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Contact from "../common/contact";
import { getProduct, loadProductDetail } from "../../store/product";
import { addToWishlist } from "../../store/customer";

import { connect } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import shipment from "./../../include/images/shipment-1.svg";
import packet from "./../../include/images/packet-1.svg";
import AddToCart from "./addToCart";

import { toast } from "react-toastify";
import AlertSuccess from "../common/alertSuccess";
import AlertError from "../common/alertError";
import { getCustomerToken } from "../services/localStorageServices";
import { getCustomer, loadCustomerProfile } from "./../../store/customer";

import StarRatings from "react-star-ratings";
import { Line } from "rc-progress";
import AllReviews from "./allReviews";
import SellerReviews from "./sellerReviews";

import wishlistIcon from "./../../include/images/fav-icon.svg";
import Copyright from "../common/copyright";
import LoadingBar from "react-top-loading-bar";

class Product extends Component {
  ref = createRef(null);

  state = {
    interVal: 0,
    productId: "",
    tabPane: "a",
    photoIndex: 0,
    sliderIndex: 0,
    isOpen: false,
    isContact: false,
    isHours: false,
    aboutShow: false,
    sellerURL: "",
    isReviews: false,
    isSellerReviews: false,
    addedToWishlist: false,
  };

  toggleReviews = (id) => {
    this.setState({
      isReviews: !this.state.isReviews,
    });
    this.props.loadProductDetail(id);
    document.body.classList.add("toggled-tab");
  };

  toggleCloseReviews = () => {
    this.setState({
      reviews: !this.state.reviews,
      isReviews: !this.state.isReviews,
    });
    document.body.classList.remove("toggled-tab");
  };

  toggleSellerReviews = (id) => {
    this.setState({
      isSellerReviews: !this.state.isSellerReviews,
    });
    this.props.loadProductDetail(id);
    document.body.classList.add("toggled-tab");
  };

  toggleCloseSellerReviews = () => {
    this.setState({
      // reviews: !this.state.reviews,
      isSellerReviews: !this.state.isSellerReviews,
    });
    document.body.classList.remove("toggled-tab");
  };

  setProductId = (productId) => this.setState({ productId });

  toggleContact = () => {
    this.setState({
      isContact: !this.state.isContact,
    });
  };

  toggleHours = () => {
    this.setState({
      isHours: !this.state.isHours,
    });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.props.loadProductDetail(this.props.match.params.id);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.detailLoading &&
      this.props.detailLoading !== prevProps.detailLoading
    ) {
      this.ref.current.complete();
      setTimeout(() => {
        this.setState({ interVal: this.state.interVal + 1 });
      }, 1000);
    }
  };

  nextClick = (e) => {
    this.setState({ photoIndex: e });
  };
  tConv24 = (time24) => {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h;
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  };

  handleWishlist = (id) => {
    if (getCustomerToken("refresh")) {
      const params = {
        product: id,
      };
      this.props.addToWishlist(params, this.callBack);
    } else {
      this.props.history.push("/customer/login");
    }
  };

  callBack = (res) => {
    if (res.status === 200) {
      toast(<AlertSuccess message={"Added to wishlist"} />);
      this.setState({
        addedToWishlist: !this.state.addedToWishlist,
      });
    } else {
      toast(<AlertError message={"Failed"} />);
    }
  };

  setKeyword = (keyword) => {
    console.log(this.props.location.state);

    // const filters = { ...this.state.filters };
    // filters.keyword = keyword;
    // this.setState({ filters }, () =>
    //   this.props.loadAllProducts(this.filterData())
    // );
  };

  render() {
    const { photoIndex, isOpen } = this.state;
    const {
      name,
      price,
      quantity,
      description,
      image,
      seller,
      _id,
      total_reviews,
      reviews,
      average_ratings,
      average_reviews,
      addedToWishList,
    } = this.props.detail;

    const settings = {
      lazyLoad: true,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      className:
        "home-services-list home-service-slider d-flex flex-wrap add-orange-shape slick-initialized slick-slider",
      afterChange: () =>
        this.setState((state) => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
    };
    var d = new Date();
    var days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    const day = days[d.getDay()];

    const toDay = days[new Date().getDay()];
    return (
      <>
        <LoadingBar color="#0b4474" ref={this.ref} />
        {/* <Header setKeyword={this.setKeyword} productList={true} /> */}
        <div
          id="main"
          onClick={(e) => {
            this.setProductId("");
          }}
        >
          <Breadcrumb states={this.props.location.state} />
          {!this.props.detailLoading && (
            <>
              <div className="product-container">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="product-img">
                        <div className="product-slide simple-slider">
                          <img
                            src={
                              process.env.REACT_APP_S3URL +
                              image[this.state.sliderIndex].large
                            }
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.setState({
                                isOpen: true,
                              })
                            }
                            alt="One"
                            id="#threes"
                          />
                        </div>
                        {isOpen && (
                          <Lightbox
                            mainSrc={
                              image[photoIndex]
                                ? process.env.REACT_APP_S3URL +
                                  image[photoIndex].original
                                : ""
                            }
                            nextSrc={
                              image.length === photoIndex + 1
                                ? false
                                : image[photoIndex + 1]
                            }
                            prevSrc={
                              photoIndex === 0 ? false : image[photoIndex - 1]
                            }
                            onCloseRequest={() =>
                              this.setState({ isOpen: false, photoIndex: 0 })
                            }
                            onMovePrevRequest={() =>
                              this.setState({
                                photoIndex: photoIndex - 1,
                              })
                            }
                            onMoveNextRequest={() =>
                              this.setState({
                                photoIndex: photoIndex + 1,
                              })
                            }
                          />
                        )}
                        {image && image.length > 1 && (
                          <div className="thumbnail-list  d-flex justify-content-center align-items-center">
                            {image.map((e1, i) => (
                              <div
                                className={
                                  this.state.sliderIndex === i
                                    ? "thumbnail-item active"
                                    : "thumbnail-item"
                                }
                                onClick={(e) => {
                                  this.setState({
                                    sliderIndex: i,
                                    photoIndex: i,
                                  });
                                }}
                              >
                                <img
                                  src={
                                    e1
                                      ? process.env.REACT_APP_S3URL + e1.small
                                      : ""
                                  }
                                  alt="One"
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="product-details">
                        <div className="product-list">
                          <div className="product-title">
                            <h4>{name}</h4>
                            <div className="star-list">
                              {average_ratings && average_ratings ? (
                                <StarRatings
                                  rating={average_ratings}
                                  starDimension="20px"
                                  starSpacing="3px"
                                  isSelectable="false"
                                  starEmptyColor="#C4C4C4"
                                  starRatedColor="#ffa51e"
                                  starHoverColor="#ffa51e"
                                />
                              ) : (
                                <StarRatings
                                  rating={0}
                                  starDimension="20px"
                                  starSpacing="3px"
                                  isSelectable="false"
                                  starEmptyColor="#C4C4C4"
                                  starRatedColor="#ffa51e"
                                  starHoverColor="#ffa51e"
                                />
                              )}
                              <span className="reviews-text custom-rating">
                                {/* {average_ratings && average_ratings}{" "} */}
                                <small>
                                  ({total_reviews && total_reviews})
                                </small>{" "}
                              </span>
                            </div>
                            <div className="product-price">
                              <small className="mr-1">$</small>
                              {price}{" "}
                            </div>
                          </div>
                          <div className="avability">
                            {" "}
                            Available Qty {quantity}
                          </div>
                          <ul className="available-list">
                            {seller && seller.delivery.pickup.pickup && (
                              <li>
                                <div className="al-icon">
                                  <img src={packet} alt="" />
                                </div>
                                <div className="available-text-box">
                                  Pickup - Available
                                </div>
                              </li>
                            )}
                            {seller && seller.delivery.delivery_option && (
                              <li>
                                <div className="al-icon">
                                  <img src={shipment} alt="" />
                                </div>
                                <div className="available-text-box">
                                  {`${
                                    seller.delivery.delivery_option
                                      .delivery_option
                                      ? "Delivery - "
                                      : ""
                                  }${
                                    seller.delivery.delivery_option
                                      .delivery_option_value
                                  }`}
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                        <div className="product-list">
                          <div className="product-description">
                            <h5>Product Description </h5>
                          </div>
                          <ul className="prod-desc">
                            <li>{description}</li>
                          </ul>
                        </div>
                        <div className="add-to-cart-dropdown-box list-dropdown-box d-flex">
                          {!localStorage.getItem("x-auth-token-seller") && (
                            <a
                              onClick={() => {
                                this.handleWishlist(_id);
                              }}
                              href="javascript:void(0)"
                              className="border-btn"
                            >
                              <img
                                src={
                                  addedToWishList || this.state.addedToWishlist
                                    ? wishlistIcon
                                    : heartIcon
                                }
                                alt=""
                              />
                            </a>
                          )}

                          {this.props.detail.seller && (
                            <AddToCart
                              data={this.props.detail}
                              setProductId={this.setProductId}
                              productId={this.state.productId}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-container product-details-container">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <ul
                        id="tabs"
                        className="nav nav-tabs product-details-tab"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <a
                            id="tab-A"
                            href="#pane-A"
                            className={
                              this.state.tabPane === "a"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            role="tab"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ tabPane: "a" });
                            }}
                          >
                            Seller Details
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            id="tab-B"
                            href="#pane-B"
                            className={
                              this.state.tabPane === "b"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            role="tab"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ tabPane: "b" });
                            }}
                          >
                            Hours of Operation
                          </a>
                        </li>
                        {reviews && reviews.length !== 0 && (
                          <li className="nav-item">
                            <a
                              id="tab-C"
                              href="#pane-C"
                              className={
                                this.state.tabPane === "c"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-toggle="tab"
                              role="tab"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ tabPane: "c" });
                              }}
                            >
                              Customer Reviews--
                            </a>
                          </li>
                        )}
                        {seller &&
                          (seller.refundPolicy || seller.returnPolicy) && (
                            <li className="nav-item">
                              <a
                                id="tab-D"
                                href="#pane-D"
                                className={
                                  this.state.tabPane === "d"
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                data-toggle="tab"
                                role="tab"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({ tabPane: "d" });
                                }}
                              >
                                Refund/Return Policy
                              </a>
                            </li>
                          )}
                      </ul>
                      <div id="content" className="tab-content" role="tablist">
                        <div
                          id="pane-A"
                          className={
                            this.state.tabPane === "a"
                              ? "card tab-pane fade show active"
                              : "card tab-pane fade show"
                          }
                          role="tabpanel"
                          aria-labelledby="tab-A"
                        >
                          <div
                            className="card-header"
                            role="tab"
                            id="heading-A"
                          >
                            <h5 className="mb-0">
                              <a
                                data-toggle="collapse"
                                href="#collapse-A"
                                aria-expanded="true"
                                aria-controls="collapse-A"
                              >
                                Seller Details
                              </a>
                            </h5>
                          </div>
                          <div
                            id="collapse-A"
                            className="collapse show"
                            data-parent="#content"
                            role="tabpanel"
                            aria-labelledby="heading-A"
                          >
                            <div className="card-body">
                              <div className="seller-man d-flex justify-content-start align-items-center">
                                <div className="pd-profile-box"></div>
                                <div className="seller-profile-name d-flex flex-column align-items center">
                                  <span>{seller && seller.company}</span>
                                </div>
                              </div>
                              <div className="star-list">
                                {seller && seller.average_ratings ? (
                                  <StarRatings
                                    rating={seller.average_ratings}
                                    starDimension="20px"
                                    starSpacing="3px"
                                    isSelectable="false"
                                    starEmptyColor="#C4C4C4"
                                    starRatedColor="#ffa51e"
                                    starHoverColor="#ffa51e"
                                  />
                                ) : (
                                  <StarRatings
                                    rating={0}
                                    starDimension="20px"
                                    starSpacing="3px"
                                    isSelectable="false"
                                    starEmptyColor="#C4C4C4"
                                    starRatedColor="#ffa51e"
                                    starHoverColor="#ffa51e"
                                  />
                                )}
                                <span
                                  className="reviews-text custom-rating"
                                  onClick={() => this.toggleSellerReviews(_id)}
                                >
                                  {/* {average_ratings && average_ratings}{" "} */}
                                  <small>
                                    ({seller && seller.total_reviews})
                                  </small>{" "}
                                </span>
                              </div>
                              <ul className="seller-details">
                                <li>
                                  <img alt="" src={email} />
                                  {seller && seller.email}
                                </li>
                                <li>
                                  <img alt="" src={phone} />
                                  {seller && seller.mobile}
                                </li>{" "}
                                {seller && (
                                  <li>
                                    <img alt="" src={location} />
                                    {seller.address.street1}
                                    {seller.address.street2 &&
                                      ", " + seller.address.street2}
                                    {seller.address.city &&
                                      ", " + seller.address.city}

                                    {seller.address.state &&
                                      ", " + seller.address.state}
                                    {seller.address.zip &&
                                      ", " + seller.address.zip}
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div
                          id="pane-B"
                          className={
                            this.state.tabPane === "b"
                              ? "card tab-pane fade show active"
                              : "card tab-pane fade show"
                          }
                          role="tabpanel"
                          aria-labelledby="tab-B"
                        >
                          <div
                            className="card-header"
                            role="tab"
                            id="heading-B"
                          >
                            <h5 className="mb-0">
                              <a
                                className="collapsed"
                                data-toggle="collapse"
                                href="#collapse-B"
                                aria-expanded="false"
                                aria-controls="collapse-B"
                              >
                                Hours of Operation
                              </a>
                            </h5>
                          </div>
                          <div
                            id="collapse-B"
                            className="collapse"
                            data-parent="#content"
                            role="tabpanel"
                            aria-labelledby="heading-B"
                          >
                            <div className="card-body">
                              <ul className="hrs-operation">
                                {/* <li>
                              <h6>Hours of Operation</h6>
                            </li> */}
                                {seller &&
                                seller.schedules &&
                                !seller.schedules.mon ? (
                                  <li>
                                    <p className="day">Monday</p>
                                    <p className="date">
                                      {seller &&
                                      seller.schedules &&
                                      seller.schedules.tbd === true
                                        ? "TBD"
                                        : "Closed"}
                                    </p>
                                  </li>
                                ) : (
                                  <li>
                                    <p className="day">Monday</p>
                                    <p className="date">
                                      <ul className="product-hors">
                                        {seller &&
                                          seller.schedules &&
                                          seller.schedules.mon.map((e, i) => (
                                            <li className="product-hors">
                                              {this.tConv24(
                                                e.start_time
                                                  .toString()
                                                  .replace(/(..)/g, "$1:")
                                                  .slice(0, -1)
                                              )}{" "}
                                              -{" "}
                                              {this.tConv24(
                                                e.end_time
                                                  .toString()
                                                  .replace(/(..)/g, "$1:")
                                                  .slice(0, -1)
                                              )}
                                            </li>
                                          ))}
                                      </ul>
                                    </p>
                                  </li>
                                )}
                                {seller &&
                                seller.schedules &&
                                !seller.schedules.tue ? (
                                  <li>
                                    <p className="day">Tuesday</p>
                                    <p className="date">
                                      {seller &&
                                      seller.schedules &&
                                      seller.schedules.tbd === true
                                        ? "TBD"
                                        : "closed"}
                                    </p>
                                  </li>
                                ) : (
                                  <li>
                                    <p className="day">Tuesday</p>
                                    <p className="date">
                                      <ul className="product-hors">
                                        {seller &&
                                          seller.schedules &&
                                          seller.schedules.tue.map((e, i) => (
                                            <li className="product-hors">
                                              {this.tConv24(
                                                e.start_time
                                                  .toString()
                                                  .replace(/(..)/g, "$1:")
                                                  .slice(0, -1)
                                              )}{" "}
                                              -{" "}
                                              {this.tConv24(
                                                e.end_time
                                                  .toString()
                                                  .replace(/(..)/g, "$1:")
                                                  .slice(0, -1)
                                              )}
                                            </li>
                                          ))}
                                      </ul>
                                    </p>
                                  </li>
                                )}
                                {seller &&
                                seller.schedules &&
                                !seller.schedules.wed ? (
                                  <li>
                                    <p className="day">Wednesday</p>
                                    <p className="date">
                                      {seller &&
                                      seller.schedules &&
                                      seller.schedules.tbd === true
                                        ? "TBD"
                                        : "closed"}
                                    </p>
                                  </li>
                                ) : (
                                  <li>
                                    <p className="day">Wednesday</p>
                                    <p className="date">
                                      <ul className="product-hors">
                                        {seller &&
                                          seller.schedules &&
                                          seller.schedules.wed.map((e, i) => (
                                            <li className="product-hors">
                                              {this.tConv24(
                                                e.start_time
                                                  .toString()
                                                  .replace(/(..)/g, "$1:")
                                                  .slice(0, -1)
                                              )}{" "}
                                              -{" "}
                                              {this.tConv24(
                                                e.end_time
                                                  .toString()
                                                  .replace(/(..)/g, "$1:")
                                                  .slice(0, -1)
                                              )}
                                            </li>
                                          ))}
                                      </ul>
                                    </p>
                                  </li>
                                )}
                                {seller &&
                                seller.schedules &&
                                !seller.schedules.thu ? (
                                  <li>
                                    <p className="day">Thursday</p>
                                    <p className="date">
                                      {seller &&
                                      seller.schedules &&
                                      seller.schedules.tbd === true
                                        ? "TBD"
                                        : "closed"}
                                    </p>
                                  </li>
                                ) : (
                                  <li>
                                    <p className="day">Thursday</p>
                                    <p className="date">
                                      <ul className="product-hors">
                                        {seller &&
                                          seller.schedules &&
                                          seller.schedules.thu.map((e, i) => (
                                            <li className="product-hors">
                                              {this.tConv24(
                                                e.start_time
                                                  .toString()
                                                  .replace(/(..)/g, "$1:")
                                                  .slice(0, -1)
                                              )}{" "}
                                              -{" "}
                                              {this.tConv24(
                                                e.end_time
                                                  .toString()
                                                  .replace(/(..)/g, "$1:")
                                                  .slice(0, -1)
                                              )}
                                            </li>
                                          ))}
                                      </ul>
                                    </p>
                                  </li>
                                )}
                                {seller &&
                                seller.schedules &&
                                !seller.schedules.fri ? (
                                  <li>
                                    <p className="day">Friday</p>
                                    <p className="date">
                                      {seller &&
                                      seller.schedules &&
                                      seller.schedules.tbd === true
                                        ? "TBD"
                                        : "closed"}
                                    </p>
                                  </li>
                                ) : (
                                  <li>
                                    <p className="day">Friday</p>
                                    <p className="date">
                                      <ul className="product-hors">
                                        {seller &&
                                          seller.schedules &&
                                          seller.schedules.fri.map((e, i) => (
                                            <li className="product-hors">
                                              {this.tConv24(
                                                e.start_time
                                                  .toString()
                                                  .replace(/(..)/g, "$1:")
                                                  .slice(0, -1)
                                              )}{" "}
                                              -{" "}
                                              {this.tConv24(
                                                e.end_time
                                                  .toString()
                                                  .replace(/(..)/g, "$1:")
                                                  .slice(0, -1)
                                              )}
                                            </li>
                                          ))}
                                      </ul>
                                    </p>
                                  </li>
                                )}
                                {seller &&
                                seller.schedules &&
                                !seller.schedules.sat ? (
                                  <li>
                                    <p className="day">Saturday</p>
                                    <p className="date">
                                      {seller &&
                                      seller.schedules &&
                                      seller.schedules.tbd === true
                                        ? "TBD"
                                        : "closed"}
                                    </p>
                                  </li>
                                ) : (
                                  <li>
                                    <p className="day">Saturday</p>
                                    <p className="date">
                                      <ul className="product-hors">
                                        {seller &&
                                          seller.schedules &&
                                          seller.schedules.sat.map((e, i) => (
                                            <li className="product-hors">
                                              {this.tConv24(
                                                e.start_time
                                                  .toString()
                                                  .replace(/(..)/g, "$1:")
                                                  .slice(0, -1)
                                              )}{" "}
                                              -{" "}
                                              {this.tConv24(
                                                e.end_time
                                                  .toString()
                                                  .replace(/(..)/g, "$1:")
                                                  .slice(0, -1)
                                              )}
                                            </li>
                                          ))}
                                      </ul>
                                    </p>
                                  </li>
                                )}
                                {seller &&
                                seller.schedules &&
                                !seller.schedules.sun ? (
                                  <li>
                                    <p className="day">Sunday</p>
                                    <p className="date">
                                      {seller &&
                                      seller.schedules &&
                                      seller.schedules.tbd === true
                                        ? "TBD"
                                        : "closed"}
                                    </p>
                                  </li>
                                ) : (
                                  <li>
                                    <p className="day">Sunday</p>
                                    <p className="date">
                                      <ul className="product-hors">
                                        {seller &&
                                          seller.schedules &&
                                          seller.schedules.sun.map((e, i) => (
                                            <li className="product-hors">
                                              {this.tConv24(
                                                e.start_time
                                                  .toString()
                                                  .replace(/(..)/g, "$1:")
                                                  .slice(0, -1)
                                              )}{" "}
                                              -{" "}
                                              {this.tConv24(
                                                e.end_time
                                                  .toString()
                                                  .replace(/(..)/g, "$1:")
                                                  .slice(0, -1)
                                              )}
                                            </li>
                                          ))}
                                      </ul>
                                    </p>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div
                          id="pane-C"
                          className={
                            this.state.tabPane === "c"
                              ? "card tab-pane fade show active"
                              : "card tab-pane fade show"
                          }
                          role="tabpanel"
                          aria-labelledby="tab-C"
                        >
                          <div
                            className="card-header"
                            role="tab"
                            id="heading-C"
                          >
                            <h5 className="mb-0">
                              <a
                                className="collapsed"
                                data-toggle="collapse"
                                href="#collapse-C"
                                aria-expanded="false"
                                aria-controls="collapse-C"
                              >
                                Customer Reviews---2
                              </a>
                            </h5>
                          </div>
                          <div
                            id="collapse-C"
                            className="collapse"
                            role="tabpanel"
                            data-parent="#content"
                            aria-labelledby="heading-C"
                          >
                            <div className="card-body w-100">
                              <div className="customer-reviews-box">
                                <div className="cr-head d-flex align-items-center justify-content-between">
                                  <div className="star-list">
                                    {average_ratings ? (
                                      <StarRatings
                                        rating={average_ratings}
                                        starDimension="20px"
                                        starSpacing="3px"
                                        isSelectable="false"
                                        starEmptyColor="#C4C4C4"
                                        starRatedColor="#ffa51e"
                                        starHoverColor="#ffa51e"
                                      />
                                    ) : (
                                      <StarRatings
                                        rating={0}
                                        starDimension="20px"
                                        starSpacing="3px"
                                        isSelectable="false"
                                        starEmptyColor="#C4C4C4"
                                        starRatedColor="#ffa51e"
                                        starHoverColor="#ffa51e"
                                      />
                                    )}
                                    <span className="reviews-text custom-rating">
                                      {/* {average_ratings && average_ratings}{" "} */}
                                      <small>
                                        ({total_reviews && total_reviews})
                                      </small>{" "}
                                    </span>
                                  </div>
                                  {reviews && reviews.length !== 0 && (
                                    <div>
                                      <a
                                        href="javascript:void(0)"
                                        onClick={() => this.toggleReviews(_id)}
                                        className="btn btn-link-text physicalVisit"
                                      >
                                        View all reviews
                                      </a>
                                    </div>
                                  )}
                                </div>
                                <div className="cr-body">
                                  <div className="cr-progress-box d-flex flex-wrap">
                                    {/* {average_reviews && ( */}
                                    <div className="cr-progress-left">
                                      <div className="crp-item d-flex flex-wrap align-items-center">
                                        <span>5 Star</span>
                                        <div className="progress">
                                          <Line
                                            percent={
                                              average_reviews
                                                ? average_reviews.five
                                                    .percentage
                                                : 0
                                            }
                                            trailColor="#e9ecef"
                                            strokeColor={
                                              average_reviews &&
                                              average_reviews.five
                                                .percentage !== 0
                                                ? "#FFC531"
                                                : "#e9ecef"
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="crp-item d-flex flex-wrap align-items-center">
                                        <span>4 Star</span>
                                        <div className="progress">
                                          <Line
                                            percent={
                                              average_reviews
                                                ? average_reviews.four
                                                    .percentage
                                                : 0
                                            }
                                            trailColor="#e9ecef"
                                            strokeColor={
                                              average_reviews &&
                                              average_reviews.four
                                                .percentage !== 0
                                                ? "#FFC531"
                                                : "#e9ecef"
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="crp-item d-flex flex-wrap align-items-center">
                                        <span>3 Star</span>
                                        <div className="progress">
                                          <Line
                                            percent={
                                              average_reviews
                                                ? average_reviews.three
                                                    .percentage
                                                : 0
                                            }
                                            trailColor="#e9ecef"
                                            strokeColor={
                                              average_reviews &&
                                              average_reviews.three
                                                .percentage !== 0
                                                ? "#FFC531"
                                                : "#e9ecef"
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="crp-item d-flex flex-wrap align-items-center">
                                        <span>2 Star</span>
                                        <div className="progress">
                                          <Line
                                            percent={
                                              average_reviews
                                                ? average_reviews.two.percentage
                                                : 0
                                            }
                                            trailColor="#e9ecef"
                                            strokeColor={
                                              average_reviews &&
                                              average_reviews.two.percentage !==
                                                0
                                                ? "#FFC531"
                                                : "#e9ecef"
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="crp-item d-flex flex-wrap align-items-center">
                                        <span>1 Star</span>
                                        <div className="progress">
                                          <Line
                                            percent={
                                              average_reviews
                                                ? average_reviews.one.percentage
                                                : 0
                                            }
                                            trailColor="#e9ecef"
                                            strokeColor={
                                              average_reviews &&
                                              average_reviews.one.percentage !==
                                                0
                                                ? "#FFC531"
                                                : "#e9ecef"
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {/* )} */}
                                    {reviews && reviews.length !== 0 && (
                                      <>
                                        {" "}
                                        <div className="cr-progress-right">
                                          <div className="star-list">
                                            {reviews[0].rating ? (
                                              <StarRatings
                                                rating={
                                                  reviews && reviews[0].rating
                                                }
                                                starDimension="20px"
                                                starSpacing="3px"
                                                isSelectable="false"
                                                starEmptyColor="#C4C4C4"
                                                starRatedColor="#ffa51e"
                                                starHoverColor="#ffa51e"
                                              />
                                            ) : (
                                              <StarRatings
                                                rating={0}
                                                starDimension="20px"
                                                starSpacing="3px"
                                                isSelectable="false"
                                                starEmptyColor="#C4C4C4"
                                                starRatedColor="#ffa51e"
                                                starHoverColor="#ffa51e"
                                              />
                                            )}
                                          </div>

                                          {reviews[0].customer_first_name &&
                                            reviews[0].customer_last_name && (
                                              <p>
                                                By{" "}
                                                {reviews[0]
                                                  .customer_first_name +
                                                  " " +
                                                  reviews[0].customer_last_name}
                                              </p>
                                            )}
                                          <h6>
                                            {reviews[0].subject &&
                                              reviews[0].subject}
                                          </h6>
                                          <p>
                                            {reviews[0].content &&
                                              reviews[0].content}
                                          </p>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {seller &&
                          (seller.refundPolicy || seller.returnPolicy) && (
                            <div
                              id="pane-D"
                              className={
                                this.state.tabPane === "d"
                                  ? "card tab-pane fade show active"
                                  : "card tab-pane fade show"
                              }
                              role="tabpanel"
                              aria-labelledby="tab-D"
                            >
                              <div
                                className="card-header"
                                role="tab"
                                id="heading-D"
                              >
                                <h5 className="mb-0">
                                  <a
                                    className="collapsed"
                                    data-toggle="collapse"
                                    href="#collapse-D"
                                    aria-expanded="false"
                                    aria-controls="collapse-D"
                                  >
                                    Refund/Return Policy
                                  </a>
                                </h5>
                              </div>
                              <div
                                id="collapse-D"
                                className="collapse"
                                role="tabpanel"
                                data-parent="#content"
                                aria-labelledby="heading-D"
                              >
                                <div className="card-body">
                                  <p>
                                    {seller.returnPolicy && (
                                      <>
                                        <h6>Return Policy:</h6>
                                        <br />
                                        {seller.returnPolicy}
                                        <br />
                                        <br />
                                      </>
                                    )}
                                    {seller.refundPolicy && (
                                      <>
                                        <h6>Refund Policy:</h6>
                                        <br />
                                        {seller.refundPolicy}
                                      </>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <AllReviews
                detail={this.props.detail}
                isReviews={this.state.isReviews}
                toggleReviews={this.toggleReviews}
                handleClose={this.toggleCloseReviews}
              />
              <SellerReviews
                detail={this.props.detail}
                isSellerReviews={this.state.isSellerReviews}
                toggleReviews={this.toggleSellerReviews}
                handleClose={this.toggleCloseSellerReviews}
              />
              {this.state.isContact && (
                <Contact toggleContact={this.toggleContact} />
              )}
              <footer className="footer" id="footer">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="footer-content text-center">
                        <Copyright />
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </>
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  detail: getProduct(state).detail,
  detailLoading: getProduct(state).detailLoading,
  customerProfile: getCustomer(state).profile,
  profileLoading: getCustomer(state).profileLoading,
});
const mapDispatchToProps = (dispatch) => ({
  addToWishlist: (data, callback) => dispatch(addToWishlist(data, callback)),
  loadProductDetail: (id) => dispatch(loadProductDetail(id)),
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Product);
