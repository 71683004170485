import React, { Component } from "react";

class LoginPassword extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.state = {
      render: false,
      isPasswordShown: false,
      formclass: "form-group custom-input",
    };
    this.passwordMessage = this.passwordMessage.bind(this);
  }

  passwordMessage(e) {
    e.preventDefault();
    this.setState({ render: !this.state.render });
  }

  handleFocus = () => {
    this.setState({ formclass: "form-group custom-input show on" });
  };

  handleBlur = (e) => {
    const classname = e.target.value
      ? "form-group custom-input"
      : "form-group custom-input show on";
    this.setState({ formclass: classname });
  };

  toggleShowPassword = (e) => {
    this.setState({ isPasswordShown: !this.state.isPasswordShown });
    this.input.current.type = this.state.isPasswordShown ? "password" : "text";
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const classname = this.props.value
        ? "form-group custom-input"
        : "form-group custom-input show on";
      this.setState({ formclass: classname });
    }
  }

  render() {
    const { name, label, error, ...rest } = this.props;

    return (
      <div>
        <label
          htmlFor={name}
          className="d-flex align-items-center justify-content-between"
        >
          {label}
        </label>

        <div className={this.state.formclass}>
            <input
              name={name}
              id={name}
              className={error ? "form-control input-data error" : "form-control input-data"}
              type="password"
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              ref={this.input}
              {...rest}
            />
            {error && <label className="error">{error}</label>}
        </div>
      </div>
    );
  }
}

export default LoginPassword;
