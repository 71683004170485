import React, { Component } from "react";
import heartIcon from "./../../include/images/dark-heart.svg";
import favIcon from "../../include/images/blue-heart-icon.svg";
import { getCustomerToken } from "../services/localStorageServices";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";

class WishList extends Component {
  state = { added: false };

  componentDidMount = () => {
    this.setState({ added: this.props.p.addedToWishList });
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.p &&
      this.props.p.addedToWishList != prevProps.p.addedToWishList
    ) {
      this.setState({ added: this.props.p.addedToWishList });
    }
  };
  handleWishlist = (id, p) => {
    this.setState({ added: !this.state.added });
    if (getCustomerToken("refresh")) {
      const params = {
        product: id,
      };
      if (!p) this.props.addToWishlist(params, this.props.callBack);
      else this.props.deleteWishlist(params, this.props.callBack);
    } else {
      this.props.history.push("/customer/login");
    }
  };
  render() {
    return (
      // <a
      //   href="javascript:void(0)"
      //   onClick={() => {
      //     this.handleWishlist(this.props.p._id, this.props.p.addedToWishList);
      //   }}
      //   class="border-btn d-none d-md-block d-md-flex"
      // >
      //   <img src={this.state.added ? favIcon : heartIcon} alt="" />
      // </a>
      <div
        class="fav-icon-block"
        onClick={() => {
          this.handleWishlist(this.props.p._id, this.props.p.addedToWishList);
        }}
      >
        <img src={this.state.added ? favIcon : heartIcon} alt="" />
      </div>
    );
  }
}
export default WishList;
