import React, { Component } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import wishlistIcon from "./../../include/images/fav-icon.svg";
import heartIcon from "./../../include/images/dark-heart.svg";
import favIcon from "../../include/images/blue-heart-icon.svg";

import AddToCart from "./addToCart";
import StarRatings from "react-star-ratings";

import { Link, withRouter } from "react-router-dom";

import { addToWishlist } from "../../store/customer";
import { connect } from "react-redux";

import { toast } from "react-toastify";
import AlertSuccess from "../common/alertSuccess";
import AlertError from "../common/alertError";
import { getCustomerToken } from "../services/localStorageServices";
import sampleImg from "./../../include/images/product-1.jpg";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import AddBtnNew from "./addBtnNew";

class ProductGridB extends Component {
  state = {
    added: false,
  };
  componentDidMount = () => {
    this.setState({ added: this.props.data.addedToWishList });
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.data &&
      this.props.data.addedToWishList != prevProps.data.addedToWishList
    ) {
      this.setState({ added: this.props.data.addedToWishList });
    }
  };

  handleWishlist = (id, p) => {
    this.setState({ added: !this.state.added });
    if (getCustomerToken("refresh")) {
      const params = {
        product: id,
      };
      if (!p) this.props.addToWishlist(params, this.props.callBack);
      else this.props.deleteWishlist(params, this.props.callBack);
    } else {
      this.props.history.push("/customer/login");
    }
  };

  render() {
    const e = this.props.data;
    return (
      // <li class="list-item">
      //   {/* <Link to={"/product-detail/" + e._id}> */}
      //   <div class="list-box d-flex">
      //     <div
      //       class="list-img"
      //       style={{ cursor: "pointer" }}
      //       onClick={
      //         (er) => {
      //           er.preventDefault();
      //           this.props.history.push({
      //             pathname: "/product-detail/" + e._id,
      //             state: this.props.stateData,
      //           });
      //         }
      //         //window.open("/product-detail/" + e._id, "_blank").focus()
      //       }
      //     >
      //       <img
      //         src={
      //           e.image[0]
      //             ? process.env.REACT_APP_S3URL + e.image[0].medium
      //             : ""
      //         }
      //         alt=""
      //       />
      //     </div>
      //     <div class="list-details d-md-flex flex-md-wrap">
      //       <div class="list-details-header">
      //         <div
      //           class="list-details-star d-flex align-items-center"
      //           style={{ cursor: "pointer" }}
      // onClick={() =>
      //   this.props.history.push(`/store/${e.seller.seller_id}`)
      // }
      //         >
      //           <p class="seller-name">{e.seller.company}</p>
      //           {/* {e.seller.average_ratings && (
      //             <>
      //               <i class="fas fa-star"></i>
      //               <span class="reviews-text">{e.seller.average_ratings}</span>
      //             </>
      //           )} */}
      //         </div>
      //         <h3
      //           style={{ cursor: "pointer" }}
      //           onClick={(er) =>
      //             this.props.history.push({
      //               pathname: "/product-detail/" + e._id,
      //               state: this.props.stateData,
      //             })
      //           }
      //         >
      //           <LinesEllipsis
      //             text={e.name}
      //             maxLine="1"
      //             ellipsis="..."
      //             trimRight
      //             basedOn="letters"
      //           />
      //         </h3>
      //         <p class="list-details-text">
      //           <LinesEllipsis
      //             text={e.description}
      //             maxLine="2"
      //             ellipsis="..."
      //             trimRight
      //             basedOn="letters"
      //           />
      //         </p>
      //         <div class="star-list">
      // {e.average_ratings ? (
      //   <StarRatings
      //     rating={e.average_ratings}
      //     starDimension="20px"
      //     starSpacing="3px"
      //     isSelectable="false"
      //     starEmptyColor="#C4C4C4"
      //     starRatedColor="#ffa51e"
      //     starHoverColor="#ffa51e"
      //   />
      // ) : (
      //   <StarRatings
      //     rating={0}
      //     starDimension="20px"
      //     starSpacing="3px"
      //     isSelectable="false"
      //     starEmptyColor="#C4C4C4"
      //     starRatedColor="#ffa51e"
      //     starHoverColor="#ffa51e"
      //   />
      // )}
      //         </div>
      //         <ul class="dots-list">
      //           <ul className="dots-list">
      //             <li>
      //               {e.seller.delivery.pickup && "Pickup"}
      //               {e.seller.delivery.pickup &&
      //                 e.seller.delivery.delivery_option &&
      //                 ", "}
      //               {e.seller.delivery.delivery_option && "Delivery"}{" "}
      //               {e.seller.delivery.delivery_option &&
      //                 e.seller.delivery.delivery_option_value &&
      //                 " :" + " " + e.seller.delivery.delivery_option_value}
      //             </li>
      //           </ul>
      //         </ul>
      //       </div>
      //       <div class="list-bottom-details">
      //         <div class="list-price">
      //           <h4>
      //             ${" "}
      //             {e.price.toLocaleString(undefined, {
      //               minimumFractionDigits: 2,
      //             })}
      //           </h4>
      //           {/* <span class="avability">
      //             <span>Available</span> Qty {e.quantity}
      //           </span> */}
      //         </div>
      //         <div class="list-dropdown-box d-flex">
      //           {!localStorage.getItem("x-auth-token-seller") && (
      //             <a
      //               onClick={() => {
      //                 this.handleWishlist(e._id, e.addedToWishList);
      //               }}
      //               href="javascript:void(0)"
      //               className="border-btn"
      //             >
      //               <img
      //                 src={this.state.added ? wishlistIcon : heartIcon}
      //                 alt=""
      //               />
      //             </a>
      //           )}
      //           <AddToCart
      //             data={this.props.data}
      //             setProductId={this.props.setProductId}
      //             productId={this.props.productId}
      //           />
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      //   {/* </Link> */}
      // </li>
      <li class="pll-item">
        <div class="pll-box w-100 position-relative d-flex overflow-hidden">
          {/* <div class="add-button-outer  text-white d-inline-block">
            <div class="add-button-box d-flex align-items-center justify-content-center">
              <img src="include/images/white-plus-icon.svg" alt="" />
              Add
            </div>
            <div class="button-hover-content">
              <ul class="bhc-list d-flex align-items-center">
                <li class="bhc-item w-auto flex-fill">
                  <a class="bhc-link w-100 text-center" href="#!">
                    Pickup
                  </a>
                </li>
                <li class="bhc-item w-auto flex-fill">
                  <a class="bhc-link w-100 text-center" href="#!">
                    Delivery
                  </a>
                </li>
                <li class="bhc-item w-auto flex-fill">
                  <a class="bhc-link w-100 text-center" href="#!">
                    Shipping
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
          <AddBtnNew
            data={this.props.data}
            setProductId={this.props.setProductId}
            productId={this.props.productId}
            filters={this.props.filters}
          />
          <div class="pll-img-box">
            <div
              class="pll-img w-100"
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.props.history.push("/product-detail/" + e._id)
              }
            >
              <img
                src={process.env.REACT_APP_S3URL + e.image[0].large}
                alt=""
              />
            </div>
          </div>
          <div class="pll-text-box d-flex flex-column">
            <div class="pll-price d-flex align-items-center">
              ${" "}
              <span>
                {e.price.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
              </span>
            </div>
            {!localStorage.getItem("x-auth-token-seller") && (
              <div
                class="favicon-block"
                onClick={() => {
                  this.handleWishlist(e._id, e.addedToWishList);
                }}
              >
                <img src={this.state.added ? favIcon : heartIcon} alt="" />
              </div>
            )}
            <h5
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.props.history.push("/product-detail/" + e._id)
              }
            >
              {e.name}
            </h5>
            <p>
              <HTMLEllipsis
                unsafeHTML={e.description}
                maxLine="4"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </p>
            <div class="pll-bott-text mt-auto position-relative">
              <div class="pll-rating">
                {e.average_ratings ? (
                  <StarRatings
                    rating={e.average_ratings}
                    starDimension="20px"
                    starSpacing="3px"
                    isSelectable="false"
                    starEmptyColor="#C4C4C4"
                    starRatedColor="#ffa51e"
                    starHoverColor="#ffa51e"
                    svgIconViewBox="0 0 14 13"
                    svgIconPath="M6.29782 0.816984C6.66463 0.0737383 7.72447 0.07374 8.09129 0.816986L9.3702 3.40835C9.51586 3.70349 9.79743 3.90806 10.1231 3.95539L12.9829 4.37093C13.8031 4.49011 14.1306 5.49809 13.5371 6.07662L11.4678 8.09371C11.2321 8.32345 11.1245 8.65445 11.1802 8.97885L11.6687 11.827C11.8088 12.6439 10.9513 13.2669 10.2177 12.8812L7.65989 11.5365C7.36857 11.3833 7.02054 11.3833 6.72921 11.5365L4.17139 12.8812C3.43776 13.2669 2.58033 12.6439 2.72044 11.827L3.20894 8.97884C3.26458 8.65445 3.15703 8.32345 2.92134 8.09371L0.852018 6.07662C0.258501 5.49809 0.586013 4.49011 1.40623 4.37093L4.26597 3.95539C4.59168 3.90806 4.87324 3.70349 5.0189 3.40834L6.29782 0.816984Z"
                  />
                ) : (
                  <StarRatings
                    rating={0}
                    starDimension="20px"
                    starSpacing="3px"
                    isSelectable="false"
                    starEmptyColor="#C4C4C4"
                    starRatedColor="#ffa51e"
                    starHoverColor="#ffa51e"
                    svgIconViewBox="0 0 14 13"
                    svgIconPath="M6.29782 0.816984C6.66463 0.0737383 7.72447 0.07374 8.09129 0.816986L9.3702 3.40835C9.51586 3.70349 9.79743 3.90806 10.1231 3.95539L12.9829 4.37093C13.8031 4.49011 14.1306 5.49809 13.5371 6.07662L11.4678 8.09371C11.2321 8.32345 11.1245 8.65445 11.1802 8.97885L11.6687 11.827C11.8088 12.6439 10.9513 13.2669 10.2177 12.8812L7.65989 11.5365C7.36857 11.3833 7.02054 11.3833 6.72921 11.5365L4.17139 12.8812C3.43776 13.2669 2.58033 12.6439 2.72044 11.827L3.20894 8.97884C3.26458 8.65445 3.15703 8.32345 2.92134 8.09371L0.852018 6.07662C0.258501 5.49809 0.586013 4.49011 1.40623 4.37093L4.26597 3.95539C4.59168 3.90806 4.87324 3.70349 5.0189 3.40834L6.29782 0.816984Z"
                  />
                )}
              </div>
              <div
                style={{ cursor: "pointer" }}
                class="pll-sub-text"
                onClick={() =>
                  this.props.history.push(`/store/${e.seller.seller_id}`)
                }
              >
                <span>{e.seller.company}</span>
              </div>
              <ul class="pll-tags-list d-flex flex-wrap align-items-center">
                {e.isPickupAvailable && (
                  <li class="w-auto text-center">
                    <div class="pll-tags-box">Pickup</div>
                  </li>
                )}
                {e.isShippingAvailable && (
                  <li class="w-auto text-center">
                    <div class="pll-tags-box">Shipping</div>
                  </li>
                )}
                {e.isDeliveryAvailable && (
                  <li class="w-auto text-center">
                    <div class="pll-tags-box">Delivery</div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addToWishlist: (data, callback) => dispatch(addToWishlist(data, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(ProductGridB));
