import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import dummyImg from "./../../include/images/iphone.png";

class OrderSuccess extends Component {
  state = {};
  render() {
    return (
      <Modal
        show={this.props.modelShow}
        className="modal fade success-modal-popup"
        onHide={this.props.modelToggle}
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.props.modelToggle}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="smp-box">
            <div className="smp-icon">
              <img src={dummyImg} alt="" />
            </div>
            <div className="smp-heading text-center">
              <h4>Thank you for your order.</h4>
              <h6>Your Order number is 11234.</h6>
            </div>
            <div className="smp-table-box">
              <div className="table-responsive table_wrap">
                <table className="table2 table-wrap">
                  <thead>
                    <tr>
                      <th>Products</th>
                      <th></th>
                      <th style={{ width: "180px", textAlign: "right" }}>
                        Quantity
                      </th>
                      <th style={{ width: "100px", textAlign: "right" }}>
                        Subtotal
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="td-image text-md-center">
                        <span>
                          <img src="include/images/iphone.png" alt="" />
                        </span>
                      </td>
                      <td>
                        <b>Apple iPhone 12 (64GB)</b>
                        <div className="table-dropdown btn-group dropdown">
                          <button
                            className="dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Pickup{" "}
                            <span className="caret">
                              <img
                                src="include/images/down-arrow-icon.svg"
                                alt=""
                              />
                            </span>
                          </button>
                          <div className="dropdown-menu">
                            <div className="drop-content-box">
                              <div className="drop-content-header d-flex align-items-center justify-content-between">
                                <h4>Pickup option</h4>
                                <div className="close-icon">
                                  <img src="include/images/close.svg" alt="" />
                                </div>
                              </div>
                              <ul className="drop-menu">
                                <li className="drop-item">
                                  <a
                                    href="javascript:void(0)"
                                    className="drop-link"
                                  >
                                    <svg
                                      width="15"
                                      height="15"
                                      viewBox="0 0 15 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M12.1875 15H2.8125C1.95063 15 1.25 14.2988 1.25 13.4375V5.72938C1.25 5.39375 1.36125 5.06063 1.5625 4.79188L3.1875 2.625C3.24625 2.54625 3.33938 2.5 3.4375 2.5H11.5594H11.5625C11.6263 2.5 11.6894 2.51875 11.7444 2.55812C11.7488 2.56125 11.7537 2.565 11.7575 2.56813C11.78 2.58625 11.8 2.6075 11.8169 2.63063L13.4375 4.79188C13.6388 5.06063 13.75 5.39313 13.75 5.72938V13.4375C13.75 14.2988 13.0494 15 12.1875 15ZM9.68687 14.375H12.1875C12.7044 14.375 13.125 13.9544 13.125 13.4375V5.72938C13.125 5.52813 13.0587 5.32812 12.9369 5.1675L11.5625 3.33313L10.1881 5.16625C10.0663 5.32813 10 5.5275 10 5.72938V13.4375C10 13.7894 9.88312 14.1138 9.68687 14.375ZM3.59375 3.125L2.06313 5.16688C1.94125 5.32813 1.875 5.5275 1.875 5.72938V13.4375C1.875 13.9544 2.29562 14.375 2.8125 14.375H8.4375C8.95437 14.375 9.375 13.9544 9.375 13.4375V5.72938C9.375 5.39375 9.48625 5.06063 9.6875 4.79188L10.9375 3.125H3.59375Z"
                                        fill="#4F4F4F"
                                      />
                                      <path
                                        d="M11.5625 3.125C11.39 3.125 11.25 2.985 11.25 2.8125V0.9375C11.25 0.765 11.1094 0.625 10.9375 0.625H4.0625C3.89062 0.625 3.75 0.765 3.75 0.9375V2.8125C3.75 2.985 3.61 3.125 3.4375 3.125C3.265 3.125 3.125 2.985 3.125 2.8125V0.9375C3.125 0.420625 3.54562 0 4.0625 0H10.9375C11.4544 0 11.875 0.420625 11.875 0.9375V2.8125C11.875 2.985 11.735 3.125 11.5625 3.125Z"
                                        fill="#4F4F4F"
                                      />
                                      <path
                                        d="M6.5625 8.74984H4.6875C4.17063 8.74984 3.75 8.32921 3.75 7.81234V5.72922C3.75 5.39359 3.86125 5.06047 4.0625 4.79172L5.6875 2.62485C5.7925 2.48673 5.98813 2.45923 6.125 2.56235C6.26313 2.6661 6.29125 2.86172 6.18813 2.99985L4.56313 5.16672C4.44125 5.32797 4.375 5.52734 4.375 5.72922V7.81234C4.375 7.98484 4.51563 8.12484 4.6875 8.12484H6.5625C6.73438 8.12484 6.875 7.98484 6.875 7.81234V5.72922C6.875 5.39359 6.98625 5.06047 7.1875 4.79172L8.8125 2.62485C8.9175 2.48673 9.11313 2.45923 9.25 2.56235C9.38813 2.6661 9.41625 2.86172 9.31313 2.99985L7.68813 5.16672C7.56625 5.32797 7.5 5.52734 7.5 5.72922V7.81234C7.5 8.32921 7.07938 8.74984 6.5625 8.74984Z"
                                        fill="#4F4F4F"
                                      />
                                      <path
                                        d="M11.5625 6.875C11.39 6.875 11.25 6.735 11.25 6.5625V2.8125C11.25 2.64 11.39 2.5 11.5625 2.5C11.735 2.5 11.875 2.64 11.875 2.8125V6.5625C11.875 6.735 11.735 6.875 11.5625 6.875Z"
                                        fill="#4F4F4F"
                                      />
                                    </svg>
                                    Pickup
                                  </a>
                                </li>
                                <li className="drop-item">
                                  <a
                                    href="javascript:void(0)"
                                    className="drop-link"
                                  >
                                    <svg
                                      width="15"
                                      height="15"
                                      viewBox="0 0 15 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M9.6875 13.125H5.28125C5.10875 13.125 4.96875 12.985 4.96875 12.8125C4.96875 12.64 5.10875 12.5 5.28125 12.5H9.375V4.0625C9.375 3.89 9.23438 3.75 9.0625 3.75H0.9375C0.765625 3.75 0.625 3.89 0.625 4.0625V12.1875C0.625 12.36 0.765625 12.5 0.9375 12.5H2.21875C2.39125 12.5 2.53125 12.64 2.53125 12.8125C2.53125 12.985 2.39125 13.125 2.21875 13.125H0.9375C0.420625 13.125 0 12.7044 0 12.1875V4.0625C0 3.54562 0.420625 3.125 0.9375 3.125H9.0625C9.57938 3.125 10 3.54562 10 4.0625V12.8125C10 12.985 9.86 13.125 9.6875 13.125Z"
                                        fill="#4F4F4F"
                                      />
                                      <path
                                        d="M14.0625 13.125H13.25C13.0775 13.125 12.9375 12.985 12.9375 12.8125C12.9375 12.64 13.0775 12.5 13.25 12.5H14.0625C14.2344 12.5 14.375 12.36 14.375 12.1875V10.9375C14.375 9.04187 12.8325 7.5 10.9375 7.5H10V12.5H10.1875C10.36 12.5 10.5 12.64 10.5 12.8125C10.5 12.985 10.36 13.125 10.1875 13.125H9.6875C9.515 13.125 9.375 12.985 9.375 12.8125V7.1875C9.375 7.015 9.515 6.875 9.6875 6.875H10.9375C13.1775 6.875 15 8.6975 15 10.9375V12.1875C15 12.7044 14.5794 13.125 14.0625 13.125Z"
                                        fill="#4F4F4F"
                                      />
                                      <path
                                        d="M11.7188 15C10.685 15 9.84375 14.1588 9.84375 13.125C9.84375 12.0912 10.685 11.25 11.7188 11.25C12.7525 11.25 13.5938 12.0912 13.5938 13.125C13.5938 14.1588 12.7525 15 11.7188 15ZM11.7188 11.875C11.0294 11.875 10.4688 12.4356 10.4688 13.125C10.4688 13.8144 11.0294 14.375 11.7188 14.375C12.4081 14.375 12.9688 13.8144 12.9688 13.125C12.9688 12.4356 12.4081 11.875 11.7188 11.875Z"
                                        fill="#4F4F4F"
                                      />
                                      <path
                                        d="M3.75 15C2.71625 15 1.875 14.1588 1.875 13.125C1.875 12.0912 2.71625 11.25 3.75 11.25C4.78375 11.25 5.625 12.0912 5.625 13.125C5.625 14.1588 4.78375 15 3.75 15ZM3.75 11.875C3.06062 11.875 2.5 12.4356 2.5 13.125C2.5 13.8144 3.06062 14.375 3.75 14.375C4.43937 14.375 5 13.8144 5 13.125C5 12.4356 4.43937 11.875 3.75 11.875Z"
                                        fill="#4F4F4F"
                                      />
                                      <path
                                        d="M2.18751 8.12505C2.12251 8.12505 2.05626 8.10442 2.00001 8.06255C1.86188 7.9588 1.83376 7.76317 1.93688 7.62505L3.81188 5.12504C3.91626 4.98628 4.11188 4.95941 4.24938 5.06254C4.38813 5.16629 4.41563 5.36191 4.31251 5.50004L2.43751 8.00005C2.37626 8.08192 2.28188 8.12505 2.18751 8.12505Z"
                                        fill="#4F4F4F"
                                      />
                                      <path
                                        d="M5.93751 9.99985C5.87251 9.99985 5.80626 9.97923 5.75001 9.93735C5.61188 9.8336 5.58376 9.63798 5.68688 9.49985L7.56188 6.99985C7.66626 6.86173 7.86251 6.83423 7.99938 6.93735C8.13751 7.0411 8.16563 7.23673 8.06251 7.37485L6.1875 9.87485C6.12625 9.95673 6.03188 9.99985 5.93751 9.99985Z"
                                        fill="#4F4F4F"
                                      />
                                      <path
                                        d="M3.43757 9.99963C3.37445 9.99963 3.31132 9.98088 3.2557 9.9415C3.11507 9.8415 3.0832 9.64588 3.1832 9.50588L6.3082 5.13088C6.40883 4.98963 6.60383 4.95838 6.74445 5.05838C6.88508 5.15838 6.91695 5.354 6.81695 5.494L3.69195 9.869C3.63132 9.954 3.53445 9.99963 3.43757 9.99963Z"
                                        fill="#4F4F4F"
                                      />
                                    </svg>
                                    Delivery
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td style={{ textAlign: "right" }}>25</td>
                      <td style={{ textAlign: "right" }}>
                        <b>$400</b>
                        <div className="sub-code">Tax : $10</div>
                      </td>
                    </tr>

                    <tr>
                      <td className="td-image text-md-center">
                        <span>
                          <img src="include/images/iphone.png" alt="" />
                        </span>
                      </td>
                      <td>
                        <b>Apple iPhone 12 (64GB)</b>
                        <div className="table-dropdown btn-group dropdown">
                          <button
                            className="dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Pickup{" "}
                            <span className="caret">
                              <img
                                src="include/images/down-arrow-icon.svg"
                                alt=""
                              />
                            </span>
                          </button>
                          <div className="dropdown-menu">
                            <div className="drop-content-box">
                              <div className="drop-content-header d-flex align-items-center justify-content-between">
                                <h4>Pickup option</h4>
                                <div className="close-icon">
                                  <img src="include/images/close.svg" alt="" />
                                </div>
                              </div>
                              <ul className="drop-menu">
                                <li className="drop-item">
                                  <a
                                    href="javascript:void(0)"
                                    className="drop-link"
                                  >
                                    <svg
                                      width="15"
                                      height="15"
                                      viewBox="0 0 15 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M12.1875 15H2.8125C1.95063 15 1.25 14.2988 1.25 13.4375V5.72938C1.25 5.39375 1.36125 5.06063 1.5625 4.79188L3.1875 2.625C3.24625 2.54625 3.33938 2.5 3.4375 2.5H11.5594H11.5625C11.6263 2.5 11.6894 2.51875 11.7444 2.55812C11.7488 2.56125 11.7537 2.565 11.7575 2.56813C11.78 2.58625 11.8 2.6075 11.8169 2.63063L13.4375 4.79188C13.6388 5.06063 13.75 5.39313 13.75 5.72938V13.4375C13.75 14.2988 13.0494 15 12.1875 15ZM9.68687 14.375H12.1875C12.7044 14.375 13.125 13.9544 13.125 13.4375V5.72938C13.125 5.52813 13.0587 5.32812 12.9369 5.1675L11.5625 3.33313L10.1881 5.16625C10.0663 5.32813 10 5.5275 10 5.72938V13.4375C10 13.7894 9.88312 14.1138 9.68687 14.375ZM3.59375 3.125L2.06313 5.16688C1.94125 5.32813 1.875 5.5275 1.875 5.72938V13.4375C1.875 13.9544 2.29562 14.375 2.8125 14.375H8.4375C8.95437 14.375 9.375 13.9544 9.375 13.4375V5.72938C9.375 5.39375 9.48625 5.06063 9.6875 4.79188L10.9375 3.125H3.59375Z"
                                        fill="#4F4F4F"
                                      />
                                      <path
                                        d="M11.5625 3.125C11.39 3.125 11.25 2.985 11.25 2.8125V0.9375C11.25 0.765 11.1094 0.625 10.9375 0.625H4.0625C3.89062 0.625 3.75 0.765 3.75 0.9375V2.8125C3.75 2.985 3.61 3.125 3.4375 3.125C3.265 3.125 3.125 2.985 3.125 2.8125V0.9375C3.125 0.420625 3.54562 0 4.0625 0H10.9375C11.4544 0 11.875 0.420625 11.875 0.9375V2.8125C11.875 2.985 11.735 3.125 11.5625 3.125Z"
                                        fill="#4F4F4F"
                                      />
                                      <path
                                        d="M6.5625 8.74984H4.6875C4.17063 8.74984 3.75 8.32921 3.75 7.81234V5.72922C3.75 5.39359 3.86125 5.06047 4.0625 4.79172L5.6875 2.62485C5.7925 2.48673 5.98813 2.45923 6.125 2.56235C6.26313 2.6661 6.29125 2.86172 6.18813 2.99985L4.56313 5.16672C4.44125 5.32797 4.375 5.52734 4.375 5.72922V7.81234C4.375 7.98484 4.51563 8.12484 4.6875 8.12484H6.5625C6.73438 8.12484 6.875 7.98484 6.875 7.81234V5.72922C6.875 5.39359 6.98625 5.06047 7.1875 4.79172L8.8125 2.62485C8.9175 2.48673 9.11313 2.45923 9.25 2.56235C9.38813 2.6661 9.41625 2.86172 9.31313 2.99985L7.68813 5.16672C7.56625 5.32797 7.5 5.52734 7.5 5.72922V7.81234C7.5 8.32921 7.07938 8.74984 6.5625 8.74984Z"
                                        fill="#4F4F4F"
                                      />
                                      <path
                                        d="M11.5625 6.875C11.39 6.875 11.25 6.735 11.25 6.5625V2.8125C11.25 2.64 11.39 2.5 11.5625 2.5C11.735 2.5 11.875 2.64 11.875 2.8125V6.5625C11.875 6.735 11.735 6.875 11.5625 6.875Z"
                                        fill="#4F4F4F"
                                      />
                                    </svg>
                                    Pickup
                                  </a>
                                </li>
                                <li className="drop-item">
                                  <a
                                    href="javascript:void(0)"
                                    className="drop-link"
                                  >
                                    <svg
                                      width="15"
                                      height="15"
                                      viewBox="0 0 15 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M9.6875 13.125H5.28125C5.10875 13.125 4.96875 12.985 4.96875 12.8125C4.96875 12.64 5.10875 12.5 5.28125 12.5H9.375V4.0625C9.375 3.89 9.23438 3.75 9.0625 3.75H0.9375C0.765625 3.75 0.625 3.89 0.625 4.0625V12.1875C0.625 12.36 0.765625 12.5 0.9375 12.5H2.21875C2.39125 12.5 2.53125 12.64 2.53125 12.8125C2.53125 12.985 2.39125 13.125 2.21875 13.125H0.9375C0.420625 13.125 0 12.7044 0 12.1875V4.0625C0 3.54562 0.420625 3.125 0.9375 3.125H9.0625C9.57938 3.125 10 3.54562 10 4.0625V12.8125C10 12.985 9.86 13.125 9.6875 13.125Z"
                                        fill="#4F4F4F"
                                      />
                                      <path
                                        d="M14.0625 13.125H13.25C13.0775 13.125 12.9375 12.985 12.9375 12.8125C12.9375 12.64 13.0775 12.5 13.25 12.5H14.0625C14.2344 12.5 14.375 12.36 14.375 12.1875V10.9375C14.375 9.04187 12.8325 7.5 10.9375 7.5H10V12.5H10.1875C10.36 12.5 10.5 12.64 10.5 12.8125C10.5 12.985 10.36 13.125 10.1875 13.125H9.6875C9.515 13.125 9.375 12.985 9.375 12.8125V7.1875C9.375 7.015 9.515 6.875 9.6875 6.875H10.9375C13.1775 6.875 15 8.6975 15 10.9375V12.1875C15 12.7044 14.5794 13.125 14.0625 13.125Z"
                                        fill="#4F4F4F"
                                      />
                                      <path
                                        d="M11.7188 15C10.685 15 9.84375 14.1588 9.84375 13.125C9.84375 12.0912 10.685 11.25 11.7188 11.25C12.7525 11.25 13.5938 12.0912 13.5938 13.125C13.5938 14.1588 12.7525 15 11.7188 15ZM11.7188 11.875C11.0294 11.875 10.4688 12.4356 10.4688 13.125C10.4688 13.8144 11.0294 14.375 11.7188 14.375C12.4081 14.375 12.9688 13.8144 12.9688 13.125C12.9688 12.4356 12.4081 11.875 11.7188 11.875Z"
                                        fill="#4F4F4F"
                                      />
                                      <path
                                        d="M3.75 15C2.71625 15 1.875 14.1588 1.875 13.125C1.875 12.0912 2.71625 11.25 3.75 11.25C4.78375 11.25 5.625 12.0912 5.625 13.125C5.625 14.1588 4.78375 15 3.75 15ZM3.75 11.875C3.06062 11.875 2.5 12.4356 2.5 13.125C2.5 13.8144 3.06062 14.375 3.75 14.375C4.43937 14.375 5 13.8144 5 13.125C5 12.4356 4.43937 11.875 3.75 11.875Z"
                                        fill="#4F4F4F"
                                      />
                                      <path
                                        d="M2.18751 8.12505C2.12251 8.12505 2.05626 8.10442 2.00001 8.06255C1.86188 7.9588 1.83376 7.76317 1.93688 7.62505L3.81188 5.12504C3.91626 4.98628 4.11188 4.95941 4.24938 5.06254C4.38813 5.16629 4.41563 5.36191 4.31251 5.50004L2.43751 8.00005C2.37626 8.08192 2.28188 8.12505 2.18751 8.12505Z"
                                        fill="#4F4F4F"
                                      />
                                      <path
                                        d="M5.93751 9.99985C5.87251 9.99985 5.80626 9.97923 5.75001 9.93735C5.61188 9.8336 5.58376 9.63798 5.68688 9.49985L7.56188 6.99985C7.66626 6.86173 7.86251 6.83423 7.99938 6.93735C8.13751 7.0411 8.16563 7.23673 8.06251 7.37485L6.1875 9.87485C6.12625 9.95673 6.03188 9.99985 5.93751 9.99985Z"
                                        fill="#4F4F4F"
                                      />
                                      <path
                                        d="M3.43757 9.99963C3.37445 9.99963 3.31132 9.98088 3.2557 9.9415C3.11507 9.8415 3.0832 9.64588 3.1832 9.50588L6.3082 5.13088C6.40883 4.98963 6.60383 4.95838 6.74445 5.05838C6.88508 5.15838 6.91695 5.354 6.81695 5.494L3.69195 9.869C3.63132 9.954 3.53445 9.99963 3.43757 9.99963Z"
                                        fill="#4F4F4F"
                                      />
                                    </svg>
                                    Delivery
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td style={{ textAlign: "right" }}>25</td>
                      <td style={{ textAlign: "right" }}>
                        <b>$400</b>
                        <div className="sub-code">Tax : $10</div>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2">
                        <b>Total</b>
                      </td>
                      <td colspan="2" style={{ textAlign: "right" }}>
                        <div>
                          <b>$800</b>
                          <div className="sub-code">Tax : $20</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="smp-footer d-md-flex flex-md-wrap">
              <div className="smpf-left">
                <a
                  href="javascript:void(0)"
                  className="btn btn-default btn-block"
                >
                  Continue
                </a>
                <a
                  href="javascript:void(0)"
                  className="btn btn-secondary btn-block"
                >
                  Go to Order History
                </a>
              </div>
              <div className="smpf-right">
                <h3>Order Summary</h3>
                <div className="table-box">
                  <table className="table-responsive2">
                    <tbody>
                      <tr>
                        <td>Order Total</td>
                        <td align="right">$1600</td>
                      </tr>
                      <tr>
                        <td>Tax Total</td>
                        <td align="right">+$40</td>
                      </tr>
                      <tr>
                        <td>Coupons</td>
                        <td align="right">-$10</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>Final Amount</td>
                        <td align="right">$88.36</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default OrderSuccess;
