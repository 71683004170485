import React, { createRef } from "react";
import Form from "../../common/form/form";

import plusIcon from "../../../include/images/plus-icon_1.svg";

import Footer from "../../common/footer";
import EditAddress from "../../common/customer/editAddress";
import CustomerSidebar from "../../common/customer/customerSidebar";
import Header from "../../productV2/header";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import LoadingBar from "react-top-loading-bar";

import AlertSuccess from "../../common/alertSuccess";
import AlertError from "../../common/alertError";
import { toast } from "react-toastify";

import {
  loadCustomerProfile,
  getCustomer,
  updateAddress,
} from "../../../store/customer";
import DeleteAddressPopup from "../../common/customer/deleteAddress";

class Address extends Form {
  ref = createRef(null);

  state = {
    editShippingAddress: false,
    editBillingAddress: false,
    removeShippingAddress: false,
    removeBillingAddress: false,
    data: {
      billingAddress: {
        address_line1: "",
        address_line2: "",
        zipcode: "",
        city: "",
        state: "",
      },
      shippingAddress: {
        address_line1: "",
        address_line2: "",
        zipcode: "",
        city: "",
        state: "",
      },
    },
  };

  componentDidMount = () => {
    this.props.loadCustomerProfile();
    this.ref.current.continuousStart();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.complete();
    }
  };

  handleEditShippingAddress = () => {
    this.setState({ editShippingAddress: !this.state.editShippingAddress });
    document.body.classList.add("toggled-tab");
  };

  handleEditBillingAddress = () => {
    this.setState({ editBillingAddress: !this.state.editBillingAddress });
    document.body.classList.add("toggled-tab");
  };

  handleClose = () => {
    if (this.state.editShippingAddress === true) {
      this.setState({
        editShippingAddress: !this.state.editShippingAddress,
      });
    } else
      this.setState({
        editBillingAddress: !this.state.editBillingAddress,
      });

    document.body.classList.remove("toggled-tab");
  };

  handleShippingRemovePopup = () => {
    this.setState({
      removeShippingAddress: !this.state.removeShippingAddress,
    });
  };
  handleBillingRemovePopup = () => {
    this.setState({
      removeBillingAddress: !this.state.removeBillingAddress,
    });
  };

  handleRemove = () => {
    if (this.state.removeShippingAddress === true) {
      const updateAddressParams = {
        shipping_address: null,
      };
      this.props.updateAddress(updateAddressParams, this.callbackRemoveAddress);
    } else {
      const updateAddressParams = {
        billing_address: null,
      };
      this.props.updateAddress(updateAddressParams, this.callbackRemoveAddress);
    }
  };

  callbackRemoveAddress = (res) => {
    this.setState({
      loader: false,
      labelRegister: "Save Changes",
      btnClass: "btn btn-default",
    });
    if (res.status === 200) {
      this.props.loadCustomerProfile();
      toast(<AlertSuccess message={"Address Removed Successfully"} />);
      if (this.state.removeShippingAddress === true) {
        this.setState({
          removeShippingAddress: !this.state.removeShippingAddress,
        });
      } else
        this.setState({
          removeBillingAddress: !this.state.removeBillingAddress,
        });
    } else {
      toast(<AlertError message={"Please try again."} />);
    }
  };

  onHide = () => {
    if (this.state.removeShippingAddress === true) {
      this.setState({
        removeShippingAddress: !this.state.removeShippingAddress,
      });
    } else
      this.setState({
        removeBillingAddress: !this.state.removeBillingAddress,
      });
  };

  render() {
    const {
      first_name,
      last_name,
      billing_address,
      shipping_address,
      mobile,
      code,
    } = this.props.customerProfile;

    return (
      <>
        <LoadingBar color="#0b4474" ref={this.ref} />
        <Header />
        <>
          <main id="main">
            <div className="content-container">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="conetnt-box d-md-flex flex-md-wrap">
                      <CustomerSidebar />
                      <div className="main-content-box">
                        <h3>My Addresses</h3>

                        <div className="address-box">
                          <div className="ab-left shipping-address">
                            <h4>Delivery Address</h4>

                            {shipping_address ? (
                              <div className="address-text-box">
                                <h4>
                                  {first_name &&
                                    last_name &&
                                    first_name + " " + last_name}
                                </h4>
                                <p>
                                  {shipping_address.address_line1 &&
                                    shipping_address.address_line1}
                                  ,{" "}
                                  {shipping_address.address_line2 &&
                                    shipping_address.address_line2}{" "}
                                  <br />
                                  {shipping_address.city &&
                                    shipping_address.city}
                                  ,&nbsp;
                                  {shipping_address.state &&
                                    shipping_address.state}
                                  &nbsp;
                                  {shipping_address.zipcode &&
                                    shipping_address.zipcode}
                                  <br />{" "}
                                  {code && mobile && code.includes("+")
                                    ? code + mobile
                                    : "+" + code + mobile}
                                </p>
                                <div className="address-btn d-flex align-items-center">
                                  <a
                                    onClick={this.handleEditShippingAddress}
                                    href="javascript:void(0)"
                                    className="btn-links"
                                  >
                                    Edit
                                  </a>
                                  <a
                                    onClick={this.handleShippingRemovePopup}
                                    href="javascript:void(0)"
                                    className="btn-links"
                                  >
                                    Remove
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="address-billing-box physicalVisit"
                                onClick={this.handleEditShippingAddress}
                              >
                                <div className="plus-icon">
                                  <img src={plusIcon} alt="" />
                                </div>
                                <h6>Add a Delivery Address</h6>
                              </div>
                            )}
                          </div>
                          <div className="ab-right">
                            <h4>Billing Address</h4>

                            {billing_address ? (
                              <div className="address-text-box">
                                <h4>
                                  {first_name &&
                                    last_name &&
                                    first_name + " " + last_name}
                                </h4>

                                <p>
                                  {billing_address.address_line1 &&
                                    billing_address.address_line1}
                                  ,{" "}
                                  {billing_address.address_line2 &&
                                    billing_address.address_line2}{" "}
                                  <br />
                                  {billing_address.city && billing_address.city}
                                  , &nbsp;
                                  {billing_address.state &&
                                    billing_address.state}
                                  &nbsp;
                                  {billing_address.zipcode &&
                                    billing_address.zipcode}{" "}
                                  <br />{" "}
                                  {code && mobile && code.includes("+")
                                    ? code + mobile
                                    : "+" + code + mobile}
                                </p>

                                <div className="address-btn d-flex align-items-center">
                                  <a
                                    onClick={this.handleEditBillingAddress}
                                    href="javascript:void(0)"
                                    className="btn-links"
                                  >
                                    Edit
                                  </a>
                                  <a
                                    onClick={this.handleBillingRemovePopup}
                                    href="javascript:void(0)"
                                    className="btn-links"
                                  >
                                    Remove
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <div
                                onClick={this.handleEditBillingAddress}
                                className="address-billing-box physicalVisit"
                              >
                                <div className="plus-icon">
                                  <img src={plusIcon} alt="" />
                                </div>
                                <h6>Add a Billing Address</h6>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.editShippingAddress || this.state.editBillingAddress ? (
              <EditAddress
                editShippingAddress={this.state.editShippingAddress}
                handleClose={this.handleClose}
                editBillingAddress={this.state.editBillingAddress}
              />
            ) : (
              ""
            )}
            <Footer />
          </main>
          <div className="overlay"></div>
          <DeleteAddressPopup
            show={
              this.state.removeBillingAddress ||
              this.state.removeShippingAddress
            }
            onHide={this.onHide}
            handleRemove={this.handleRemove}
            removeBillingAddress={this.state.removeBillingAddress}
            removeShippingAddress={this.state.removeShippingAddress}
          />
        </>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customerProfile: getCustomer(state).profile,
  loading: getCustomer(state).profileLoading,
});

const mapDispatchToProps = (dispatch) => ({
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
  updateAddress: (data, callback) => dispatch(updateAddress(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Address)
);
