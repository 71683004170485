import React from "react";
import Joi from "joi-browser";
import Form from "../common/form/form";
import SubmitBtn from "../common/form/submitBtn";
import close from "../../include/images/close.svg";
import { getContact, addContact } from "../../store/contact";
import { loadCustomerProfile, getCustomer } from "../../store/customer";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import {
  nameRequired,
  failed,
  emailRequired,
  mobileRequired,
  message,
} from "../common/misc";

const initialState = {
  data: {
    name: "",
    email: "",
    mobile: "",
    message: "",
  },
  label: "Submit",
  loader: false,
  errors: {},
};
class Contact extends Form {
  state = initialState;

  schema = {
    name: Joi.string()
      .required()
      .label("Name")
      .error(() => {
        return { message: nameRequired };
      }),
    email: Joi.string()
      .required()
      .label("Email")
      .error(() => {
        return { message: emailRequired };
      }),
    mobile: Joi.string()
      .required()
      .label("Mobile Number")
      .error(() => {
        return { message: mobileRequired };
      }),
    message: Joi.string()
      .required()
      .label("Message")
      .error(() => {
        return { message: message };
      }),
  };

  componentDidMount = () => {
    if (localStorage.getItem("x-refresh-token-customer")) {
      this.props.loadCustomerProfile();
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      const { first_name, last_name, mobile, email, code } =
        this.props.customerProfile;

      const fullName = first_name + " " + last_name;
      const mobileNumber = code && (code.includes("+")
        ? code + JSON.stringify(mobile)
        : "+" + code + JSON.stringify(mobile))

      const data = {
        name: fullName,
        mobile: mobileNumber,
        email,
      };

      this.setState({ data });
    }
  };

  doSubmit = () => {
    this.setState({
      loader: true,
      label: "",
    });
    const { name, email, mobile, message } = this.state.data;
    const formdata = {
      name: name,
      email: email,
      mobile: mobile,
      message: message,
    };
    this.props.addContact(formdata, this.callback);
  };

  callback = (res) => {
    this.setState({
      loader: false,
      label: "Submit",
    });

    if (res && res.status === 200) {
      // this.setState(initialState);
      const { first_name, last_name, mobile, email, code } =
        this.props.customerProfile;

      const fullName = first_name + " " + last_name;
      const mobileNumber = code.includes("+")
        ? code + JSON.stringify(mobile)
        : "+" + code + JSON.stringify(mobile);

      const data = {
        name: fullName,
        mobile: mobileNumber,
        email,
        message: "",
      };

      this.setState({ data });
      document.body.classList.remove("toggled-tab");
      this.props.removeContact();
      toast(
        <AlertSuccess
          message={
            "Thank you for contacting us. We will get back to you as soon as possible."
          }
        />
      );
    } else {
      this.setState(initialState);
      this.props.toggleContact();
      toast(<AlertError message={failed} />);
    }
  };
  render() {
    return (
      <>
        {this.props.isContact && (
          <>
            <div className="contact-slidebar-container">
              <div className="contact-slide-inner">
                <div className="tab-header d-flex justify-content-between">
                  <h3>Contact us</h3>
                  <a
                    href="javascript:void(0)"
                    className="close"
                    onClick={this.props.removeContact}
                  >
                    <img src={close} alt="" />
                  </a>
                </div>
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    {this.renderInput("name", "Name")}
                  </div>
                  <div className="form-group">
                    {this.renderInput("email", "Email")}
                  </div>
                  <div className="form-group">
                    {this.renderInput("mobile", "Mobile Number")}
                  </div>
                  <div className="form-group">
                    {this.renderText("message", "Message")}
                  </div>
                  <div className="form-group">
                    <SubmitBtn
                      label={this.state.label}
                      loading={this.state.loader}
                      btnClass="btn btn-default"
                    />
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  contact: getContact(state),
  customerProfile: getCustomer(state).profile,
  loading: getCustomer(state).profileLoading,
});
const mapDispatchToProps = (dispatch) => ({
  addContact: (param, callback) => dispatch(addContact(param, callback)),
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Contact);
