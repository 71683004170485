import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../product/header";
import failed from "./../../include/images/failed.svg";
import { Link } from "react-router-dom";

class OrderFailed extends Component {
  render() {
    return (
      <>
        <Header cart={"status"} toggleContact={null} />
        <main id="main">
          <div className="cart-container d-flex align-items-center">
            <div className="empty-cart-content">
              <div className="ec-img-box">
                <img src={failed} alt="" />
              </div>
              <h4 style={{ lineHeight: "30px !important" }}>
                Your payment is declined and hence we could not place your order
              </h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <Link to="/products-listing" className="btn btn-default">
                Continue Shopping
              </Link>
            </div>
          </div>
          <footer className="footer" id="footer">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="footer-content text-center">
                    <div className="copyright">
                      &#169;2021-{new Date().getFullYear()} Effisim Corp. All
                      rights reserved
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </main>
      </>
    );
  }
}

export default connect(null, null)(OrderFailed);
