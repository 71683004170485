import React, { createRef } from "react";
import LoadingBar from "react-top-loading-bar";
import Header from "./header";
import { connect } from "react-redux";
import sortIcon from "../../include/images/sort-icon-1.svg";
import filterIcon from "../../include/images/filter-icon-1.svg";
import pickup from "../../include/images/pickup-icon.svg";
import shipicon from "../../include/images/shipment-icon.svg";
import chevronDown from "../../include/images/chevron-down-20x20.svg";
import filterIconNew from "../../include/images/filter-list-icon.svg";
import packageicon from "../../include/images/package-icon.svg";
import { getSeller } from "./../../store/seller";
import SellerDetail from "./sellerDetail";
import SellerReviews from "./sellerReviews";
import {
  getProduct,
  loadAllProducts,
  loadCategories,
} from "../../store/product";
import { PureComponent } from "react";
import { getCustomer, loadCustomerProfile } from "../../store/customer";
import { withRouter } from "react-router-dom";
import { loadCart } from "../../store/cart";
import FilterMobile from "./filterMobile";
import ProductListMain from "./productListMain";
import Geocode from "react-geocode";
import { getCustomerToken } from "../services/localStorageServices";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import { addToWishlist, deleteWishlist } from "../../store/customer";
import FilterModal from "./filterModal";
import Footer from "./footer";
import { filter } from "lodash";
Geocode.setApiKey(process.env.REACT_APP_MAPKEY);
Geocode.setLanguage("en");
Geocode.setRegion("us");
Geocode.setLocationType("APPROXIMATE");
Geocode.enableDebug();
const pricArr = [
  { min: 10, max: 50 },
  { min: 50, max: 100 },
  { min: 100, max: 200 },
];

class ProductList extends PureComponent {
  ref = createRef(null);

  state = {
    filters: {
      seller_id: "",
      sort: "",
      order: "",
      mode: "",
      deliveryOption: "",
      pickupOption: "10",
      keyword: "",
      page: 1,
      priceMax: 0,
      priceMin: 0,
      pricesMinMax: {},
      averageRating: "",
      numberOfReviews: "",
      category: "",
      sub_category: "",
      second_sub_category: "",
      sellers: [],
      prices: [],
      sellerKey: 0,
      priceKey: 0,
    },
    sortDropdown: false,
    listType: "a",
    productId: "",
    userLatLng: true,
    tab: "A",
    lng: -81.4915242,
    lat: 28.4716263,
    maxDistance: 16093,
    seller: [],
    page: 1,
    q: "",
    isContact: false,
    zoom: 12,
    address: localStorage.getItem("customerDddress")
      ? JSON.parse(localStorage.getItem("customerDddress")).address
      : "",
    postal_code: localStorage.getItem("customerDddress")
      ? JSON.parse(localStorage.getItem("customerDddress")).postal_code
          .long_name
      : "",
    pickup: false,
    delivery_option_value: ["Weekly once", "More than a week"],
    mobileFilter: false,
    sort: false,
    filterModal: false,
    isSellerReviews: false,
  };

  toggleSellerReviews = (id) => {
    this.setState({
      isSellerReviews: !this.state.isSellerReviews,
    });
    document.body.classList.add("toggled-tab");
  };

  toggleCloseSellerReviews = () => {
    this.setState({
      // reviews: !this.state.reviews,
      isSellerReviews: !this.state.isSellerReviews,
    });
    document.body.classList.remove("toggled-tab");
  };

  toggleFilterModal = () => {
    this.setState({ filterModal: !this.state.filterModal }, () => {
      if (this.state.filterModal) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    });
  };

  toggleSortDropdown = () => {
    this.setState({ sortDropdown: !this.state.sortDropdown });
  };

  onChangePrices = (value) => {
    const filters = { ...this.state.filters };

    const findData = filters.prices.find((es) => es === value);
    if (findData) {
      filters.prices = filters.prices.filter((efi) => efi !== value);
    } else {
      filters.prices.push(value);
    }
    filters.page = 1;
    this.setState({ filters }, () =>
      this.props.loadAllProducts(this.filterData())
    );
  };

  changePriceMinMax = (min, max) => {
    const filters = { ...this.state.filters };
    filters.pricesMinMax = { min: parseInt(min), max: parseInt(max) };
    if (!min || !max) filters.pricesMinMax = {};
    filters.page = 1;
    this.setState({ filters }, () =>
      this.props.loadAllProducts(this.filterData())
    );
  };

  onChangeSeller = (value) => {
    const filters = { ...this.state.filters };

    const findData = filters.sellers.find((es) => es === value);
    if (findData) {
      filters.sellers = filters.sellers.filter((efi) => efi !== value);
    } else {
      filters.sellers.push(value);
    }
    filters.page = 1;
    this.setState({ filters }, () =>
      this.props.loadAllProducts(this.filterData())
    );
  };

  onChangeCategory = (name, value) => {
    const filters = { ...this.state.filters };
    filters[name] = value;
    filters.page = 1;
    this.setState({ filters }, () =>
      this.props.loadAllProducts(this.filterData())
    );
  };
  handleSort = (sort, order) => {
    const filters = { ...this.state.filters };
    filters.sort = sort;
    filters.order = order;
    filters.page = 1;
    this.setState({ filters }, () =>
      this.props.loadAllProducts(this.filterData())
    );
  };

  handlePageClick = (e) => {
    //this.ref.current.continuousStart();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const filters = { ...this.state.filters };
    filters.page = e.selected + 1;

    this.setState({ filters }, () =>
      this.props.loadAllProducts(this.filterData())
    );
  };

  handleRadio = (e) => {
    const { name, value, noLoad } = e.target;
    const filters = { ...this.state.filters };
    filters[name] = value;

    if (name === "mode" && (value === "pickup" || !value)) {
      filters.pickupOption = "10";
    }
    if (name === "mode" && value === "delivery") {
      filters.pickupOption = "";
    }
    if (name === "mode" && value === "pickup") {
      filters.deliveryOption = "";
    }
    if (name === "category") {
      filters.sub_category = "";
    }

    this.setState(
      { filters },
      () => !noLoad && this.props.loadAllProducts(this.filterData())
    );
  };
  setKeyword = (keyword) => {
    const filters = { ...this.state.filters };
    filters.keyword = keyword;
    filters.page = 1;
    this.setState({ filters }, () =>
      this.props.loadAllProducts(this.filterData())
    );
  };
  setPrice = (priceMin, priceMax, refresh) => {
    const filters = { ...this.state.filters };
    filters.priceMax = priceMax;
    filters.priceMin = priceMin;

    this.setState(
      { filters },
      () => refresh && this.props.loadAllProducts(this.filterData())
    );
  };
  toggleListType = (e, listType) => {
    if (e) e.preventDefault();
    this.setState({ listType });
  };
  toggleSort = (e) => {
    if (e) e.preventDefault();
    this.setState({ sort: !this.state.sort }, () => {
      if (this.state.sort) {
        document.body.classList.add("show-sortby-box");
      } else {
        document.body.classList.remove("show-sortby-box");
      }
    });
  };

  toggleMobileFilter = (e) => {
    if (e) e.preventDefault();
    this.setState({ mobileFilter: !this.state.mobileFilter });
  };

  updateLatLng = (
    lat,
    lng,
    address,
    postal_code = "",
    repplace = false,
    customerAddress = false
  ) => {
    if (
      localStorage.getItem("customerDddress") &&
      (!repplace || !customerAddress)
    )
      return;
    localStorage.setItem(
      "customerDddress",
      JSON.stringify({ lat, lng, address, postal_code, repplace })
    );
    this.setState(
      {
        address,
        lat,
        lng,
        userLatLng: true,
        postal_code: postal_code ? postal_code.long_name : "",
      },
      () => {
        this.props.loadAllProducts(this.filterData());
      }
    );
  };

  getLatLong = () => {
    this.updateLatLng(
      "28.4716263",
      "-81.4915242",
      "Dr Phillips Blvd, Orlando, FL, USA",
      {
        long_name: "32819",
      }
    );
    navigator.geolocation.getCurrentPosition((position) => {
      Geocode.fromLatLng(
        position.coords.latitude,
        position.coords.longitude
      ).then(
        (response) => {
          const address = response.results[0].formatted_address;
          const postal_code = response.results[0].address_components.find(
            (es) => es.types[0] === "postal_code"
          );
          this.updateLatLng(
            position.coords.latitude,
            position.coords.longitude,
            address,
            postal_code,
            !localStorage.getItem("customerAddress"),
            true
          );
        },
        (error) => {
          console.error(" updateLatLngerror--->", error);
        }
      );
    });
  };

  filterData = () => {
    this.ref.current.continuousStart();
    const customerDddress = localStorage.getItem("customerDddress")
      ? JSON.parse(localStorage.getItem("customerDddress"))
      : {};
    const data = {};
    const { cat1, cat2, cat3 } = this.props.match.params;
    const filters = { ...this.state.filters };
    const { address, postal_code, lat, lng } = this.state;
    if (address) data.address = address;
    if (postal_code) data.zipCode = postal_code;
    if (lat && lng) {
      data.lat = lat;
      data.lon = lng;
    }
    if (customerDddress.lat) {
      data.lat = customerDddress.lat;
      data.lon = customerDddress.lng;
    }
    data.page = filters.page;
    data.includeOutofStock = false;
    if (this.props.match.params.seller_id)
      data.seller_id = this.props.match.params.seller_id;
    if (filters.sort) data.sort = filters.sort;
    if (filters.order) data.order = filters.order;
    if (filters.averageRating)
      data.averageRating = parseInt(filters.averageRating);
    if (filters.numberOfReviews)
      data.numberOfReviews = parseInt(filters.numberOfReviews);
    if (filters.priceMax && filters.priceMax != 0)
      data.priceMax = parseInt(filters.priceMax);
    if (filters.priceMin && filters.priceMin != 0)
      data.priceMin = parseInt(filters.priceMin);

    if (filters.pickupOption) {
      data.pickupOption = filters.pickupOption;
    } else {
      data.pickupOption = "100";
    }
    if (filters.deliveryOption) data.deliveryOption = filters.deliveryOption;
    if (filters.keyword) data.keyword = filters.keyword;
    if (filters.mode) data.mode = filters.mode;
    if (filters.mode1) data.mode = filters.mode1;
    if (cat1) data.category = cat1.split("-").join(" ");
    if (cat2) data.sub_category = cat2.split("-").join(" ");
    if (cat3) data.second_sub_category = cat3.split("-").join(" ");
    if (filters.sellers && filters.sellers.length > 0)
      data.sellers = filters.sellers;
    if (filters.category) data.category = filters.category;
    if (filters.sub_category) data.sub_category = filters.sub_category;
    if (filters.second_sub_category)
      data.second_sub_category = filters.second_sub_category;
    if (filters.prices && filters.prices.length > 0) {
      data.prices = filters.prices.map((v) => pricArr[v]);
    }

    if (filters.pricesMinMax.min && filters.pricesMinMax.max) {
      if (data.prices && data.prices.length > 0) {
        data.prices.push(filters.pricesMinMax);
      } else {
        data.prices = [filters.pricesMinMax];
      }
    }
    this.props.loadCategories("", {
      sellers: filters.sellers,
      seller_id: this.props.match.params.seller_id,
    });
    return data;
  };

  componentDidMount = () => {
    this.ref.current.continuousStart();
    window.scrollTo({ top: 0, behavior: "smooth" });
    const address = localStorage.getItem("customerDddress")
      ? JSON.parse(localStorage.getItem("customerDddress"))
      : {};

    if (localStorage.getItem("x-auth-token-customer"))
      this.props.loadCustomerProfile();
    if (localStorage.getItem("customerDddress") && address.repplace) {
      this.updateLatLng(
        address.lat,
        address.lng,
        address.address,
        address.postal_code,
        false,
        true
      );
    } else {
      this.getLatLong();
    }
    document.body.classList.add("home");
    document.body.classList.add("fixed");
    document.body.classList.remove("cart_page");

    // this.props.history.replace();
    if (this.props.location.state && this.props.location.state.product_id) {
      window.location.href =
        "/product-detail/" + this.props.location.state.product_id;
    } else if (this.props.location.state) {
      this.setState(this.props.location.state, () => {
        this.props.loadAllProducts(this.filterData());
      });
    } else {
      this.props.loadAllProducts(this.filterData());
    }
  };

  clearFilter = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState(
      {
        filters: {
          sort: "",
          order: "",
          mode: "",
          deliveryOption: "",
          pickupOption: "10",
          keyword: "",
          page: 1,
          priceMax: 0,
          priceMin: 0,
          averageRating: "",
          numberOfReviews: "",
          category: "",
          sub_category: "",
          second_sub_category: "",
          sellers: [],
          prices: [],
          pricesMinMax: {},
        },
      },
      () => {
        //this.toggleFilterModal();
        this.props.loadAllProducts(this.filterData());
      }
    );
  };
  componentDidUpdate = async (prevProps, prevState) => {
    if (this.props.match.params !== prevProps.match.params)
      this.props.loadAllProducts(this.filterData());
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.complete();
    }
    if (
      !this.props.getCustomer.profileLoading &&
      this.props.getCustomer.profileLoading !==
        prevProps.getCustomer.profileLoading
    ) {
      if (this.props.getCustomer.profile.shipping_address) {
        try {
          const add = await Geocode.fromAddress(
            `${this.props.getCustomer.profile.shipping_address.address_line1} ${this.props.getCustomer.profile.shipping_address.address_line2} ${this.props.getCustomer.profile.shipping_address.city} ${this.props.getCustomer.profile.shipping_address.state} ${this.props.getCustomer.profile.shipping_address.zipcode}`
          );

          const lat = add.results[0].geometry.location.lat;
          const lng = add.results[0].geometry.location.lng;
          const localStorageData = localStorage.getItem("customerDddress")
            ? JSON.parse(localStorage.getItem("customerDddress"))
            : {};

          if (!localStorageData.repplace) {
            localStorage.setItem("customerAddress", true);
            this.updateLatLng(
              lat,
              lng,
              this.props.getCustomer.profile.shipping_address.address_line2
                ? `${this.props.getCustomer.profile.shipping_address.address_line1}, ${this.props.getCustomer.profile.shipping_address.address_line2}, ${this.props.getCustomer.profile.shipping_address.city}, ${this.props.getCustomer.profile.shipping_address.state}, USA`
                : `${this.props.getCustomer.profile.shipping_address.address_line1}, ${this.props.getCustomer.profile.shipping_address.city}, ${this.props.getCustomer.profile.shipping_address.state}, USA`,
              {
                long_name:
                  this.props.getCustomer.profile.shipping_address.zipcode,
              },
              true,
              true
            );
          }
        } catch (error) {
          console.log("addddddError", error);
        }
      }
    }
  };

  componentWillUnmount = () => {
    document.body.classList.remove("home");
    document.body.classList.remove("fixed");
  };

  callBack = (res) => {
    // this.ref.current.complete();
    // this.setState({ loader: false });
    if (res.status === 200) {
      this.props.loadAllProducts(this.filterData());
      // toast(<AlertSuccess message={"Added to wishlist"} />);
      // this.setState({
      //   addedToWishlist: !this.state.addedToWishlist,
      // });
    } else {
      toast(<AlertError message={"Failed"} />);
    }
  };

  render() {
    const { filters } = this.state;
    const { product, pagination } = this.props;
    const pathname = this.props.location.pathname.split("/")[1];
    let count = 0;
    if (filters.deliveryOption) {
      count += 1;
    }
    if (filters.sellers && filters.sellers.length > 0) {
      count += filters.sellers.length;
    }
    if (filters.prices && filters.prices.length > 0) {
      count += filters.prices.length;
    }
    if (filters.mode) {
      count += 1;
    }
    if (filters.pickupOption) {
      count += 1;
    }
    if (filters.priceMax != 0) {
      count += 1;
    }
    if (filters.priceMin != 0) {
      count += 1;
    }
    if (filters.category) {
      count += 1;
    }
    if (filters.sub_category) {
      count += 1;
    }
    if (filters.averageRating) {
      count += 1;
    }
    if (filters.numberOfReviews) {
      count += 1;
    }

    return (
      <>
        <LoadingBar color="#ffffff" ref={this.ref} />
        <Header
          postal_code={this.state.postal_code}
          updateLatLng={this.updateLatLng}
          address={this.state.address}
          setKeyword={this.setKeyword}
          keyword={this.state.filters.keyword}
          toggleListType={this.toggleListType}
          listType={this.state.listType}
          togglemobEdit={this.togglemobEdit}
        />
        <div class="new-wrapper-container">
          {pathname == "store" && (
            <SellerDetail
              sellerDetail={this.props.sellerDetail}
              isSellerReviews={this.state.isSellerReviews}
              toggleSellerReviews={this.toggleSellerReviews}
            />
          )}
          <div class="filter-nav-wrapper">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 ">
                  <div class="filter-nav-row d-lg-flex align-items-lg-center justify-content-lg-between">
                    <div class="filter-nav-column">
                      <div class="filter-nav d-flex flex-wrap">
                        <div class="fnr-left d-lg-flex align-items-lg-center">
                          <div
                            class={
                              count
                                ? "fnr-side-nav-btn selected"
                                : "fnr-side-nav-btn"
                            }
                            data-toggle="modal"
                            data-target="#sideCollapseMenu"
                            onClick={this.toggleFilterModal}
                          >
                            <img src={filterIconNew} alt="" /> <span>All</span>{" "}
                            Filters{count ? ` ( ${count} )` : ""}
                          </div>
                        </div>
                        <div
                          onClick={this.toggleMobileFilter}
                          class={
                            count
                              ? "mob-filter-button d-block d-lg-none sidebar_toggler selected"
                              : "mob-filter-button d-block d-lg-none sidebar_toggler"
                          }
                          data-toggle="filter-box"
                        >
                          <img src={filterIconNew} alt="" />
                          Filters
                        </div>
                        <div class="fnr-dividor"></div>
                        <div class="fnr-right d-flex flex-wrap align-items-center">
                          <div class="form-check-outer d-flex align-items-center">
                            <div class="new-form-check d-none d-lg-block">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="mode"
                                id="all"
                                value=""
                                checked={!this.state.filters.mode}
                                onChange={this.handleRadio}
                              />
                              <label class="form-check-label" for="all">
                                All
                              </label>
                            </div>
                            <div class="new-form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="mode"
                                id="pickup"
                                value="pickup"
                                checked={this.state.filters.mode === "pickup"}
                                onChange={this.handleRadio}
                              />
                              <label class="form-check-label" for="pickup">
                                <img src={pickup} alt="" /> Pickup
                              </label>
                            </div>
                            <div class="new-form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="mode"
                                id="delivery"
                                value="delivery"
                                checked={this.state.filters.mode === "delivery"}
                                onChange={this.handleRadio}
                              />
                              <label class="form-check-label" for="delivery">
                                <img src={shipicon} alt="" /> Delivery
                              </label>
                            </div>
                            <div class="new-form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="mode"
                                id="shipping"
                                value="shipping"
                                checked={this.state.filters.mode === "shipping"}
                                onChange={this.handleRadio}
                              />
                              <label class="form-check-label" for="shipping">
                                <img src={packageicon} alt="" /> Shipping
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="filter-nav-column d-flex align-items-center">
                      <div class="sortby-row d-flex align-items-center">
                        <b>Sort by</b>
                        <span class="sortby-sep d-none d-lg-block">|</span>
                        <div class="pricing-dropdown add-new-dropdown">
                          <div
                            class={
                              this.state.sortDropdown
                                ? "dropdown show"
                                : "dropdown"
                            }
                            onClick={this.toggleSortDropdown}
                          >
                            <div
                              class="dropdown-toggle"
                              id="dropdownMenu2"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {this.state.sort === "date"
                                ? " New Arrivals"
                                : this.state.sort === "price"
                                ? this.state.order === "desc"
                                  ? "Price - High to Low"
                                  : "Price - Low to High"
                                : "Best Match"}
                              <img src={chevronDown} alt="" />
                            </div>
                            <div
                              class={
                                this.state.sortDropdown
                                  ? "dropdown-menu show"
                                  : "dropdown-menu"
                              }
                              aria-labelledby="dropdownMenu2"
                            >
                              <button
                                class="dropdown-item"
                                type="button"
                                onClick={() => {
                                  this.setState(
                                    {
                                      sort: "",
                                      order: "",
                                    },
                                    () =>
                                      this.handleSort(
                                        this.state.sort,
                                        this.state.order
                                      )
                                  );
                                }}
                              >
                                Best Match
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                onClick={() => {
                                  this.setState(
                                    {
                                      sort: "date",
                                      order: "desc",
                                    },
                                    () =>
                                      this.handleSort(
                                        this.state.sort,
                                        this.state.order
                                      )
                                  );
                                }}
                              >
                                New Arrivals
                              </button>

                              <button
                                class="dropdown-item"
                                type="button"
                                onClick={() => {
                                  this.setState(
                                    {
                                      sort: "price",
                                      order: "desc",
                                    },
                                    () =>
                                      this.handleSort(
                                        this.state.sort,
                                        this.state.order
                                      )
                                  );
                                }}
                              >
                                Price - High to Low
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                onClick={() => {
                                  this.setState(
                                    {
                                      sort: "price",
                                      order: "asc",
                                    },
                                    () =>
                                      this.handleSort(
                                        this.state.sort,
                                        this.state.order
                                      )
                                  );
                                }}
                              >
                                Price - Low to High
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ul class="view-type m-0 d-none d-lg-block">
                        <li class={this.state.listType == "b" ? "active" : ""}>
                          <a
                            href="javascript:void(0)"
                            class="list-type"
                            onClick={(e) => this.toggleListType(e, "b")}
                          >
                            <b></b>
                          </a>
                        </li>
                        <li class={this.state.listType == "a" ? "active" : ""}>
                          <a
                            href="javascript:void(0)"
                            class="grid-type"
                            onClick={(e) => this.toggleListType(e, "a")}
                          >
                            <b></b>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ProductListMain
            changePriceMinMax={this.changePriceMinMax}
            onChangePrices={this.onChangePrices}
            clearFilter={this.clearFilter}
            stateData={this.state}
            onChangeSeller={this.onChangeSeller}
            onChangeCategory={this.onChangeCategory}
            handleSort={this.handleSort}
            handlePageClick={this.handlePageClick}
            handleRadio={this.handleRadio}
            toggleListType={this.toggleListType}
            listType={this.state.listType}
            setPrice={this.setPrice}
            priceMax={this.state.filters.priceMax}
            priceMin={this.state.filters.priceMin}
            filters={this.state.filters}
            addToWishlist={this.props.addToWishlist}
            deleteWishlist={this.props.deleteWishlist}
            callBack={this.callBack}
            keyword={this.state.filters.keyword}
          />
        </div>
        <Footer />
        {this.state.filterModal && <div class="modal-backdrop fade show"></div>}
        {this.state.filterModal && (
          <FilterModal
            filterModal={this.state.filterModal}
            toggleFilterModal={this.toggleFilterModal}
            clearFilter={this.clearFilter}
            setPrice={this.setPrice}
            priceMax={this.state.filters.priceMax}
            priceMin={this.state.filters.priceMin}
            filters={this.state.filters}
            handleRadio={this.handleRadio}
            stateData={this.state}
            onChangeSeller={this.onChangeSeller}
            onChangeCategory={this.onChangeCategory}
            handleSort={this.handleSort}
            changePriceMinMax={this.changePriceMinMax}
            onChangePrices={this.onChangePrices}
            categories={this.props.categories}
            sellers={this.props.sellers}
            match={this.props.match.params}
          />
        )}
        {this.state.mobileFilter && (
          <FilterMobile
            changePriceMinMax={this.changePriceMinMax}
            onChangePrices={this.onChangePrices}
            clearFilter={this.clearFilter}
            onChangeSeller={this.onChangeSeller}
            onChangeCategory={this.onChangeCategory}
            categories={this.props.categories}
            sellers={this.props.sellers}
            mobileFilter={this.state.mobileFilter}
            toggleMobileFilter={this.toggleMobileFilter}
            handlePageClick={this.handlePageClick}
            handleRadio={this.handleRadio}
            toggleListType={this.toggleListType}
            listType={this.state.listType}
            setPrice={this.setPrice}
            priceMax={this.state.filters.priceMax}
            priceMin={this.state.filters.priceMin}
            filters={this.state.filters}
          />
        )}
        <SellerReviews
          detail={this.props.sellerDetail}
          isSellerReviews={this.state.isSellerReviews}
          toggleReviews={this.toggleSellerReviews}
          handleClose={this.toggleCloseSellerReviews}
          store={true}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: getProduct(state).categories,
  getSeller: getSeller(state),
  product: getProduct(state).product,
  pagination: getProduct(state).pagination,
  sellers: getProduct(state).sellers,
  getCustomer: getCustomer(state),
  loading: getProduct(state).loading,
  sellerDetail: getProduct(state).sellerDetail,
});

const mapDispatchToProps = (dispatch) => ({
  loadAllProducts: (data) => dispatch(loadAllProducts(data)),
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
  loadCart: (id, data) => dispatch(loadCart(id, data)),
  loadCategories: (url, params) => dispatch(loadCategories(url, params)),
  addToWishlist: (data, callback) => dispatch(addToWishlist(data, callback)),
  deleteWishlist: (data, callback) => dispatch(deleteWishlist(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductList)
);

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyDHZd1NTh7pJw6-zGe1Mf_cUBx2cIhz46Y",
// })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductList)));
