import React from "react";
import Joi from "joi-browser";

import closeIcon from "../../../include/images/close.svg";
import deleteIcon from "../../../include/images/delete1.svg";

import Moment from "moment";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import {
  addOrderDispute,
  loadOrders,
  loadCustomerProfile,
  uploadDisputePictures,
  getCustomer,
} from "../../../store/customer";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Form from "../../common/form/form";
import seUploadFun from "../../services/s3Services";

import FileInput from "../form/fileInput";
import SubmitBtn from "../form/submitBtn";

import { toast } from "react-toastify";
import AlertError from "../../common/alertError";
import AlertSuccess from "../../common/alertSuccess";

class DisputeOrder extends Form {
  state = {
    data: { notes: "", pics: [] },
    photoStatus: "",
    errors: {},
    isDisputeOpen: false,
  };

  schema = {
    notes: Joi.string()
      .required()
      .error(() => {
        return {
          message: "Required",
        };
      }),
    pics: Joi.array().items(Joi.object().optional()).optional(),
  };

  remoVeImg = (e, i) => {
    e.preventDefault();
    const data = this.state.data;
    const pics = data.pics;
    data.pics = pics.filter((p) => p !== i);
    this.setState({ data });
  };

  fileUpload = (e) => {
    this.setState({ photoStatus: "Please wait..." });
    const files = e.target.files;
    for (var i = 0; i < files.length; i++) {
      const element = files[i];
      let formData = new FormData();
      formData.append("image", element);
      this.props.uploadDisputePictures(formData, this.imageCallback);
    }
  };

  imageCallback = (res) => {
    if (res && res.status === 200) {
      const { data, errors } = this.state;
      errors.photo = "";
      data.pics.push(this.props.disputePhoto.urls);
      this.setState({
        data,
        errors,
        photoStatus: "",
      });
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  doSubmit = () => {
    const { notes, pics } = this.state.data;
    const { ep, eo } = this.props;

    const data = {
      order: eo._id,
      product: ep.product_id,
      notes: notes,
      images: pics,
    };
    this.props.addOrderDispute(data, this.callback);
  };

  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadOrders();
      this.props.loadCustomerProfile();
      this.props.setProductId({
        productId: "",
        orderId: "",
      });
      toast(<AlertSuccess message={"Dispute Created"} />);
    } else {
      toast(<AlertError message={"Failed"} />);
    }
  };

  render() {
    const { ep, eo } = this.props;
    const { photoIndex, isDisputeOpen } = this.state;

    return (
      this.props.productId === ep.product_id &&
      this.props.orderId === eo.order_id && (
        <div
          className={
            this.props.productId === ep.product_id &&
            this.props.orderId === eo.order_id
              ? "dropdown-menu show"
              : "dropdown-menu"
          }
          aria-labelledby={ep.product_id && eo.order_id}
        >
          {ep.dispute === null ? (
            <>
              <div
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  this.props.setProductId(ep.product_id, eo.order_id);
                }}
                className="dm-header-text d-flex align-items-center justify-content-between"
              >
                <span>Create Dispute</span>
                <div
                  className="close-icon"
                  onClick={() => {
                    this.props.setProductId({
                      productId: "",
                      orderId: "",
                    });
                  }}
                >
                  <img src={closeIcon} alt="" />
                </div>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  {this.renderText("notes", "Notes")}
                </div>

                <div className="form-group custom-upload">
                  <FileInput
                    totalUploaded={this.state.data.pics.length}
                    flag="files"
                    error={this.state.errors["pics"]}
                    label="Upload Image"
                    name="pics"
                    status={this.state.photoStatus}
                    onchange={this.fileUpload}
                    text={this.state.data["pics"]}
                  />
                  <span className="note">Max file size allowed 1 MB</span>
                </div>
                <ul className="delete-img-container d-flex">
                  {this.state.data.pics.map((e, i) => (
                    <li key={i}>
                      <div className="delete-img-box">
                        <img
                          width="180px"
                          src={e ? process.env.REACT_APP_S3URL + e.small : ""}
                          alt="img"
                        />
                        <div
                          className="delete-img"
                          onClick={(ele) => this.remoVeImg(ele, e)}
                        >
                          <img src={deleteIcon} alt="" />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="form-group">
                  <SubmitBtn
                    label="Submit"
                    loading={this.state.loading}
                    btnClass="btn btn-lg btn-default dispute-btn"
                  />
                </div>
              </form>
            </>
          ) : (
            ep.dispute &&
            (ep.dispute.resolved_on === null ? (
              <>
                <div
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.props.setProductId(ep.product_id, eo.order_id);
                  }}
                  className="dm-header-text d-flex align-items-center justify-content-between"
                >
                  <span>Create Dispute</span>
                  <div
                    className="close-icon"
                    onClick={() => {
                      this.props.setProductId({
                        productId: "",
                        orderId: "",
                      });
                    }}
                  >
                    <img src={closeIcon} alt="" />
                  </div>
                </div>
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    {this.renderText("notes", "Notes")}
                  </div>

                  <div className="form-group custom-upload">
                    <FileInput
                      totalUploaded={this.state.data.pics.length}
                      flag="files"
                      error={this.state.errors["pics"]}
                      label="Upload Image"
                      name="pics"
                      status={this.state.photoStatus}
                      onchange={this.fileUpload}
                      text={this.state.data["pics"]}
                    />
                    <span className="note">Max file size allowed 1 MB</span>
                  </div>
                  <ul className="delete-img-container d-flex">
                    {this.state.data.pics.map((e, i) => (
                      <li key={i}>
                        <div className="delete-img-box">
                          <img
                            width="180px"
                            src={e ? process.env.REACT_APP_S3URL + e.small : ""}
                            alt="img"
                          />
                          <div
                            className="delete-img"
                            onClick={(ele) => this.remoVeImg(ele, e)}
                          >
                            <img src={deleteIcon} alt="" />
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="form-group">
                    <SubmitBtn
                      label="Submit"
                      loading={this.state.loading}
                      btnClass="btn btn-lg btn-default dispute-btn"
                    />
                  </div>
                </form>
                <div className="form-group-box">
                  {ep.dispute &&
                    ep.dispute.messages &&
                    ep.dispute.messages.map((msg) => (
                      <>
                        <p>{msg.notes}</p>
                        <ul className="tpi-list custom-img d-flex flex-wrap">
                          {msg.images &&
                            msg.images.map((img) => (
                              <>
                                <li>
                                  <img
                                    onClick={() =>
                                      this.setState({
                                        isDisputeOpen: true,
                                      })
                                    }
                                    src={
                                      img &&
                                      process.env.REACT_APP_S3URL + img.small
                                    }
                                    alt=""
                                  />
                                </li>
                                {isDisputeOpen && (
                                  <Lightbox
                                    mainSrc={
                                      img
                                        ? process.env.REACT_APP_S3URL +
                                          img.original
                                        : ""
                                    }
                                    // mainSrc={
                                    //   msg.images[photoIndex]
                                    //     ? process.env.REACT_APP_S3URL +
                                    //       msg.images[photoIndex]
                                    //     : ""
                                    // }
                                    // nextSrc={
                                    //   msg.images.length === photoIndex + 1
                                    //     ? false
                                    //     : msg.images[photoIndex + 1]
                                    // }
                                    // prevSrc={
                                    //   photoIndex === 0
                                    //     ? false
                                    //     : msg.images[photoIndex - 1]
                                    // }
                                    onCloseRequest={() =>
                                      this.setState({
                                        isDisputeOpen: false,
                                      })
                                    }
                                    onMovePrevRequest={() =>
                                      this.setState({
                                        photoIndex: photoIndex - 1,
                                      })
                                    }
                                    onMoveNextRequest={() =>
                                      this.setState({
                                        photoIndex: photoIndex + 1,
                                      })
                                    }
                                  />
                                )}
                              </>
                            ))}
                        </ul>
                        <p>
                          <span>
                            By{" "}
                            {msg.customer
                              ? msg.customer.first_name +
                                " " +
                                msg.customer.last_name
                              : msg.seller.first_name +
                                " " +
                                msg.seller.last_name}{" "}
                            on {Moment(msg.date).format("LL")}
                          </span>
                        </p>
                      </>
                    ))}
                </div>
              </>
            ) : (
              <>
                <div
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.props.setProductId(ep.product_id, eo.order_id);
                  }}
                  className="dm-header-text d-flex align-items-center justify-content-between"
                >
                  <span>Create New Dispute</span>
                  <div
                    className="close-icon"
                    onClick={() => {
                      this.props.setProductId({
                        productId: "",
                        orderId: "",
                      });
                    }}
                  >
                    <img src={closeIcon} alt="" />
                  </div>
                </div>
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    {this.renderText("notes", "Notes")}
                  </div>

                  <div className="form-group custom-upload">
                    <FileInput
                      totalUploaded={this.state.data.pics.length}
                      flag="files"
                      error={this.state.errors["pics"]}
                      label="Upload Image"
                      name="pics"
                      status={this.state.photoStatus}
                      onchange={this.fileUpload}
                      text={this.state.data["pics"]}
                    />
                    <span className="note">Max file size allowed 1 MB</span>
                  </div>
                  <ul className="delete-img-container d-flex">
                    {this.state.data.pics.map((e, i) => (
                      <li key={i}>
                        <div className="delete-img-box">
                          <img
                            width="180px"
                            src={e ? process.env.REACT_APP_S3URL + e.small : ""}
                            alt="img"
                          />
                          <div
                            className="delete-img"
                            onClick={(ele) => this.remoVeImg(ele, e)}
                          >
                            <img src={deleteIcon} alt="" />
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="form-group">
                    <SubmitBtn
                      label="Submit"
                      loading={this.state.loading}
                      btnClass="btn btn-lg btn-default dispute-btn"
                    />
                  </div>
                </form>
                <div className="form-group-box">
                  {ep.dispute &&
                    ep.dispute.messages &&
                    ep.dispute.messages.map((msg) => (
                      <>
                        <p>{msg.notes}</p>
                        <ul className="tpi-list custom-img d-flex flex-wrap">
                          {msg.images &&
                            msg.images.map((img) => (
                              <>
                                <li>
                                  <img
                                    onClick={() =>
                                      this.setState({
                                        isDisputeOpen: true,
                                      })
                                    }
                                    src={
                                      img &&
                                      process.env.REACT_APP_S3URL + img.small
                                    }
                                    alt=""
                                  />
                                </li>
                                {isDisputeOpen && (
                                  <Lightbox
                                    mainSrc={
                                      img
                                        ? process.env.REACT_APP_S3URL +
                                          img.original
                                        : ""
                                    }
                                    onCloseRequest={() =>
                                      this.setState({
                                        isDisputeOpen: false,
                                      })
                                    }
                                    onMovePrevRequest={() =>
                                      this.setState({
                                        photoIndex: photoIndex - 1,
                                      })
                                    }
                                    onMoveNextRequest={() =>
                                      this.setState({
                                        photoIndex: photoIndex + 1,
                                      })
                                    }
                                  />
                                )}
                              </>
                            ))}
                        </ul>
                        <p>
                          <span>
                            By{" "}
                            {msg.customer
                              ? msg.customer.first_name +
                                " " +
                                msg.customer.last_name
                              : msg.seller.first_name +
                                " " +
                                msg.seller.last_name}{" "}
                            on {Moment(msg.date).format("LL")}
                          </span>
                        </p>
                      </>
                    ))}
                </div>
              </>
            ))
          )}
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  disputePhoto: getCustomer(state).disputePhoto,
});

const mapDispatchToProps = (dispatch) => ({
  addOrderDispute: (data, callback) =>
    dispatch(addOrderDispute(data, callback)),
  loadOrders: (params) => dispatch(loadOrders(params)),
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
  uploadDisputePictures: (data, callback) =>
    dispatch(uploadDisputePictures(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DisputeOrder)
);
