import React, { Component, createRef } from "react";
import TopBar from "../common/topBar";
// import Footer from "../common/footer";
import { connect } from "react-redux";

import Pagination from "../common/Pagination";

import image1 from "../../include/images/no-result-img.svg";
import vector from "../../include/images/Vector.svg";

import {
  getSeller,
  loadOrderDetails,
  loadDisputeReturnOrder,
} from "../../store/seller";
import Moment from "moment";

import OrderDetails from "../common/orderDetails";

import SortingIconUp from "../common/sortingIconUp";
import SortingIcon from "../common/sortingIconDown";
import { withRouter } from "react-router-dom";

class DisputesReturnRequests extends Component {
  ref = createRef(null);

  state = {
    productId: "",
    disputeProductId: "",
    returnProductId: "",
    isOrderDetails: false,
    isAdvanceSearch: false,
    search: "",
    sort: "desc",
    sort_by: "date",
    params: {},
    settingDropdown: false,
    profileDropdown: false,
  };

  openAdvanceSearch = () => {
    this.setState({ isAdvanceSearch: !this.state.isAdvanceSearch });
  };

  openDetails = (id) => {
    this.props.loadOrderDetails(id);
    this.setState({ isOrderDetails: !this.state.isOrderDetails });
  };

  closeDetails = () => {
    this.setState({ isOrderDetails: !this.state.isOrderDetails });
  };

  componentDidMount = () => {
    this.props.loadDisputeReturnOrder({
      sort_by: this.state.sort_by,
      sort: this.state.sort,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.sort_by !== prevState.sort_by ||
      this.state.sort !== prevState.sort
    ) {
      this.props.loadDisputeReturnOrder({
        sort_by: this.state.sort_by,
        sort: this.state.sort,
      });
    }
    if (this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.continuousStart();
    }
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.complete();
    }
  };

  onSearch = (e) => {
    if (this.state.search) {
      this.props.loadDisputeReturnOrder({ order_id: this.state.search });
    }
  };

  onKeyPress = (e) => {
    if (e.which === 13 && this.state.search) {
      this.props.loadDisputeReturnOrder({ order_id: this.state.search });
    }
  };

  setProductId = (productId) => {
    this.setState({
      productId: productId === this.state.productId ? "" : productId,
      disputeProductId: "",
      returnProductId: "",
    });
  };

  setDisputeProductId = (disputeProductId) => {
    this.setState({
      disputeProductId,
      productId: "",
    });
  };

  setReturnProductId = (returnProductId) => {
    this.setState({
      returnProductId,
      productId: "",
    });
  };
  
  onChangePage = (data) => {
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
    });
    if (data.page !== this.state.currentPage) {
      this.props.loadDisputeReturnOrder({ page: data.page });
    }
  };

  sortBy = (param) => {
    let sort;
    if (this.state.sort === "asc") {
      sort = "desc";
    } else {
      sort = "asc";
    }
    this.setState({
      sort_by: param,
      sort,
    });
  };

  handleSettingDropdown = () => {
    if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
      this.setState({ settingDropdown: !this.state.settingDropdown });
    } else {
      this.setState({ settingDropdown: !this.state.settingDropdown });
    }
  };

  handleProfileDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
      this.setState({ profileDropdown: !this.state.profileDropdown });
    } else {
      this.setState({ profileDropdown: !this.state.profileDropdown });
    }
  };

  setSettingDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
    } else if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
    }
  };

  render() {
    const { record_per_page, total_page, current_page, filtered_record } =
      this.props.pagination;
    return (
      <>
        <main
          id="main"
          onClick={() => {
            this.setState({ productId: "" });
            this.setSettingDropdown();
          }}
        >
          <TopBar
            loaDerRef={this.ref}
            sellerProfile={this.props.sellerProfile}
            handleProfileDropdown={this.handleProfileDropdown}
            handleSettingDropdown={this.handleSettingDropdown}
            settingDropdown={this.state.settingDropdown}
            profileDropdown={this.state.profileDropdown}
          />
          {!this.props.loading &&
          this.props.disputeReturnOrders.length === 0 ? (
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="no-result-content">
                    <div className="no-result-box d-inline-block">
                      <div className="no-result-img">
                        <img src={image1} alt="" />
                      </div>
                      <div className="no-result-text">
                        <h2>Welcome Aboard!</h2>
                        <p>
                          There are no disputed or return requested orders yet.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="dashboard-container custom-top">
              <div className="search-item-container order-search-item-container">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="search-item-content d-flex flex-column flex-md-row align-items-md--center justify-content-end justify-content-md-between">
                        <div className="flex-grow-1 d-flex align-items-center">
                          <div
                            className="input-group"
                            onKeyPress={this.onKeyPress}
                            onClick={this.onSearch}
                          >
                            <div
                              className="input-group-prepend"
                              onKeyPress={this.onKeyPress}
                              onClick={this.onSearch}
                            >
                              {" "}
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                <img src={vector} alt="" />
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search here"
                              aria-label="search"
                              aria-describedby="basic-addon1"
                              value={this.state.search}
                              onChange={(e) =>
                                this.setState({ search: e.target.value })
                              }
                            />
                          </div>
                          {/* <a
                          href="javascript:void(0)"
                          onClick={this.openAdvanceSearch}
                          className="search-text sidebar_toggler"
                          data-toggle="advancedSearch"
                        >
                          Advanced Search
                        </a> */}
                        </div>
                        <div className="showing-result d-flex align-items-center justify-content-end">
                          <div className="result-number">
                            Showing{" "}
                            <strong>
                              {this.props.pagination
                                .total_record_on_current_page &&
                                this.props.pagination
                                  .total_record_on_current_page}
                            </strong>{" "}
                            of{" "}
                            <strong>
                              {this.props.pagination &&
                                this.props.pagination.filtered_record}
                            </strong>{" "}
                            results
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {!this.props.loading && (
                <div className="product-list-item-container ">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="product-list-item-content">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th
                                    onClick={() => this.sortBy("order_id")}
                                    style={{ width: "250px" }}
                                  >
                                    Orders{" "}
                                    {/* {this.state.sort_by === "order_id" &&
                                  this.state.sort === "asc" ? (
                                    <SortingIcon sorting={() => {}} />
                                  ) : (
                                    <SortingIconUp sorting={() => {}} />
                                  )} */}
                                  </th>
                                  <th
                                    onClick={() => this.sortBy("tax")}
                                    style={{ width: "2000px" }}
                                  >
                                    Products{" "}
                                    {/* {this.state.sort_by === "tax" &&
                                this.state.sort === "asc" ? (
                                  <SortingIcon sorting={() => {}} />
                                ) : (
                                  <SortingIconUp sorting={() => {}} />
                                )} */}
                                  </th>
                                  <th
                                    onClick={() => this.sortBy("amount")}
                                    style={{ width: "250px" }}
                                  >
                                    Amount{" "}
                                    {/* {this.state.sort_by === "amount" &&
                                  this.state.sort === "asc" ? (
                                    <SortingIcon sorting={() => {}} />
                                  ) : (
                                    <SortingIconUp sorting={() => {}} />
                                  )} */}
                                  </th>

                                  <th style={{ width: "250px" }}>Type </th>
                                  <th
                                    onClick={() => this.sortBy("status")}
                                    style={{ width: "250px" }}
                                  >
                                    Status{" "}
                                    {/* {this.state.sort_by === "status" &&
                                  this.state.sort === "asc" ? (
                                    <SortingIcon sorting={() => {}} />
                                  ) : (
                                    <SortingIconUp sorting={() => {}} />
                                  )} */}
                                  </th>
                                  <th
                                    onClick={() => this.sortBy("status")}
                                    style={{ width: "1400px" }}
                                  >
                                    Dispute/Return Status{" "}
                                  </th>
                                  <th
                                    onClick={() => this.sortBy("date")}
                                    style={{ width: "1000px" }}
                                  >
                                    Date{" "}
                                    {this.state.sort_by === "date" &&
                                    this.state.sort === "asc" ? (
                                      <SortingIcon sorting={() => {}} />
                                    ) : (
                                      <SortingIconUp sorting={() => {}} />
                                    )}
                                  </th>
                                  <th style={{ width: "100px" }}></th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.disputeReturnOrders &&
                                  this.props.disputeReturnOrders.map((e, i) => (
                                    <tr key={i}>
                                      <td>{e.order_id && e.order_id}</td>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <div className="symbol symbol-40 symbol-sm flex-shrink-0">
                                            <img
                                              className=""
                                              src={
                                                e.product.image
                                                  ? process.env
                                                      .REACT_APP_S3URL +
                                                    e.product.image[0].small
                                                  : ""
                                              }
                                              alt="product"
                                            />
                                          </div>
                                          <div className="ml-4">
                                            <b>
                                              {e.product.product_name &&
                                                e.product.product_name}
                                            </b>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <b>
                                          $
                                          {e.product &&
                                            e.product.subtotal.toLocaleString(
                                              undefined,
                                              {
                                                minimumFractionDigits: 2,
                                              }
                                            )}
                                        </b>
                                        <small>
                                          {" "}
                                          $
                                          {e.product &&
                                            e.product.tax_total.toLocaleString(
                                              undefined,
                                              { minimumFractionDigits: 2 }
                                            )}{" "}
                                        </small>
                                      </td>

                                      <td>
                                        <b>
                                          {e.product.delivery_type &&
                                            e.product.delivery_type}
                                        </b>
                                      </td>

                                      <td>
                                        <div
                                          className={
                                            e.product.status === "Pending"
                                              ? "pending"
                                              : "fulfilled"
                                          }
                                        >
                                          {e.product.status && e.product.status}
                                        </div>
                                      </td>

                                      <td>
                                        {e.product.returnRequest &&
                                        Object.entries(e.product.returnRequest)
                                          .length !== 0 ? (
                                          <div className="fulfilled">
                                            {e.request_status}
                                          </div>
                                        ) : e.product.dispute !== null ? (
                                          e.product.dispute.resolved_on !==
                                          null ? (
                                            <div className="fulfilled">
                                              Resolved
                                            </div>
                                          ) : (
                                            <div className="pending">
                                              Disputed
                                            </div>
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </td>

                                      <td>
                                        {Moment(e.created_on).format("LL")}{" "}
                                        <small>
                                          {" "}
                                          {Moment(e.created_on).format("LT")}
                                        </small>
                                      </td>
                                      <td className="editable">
                                        {" "}
                                        <a
                                          onClick={() => {
                                            this.openDetails(e.order_id);
                                          }}
                                          href="javascript:void(0)"
                                          className=" btn btn-default outline sidebar_toggler"
                                          data-toggle="viewOrder"
                                        >
                                          view
                                        </a>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <Pagination
                          totalRecords={filtered_record}
                          total_page={total_page}
                          pageLimit={record_per_page}
                          initialPage={current_page}
                          pagesToShow={5}
                          onChangePage={this.onChangePage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <OrderDetails
            loadOrderDetails={this.props.loadOrderDetails}
            setProductId={this.setProductId}
            productId={this.state.productId}
            setReturnProductId={this.setReturnProductId}
            returnProductId={this.state.returnProductId}
            setDisputeProductId={this.setDisputeProductId}
            disputeProductId={this.state.disputeProductId}
            isOrderDetails={this.state.isOrderDetails}
            closeDetails={this.closeDetails}
            orderDetails={this.props.orderDetails}
          />
          {/* <Footer /> */}
        </main>
        <div
          className={
            this.state.isAdvanceSearch || this.state.isOrderDetails
              ? "overlay show"
              : "overlay"
          }
        ></div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  disputeReturnOrders: getSeller(state).disputeReturnOrders.data,
  orderDetails: getSeller(state).orderDetails,
  pagination: getSeller(state).disputeReturnOrders.pagination,
  loading: getSeller(state).disputeReturnOrdersLoading,
});

const mapDispatchToProps = (dispatch) => ({
  loadOrderDetails: (id) => dispatch(loadOrderDetails(id)),
  loadDisputeReturnOrder: (params) => dispatch(loadDisputeReturnOrder(params)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DisputesReturnRequests)
);
