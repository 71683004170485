import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "coupons",
  initialState: {
    category: [],
    coupons: {
      data: [],
      pagination: {},
    },
    detail: {},
    coupon: [],
    pagination: {},
    update: {},
    add: {},
    loading: true,
    lastFetch: null,
    couponsLoading: true,
    detailLoading: true,
    couponsLastFetch: null,
    categoryLoading: false,
    categoryLastFetch: null,
  },
  reducers: {
    couponsRequested: (coupon, action) => {
      coupon.couponsLoading = true;
    },

    couponsReceived: (coupon, action) => {
      coupon.coupons = action.payload;
      coupon.couponsLoading = false;
      coupon.couponsLastFetch = Date.now();
    },

    couponsRequestFailed: (coupon, action) => {
      coupon.couponsLoading = false;
    },

    categoryRequested: (coupon, action) => {
      coupon.categoryLoading = true;
    },

    categoryReceived: (coupon, action) => {
      coupon.category = action.payload.data;
      coupon.categoryLoading = false;
      coupon.categoryLastFetch = Date.now();
    },

    categoryRequestFailed: (coupon, action) => {
      coupon.categoryLoading = false;
    },

    couponRequested: (coupon, action) => {
      coupon.loading = true;
    },

    couponReceived: (coupon, action) => {
      coupon.coupon = action.payload.data;
      coupon.pagination = action.payload.pagination;
      coupon.loading = false;
      coupon.lastFetch = Date.now();
    },

    couponRequestFailed: (coupon, action) => {
      coupon.loading = false;
    },
    coupondetailRequested: (coupon, action) => {
      coupon.detailLoading = true;
    },

    coupondetailReceived: (coupon, action) => {
      coupon.detail = action.payload;
      coupon.detailLoading = false;
      coupon.lastFetch = Date.now();
    },

    coupondetailRequestFailed: (coupon, action) => {
      coupon.detailLoading = false;
    },
    updatedCoupon: (coupon, action) => {
      coupon.update = action.payload;
      coupon.loading = false;
      coupon.lastFetch = Date.now();
    },

    updatedCouponRequestFailed: (coupon, action) => {
      coupon.loading = false;
    },
    couponAdded: (coupon, action) => {
      coupon.add = action.payload;
      coupon.loading = false;
      coupon.lastFetch = Date.now();
    },

    couponAddRequestFailed: (coupon, action) => {
      coupon.loading = false;
    },
  },
});

export const {
  couponsRequested,
  couponsReceived,
  couponsRequestFailed,
  categoryRequested,
  categoryReceived,
  categoryRequestFailed,
  couponRequested,
  couponReceived,
  couponRequestFailed,
  coupondetailRequested,
  coupondetailReceived,
  coupondetailRequestFailed,
  updatedCoupon,
  updatedCouponRequestFailed,
  couponAdded,
  couponAddRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const loadCoupons = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: "discountCoupon",
      params,
      onStart: couponsRequested.type,
      onSuccess: couponsReceived.type,
      onError: couponsRequestFailed.type,
    })
  );
};

export const loadCategory = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: "category",
      onStart: categoryRequested.type,
      onSuccess: categoryReceived.type,
      onError: categoryRequestFailed.type,
    })
  );
};

export const loadAllCoupons = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: "discountCoupon",
      params,
      onStart: couponRequested.type,
      onSuccess: couponReceived.type,
      onError: couponRequestFailed.type,
    })
  );
};

export const updateCoupon = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "discountCoupon/" + id,
      method: "PUT",
      data,
      callback,
      onSuccess: updatedCoupon.type,
      onError: updatedCouponRequestFailed.type,
    })
  );
};

export const addCoupon = (param, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url: "discountCoupon",
      method: "POST",
      data: param,
      onSuccess: couponAdded.type,
      onError: couponAddRequestFailed.type,
    })
  );
};

export const deletedCoupon = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: "discountCoupon/" + id,
      method: "delete",
      callback,
    })
  );
};

export const getCoupon = createSelector(
  (state) => state.entities.coupons,
  (coupon) => coupon
);
