import React, { createRef } from "react";
import CustomerSidebar from "../../common/customer/customerSidebar";
import Header from "../../productV2/header";
import {
  loadOrders,
  getCustomer,
  loadCustomerProfile,
} from "../../../store/customer";
import Footer from "../../common/footer";
import StarRatings from "react-star-ratings";

import LoadingBar from "react-top-loading-bar";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PostReview from "../../common/customer/postReview";

import noOrderHistory from "../../../include/images/order-history-icon.svg";
import DisputeOrder from "../../common/customer/disputeOrder";
import ReturnRequest from "../../common/customer/returnRequest";

class OrderHistory extends React.Component {
  ref = createRef(null);

  state = {
    sellerReview: false,
    productReview: false,
    id: "",
    product_id: "",
    productId: "",
    orderId: "",
    returnProduct: "",
    returnOrderId: "",
  };

  rateProduct = (id, product_id) => {
    document.body.classList.add("toggled-tab");
    this.setState({
      productReview: !this.state.productReview,
      id: id,
      product_id: product_id,
    });
  };

  rateSeller = (id) => {
    document.body.classList.add("toggled-tab");
    this.setState({ sellerReview: !this.state.sellerReview, id: id });
  };

  handleClose = () => {
    document.body.classList.remove("toggled-tab");
    if (this.state.productReview === true) {
      this.setState({ productReview: !this.state.productReview });
    } else {
      this.setState({ sellerReview: !this.state.sellerReview });
    }
  };

  componentDidMount = () => {
    this.props.loadOrders();
    this.props.loadCustomerProfile();
    this.ref.current.continuousStart();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.complete();
    }
  };

  setProductId = (productId, orderId) =>
    this.setState({
      productId: productId === this.state.productId ? "" : productId,
      orderId: orderId === this.state.orderId ? "" : orderId,
    });

  setReturnProductId = (returnProduct, returnOrderId) =>
    this.setState({
      returnProduct:
        returnProduct === this.state.returnProduct ? "" : returnProduct,
      returnOrderId:
        returnOrderId === this.state.returnOrderId ? "" : returnOrderId,
    });

  render() {
    return (
      <>
        <LoadingBar color="#0b4474" ref={this.ref} />
        <Header />
        <main id="main">
          <div className="content-container">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="conetnt-box d-md-flex flex-md-wrap">
                    <CustomerSidebar />
                    {!this.props.loading &&
                      (this.props.customerOrders.length === 0 ? (
                        <>
                          <div className="main-content-box d-flex align-items-center justify-content-center">
                            <div className="empty-order-box text-center">
                              <div className="eob-icon">
                                <img src={noOrderHistory} alt="" />
                              </div>
                              <h4>You have not placed any order yet</h4>
                              {/* <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua.
                              </p> */}
                              <div className="seprator"></div>
                              <div className="eob-btn">
                                <a
                                  href="/products-listing"
                                  className="btn btn-default"
                                >
                                  Continue Shopping
                                </a>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="main-content-box mob-pb-0">
                            <h3>Order History</h3>
                            {this.props.customerOrders.map((e, i) => (
                              <>
                                <div className="order-box" key={i}>
                                  <div className="ob-head d-flex flex-wrap justify-content-between">
                                    <div className="obh-left">
                                      <h6>
                                        Order number: #
                                        {e.order_id && e.order_id}
                                      </h6>
                                      <p>
                                        Status :{" "}
                                        <span>{e.status && e.status}</span>
                                      </p>
                                    </div>
                                    <div className="obh-right">
                                      <h6>
                                        From{" "}
                                        <span>
                                          {e.seller_company && e.seller_company}
                                        </span>
                                      </h6>
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          color: "#297ab9",
                                        }}
                                      >
                                        Payment Status :{" "}
                                        <span>
                                          {/* {!e.pay_later ? "Paid" : "Unpaid"} */}
                                          {e.status === "Pending" ? (
                                            e.pay_later ? (
                                              <b>Unpaid</b>
                                            ) : (
                                              <b>Paid</b>
                                            )
                                          ) : (
                                            <b>Paid</b>
                                          )}
                                        </span>
                                      </p>
                                      {e.sellerReview &&
                                      Object.entries(e.seller_review).length !==
                                        0 ? (
                                        <StarRatings
                                          rating={e.seller_review.rating}
                                          starDimension="15px"
                                          starSpacing="3px"
                                          isSelectable="false"
                                          starEmptyColor="#C4C4C4"
                                          starRatedColor="#ffa51e"
                                          starHoverColor="#ffa51e"
                                        />
                                      ) : (
                                        e.status === "Fulfilled" && (
                                          <p>
                                            <a
                                              onClick={() => {
                                                this.rateSeller(e._id);
                                              }}
                                              href="javascript:void(0)"
                                              className="btn btn-white"
                                            >
                                              Rate this Seller
                                            </a>
                                          </p>
                                        )
                                      )}
                                    </div>
                                  </div>
                                  <div className="ob-body mob-pl-0 mb-pt-0">
                                    <div className="table-responsive table_wrap">
                                      <table className="table small mb-0 table-wrap">
                                        <thead>
                                          <tr>
                                            <th>Products</th>
                                            <th></th>
                                            <th style={{ width: "150px" }}>
                                              Rating
                                            </th>
                                            <th
                                              style={{
                                                width: "124px",
                                                textAlign: "right",
                                              }}
                                            >
                                              Quantity
                                            </th>
                                            <th
                                              style={{
                                                width: "130px",
                                                textAlign: "right",
                                              }}
                                            >
                                              Price
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {e.products &&
                                            e.products.map((p, i) => (
                                              <tr key={i}>
                                                <td className="td-image text-md-center">
                                                  {p.image &&
                                                    p.image.map((img, k) => (
                                                      <span key={k}>
                                                        <img
                                                          src={
                                                            img &&
                                                            process.env
                                                              .REACT_APP_S3URL +
                                                              img.small
                                                          }
                                                          alt=""
                                                        />
                                                      </span>
                                                    ))}
                                                </td>
                                                <td>
                                                  <b>
                                                    {p.product_name &&
                                                      p.product_name}
                                                  </b>
                                                  <div className="code-dispute">
                                                    {p.dispute === null ? (
                                                      <div
                                                        className={
                                                          this.state
                                                            .productId ===
                                                            p.product_id &&
                                                          this.state.orderId ===
                                                            e.order_id
                                                            ? "table-dropdown btn-group dropdown show"
                                                            : "table-dropdown btn-group dropdown"
                                                        }
                                                      >
                                                        <button
                                                          id={
                                                            p.product_id &&
                                                            e.order_id
                                                          }
                                                          onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            this.setProductId(
                                                              p.product_id,
                                                              e.order_id
                                                            );
                                                          }}
                                                          className="code-dispute dropdown-toggle"
                                                          type="button"
                                                          data-toggle="dropdown"
                                                          aria-haspopup="true"
                                                          aria-expanded="false"
                                                        >
                                                          Create Dispute
                                                        </button>

                                                        <DisputeOrder
                                                          productId={
                                                            this.state.productId
                                                          }
                                                          orderId={
                                                            this.state.orderId
                                                          }
                                                          ep={p}
                                                          eo={e}
                                                          setProductId={
                                                            this.setProductId
                                                          }
                                                        />
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className={
                                                          this.state
                                                            .productId ===
                                                            p.product_id &&
                                                          this.state.orderId ===
                                                            e.order_id
                                                            ? "table-dropdown btn-group dropdown show"
                                                            : "table-dropdown btn-group dropdown"
                                                        }
                                                      >
                                                        <button
                                                          id={
                                                            p.product_id &&
                                                            e.order_id
                                                          }
                                                          onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            this.setProductId(
                                                              p.product_id,
                                                              e.order_id
                                                            );
                                                          }}
                                                          className={
                                                            p.dispute &&
                                                            p.dispute
                                                              .resolved_on ===
                                                              null
                                                              ? "code-dispute dropdown-toggle"
                                                              : "resolved-text green-imp dropdown-toggle"
                                                          }
                                                          type="button"
                                                          data-toggle="dropdown"
                                                          aria-haspopup="true"
                                                          aria-expanded="false"
                                                        >
                                                          {p.dispute &&
                                                          p.dispute
                                                            .resolved_on ===
                                                            null
                                                            ? "Disputed"
                                                            : "Resolved"}
                                                        </button>

                                                        <DisputeOrder
                                                          productId={
                                                            this.state.productId
                                                          }
                                                          orderId={
                                                            this.state.orderId
                                                          }
                                                          ep={p}
                                                          eo={e}
                                                          setProductId={
                                                            this.setProductId
                                                          }
                                                        />
                                                      </div>
                                                    )}
                                                  </div>

                                                  <div className="code-dispute">
                                                    {(e.status ===
                                                      "Fulfilled" ||
                                                      p.status ===
                                                        "Delivered" ||
                                                      p.status ===
                                                        "Picked up") &&
                                                      (p.returnRequest &&
                                                      Object.entries(
                                                        p.returnRequest
                                                      ).length !== 0 ? (
                                                        <div
                                                          className={
                                                            this.state
                                                              .returnProduct ===
                                                              p.product_id &&
                                                            this.state
                                                              .returnOrderId ===
                                                              e.order_id
                                                              ? "table-dropdown btn-group dropdown show"
                                                              : "table-dropdown btn-group dropdown"
                                                          }
                                                        >
                                                          <button
                                                            id={
                                                              p.product_id &&
                                                              e.order_id
                                                            }
                                                            onClick={(
                                                              event
                                                            ) => {
                                                              event.preventDefault();
                                                              event.stopPropagation();
                                                              this.setReturnProductId(
                                                                p.product_id,
                                                                e.order_id
                                                              );
                                                            }}
                                                            className="resolved-text green-imp dropdown-toggle"
                                                            type="button"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                          >
                                                            {
                                                              p.returnRequest
                                                                .status
                                                            }
                                                          </button>

                                                          <ReturnRequest
                                                            returnProduct={
                                                              this.state
                                                                .returnProduct
                                                            }
                                                            returnOrderId={
                                                              this.state
                                                                .returnOrderId
                                                            }
                                                            ep={p}
                                                            eo={e}
                                                            setReturnProductId={
                                                              this
                                                                .setReturnProductId
                                                            }
                                                          />
                                                        </div>
                                                      ) : (
                                                        (p.status ===
                                                          "Delivered" ||
                                                          p.status ===
                                                            "Picked up") && (
                                                          <div
                                                            className={
                                                              this.state
                                                                .returnProduct ===
                                                                p.product_id &&
                                                              this.state
                                                                .returnOrderId ===
                                                                e.order_id
                                                                ? "table-dropdown btn-group dropdown show"
                                                                : "table-dropdown btn-group dropdown"
                                                            }
                                                          >
                                                            <button
                                                              id={
                                                                p.product_id &&
                                                                e.order_id
                                                              }
                                                              onClick={(
                                                                event
                                                              ) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                this.setReturnProductId(
                                                                  p.product_id,
                                                                  e.order_id
                                                                );
                                                              }}
                                                              className="code-dispute dropdown-toggle"
                                                              type="button"
                                                              data-toggle="dropdown"
                                                              aria-haspopup="true"
                                                              aria-expanded="false"
                                                            >
                                                              Return{" "}
                                                            </button>

                                                            <ReturnRequest
                                                              returnProduct={
                                                                this.state
                                                                  .returnProduct
                                                              }
                                                              returnOrderId={
                                                                this.state
                                                                  .returnOrderId
                                                              }
                                                              ep={p}
                                                              eo={e}
                                                              setReturnProductId={
                                                                this
                                                                  .setReturnProductId
                                                              }
                                                            />
                                                          </div>
                                                        )
                                                      ))}
                                                  </div>
                                                </td>

                                                <td>
                                                  <div className="stars-icon d-flex align-items-center">
                                                    {p.review ? (
                                                      <StarRatings
                                                        rating={p.review.rating}
                                                        starDimension="18px"
                                                        starSpacing="3px"
                                                        isSelectable="false"
                                                        starEmptyColor="#C4C4C4"
                                                        starRatedColor="#ffa51e"
                                                        starHoverColor="#ffa51e"
                                                      />
                                                    ) : (
                                                      e.status ===
                                                        "Fulfilled" && (
                                                        <p>
                                                          <a
                                                            onClick={() => {
                                                              this.rateProduct(
                                                                e._id,
                                                                p.product_id
                                                              );
                                                            }}
                                                            href="javascript:void(0)"
                                                            className="btn btn-white"
                                                          >
                                                            Rate this Product
                                                          </a>
                                                        </p>
                                                      )
                                                    )}
                                                  </div>
                                                </td>
                                                <td
                                                  style={{ textAlign: "right" }}
                                                >
                                                  {p.quantity && p.quantity}
                                                </td>
                                                <td
                                                  style={{ textAlign: "right" }}
                                                >
                                                  <b>
                                                    $
                                                    {p.subtotal &&
                                                      p.subtotal.toLocaleString(
                                                        undefined,
                                                        {
                                                          minimumFractionDigits: 2,
                                                        }
                                                      )}
                                                  </b>
                                                  {p.discount &&
                                                    p.discount !== null && (
                                                      <div className="sub-code">
                                                        Discount : $
                                                        {p.discount.toLocaleString(
                                                          undefined,
                                                          {
                                                            minimumFractionDigits: 2,
                                                          }
                                                        )}
                                                      </div>
                                                    )}
                                                  <div className="sub-code">
                                                    Tax : $
                                                    {p.tax_total &&
                                                      p.tax_total.toLocaleString(
                                                        undefined,
                                                        {
                                                          minimumFractionDigits: 2,
                                                        }
                                                      )}
                                                  </div>
                                                </td>
                                              </tr>
                                            ))}

                                          <tr>
                                            <td
                                              colSpan="2"
                                              className="mob-hide"
                                            >
                                              <b>Total</b>
                                            </td>
                                            <td
                                              colSpan="3"
                                              style={{ textAlign: "right" }}
                                            >
                                              <b className="grand-total d-block d-md-none">
                                                Total
                                              </b>
                                              <b>
                                                $
                                                {e.summary &&
                                                  e.summary.final_total.toLocaleString(
                                                    undefined,
                                                    {
                                                      minimumFractionDigits: 2,
                                                    }
                                                  )}
                                              </b>
                                              <div className="sub-code">
                                                Tax : $
                                                {e.summary &&
                                                  e.summary.tax_total.toLocaleString(
                                                    undefined,
                                                    {
                                                      minimumFractionDigits: 2,
                                                    }
                                                  )}
                                              </div>
                                              {e.summary.delivery_charge !==
                                                0 && (
                                                <div className="sub-code">
                                                  Delivery charge : $
                                                  {e.summary.delivery_charge &&
                                                    e.summary.delivery_charge.toLocaleString(
                                                      undefined,
                                                      {
                                                        minimumFractionDigits: 2,
                                                      }
                                                    )}
                                                </div>
                                              )}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PostReview
            productReview={this.state.productReview}
            sellerReview={this.state.sellerReview}
            handleClose={this.handleClose}
            _id={this.state.id}
            product_id={this.state.product_id}
          />
          <Footer />
        </main>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customerProfile: getCustomer(state).profile,
  customerOrders: getCustomer(state).orders.data,
  loading: getCustomer(state).ordersLoading,
});

const mapDispatchToProps = (dispatch) => ({
  loadOrders: (params) => dispatch(loadOrders(params)),
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderHistory)
);
