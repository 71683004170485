import React from "react";
import closeIcon from "../../../include/images/close.svg";

class ModalHeader extends React.Component {
  render() {
    return (
      <div className="modal-header">
        <h5 className="modal-title">Upload Products</h5>
        <div className="product-nav-box d-none d-md-block">
          <ul className="product-nav-list d-flex align-items-center">
            <li className={this.props.step === 1 ? "active" : ""}>
              <p
                className="pn-link"
              >
                Choose
              </p>
            </li>
            <li className={this.props.step === 2 ? "active" : ""}>
              <p
                className="pn-link"
              >
                Map
              </p>
            </li>
            <li className={this.props.step === 3 ? "active" : ""}>
              <p
                className="pn-link"
              >
                Setup
              </p>
            </li>
          </ul>
        </div>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={this.props.handleClose}
        >
          <img src={closeIcon} alt="" />
        </button>
      </div>
    );
  }
}

export default ModalHeader;
