import React, { Component } from "react";
import mapPin from "../../include/images/aim 1.svg";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import closeicon from "../../include/images/close_icon-1.svg";

const searchOptions = {
  types: ["address"],
  componentRestrictions: { country: "us" },
};
class AutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: "" };
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    this.setState({ address });
    geocodeByAddress(address)
      .then((results) => {
        const zipCode = results[0].address_components.find(
          (ra) => ra.types[0] === "postal_code"
        );
        if (!zipCode)
          return toast(
            <AlertError message="Invalid delivery address. Please enter your correct address." />
          );
        this.props.doSubmit(address, zipCode.long_name, {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        });
        getLatLng(results[0]);
        if (this.props.total_products == 0) {
          this.props.changeAddress();
        }
      })
      .catch((error) => console.error("Error", error));
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            {this.props.hero == "hero" ? (
              <div class="input-group">
                <div class="input-group-inside d-flex align-items-center">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                      <img src={mapPin} alt="" />
                    </span>
                  </div>
                  <div className="autocomplete-div">
                    <input
                      onChange={this.handleChange}
                      type="text"
                      class="form-control"
                      aria-label=""
                      value={this.state.value}
                      aria-describedby="basic-addon1"
                      style={{ opacity: "1" }}
                      {...getInputProps({
                        placeholder: "Enter your address",
                        className: "form-control hero-placeholder",
                      })}
                    />
                  </div>
                  <div
                    className="autocomplete-dropdown-container"
                    style={{
                      width: "100%",
                      position: "absolute",
                      top: "0",
                      left: "0",
                    }}
                  >
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                            color: "#000",
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                            color: "#000",
                          };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  class="input-group-append"
                  onClick={() => this.props.history.push("/products-listing")}
                >
                  Shop now
                </div>
              </div>
            ) : this.props.search === "search" ? (
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    <img src={mapPin} alt="" />
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  aria-label="search"
                  aria-describedby="basic-addon1"
                  // value={this.state.searchText}
                  // onKeyPress={(event) => {
                  //   if (event.key === "Enter") {
                  //     this.props.setKeyword(this.state.searchText);
                  //   }
                  // }}
                  // onChange={(e) =>
                  //   this.setState({ searchText: e.target.value })
                  // }
                  {...getInputProps({
                    placeholder: "Enter your address",
                    className: "form-control",
                  })}
                />
                <img
                  onClick={(e) => {
                    this.props.handleClose();
                  }}
                  class="close"
                  src={closeicon}
                  alt=""
                />
              </div>
            ) : (
              <div class="row">
                <div class="col-lg-12">
                  <div>
                    {this.props.search === "search" ? (
                      ""
                    ) : (
                      <label for="street1">Enter your delivery address</label>
                    )}
                    <div
                      class="form-group custom-input show on"
                      style={{
                        marginBottom: "1px",
                        marginLeft: "10px",
                      }}
                    >
                      <input
                        onChange={this.handleChange}
                        {...getInputProps({
                          placeholder: "",
                          className: "form-control input-data",
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.props.hero !== "hero" && (
              <div
                className="autocomplete-dropdown-container"
                style={{
                  width: "100%",
                  position: "absolute",
                  top: "0",
                  left: "0",
                }}
              >
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  const style = suggestion.active
                    ? {
                        backgroundColor: "#fafafa",
                        cursor: "pointer",
                        color: "#000",
                      }
                    : {
                        backgroundColor: "#ffffff",
                        cursor: "pointer",
                        color: "#000",
                      };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default AutoComplete;
