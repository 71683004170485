import React from "react";

import { bulkUploadProducts, getProduct } from "../../../store/product";
import { connect } from "react-redux";

class UploadModal3 extends React.Component {
  render() {
    return (
      <>
        <div className="modal-body">
          <div className="mob-product-nav d-block d-md-none">
            <ul className="product-nav-list d-flex align-items-center">
              <li className="active">
                <a href="#!" className="pn-link">
                  Choose
                </a>
              </li>
              <li>
                <a href="#!" className="pn-link">
                  Map
                </a>
              </li>
              <li>
                <a href="#!" className="pn-link">
                  Setup
                </a>
              </li>
            </ul>
          </div>
          <div className="step-content-box text-center">
            <h5>Upload Completed</h5>
            <ul className="import-details-list d-flex flex-wrap">
              <li>
                <div className="id-box text-center">
                  <b>
                    {this.props.bulkUpload.created &&
                      this.props.bulkUpload.created}
                  </b>
                  <p>New Imports</p>
                </div>
              </li>
              <li>
                <div className="id-box text-center">
                  <b>
                    {this.props.bulkUpload.updated &&
                      this.props.bulkUpload.updated}
                  </b>
                  <p>Updated</p>
                </div>
              </li>
              <li>
                <div className="id-box text-center">
                  <b>
                    {this.props.bulkUpload.errors &&
                      this.props.bulkUpload.errors}
                  </b>
                  <p>Errors</p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="modal-footer">
          <button
            onClick={this.props.closeUploadProducts}
            type="button"
            className="btn btn-default btn-xs"
          >
            Close
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  bulkUpload: getProduct(state).bulkUpload,
});
const mapDispatchToProps = (dispatch) => ({
  bulkUploadProducts: (data, callback) =>
    dispatch(bulkUploadProducts(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UploadModal3);
