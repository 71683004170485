import React from "react";
import Joi from "joi-browser";
import Form from "../common/form/form";
import SubmitBtn from "../common/form/submitBtn";

import close from "../../include/images/close.svg";

import {
  getCoupon,
  loadAllCoupons,
  addCoupon,
  updateCoupon,
  loadCategory,
  loadCoupons,
} from "../../store/coupons";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import {
  informationSaved,
  failed,
  couponCode,
  startDate,
  endDate,
  discountAmount,
  maxRedeem,
} from "../common/misc";
import SelectOptions from "../common/selectOptions";
import { getSeller, loadSellerProfile } from "../../store/seller";

const initialState = {
  discountType: [
    {
      label: "Percentage",
      value: "percentage",
    },
    { label: "Fix Amount", value: "fixAmount" },
  ],
  data: {
    code: "",
    startDate: "",
    minOrderAmount: "",
    endDate: "",
    discountType: "",
    maxRedeemCount: "",
    discount: "",
    maxRedeemCountPerCustomer: "",
  },
  allCategory: [
    {
      title: "All Categories",
      _id: "all",
    },
  ],
  errors: {},
};
class AddCoupons extends Form {
  state = initialState;

  componentDidMount = () => {
    this.props.loadCategory();
    this.props.loadSellerProfile();
  };

  schema = {
    code: Joi.string()
      .required()
      .error(() => {
        return { message: couponCode };
      }),
    category: Joi.object()
      .required()
      .label("Categories")
      .error(() => {
        return { message: "Categories field is required" };
      }),
    startDate: Joi.date()
      .required()
      .error(() => {
        return { message: startDate };
      }),
    minOrderAmount: Joi.number().optional().allow(""),
    allCategory: Joi.array().optional().allow(""),
    maxRedeemCount: Joi.number()
      .required()
      .error(() => {
        return { message: maxRedeem };
      }),
    maxRedeemCountPerCustomer: Joi.number()
      .required()
      .error(() => {
        return { message: maxRedeem };
      }),
    discount: Joi.number()
      .required()
      .error(() => {
        return { message: discountAmount };
      }),
    discountType: Joi.any()
      .required()
      .error(() => {
        return { message: "Discount Type field is required" };
      }),

    endDate: Joi.date()
      .required()
      .error(() => {
        return { message: endDate };
      }),
  };

  toggleClose = () => {
    this.setState(initialState);
    this.props.toggleEdit();
  };
  doSubmit = () => {
    const {
      code,
      startDate,
      minOrderAmount,
      discountType,
      endDate,
      category,
      maxRedeemCount,
      discount,
      maxRedeemCountPerCustomer,
    } = this.state.data;

    if (!category) {
      const { errors } = this.state;
      errors.category = "Category field is required";
      return this.setState({ errors });
    }
    var formdata;
    if (minOrderAmount === "") {
      formdata = {
        code: code,
        startDate: startDate,
        discountType:
          typeof discountType === "object" ? discountType.value : discountType,
        endDate: endDate,
        category: category.value,
        discount,
        maxRedeemCountPerCustomer,
        maxRedeemCount: maxRedeemCount,
        // maxDiscountAmount: maxDiscount,
      };
    } else {
      formdata = {
        code: code,
        startDate: startDate,
        minOrderAmount: minOrderAmount,
        discountType:
          typeof discountType === "object" ? discountType.value : discountType,
        endDate: endDate,
        category: category.value,
        discount,
        maxRedeemCountPerCustomer,
        maxRedeemCount: maxRedeemCount,
        // maxDiscountAmount: maxDiscount,
      };
    }
    if (this.props.data) {
      const id = this.props.data._id;
      this.props.updateCoupon(id, formdata, this.editCallback);
    } else {
      this.props.addCoupon(formdata, this.callback);
    }
  };
  editCallback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllCoupons({ page: 1 });
      this.setState(initialState);

      this.props.toggleEdit();
      toast(<AlertSuccess message={informationSaved} />);
    } else {
      this.props.toggleEdit();
      this.setState(initialState);
      toast(<AlertError message={failed} />);
    }
  };
  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllCoupons();
      this.setState({
        data: {
          code: "",
          startDate: "",
          minOrderAmount: "",
          endDate: "",
          discountType: "",
          category: "",
          discount: "",
          maxRedeemCount: "",
          maxRedeemCountPerCustomer: "",
        },
      });
      this.props.toggleAdd();
      toast(<AlertSuccess message={informationSaved} />);
    } else {
      this.setState({
        data: {
          code: "",
          startDate: "",
          minOrderAmount: "",
          endDate: "",
          discountType: "",
        },
      });
      this.props.toggleAdd();
      toast(<AlertError message={failed} />);
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.data && this.props.data !== prevProps.data) {
      const {
        code,
        startDate,
        minOrderAmount,
        discountType,
        endDate,
        category_title,
        category,
        discount,
        maxRedeemCount,
        maxRedeemCountPerCustomer,
      } = this.props.data;

      const data = {
        code: code,
        startDate: new Date(startDate),
        minOrderAmount: minOrderAmount,
        discountType: discountType,
        endDate: new Date(endDate),
        category: {
          label: category_title,
          value: category,
        },
        discount: discount,
        maxRedeemCount: maxRedeemCount,
        maxRedeemCountPerCustomer,
      };
      this.setState({ data });
    }
  };
  render() {
    const cat1 = this.state.allCategory;
    const cat2 = this.props.category;
    const combineCategories = Array.from(new Set(cat1.concat(cat2)));

    return (
      <div className="tab-modify-slidebar">
        <div className="tab-modify-box">
          <div className="tab-header d-flex justify-content-between">
            {this.props.isShow === true && <h3>Add Coupon</h3>}
            {this.props.editShow === true && <h3>Modify Coupon</h3>}
            {this.props.isShow === true ? (
              <a
                href="javascript:void(0)"
                className="close"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.toggleAdd();
                }}
              >
                <img src={close} alt="" />
              </a>
            ) : (
              <a
                href="javascript:void(0)"
                className="close"
                onClick={(e) => {
                  e.preventDefault();
                  this.toggleClose();
                }}
              >
                <img src={close} alt="" />
              </a>
            )}
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              {this.renderInput("code", "Coupon Code")}
            </div>

            {this.props.sellerProfile.category ? (
              <div className="form-group">
                <label> Categories </label>
                <SelectOptions
                  width={"auto"}
                  value={this.state.data.category}
                  onChange={(e) => {
                    const { data, errors } = this.state;
                    data.category = e;
                    if (!e) errors.category = "Category field is required";
                    this.setState({ data, errors });
                  }}
                  options={combineCategories.map((e) => ({
                    value: e._id,
                    label: e.title,
                    // label: "All Categories",
                    // value: "all",
                  }))}
                />
                {this.state.errors.category && (
                  <label
                    className="error"
                    style={{
                      fontSize: "12px",
                      lineHeight: "16px",
                      fontWeight: "400",
                      letterSpacing: "0.4px",
                      color: "#ff0c3e",
                      marginBottom: "0",
                    }}
                  >
                    {this.state.errors.category}
                  </label>
                )}
              </div>
            ) : (
              ""
            )}

            <div className="form-group">
              {this.renderNumber("minOrderAmount", "On Min Purchase")}
            </div>

            <div className="form-group">
              {this.renderNumber("maxRedeemCount", "Maximum Quantities")}
            </div>
            <div className="form-group">
              {this.renderNumber(
                "maxRedeemCountPerCustomer",
                "Max Usage per Customer"
              )}
            </div>

            <div className="form-group d-flex">
              <div className="row">
                <div className="col-md-6 custom-col">
                  {this.renderDateInput("startDate", "Start Date")}
                </div>
                <div className="col-md-6 custom-col">
                  {this.renderDateInput("endDate", "End Date")}
                </div>
              </div>
            </div>

            {/* <div className="form-group d-flex">
              <div className="row">
                <div className="col-md-6">
                  {this.renderNumber("maxDiscount", "Maximum Discount")}
                </div>
                <div className="col-md-6">
                  {this.renderNumber("maxRedeemCount", "Maximum Quantities")}
                </div>
              </div>
            </div> */}

            <div className="form-group d-flex">
              <div className="row">
                <div className="col-md-6 custom-col">
                  <label htmlFor="discountType"> Discount Type </label>

                  <SelectOptions
                    width={"auto"}
                    value={this.state.discountType.find(
                      (e) => e.value === this.state.data.discountType
                    )}
                    options={this.state.discountType}
                    onChange={(e) => {
                      const { data, errors } = this.state;
                      data.discountType = e;
                      errors.discountType = "";
                      this.setState({ data, errors });
                    }}
                  />
                  {this.state.errors.discountType && (
                    <label
                      className="error"
                      style={{
                        fontSize: "12px",
                        lineHeight: "16px",
                        fontWeight: "400",
                        letterSpacing: "0.4px",
                        color: "#ff0c3e",
                        marginBottom: "0",
                      }}
                    >
                      {this.state.errors.discountType}
                    </label>
                  )}
                </div>

                <div className="col-md-6 custom-col">
                  {this.renderNumber("discount", "Discount")}
                </div>
              </div>
            </div>

            <div className="form-group">
              <SubmitBtn
                label="Submit"
                loading={this.state.loading}
                btnClass="btn btn-lg btn-default"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  category: getCoupon(state).category,
  sellerProfile: getSeller(state).profile,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllCoupons: (params) => dispatch(loadCoupons(params)),
  loadCategory: (params) => dispatch(loadCategory()),
  loadSellerProfile: (params) => dispatch(loadSellerProfile(params)),
  addCoupon: (param, callback) => dispatch(addCoupon(param, callback)),
  updateCoupon: (id, data, editCallback) =>
    dispatch(updateCoupon(id, data, editCallback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddCoupons);
