import React, { Component } from "react";
import close from "../../include/images/close.svg";
import StarRatings from "react-star-ratings";

class SellerReviews extends Component {
  render() {
    return (
      <>
        {this.props.isSellerReviews && (
          <>
            <div className="tab-modify-slidebar large-modify-sidebar">
              <div className="tab-modify-box">
                <div className="tab-header d-flex justify-content-between">
                  <h3>Seller Reviews</h3>
                  <a
                    href="javascript:void(0)"
                    className="close"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.handleClose();
                    }}
                  >
                    <img src={close} alt="" />
                  </a>
                </div>
                {this.props.store ? (
                  <div className="tab-body">
                    {this.props.detail &&
                      this.props.detail.reviews.map((r, i) => (
                        <div className="review-box" key={i}>
                          <div className="star-list">
                            <StarRatings
                              rating={r.rating}
                              starDimension="15px"
                              starSpacing="3px"
                              isSelectable="false"
                              starEmptyColor="#C4C4C4"
                              starRatedColor="#ffa51e"
                              starHoverColor="#ffa51e"
                              svgIconViewBox="0 0 14 13"
                              svgIconPath="M6.29782 0.816984C6.66463 0.0737383 7.72447 0.07374 8.09129 0.816986L9.3702 3.40835C9.51586 3.70349 9.79743 3.90806 10.1231 3.95539L12.9829 4.37093C13.8031 4.49011 14.1306 5.49809 13.5371 6.07662L11.4678 8.09371C11.2321 8.32345 11.1245 8.65445 11.1802 8.97885L11.6687 11.827C11.8088 12.6439 10.9513 13.2669 10.2177 12.8812L7.65989 11.5365C7.36857 11.3833 7.02054 11.3833 6.72921 11.5365L4.17139 12.8812C3.43776 13.2669 2.58033 12.6439 2.72044 11.827L3.20894 8.97884C3.26458 8.65445 3.15703 8.32345 2.92134 8.09371L0.852018 6.07662C0.258501 5.49809 0.586013 4.49011 1.40623 4.37093L4.26597 3.95539C4.59168 3.90806 4.87324 3.70349 5.0189 3.40834L6.29782 0.816984Z"
                            />
                            {/* <span className="reviews-text custom-rating">
                            {r.rating}{" "}
                          </span> */}
                          </div>
                          {r.customer_first_name && r.customer_last_name && (
                            <p>
                              By{" "}
                              {r.customer_first_name +
                                " " +
                                r.customer_last_name}
                            </p>
                          )}
                          <h6>{r.subject && r.subject}</h6>
                          <p>{r.content && r.content}</p>
                        </div>
                      ))}
                  </div>
                ) : (
                  <div className="tab-body">
                    {this.props.detail.seller &&
                      this.props.detail.seller.reviews.map((r, i) => (
                        <div className="review-box" key={i}>
                          <div className="star-list">
                            <StarRatings
                              rating={r.rating}
                              starDimension="15px"
                              starSpacing="3px"
                              isSelectable="false"
                              starEmptyColor="#C4C4C4"
                              starRatedColor="#ffa51e"
                              starHoverColor="#ffa51e"
                              svgIconViewBox="0 0 14 13"
                              svgIconPath="M6.29782 0.816984C6.66463 0.0737383 7.72447 0.07374 8.09129 0.816986L9.3702 3.40835C9.51586 3.70349 9.79743 3.90806 10.1231 3.95539L12.9829 4.37093C13.8031 4.49011 14.1306 5.49809 13.5371 6.07662L11.4678 8.09371C11.2321 8.32345 11.1245 8.65445 11.1802 8.97885L11.6687 11.827C11.8088 12.6439 10.9513 13.2669 10.2177 12.8812L7.65989 11.5365C7.36857 11.3833 7.02054 11.3833 6.72921 11.5365L4.17139 12.8812C3.43776 13.2669 2.58033 12.6439 2.72044 11.827L3.20894 8.97884C3.26458 8.65445 3.15703 8.32345 2.92134 8.09371L0.852018 6.07662C0.258501 5.49809 0.586013 4.49011 1.40623 4.37093L4.26597 3.95539C4.59168 3.90806 4.87324 3.70349 5.0189 3.40834L6.29782 0.816984Z"
                            />
                            {/* <span className="reviews-text custom-rating">
                            {r.rating}{" "}
                          </span> */}
                          </div>
                          {r.customer_first_name && r.customer_last_name && (
                            <p>
                              By{" "}
                              {r.customer_first_name +
                                " " +
                                r.customer_last_name}
                            </p>
                          )}
                          <h6>{r.subject && r.subject}</h6>
                          <p>{r.content && r.content}</p>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default SellerReviews;
