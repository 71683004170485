import React, { PureComponent } from "react";
import markerSmall from "./../../include/images/markerSmall.svg";
import {
  Map,
  InfoWindow,
  Marker,
  GoogleApiWrapper,
  // Polygon,
} from "google-maps-react";

class SimpleMap extends PureComponent {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };
  mapChange = (e, e1, e3) => {
    const b = e1.getBounds();
  };

  // componentDidUpdate = (prevProps, prevState) => {
  //   console.log("updated...", this.props, prevProps);
  // };

  render() {
    return (
      <div style={{ height: "50vh", width: "100%" }}>
        <Map
          onZoomChanged={this.props.mapChange}
          google={this.props.google}
          zoom={this.props.zoom}
          initialCenter={this.props.center}
          center={this.props.center}
          mapTypeId={"terrain"}
        >
          <Marker
            fullscreenControl={false}
            onClick={this.onMarkerClick}
            title={"The marker`s title will appear as a tooltip."}
            name={"My Location"}
            position={this.props.center}
          />
          {this.props.sellers.map(
            (e, i) =>
              e.delivery.pickup &&
              this.props.productCount[i] !== 0 && (
                <Marker
                  className="marketImage"
                  onClick={this.onMarkerClick}
                  icon={{
                    url: markerSmall,
                    anchor: this.props.google.maps.Point(1, 1),
                    scaledSize: this.props.google.maps.Size(1, 1),
                  }}
                  name={e.company}
                  position={{
                    lat: e.location.coordinates[1],
                    lng: e.location.coordinates[0],
                  }}
                />
              )
          )}
          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
          >
            <div>
              <h6 className="markerText">{this.state.selectedPlace.name}</h6>
            </div>
          </InfoWindow>
          {/* {this.props.sellers.map(
            (e, i) =>
              this.props.productCount[i] !== 0 &&
              e.polygonLocation.coordinates &&
              e.polygonLocation.coordinates.length > 0 && (
                <Polygon
                  key={e.polygonLocation.coordinates[0]}
                  paths={e.polygonLocation.coordinates[0].map((ed) => ({
                    lat: ed[1],
                    lng: ed[0],
                  }))}
                  strokeColor="#0000FF"
                  strokeOpacity={0.6}
                  strokeWeight={2}
                  fillColor="#0000FF"
                  fillOpacity={0.35}
                />
              )
          )} */}
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDHZd1NTh7pJw6-zGe1Mf_cUBx2cIhz46Y",
})(SimpleMap);
