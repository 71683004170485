import React, { Component } from "react";
import AlertSuccess from "../../../common/alertSuccess";
import AlertError from "../../../common/alertError";
import { toast } from "react-toastify";
import { checkMobileOtp } from "../../../../store/seller";
import {
  sendMobileOtp,
  customerSignUp,
  customerSignIn,
} from "../../../../store/customer";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SidebarCustomer from "../../../common/customer/sideBarCustomer";
import {
  setCustomerName,
  setCustomerToken,
} from "../../../services/localStorageServices";

class RequestMobileOtp extends Component {
  _isMounted = false;
  state = {
    otp: {
      a: "",
      b: "",
      c: "",
      d: "",
    },
  };

  constructor(props) {
    super(props);
    this.aRef = React.createRef();
    this.bRef = React.createRef();
    this.cRef = React.createRef();
    this.dRef = React.createRef();
  }

  componentDidMount = () => {
    this._isMounted = true;

    document.body.classList.add("pt-0");
  };

  componentWillUnmount() {
    this._isMounted = false;

    document.body.classList.remove("pt-0");
  }

  onChange = (e) => {
    const { name, value } = e.target;
    const otp = this.state.otp;

    otp[name] = value.replace(/\D/, "");
    if (otp[name] !== "") {
      this.setState({ otp });
      if (name === "a") this.bRef.current.focus();
      if (name === "b") this.cRef.current.focus();
      if (name === "c") this.dRef.current.focus();
    } else {
      this.setState({ otp });
    }
  };

  doSubmit = (e) => {
    e.preventDefault();

    const { a, b, c, d } = this.state.otp;

    const data = {
      mobile: this.props.verifyMobile.mobile,
      mobileOtp: a + b + c + d,
    };

    this.props.checkMobileOtp(data, this.mobileCallback);
  };

  mobileCallback = (res) => {
    if (res && res.status === 200) {
      this.props.updateVerifyMobile({
        verification_id: res.data.verification_id,
        mobile: this.props.verifyMobile.mobile,
      });

      const params = {
        code: this.props.formData.code,
        mobile: this.props.formData.mobile.toString(),
        mobileOtp_verification_id: res.data.verification_id,
        email: this.props.formData.email,
        password: this.props.formData.password,
        first_name: this.props.formData.firstName,
        last_name: this.props.formData.lastName,
      };
      this.props.customerSignUp(params, this.callbackSignup);
    } else {
      // this.setState({
      //   loader: false,
      //   btnClass: "btn btn-default",
      // });
      return toast(<AlertError message={res && res.data.error} />);
    }
  };

  callbackSignup = (res) => {
    this.setState({
      loader: false,
      btnClass: "btn btn-lg btn-primary btn-block",
    });
    if (res.status === 200) {
      const data = {
        username: this.props.formData.email,
        password: this.props.formData.password,
      };
      this.props.customerSignIn(data, this.loginCallback);
      this.props.history.push("/customer/registrationSuccess");
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  loginCallback = (res) => {
    this.setState({
      loading: false,
      btnClass: "btn btn-default",
    });
    if (res.status === 200) {
      const { xAuthToken, xRefreshToken } = res.data;
      const { first_name } = res.data.data;
      setCustomerToken(xRefreshToken, xAuthToken);
      setCustomerName(first_name);
    } else {
      toast(<AlertError message={"Incorrect login credentials"} />);
    }
  };

  reSend = (e) => {
    clearInterval(this.state.countDownId);
    const param = { mobile: this.props.verifyMobile.mobile };
    this.props.sendMobileOtp(param, this.callback);
    e.preventDefault();
  };

  callback = (res) => {
    if (res.status === 200) {
      if (res.data.otp) {
        toast(<AlertSuccess message={res.data.otp} />);
      } else {
        toast(<AlertSuccess message="OTP send." />);
      }
      this.props.updateVerifyMobile({
        otp: res.data.otp,
        mobile: this.props.verifyMobile.mobile,
      });
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  render() {
    return (
      <div className="member-container">
        <SidebarCustomer />

        <div className="member-right">
          <div className="signin-form-container">
            <div className="signin-form">
              <div className="mr-box">
                <div className="mr-head mb-4">
                  <span
                    className="back-icon"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.updateStep(1);
                    }}
                  >
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 31.494 31.494"
                      space="preserve"
                    >
                      <path
                        d="M10.273,5.009c0.444-0.444,1.143-0.444,1.587,0c0.429,0.429,0.429,1.143,0,1.571l-8.047,8.047h26.554
                      c0.619,0,1.127,0.492,1.127,1.111c0,0.619-0.508,1.127-1.127,1.127H3.813l8.047,8.032c0.429,0.444,0.429,1.159,0,1.587
                      c-0.444,0.444-1.143,0.444-1.587,0l-9.952-9.952c-0.429-0.429-0.429-1.143,0-1.571L10.273,5.009z"
                      />
                    </svg>
                  </span>
                  <div className="mr-step">Step 2/2</div>
                  <h2>Mobile Number Verification</h2>
                </div>
                <form>
                  <div className="form-box">
                    <div className="enter-otp-box">
                      <p>
                        Please enter OTP sent to your mobile <br />
                        {this.props.verifyMobile.mobile}
                      </p>
                      <div className="otp-box">
                        <input
                          value={this.state.otp.a}
                          className="form-control"
                          id="codeBox1"
                          type="text"
                          name="a"
                          onChange={this.onChange}
                          maxLength="1"
                          ref={this.aRef}
                        />
                        <input
                          value={this.state.otp.b}
                          className="form-control"
                          id="codeBox2"
                          type="text"
                          name="b"
                          onChange={this.onChange}
                          maxLength="1"
                          ref={this.bRef}
                        />
                        <input
                          value={this.state.otp.c}
                          className="form-control"
                          id="codeBox3"
                          type="text"
                          name="c"
                          onChange={this.onChange}
                          maxLength="1"
                          ref={this.cRef}
                        />
                        <input
                          value={this.state.otp.d}
                          className="form-control"
                          id="codeBox4"
                          type="text"
                          name="d"
                          onChange={this.onChange}
                          maxLength="1"
                          ref={this.dRef}
                        />
                      </div>
                    </div>
                    <div className="form-group form-button">
                      <a
                        onClick={this.doSubmit}
                        href="/#"
                        className="btn btn-default"
                      >
                        Verify
                      </a>
                    </div>
                    <div className="resend-box">
                      <p>
                        Didn't get the code?{" "}
                        <a href="/#" onClick={this.reSend}>
                          Resend
                        </a>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  customerSignUp: (param, callback) =>
    dispatch(customerSignUp(param, callback)),
  customerSignIn: (param, callback) =>
    dispatch(customerSignIn(param, callback)),
  sendMobileOtp: (param, callback) => dispatch(sendMobileOtp(param, callback)),
  checkMobileOtp: (data, callback) => dispatch(checkMobileOtp(data, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(RequestMobileOtp));
