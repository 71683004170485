import React from "react";
import Form from "../../common/form/form";
import SidebarCustomer from "../../common/customer/sideBarCustomer";
import SubmitBtn from "../../common/form/submitBtn";
import AlertError from "../../common/alertError";

import { Link, withRouter } from "react-router-dom";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import { customerSignIn } from "../../../store/customer";
import {
  passwordInvalid,
  passwordInvalidMax,
  passwordRequired,
  loginRequired,
} from "../../common/misc";

import {
  setCustomerName,
  setCustomerToken,
} from "../../services/localStorageServices";

class LoginForm extends Form {
  state = {
    btnClass: "btn btn-default",
    loading: false,
    multi: false,
    data: {
      username: "",
      password: "",
    },
    errors: {
      username: "",
      password: "",
    },
  };

  componentDidMount = () => {
    document.body.classList.add("pt-0");
  };

  componentWillUnmount = () => {
    document.body.classList.remove("pt-0");
  };

  schema = {
    password: Joi.string()
      .min(8)
      .max(25)
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;

            case "string.max":
              err.message = passwordInvalidMax;
              break;

            default:
              break;
          }
        });
        return errors;
      }),
    username: Joi.string()
      .required()
      .label("Username")
      .error(() => {
        return { message: loginRequired };
      }),
  };

  doSubmit = () => {
    window.localStorage.removeItem("x-auth-token-customer");
    window.localStorage.removeItem("x-refresh-token-customer");
    window.localStorage.removeItem("customer-name");
    window.localStorage.removeItem("redirectUrl");
    localStorage.removeItem("customer_information");
    window.localStorage.removeItem("customerDddress");
    localStorage.removeItem("customerAddress");
    if (!this.state.loading) {
      this.setState({
        btnClass: "btn btn-default disabled",
        loading: true,
      });
      const { data } = this.state;
      this.props.customerSignIn(data, this.callback);
    }
  };

  callback = (res) => {
    this.setState({
      loading: false,
      btnClass: "btn btn-default",
    });
    if (res.status === 200) {
      const { xAuthToken, xRefreshToken } = res.data;
      const { first_name } = res.data.data;
      setCustomerToken(xRefreshToken, xAuthToken);
      setCustomerName(first_name);
      this.props.history.push(
        localStorage.getItem("redirectUrl")
          ? localStorage.getItem("redirectUrl")
          : // : this.props.location.state.cart === "cart"
            // ? "/cart"
            "/"
      );
    } else {
      toast(<AlertError message={"Incorrect login credentials"} />);
    }
  };

  render() {
    console.log("pro", this.props);
    return (
      <div className="member-container">
        <SidebarCustomer />
        <div className="member-right">
          <div className="signin-form-container">
            <div className="signin-form">
              <h2>Customer Login</h2>

              <div className="already-login">
                <Link to="/customer/registration" className="sep-login">
                  Register as a customer
                </Link>
              </div>

              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  {this.renderInput("username", "Email ID / Mobile Number")}
                </div>

                <div className="form-group">
                  {this.renderLoginPasswordInput("password", "Password")}
                  <div className="forgot-password">
                    <Link to="/customer/forgot-password">Forgot password?</Link>
                  </div>
                </div>

                <div className="form-group form-button">
                  <SubmitBtn
                    label="Login"
                    loading={this.state.loader}
                    btnClass={this.state.btnClass}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  customerSignIn: (param, callback) =>
    dispatch(customerSignIn(param, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(LoginForm));
