import React, { Component } from "react";
import { Link } from "react-router-dom";
import arrow_1 from "./../../include/images/arrow_1.svg";
import plusIcon from "./../../include/images/plus-icon.svg";
import editIcon from "./../../include/images/edit.svg";
import closeIcon from "./../../include/images/close.svg";
import CartProduct from "./cartProduct";
import { toast } from "react-toastify";
import AlertSuccess from "../common/alertSuccess";
import AlertError from "../common/alertError";
import { getCustomerToken } from "../services/localStorageServices";

class CartSeller extends Component {
  state = { couponCode: "", note: "" };

  handleCheckout = (payLater, stripe) => {
    if (!stripe && payLater) {
      getCustomerToken("refresh")
        ? this.props.history.push({
            pathname: "/checkout",
            state: { seller_number: this.props.seller_no },
          })
        : toast(
            <AlertError
              message={
                <>
                  Please click here to{" "}
                  <a
                    href="javascript:void(0)"
                    onClick={() =>
                      this.props.history.push({
                        pathname: "customer/login",
                        state: { cart: "cart" },
                      })
                    }
                    className="link-text"
                  >
                    login
                  </a>{" "}
                  in order to complete this transaction.
                </>
              }
            />
          );
    }
  };
  render() {
    const { es } = this.props;
    return (
      <>
        <tr>
          <td colspan="5" className="table-heading">
            Seller : {es.seller_company}
          </td>
        </tr>
        {es.products.map((ep) => (
          <CartProduct
            ep={ep}
            es={es}
            setSelectedProductId={this.props.setSelectedProductId}
            selectedProductId={this.props.selectedProductId}
            updateProductQuantity={this.props.updateProductQuantity}
            updateDeliveryForProduct={this.props.updateDeliveryForProduct}
            setSelectedDeliveryProductId={
              this.props.setSelectedDeliveryProductId
            }
            selectedDeliveryProductId={this.props.selectedDeliveryProductId}
          />
        ))}
        <tr>
          <td colspan="2">
            <b>Total</b>
          </td>
          <td colspan="2" style={{ textAlign: "right" }}>
            <div>
              <b>
                $
                {es.summary.subtotal.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
              </b>

              <div className="sub-code">
                {es.summary.discount > 0 && (
                  <span>
                    {es.summary.discount > 0 && (
                      <img
                        style={{ cursor: "pointer", width: "18px" }}
                        onClick={(e) =>
                          this.props.removeCouponFromSeller(e, es.seller_id)
                        }
                        src={closeIcon}
                        alt=""
                      />
                    )}
                    Coupon Code ({es.discount_coupon.discountAmount}
                    {es.discount_coupon.discountType === "percentage"
                      ? "% "
                      : "$ "}
                    Off) : -${es.summary.discount}
                  </span>
                )}
              </div>
            </div>
          </td>

          <td
            style={{ textAlign: "right" }}
            onClick={(e) => this.props.removeCouponFromSeller(e, es.seller_id)}
          ></td>
        </tr>

        <tr>
          <td colspan="5">
            <div className="table-add-dropdown d-flex align-items-center justify-content-between">
              <div className="table-notes-text" style={{ width: "80%" }}>
                <b>Notes : </b>
                {es.note}
              </div>
              <div
                class={
                  this.props.selectedSellerId === es.seller_id
                    ? "table-dropdown  btn-group dropdown show"
                    : "table-dropdown  btn-group dropdown"
                }
              >
                <button
                  className="dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={(e) => {
                    this.props.setSelectedSellerId(e, es.seller_id);
                  }}
                >
                  <div className="table-add-icon">
                    <img src={es.note ? editIcon : plusIcon} alt="" />
                  </div>
                </button>
                <div
                  class={
                    this.props.selectedSellerId === es.seller_id
                      ? "dropdown-menu show"
                      : "dropdown-menu"
                  }
                >
                  <div className="drop-content-box">
                    <div className="drop-content-header d-flex align-items-center justify-content-end">
                      <div
                        className="close-icon"
                        onClick={(e) => {
                          this.props.setSelectedSellerId(e, "");
                        }}
                      >
                        <img src={closeIcon} alt="" />
                      </div>
                    </div>
                    <div className="table-dropdown-form">
                      <div className="form-group">
                        <label for="notes">Notes</label>
                        <textarea
                          onChange={(e) =>
                            this.setState({ note: e.target.value })
                          }
                          value={this.state.note}
                          className="form-control"
                          id="notes"
                          rows="3"
                        ></textarea>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-default"
                        onClick={(e) => {
                          this.props.setSelectedSellerId(e, "");
                          this.props.addNotesToSeller(
                            e,
                            this.state.note,
                            es.seller_id
                          );
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            {localStorage.getItem("x-auth-token-customer") && (
              <div className="coupon-code-box d-flex">
                <form className="promo-code-form d-flex align-items-center">
                  <input
                    onChange={(e) =>
                      this.setState({ couponCode: e.target.value })
                    }
                    value={this.state.couponCode}
                    type="text"
                    className="form-control"
                    placeholder="Coupon code"
                  />
                  <button
                    disabled={!this.state.couponCode}
                    type="submit"
                    class={
                      !this.state.couponCode
                        ? "b-core-ui-select b-disable"
                        : "b-core-ui-select"
                    }
                    onClick={(e) =>
                      this.props.addCouponToSeller(
                        e,
                        this.state.couponCode,
                        es.seller_id
                      )
                    }
                  >
                    <img src={arrow_1} alt="" />
                  </button>
                </form>
              </div>
            )}
          </td>
          <td colspan="3" align="center">
            {es.seller_stripe_account_id ? (
              <div className="checkout-btn">
                <Link
                  to={{
                    pathname: "/checkout",
                    state: { seller_number: this.props.seller_no },
                  }}
                  type="submit"
                  className="btn btn-default btn-block"
                >
                  Checkout
                </Link>
              </div>
            ) : (
              <div className="checkout-btn">
                <button
                  onClick={() =>
                    this.handleCheckout(
                      es.pay_later,
                      es.seller_stripe_account_id
                    )
                  }
                  type="submit"
                  className="btn btn-default btn-block"
                >
                  Checkout
                </button>
              </div>
            )}
          </td>
        </tr>
      </>
    );
  }
}

export default CartSeller;
