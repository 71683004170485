import React, { Component } from "react";
import AlertSuccess from "../../common/alertSuccess";
import AlertError from "../../common/alertError";
import { toast } from "react-toastify";
import {
  sendEmailOtp,
  sellerSignUp,
  emailValidation,
} from "../../../store/seller";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../../common/sideBar";
import loaderIcon from "../../../include/images/loading-icon.svg";

class RequestEmailOtp extends Component {
  state = {
    btnClass: "btn btn-default",
    loader: false,
    otp: {
      a: "",
      b: "",
      c: "",
      d: "",
    },
  };

  constructor(props) {
    super(props);
    this.aRef = React.createRef();
    this.bRef = React.createRef();
    this.cRef = React.createRef();
    this.dRef = React.createRef();
  }

  componentWillUnmount = () => {
    document.body.classList.remove("pt-0");
  };

  componentDidMount = () => {
    document.body.classList.add("pt-0");
  };

  onChange = (e) => {
    const { name, value } = e.target;
    const otp = this.state.otp;

    otp[name] = value.replace(/\D/, "");
    if (otp[name] !== "") {
      this.setState({ otp });
      if (name === "a") this.bRef.current.focus();
      if (name === "b") this.cRef.current.focus();
      if (name === "c") this.dRef.current.focus();
    } else {
      this.setState({ otp });
    }
  };

  doSubmit = (e) => {
    e.preventDefault();
    const { a, b, c, d } = this.state.otp;
    this.props.emailValidation(
      {
        email: this.props.verifyEmail.email,
        emailOtp: parseInt(a + b + c + d),
      },
      this.emailCallback
    );
  };
  emailCallback = (res) => {
    if (res && res.status === 200) {
      const { a, b, c, d } = this.state.otp;
      this.props.updateVerifyEmail({
        otp: parseInt(a + b + c + d),
        email: this.props.verifyEmail.email,
      });
      this.setState({
        loader: true,
        btnClass: "btn btn-default disabled",
      });
      const params = {
        company: this.props.formData.companyName,
        code: "+91",
        mobile: this.props.formData.mobile.toString(),
        mobileOtp: this.props.verifyMobile.otp.toString(),
        email: this.props.formData.email,
        // emailOtp: this.props.verifyEmail.otp.toString(),
        password: this.props.formData.password,
        first_name: this.props.formData.firstName,
        last_name: this.props.formData.lastName,
        address: {
          street1: this.props.add.street1,
          street2: this.props.add.street2,
          zip: this.props.add.zip,
          city: this.props.add.city,
          state: this.props.add.state,
          country: this.props.add.country,
          latitude: this.props.add.latitude,
          longitude: this.props.add.longitude,
          formatted_address: this.props.add.formatted_address,
        },
        latitude: this.props.add.latitude,
        longitude: this.props.add.longitude,
      };
      this.props.sellerSignUp(params, this.callbackSignup);
    } else {
      return toast(<AlertError message={res.data.error} />);
    }
  };
  callbackSignup = (res) => {
    if (res.status === 200) {
      this.props.history.push("/seller/successful-registration");
    } else {
      this.setState({
        loader: false,
        btnClass: "btn btn-lg btn-primary btn-block",
      });
      toast(<AlertError message={res.data.error} />);
    }
  };

  reSend = (e) => {
    clearInterval(this.state.countDownId);
    const param = { email: this.props.verifyEmail.email };
    this.props.sendEmailOtp(param, this.callback);
    e.preventDefault();
  };

  callback = (res) => {
    if (res && res.status === 200) {
      if (res.data.otp) {
        toast(<AlertSuccess message={res.data.otp} />);
      } else {
        toast(<AlertSuccess message="OTP send." />);
      }

      this.props.updateVerifyEmail({
        otp: res.data.otp,
        email: this.props.verifyEmail.email,
      });
    } else {
      toast(<AlertError message={res && res.data.error} />);
    }
  };

  render() {
    return (
      <div className="member-container">
        <Sidebar />

        <div className="member-right">
          <div className="signin-form-container">
            <div className="signin-form">
              <div className="mr-box">
                <div className="mr-head mb-4">
                  <span
                    className="back-icon"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.updateStep(1);
                    }}
                  >
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 31.494 31.494"
                      space="preserve"
                    >
                      <path
                        d="M10.273,5.009c0.444-0.444,1.143-0.444,1.587,0c0.429,0.429,0.429,1.143,0,1.571l-8.047,8.047h26.554
                      c0.619,0,1.127,0.492,1.127,1.111c0,0.619-0.508,1.127-1.127,1.127H3.813l8.047,8.032c0.429,0.444,0.429,1.159,0,1.587
                      c-0.444,0.444-1.143,0.444-1.587,0l-9.952-9.952c-0.429-0.429-0.429-1.143,0-1.571L10.273,5.009z"
                      />
                    </svg>
                  </span>
                  <div className="mr-step">Step 3/3</div>
                  <h2>Email Id Verification</h2>
                </div>
                <form>
                  <div className="form-box">
                    <div className="enter-otp-box">
                      <p>
                        Please enter OTP sent to your email id <br />
                        {this.props.verifyEmail.email}
                      </p>
                      <div className="otp-box">
                        <input
                          value={this.state.otp.a}
                          className="form-control"
                          id="codeBox1"
                          type="text"
                          name="a"
                          onChange={this.onChange}
                          maxLength="1"
                          ref={this.aRef}
                        />
                        <input
                          value={this.state.otp.b}
                          className="form-control"
                          id="codeBox2"
                          type="text"
                          name="b"
                          onChange={this.onChange}
                          maxLength="1"
                          ref={this.bRef}
                        />
                        <input
                          value={this.state.otp.c}
                          className="form-control"
                          id="codeBox3"
                          type="text"
                          name="c"
                          onChange={this.onChange}
                          maxLength="1"
                          ref={this.cRef}
                        />
                        <input
                          value={this.state.otp.d}
                          className="form-control"
                          id="codeBox4"
                          type="text"
                          name="d"
                          onChange={this.onChange}
                          maxLength="1"
                          ref={this.dRef}
                        />
                      </div>
                    </div>
                    <div className="form-group form-button">
                      <a
                        onClick={this.doSubmit}
                        href="/#"
                        type="submit"
                        className={this.state.btnClass}
                      >
                        {this.state.loader === true ? (
                          <b className="btn-loader">
                            <img
                              src={loaderIcon}
                              alt="loader"
                              className="loader-img fa-spin loader-icon"
                            />
                          </b>
                        ) : (
                          "Verify"
                        )}
                      </a>
                    </div>
                    <div className="resend-box">
                      <p>
                        Didn't get the code?{" "}
                        <a href="/#" onClick={this.reSend}>
                          Resend
                        </a>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  sellerSignUp: (param, callback) => dispatch(sellerSignUp(param, callback)),
  sendEmailOtp: (param, callback) => dispatch(sendEmailOtp(param, callback)),
  emailValidation: (data, callback) =>
    dispatch(emailValidation(data, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(RequestEmailOtp));
