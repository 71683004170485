import React, { createRef } from "react";
import Form from "../common/form/form";
import close from "../../include/images/close.svg";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Joi from "joi-browser";
import LoadingBar from "react-top-loading-bar";

import FetchBtn from "../common/form/fetchBtn";

import {
  loadCustomerProfile,
  getCustomer,
  updateAddress,
} from "../../store/customer";

import {
  Address1Required,
  cityRequired,
  stateRequired,
  zipRequired,
} from "../common/misc";
import { zipValidation, addressValidation } from "../../store/seller";
import AlertSuccess from "../common/alertSuccess";
import AlertError from "../common/alertError";
import { toast } from "react-toastify";
import SubmitBtn from "../common/form/submitBtn";
import { geocodeByAddress } from "react-places-autocomplete";
import { emptyCart } from "../../store/cart";
import { Modal } from "react-bootstrap";

class AddressFlyer extends Form {
  ref = createRef(null);

  state = {
    labelFetch: "Fetch city & state",
    zipLoader: false,
    loader: false,
    labelRegister: "Save Changes",
    btnClass: "btn btn-default",
    data: {
      street1: "",
      street2: "",
      zip: "",
      city: "",
      state: "",
    },
    popup: false,
    errors: {},
  };
  handlePopup = (e) => {
    if (e) e.preventDefault();
    this.setState({ popup: !this.state.popup });
  };

  doSubmit = () => {
    if (!this.state.loader) {
      this.handlePopup();
    }
  };

  doSubmitFinal = (e) => {
    this.handlePopup();
    e.preventDefault();
    this.setState({
      labelRegister: "",
      loader: true,
      btnClass: "btn btn-default disabled",
    });
    const addressParams = {
      street1: this.state.data.street1,
      street2: this.state.data.street2,
      zip: this.state.data.zip,
    };
    this.props.addressValidation(addressParams, this.addressCallback);
  };

  addressCallback = async (res) => {
    this.setState({
      loader: false,
      labelRegister: "Save Changes",
      btnClass: "btn btn-default",
    });
    if (res.status === 200) {
      const updateAddressParams = {
        billing_address: {
          address_line1: this.state.data.street1,
          address_line2: this.state.data.street2,
          zipcode: this.state.data.zip,
          city: this.state.data.city,
          state: this.state.data.state,
        },
      };

      const latLng = {};

      try {
        this.props.emptyCart(localStorage.getItem("user_device_id"));
        const add = await geocodeByAddress(
          `${this.state.data.street1} ${this.state.data.street2} ${this.state.data.city} ${this.state.data.state} ${this.state.data.zip}`
        );

        latLng.lat = add[0].geometry.location.lat();
        latLng.lng = add[0].geometry.location.lng();
        console.log("latLng", latLng);
        this.props.updateLatLng(
          latLng.lat,
          latLng.lng,
          this.state.data.street2
            ? `${this.state.data.street1}, ${this.state.data.street2}, ${this.state.data.city}, ${this.state.data.state}, USA`
            : `${this.state.data.street1}, ${this.state.data.city}, ${this.state.data.state}, USA`,
          {
            long_name: this.state.data.zip,
          },
          true,
          true
        );

        this.props.handleClose();
      } catch (error) {
        this.setState({
          loader: false,
        });
        return toast(
          <AlertError message="Invalid delivery address. Please enter your correct address." />
        );
      }
    } else {
      toast(
        <AlertError message="Invalid address. Please enter your correct address." />
      );
    }
  };

  zipCallback = (res) => {
    if (res.status === 200) {
      this.setState({
        labelFetch: "Fetch city & state",
        zipLoader: false,
        btnClass: "btn btn-default",
      });
      const { data, errors } = this.state;
      data.city = res.data.data.city;
      data.state = res.data.data.state;
      errors.city = "";
      errors.state = "";
      this.setState({ data, errors });
    } else {
      this.setState({
        labelFetch: "Fetch city & state",
        zipLoader: false,
        btnClass: "btn btn-default",
      });

      toast(<AlertError message={res.data.error.Description} />);
    }
  };

  doSubmitZip = () => {
    if (!this.state.zipLoader) {
      this.setState({
        labelFetch: "",
        zipLoader: true,
        btnClass: "btn btn-default disabled",
        // btnClass: "sidebar-default-btn disabled",
      });
    }
    const params = {
      zip: this.state.data.zip,
    };
    this.props.zipValidation(params, this.zipCallback);
  };

  render() {
    return (
      <>
        <LoadingBar color="#0b4474" ref={this.ref} />
        <>
          <div className="tab-modify-slidebar tab-modify-slidebar2">
            <div className="tab-modify-box">
              <form onSubmit={this.handleSubmit}>
                <div className="tab-header">
                  <h3>Delivery Address</h3>
                  <a
                    href="javascript:void(0)"
                    onClick={this.props.handleClose}
                    className="close"
                  >
                    <img src={close} alt="" />
                  </a>

                  <div className="row">
                    <div className="col-lg-6">
                      {this.renderInput("street1", "Address line 1")}
                    </div>

                    <div className="col-lg-6">
                      {this.renderAddress2("street2", "Address line 2")}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      {this.renderInput("zip", "Zip Code")}
                    </div>

                    <div className="col-lg-6 d-lg-flex align-items-lg-center justify-content-lg-center">
                      <FetchBtn
                        disabled={
                          this.state.data.zip.length !== 5 ? "true" : ""
                        }
                        onClickHandle={this.handleSubmitZip}
                        loading={this.state.zipLoader}
                        label={this.state.labelFetch}
                        btnClass="btn fetch-btn"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        {this.renderInputCityState("city", "City")}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        {this.renderInputCityState("state", "State")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group sidebar-btn">
                  <SubmitBtn
                    label={this.state.labelRegister}
                    loading={this.state.loader}
                    btnClass={this.state.btnClass}
                  />
                </div>
              </form>
            </div>
          </div>{" "}
          <Modal
            show={this.state.popup}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <div className="modal-header">
              <button
                type="button"
                className="close mt-2"
                data-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <div className="modal-alert-box">
                <h3 className="mb-6">Alert!</h3>
                <p>Modifying delivery address will clear your cart.</p>
              </div>
              <div className="modal-action-btn">
                <a
                  href="javascript:void(0)"
                  data-dismiss="modal"
                  className="btn btn-default"
                  onClick={(e) => {
                    this.doSubmitFinal(e);
                  }}
                >
                  Ok
                </a>
                <a
                  href="#!"
                  className="btn btn-cancel"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.handlePopup}
                >
                  Cancel
                </a>
              </div>
            </div>
          </Modal>
        </>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customerProfile: getCustomer(state).profile,
  profileLoading: getCustomer(state).profileLoading,
});

const mapDispatchToProps = (dispatch) => ({
  updateAddress: (data, callback) => dispatch(updateAddress(data, callback)),
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
  zipValidation: (data, callback) => dispatch(zipValidation(data, callback)),
  emptyCart: (id) => dispatch(emptyCart(id)),
  addressValidation: (data, callback) =>
    dispatch(addressValidation(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddressFlyer)
);
