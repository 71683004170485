import React, { Component } from "react";
// import Footer from "../common/footer";
import TopBar from "../common/topBar";
import Search from "./search";
import AddProduct from "./addProduct";
import {
  getProduct,
  loadAllProduct,
  deletedProduct,
  loadCategory,
  loadCategories,
} from "../../store/product";
import { connect } from "react-redux";
import ProductGrid from "./productGrid";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import { productDeleted, failed } from "../common/misc";
import Pagination from "../common/Pagination";
import ProductEmpty from "./productEmpty";
import image1 from "../../include/images/no-result-img.svg";
import { getSeller } from "../../store/seller";
import { loadProductDetail } from "../../store/product";
import UploadBulkFile from "../common/bulkProductUpload/uploadBulkFile";
import SortingIconUp from "../common/sortingIconUp";
import SortingIcon from "../common/sortingIconDown";

class ProductListing extends Component {
  ref = React.createRef(null);

  state = {
    uploadProducts: false,
    editShow: false,
    isShow: false,
    reviews: false,
    isReviews: false,
    data: "",
    totalRecords: "",
    totalPages: "",
    pageLimit: "",
    currentPage: "",
    modal: false,
    delete_id: "",
    settingDropdown: false,
    profileDropdown: false,
    sort: "desc",
    sort_by: "date",
    sorting: false,
    params: {},
    totalItemAllowed: "",
    totalItem: "",
  };

  toggleEdit = (data) => {
    console.log(data);
    this.setState({
      editShow: !this.state.editShow,
      data: data,
      isShow: false,
    });
  };

  toggleReviews = (id) => {
    this.setState({
      reviews: !this.state.reviews,
      isReviews: !this.state.isReviews,
    });
    this.props.loadProductDetail(id);
    document.body.classList.add("toggled-tab");
  };

  toggleCloseReviews = () => {
    this.setState({
      reviews: !this.state.reviews,
      isReviews: !this.state.isReviews,
    });
    document.body.classList.remove("toggled-tab");
  };

  toggleAdd = () => {
    this.setState({
      isShow: !this.state.isShow,
      editShow: false,
      data: "",
    });
  };

  toggleUploadProducts = () => {
    document.body.classList.add("modal-open");
    this.setState({
      uploadProducts: !this.state.uploadProducts,
    });
  };

  closeUploadProducts = () => {
    window.location.reload();
    document.body.classList.remove("modal-open");
    this.props.loadAllProduct({ page: this.state.currentPage });

    this.setState({
      uploadProducts: !this.state.uploadProducts,
    });
  };

  sortBy = (param) => {
    let sort;
    if (this.state.sort === "asc") {
      sort = "desc";
    } else {
      sort = "asc";
    }
    this.setState({
      sort_by: param,
      sort,
      sorting: true,
    });
  };

  componentDidMount = () => {
    this.ref.current.continuousStart();
    this.props.loadCategories("category/all");
    this.props.loadAllProduct({
      sort_by: this.state.sort_by,
      sort: this.state.sort,
      page: this.state.currentPage,
    });
  };

  deletedProduct = (id) => {
    this.props.deletedProduct(id, this.callback);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.productLoading &&
      this.props.productLoading !== prevProps.productLoading
    ) {
      this.ref.current.complete();
    }
    if (
      this.state.sort_by !== prevState.sort_by ||
      this.state.sort !== prevState.sort
    ) {
      this.props.loadAllProduct({
        sort_by: this.state.sort_by,
        sort: this.state.sort,
      });
    }
    if (
      !this.props.sellerLoading &&
      this.props.sellerLoading != prevProps.sellerLoading
    ) {
      const totalItemAllowed = this.props.sellerProfile.totalItemsAllowed;
      this.setState({ totalItemAllowed });
      console.log("totl", this.props.totalItemAllowed);
    }
  };

  toggle = (id) => {
    this.setState({
      modal: !this.state.modal,
      delete_id: id,
    });
  };
  callback = (res) => {
    this.ref.current.complete();
    if (res && res.status === 200) {
      this.props.loadAllProduct({ page: this.state.currentPage });
      this.setState({
        modal: !this.state.modal,
      });
      toast(<AlertSuccess message={productDeleted} />);
    } else {
      toast(<AlertError message={failed} />);
      this.setState({
        modal: !this.state.modal,
      });
    }
  };
  onChangePage = (data) => {
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
    });
    if (data.page !== this.state.currentPage) {
      this.props.loadAllProduct({ page: data.page });
    }
  };

  handleSettingDropdown = () => {
    if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
      this.setState({ settingDropdown: !this.state.settingDropdown });
    } else {
      this.setState({ settingDropdown: !this.state.settingDropdown });
    }
  };

  handleProfileDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
      this.setState({ profileDropdown: !this.state.profileDropdown });
    } else {
      this.setState({ profileDropdown: !this.state.profileDropdown });
    }
  };

  setSettingDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
    } else if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
    }
  };

  render() {
    const style = {
      ":before": {
        zIndex: "999",
      },
    };
    const { product, loading } = this.props.product;
    const {
      record_per_page,
      total_page,
      current_page,
      filtered_record,
      total_record,
      total_record_on_current_page,
    } = this.props.pagination;
    return (
      <div
        onClick={this.setSettingDropdown}
        className={
          this.state.editShow === true || this.state.isShow === true
            ? "toggled-tab"
            : ""
        }
        style={style}
      >
        <div id="main">
          <div className="dashboard-container custom-top">
            <TopBar
              loaDerRef={this.ref}
              sellerProfile={this.props.sellerProfile}
              handleProfileDropdown={this.handleProfileDropdown}
              handleSettingDropdown={this.handleSettingDropdown}
              settingDropdown={this.state.settingDropdown}
              profileDropdown={this.state.profileDropdown}
            />
            {!loading && product && product.length === 0 ? (
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="no-result-content">
                      <div className="no-result-box d-inline-block">
                        <div className="no-result-img">
                          <img src={image1} alt="" />
                        </div>
                        <div className="no-result-text">
                          <h2>Welcome Aboard!</h2>
                          <p>
                            You have not added any product yet. Let's get
                            started.
                          </p>
                          <p style={{ marginTop: "15px" }}>
                            <a
                              href="javascript:void(0)"
                              className="btn btn-default btn-xs"
                              onClick={(e) => {
                                e.preventDefault();
                                this.toggleAdd();
                              }}
                            >
                              + Add Product
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <Search
                  toggleAdd={this.toggleAdd}
                  toggleUploadProducts={this.toggleUploadProducts}
                  record_per_page={record_per_page}
                  filtered_record={filtered_record}
                  total_record_on_current_page={total_record_on_current_page}
                  total_record={total_record}
                  currentPage={current_page}
                  totalItemsAllowed={this.props.totalItemsAllowed}
                />
                {!loading && filtered_record === 0 && total_record > 0 ? (
                  <ProductEmpty total_record={total_record} />
                ) : (
                  !loading && (
                    <>
                      <div className="product-list-item-container ">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="product-list-item-content">
                                <div className="table-responsive">
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th
                                          style={{
                                            width: "95px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => this.sortBy("name")}
                                        >
                                          Products&nbsp;
                                          {this.state.sorting === true &&
                                          this.state.sort_by === "name" ? (
                                            this.state.sort === "asc" ? (
                                              <SortingIcon sorting={() => {}} />
                                            ) : (
                                              <SortingIconUp
                                                sorting={() => {}}
                                              />
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </th>
                                        <th></th>
                                        <th
                                          onClick={() => this.sortBy("price")}
                                          style={{
                                            width: "115px",
                                            textAlign: "right",
                                            cursor: "pointer",
                                          }}
                                        >
                                          Price
                                          {this.state.sorting === true &&
                                          this.state.sort_by === "price" ? (
                                            this.state.sort === "asc" ? (
                                              <SortingIcon sorting={() => {}} />
                                            ) : (
                                              <SortingIconUp
                                                sorting={() => {}}
                                              />
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </th>
                                        <th
                                          style={{
                                            width: "115px",
                                            textAlign: "right",
                                          }}
                                        >
                                          Sales tax
                                        </th>
                                        <th
                                          onClick={() =>
                                            this.sortBy("quantity")
                                          }
                                          style={{
                                            width: "115px",
                                            textAlign: "right",
                                            cursor: "pointer",
                                          }}
                                        >
                                          Quantity
                                          {this.state.sorting === true &&
                                          this.state.sort_by === "quantity" ? (
                                            this.state.sort === "asc" ? (
                                              <SortingIcon sorting={() => {}} />
                                            ) : (
                                              <SortingIconUp
                                                sorting={() => {}}
                                              />
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </th>
                                        <th style={{ width: "105px" }}></th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      <ProductGrid
                                        sellerProfile={
                                          this.props.sellerProfile &&
                                          this.props.sellerProfile.category
                                        }
                                        product={product}
                                        category={this.props.product.category}
                                        deletedProduct={this.deletedProduct}
                                        toggleReviews={this.toggleReviews}
                                        toggleEdit={this.toggleEdit}
                                        toggleAdd={this.toggleAdd}
                                        delete_id={this.state.delete_id}
                                        modal={this.state.modal}
                                        toggle={this.toggle}
                                      />
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <Pagination
                                totalRecords={filtered_record}
                                total_page={total_page}
                                pageLimit={record_per_page}
                                initialPage={current_page}
                                pagesToShow={5}
                                onChangePage={this.onChangePage}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                )}
              </>
            )}
            <AddProduct
              page={this.state.currentPage}
              toggleEdit={this.toggleEdit}
              toggleAdd={this.toggleAdd}
              editShow={this.state.editShow}
              isShow={this.state.isShow}
              data={this.state.data}
              isReviews={this.state.isReviews}
              toggleReviews={this.toggleReviews}
              handleClose={this.toggleCloseReviews}
            />
          </div>
        </div>

        <UploadBulkFile
          setStep={this.state.step}
          uploadProducts={this.state.uploadProducts}
          closeUploadProducts={this.closeUploadProducts}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  product: getProduct(state),
  pagination: getProduct(state).pagination,
  totalItemsAllowed: getProduct(state).totalItemsAllowed,
  sellerProfile: getSeller(state).profile,
  productLoading: getProduct(state).loading,
  sellerLoading: getSeller(state).sellerLoading,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllProduct: (params) => dispatch(loadAllProduct(params)),
  loadCategory: () => dispatch(loadCategory()),
  loadCategories: (url) => dispatch(loadCategories(url)),
  deletedProduct: (id, callback) => dispatch(deletedProduct(id, callback)),
  loadProductDetail: (id) => dispatch(loadProductDetail(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductListing);
