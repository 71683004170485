import React, { Component } from "react";
import vector from "../../include/images/Vector.svg";
import { getCoupon, loadCoupons } from "../../store/coupons";
import { connect } from "react-redux";

const initialState = {
  keyword: "",
};

class CouponSearch extends Component {
  state = initialState;
  onSearch = (e) => {
    if (this.state.keyword) {
      this.props.loadCoupons({ keyword: this.state.keyword });
    }
  };
  onKeyPress = (e) => {
    if (e.which === 13 && this.state.keyword) {
      this.props.loadCoupons({ keyword: this.state.keyword });
    }
  };
  reset = () => {
    this.props.loadCoupons({ page: this.props.currentPage });
    this.setState(initialState);
  };
  render() {
    return (
      <div className="content-container search-item-container pb-0 custom-top">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="search-item-content d-flex flex-column flex-md-row align-items-md--center justify-content-end justify-content-md-between">
                <div
                  className="input-group"
                  onKeyPress={this.onKeyPress}
                  onClick={this.onSearch}
                >
                  <div
                    className="input-group-prepend"
                    onKeyPress={this.onKeyPress}
                    onClick={this.onSearch}
                  >
                    <span className="input-group-text" id="basic-addon1">
                      <img src={vector} alt="" />
                    </span>
                  </div>
                  <input
                    name="search"
                    id="search"
                    className="form-control"
                    placeholder="Search here"
                    autoComplete="off"
                    value={this.state.keyword}
                    onChange={(e) => this.setState({ keyword: e.target.value })}
                  />
                </div>
                <div
                  className="showing-result d-flex align-items-center justify-content-end reset"
                  onClick={this.reset}
                >
                  Reset
                </div>
                <div className="showing-result d-flex align-items-center justify-content-end">
                  <div className="result-number">
                    {this.props.filtered_record > 50 ? (
                      <>
                        Showing <strong></strong>
                        {this.props.total_record_on_current_page &&
                          this.props.total_record_on_current_page}{" "}
                        of <strong>{this.props.filtered_record}</strong> result
                      </>
                    ) : (
                      <>
                        Showing{" "}
                        <strong>
                          {this.props.pagination &&
                            this.props.pagination.total_record_on_current_page}
                        </strong>{" "}
                        of <strong>{this.props.filtered_record} </strong>
                        results
                      </>
                    )}
                  </div>
                  <a
                    href="javascript:void(0)"
                    className="btn btn-default btn-xs"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.toggleAdd();
                    }}
                  >
                    + Add Coupon
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  coupons: getCoupon(state).coupons.data,
  pagination: getCoupon(state).coupons.pagination,
});
const mapDispatchToProps = (dispatch) => ({
  loadCoupons: (params) => dispatch(loadCoupons(params)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CouponSearch);
