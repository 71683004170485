import React, { Component } from "react";
import edit from "../../include/images/edit.svg";
import delete1 from "../../include/images/delete1.svg";
import ModalPopup from "../common/modal";

export default class ProductGrid extends Component {
  render() {
    return (
      <>
        {this.props.product.map((e, i) => (
          <tr key={i}>
            <td className="td-image">
              <span>
                <img
                  className="listImage"
                  src={
                    e.image[0]
                      ? process.env.REACT_APP_S3URL + e.image[0].small
                      : ""
                  }
                  alt=""
                />
              </span>
            </td>
            <td>
              <b>{e.name}</b>
              {e.product_id && (
                <div className="code">#{e.product_id && e.product_id}</div>
              )}
              {e.category &&
                this.props.category.find(
                  (ec) => ec._id === e.category.value
                ) && (
                  <div className="code">
                    {
                      this.props.category.find(
                        (ec) => ec._id === e.category.value
                      ).title
                    }
                  </div>
                )}
              {e.average_ratings && (
                <div className="code star-list">
                  <span className="star-icon filled">
                    <i className="fas fa-star" aria-hidden="true"></i>
                  </span>{" "}
                  &nbsp;
                  {e.average_ratings}
                </div>
              )}
            </td>
            <td style={{ textAlign: "right" }}>
              <b>${e.price}</b>
            </td>
            <td style={{ textAlign: "right" }}>
              <b>
                {e.category &&
                this.props.sellerProfile &&
                this.props.sellerProfile.length &&
                this.props.sellerProfile.find(
                  (ecat) => ecat._id === e.category.value
                ) &&
                this.props.sellerProfile.find(
                  (ecat) => ecat._id === e.category.value
                ).value
                  ? this.props.sellerProfile.find(
                      (ecat) => ecat._id === e.category.value
                    ).value + " %"
                  : ""}
              </b>
            </td>
            <td style={{ textAlign: "right" }}>{e.quantity}</td>
            <td className="editable star-list custom-options">
              {e.average_ratings && (
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.toggleReviews(e._id)}
                  className="filled"
                >
                  <i className="fas fa-star" aria-hidden="true"></i>
                </a>
              )}
              <a
                href="javascript:void(0)"
                onClick={() => this.props.toggleEdit(e)}
                className="physicalVisit star-icon"
              >
                <img src={edit} alt="edit" />
              </a>
              <a
                href="javascript:void(0)"
                className="delete"
                onClick={() => this.props.toggle(e._id)}
              >
                <img src={delete1} alt="delete" />
              </a>
            </td>
          </tr>
        ))}
        <ModalPopup
          show={this.props.modal}
          onHide={this.props.toggle}
          delete_id={this.props.delete_id}
          deleted={this.props.deletedProduct}
        />
      </>
    );
  }
}
