import React, { Component } from "react";
import ReactTooltip from "react-tooltip";

class Password extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.state = {
      render: false,
      isPasswordShown: false,
      formclass: "form-group custom-input",
    };
    this.passwordMessage = this.passwordMessage.bind(this);
  }

  passwordMessage(e) {
    e.preventDefault();
    this.setState({ render: !this.state.render });
  }

  handleFocus = () => {
    this.setState({ formclass: "form-group custom-input show on" });
  };

  handleBlur = (e) => {
    const classname = e.target.value
      ? "form-group custom-input show on"
      : "form-group custom-input";
    this.setState({ formclass: classname });
  };

  toggleShowPassword = (e) => {
    this.setState({ isPasswordShown: !this.state.isPasswordShown });
    this.input.current.type = this.state.isPasswordShown ? "password" : "text";
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const classname = this.props.value
        ? "form-group custom-input show on"
        : "form-group custom-input";
      this.setState({ formclass: classname });
    }
  }

  render() {
    const { name, label, error, ...rest } = this.props;

    return (
      <div>
        <label
          htmlFor={name}
          className="d-flex align-items-center justify-content-between"
        >
          {label}

          <div
            className={
              this.props.label === "Confirm Password"
                ? "question-mark custom-dropdown-outer"
                : "custom-dropdown-outer"
            }
          >
            <span className="question-box" onClick={this.passwordMessage}>
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.12492 11.25C8.12492 11.5951 7.84512 11.8749 7.49996 11.8749C7.15481 11.8749 6.875 11.5951 6.875 11.25C6.875 10.9048 7.15481 10.625 7.49996 10.625C7.84512 10.625 8.12492 10.9048 8.12492 11.25Z"
                  fill="#297AB9"
                />
                <path
                  d="M7.5 15C3.36433 15 0 11.6357 0 7.5C0 3.36433 3.36433 0 7.5 0C11.6357 0 15 3.36433 15 7.5C15 11.6357 11.6357 15 7.5 15ZM7.5 0.9375C3.88126 0.9375 0.9375 3.88126 0.9375 7.5C0.9375 11.1187 3.88126 14.0625 7.5 14.0625C11.1187 14.0625 14.0625 11.1187 14.0625 7.5C14.0625 3.88126 11.1187 0.9375 7.5 0.9375Z"
                  fill="#297AB9"
                />
                <path
                  d="M7.50004 9.21883C7.24129 9.21883 7.03129 9.00883 7.03129 8.75008V8.11882C7.03129 7.52384 7.40883 6.99066 7.97005 6.79256C8.71689 6.52946 9.21883 5.73376 9.21883 5.15629C9.21883 4.20815 8.44818 3.4375 7.50004 3.4375C6.5519 3.4375 5.78125 4.20815 5.78125 5.15629C5.78125 5.41504 5.57125 5.62504 5.3125 5.62504C5.05375 5.62504 4.84375 5.41504 4.84375 5.15629C4.84375 3.6919 6.03508 2.5 7.50004 2.5C8.965 2.5 10.1563 3.6919 10.1563 5.15629C10.1563 6.19884 9.33315 7.30572 8.2819 7.67696C8.09444 7.74254 7.96879 7.92061 7.96879 8.11939V8.75008C7.96879 9.00883 7.75879 9.21883 7.50004 9.21883Z"
                  fill="#297AB9"
                />
              </svg>
            </span>
          </div>

          {this.state.render && (
            <div className="custom-dropdown">
              <p data-tip=" " data-event="click focus">
                {" "}
                Password should be 8 characters long, should have 1 upper case
                and 1 lower case, 1 number and 1 special character (except #).
              </p>
              <ReactTooltip globalEventOff="click" />
            </div>
          )}
        </label>

        <div className={this.state.formclass}>
          <input
            name={name}
            id={name}
            className={
              error
                ? "form-control password input-data error"
                : "form-control password input-data"
            }
            type="password"
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            ref={this.input}
            {...rest}
          />
          <span className="custom-hide-show" onClick={this.toggleShowPassword}>
            {this.state.isPasswordShown ? (
              <span className="toggle-password hide">Hide</span>
            ) : (
              <span className="toggle-password show">Show</span>
            )}
          </span>
          {error && <label className="error">{error}</label>}
        </div>
      </div>
    );
  }
}

export default Password;
