import React, { createRef } from "react";
import Joi from "joi-browser";
import Form from "../common/form/form";
import { Editor } from "@tinymce/tinymce-react";

// import Footer from "../common/footer";
import TopBar from "../common/topBar";
import seUploadFun from "../services/s3Services";

import loaderIconBlue from "../../include/images/loading-icon-blue.svg";

import { getSellerToken } from "../services/localStorageServices";
import { withRouter } from "react-router-dom";
import {
  zipValidation,
  addressValidation,
  loadSellerProfile,
  sellerUpdate,
  getSeller,
  sendMobileOtp,
  mobileAvailability,
  emailAvailability,
  updateMobile,
  updateEmail,
  sendEmailOtp,
  uploadProfilePictures,
  uploadProfileVideo,
} from "../../store/seller";

import AlertSuccess from "../common/alertSuccess";
import AlertError from "../common/alertError";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import Vector from "../../include/images/Vector2.svg";
import Group from "../../include/images/grey-square.jpg";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import {
  companyNameRequired,
  firstNameRequired,
  lastNameRequired,
  Address1Required,
  cityRequired,
  stateRequired,
  zipRequired,
  emailRequired,
  mobileRequired,
  mobileInvalid,
  emailUpdated,
  mobileUpdated,
  emailExist,
  mobileExist,
  invalidAddress,
  informationSaved,
} from "../common/misc";
import SubmitBtn from "../common/form/submitBtn";
import FetchBtn from "../common/form/fetchBtn";
import VerifyMobileBtn from "../common/form/updateMobileBtn";
import VerifyEmailBtn from "../common/form/updateEmailBtn";
import { getCities, loadCity, loadState } from "../../store/cities";
import SelectOptions from "../common/selectOptions";
import { result, toPlainObject } from "lodash";
const searchOptions = {
  types: ["address"],
  componentRestrictions: { country: "us" },
};
class Dashboard extends Form {
  ref = createRef(null);

  constructor(props) {
    super(props);
    this.aRef = React.createRef();
    this.bRef = React.createRef();
    this.cRef = React.createRef();
    this.dRef = React.createRef();

    this.wRef = React.createRef();
    this.xRef = React.createRef();
    this.yRef = React.createRef();
    this.zRef = React.createRef();

    this.state = {
      responseEmailOtp: "",
      responseOtp: "",
      isEnterOtp: false,
      isEnterEmailOtp: false,

      otp: {
        a: "",
        b: "",
        c: "",
        d: "",
      },
      emailOtp: {
        w: "",
        x: "",
        y: "",
        z: "",
      },

      deliver: {
        pick: false,
        sameday: false,
        delivery_in_1_week: false,
        delivery_in_2_week: false,
      },
      delivery_option: false,
      delivery_charge: false,
      shipping: false,
      payLater: false,
      payments: {
        credit: false,
        cash: false,
        paypal: false,
        certified: false,
      },

      visa: false,
      mastercard: false,
      americanExpress: false,
      cash: false,
      check: false,
      zelle: false,
      venmo: false,

      loader: false,
      zipLoader: false,
      emailLoader: false,
      mobileLoader: false,

      labelFetch: "Fetch City & State",
      labelChanges: "Save Changes",
      labelMobile: "Update Mobile",
      labelEmail: "Update Email",

      isUpdateEmail: false,
      isUpdateMobile: false,

      // fieldsEditable: false,
      btnClass: "btn btn-lg btn-default",
      photoStatus: "",
      videoStatus: "",
      shipping_enabled_by_admin: false,
      address: "",
      street1: "",
      street2: "",
      state: "",
      city: "",
      zip: "",
      state: "",
      country: "",
      city: "",
      latitude: "",
      longitude: "",
      formatted_address: "",
      data: {
        photo: {},
        companyName: "",
        about: "",
        url: "",
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        // street1: "",
        // street2: "",
        // state: "",
        // city: "",
        delivery_zip: "",
        video: "",
        delivery_option: "",
        delivery_charge: null,
        minimum_amount: null,
        shipping_charge: null,
        minimum_amount_shipping: null,
        delivery_area: "",
        delivery_state: "",
        delivery_city: "",
        returnPolicy: "",
        refundPolicy: "",
        settingDropdown: false,
        profileDropdown: false,
      },
      errors: {},
    };
    this.handlePaymentsChange = this.handlePaymentsChange.bind(this);
    this.handleDeliveryChange = this.handleDeliveryChange.bind(this);
    this.handleDeliveryCharge = this.handleDeliveryCharge.bind(this);
    this.handleShipping = this.handleShipping.bind(this);
    this.handleVisa = this.handleVisa.bind(this);
    this.handleAmerican = this.handleAmerican.bind(this);
    this.handleMaster = this.handleMaster.bind(this);
    this.handleCash = this.handleCash.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleZelle = this.handleZelle.bind(this);
    this.handleVenmo = this.handleVenmo.bind(this);
  }

  componentDidMount = () => {
    this.props.loadState();
    this.ref.current.continuousStart();
    if (getSellerToken("auth")) {
      this.props.loadSellerProfile();
    } else {
      this.props.history.push("/seller/login");
    }
  };

  onChange = (e) => {
    const { name, value } = e.target;
    const otp = this.state.otp;

    otp[name] = value.replace(/\D/, "");
    if (otp[name] !== "") {
      this.setState({ otp });
      if (name === "a") this.bRef.current.focus();
      if (name === "b") this.cRef.current.focus();
      if (name === "c") this.dRef.current.focus();
    } else {
      this.setState({ otp });
    }
  };

  onChangeEmail = (e) => {
    const { name, value } = e.target;
    const emailOtp = this.state.emailOtp;

    emailOtp[name] = value.replace(/\D/, "");
    if (emailOtp[name] !== "") {
      this.setState({ emailOtp });
      if (name === "w") this.xRef.current.focus();
      if (name === "x") this.yRef.current.focus();
      if (name === "y") this.zRef.current.focus();
    } else {
      this.setState({ emailOtp });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    // if (
    //   this.state.delivery_charge == false &&
    //   this.state.delivery_charge != prevState.delivery_charge
    // ) {
    //   this.schema.delivery_charge = Joi.any()
    //     .allow("")
    //     .optional()
    //     .label("delivery_charge");
    //   this.schema.minimum_amount = Joi.any()
    //     .allow("")
    //     .optional()
    //     .label("minimum_amount");
    // }
    if (this.state.data.delivery_area !== prevState.data.delivery_area) {
      if (this.state.data.delivery_area === "Zip_code") {
        const { data } = this.state;
        data.delivery_zip = this.state.data.delivery_zip;
        data.delivery_state = "";
        data.delivery_city = "";
        this.setState({ data });
      }
      if (this.state.data.delivery_area === "City") {
        const { data } = this.state;
        data.delivery_city = this.props.sellerProfile.delivery.delivery_city
          ? this.props.sellerProfile.delivery.delivery_city
          : this.state.city;
        data.delivery_state = this.props.sellerProfile.delivery.delivery_state
          ? this.props.sellerProfile.delivery.delivery_state
          : this.state.state;
        this.props.loadCity(data.delivery_state);
        data.delivery_zip = "";
        this.setState({ data });
      }
    }
    if (
      !this.props.profileLoading &&
      this.props.profileLoading !== prevProps.profileLoading
    ) {
      this.ref.current.complete();
      const {
        profilePictureURLs: photo,
        first_name: firstName,
        last_name: lastName,
        mobile,
        email,
        company: companyName,
        address: {
          street1,
          street2,
          zip,
          state,
          city,
          country,
          formatted_address,
          latitude,
          longitude,
        },
        about,
        url,
        video,
        payment,
        delivery,
        refundPolicy,
        returnPolicy,
        delivery_charge,
        pay_later,
        shipping_charge,
        shipping_enabled_by_admin,
      } = this.props.sellerProfile;
      const delivery_option = delivery.delivery_option;

      if (delivery_charge) {
        this.setState({ delivery_charge: true });
      }

      if (pay_later) {
        this.setState({ payLater: true });
      }
      if (delivery.shipping) {
        this.setState({ shipping: true });
      }

      if (!shipping_enabled_by_admin) {
        this.setState({ shipping_enabled_by_admin: true });
      }

      const data = {
        photo,
        companyName,
        about,
        url,
        firstName,
        lastName,
        email,
        mobile,
        // street1,
        // street2,
        // state,
        // city,
        // zip,
        refundPolicy,
        returnPolicy,
        delivery_zip: zip,
        video,
      };

      data.delivery_option = delivery.delivery_option_value;
      data.delivery_area = delivery.delivery_area;
      data.delivery_city = delivery.delivery_city;
      data.delivery_charge = delivery_charge && delivery_charge.charge;
      data.minimum_amount = delivery_charge && delivery_charge.minOrderAmount;
      data.shipping_charge = shipping_charge && shipping_charge.charge;
      data.minimum_amount_shipping =
        shipping_charge && shipping_charge.minOrderAmount;
      data.delivery_state = delivery.delivery_state;
      data.delivery_zip = delivery.delivery_zip;
      this.props.loadCity(data.delivery_state);

      if (pay_later) {
        this.setState({ visa: pay_later.visa });
        this.setState({ mastercard: pay_later.mastercard });
        this.setState({
          americanExpress: pay_later.americanExpress,
        });
        this.setState({ cash: pay_later.cash });
        this.setState({ check: pay_later.check });
        this.setState({ zelle: pay_later.zelle });
        this.setState({ venmo: pay_later.venmo });
      }

      const setPayLoad = {
        video: video,
        photo: photo,
        companyName: companyName,
        about: about,
        url: url,
        firstName: firstName,
        lastName: lastName,
        // street1: street1,
        // street2: street2,
        // state: state,
        // city: city,
        // zip: zip,
        email: email,
        mobile: mobile,
        refundPolicy,
        returnPolicy,
      };

      const deliver = {};
      const payments = {};

      deliver.delivery =
        delivery === undefined
          ? this.state.deliver.delivery
          : delivery.once_in_a_week === true
          ? true
          : false;
      deliver.pick =
        delivery === undefined
          ? this.state.deliver.pick
          : delivery.pickup === true
          ? true
          : false;
      deliver.sameday =
        delivery === undefined
          ? this.state.deliver.sameday
          : delivery.same_day === true
          ? true
          : false;
      deliver.shipping =
        delivery === undefined
          ? this.state.deliver.shipping
          : delivery.shipping === true
          ? true
          : false;

      payments.paypal =
        payment === undefined
          ? this.state.payments.paypal
          : payment.paypal === true
          ? true
          : false;
      payments.credit =
        payment === undefined
          ? this.state.payments.credit
          : payment.cards === true
          ? true
          : false;
      payments.cash =
        payment === undefined
          ? this.state.payments.cash
          : payment.cash === true
          ? true
          : false;
      payments.certified =
        payment === undefined
          ? this.state.payments.certified
          : payment.certified_checks === true
          ? true
          : false;

      this.setState({
        delivery_option,
        setPayLoad,
        data,
        deliver,
        payments,
        street1,
        street2,
        zip,
        city,
        state,
        country,
        formatted_address,
        latitude,
        longitude,
      });
    }
    if (
      this.state.delivery_charge == false &&
      this.state.delivery_charge != prevState.delivery_charge
    ) {
      this.schema.delivery_charge = Joi.any()
        .allow("")
        .optional()
        .label("delivery_charge");
      this.schema.minimum_amount = Joi.any()
        .allow("")
        .optional()
        .label("minimum_amount");
    }
  };

  fileUploadVideo = (e) => {
    this.setState({
      videoStatus: "...",
      btnClass: "btn btn-default disabled",
    });
    this.ref.current.continuousStart();
    const files = e.target.files;
    const size = files[0].size / 1024 / 1024;
    if (size > process.env.REACT_APP_VIDEOUPLOADMAX) {
      this.ref.current.complete();
      this.setState({
        btnClass: "btn btn-default",
        // videoStatus: "Uploaded successfully. Click here to change",
      });
      return toast(
        <AlertError
          message={`Video file should be lesser than ${process.env.REACT_APP_VIDEOUPLOADMAX} MB.`}
        />
      );
    }
    //return console.log("filesfiles", files[0].size / 1024 / 1024);
    for (var i = 0; i < files.length; i++) {
      const element = files[i];
      let formData = new FormData();
      formData.append("video", element);
      this.props.uploadProfileVideo(formData, this.videoCallback);
    }
  };

  fileUpload = (e) => {
    this.setState({
      photoStatus: "...",
      btnClass: "btn btn-default disabled",
    });
    this.ref.current.continuousStart();
    const files = e.target.files;
    for (var i = 0; i < files.length; i++) {
      const element = files[i];
      let formData = new FormData();
      formData.append("image", element);
      this.props.uploadProfilePictures(formData, this.imageCallback);
    }
  };

  videoCallback = (res) => {
    this.ref.current.complete();
    if (res && res.status === 200) {
      this.props.loadSellerProfile();
      const { data, errors } = this.state;
      errors.video = "";
      data.video = this.props.profileImage.video;
      this.setState({
        btnClass: "btn btn-default",
        videoStatus: "Uploaded successfully. Click here to change",
        data,
        // displayImage: this.props.profileImage.profilePictureURLs
        //   ? process.env.REACT_APP_S3URL +
        //     this.props.profileImage.profilePictureURLs.small
        //   : "",
      });
    } else {
      toast(<AlertError message={"Failed to upload. Please try again"} />);
    }
  };

  imageCallback = (res) => {
    this.ref.current.complete();
    if (res && res.status === 200) {
      this.props.loadSellerProfile();
      const { data, errors } = this.state;
      errors.photo = "";
      data.photo = this.props.profileImage.profilePictureURLs;
      this.setState({
        btnClass: "btn btn-default",
        photoStatus: "Uploaded successfully. Click here to change",
        data,
        displayImage: this.props.profileImage.profilePictureURLs
          ? process.env.REACT_APP_S3URL +
            this.props.profileImage.profilePictureURLs.small
          : "",
      });
    } else {
      toast(<AlertError message={"Failed to upload. Please try again"} />);
    }
  };

  handlePaymentsChange(event) {
    const { payments } = this.state;
    payments[event.target.id] = !payments[event.target.id];
    this.setState({
      payments,
    });
  }
  handleDeliveryChange(event) {
    const { deliver } = this.state;
    deliver[event.target.id] = !deliver[event.target.id];

    this.setState({
      deliver,
    });
  }

  handleDeliveryOption = () => {
    this.setState(
      {
        delivery_option: !this.state.delivery_option,
      },
      () => {
        if (this.state.delivery_option) {
          this.schema.delivery_option = Joi.string()
            .required()
            .label("delivery_option")
            .error(() => {
              return { message: "Required" };
            });
          this.schema.delivery_area = Joi.string()
            .required()
            .label("delivery_area")
            .error(() => {
              return { message: "Required" };
            });
        } else {
          this.schema.delivery_option = Joi.string()
            .allow("")
            .label("delivery_option")
            .error(() => {
              return { message: "Required" };
            });
          this.schema.delivery_area = Joi.string()
            .allow("")
            .label("delivery_area")
            .error(() => {
              return { message: "Required" };
            });
        }
      }
    );
  };

  handleDeliveryCharge = () => {
    this.setState({ delivery_charge: !this.state.delivery_charge }, () => {
      if (this.state.delivery_charge) {
        this.schema.delivery_charge = Joi.number()
          .required()
          .label("delivery_charge")
          .error(() => {
            return { message: "Required" };
          });
        this.schema.minimum_amount = Joi.number()
          .required()
          .label("minimum_amount")
          .error(() => {
            return { message: "Required" };
          });
      } else {
        this.schema.delivery_charge = Joi.any()
          .allow("")
          .optional()
          .label("delivery_charge");
        this.schema.minimum_amount = Joi.any()
          .allow("")
          .optional()
          .label("minimum_amount");
      }
    });
  };

  handleShipping = () => {
    this.setState({ shipping: !this.state.shipping }, () => {
      if (this.state.shipping) {
        this.schema.shipping_charge = Joi.number()
          .required()
          .label("shipping_charge")
          .error(() => {
            return { message: "Required" };
          });
        this.schema.minimum_amount_shipping = Joi.number()
          .required()
          .label("minimum_amount_shipping")
          .error(() => {
            return { message: "Required" };
          });
      } else {
        this.schema.shipping_charge = Joi.number()
          .allow("")
          .label("shipping_charge")
          .error(() => {
            return { message: "Required" };
          });
        this.schema.minimum_amount_shipping = Joi.number()
          .allow("")
          .label("minimum_amount_shipping")
          .error(() => {
            return { message: "Required" };
          });
      }
    });
  };

  handlePayLater = () => {
    this.setState({ payLater: !this.state.payLater });
  };

  schema = {
    photo: Joi.object()
      .allow("")
      .optional()
      .label("Photo")
      .error(() => {
        return { message: "Photo not uploaded" };
      }),
    video: Joi.any().allow("").optional().label("video"),
    // .error(() => {
    //   return { message: "Video not uploaded" };
    // }),
    delivery_option: Joi.string()
      .allow("")
      .label("delivery_option")
      .error(() => {
        return { message: "Required" };
      }),
    delivery_area: Joi.string()
      .allow("")
      .label("delivery_area")
      .error(() => {
        return { message: "Required" };
      }),
    delivery_charge: Joi.any().allow("").optional().label("delivery_charge"),
    minimum_amount: Joi.any().allow("").optional().label("minimum_amount"),
    shipping_charge: Joi.any().allow("").optional().label("shipping_charge"),
    minimum_amount_shipping: Joi.any()
      .allow("")
      .optional()
      .label("minimum_amount_shipping"),
    delivery_state: Joi.string()
      .allow("")
      .label("delivery_area")
      .error(() => {
        return { message: "Required" };
      }),
    delivery_city: Joi.string()
      .allow("")
      .label("delivery_area")
      .error(() => {
        return { message: "Required" };
      }),
    delivery_zip: Joi.string()
      .allow("")
      .label("delivery_area")
      .error(() => {
        return { message: "Required" };
      }),

    companyName: Joi.string()
      .required()
      .label("Company Name")
      .error(() => {
        return { message: companyNameRequired };
      }),

    about: Joi.any().label("About").allow("").optional(),

    url: Joi.any().label("Url").allow("").optional(),

    firstName: Joi.string()
      .required()
      .label("First Name")
      .error(() => {
        return { message: firstNameRequired };
      }),

    lastName: Joi.string()
      .required()
      .label("Last Name")
      .error(() => {
        return { message: lastNameRequired };
      }),

    email: Joi.string()
      .email()
      .max(50)
      .required()
      .label("Email")
      .error(() => {
        return { message: emailRequired };
      }),

    mobile: Joi.number()
      .required()
      .label("Mobile Number")
      .min(1000000000)
      .max(999999999999999)
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "number.base":
              err.message = mobileRequired;
              break;

            case "number.max":
              err.message = mobileInvalid;
              break;

            case "number.min":
              err.message = mobileInvalid;
              break;

            default:
          }
        });
        return errors;
      }),

    // street1: Joi.string()
    //   .required()
    //   .label("Address 1")
    //   .error(() => {
    //     return { message: Address1Required };
    //   }),

    // street2: Joi.string().label("Address 2").allow("").optional(),

    // city: Joi.string()
    //   .required()
    //   .label("City")
    //   .error(() => {
    //     return { message: cityRequired };
    //   }),

    // state: Joi.string()
    //   .required()
    //   .label("State")
    //   .error(() => {
    //     return { message: stateRequired };
    //   }),

    // zip: Joi.number()
    //   .required()
    //   .label("Zip Code")
    //   .error(() => {
    //     return { message: zipRequired };
    //   }),

    refundPolicy: Joi.string().label("Refund Policy").allow("").optional(),
    returnPolicy: Joi.string().label("Return Policy").allow("").optional(),
  };

  zipCallback = (res) => {
    this.ref.current.complete();
    if (res.status === 200) {
      this.setState({
        labelFetch: "Fetch City & State",
        zipLoader: false,
        btnClass: "btn btn-default",
      });
      const { data, errors } = this.state;
      data.city = res.data.data.city;
      data.state = res.data.data.state;
      errors.city = "";
      errors.state = "";
      this.setState({ data, errors });
    } else {
      this.setState({
        labelFetch: "Fetch City & State",
        zipLoader: false,
        btnClass: "btn btn-default",
      });

      toast(<AlertError message={res.data.error.Description} />);
    }
  };

  doSubmitMobile = () => {
    this.ref.current.continuousStart();

    if (!this.state.mobileLoader) {
      this.setState({
        isUpdateMobile: true,
        labelMobile: "Submit",
      });
    }

    if (this.state.labelMobile === "Submit") {
      const mobileParams = {
        mobile: this.state.data.mobile,
        type: 1,
      };
      this.props.mobileAvailability(mobileParams, this.callbackMobile);
    }

    this.state.otp.a = "";
    this.state.otp.b = "";
    this.state.otp.c = "";
    this.state.otp.d = "";
  };

  callbackMobile = (res) => {
    this.ref.current.complete();

    if (res.status === 200) {
      this.setState({
        isUpdateMobile: false,
        mobileLoader: false,
        labelMobile: "Cancel",
        btnClass: "btn btn-default update",
      });
      const params = { mobile: this.state.data.mobile };
      this.props.sendMobileOtp(params, this.otpMobileCallBack);
    } else {
      this.setState({
        isUpdateMobile: false,
        mobileLoader: false,
        labelMobile: "Update Mobile",
        btnClass: "btn btn-default",
      });
      toast(<AlertError message={mobileExist} />);
    }
  };

  reSend = (e) => {
    this.ref.current.continuousStart();

    clearInterval(this.myInterval);
    const param = { mobile: this.state.data.mobile };
    this.props.sendMobileOtp(param, this.otpMobileCallBack);
    e.preventDefault();
  };

  otpMobileCallBack = (res) => {
    this.ref.current.complete();

    if (res.status === 200) {
      if (res.data.otp) {
        toast(<AlertSuccess message={"Mobile OTP:" + res.data.otp} />);
      } else {
        toast(
          <AlertSuccess
            message={"We have sent OTP to your registered mobile number"}
          />
        );
      }
      this.setState({
        isUpdateMobile: false,
        responseOtp: res.data.otp,
        isEnterOtp: true,
      });
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  doSubmitOtp = (e) => {
    e.preventDefault();
    const { a, b, c, d } = this.state.otp;

    const otpParams = {
      mobile: this.state.data.mobile,
      mobileOtp: parseInt(a + b + c + d).toString(),
    };

    this.ref.current.continuousStart();

    this.props.updateMobile(otpParams, this.updateMobileCallBack);
  };

  updateMobileCallBack = (res) => {
    this.ref.current.complete();

    if (res.status === 200) {
      this.setState({
        isEnterOtp: false,
        mobileLoader: false,
        labelMobile: "Update Mobile",
        btnClass: "btn btn-default",
      });
      toast(<AlertSuccess message={mobileUpdated} />);
    } else {
      this.setState({
        mobileLoader: false,
        labelEmail: "Cancel",
        btnClass: "btn btn-default",
      });
      toast(<AlertError message={res.data.error} />);
    }
  };

  doCancelMobile = () => {
    this.setState({
      data: { ...this.state.setPayLoad, mobile: this.state.setPayLoad.mobile },
      isEnterOtp: false,
      isUpdateMobile: false,
      labelMobile: "Update Mobile",
    });
  };

  doCancelEmail = () => {
    this.setState({
      data: { ...this.state.setPayLoad, email: this.state.setPayLoad.email },
      isEnterEmailOtp: false,
      isUpdateEmail: false,
      labelEmail: "Update Email",
    });
  };

  doSubmitEmail = () => {
    if (!this.state.emailLoader) {
      this.setState({
        isUpdateEmail: true,
        labelEmail: "Submit",
      });
    }

    if (this.state.labelEmail === "Submit") {
      const emailParams = {
        email: this.state.data.email,
      };
      this.ref.current.continuousStart();

      this.props.emailAvailability(emailParams, this.callbackEmail);
    }
    this.state.emailOtp.x = "";
    this.state.emailOtp.y = "";
    this.state.emailOtp.z = "";
    this.state.emailOtp.w = "";
  };

  callbackEmail = (res) => {
    this.ref.current.complete();

    if (res.status === 200) {
      this.setState({
        isUpdateEmail: false,
        emailLoader: false,
        labelEmail: "Cancel",
        btnClass: "btn btn-default update",
      });
      const params = { email: this.state.data.email };
      this.props.sendEmailOtp(params, this.otpEmailCallBack);
    } else {
      this.setState({
        data: { ...this.state.setPayLoad, email: this.state.setPayLoad.email },
        emailLoader: false,
        isUpdateEmail: false,
        labelEmail: "Update Email",
        btnClass: "btn btn-default",
      });
      toast(<AlertError message={emailExist} />);
    }
  };

  reSendEmail = (e) => {
    this.ref.current.continuousStart();

    clearInterval(this.renderer);
    const param = { email: this.state.data.email };
    this.props.sendEmailOtp(param, this.otpEmailCallBack);
    e.preventDefault();
  };

  otpEmailCallBack = (res) => {
    this.ref.current.complete();

    if (res.status === 200) {
      if (res.data.otp) {
        toast(<AlertSuccess message={"Mobile OTP:" + res.data.otp} />);
      } else {
        toast(
          <AlertSuccess
            message={"We have sent OTP to your registered email address"}
          />
        );
      }
      this.setState({ reset: true });
      this.setState({
        responseEmailOtp: res.data.otp,
        isEnterEmailOtp: true,
      });
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  doSubmitEmailOtp = (e) => {
    e.preventDefault();
    const { w, x, y, z } = this.state.emailOtp;
    const otpEmailParams = {
      email: this.state.data.email,
      emailOtp: parseInt(w + x + y + z).toString(),
    };
    this.ref.current.continuousStart();

    this.props.updateEmail(otpEmailParams, this.updateEmailCallBack);
  };

  updateEmailCallBack = (res) => {
    this.ref.current.complete();

    if (res.status === 200) {
      this.setState({
        isEnterEmailOtp: false,
        emailLoader: false,
        labelEmail: "Update Email",
        btnClass: "btn btn-default",
      });
      toast(<AlertSuccess message={emailUpdated} />);
    } else {
      this.setState({
        emailLoader: false,
        labelEmail: "Cancel",
        btnClass: "btn btn-default",
      });
      toast(<AlertError message={res.data.error} />);
    }
  };

  doSubmitZip = () => {
    if (!this.state.zipLoader) {
      this.setState({
        labelFetch: "",
        zipLoader: true,
        btnClass: "btn btn-default disabled",
      });
    }
    const params = {
      zip: this.state.data.zip,
    };
    this.ref.current.continuousStart();

    this.props.zipValidation(params, this.zipCallback);
  };

  doSubmit = () => {
    if (!this.state.loader) {
      this.setState({
        labelChanges: " ",
        loader: true,
        btnClass: "btn btn-default disabled",
      });

      // const addressParams = {
      //   street1: this.state.street1,
      //   street2: this.state.street2,
      //   zip: this.state.zip,
      // };
      this.ref.current.continuousStart();
      //this.props.addressValidation(addressParams, this.addressCallback);
      const updateSellerData = {
        // profilePictureURLs: this.state.data.photo,
        company: this.state.data.companyName,
        first_name: this.state.data.firstName,
        last_name: this.state.data.lastName,
        about: this.state.data.about,
        url: this.state.data.url,
        address: {
          street1: this.state.street1,
          street2: this.state.street2,
          zip: this.state.zip,
          city: this.state.city,
          state: this.state.state,
          country: this.state.country,
          latitude: this.state.latitude,
          longitude: this.state.longitude,
          formatted_address: this.state.formatted_address,
        },
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        delivery: {
          pickup: this.state.deliver.pick,
          delivery_option: this.state.delivery_option,
          delivery_option_value: this.state.data.delivery_option,
          delivery_area: this.state.data.delivery_area,
          delivery_city: this.state.data.delivery_city,
          delivery_state: this.state.data.delivery_state,
          delivery_zip: this.state.data.delivery_zip,
          same_day: this.state.deliver.sameday,
          once_in_a_week: this.state.deliver.delivery,
          shipping: this.state.shipping,
        },
        payment: {
          cards: this.state.payments.credit,
          cash: this.state.payments.cash,
          paypal: this.state.payments.paypal,
          certified_checks: this.state.payments.certified,
        },
        returnPolicy: this.state.data.returnPolicy,
        refundPolicy: this.state.data.refundPolicy,
      };
      if (this.state.payLater) {
        updateSellerData.pay_later = {
          visa: this.state.visa,
          mastercard: this.state.mastercard,
          americanExpress: this.state.americanExpress,
          cash: this.state.cash,
          check: this.state.check,
          zelle: this.state.zelle,
          venmo: this.state.venmo,
        };
      } else {
        updateSellerData.pay_later = null;
      }
      if (this.state.delivery_charge) {
        if (this.state.data.delivery_charge && this.state.data.minimum_amount) {
          updateSellerData.delivery_charge = {
            charge: this.state.data.delivery_charge,
            minOrderAmount: this.state.data.minimum_amount,
          };
        }
      } else {
        updateSellerData.delivery_charge = null;
      }
      if (this.state.shipping) {
        updateSellerData.shipping_charge = {
          charge: this.state.data.shipping_charge,
          minOrderAmount: this.state.data.minimum_amount_shipping,
        };
      } else {
        updateSellerData.shipping_charge = null;
      }
      this.props.sellerUpdate(updateSellerData, this.callbackSellerUpdate);
    }
  };

  addressCallback = (res) => {
    this.ref.current.complete();

    if (res.status === 200) {
      this.setState({
        labelChanges: "Save Changes",
        loader: false,
      });
      const updateSellerData = {
        // profilePictureURLs: this.state.data.photo,
        company: this.state.data.companyName,
        first_name: this.state.data.firstName,
        last_name: this.state.data.lastName,
        about: this.state.data.about,
        url: this.state.data.url,
        address: {
          street1: this.state.street1,
          street2: this.state.street2,
          zip: this.state.zip,
        },
        delivery: {
          pickup: this.state.deliver.pick,
          delivery_option: this.state.delivery_option,
          delivery_option_value: this.state.data.delivery_option,
          delivery_area: this.state.data.delivery_area,
          delivery_city: this.state.data.delivery_city,
          delivery_state: this.state.data.delivery_state,
          delivery_zip: this.state.data.delivery_zip,
          same_day: this.state.deliver.sameday,
          once_in_a_week: this.state.deliver.delivery,
          shipping: this.state.shipping,
        },
        payment: {
          cards: this.state.payments.credit,
          cash: this.state.payments.cash,
          paypal: this.state.payments.paypal,
          certified_checks: this.state.payments.certified,
        },
        returnPolicy: this.state.data.returnPolicy,
        refundPolicy: this.state.data.refundPolicy,
      };
      if (this.state.payLater) {
        updateSellerData.pay_later = {
          visa: this.state.visa,
          mastercard: this.state.mastercard,
          americanExpress: this.state.americanExpress,
          cash: this.state.cash,
          check: this.state.check,
          zelle: this.state.zelle,
          venmo: this.state.venmo,
        };
      } else {
        updateSellerData.pay_later = null;
      }
      if (
        this.state.delivery_charge &&
        this.state.data.delivery_charge &&
        this.state.data.minimum_amount
      ) {
        updateSellerData.delivery_charge = {
          charge: this.state.data.delivery_charge,
          minOrderAmount: this.state.data.minimum_amount,
        };
      } else {
        updateSellerData.delivery_charge = null;
      }
      if (this.state.shipping) {
        updateSellerData.shipping_charge = {
          charge: this.state.data.shipping_charge,
          minOrderAmount: this.state.data.minimum_amount_shipping,
        };
      } else {
        updateSellerData.delivery_charge = null;
      }
      this.props.sellerUpdate(updateSellerData, this.callbackSellerUpdate);
    } else {
      this.setState({
        labelChanges: "Save Changes",
        loader: false,
        btnClass: "btn btn-default",
      });
      toast(<AlertError message={invalidAddress} />);
    }
  };

  callbackSellerUpdate = (res) => {
    this.ref.current.complete();

    this.setState({
      labelChanges: "Save Changes",
      loader: false,
      btnClass: "btn btn-default",
    });
    this.props.loadSellerProfile();
    if (res.status === 200) {
      // if (this.state.fieldsEditable) {
      //   this.setState({ fieldsEditable: false });
      // }
      toast(<AlertSuccess message={informationSaved} />);
    } else {
      if (!this.state.loader) {
        this.setState({
          loader: false,
          btnClass: "btn btn-default",
        });
      }
      toast(<AlertError message={res.data.error} />);
    }
  };

  dCancel = () => {
    window.location.reload();
    //this.props.loadSellerProfile();
    // if (this.state.fieldsEditable) {
    //   this.setState({ fieldsEditable: false });
    // }
  };

  changeState = (e) => {
    const { data, errors } = this.state;
    data.delivery_state = e.value;
    errors.delivery_state = e.value ? "" : "Required";
    this.setState({ data, errors });
    this.props.loadCity(e.value);
  };

  changeCity = (e) => {
    const { data, errors } = this.state;
    data.delivery_city = e.value;
    errors.delivery_city = e.value ? "" : "Required";
    this.setState({ data, errors });
  };

  handleSettingDropdown = () => {
    if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
      this.setState({ settingDropdown: !this.state.settingDropdown });
    } else {
      this.setState({ settingDropdown: !this.state.settingDropdown });
    }
  };

  handleProfileDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
      this.setState({ profileDropdown: !this.state.profileDropdown });
    } else {
      this.setState({ profileDropdown: !this.state.profileDropdown });
    }
  };

  setSettingDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
    } else if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
    }
  };

  handleVisa = () => {
    this.setState({ visa: !this.state.visa });
  };

  handleMaster = () => {
    this.setState({
      mastercard: !this.state.mastercard,
    });
  };

  handleAmerican = () => {
    this.setState({
      americanExpress: !this.state.americanExpress,
    });
  };

  handleCash = () => {
    this.setState({ cash: !this.state.cash });
  };

  handleCheck = () => {
    this.setState({ check: !this.state.check });
  };
  handleZelle = () => {
    this.setState({ zelle: !this.state.zelle });
  };
  handleVenmo = () => {
    this.setState({ venmo: !this.state.venmo });
  };

  handleEditorChange = (content) => {
    const data = { ...this.state.data };
    data.about = content;
    this.setState({ data });
  };

  handleChange1 = (address) => {
    this.setState({ formatted_address: address });
  };

  handleSelect = (address) => {
    this.setState({ formatted_address: address });
    geocodeByAddress(address)
      .then((results) => {
        console.log("result", results);
        var street1;
        const zipCode = results[0].address_components.find(
          (ra) => ra.types[0] === "postal_code"
        );
        if (
          results[0].address_components.find(
            (ra) => ra.types[0] === "street_number"
          ) &&
          results[0].address_components.find(
            (ra) => ra.types[0] === "street_number"
          ).long_name
        ) {
          street1 = `${
            results[0].address_components.find(
              (ra) => ra.types[0] === "street_number"
            ).long_name
          } ${
            results[0].address_components.find((ra) => ra.types[0] === "route")
              .long_name
          }`;
        } else {
          street1 = results[0].address_components.find(
            (ra) => ra.types[0] === "route"
          ).long_name;
        }
        const street2 = results[0].address_components.find(
          (ra) => ra.types[0] === "administrative_area_level_2"
        );
        const city = results[0].address_components.find(
          (ra) => ra.types[0] === "locality"
        );
        const state = results[0].address_components.find(
          (ra) => ra.types[0] === "administrative_area_level_1"
        );
        const country = results[0].address_components.find(
          (ra) => ra.types[0] === "country"
        );
        const latitude = results[0].geometry.location.lat();
        const longitude = results[0].geometry.location.lng();
        const formatted_address = results[0].formatted_address;
        this.setState({
          zip: zipCode && zipCode.long_name,
          street1,
          street2: street2 && street2.long_name,
          city: city && city.long_name,
          state: state && state.short_name,
          country: country && country.short_name,
          formatted_address,
          latitude,
          longitude,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  render() {
    const selectedState = this.props.getCity.state.find(
      (es) => es.code === this.state.data.delivery_state
    );

    const selectedCity = this.props.getCity.city.find(
      (es) => es.name.toUpperCase() === this.state.data.delivery_city
    );
    console.log("error", this.state.errors);
    console.log("state", this.state);
    return (
      <>
        <div id="main" onClick={this.setSettingDropdown}>
          <div className="dashboard-container custom-top">
            <TopBar
              handleProfileDropdown={this.handleProfileDropdown}
              handleSettingDropdown={this.handleSettingDropdown}
              settingDropdown={this.state.settingDropdown}
              profileDropdown={this.state.profileDropdown}
              loaDerRef={this.ref}
              sellerProfile={this.props.sellerProfile}
            />
            <div className="form-container">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <label>Photo</label>
                        <div className="seller-info d-flex justify-content-between align-items-center">
                          <a
                            href={
                              !this.state.data.photo
                                ? Group
                                : process.env.REACT_APP_S3URL +
                                  this.state.data.photo.small
                            }
                            target="_blank"
                            rel="noreferrer"
                            className="seller-img custom-seller-img"
                          >
                            {this.state.photoStatus === "..." ? (
                              this.state.photoStatus
                            ) : !this.state.data.photo ? (
                              <img
                                className="seller-profile-img"
                                src={Group}
                                alt=""
                              />
                            ) : (
                              <img
                                className="seller-profile-img"
                                src={
                                  this.state.data.photo
                                    ? process.env.REACT_APP_S3URL +
                                      this.state.data.photo.medium
                                    : ""
                                }
                                alt=""
                              />
                            )}
                            <div>
                              <label
                                className={
                                  this.state.errors.photo
                                    ? "editable errorColor"
                                    : "editable"
                                }
                                htmlFor="photograph2"
                              >
                                <span>
                                  <img
                                    src={
                                      this.state.photoStatus === "..."
                                        ? loaderIconBlue
                                        : Vector
                                    }
                                    alt=""
                                  />
                                </span>
                              </label>
                              <input
                                accept="image/*"
                                type="file"
                                id="photograph2"
                                className="editable edit-image"
                                onChange={this.fileUpload}
                                disabled={this.state.photoStatus === "..."}
                              />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <label>Video</label>
                        <div className="seller-info">
                          <div className="seller-img custom-seller-img sellerVideo">
                            {this.state.videoStatus === "..." ? (
                              this.state.videoStatus
                            ) : !this.state.data.video ? (
                              <img
                                className="seller-profile-img"
                                src={Group}
                                alt=""
                              />
                            ) : (
                              this.state.data.video && (
                                <video
                                  width="100%"
                                  controls
                                  key={this.state.data.video}
                                >
                                  <source
                                    src={
                                      process.env.REACT_APP_S3URL +
                                      this.state.data.video
                                    }
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              )
                            )}

                            <label
                              className={
                                this.state.errors.video
                                  ? "editable errorColor"
                                  : "editable"
                              }
                              htmlFor="videoUpload"
                            >
                              <span>
                                <img
                                  src={
                                    this.state.videoStatus === "..."
                                      ? loaderIconBlue
                                      : Vector
                                  }
                                  alt=""
                                />
                              </span>
                            </label>
                            <input
                              accept="video/*"
                              type="file"
                              id="videoUpload"
                              className="editable edit-image"
                              onChange={this.fileUploadVideo}
                              disabled={this.state.videoStatus === "..."}
                            />
                            <label class="error" style={{ color: "red" }}>
                              Video file cannot be more than{" "}
                              {process.env.REACT_APP_VIDEOUPLOADMAX}MB
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-5">
                        <div className="form-group">
                          {this.renderInput("companyName", "Company Name")}
                        </div>
                        <div className="form-group">
                          {/* {this.renderText("about", "About")} */}
                          <label>About</label>
                          <Editor
                            apiKey={process.env.REACT_APP_TINYMCE_KEY}
                            value={this.state.data.about}
                            init={{
                              branding: false,
                              height: 300,
                              menubar: false,
                              plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount image",
                              ],

                              toolbar:
                                "undo redo | formatselect | bold italic backcolor | \
														                    alignleft aligncenter alignright alignjustify | \
														                    bullist numlist outdent indent | removeformat | help | image",
                            }}
                            onEditorChange={this.handleEditorChange}
                          />
                          {this.state.errors.about && (
                            <div className="fv-plugins-message-container">
                              <div
                                data-validator="notEmpty"
                                className="fv-help-block"
                              >
                                {this.state.errors.about}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          {this.renderInput("url", "Url")}
                        </div>
                        <div class="form-group">
                          <div>
                            <label for="url">Store URL</label>
                            <div class="form-group custom-input show on">
                              <input
                                readOnly
                                name="url"
                                id="url"
                                class="form-control input-data"
                                type="text"
                                value={
                                  process.env.REACT_APP_BASEURL +
                                  "store/" +
                                  this.props.sellerProfile.seller_id
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              {this.renderInput("firstName", "First Name")}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              {this.renderInput("lastName", "Last Name")}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              {this.renderInput(
                                "email",
                                "Email",
                                "text",
                                !this.state.isUpdateEmail
                              )}
                              <div
                                className={
                                  this.state.labelEmail === "Cancel"
                                    ? "verified custom-update update"
                                    : "verified custom-update"
                                }
                              >
                                <VerifyEmailBtn
                                  onClickHandle={
                                    this.state.labelEmail === "Cancel"
                                      ? this.doCancelEmail
                                      : this.doSubmitEmail
                                  }
                                  loading={this.state.emailLoader}
                                  label={this.state.labelEmail}
                                  btnClass="btn fetch-btn"
                                />
                              </div>
                              {this.state.isEnterEmailOtp ? (
                                <>
                                  <div>
                                    <div className="otp-box">
                                      <input
                                        value={this.state.emailOtp.w}
                                        className="form-control"
                                        id="codeBoxW"
                                        type="text"
                                        name="w"
                                        onChange={this.onChangeEmail}
                                        maxLength="1"
                                        ref={this.wRef}
                                      />
                                      <input
                                        value={this.state.emailOtp.x}
                                        className="form-control"
                                        id="codeBoxX"
                                        type="text"
                                        name="x"
                                        onChange={this.onChangeEmail}
                                        maxLength="1"
                                        ref={this.xRef}
                                      />
                                      <input
                                        value={this.state.emailOtp.y}
                                        className="form-control"
                                        id="codeBoxY"
                                        type="text"
                                        name="y"
                                        onChange={this.onChangeEmail}
                                        maxLength="1"
                                        ref={this.yRef}
                                      />
                                      <input
                                        value={this.state.emailOtp.z}
                                        className="form-control"
                                        id="codeBoxZ"
                                        type="text"
                                        name="z"
                                        onChange={this.onChangeEmail}
                                        maxLength="1"
                                        ref={this.zRef}
                                      />
                                    </div>

                                    <div className="verified custom form-group form-button">
                                      <a
                                        onClick={this.doSubmitEmailOtp}
                                        href="/#"
                                        type="submit"
                                        className="btn fetch-btn"
                                      >
                                        Verify
                                      </a>
                                    </div>
                                  </div>

                                  <div className="resend-box">
                                    <p>
                                      Didn't get the code?{" "}
                                      <a href="/#" onClick={this.reSendEmail}>
                                        Resend
                                      </a>
                                    </p>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              {this.renderInput(
                                "mobile",
                                "Mobile Number",
                                "text",
                                !this.state.isUpdateMobile
                              )}
                              <div
                                className={
                                  this.state.labelMobile === "Cancel"
                                    ? "verified custom-update update"
                                    : "verified custom-update"
                                }
                              >
                                <VerifyMobileBtn
                                  onClickHandle={
                                    this.state.labelMobile === "Cancel"
                                      ? this.doCancelMobile
                                      : this.doSubmitMobile
                                  }
                                  loading={this.state.mobileLoader}
                                  label={this.state.labelMobile}
                                  btnClass="btn fetch-btn"
                                />
                              </div>
                              {this.state.isEnterOtp ? (
                                <>
                                  <div>
                                    <div className="otp-box">
                                      <input
                                        value={this.state.otp.a}
                                        className="form-control"
                                        id="codeBox1"
                                        type="text"
                                        name="a"
                                        onChange={this.onChange}
                                        maxLength="1"
                                        ref={this.aRef}
                                      />
                                      <input
                                        value={this.state.otp.b}
                                        className="form-control"
                                        id="codeBox2"
                                        type="text"
                                        name="b"
                                        onChange={this.onChange}
                                        maxLength="1"
                                        ref={this.bRef}
                                      />
                                      <input
                                        value={this.state.otp.c}
                                        className="form-control"
                                        id="codeBox3"
                                        type="text"
                                        name="c"
                                        onChange={this.onChange}
                                        maxLength="1"
                                        ref={this.cRef}
                                      />
                                      <input
                                        value={this.state.otp.d}
                                        className="form-control"
                                        id="codeBox4"
                                        type="text"
                                        name="d"
                                        onChange={this.onChange}
                                        maxLength="1"
                                        ref={this.dRef}
                                      />
                                    </div>

                                    <div className="verified custom form-group form-button">
                                      <a
                                        onClick={this.doSubmitOtp}
                                        href="/#"
                                        type="submit"
                                        className="btn fetch-btn"
                                      >
                                        Verify
                                      </a>
                                    </div>
                                  </div>
                                  <div className="resend-box">
                                    <p>
                                      Didn't get the code?{" "}
                                      <a href="/#" onClick={this.reSend}>
                                        Resend
                                      </a>
                                    </p>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        {/* <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              {this.renderInput("street1", "Address 1")}
                            </div>
                          </div>
                        </div> */}

                        {/* <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              {this.renderInput("street2", "Address 2")}
                            </div>
                          </div>
                        </div> */}

                        {/* <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              {this.renderInput("zip", "Zip Code")}
                            </div>
                          </div>
                          <div className="save-change-btn col-md-6 mt-4">
                            <FetchBtn
                              disabled={
                                this.state.data.zip &&
                                (this.state.data.zip.length < 5 ||
                                this.state.data.zip.length > 5
                                  ? "true"
                                  : "")
                              }
                              onClickHandle={this.handleSubmitZip}
                              loading={this.state.zipLoader}
                              label={this.state.labelFetch}
                              btnClass="btn fetch-btn"
                            />
                          </div>
                        </div> */}

                        <div className="row">
                          <div className="col-md-12">
                            <PlacesAutocomplete
                              value={this.state.formatted_address}
                              onChange={this.handleChange1}
                              onSelect={this.handleSelect}
                              searchOptions={searchOptions}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <div className="form-group not-empty">
                                    <label>Address</label>
                                    <input
                                      onChange={this.handleChange1}
                                      value={this.state.formatted_address}
                                      type="text"
                                      class="form-control"
                                      aria-label=""
                                      aria-describedby="basic-addon1"
                                      {...getInputProps({
                                        placeholder: "Enter your address",
                                        className: "form-control",
                                      })}
                                    />
                                  </div>
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer",
                                          }
                                        : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                          };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group delivery-mode">
                          <label htmlFor="city">Mode</label>
                          <div className="form-check-group d-flex flex-column flex-md-row flex-wrap">
                            <div className="form-check">
                              <input
                                checked={this.state.deliver.pick}
                                onChange={this.handleDeliveryChange}
                                className="form-check-input custom-checkbox"
                                name="modeOfDelivery"
                                type="checkbox"
                                value=""
                                id="pick"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="pick"
                              >
                                Pick up
                              </label>
                            </div>

                            <div className="form-check">
                              <input
                                checked={this.state.delivery_option}
                                onChange={this.handleDeliveryOption}
                                className="form-check-input custom-checkbox"
                                name="modeOfDelivery"
                                type="checkbox"
                                value=""
                                id="sameday"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="sameday"
                              >
                                Delivery
                              </label>
                            </div>
                            <div className="form-check d-flex align-items-center dis-check-cont">
                              <input
                                checked={this.state.shipping}
                                onChange={this.handleShipping}
                                className={
                                  this.state.shipping_enabled_by_admin
                                    ? "form-check-input custom-checkbox "
                                    : "form-check-input custom-checkbox dis-check"
                                }
                                name="modeOfDelivery"
                                type="checkbox"
                                value=""
                                id="shipping"
                                disabled={this.state.shipping_enabled_by_admin}
                              />
                              <label
                                className={
                                  this.state.shipping_enabled_by_admin
                                    ? "form-check-label"
                                    : "form-check-label dis-check"
                                }
                                htmlFor="shipping"
                              >
                                Shipping
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-5">
                        <div className="row">
                          {this.state.shipping && (
                            <>
                              <div className="col-md-12">
                                <div className="form-group">
                                  {this.renderInput(
                                    "shipping_charge",
                                    "Shipping Charges"
                                  )}
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group">
                                  <div className="form-group">
                                    {this.renderInput(
                                      "minimum_amount_shipping",
                                      "Minimum Amount (above which shipping charges will not be applicable)"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-5">
                        <div className="row">
                          {this.state.delivery_option && (
                            <div className="col-md-6">
                              <div className="form-group">
                                {this.renderSelect(
                                  "delivery_option",
                                  "Delivery Option",
                                  [
                                    {
                                      id: "",
                                      name: "Select",
                                    },
                                    {
                                      id: "Same day delivery",
                                      name: "Same day delivery",
                                    },
                                    {
                                      id: "Weekly once",

                                      name: "Weekly once",
                                    },
                                    {
                                      id: "More than a week",
                                      name: "More than a week",
                                    },
                                  ]
                                )}
                              </div>
                            </div>
                          )}

                          {this.state.delivery_option && (
                            <div className="col-md-6">
                              <div className="form-group">
                                {this.renderSelect(
                                  "delivery_area",
                                  "Delivery Area",
                                  [
                                    {
                                      id: "",
                                      name: "Select",
                                    },
                                    {
                                      id: "City",
                                      name: "City",
                                    },
                                    {
                                      id: "Zip_code",
                                      name: "Zip code",
                                    },
                                    {
                                      id: "Custom area on a map",
                                      name: "Custom area on a map",
                                    },
                                  ]
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-lg-5">
                        <div className="row">
                          {this.state.delivery_option &&
                            this.state.data.delivery_area === "City" && (
                              <>
                                <div className="col-md-6">
                                  <SelectOptions
                                    width={"auto"}
                                    value={{
                                      label: selectedState
                                        ? selectedState.name
                                        : "",
                                      value: selectedState
                                        ? selectedState.code
                                        : "",
                                    }}
                                    onChange={(e) => this.changeState(e)}
                                    options={this.props.getCity.state.map(
                                      (e) => ({
                                        value: e.code,
                                        label: e.name,
                                      })
                                    )}
                                  />
                                </div>
                                {this.state.data.delivery_state && (
                                  <div className="col-md-6">
                                    <SelectOptions
                                      width={"auto"}
                                      value={{
                                        label: selectedCity
                                          ? selectedCity.name
                                          : "",
                                        value: selectedCity
                                          ? selectedCity.code
                                          : "",
                                      }}
                                      onChange={(e) => this.changeCity(e)}
                                      options={this.props.getCity.city.map(
                                        (e) => ({
                                          value: e.name.toUpperCase(),
                                          label: e.name.toUpperCase(),
                                        })
                                      )}
                                    />
                                  </div>
                                )}
                              </>
                            )}

                          {this.state.delivery_option &&
                            this.state.data.delivery_area === "Zip_code" && (
                              <>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    {this.renderInput(
                                      "delivery_zip",
                                      "Zip Code"
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                    {this.state.delivery_option && (
                      <>
                        {" "}
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="form-check">
                                <input
                                  checked={this.state.delivery_charge}
                                  onChange={this.handleDeliveryCharge}
                                  className="form-check-input custom-checkbox"
                                  name="deliveryCharge"
                                  type="checkbox"
                                  value=""
                                  id="del_charge"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="del_charge"
                                >
                                  Delivery Charges Applicable
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-5">
                            <div className="row">
                              {this.state.delivery_charge && (
                                <>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      {this.renderInput(
                                        "delivery_charge",
                                        "Delivery Charges"
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <div className="form-group">
                                        {this.renderInput(
                                          "minimum_amount",
                                          "Minimum Amount (above which charges will not be applicable)"
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="row mt-2">
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="form-check">
                            <input
                              checked={this.state.payLater}
                              onChange={this.handlePayLater}
                              className="form-check-input custom-checkbox"
                              name="payLater"
                              type="checkbox"
                              value=""
                              id="pay_later"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="pay_later"
                            >
                              Allow customers to pay at delivery/pick
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.payLater && (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group delivery-mode">
                            <div className="form-check-group d-flex flex-column flex-md-row flex-wrap">
                              <div className="form-check">
                                <input
                                  checked={this.state.visa}
                                  onChange={this.handleVisa}
                                  className="form-check-input custom-checkbox"
                                  name="modeOfPayment"
                                  type="checkbox"
                                  value=""
                                  id="visa"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="visa"
                                >
                                  Visa
                                </label>
                              </div>

                              <div className="form-check">
                                <input
                                  checked={this.state.mastercard}
                                  onChange={this.handleMaster}
                                  className="form-check-input custom-checkbox"
                                  name="modeOfPayment"
                                  type="checkbox"
                                  value=""
                                  id="master_card"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="master_card"
                                >
                                  Mastercard
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  checked={this.state.americanExpress}
                                  onChange={this.handleAmerican}
                                  className="form-check-input custom-checkbox"
                                  name="modeOfPayment"
                                  type="checkbox"
                                  value=""
                                  id="american_exp"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="american_exp"
                                >
                                  American Express
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  checked={this.state.cash}
                                  onChange={this.handleCash}
                                  className="form-check-input custom-checkbox"
                                  name="modeOfPayment"
                                  type="checkbox"
                                  value=""
                                  id="cash"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="cash"
                                >
                                  Cash
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  checked={this.state.check}
                                  onChange={this.handleCheck}
                                  className="form-check-input custom-checkbox"
                                  name="modeOfPayment"
                                  type="checkbox"
                                  value=""
                                  id="check"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="check"
                                >
                                  Check
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  checked={this.state.zelle}
                                  onChange={this.handleZelle}
                                  className="form-check-input custom-checkbox"
                                  name="modeOfPayment"
                                  type="checkbox"
                                  value=""
                                  id="zelle"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="zelle"
                                >
                                  Zelle
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  checked={this.state.venmo}
                                  onChange={this.handleVenmo}
                                  className="form-check-input custom-checkbox"
                                  name="modeOfPayment"
                                  type="checkbox"
                                  value=""
                                  id="venmo"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="venmo"
                                >
                                  Venmo
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row custom-policy">
                      <div className="col-lg-5">
                        <div className="form-group">
                          {this.renderText("returnPolicy", "Return Policy")}
                        </div>
                        <div className="form-group">
                          {this.renderText("refundPolicy", "Refund Policy")}
                        </div>
                      </div>
                    </div>
                    {
                      <div className="save-change-btn mt-4 mt-md-5">
                        <form onSubmit={this.handleSubmit}>
                          <SubmitBtn
                            disabled={this.state.photoStatus === "..."}
                            // label={this.state.labelChanges}
                            label="Save Changes"
                            btnClass={this.state.btnClass}
                            loading={this.state.loader}
                          />
                          <button onClick={this.dCancel} className="btn">
                            Cancel
                          </button>
                        </form>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profileLoading: getSeller(state).profileLoading,
  getCity: getCities(state),
  sellerProfile: getSeller(state).profile,
  profileImage: getSeller(state).profilePhoto,
});

const mapDispatchToProps = (dispatch) => ({
  mobileAvailability: (param, callback) =>
    dispatch(mobileAvailability(param, callback)),
  updateMobile: (data, callback) => dispatch(updateMobile(data, callback)),
  sendMobileOtp: (param, callback) => dispatch(sendMobileOtp(param, callback)),
  emailAvailability: (param, callback) =>
    dispatch(emailAvailability(param, callback)),
  updateEmail: (data, callback) => dispatch(updateEmail(data, callback)),
  sendEmailOtp: (param, callback) => dispatch(sendEmailOtp(param, callback)),
  zipValidation: (data, callback) => dispatch(zipValidation(data, callback)),
  addressValidation: (data, callback) =>
    dispatch(addressValidation(data, callback)),
  sellerUpdate: (data, callback) => dispatch(sellerUpdate(data, callback)),
  loadSellerProfile: () => dispatch(loadSellerProfile()),
  loadState: () => dispatch(loadState()),
  loadCity: (code) => dispatch(loadCity(code)),
  uploadProfilePictures: (data, callback) =>
    dispatch(uploadProfilePictures(data, callback)),
  uploadProfileVideo: (data, callback) =>
    dispatch(uploadProfileVideo(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
