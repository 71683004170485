import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import logo from "../../include/images/logo.svg";
import closeicon from "../../include/images/close_icon-1.svg";
import cartIcon from "../../include/images/cart-icon_1.svg";
import homeIcon from "../../include/images/home-icon.svg";
import userIcon from "../../include/images/user-icon.svg";
import user from "../../include/images/user.svg";
import closeIcon from "./../../include/images/close-icon.svg";
import phone from "../../include/images/phone-icon.svg";
import enter from "../../include/images/enter-icon.svg";
import Vector from "../../include/images/Vector.svg";
import aimWh from "../../include/images/aim-white.svg";
import aim1 from "../../include/images/aim 1.svg";
import downArow from "../../include/images/user-down-arrow.svg";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import { getCart, loadCart } from "../../store/cart";
import {
  logoutCustomer,
  loadCustomerProfile,
  getCustomer,
} from "../../store/customer";
import logoutIcon from "../../include/images/logout.svg";

import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import {
  getCustomerName,
  getCustomerToken,
} from "../services/localStorageServices";
import _ from "lodash";
import AddressFlyer from "./addressFlyer";
import UserSideBar from "./userSideBar";
import CategoryMenu from "./categoryMenu";
import { getProduct, loadCategories } from "../../store/product";
import AutoComplete from "./autoComplete";
import Contact from "./../common/contact";

class HeaderHome extends Component {
  state = {
    alertShow: !localStorage.getItem("alertShow"),
    mobileMenu: false,
    userSideBar: false,
    seratchOpen: false,
    adressChange: false,
    isContact: false,
    isShow: false,
    user_device_id: localStorage.getItem("user_device_id"),
    searchText: "",
    sidebar: false,
    param: JSON.parse(localStorage.getItem("customerDddress")),
  };

  toggleSidebar = () => {
    this.setState({ sidebar: !this.state.sidebar });
  };

  toggleUserSideBar = (e) => {
    e.preventDefault();
    this.setState({ userSideBar: !this.state.userSideBar });
  };
  toggleMobileMenu = (e) => {
    e.preventDefault();
    this.setState({ mobileMenu: !this.state.mobileMenu });
  };
  toggleSearch = () => {
    this.setState({ seratchOpen: !this.state.seratchOpen }, () => {
      if (this.state.seratchOpen) {
        document.body.classList.add("search-open");
      } else {
        document.body.classList.remove("search-open");
      }
    });
  };

  //search-open
  handleadressChange = (e) => {
    if (e) e.preventDefault();
    localStorage.setItem("alertShow", true);
    this.setState({ adressChange: !this.state.adressChange, alertShow: false });
    document.body.classList.add("toggled-tab");
  };
  handleClose = () => {
    this.setState(
      {
        adressChange: !this.state.adressChange,
      },
      () =>
        this.props.loadCart(this.state.user_device_id, {
          address: this.state.param && this.state.param.address,
          zipCode: this.state.param && this.state.param.postal_code.long_name,
          lat: this.state.param && this.state.param.lat,
          lon: this.state.param && this.state.param.lng,
        })
    );
    document.body.classList.remove("toggled-tab");
  };
  toggle = () => {
    this.setState({
      isShow: !this.state.isShow,
    });
  };
  toggleContact = () => {
    this.setState({
      isContact: !this.state.isContact,
    });
    document.body.classList.add("toggled-tab");
  };

  removeContact = () => {
    this.setState({
      isContact: !this.state.isContact,
    });
    document.body.classList.remove("toggled-tab");
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.keyword !== prevProps.keyword) {
      this.setState({ searchText: this.props.keyword });
    }
  };

  componentDidMount = () => {
    //this.props.loadCategories();
    localStorage.setItem("redirectUrl", this.props.location.pathname);

    if (this.state.user_device_id) {
      // this.props.loadCustomerProfile();
      this.props.loadCart(this.state.user_device_id, {
        address: this.state.param && this.state.param.address,
        zipCode: this.state.param && this.state.param.postal_code.long_name,
        lat: this.state.param && this.state.param.lat,
        lon: this.state.param && this.state.param.lng,
      });
    } else {
      const uuid = uuidv4();
      localStorage.setItem("user_device_id", uuid);
      this.props.loadCart(uuid, {
        address: this.state.param && this.state.param.address,
        zipCode: this.state.param && this.state.param.postal_code.long_name,
        lat: this.state.param && this.state.param.lat,
        lon: this.state.param && this.state.param.lng,
      });
    }
  };

  logout = (e) => {
    e.preventDefault();
    this.props.logoutCustomer(this.callback);
  };

  callback = (res) => {
    if (res.status === 200) {
      window.localStorage.removeItem("x-auth-token-customer");
      window.localStorage.removeItem("x-refresh-token-customer");
      window.localStorage.removeItem("customer-name");
      window.localStorage.removeItem("redirectUrl");
      localStorage.removeItem("customer_information");
      window.localStorage.removeItem("customerDddress");
      localStorage.removeItem("customerAddress");
      window.location.href = "/";
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };

  // componentDidMount = () => {
  //   console.log("componentDidMount", this.props);
  // };

  render() {
    const adressArr = this.props.address ? this.props.address.split(",") : [];
    const { final_total, total_products } =
      this.props.getCart.cartDetail.orderSummary;
    return (
      <>
        <header id="header4">
          <nav class="navbar navbar-expand-xl">
            <div class="container">
              <div class="nav-inside d-flex align-items-center justify-content-between">
                <Link className="navbar-brand" to="/products-listing">
                  <img src={logo} alt="" />
                </Link>
                <button
                  class={
                    this.state.sidebar
                      ? "navbar-toggler"
                      : "navbar-toggler collapsed"
                  }
                  type="button"
                  data-toggle="collapse"
                  data-target="#mainNav"
                  aria-controls="mainNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={this.toggleSidebar}
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                {localStorage.getItem("x-auth-token-seller") ? (
                  <div
                    class={
                      this.state.sidebar
                        ? "collapse navbar-collapse show"
                        : "collapse navbar-collapse"
                    }
                    id="mainNav"
                  >
                    <div class="navbar-inside w-100">
                      <ul class="navbar-nav justify-content-lg-end">
                        <li class="nav-item">
                          <Link class="nav-link" to={"/product-listing"}>
                            Back to seller dashboard
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div
                    class={
                      this.state.sidebar
                        ? "collapse navbar-collapse show"
                        : "collapse navbar-collapse"
                    }
                    id="mainNav"
                  >
                    {getCustomerToken("refresh") ? (
                      <div class="navbar-inside w-100">
                        <ul class="navbar-nav justify-content-lg-end">
                          <li class="nav-item">
                            <Link class="nav-link" to="/products-listing">
                              Home
                            </Link>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              href="javascript:void(0)"
                              onClick={this.toggleContact}
                            >
                              Contact Us
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              href="https://effisim.info/FAQ"
                              target="_blank"
                            >
                              About
                            </a>
                          </li>
                        </ul>
                        <div class="nav-inside-content d-block d-lg-none text-center">
                          <div class="header-btn-group d-flex align-items-center">
                            <Link
                              to={"/customer/account-details"}
                              class="header-btn header-blue-btn"
                            >
                              My Account
                            </Link>
                            <a
                              href="javascript:void(0)"
                              class="header-btn header-grey-btn"
                              onClick={this.logout}
                            >
                              Logout
                            </a>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div class="navbar-inside w-100">
                        <ul class="navbar-nav justify-content-lg-end">
                          <li class="nav-item">
                            <Link class="nav-link" to="/products-listing">
                              Home
                            </Link>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              href="javascript:void(0)"
                              onClick={this.toggleContact}
                            >
                              Contact Us
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              href="https://effisim.info/FAQ"
                              target="_blank"
                            >
                              About
                            </a>
                          </li>
                        </ul>
                        <div class="nav-inside-content d-block d-lg-none text-center">
                          <div class="header-btn-group d-flex align-items-center">
                            <Link
                              to="/customer/login"
                              class="header-btn header-grey-btn"
                            >
                              Login
                            </Link>
                            <Link
                              to="/customer/registration"
                              class="header-btn header-blue-btn"
                            >
                              Sign up
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {localStorage.getItem("x-auth-token-seller") ? (
                  ""
                ) : getCustomerToken("refresh") ? (
                  <div class="new-header-right d-lg-flex align-items-lg-center justify-content-lg-end">
                    <div class="header-btn-group d-lg-flex align-items-lg-center justify-content-lg-end  d-none d-lg-block d-lg-flex align-items-lg-center">
                      <Link
                        to={"/customer/account-details"}
                        class="header-btn header-blue-btn"
                      >
                        My Account
                      </Link>
                      <a
                        href="javascript:void(0)"
                        class="header-btn header-grey-btn"
                        onClick={this.logout}
                      >
                        Logout
                      </a>
                    </div>
                    <div class="header-right-content ml-auto">
                      <div class="cart-icon">
                        <i
                          onClick={(e) => {
                            this.props.history.push("/cart");
                          }}
                        >
                          <img src={cartIcon} alt="" />
                          <span class="price-icon">
                            {total_products ? total_products : 0}
                          </span>
                        </i>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div class="new-header-right d-lg-flex align-items-lg-center justify-content-lg-end">
                    <div class="header-btn-group d-lg-flex align-items-lg-center justify-content-lg-end  d-none d-lg-block d-lg-flex align-items-lg-center">
                      <Link
                        to="/customer/login"
                        class="header-btn header-grey-btn"
                      >
                        Login
                      </Link>
                      <Link
                        to="/customer/registration"
                        class="header-btn header-blue-btn"
                      >
                        Sign up
                      </Link>
                    </div>
                    <div class="header-right-content ml-auto">
                      <div class="cart-icon">
                        <i
                          onClick={(e) => {
                            this.props.history.push("/cart");
                          }}
                        >
                          <img src={cartIcon} alt="" />
                          <span class="price-icon">
                            {total_products ? total_products : 0}
                          </span>
                        </i>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </nav>
        </header>
        <Contact
          isContact={this.state.isContact}
          toggleContact={this.toggleContact}
          removeContact={this.removeContact}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  getCart: getCart(state),
  customerProfile: getCustomer(state).profile,
  pagination: getProduct(state).pagination,
  loading: getProduct(state).loading,
});
const mapDispatchToProps = (dispatch) => ({
  loadCart: (id, data) => dispatch(loadCart(id, data)),
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
  logoutCustomer: (callback) => dispatch(logoutCustomer(callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderHome)
);
