import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AlertSuccess from "../common/alertSuccess";
import AlertError from "../common/alertError";
// import Footer from "../common/footer";
import TopBar from "../common/topBar";
import delete1 from "../../include/images/delete1.svg";
import SelectOptions from "../common/selectOptions";
import SubmitBtn from "../common/form/submitBtn";
import { getSeller, loadSellerProfile, sellerUpdate } from "../../store/seller";
import { failed, informationSaved } from "../common/misc";

class Schedule extends Component {
  state = {
    loading: false,
    settingDropdown: false,
    profileDropdown: false,
    data: {
      sun: true,
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: true,
    },
    timeSlot: {
      sun: [[{ id: "0800" }, { id: "1700" }]],
      mon: [[{ id: "0800" }, { id: "1700" }]],
      tue: [[{ id: "0800" }, { id: "1700" }]],
      wed: [[{ id: "0800" }, { id: "1700" }]],
      thu: [[{ id: "0800" }, { id: "1700" }]],
      fri: [[{ id: "0800" }, { id: "1700" }]],
      sat: [[{ id: "0800" }, { id: "1700" }]],
    },
  };

  timeDropdownNew = [
    { value: "0000", label: "12:00 AM" },
    { value: "0030", label: "12:30 AM" },
    { value: "0100", label: "01:00 AM" },
    { value: "0130", label: "01:30 AM" },
    { value: "0200", label: "02:00 AM" },
    { value: "0230", label: "02:30 AM" },
    { value: "0300", label: "03:00 AM" },
    { value: "0330", label: "03:30 AM" },
    { value: "0400", label: "04:00 AM" },
    { value: "0430", label: "04:30 AM" },
    { value: "0500", label: "05:00 AM" },
    { value: "0530", label: "05:30 AM" },
    { value: "0600", label: "06:00 AM" },
    { value: "0630", label: "06:30 AM" },
    { value: "0700", label: "07:00 AM" },
    { value: "0730", label: "07:30 AM" },
    { value: "0800", label: "08:00 AM" },
    { value: "0830", label: "08:30 AM" },
    { value: "0900", label: "09:00 AM" },
    { value: "0930", label: "09:30 AM" },
    { value: "1000", label: "10:00 AM" },
    { value: "1030", label: "10:30 AM" },
    { value: "1100", label: "11:00 AM" },
    { value: "1130", label: "11:30 AM" },
    { value: "1200", label: "12:00 PM" },
    { value: "1230", label: "12:30 PM" },
    { value: "1300", label: "01:00 PM" },
    { value: "1330", label: "01:30 PM" },
    { value: "1400", label: "02:00 PM" },
    { value: "1430", label: "02:30 PM" },
    { value: "1500", label: "03:00 PM" },
    { value: "1530", label: "03:30 PM" },
    { value: "1600", label: "04:00 PM" },
    { value: "1630", label: "04:30 PM" },
    { value: "1700", label: "05:00 PM" },
    { value: "1730", label: "05:30 PM" },
    { value: "1800", label: "06:00 PM" },
    { value: "1830", label: "06:30 PM" },
    { value: "1900", label: "07:00 PM" },
    { value: "1930", label: "07:30 PM" },
    { value: "2000", label: "08:00 PM" },
    { value: "2030", label: "08:30 PM" },
    { value: "2100", label: "09:00 PM" },
    { value: "2130", label: "09:30 PM" },
    { value: "2200", label: "10:00 PM" },
    { value: "2230", label: "10:30 PM" },
    { value: "2300", label: "11:00 PM" },
    { value: "2330", label: "11:30 PM" },
    { value: "2400", label: "12:00 AM" },
  ];

  doSubmit = (e) => {
    e.preventDefault();

    const { timeSlot, data } = this.state;
    const { sun, mon, tue, wed, thu, fri, sat } = data;

    const payLoad = {
      schedules: {},
    };
    payLoad.schedules.sun = !sun
      ? []
      : timeSlot.sun.map((el, i) => ({
          start_time: el[0].id,
          end_time: el[1].id,
        }));

    payLoad.schedules.mon = !mon
      ? []
      : timeSlot.mon.map((el, i) => ({
          start_time: el[0].id,
          end_time: el[1].id,
        }));
    payLoad.schedules.tue = !tue
      ? []
      : timeSlot.tue.map((el, i) => ({
          start_time: el[0].id,
          end_time: el[1].id,
        }));
    payLoad.schedules.wed = !wed
      ? []
      : timeSlot.wed.map((el, i) => ({
          start_time: el[0].id,
          end_time: el[1].id,
        }));
    payLoad.schedules.thu = !thu
      ? []
      : timeSlot.thu.map((el, i) => ({
          start_time: el[0].id,
          end_time: el[1].id,
        }));
    payLoad.schedules.fri = !fri
      ? []
      : timeSlot.fri.map((el, i) => ({
          start_time: el[0].id,
          end_time: el[1].id,
        }));
    payLoad.schedules.sat = !sat
      ? []
      : timeSlot.sat.map((el, i) => ({
          start_time: el[0].id,
          end_time: el[1].id,
        }));

    if (!this.state.loading) {
      this.setState({
        btnClass: "btn btn-primary btn-lg disabled",
        loading: true,
      });
    }
    payLoad.schedules.tbd = false;
    this.props.sellerUpdate(payLoad, this.callback);
  };
  callback = (res) => {
    if (res && res.status === 200) {
      toast(<AlertSuccess message={informationSaved} />);
    } else {
      toast(<AlertError message={failed} />);
    }
    this.props.history.push("hours-of-operation");
  };
  changeBodyClass = () => {
    document.body.classList.add("active");
  };

  componentDidUpdate = (prevProps, prevState) => {};

  customStyles = {
    indicatorSeparator: (styles) => ({ display: "none" }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#297ab9",
      },

      color: state.isSelected ? "#297ab9" : "black",
      backgroundColor: state.isSelected
        ? "rgb(95, 50, 187, 10%)"
        : provided.backgroundColor,
      //backgroundColor: state.isSelected ? "rgb(95, 50, 187, 10%)" : "white",
    }),

    control: (base, state) => ({
      ...base,
      height: 49,
      minHeight: 49,
      fontSize: 16,
      width: 120,
      borderColor: state.isFocused ? "#297ab9" : "#e0e0e0",
      boxShadow: "0 !important",
      "&:hover": {
        borderColor: "#297ab9",
      },
      "&:focus": {
        borderColor: "#297ab9",
      },
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#6119c0",
      },
    }),
  };

  DayChange = (e) => {
    const { data, timeSlot } = this.state;
    data[e.target.name] = !data[e.target.name];
    timeSlot[e.target.name] = [[{ id: "0800" }, { id: "1700" }]];
    this.setState({ data, timeSlot });
  };

  addMore = (e, day) => {
    e.preventDefault();
    const { timeSlot } = this.state;
    timeSlot[day][timeSlot[day].length] = [{ id: "0800" }, { id: "1700" }];
    this.setState({ timeSlot });
  };

  removeMore = (e, day) => {
    e.preventDefault();
    const { timeSlot } = this.state;
    timeSlot[day].pop();
    this.setState({ timeSlot });
  };
  componentDidMount = () => {
    const data = { ...this.state.data };
    data.sun =
      this.props.location.state.tbd === false &&
      this.props.location.state.sun.length > 0
        ? true
        : false;
    data.mon =
      this.props.location.state.tbd === false &&
      this.props.location.state.mon.length > 0
        ? true
        : false;
    data.tue =
      this.props.location.state.tbd === false &&
      this.props.location.state.tue.length > 0
        ? true
        : false;
    data.wed =
      this.props.location.state.tbd === false &&
      this.props.location.state.wed.length > 0
        ? true
        : false;
    data.thu =
      this.props.location.state.tbd === false &&
      this.props.location.state.thu.length > 0
        ? true
        : false;
    data.fri =
      this.props.location.state.tbd === false &&
      this.props.location.state.fri.length > 0
        ? true
        : false;
    data.sat =
      this.props.location.state.tbd === false &&
      this.props.location.state.sat.length > 0
        ? true
        : false;

    const schedules = this.props.location.state;
    const timeSlot = {};
    timeSlot.sun = schedules.sun.length
      ? schedules.sun.map((el) => [{ id: el.start_time }, { id: el.end_time }])
      : [];
    timeSlot.mon = schedules.mon.length
      ? schedules.mon.map((el) => [{ id: el.start_time }, { id: el.end_time }])
      : [];
    timeSlot.tue = schedules.tue.length
      ? schedules.tue.map((el) => [{ id: el.start_time }, { id: el.end_time }])
      : [];
    timeSlot.wed = schedules.wed.length
      ? schedules.wed.map((el) => [{ id: el.start_time }, { id: el.end_time }])
      : [];
    timeSlot.thu = schedules.thu.length
      ? schedules.thu.map((el) => [{ id: el.start_time }, { id: el.end_time }])
      : [];
    timeSlot.fri = schedules.fri.length
      ? schedules.fri.map((el) => [{ id: el.start_time }, { id: el.end_time }])
      : [];
    timeSlot.sat = schedules.sat.length
      ? schedules.sat.map((el) => [{ id: el.start_time }, { id: el.end_time }])
      : [];

    this.setState({ data, timeSlot });
  };

  selectChangeOption = (e) => {
    const { attrind, attrnum, value, name } = e;
    const { timeSlot } = this.state;
    timeSlot[name][attrind][attrnum].id = value;
    this.setState({ timeSlot });
  };

  changeTime = (e) => {
    this.setState({ time: e });
  };

  handleSettingDropdown = () => {
    if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
      this.setState({ settingDropdown: !this.state.settingDropdown });
    } else {
      this.setState({ settingDropdown: !this.state.settingDropdown });
    }
  };

  handleProfileDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
      this.setState({ profileDropdown: !this.state.profileDropdown });
    } else {
      this.setState({ profileDropdown: !this.state.profileDropdown });
    }
  };

  setSettingDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
    } else if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
    }
  };

  render() {
    return (
      <>
        <div id="main" onClick={this.setSettingDropdown}>
          <div className="dashboard-container custom-top">
            <TopBar
              handleProfileDropdown={this.handleProfileDropdown}
              handleSettingDropdown={this.handleSettingDropdown}
              settingDropdown={this.state.settingDropdown}
              profileDropdown={this.state.profileDropdown}
              sellerProfile={this.props.seller}
            />
            <div className="schedule-container">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <ul className="schedule-content">
                      <li className="schedule-list d-lg-flex flex-row align-items-center">
                        <div className="schedule-day">
                          <div className="form-check">
                            <input
                              name="mon"
                              onChange={this.DayChange}
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckMon"
                              checked={this.state.data.mon}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckMon"
                            >
                              Mon
                            </label>
                          </div>
                        </div>
                        <div className="schedule-time-outer d-xl-flex flex-row">
                          <div className="d-lg-flex flex-column flex-wrap">
                            <div className="schedule-time form-inline d-flex flex-column flex-md-row">
                              {this.state.data.mon ? (
                                <>
                                  {(this.state.timeSlot.mon || []).map(
                                    (el, i) => (
                                      <>
                                        <div className="form-group d-flex">
                                          <SelectOptions
                                            name="mon"
                                            attrind={i}
                                            attrnum="0"
                                            onChange={this.selectChangeOption}
                                            value={this.timeDropdownNew.find(
                                              (es) =>
                                                es.value ===
                                                this.state.timeSlot.mon[i][0].id
                                            )}
                                            nameCl="small"
                                            options={this.timeDropdownNew}
                                          />
                                          <span className="space">-</span>
                                          <SelectOptions
                                            name="mon"
                                            attrind={i}
                                            attrnum="1"
                                            onChange={this.selectChangeOption}
                                            value={this.timeDropdownNew.find(
                                              (es) =>
                                                es.value ===
                                                this.state.timeSlot.mon[i][1].id
                                            )}
                                            nameCl="small"
                                            options={this.timeDropdownNew}
                                          />
                                          {this.state.timeSlot.mon.length > 1 &&
                                            i ===
                                              this.state.timeSlot.mon.length -
                                                1 && (
                                              <a
                                                href="javascript:void(0)"
                                                className="delete-this-field"
                                                onClick={(e) =>
                                                  this.removeMore(e, "mon")
                                                }
                                              >
                                                {" "}
                                                <img src={delete1} alt="" />
                                              </a>
                                            )}
                                        </div>
                                        {this.state.data.mon &&
                                        this.state.timeSlot.mon.length > 1 &&
                                        i - 1 ? (
                                          <span className="small">&#38;</span>
                                        ) : (
                                          ""
                                        )}{" "}
                                      </>
                                    )
                                  )}{" "}
                                </>
                              ) : (
                                "Closed"
                              )}
                              {this.state.data.mon &&
                                this.state.timeSlot.mon.length < 2 && (
                                  <a
                                    href="javascript:void(0)"
                                    className="add-more-field"
                                    onClick={(e) => this.addMore(e, "mon")}
                                  >
                                    + Add
                                  </a>
                                )}
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="schedule-list d-lg-flex flex-row align-items-center">
                        <div className="schedule-day">
                          <div className="form-check">
                            <input
                              name="tue"
                              onChange={this.DayChange}
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckTue"
                              checked={this.state.data.tue}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckTue"
                            >
                              Tue
                            </label>
                          </div>
                        </div>
                        <div className="schedule-time-outer d-xl-flex flex-row">
                          <div className="d-lg-flex flex-column flex-wrap">
                            <div className="schedule-time form-inline d-flex flex-column flex-md-row">
                              {this.state.data.tue ? (
                                <>
                                  {(this.state.timeSlot.tue || []).map(
                                    (el, i) => (
                                      <>
                                        <div className="form-group d-flex">
                                          <SelectOptions
                                            name="tue"
                                            attrind={i}
                                            attrnum="0"
                                            onChange={this.selectChangeOption}
                                            value={this.timeDropdownNew.find(
                                              (es) =>
                                                es.value ===
                                                this.state.timeSlot.tue[i][0].id
                                            )}
                                            nameCl="small"
                                            options={this.timeDropdownNew}
                                          />
                                          <span className="space">-</span>
                                          <SelectOptions
                                            name="tue"
                                            attrind={i}
                                            attrnum="1"
                                            onChange={this.selectChangeOption}
                                            value={this.timeDropdownNew.find(
                                              (es) =>
                                                es.value ===
                                                this.state.timeSlot.tue[i][1].id
                                            )}
                                            nameCl="small"
                                            options={this.timeDropdownNew}
                                          />
                                          {this.state.timeSlot.tue.length > 1 &&
                                            i ===
                                              this.state.timeSlot.tue.length -
                                                1 && (
                                              <a
                                                href="javascript:void(0)"
                                                className="delete-this-field"
                                                onClick={(e) =>
                                                  this.removeMore(e, "tue")
                                                }
                                              >
                                                {" "}
                                                <img src={delete1} alt="" />
                                              </a>
                                            )}
                                        </div>
                                        {this.state.data.tue &&
                                        this.state.timeSlot.tue.length > 1 &&
                                        i - 1 ? (
                                          <span className="small">&#38;</span>
                                        ) : (
                                          ""
                                        )}{" "}
                                      </>
                                    )
                                  )}{" "}
                                </>
                              ) : (
                                "Closed"
                              )}
                              {this.state.data.tue &&
                                this.state.timeSlot.tue.length < 2 && (
                                  <a
                                    href="javascript:void(0)"
                                    className="add-more-field"
                                    onClick={(e) => this.addMore(e, "tue")}
                                  >
                                    + Add
                                  </a>
                                )}
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="schedule-list d-lg-flex flex-row align-items-center">
                        <div className="schedule-day">
                          <div className="form-check">
                            <input
                              name="wed"
                              onChange={this.DayChange}
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckWed"
                              checked={this.state.data.wed}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckWed"
                            >
                              Wed
                            </label>
                          </div>
                        </div>
                        <div className="schedule-time-outer d-xl-flex flex-row">
                          <div className="d-lg-flex flex-column flex-wrap">
                            <div className="schedule-time form-inline d-flex flex-column flex-md-row">
                              {this.state.data.wed ? (
                                <>
                                  {(this.state.timeSlot.wed || []).map(
                                    (el, i) => (
                                      <>
                                        <div className="form-group d-flex">
                                          <SelectOptions
                                            name="wed"
                                            attrind={i}
                                            attrnum="0"
                                            onChange={this.selectChangeOption}
                                            value={this.timeDropdownNew.find(
                                              (es) =>
                                                es.value ===
                                                this.state.timeSlot.wed[i][0].id
                                            )}
                                            nameCl="small"
                                            options={this.timeDropdownNew}
                                          />
                                          <span className="space">-</span>
                                          <SelectOptions
                                            name="wed"
                                            attrind={i}
                                            attrnum="1"
                                            onChange={this.selectChangeOption}
                                            value={this.timeDropdownNew.find(
                                              (es) =>
                                                es.value ===
                                                this.state.timeSlot.wed[i][1].id
                                            )}
                                            nameCl="small"
                                            options={this.timeDropdownNew}
                                          />
                                          {this.state.timeSlot.wed.length > 1 &&
                                            i ===
                                              this.state.timeSlot.wed.length -
                                                1 && (
                                              <a
                                                href="javascript:void(0)"
                                                className="delete-this-field"
                                                onClick={(e) =>
                                                  this.removeMore(e, "wed")
                                                }
                                              >
                                                {" "}
                                                <img src={delete1} alt="" />
                                              </a>
                                            )}
                                        </div>
                                        {this.state.data.wed &&
                                        this.state.timeSlot.wed.length > 1 &&
                                        i - 1 ? (
                                          <span className="small">&#38;</span>
                                        ) : (
                                          ""
                                        )}{" "}
                                      </>
                                    )
                                  )}{" "}
                                </>
                              ) : (
                                "Closed"
                              )}
                              {this.state.data.wed &&
                                this.state.timeSlot.wed.length < 2 && (
                                  <a
                                    href="javascript:void(0)"
                                    className="add-more-field"
                                    onClick={(e) => this.addMore(e, "wed")}
                                  >
                                    + Add
                                  </a>
                                )}
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="schedule-list d-lg-flex flex-row align-items-center">
                        <div className="schedule-day">
                          <div className="form-check">
                            <input
                              name="thu"
                              onChange={this.DayChange}
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckThu"
                              checked={this.state.data.thu}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckThu"
                            >
                              Thu
                            </label>
                          </div>
                        </div>
                        <div className="schedule-time-outer d-xl-flex flex-row">
                          <div className="d-lg-flex flex-column flex-wrap">
                            <div className="schedule-time form-inline d-flex flex-column flex-md-row">
                              {this.state.data.thu ? (
                                <>
                                  {(this.state.timeSlot.thu || []).map(
                                    (el, i) => (
                                      <>
                                        <div className="form-group d-flex">
                                          <SelectOptions
                                            name="thu"
                                            attrind={i}
                                            attrnum="0"
                                            onChange={this.selectChangeOption}
                                            value={this.timeDropdownNew.find(
                                              (es) =>
                                                es.value ===
                                                this.state.timeSlot.thu[i][0].id
                                            )}
                                            nameCl="small"
                                            options={this.timeDropdownNew}
                                          />
                                          <span className="space">-</span>
                                          <SelectOptions
                                            name="thu"
                                            attrind={i}
                                            attrnum="1"
                                            onChange={this.selectChangeOption}
                                            value={this.timeDropdownNew.find(
                                              (es) =>
                                                es.value ===
                                                this.state.timeSlot.thu[i][1].id
                                            )}
                                            nameCl="small"
                                            options={this.timeDropdownNew}
                                          />
                                          {this.state.timeSlot.thu.length > 1 &&
                                            i ===
                                              this.state.timeSlot.thu.length -
                                                1 && (
                                              <a
                                                href="javascript:void(0)"
                                                className="delete-this-field"
                                                onClick={(e) =>
                                                  this.removeMore(e, "thu")
                                                }
                                              >
                                                {" "}
                                                <img src={delete1} alt="" />
                                              </a>
                                            )}
                                        </div>
                                        {this.state.data.thu &&
                                        this.state.timeSlot.thu.length > 1 &&
                                        i - 1 ? (
                                          <span className="small">&#38;</span>
                                        ) : (
                                          ""
                                        )}{" "}
                                      </>
                                    )
                                  )}{" "}
                                </>
                              ) : (
                                "Closed"
                              )}
                              {this.state.data.thu &&
                                this.state.timeSlot.thu.length < 2 && (
                                  <a
                                    href="javascript:void(0)"
                                    className="add-more-field"
                                    onClick={(e) => this.addMore(e, "thu")}
                                  >
                                    + Add
                                  </a>
                                )}
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="schedule-list d-lg-flex flex-row align-items-center">
                        <div className="schedule-day">
                          <div className="form-check">
                            <input
                              name="fri"
                              onChange={this.DayChange}
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckFri"
                              checked={this.state.data.fri}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckFri"
                            >
                              Fri
                            </label>
                          </div>
                        </div>
                        <div className="schedule-time-outer d-xl-flex flex-row">
                          <div className="d-lg-flex flex-column flex-wrap">
                            <div className="schedule-time form-inline d-flex flex-column flex-md-row">
                              {this.state.data.fri ? (
                                <>
                                  {(this.state.timeSlot.fri || []).map(
                                    (el, i) => (
                                      <>
                                        <div className="form-group d-flex">
                                          <SelectOptions
                                            name="fri"
                                            attrind={i}
                                            attrnum="0"
                                            onChange={this.selectChangeOption}
                                            value={this.timeDropdownNew.find(
                                              (es) =>
                                                es.value ===
                                                this.state.timeSlot.fri[i][0].id
                                            )}
                                            nameCl="small"
                                            options={this.timeDropdownNew}
                                          />
                                          <span className="space">-</span>
                                          <SelectOptions
                                            name="fri"
                                            attrind={i}
                                            attrnum="1"
                                            onChange={this.selectChangeOption}
                                            value={this.timeDropdownNew.find(
                                              (es) =>
                                                es.value ===
                                                this.state.timeSlot.fri[i][1].id
                                            )}
                                            nameCl="small"
                                            options={this.timeDropdownNew}
                                          />
                                          {this.state.timeSlot.fri.length > 1 &&
                                            i ===
                                              this.state.timeSlot.fri.length -
                                                1 && (
                                              <a
                                                href="javascript:void(0)"
                                                className="delete-this-field"
                                                onClick={(e) =>
                                                  this.removeMore(e, "fri")
                                                }
                                              >
                                                {" "}
                                                <img src={delete1} alt="" />
                                              </a>
                                            )}
                                        </div>
                                        {this.state.data.fri &&
                                        this.state.timeSlot.fri.length > 1 &&
                                        i - 1 ? (
                                          <span className="small">&#38;</span>
                                        ) : (
                                          ""
                                        )}{" "}
                                      </>
                                    )
                                  )}{" "}
                                </>
                              ) : (
                                "Closed"
                              )}
                              {this.state.data.fri &&
                                this.state.timeSlot.fri.length < 2 && (
                                  <a
                                    href="javascript:void(0)"
                                    className="add-more-field"
                                    onClick={(e) => this.addMore(e, "fri")}
                                  >
                                    + Add
                                  </a>
                                )}
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="schedule-list d-lg-flex flex-row align-items-center">
                        <div className="schedule-day">
                          <div className="form-check">
                            <input
                              name="sat"
                              onChange={this.DayChange}
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckSat"
                              checked={this.state.data.sat}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckSat"
                            >
                              Sat
                            </label>
                          </div>
                        </div>
                        <div className="schedule-time-outer d-xl-flex flex-row">
                          <div className="d-lg-flex flex-column flex-wrap">
                            <div className="schedule-time form-inline d-flex flex-column flex-md-row">
                              {this.state.data.sat ? (
                                <>
                                  {(this.state.timeSlot.sat || []).map(
                                    (el, i) => (
                                      <>
                                        <div className="form-group d-flex">
                                          <SelectOptions
                                            name="sat"
                                            attrind={i}
                                            attrnum="0"
                                            onChange={this.selectChangeOption}
                                            value={this.timeDropdownNew.find(
                                              (es) =>
                                                es.value ===
                                                this.state.timeSlot.sat[i][0].id
                                            )}
                                            nameCl="small"
                                            options={this.timeDropdownNew}
                                          />
                                          <span className="space">-</span>
                                          <SelectOptions
                                            name="sat"
                                            attrind={i}
                                            attrnum="1"
                                            onChange={this.selectChangeOption}
                                            value={this.timeDropdownNew.find(
                                              (es) =>
                                                es.value ===
                                                this.state.timeSlot.sat[i][1].id
                                            )}
                                            nameCl="small"
                                            options={this.timeDropdownNew}
                                          />
                                          {this.state.timeSlot.sat.length > 1 &&
                                            i ===
                                              this.state.timeSlot.sat.length -
                                                1 && (
                                              <a
                                                href="javascript:void(0)"
                                                className="delete-this-field"
                                                onClick={(e) =>
                                                  this.removeMore(e, "sat")
                                                }
                                              >
                                                {" "}
                                                <img src={delete1} alt="" />
                                              </a>
                                            )}
                                        </div>
                                        {this.state.data.sat &&
                                        this.state.timeSlot.sat.length > 1 &&
                                        i - 1 ? (
                                          <span className="small">&#38;</span>
                                        ) : (
                                          ""
                                        )}{" "}
                                      </>
                                    )
                                  )}{" "}
                                </>
                              ) : (
                                "Closed"
                              )}
                              {this.state.data.sat &&
                                this.state.timeSlot.sat.length < 2 && (
                                  <a
                                    href="javascript:void(0)"
                                    className="add-more-field"
                                    onClick={(e) => this.addMore(e, "sat")}
                                  >
                                    + Add
                                  </a>
                                )}
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="schedule-list d-lg-flex flex-row align-items-center">
                        <div className="schedule-day">
                          <div className="form-check">
                            <input
                              name="sun"
                              onChange={this.DayChange}
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckSun"
                              checked={this.state.data.sun}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckSun"
                            >
                              Sun
                            </label>
                          </div>
                        </div>
                        <div className="schedule-time-outer d-xl-flex flex-row">
                          <div className="d-lg-flex flex-column flex-wrap">
                            <div className="schedule-time form-inline d-flex flex-column flex-md-row">
                              {this.state.data.sun ? (
                                <>
                                  {(this.state.timeSlot.sun || []).map(
                                    (el, i) => (
                                      <>
                                        <div className="form-group d-flex">
                                          <SelectOptions
                                            name="sun"
                                            attrind={i}
                                            attrnum="0"
                                            onChange={this.selectChangeOption}
                                            value={this.timeDropdownNew.find(
                                              (es) =>
                                                es.value ===
                                                this.state.timeSlot.sun[i][0].id
                                            )}
                                            nameCl="small"
                                            options={this.timeDropdownNew}
                                          />
                                          <span className="space">-</span>
                                          <SelectOptions
                                            name="sun"
                                            attrind={i}
                                            attrnum="1"
                                            onChange={this.selectChangeOption}
                                            value={this.timeDropdownNew.find(
                                              (es) =>
                                                es.value ===
                                                this.state.timeSlot.sun[i][1].id
                                            )}
                                            nameCl="small"
                                            options={this.timeDropdownNew}
                                          />
                                          {this.state.timeSlot.sun.length > 1 &&
                                            i ===
                                              this.state.timeSlot.sun.length -
                                                1 && (
                                              <a
                                                href="javascript:void(0)"
                                                className="delete-this-field"
                                                onClick={(e) =>
                                                  this.removeMore(e, "sun")
                                                }
                                              >
                                                {" "}
                                                <img src={delete1} alt="" />
                                              </a>
                                            )}
                                        </div>
                                        {this.state.data.sun &&
                                        this.state.timeSlot.sun.length > 1 &&
                                        i - 1 ? (
                                          <span className="small">&#38;</span>
                                        ) : (
                                          ""
                                        )}{" "}
                                      </>
                                    )
                                  )}{" "}
                                </>
                              ) : (
                                "Closed"
                              )}
                              {this.state.data.sun &&
                                this.state.timeSlot.sun.length < 2 && (
                                  <a
                                    href="javascript:void(0)"
                                    className="add-more-field"
                                    onClick={(e) => this.addMore(e, "sun")}
                                  >
                                    + Add
                                  </a>
                                )}
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="form-group">
                    <SubmitBtn
                      onClickHandle={this.doSubmit}
                      label="Save Changes"
                      loading={this.state.loading}
                      btnClass="btn btn-lg btn-default"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  seller: getSeller(state).profile,
});
const mapDispatchToProps = (dispatch) => ({
  loadSellerProfile: () => dispatch(loadSellerProfile()),
  sellerUpdate: (data, callback) => dispatch(sellerUpdate(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
