import React, { Component, createRef } from "react";
import checkImg from "./../../include/images/tick-bubble.svg";
import imgLink from "./../../include/images/file_icon.svg";
import loadingIcon from "./../../include/images/loading-icon.svg";
import TopBar from "../common/topBar";
import { connect } from "react-redux";
import {
  getSeller,
  linkStripeAccount,
  loadSellerProfile,
} from "../../store/seller";
class LinkStripe extends Component {
  ref = createRef(null);
  state = {
    loading: false,
    settingDropdown: false,
    profileDropdown: false,
  };

  componentDidMount = () => {
    this.ref.current.continuousStart();
    this.props.loadSellerProfile();
  };
  callBack = (res) => {
    this.setState({ loading: false });
    this.ref.current.complete();
    if (res.status === 200) {
      window.location.href = res.data.data;
    }
  };

  linkAccount = (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    this.ref.current.continuousStart();
    this.props.linkStripeAccount(this.callBack);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.stripeAccountLoading &&
      this.props.stripeAccountLoading !== prevProps.stripeAccountLoading
    ) {
      this.ref.current.complete();
    }
  };

  handleSettingDropdown = () => {
    if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
      this.setState({ settingDropdown: !this.state.settingDropdown });
    } else {
      this.setState({ settingDropdown: !this.state.settingDropdown });
    }
  };

  handleProfileDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
      this.setState({ profileDropdown: !this.state.profileDropdown });
    } else {
      this.setState({ profileDropdown: !this.state.profileDropdown });
    }
  };

  setSettingDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
    } else if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
    }
  };

  render() {
    return (
      <>
        <TopBar
          handleProfileDropdown={this.handleProfileDropdown}
          handleSettingDropdown={this.handleSettingDropdown}
          settingDropdown={this.state.settingDropdown}
          profileDropdown={this.state.profileDropdown}
          loaDerRef={this.ref}
          sellerProfile={this.props.sellerProfile}
          linkAccount={this.linkAccount}
        />
        {!this.props.stripeAccountLoading && (
          <main id="main" onClick={this.setSettingDropdown}>
            <div className="stripe-account-container d-flex align-items-center justify-content-center">
              <div className="container container1">
                <div className="row">
                  <div className="col-md-6 offset-md-3">
                    <div className="sac-content-box text-center">
                      <div className="sac-img">
                        <img
                          src={
                            this.props.stripeAccountDetails.details_submitted
                              ? checkImg
                              : imgLink
                          }
                          alt=""
                        />
                      </div>
                      <h4>
                        {this.props.stripeAccountDetails.details_submitted
                          ? "You have already linked your stripe account."
                          : "No Stripe Account Linked"}
                      </h4>
                      <p>
                        {this.props.stripeAccountDetails.details_submitted &&
                          "Your associated email is " +
                            this.props.stripeAccountDetails.email}
                      </p>
                      <div className="seprator"></div>
                      <div className="sac-btn">
                        {" "}
                        {this.state.loading ? (
                          <a
                            href="javascript:void(0)"
                            onClick={this.linkAccount}
                            className="btn2 btn-default btn-block btn-loading"
                          >
                            <img src={loadingIcon} alt="" />
                          </a>
                        ) : (
                          <a
                            href="javascript:void(0)"
                            onClick={this.linkAccount}
                            className="btn2 btn-outline btn-block"
                          >
                            {this.props.stripeAccountDetails.details_submitted
                              ? "Login to your stripe account "
                              : "Link your stripe account"}
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  stripeAccountDetails: getSeller(state).stripeAccountDetails,
  stripeAccountLoading: getSeller(state).stripeAccountLoading,
});

const mapDispatchToProps = (dispatch) => ({
  loadSellerProfile: () => dispatch(loadSellerProfile()),
  linkStripeAccount: (callback) => dispatch(linkStripeAccount(callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LinkStripe);
