import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import {
  addCouponToSeller,
  addNotesToSeller,
  getCart,
  loadCart,
  removeCouponFromSeller,
  updateDeliveryForProduct,
  updateProductQuantity,
} from "../../store/cart";
import Header from "../product/header";
import empty_icon from "./../../include/images/empty_icon.svg";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-toastify";
import AlertSuccess from "../common/alertSuccess";
import AlertError from "../common/alertError";
import { Link } from "react-router-dom";
import CartSeller from "./cartSeller";
import { loadCustomerProfile } from "../../store/customer";
import { getCustomerToken } from "../services/localStorageServices";
import Copyright from "../common/copyright";

class Cart extends Component {
  ref = createRef(null);
  state = {
    action: null,
    selectedProductId: "",
    selectedDeliveryProductId: "",
    selectedSellerId: "",
    param: JSON.parse(localStorage.getItem("customerDddress")),
  };

  componentDidMount = () => {
    if (getCustomerToken("auth")) this.props.loadCustomerProfile();
    window.scrollTo(0, 0);
    // localStorage.setItem("redirectUrl", this.props.location.pathname);
  };
  setSelectedSellerId = (e, selectedSellerId) => {
    e.stopPropagation();
    this.setState({
      selectedSellerId,
    });
  };

  setSelectedProductId = (e, selectedProductId) => {
    e.stopPropagation();
    this.setState({
      selectedProductId: selectedProductId,
      selectedDeliveryProductId: "",
    });
  };

  setSelectedDeliveryProductId = (e, selectedDeliveryProductId) => {
    e.stopPropagation();
    this.setState({ selectedDeliveryProductId });
  };

  removeCouponFromSeller = (e, seller_id) => {
    this.ref.current.continuousStart();
    this.setState({ loader: true });
    e.preventDefault();
    const payload = {
      user_device_id: localStorage.getItem("user_device_id"),
      seller_id,
    };
    this.props.removeCouponFromSeller(payload, this.callBack);
  };

  addCouponToSeller = (e, coupon, seller_id) => {
    this.ref.current.continuousStart();
    this.setState({ loader: true });
    e.preventDefault();
    const payload = {
      user_device_id: localStorage.getItem("user_device_id"),
      seller_id,
      coupon,
    };
    this.props.addCouponToSeller(payload, this.callBack);
  };

  addNotesToSeller = (e, note, seller_id) => {
    this.ref.current.continuousStart();
    this.setState({ loader: true });
    e.preventDefault();
    const payload = {
      user_device_id: localStorage.getItem("user_device_id"),
      seller_id,
      note,
    };
    this.props.addNotesToSeller(payload, this.callBack);
  };

  updateProductQuantity = (e, product_id, quantity) => {
    if (quantity == 0) {
      this.setState({ action: "Deleted" });
    } else {
      this.setState({ action: null });
    }
    this.ref.current.continuousStart();
    this.setState({ loader: true });
    e.preventDefault();
    const payload = {
      user_device_id: localStorage.getItem("user_device_id"),
      product_id: product_id,
      quantity,
      address: this.state.param && this.state.param.address,
      zipCode: this.state.param && this.state.param.postal_code.long_name,
      lat: this.state.param && this.state.param.lat,
      lon: this.state.param && this.state.param.lng,
    };

    this.props.updateProductQuantity(payload, this.callBack);
  };

  updateDeliveryForProduct = (
    e,
    product_id,
    delivery_type,
    delivery_option = "in_person"
  ) => {
    this.ref.current.continuousStart();
    this.setState({ loader: true });
    e.preventDefault();
    const payload = {
      user_device_id: localStorage.getItem("user_device_id"),
      product_id,
      delivery_type,
      delivery_option,
      address: this.state.param && this.state.param.address,
      zipCode: this.state.param && this.state.param.postal_code.long_name,
      lat: this.state.param && this.state.param.lat,
      lon: this.state.param && this.state.param.lng,
    };

    this.props.updateDeliveryForProduct(payload, this.callBack);
  };

  callBack = (res) => {
    this.ref.current.complete();
    this.setState({ loader: false });
    this.props.loadCart(localStorage.getItem("user_device_id"), {
      address: this.state.param && this.state.param.address,
      zipCode: this.state.param && this.state.param.postal_code.long_name,
      lat: this.state.param && this.state.param.lat,
      lon: this.state.param && this.state.param.lng,
    });
    if (res.status === 200) {
      if (this.state.action === "Deleted") {
        // toast(
        //   <AlertSuccess message={"Item has been removed from your cart."} />
        // );
      } else {
        //toast(<AlertSuccess message={"You cart has been updated."} />);
      }
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };
  render() {
    console.log(
      "this.props.getCart.cartDetail.orderSummary",
      this.props.getCart.cartDetail.orderSummary
    );
    return (
      <>
        <LoadingBar color="#0b4474" ref={this.ref} />
        <Header cart={"cart"} toggleContact={this.toggleContact} />
        <main
          id="main"
          onClick={(e) =>
            this.setState({
              selectedProductId: "",
              selectedDeliveryProductId: "",
            })
          }
        >
          {this.props.getCart.cartDetail.sellers &&
          this.props.getCart.cartDetail.sellers.length > 0 ? (
            <div className="content-container mob-no-border mob-pt-0">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="cc-box d-lg-flex d-lg-wrap">
                      <div className="cc-left-content">
                        <div className="table-responsive table_wrap">
                          <table className="table2 table-wrap">
                            <thead>
                              <tr>
                                <th>Products</th>
                                <th></th>
                                <th
                                  style={{ width: "180px", textAlign: "right" }}
                                >
                                  Quantity
                                </th>
                                <th
                                  style={{ width: "125px", textAlign: "right" }}
                                >
                                  Unit Price
                                </th>
                                <th style={{ width: "30px" }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.getCart.cartDetail.sellers &&
                                this.props.getCart.cartDetail.sellers.map(
                                  (es, i) => (
                                    <CartSeller
                                      seller_no={i}
                                      removeCouponFromSeller={
                                        this.removeCouponFromSeller
                                      }
                                      addNotesToSeller={this.addNotesToSeller}
                                      setSelectedSellerId={
                                        this.setSelectedSellerId
                                      }
                                      selectedSellerId={
                                        this.state.selectedSellerId
                                      }
                                      addCouponToSeller={this.addCouponToSeller}
                                      es={es}
                                      setSelectedProductId={
                                        this.setSelectedProductId
                                      }
                                      selectedProductId={
                                        this.state.selectedProductId
                                      }
                                      updateProductQuantity={
                                        this.updateProductQuantity
                                      }
                                      updateDeliveryForProduct={
                                        this.updateDeliveryForProduct
                                      }
                                      setSelectedDeliveryProductId={
                                        this.setSelectedDeliveryProductId
                                      }
                                      selectedDeliveryProductId={
                                        this.state.selectedDeliveryProductId
                                      }
                                      history={this.props.history}
                                    />
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {this.props.getCart.cartDetail.orderSummary.subtotal && (
                        <div className="cc-right-content">
                          <h3>Order Summary</h3>
                          <div className="table-box">
                            <table className="table-responsive2">
                              <tbody>
                                <tr>
                                  <td>Order Total</td>
                                  <td align="right">
                                    $
                                    {this.props.getCart.cartDetail.orderSummary.subtotal.toLocaleString(
                                      undefined,
                                      { minimumFractionDigits: 2 }
                                    )}
                                  </td>
                                </tr>
                                {!this.props.getCart.cartDetail.orderSummary
                                  .discount_total ? (
                                  <tr>
                                    <td>Discount</td>
                                    <td align="right">$0</td>
                                  </tr>
                                ) : (
                                  <tr>
                                    <td>Discount</td>
                                    <td align="right">
                                      -$
                                      {
                                        this.props.getCart.cartDetail
                                          .orderSummary.discount_total
                                      }
                                    </td>
                                  </tr>
                                )}
                                {this.props.getCart.cartDetail.orderSummary
                                  .final_total_before_tax && (
                                  <tr>
                                    <td>Total after discount</td>
                                    <td align="right">
                                      $
                                      {this.props.getCart.cartDetail.orderSummary.final_total_before_tax.toLocaleString(
                                        undefined,
                                        { minimumFractionDigits: 2 }
                                      )}
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td>Tax Total</td>
                                  <td align="right">
                                    +$
                                    {this.props.getCart.cartDetail.orderSummary.tax_total.toLocaleString(
                                      undefined,
                                      { minimumFractionDigits: 2 }
                                    )}
                                  </td>
                                </tr>

                                {this.props.getCart.cartDetail.orderSummary
                                  .total_delivery_charge !== 0 && (
                                  <tr>
                                    <td>Delivery Charge</td>
                                    <td align="right">
                                      +$
                                      {this.props.getCart.cartDetail.orderSummary.total_delivery_charge.toLocaleString(
                                        undefined,
                                        { minimumFractionDigits: 2 }
                                      )}
                                    </td>
                                  </tr>
                                )}

                                {this.props.getCart.cartDetail.orderSummary
                                  .total_shipping_charge !== 0 && (
                                  <tr>
                                    <td>Shipping Charge</td>
                                    <td align="right">
                                      +$
                                      {this.props.getCart.cartDetail.orderSummary.total_shipping_charge.toLocaleString(
                                        undefined,
                                        { minimumFractionDigits: 2 }
                                      )}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td>Final Amount</td>
                                  <td align="right">
                                    $
                                    {this.props.getCart.cartDetail.orderSummary.final_total.toLocaleString(
                                      undefined,
                                      { minimumFractionDigits: 2 }
                                    )}
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="cart-container d-flex align-items-center">
              <div className="empty-cart-content">
                <div className="ec-img-box">
                  <img src={empty_icon} alt="" />
                </div>
                <h4>Your cart is empty </h4>

                <Link to="/products-listing" className="btn btn-default">
                  Continue Shopping
                </Link>
              </div>
            </div>
          )}

          <footer className="footer" id="footer">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="footer-content text-center">
                    <Copyright />
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </main>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  getCart: getCart(state),
});
const mapDispatchToProps = (dispatch) => ({
  removeCouponFromSeller: (data, callback) =>
    dispatch(removeCouponFromSeller(data, callback)),
  addNotesToSeller: (data, callback) =>
    dispatch(addNotesToSeller(data, callback)),
  addCouponToSeller: (data, callback) =>
    dispatch(addCouponToSeller(data, callback)),
  updateDeliveryForProduct: (data, callback) =>
    dispatch(updateDeliveryForProduct(data, callback)),
  updateProductQuantity: (data, callback) =>
    dispatch(updateProductQuantity(data, callback)),
  loadCart: (id, data) => dispatch(loadCart(id, data)),
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
