import React, { Component } from "react";
import close from "../../include/images/close.svg";
import StarRatings from "react-star-ratings";

class SellerReviews extends Component {
  render() {
    return (
      <>
        {this.props.isSellerReviews && (
          <>
            <div className="tab-modify-slidebar large-modify-sidebar">
              <div className="tab-modify-box">
                <div className="tab-header d-flex justify-content-between">
                  <h3>Reviews</h3>
                  <a
                    href="javascript:void(0)"
                    className="close"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.handleClose();
                    }}
                  >
                    <img src={close} alt="" />
                  </a>
                </div>
                <div className="tab-body">
                  {this.props.detail.seller &&
                    this.props.detail.seller.reviews.map((r, i) => (
                      <div className="review-box" key={i}>
                        <div className="star-list">
                          <StarRatings
                            rating={r.rating}
                            starDimension="15px"
                            starSpacing="3px"
                            isSelectable="false"
                            starEmptyColor="#C4C4C4"
                            starRatedColor="#ffa51e"
                            starHoverColor="#ffa51e"
                          />
                          {/* <span className="reviews-text custom-rating">
                            {r.rating}{" "}
                          </span> */}
                        </div>
                        {r.customer_first_name && r.customer_last_name && (
                          <p>
                            By{" "}
                            {r.customer_first_name + " " + r.customer_last_name}
                          </p>
                        )}
                        <h6>{r.subject && r.subject}</h6>
                        <p>{r.content && r.content}</p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default SellerReviews;
