import React from "react";
import Footer from "../common/footer";
import TopBar from "../common/topBar";
import { Map, Marker, GoogleApiWrapper, Polygon } from "google-maps-react";

import { getSeller, sellerUpdate } from "../../store/seller";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import { Component } from "react";
import { failed, informationSaved } from "../common/misc";

const initialState = {
  triangleCoords: [
    // { lat: 37.77967385186388, lng: -122.45155758117771 },
    // { lat: 37.77951273594679, lng: -122.39739707564449 },
    // { lat: 37.772231148930445, lng: -122.40011214171982 },
    // { lat: 37.772200406641765, lng: -122.44229121958351 },
  ],
};

class MapDraw extends Component {
  state = initialState;
  onMapClicked = (e1, e2, e3) => {
    //{lat: 25.774, lng: -80.190}
    const x = { lat: e3.latLng.lat(), lng: e3.latLng.lng() };
    const { triangleCoords } = this.state;
    triangleCoords.push(x);
    this.setState({ triangleCoords });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.seller.profileLoading &&
      this.props.seller.profileLoading !== prevProps.seller.profileLoading &&
      this.props.seller.profile.polygonLocation &&
      this.props.seller.profile.polygonLocation.coordinates &&
      this.props.seller.profile.polygonLocation.coordinates.length > 0
    ) {
      const dataC =
        this.props.seller.profile.polygonLocation.coordinates[0].map((ed) => ({
          lat: ed[1],
          lng: ed[0],
        }));
      this.setState({ triangleCoords: dataC });
    }
  };

  doSubmit = () => {
    const { triangleCoords } = this.state;
    const dataC = triangleCoords.map((e) => [e.lng, e.lat]);
    const formData = {
      polygonLocation: {
        type: "Polygon",
        coordinates: dataC,
      },
    };
    this.props.sellerUpdate(formData, this.callback);
  };
  callback = (res) => {
    if (res && res.status === 200) {
      toast(<AlertSuccess message={informationSaved} />);
    } else {
      toast(<AlertError message={failed} />);
    }
  };
  handleProfileDropdown = () => {
    if (this.state.settingDropdown === true) {
      this.setState({ settingDropdown: false });
      this.setState({ profileDropdown: !this.state.profileDropdown });
    } else {
      this.setState({ profileDropdown: !this.state.profileDropdown });
    }
  };
  handleSettingDropdown = () => {
    if (this.state.profileDropdown === true) {
      this.setState({ profileDropdown: false });
      this.setState({ settingDropdown: !this.state.settingDropdown });
    } else {
      this.setState({ settingDropdown: !this.state.settingDropdown });
    }
  };
  render() {
    return (
      <>
        <div className="dashboard-container custom-top">
          <TopBar
            sellerProfile={this.props.seller.profile}
            loaDerRef={this.ref}
            handleProfileDropdown={this.handleProfileDropdown}
            handleSettingDropdown={this.handleSettingDropdown}
            settingDropdown={this.state.settingDropdown}
            profileDropdown={this.state.profileDropdown}
          />
          <div className="form-container">
            <div className="container">
              <div className="row">
                <div className="col-md-4 ">
                  <button
                    className="btn btn-lg btn-default"
                    onClick={() => this.doSubmit()}
                  >
                    Save
                  </button>{" "}
                  <button
                    className="btn btn-cancelled "
                    onClick={() => this.setState({ triangleCoords: [] })}
                  >
                    Clear
                  </button>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-12">
                  <div style={{ height: "60vh", width: "100%" }}>
                    <Map
                      onClick={this.onMapClicked}
                      google={this.props.google}
                      zoom={14}
                      initialCenter={
                        this.props.seller.profile.location && {
                          lng: this.props.seller.profile.location
                            .coordinates[0],
                          lat: this.props.seller.profile.location
                            .coordinates[1],
                        }
                      }
                      center={
                        this.props.seller.profile.location && {
                          lng: this.props.seller.profile.location
                            .coordinates[0],
                          lat: this.props.seller.profile.location
                            .coordinates[1],
                        }
                      }
                    >
                      <Marker
                        onClick={this.onMarkerClick}
                        name={"Current location"}
                        position={
                          this.props.seller.profile.location && {
                            lng: this.props.seller.profile.location
                              .coordinates[0],
                            lat: this.props.seller.profile.location
                              .coordinates[1],
                          }
                        }
                      />
                      <Polygon
                        key={this.state.triangleCoords}
                        paths={this.state.triangleCoords}
                        strokeColor="#297ab9"
                        strokeOpacity={0.8}
                        strokeWeight={2}
                        fillColor="#297ab9"
                        fillOpacity={0.35}
                      />

                      {/* <InfoWindow onClose={this.onInfoWindowClose}>
                      <div>
                        <h1>{this.state.selectedPlace.name}</h1>
                      </div>
                    </InfoWindow> */}
                    </Map>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  sellerUpdate: (data, callback) => dispatch(sellerUpdate(data, callback)),
});
const mapStateToProps = (state) => ({
  seller: getSeller(state),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  GoogleApiWrapper({
    apiKey: "AIzaSyDHZd1NTh7pJw6-zGe1Mf_cUBx2cIhz46Y",
  })(MapDraw)
);

// const mapDispatchToProps = (dispatch) => ({
//   sellerUpdate: (data, callback) => dispatch(sellerUpdate(data, callback)),
// });
// export default connect(null, mapDispatchToProps)(MapDraw);
