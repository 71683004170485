import React, { Component, createRef } from "react";

import {
  loadWishlist,
  getCustomer,
  deleteWishlist,
  loadCustomerProfile,
} from "../../../store/customer";

import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import CustomerSidebar from "../../common/customer/customerSidebar";
import Header from "../../productV2/header";
import Footer from "../../common/footer";

import shoppingBag from "../../../include/images/shopping-bag.svg";
import deleteIcon from "../../../include/images/delete1.svg";

import { failed } from "../../common/misc";
import { toast } from "react-toastify";
import AlertSuccess from "../../common/alertSuccess";
import AlertError from "../../common/alertError";

import AddToCart from "../../product/addToCart";
import LoadingBar from "react-top-loading-bar";

class Wishlist extends Component {
  ref = createRef(null);

  state = {
    productId: "",
  };

  componentDidMount = () => {
    this.props.loadWishlist();
    this.props.loadCustomerProfile();
    this.ref.current.continuousStart();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.complete();
    }
  };

  setProductId = (productId) =>
    this.setState({
      productId: productId === this.state.productId ? "" : productId,
    });

  handleDelete = (id) => {
    const params = {
      product: id,
    };
    this.props.deleteWishlist(params, this.callback);
  };

  callback = (res) => {
    if (res && res.status === 200) {
      toast(<AlertSuccess message={"Product removed from wishlist"} />);
      this.props.loadWishlist();
    } else {
      toast(<AlertError message={failed} />);
    }
  };
  handleProductClick = (id) => {
    this.props.history.push("/product-detail/" + id);
  };

  render() {
    return (
      <>
        <LoadingBar color="#0b4474" ref={this.ref} />
        <Header />
        <main
          id="main"
          onClick={(e) => {
            this.setProductId("");
          }}
        >
          <div className="content-container">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="conetnt-box d-md-flex flex-md-wrap">
                    <CustomerSidebar />
                    {!this.props.wishlistLoading &&
                    this.props.wishlist &&
                    this.props.wishlist.length === 0 ? (
                      <>
                        <div className="main-content-box d-flex align-items-center justify-content-center">
                          <div className="empty-order-box text-center">
                            <div className="eob-icon">
                              <img src={shoppingBag} alt="" />
                            </div>
                            <h4>Your Wishlist is Empty</h4>
                            {/* <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua.
                            </p> */}
                            <div className="seprator"></div>
                            <div className="eob-btn">
                              <a
                                href="/products-listing"
                                className="btn btn-default"
                              >
                                Continue Shopping
                              </a>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      !this.props.wishlistLoading && (
                        <div className="main-content-box">
                          <h3>My Wishlist</h3>
                          <div className="table-responsive expand-table">
                            <table className="table small mb-0 no-total-td ">
                              <thead>
                                <tr>
                                  <th>Products</th>
                                  <th></th>
                                  <th
                                    style={{
                                      width: "80px",
                                      textAlign: "right",
                                    }}
                                  >
                                    Price
                                  </th>
                                  <th style={{ width: "190px" }}></th>
                                  <th style={{ width: "70px" }}></th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.wishlist &&
                                  this.props.wishlist.map((e, i) => (
                                    <>
                                      <tr key={i}>
                                        {/* <Link to={"/product-detail/" + e._id}> */}
                                        <td
                                          onClick={() => {
                                            this.handleProductClick(e._id);
                                          }}
                                          className="td-image text-center"
                                        >
                                          {e.image &&
                                            e.image.map((img, k) => (
                                              <span
                                                className="wishlist-product-image"
                                                key={k}
                                              >
                                                <img
                                                  src={
                                                    img &&
                                                    process.env
                                                      .REACT_APP_S3URL +
                                                      img.small
                                                  }
                                                  alt=""
                                                />
                                              </span>
                                            ))}
                                        </td>
                                        <td
                                          onClick={() => {
                                            this.handleProductClick(e._id);
                                          }}
                                        >
                                          <b>{e.name && e.name}</b>
                                        </td>
                                        {/* </Link> */}
                                        <td style={{ textAlign: "right" }}>
                                          <b>
                                            $
                                            {e.price &&
                                              e.price.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                }
                                              )}
                                          </b>
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                          <div className="add-to-cart-dropdown dropdown"></div>
                                        </td>
                                        <td className="editable">
                                          <a
                                            href="#"
                                            onClick={() => {
                                              this.handleDelete(e._id);
                                            }}
                                            className="delete"
                                          >
                                            <img
                                              src={deleteIcon}
                                              alt="delete"
                                            />
                                          </a>
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  wishlist: getCustomer(state).wishlist.data,
  customerProfile: getCustomer(state).profile,
  loading: getCustomer(state).profileLoading,
  wishlistLoading: getCustomer(state).wishlistLoading,
});

const mapDispatchToProps = (dispatch) => ({
  loadWishlist: (params) => dispatch(loadWishlist(params)),
  deleteWishlist: (data, callback) => dispatch(deleteWishlist(data, callback)),
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Wishlist)
);
