import React, { createRef } from "react";
import Joi from "joi-browser";
import Form from "../common/form/form";
import SubmitBtn from "../common/form/submitBtn";
import delete1 from "../../include/images/delete1.svg";
import close from "../../include/images/close.svg";
import FileInput from "../common/form/fileInput";
import StarRatings from "react-star-ratings";
import { Editor } from "@tinymce/tinymce-react";

import {
  getProduct,
  loadAllProduct,
  addProduct,
  updateProduct,
  loadCategory,
  uploadProductPictures,
} from "../../store/product";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import {
  informationSaved,
  failed,
  productName,
  description,
  quantity,
  price,
  picsRequired,
} from "../common/misc";
import SelectOptions from "../common/selectOptions";
import { getSeller, loadSellerProfile } from "../../store/seller";
import VideoInput from "../common/form/videoInput";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";

const initialState = {
  data: {
    name: "",
    description: "",
    quantity: "",
    product_id: "",
    price: "",
    pics: [],
    video: [],
    category: {},
    sub_category: {},
    second_sub_category: {},
  },
  progress: 0,
  photoStatus: "",
  videoUploading: "",
  errors: {},
  sub_categories: [],
  sub_categories2: [],
};
class AddProduct extends Form {
  state = initialState;

  componentDidMount = () => {
    this.props.loadCategory();
    this.props.loadSellerProfile();
  };

  schema = {
    name: Joi.string()
      .required()
      .label("Product Name")
      .error(() => {
        return { message: productName };
      }),
    sub_category: Joi.object()
      .required()
      .label("Category")
      .error(() => {
        return { message: "Category field is required" };
      }),
    second_sub_category: Joi.object()
      .label("Category")
      .error(() => {
        return { message: "Category field is required" };
      }),
    category: Joi.object()
      .keys({
        value: Joi.string().required(),
        label: Joi.string().required(),
      })
      .required()
      .label("Category")
      .error(() => {
        return { message: "Category field is required" };
      }),
    description: Joi.string()
      .required()
      .label("Description")
      .error(() => {
        return { message: description };
      }),
    quantity: Joi.number()
      .required()
      .label("Quantity")
      .error(() => {
        return { message: quantity };
      }),
    price: Joi.number()
      .required()
      .label("Price (In $)")
      .error(() => {
        return { message: price };
      }),

    product_id: Joi.any(),
    pics: Joi.array()
      .items(Joi.object().required())
      .required()
      .error(() => {
        return { message: picsRequired };
      }),
    video: Joi.array(),
  };
  remoVeImg = (e, i) => {
    e.preventDefault();
    const data = this.state.data;
    const pics = data.pics;
    data.pics = pics.filter((p) => p !== i);
    this.setState({ data });
  };

  remoVeVideo = (e, i) => {
    e.preventDefault();
    const data = this.state.data;
    const video = data.video;
    data.video = video.filter((p) => p !== i);
    this.setState({ data });
  };

  fileUploadVideo = (e) => {
    this.setState({ videoUploading: "Please wait..." });
    const files = e.target.files;
    console.log("filesfiles", files);
    const size = files[0].size / 1024 / 1024;
    if (size > process.env.REACT_APP_VIDEOUPLOADMAX) {
      //this.ref.current.complete();
      this.setState({
        videoUploading: "",
        progress: 100,
      });
      return toast(
        <AlertError
          message={`Video file should be lesser than ${process.env.REACT_APP_VIDEOUPLOADMAX} MB.`}
        />
      );
    }
    for (var i = 0; i < files.length; i++) {
      const element = files[i];
      let formData = new FormData();
      formData.append("video", element);
      const config = {
        headers: {
          "auth-token": process.env.REACT_APP_TOKEN,
          "x-auth-token": localStorage.getItem("x-auth-token-seller"),
        },
        onUploadProgress: (progressEvent) => {
          let { progress } = this.state;
          progress = (progressEvent.loaded / progressEvent.total) * 100;
          this.setState({ progress });
          console.log("progress", progress);
        },
      };
      axios
        .post(
          process.env.REACT_APP_APIBASE + "product/uploadVideo",
          formData,
          config
        )
        .then((res) => {
          if (res.status == 200) {
            console.log("done: ", res.data.url);
            const data = { ...this.state.data };
            console.log("done:---> ", data.video);
            data.video = [res.data.url];

            this.setState({ data, videoUploading: "" });
          }
        })
        .catch((err) => {
          this.setState({ progress: 100 });
          console.log("error: ", err);
          toast(<AlertError message={"Upload failed."} />);
          this.setState({ videoUploading: "" });
        });
      //this.props.uploadProductPictures(formData, this.imageCallback);
    }
  };

  fileUpload = (e) => {
    this.setState({ photoStatus: "Please wait..." });
    const files = e.target.files;
    for (var i = 0; i < files.length; i++) {
      const element = files[i];
      let formData = new FormData();
      formData.append("image", element);
      this.props.uploadProductPictures(formData, this.imageCallback);
    }
  };

  imageCallback = (res) => {
    console.log(this.props.productPhoto, res);
    if (res && res.status === 200) {
      const { data, errors } = this.state;
      errors.pics = "";
      data.pics.push(this.props.productPhoto.urls);
      this.setState({ data, errors, photoStatus: "" });
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  toggleClose = () => {
    this.setState(initialState);
    this.props.toggleEdit();
  };
  doSubmit = () => {
    const {
      name,
      description,
      quantity,
      price,
      product_id,
      pics,
      video,
      category,
      sub_category,
      second_sub_category,
    } = this.state.data;

    if (!category) {
      const { errors } = this.state;
      errors.category = "Category field is required";
      return this.setState({ errors });
    }
    if (!description) {
      const { errors } = this.state;
      errors.description = "Description field is required";
      return this.setState({ errors });
    }
    var formdata;
    if (product_id) {
      formdata = {
        name: name,
        description: description,
        quantity: quantity,
        price: price,
        videos: video,
        product_id: product_id,
        image: pics,
        category,
      };
    } else {
      formdata = {
        category,
        name: name,
        description: description,
        quantity: quantity,
        price: price,
        image: pics,
        videos: video,
      };
    }
    if (sub_category && sub_category.value)
      formdata.sub_category = sub_category;
    if (second_sub_category && second_sub_category.value)
      formdata.second_sub_category = second_sub_category;
    if (this.props.data) {
      const id = this.props.data._id;
      this.props.updateProduct(id, formdata, this.editCallback);
    } else {
      this.props.addProduct(formdata, this.callback);
    }
  };
  editCallback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllProduct({ page: this.props.page });
      this.setState(initialState);
      this.props.toggleEdit();
      toast(<AlertSuccess message={informationSaved} />);
    } else {
      this.props.toggleEdit();
      this.setState(initialState);
      toast(<AlertError message={failed} />);
    }
  };
  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllProduct({ page: this.props.page });
      this.setState({
        data: {
          name: "",
          description: "",
          quantity: "",
          product_id: "",
          price: "",
          pics: [],
          category: {},
        },
      });
      this.props.toggleAdd();
      toast(<AlertSuccess message={informationSaved} />);
    } else {
      this.setState({
        data: {
          name: "",
          description: "",
          quantity: "",
          product_id: "",
          price: "",
          pics: [],
          category: {},
        },
      });
      this.props.toggleAdd();
      toast(<AlertError message={failed} />);
    }
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.data && this.props.data !== prevProps.data) {
      const {
        name,
        description,
        quantity,
        price,
        product_id,
        image,
        category,
        sub_category,
        second_sub_category,
        videos,
      } = this.props.data;

      const sub_categoriesData = category
        ? this.props.category.find((ec) => ec._id === category.value)
        : {};

      const sub_categories2Data = sub_category
        ? sub_categoriesData.sub_categories.find(
            (esc) => esc._id === sub_category.value
          )
        : {};

      // console.log(
      //   "sub_categoriessub_categories",
      //   sub_categories2Data.sub_categories,
      //   sub_categories2Data,
      //   this.props.data,
      //   sub_category,
      //   sub_categoriesData.sub_categories
      // );
      //sub_categories2: [],

      const data = {
        name: name,
        description: description,
        quantity: quantity,
        price: price,
        product_id: product_id,
        pics: image.map((e) => e),
        category,
        video: videos,
        sub_category: sub_category ? sub_category : {},
        second_sub_category: second_sub_category ? second_sub_category : {},
      };
      this.setState({
        data,
        sub_categories: sub_categoriesData
          ? sub_categoriesData.sub_categories
          : [],
        sub_categories2:
          sub_categories2Data && sub_categories2Data.sub_categories
            ? sub_categories2Data.sub_categories
            : [],
      });
    }
  };
  ref = createRef(null);
  handleEditorChange = (content) => {
    const data = { ...this.state.data };
    data.description = content;
    this.setState({ data });
  };
  render() {
    return (
      <>
        <LoadingBar
          height={4}
          color="#0b4474"
          progress={this.state.progress}
          onLoaderFinished={() => this.setState({ progress: 0 })}
        />
        {this.props.isReviews === true ? (
          <div
            className="tab-modify-slidebar large-modify-sidebar"
            style={{ zIndex: "999" }}
          >
            <div className="tab-modify-box">
              <div className="tab-header d-flex justify-content-between">
                <h3>Customer Reviews</h3>
                <a
                  href="javascript:void(0)"
                  className="close"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.handleClose();
                  }}
                >
                  <img src={close} alt="" />
                </a>
              </div>
              <div className="tab-body">
                {this.props.details.reviews &&
                  this.props.details.reviews.map((r, i) => (
                    <div className="review-box" key={i}>
                      <div className="star-list">
                        <StarRatings
                          rating={r.rating}
                          starDimension="15px"
                          starSpacing="3px"
                          isSelectable="false"
                          starEmptyColor="#C4C4C4"
                          starRatedColor="#ffa51e"
                          starHoverColor="#ffa51e"
                        />
                        {/* <span className="reviews-text ">{r.rating} </span> */}
                      </div>
                      {r.customer_first_name && r.customer_last_name && (
                        <p>
                          By{" "}
                          {r.customer_first_name + " " + r.customer_last_name}
                        </p>
                      )}
                      <h6>{r.subject && r.subject}</h6>
                      <p>{r.content && r.content}</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="tab-modify-slidebar">
            <div className="tab-modify-box">
              <div className="tab-header d-flex justify-content-between">
                {this.props.isShow === true && <h3>Add Product</h3>}
                {/* //{JSON.stringify(this.props.data)} */}
                {this.props.editShow === true && <h3>Modify Product</h3>}
                {this.props.isShow === true ? (
                  <a
                    href="javascript:void(0)"
                    className="close"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.toggleAdd();
                    }}
                  >
                    <img src={close} alt="" />
                  </a>
                ) : (
                  <a
                    href="javascript:void(0)"
                    className="close"
                    onClick={(e) => {
                      e.preventDefault();
                      this.toggleClose();
                    }}
                  >
                    <img src={close} alt="" />
                  </a>
                )}
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  {this.renderInput("name", "Product Name")}
                </div>
                <div className="form-group">
                  {/* {this.renderText("description", "Description")} */}
                  <label>Description</label>
                  <Editor
                    apiKey={process.env.REACT_APP_TINYMCE_KEY}
                    value={this.state.data.description}
                    init={{
                      branding: false,
                      height: 300,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount image",
                      ],

                      toolbar:
                        "undo redo | formatselect | bold italic backcolor | \
														                    alignleft aligncenter alignright alignjustify | \
														                    bullist numlist outdent indent | removeformat | help | image",
                    }}
                    onEditorChange={this.handleEditorChange}
                  />
                  {this.state.errors.description && (
                    <div className="fv-plugins-message-container">
                      <div
                        data-validator="notEmpty"
                        className="fv-help-block"
                        style={{
                          fontSize: "12px",
                          lineHeight: "16px",
                          fontWeight: "400",
                          letterSpacing: "0.4px",
                          color: "#ff0c3e",
                          marginBottom: "0",
                        }}
                      >
                        {this.state.errors.description}
                      </div>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  {this.renderInput("product_id", "Product ID (optional)")}
                </div>
                {this.props.sellerProfile.category ? (
                  <div className="form-group">
                    <label> Category Level 1</label>

                    <SelectOptions
                      width={"auto"}
                      value={this.state.data.category}
                      onChange={(e) => {
                        const findSubCat = this.props.category.find(
                          (sub) => sub._id === e.value
                        );
                        const sub_categories = findSubCat.sub_categories;
                        const { data, errors } = this.state;
                        data.category = { value: e.value, label: e.label };
                        data.sub_category = {};
                        if (!e) errors.category = "Category field is required";
                        this.setState({ data, errors, sub_categories });
                      }}
                      options={this.props.category.map((e) => ({
                        value: e._id,
                        label: e.title,
                      }))}
                    />
                    {this.state.errors.category && (
                      <label
                        className="error"
                        style={{
                          fontSize: "12px",
                          lineHeight: "16px",
                          fontWeight: "400",
                          letterSpacing: "0.4px",
                          color: "#ff0c3e",
                          marginBottom: "0",
                        }}
                      >
                        {this.state.errors.category}
                      </label>
                    )}
                  </div>
                ) : (
                  ""
                )}

                {this.state.sub_categories &&
                  this.state.sub_categories.length > 0 && (
                    <div className="form-group">
                      <label> Category Level 2</label>

                      <SelectOptions
                        width={"auto"}
                        value={this.state.data.sub_category}
                        onChange={(e) => {
                          const findSubCat = this.state.sub_categories.find(
                            (sub) => sub._id === e.value
                          );
                          const sub_categories2 = findSubCat.sub_categories;
                          const { data, errors } = this.state;
                          data.sub_category = {
                            value: e.value,
                            label: e.label,
                          };

                          this.setState({ data, sub_categories2 });
                        }}
                        options={this.state.sub_categories.map((e) => ({
                          value: e._id,
                          label: e.title,
                        }))}
                      />
                    </div>
                  )}
                {this.state.sub_categories2.length > 0 && (
                  <div className="form-group">
                    <label> Category Level 3 </label>

                    <SelectOptions
                      width={"auto"}
                      value={this.state.data.second_sub_category}
                      onChange={(e) => {
                        const { data, errors } = this.state;
                        data.second_sub_category = {
                          value: e.value,
                          label: e.label,
                        };
                        this.setState({ data });
                      }}
                      options={this.state.sub_categories2.map((e) => ({
                        value: e._id,
                        label: e.title,
                      }))}
                    />
                  </div>
                )}
                <div className="form-group d-flex">
                  <div className="row">
                    <div className="col-md-6">
                      {this.renderNumber("quantity", "QTY")}
                    </div>

                    <div className="col-md-6">
                      {this.renderNumber("price", "Price (In $)")}
                    </div>
                  </div>
                </div>
                <div className="form-group custom-upload">
                  <FileInput
                    totalUploaded={this.state.data.pics.length}
                    flag="product"
                    error={this.state.errors["pics"]}
                    label=" Upload Images"
                    name="pics"
                    status={this.state.photoStatus}
                    onchange={this.fileUpload}
                    text={this.state.data["pics"]}
                  />
                </div>

                <ul className="delete-img-container d-flex">
                  {this.state.data.pics.map((e, i) => (
                    <li key={i}>
                      <div className="delete-img-box">
                        <img
                          width="180px"
                          src={e ? process.env.REACT_APP_S3URL + e.small : ""}
                          alt="img"
                        />

                        <div
                          className="delete-img"
                          onClick={(ele) => this.remoVeImg(ele, e)}
                        >
                          <img src={delete1} alt="" />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>

                <div className="form-group custom-upload">
                  <VideoInput
                    totalUploaded={this.state.data.pics.length}
                    flag="product"
                    error={false}
                    label=" Upload Video"
                    name="video"
                    status={this.state.videoUploading}
                    onchange={this.fileUploadVideo}
                    text={"video"}
                  />
                  <label class="error" style={{ color: "red" }}>
                    Video file cannot be more than{" "}
                    {process.env.REACT_APP_VIDEOUPLOADMAX}MB
                  </label>
                </div>
                <ul className="delete-img-container d-flex">
                  {this.state.data &&
                    this.state.data.video &&
                    this.state.data.video.map((e, i) => (
                      <li key={e}>
                        <div className="delete-img-box">
                          <video width="100%" controls>
                            <source
                              src={process.env.REACT_APP_S3URL + e}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                          {/* <img
                          width="180px"
                          src={e ? process.env.REACT_APP_S3URL + e.small : ""}
                          alt="img"
                        /> */}

                          <div
                            className="delete-img"
                            onClick={(ele) => this.remoVeVideo(ele, e)}
                          >
                            <img src={delete1} alt="" />
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>

                <div className="form-group">
                  <SubmitBtn
                    label="Submit"
                    loading={this.state.loading}
                    btnClass="btn btn-lg btn-default"
                  />
                </div>
              </form>
            </div>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  product: getProduct(state).product,
  category: getProduct(state).categories,
  sellerProfile: getSeller(state).profile,
  productPhoto: getProduct(state).productImage,
  details: getProduct(state).detail,
  detailLoading: getProduct(state).detailLoading,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllProduct: (params) => dispatch(loadAllProduct(params)),
  loadCategory: (params) => dispatch(loadCategory(params)),
  loadSellerProfile: (params) => dispatch(loadSellerProfile(params)),
  addProduct: (param, callback) => dispatch(addProduct(param, callback)),
  updateProduct: (id, data, editCallback) =>
    dispatch(updateProduct(id, data, editCallback)),
  uploadProductPictures: (data, callback) =>
    dispatch(uploadProductPictures(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
