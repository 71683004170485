import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class OperationGrid extends Component {
  tConv24 = (time24) => {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h;
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  };
  render() {
    const { schedules } = this.props;
    return (
      <ul className="operation-list">
        <li className="operation-item d-flex align-items-center justify-content-between">
          <div className="left d-flex align-items-center">
            <div className="day">Mon</div>
            <div className="timing d-flex flex-column flex-md-row align-items-center">
              {schedules && (!schedules.mon || schedules.mon.length === 0) ? (
                <div className="timing">
                  <div className="close-day">
                    {schedules && schedules.tbd === true ? "TBD" : "Closed"}
                  </div>
                </div>
              ) : (
                schedules &&
                schedules.mon.map((e, i) => (
                  <>
                    {" "}
                    <div className="timing-panel">
                      {this.tConv24(
                        e.start_time
                          .toString()
                          .replace(/(..)/g, "$1:")
                          .slice(0, -1)
                      )}{" "}
                      -{" "}
                      {this.tConv24(
                        e.end_time
                          .toString()
                          .replace(/(..)/g, "$1:")
                          .slice(0, -1)
                      )}
                    </div>{" "}
                    {schedules.mon.length > i + 1 ? (
                      <div className="small">&#38;</div>
                    ) : (
                      ""
                    )}
                  </>
                ))
              )}
            </div>
          </div>
          <div className="operation-item-right">
            <Link
              to={{ pathname: "/edit-hours-of-operation", state: schedules }}
              className="btn btn-lg btn-default"
            >
              Edit
            </Link>
          </div>
        </li>
        <li className="operation-item d-flex align-items-center justify-content-between">
          <div className="left d-flex align-items-center">
            <div className="day">Tue</div>
            <div className="timing d-flex flex-column flex-md-row align-items-center">
              {schedules && (!schedules.tue || schedules.tue.length === 0) ? (
                <div className="timing">
                  <div className="close-day">
                    {schedules && schedules.tbd === true ? "TBD" : "Closed"}
                  </div>
                </div>
              ) : (
                schedules &&
                schedules.tue.map((e, i) => (
                  <>
                    {" "}
                    <div className="timing-panel">
                      {this.tConv24(
                        e.start_time
                          .toString()
                          .replace(/(..)/g, "$1:")
                          .slice(0, -1)
                      )}{" "}
                      -{" "}
                      {this.tConv24(
                        e.end_time
                          .toString()
                          .replace(/(..)/g, "$1:")
                          .slice(0, -1)
                      )}
                    </div>{" "}
                    {schedules.tue.length > i + 1 ? (
                      <div className="small">&#38;</div>
                    ) : (
                      ""
                    )}
                  </>
                ))
              )}
            </div>
          </div>
        </li>
        <li className="operation-item d-flex align-items-center justify-content-between">
          <div className="left d-flex align-items-center">
            <div className="day">Wed</div>
            <div className="timing d-flex flex-column flex-md-row align-items-center">
              {schedules && (!schedules.wed || schedules.wed.length === 0) ? (
                <div className="timing">
                  <div className="close-day">
                    {schedules && schedules.tbd === true ? "TBD" : "Closed"}
                  </div>
                </div>
              ) : (
                schedules &&
                schedules.wed.map((e, i) => (
                  <>
                    {" "}
                    <div className="timing-panel">
                      {this.tConv24(
                        e.start_time
                          .toString()
                          .replace(/(..)/g, "$1:")
                          .slice(0, -1)
                      )}{" "}
                      -{" "}
                      {this.tConv24(
                        e.end_time
                          .toString()
                          .replace(/(..)/g, "$1:")
                          .slice(0, -1)
                      )}
                    </div>{" "}
                    {schedules.wed.length > i + 1 ? (
                      <div className="small">&#38;</div>
                    ) : (
                      ""
                    )}
                  </>
                ))
              )}
            </div>
          </div>
        </li>
        <li className="operation-item d-flex align-items-center justify-content-between">
          <div className="left d-flex align-items-center">
            <div className="day">Thu</div>
            <div className="timing d-flex flex-column flex-md-row align-items-center">
              {schedules && (!schedules.thu || schedules.thu.length === 0) ? (
                <div className="timing">
                  <div className="close-day">
                    {schedules && schedules.tbd === true ? "TBD" : "Closed"}
                  </div>
                </div>
              ) : (
                schedules &&
                schedules.thu.map((e, i) => (
                  <>
                    {" "}
                    <div className="timing-panel">
                      {this.tConv24(
                        e.start_time
                          .toString()
                          .replace(/(..)/g, "$1:")
                          .slice(0, -1)
                      )}{" "}
                      -{" "}
                      {this.tConv24(
                        e.end_time
                          .toString()
                          .replace(/(..)/g, "$1:")
                          .slice(0, -1)
                      )}
                    </div>{" "}
                    {schedules.thu.length > i + 1 ? (
                      <div className="small">&#38;</div>
                    ) : (
                      ""
                    )}
                  </>
                ))
              )}
            </div>
          </div>
        </li>
        <li className="operation-item d-flex align-items-center justify-content-between">
          <div className="left d-flex align-items-center">
            <div className="day">Fri</div>
            <div className="timing d-flex flex-column flex-md-row align-items-center">
              {schedules && (!schedules.fri || schedules.fri.length === 0) ? (
                <div className="timing">
                  <div className="close-day">
                    {schedules && schedules.tbd === true ? "TBD" : "Closed"}
                  </div>
                </div>
              ) : (
                schedules &&
                schedules.fri.map((e, i) => (
                  <>
                    {" "}
                    <div className="timing-panel">
                      {this.tConv24(
                        e.start_time
                          .toString()
                          .replace(/(..)/g, "$1:")
                          .slice(0, -1)
                      )}{" "}
                      -{" "}
                      {this.tConv24(
                        e.end_time
                          .toString()
                          .replace(/(..)/g, "$1:")
                          .slice(0, -1)
                      )}
                    </div>{" "}
                    {schedules.fri.length > i + 1 ? (
                      <div className="small">&#38;</div>
                    ) : (
                      ""
                    )}
                  </>
                ))
              )}
            </div>
          </div>
        </li>
        <li className="operation-item d-flex align-items-center justify-content-between">
          <div className="left d-flex align-items-center">
            <div className="day">Sat</div>
            <div className="timing d-flex flex-column flex-md-row align-items-center">
              {schedules && (!schedules.sat || schedules.sat.length === 0) ? (
                <div className="timing">
                  <div className="close-day">
                    {schedules && schedules.tbd === true ? "TBD" : "Closed"}
                  </div>
                </div>
              ) : (
                schedules &&
                schedules.sat.map((e, i) => (
                  <>
                    {" "}
                    <div className="timing-panel">
                      {this.tConv24(
                        e.start_time
                          .toString()
                          .replace(/(..)/g, "$1:")
                          .slice(0, -1)
                      )}{" "}
                      -{" "}
                      {this.tConv24(
                        e.end_time
                          .toString()
                          .replace(/(..)/g, "$1:")
                          .slice(0, -1)
                      )}
                    </div>{" "}
                    {schedules.sat.length > i + 1 ? (
                      <div className="small">&#38;</div>
                    ) : (
                      ""
                    )}
                  </>
                ))
              )}
            </div>
          </div>
        </li>
        <li className="operation-item">
          <div className="day">Sun</div>
          <div className="timing d-flex flex-column flex-md-row align-items-center">
            {schedules && (!schedules.sun || schedules.sun.length === 0) ? (
              <div className="timing">
                <div className="close-day">
                  {schedules && schedules.tbd === true ? "TBD" : "Closed"}
                </div>
              </div>
            ) : (
              schedules &&
              schedules.sun.map((e, i) => (
                <>
                  {" "}
                  <div className="timing-panel">
                    {this.tConv24(
                      e.start_time
                        .toString()
                        .replace(/(..)/g, "$1:")
                        .slice(0, -1)
                    )}{" "}
                    -{" "}
                    {this.tConv24(
                      e.end_time.toString().replace(/(..)/g, "$1:").slice(0, -1)
                    )}
                  </div>{" "}
                  {schedules.sun.length > i + 1 ? (
                    <div className="small">&#38;</div>
                  ) : (
                    ""
                  )}
                </>
              ))
            )}
          </div>
        </li>
      </ul>
    );
  }
}
