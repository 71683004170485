import React, { createRef } from "react";
import CustomerSidebar from "../../common/customer/customerSidebar";
import Header from "../../productV2/header";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Footer from "../../common/footer";

import {
  loadCustomerProfile,
  getCustomer,
  customerUpdate,
} from "../../../store/customer";

import {
  firstNameRequired,
  lastNameRequired,
  emailRequired,
} from "../../common/misc";
import SubmitBtn from "../../common/form/submitBtn";

import { toast } from "react-toastify";
import Joi from "joi-browser";

import AlertSuccess from "../../common/alertSuccess";
import AlertError from "../../common/alertError";
import Form from "../../common/form/form";

import LoadingBar from "react-top-loading-bar";

class AccountDetails extends Form {
  ref = createRef(null);

  state = {
    data: {
      firstName: "",
      lastName: "",
      email: "",
      code: 0,
      mobile: "",
    },
    loader: false,
    label: "Save Changes",
    errors: {},
  };

  schema = {
    firstName: Joi.string()
      .required()
      .label("First Name")
      .error(() => {
        return { message: firstNameRequired };
      }),

    lastName: Joi.string()
      .required()
      .label("Last Name")
      .error(() => {
        return { message: lastNameRequired };
      }),

    email: Joi.string()
      .email()
      .max(50)
      .required()
      .label("Email")
      .error(() => {
        return { message: emailRequired };
      }),
    code: Joi.number().allow("").optional(),
    mobile: Joi.number().allow("").optional(),
  };

  componentDidMount = () => {
    this.props.loadCustomerProfile();
    this.ref.current.continuousStart();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.profileLoading &&
      this.props.profileLoading !== prevProps.profileLoading
    ) {
      const {
        first_name: firstName,
        last_name: lastName,
        mobile,
        email,
        code,
      } = this.props.customerProfile;

      const mobileNumber = code.includes("+")
        ? code + JSON.stringify(mobile)
        : "+" + code + JSON.stringify(mobile);

      const data = {
        firstName,
        lastName,
        mobile: mobileNumber,
        email,
      };

      this.setState({ data });
    }
    this.ref.current.complete();
  };

  doSubmit = () => {
    this.setState({
      loader: true,
      label: "",
      btnClass: "btn btn-default",
    });
    const { firstName, lastName, email } = this.state.data;
    const formData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
    };
    this.props.customerUpdate(formData, this.callback);
  };

  callback = (res) => {
    this.setState({
      loader: false,
      label: "Save Changes",
      btnClass: "btn btn-default",
    });
    if (res.status === 200) {
      toast(<AlertSuccess message={"Information Updated"} />);
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  render() {
    return (
      <>
        <LoadingBar color="#0b4474" ref={this.ref} />
        <Header />
        <>
          <main id="main">
            <div className="content-container">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="conetnt-box d-md-flex flex-md-wrap">
                      <CustomerSidebar />
                      <div className="main-content-box">
                        <div className="account-details-box">
                          <h3>Account Details</h3>
                          {!this.props.profileLoading && (
                            <>
                              <form onSubmit={this.handleSubmit}>
                                <div className="row">
                                  <div className="col-md-6">
                                    {this.renderInput(
                                      "firstName",
                                      "First Name"
                                    )}
                                  </div>
                                  <div className="col-md-6">
                                    {this.renderInput("lastName", "Last name")}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    {this.renderInput("email", "Email")}
                                  </div>
                                  <div className="col-md-6">
                                    {this.renderNumber(
                                      "mobile",
                                      "Mobile Number",
                                      "string",
                                      true
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="form-group sidebar-btn"
                                  style={{ marginTop: "30px" }}
                                >
                                  {" "}
                                  <SubmitBtn
                                    loading={this.state.loader}
                                    btnClass=" btn btn-default"
                                    label={this.state.label}
                                  />
                                </div>
                              </form>
                            </>
                          )}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </main>
        </>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customerProfile: getCustomer(state).profile,
  profileLoading: getCustomer(state).profileLoading,
});

const mapDispatchToProps = (dispatch) => ({
  customerUpdate: (data, callback) => dispatch(customerUpdate(data, callback)),
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountDetails)
);
