import Joi, { errors } from "joi-browser";
import React from "react";
import AlertError from "../common/alertError";
import loadingIcon from "../../include/images/loading-icon.svg";

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import { reviewOrder, zipValidation } from "../../store/seller";
import Form from "../common/form/form";
import {
  Address1Required,
  cityRequired,
  emailRequired,
  firstNameRequired,
  lastNameRequired,
  mobileInvalid,
  mobileRequired,
  stateRequired,
  zipRequired,
} from "../common/misc";
import Header from "../product/header";
import { createRef } from "react";
import { getCustomer, loadCustomerProfile } from "../../store/customer";
import { emptyCart, getCart, loadCart } from "../../store/cart";
import { GoogleApiWrapper } from "google-maps-react";
import { Modal } from "react-bootstrap";
import Copyright from "../common/copyright";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
const searchOptions = {
  types: ["address"],
  componentRestrictions: { country: "us" },
};

class Checkout extends Form {
  ref = createRef(null);
  stripe = window.Stripe(process.env.REACT_APP_STRIPEKEY);
  state = {
    deliveryAddress: false,
    cardElement: {},
    billing: false,
    shipping: false,
    popup: false,
    add: {
      address1: "",
      address2: "",
      zip: "",
      city: "",
      state: "",
      country: "",
      lattitute: "",
      longitute: "",
    },
    address: "",
    data: {
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      // address1: "",
      // address2: "",
      // zipcode: "",
      // city: "",
      // state: "",
    },
    errors: {},
    param: JSON.parse(localStorage.getItem("customerDddress")),
  };

  handleChange1 = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    this.setState({ address });
    geocodeByAddress(address)
      .then((results) => {
        const zipCode = results[0].address_components.find(
          (ra) => ra.types[0] === "postal_code"
        );
        var street1;
        if (
          results[0].address_components.find(
            (ra) => ra.types[0] === "street_number"
          ) &&
          results[0].address_components.find(
            (ra) => ra.types[0] === "street_number"
          ).long_name
        ) {
          street1 = `${
            results[0].address_components.find(
              (ra) => ra.types[0] === "street_number"
            ).long_name
          } ${
            results[0].address_components.find((ra) => ra.types[0] === "route")
              .long_name
          }`;
        } else {
          street1 = results[0].address_components.find(
            (ra) => ra.types[0] === "route"
          ).long_name;
        }
        const street2 = results[0].address_components.find(
          (ra) => ra.types[0] === "administrative_area_level_2"
        );
        const city = results[0].address_components.find(
          (ra) => ra.types[0] === "locality"
        );
        const state = results[0].address_components.find(
          (ra) => ra.types[0] === "administrative_area_level_1"
        );
        const country = results[0].address_components.find(
          (ra) => ra.types[0] === "country"
        );
        const lattitude = results[0].geometry.location.lat();
        const longitude = results[0].geometry.location.lng();
        this.setState({
          add: {
            zip: zipCode && zipCode.long_name,
            address1: street1,
            address2: street2 && street2.long_name,
            city: city && city.long_name,
            state: state && state.short_name,
            country: country && country.short_name,
            lattitude,
            longitude,
          },
        });
        localStorage.setItem(
          "customer_address",
          JSON.stringify(this.state.add)
        );
      })
      .catch((error) => console.error("Error", error));
  };

  doSubmit = async () => {
    const { errors } = this.state;
    if (
      this.state.add.address1 &&
      this.state.add.city &&
      this.state.add.state &&
      this.state.add.zip
    ) {
      localStorage.setItem(
        "checkout_address",
        JSON.stringify(this.state.address)
      );
      return this.props.history.push({
        pathname: "/review",
        state: { seller_no: this.props.history.location.state.seller_number },
      });
    } else if (
      !this.state.add.address1 ||
      !this.state.add.city ||
      !this.state.add.state ||
      !this.state.add.zip
    ) {
      if (this.state.address) {
        errors.address = "Billing address is invalid";
        return this.setState({ errors });
      } else if (!this.state.address) {
        errors.address = "Billing address is required";
        return this.setState({ errors });
      }
    }
  };
  addressCallback = (res) => {
    this.setState({
      loader: false,
    });
    if (res.status === 200) {
      this.props.history.push({
        pathname: "/review",
        state: { seller_no: this.props.history.location.state.seller_number },
      });
      // }
    } else {
      if (!res.data.validAddress) {
        this.setState({ popup: true });
      } else {
        toast(
          <AlertError message="Invalid address. Please enter your correct address." />
        );
      }
    }
  };
  delivery_addressCallback = (res) => {
    this.setState({
      loader: false,
    });
    if (res.status === 200) {
      this.props.history.push({
        pathname: "/review",
        state: { seller_no: this.props.history.location.state.seller_number },
      });
    } else {
      toast(
        <AlertError message="Invalid delivery address. Please enter your correct address." />
      );
    }
  };

  stripePaymentMethodHandler = async (result) => {
    if (result.error) {
      toast(<AlertError message={"Stripe error"} />);
    } else {
      localStorage.setItem(
        "paymentMethod",
        JSON.stringify(result.paymentMethod)
      );
      this.props.history.push({
        pathname: "/review",
        //state: { cardElement: this.state.cardElement },
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.data !== prevState.data) {
      localStorage.setItem(
        "customer_information",
        JSON.stringify(this.state.data)
      );
    }
    if (this.state.add !== prevState.add) {
      localStorage.setItem("customer_address", JSON.stringify(this.state.add));
    }
  };

  doSubmitZip = (e) => {
    e.preventDefault();
    if (!this.state.zipLoader) {
      this.ref.current.continuousStart();
      this.setState({
        zipLoader: true,
      });
      const params = {
        zip: this.state.data.zipcode,
      };
      this.props.zipValidation(params, this.zipCallback);
    }
  };

  zipCallback = (res) => {
    this.ref.current.complete();
    this.setState({
      zipLoader: false,
    });
    if (res.status === 200) {
      const { data, errors } = this.state;
      data.city = res.data.data.city;
      data.state = res.data.data.state;
      errors.city = "";
      errors.state = "";
      this.setState({ data, errors });
      localStorage.setItem("customer_information", JSON.stringify(data));
    } else {
      toast(<AlertError message={res.data.error.Description} />);
    }
  };

  doSubmitZip_delivery = (e, zipcode = null) => {
    if (e) e.preventDefault();
    if (!this.state.zipLoader) {
      this.ref.current.continuousStart();
      this.setState({
        zipLoader: true,
      });
      const params = {
        zip: zipcode ? zipcode : this.state.data.delivery_zipcode,
      };
      this.props.zipValidation(params, this.zipCallback_delivery);
    }
  };

  zipCallback_delivery = (res) => {
    this.ref.current.complete();
    this.setState({
      zipLoader: false,
    });
    if (res.status === 200) {
      const { data, errors } = this.state;
      data.delivery_city = res.data.data.city;
      data.delivery_state = res.data.data.state;
      errors.delivery_city = "";
      errors.delivery_state = "";
      this.setState({ data, errors });
      localStorage.setItem("customer_information", JSON.stringify(data));
    } else {
      toast(<AlertError message={res.data.error.Description} />);
    }
  };

  chengeDelivery = (e) => {
    return false;
  };

  schema = {
    first_name: Joi.string()
      .required()
      .error(() => {
        return { message: firstNameRequired };
      }),

    last_name: Joi.string()
      .required()
      .error(() => {
        return { message: lastNameRequired };
      }),

    email: Joi.string()
      .email()
      .max(50)
      .required()
      .error(() => {
        return { message: emailRequired };
      }),

    mobile: Joi.number()
      .required()
      .min(1000000000)
      .max(999999999999999)
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.required":
              err.message = "Mobile is required";
              break;

            case "number.base":
              err.message = mobileRequired;
              break;

            case "number.max":
              err.message = mobileInvalid;
              break;

            case "number.min":
              err.message = mobileInvalid;
              break;

            default:
          }
        });
        return errors;
      }),

    // address1: Joi.string()
    //   .required()
    //   .error(() => {
    //     return { message: Address1Required };
    //   }),

    // address2: Joi.string().allow("").optional(),

    // city: Joi.string()
    //   .required()
    //   .label("City")
    //   .error(() => {
    //     return { message: cityRequired };
    //   }),

    // state: Joi.string()
    //   .required()
    //   .label("State")
    //   .error(() => {
    //     return { message: stateRequired };
    //   }),

    // zipcode: Joi.number()
    //   .required()
    //   .label("Zip Code")
    //   .error(() => {
    //     return { message: zipRequired };
    //   }),
  };

  chengeShipping = (e) => {
    return false;
  };

  chengeBilling = (e) => {
    const { email, first_name, last_name, mobile, shipping_address } =
      this.props.getCustomer;
    const billing_address = this.props.getCustomer.billing_address
      ? this.props.getCustomer.billing_address
      : {};
    const { data } = this.state;
    data.first_name = first_name;
    data.last_name = last_name;
    data.email = email;
    data.mobile = mobile.toString();
    // data.address1 = billing_address.address_line1;
    // data.address2 = billing_address.address_line2;
    // data.zipcode = billing_address.zipcode;
    // data.city = billing_address.city;
    // data.state = billing_address.state;
    this.setState({ billing: !this.state.billing, data });
    localStorage.setItem("customer_information", JSON.stringify(data));
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.props.loadCart(localStorage.getItem("user_device_id"), {
      address: this.state.param && this.state.param.address,
      zipCode: this.state.param && this.state.param.postal_code.long_name,
      lat: this.state.param && this.state.param.lat,
      lon: this.state.param && this.state.param.lng,
    });
    if (localStorage.getItem("x-auth-token-customer"))
      this.props.loadCustomerProfile();
    if (!this.props.history.location.state)
      return this.props.history.push("/cart");
    const data = JSON.parse(localStorage.getItem("customerDddress"));
    const customer_information = localStorage.getItem("customer_information")
      ? JSON.parse(localStorage.getItem("customer_information"))
      : {};
    const customer_address = localStorage.getItem("customer_address")
      ? JSON.parse(localStorage.getItem("customer_address"))
      : {};
    const addArr = data.address.split(",");
    const { data: formData } = this.state;
    const { add } = this.state;
    formData.first_name = customer_information.first_name;
    formData.last_name = customer_information.last_name;
    formData.mobile = customer_information.mobile;
    // add.address1 = customer_address.address1;
    formData.email = customer_information.email;
    // add.address2 = customer_address.address2;
    // add.zip = customer_address.zip;
    // add.city = customer_address.city;
    // add.state = customer_address.state;
    // add.country = customer_address.country;
    this.setState({ data: formData, deliveryAddress: true });
    if (this.props.getCustomer.first_name) {
      //setTimeout(() => {
      const { email, first_name, last_name, mobile, _id } =
        this.props.getCustomer;
      const { data } = this.state;
      if (first_name) data.first_name = first_name;
      if (last_name) data.last_name = last_name;
      if (email) data.email = email;
      if (mobile) data.mobile = mobile.toString();
      if (_id) {
        this.chengeBilling();
      }

      this.setState({ data });
      localStorage.setItem("customer_information", JSON.stringify(data));
      //}, 500);
    }
  };

  handlePopup = (e) => {
    if (e) e.preventDefault();
    this.setState({ popup: !this.state.popup });
  };
  render() {
    console.log(
      "customerDddress",
      JSON.parse(localStorage.getItem("customerDddress"))
    );
    const customerDeladdress = JSON.parse(
      localStorage.getItem("customerDddress")
    );
    return (
      <>
        <LoadingBar color="#0b4474" ref={this.ref} />
        <Header cart={"checkout"} toggleContact={this.toggleContact} />
        <main id="main">
          <div className="content-container">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="cc-box d-lg-flex d-lg-wrap">
                    <div className="cc-left-content">
                      <h3>Customer Information</h3>
                      <div className="row">
                        <div className="col-lg-6">
                          {this.renderInput("first_name", "First name")}
                        </div>
                        <div className="col-lg-6">
                          {this.renderInput("last_name", "Last name")}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          {this.renderInput("email", "Email")}
                        </div>
                        <div className="col-lg-6">
                          {this.renderInput("mobile", "Mobile")}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          {/* {this.renderInput("address1", "Billing Address 1")} */}
                          <PlacesAutocomplete
                            value={this.state.address}
                            onChange={this.handleChange1}
                            onSelect={this.handleSelect}
                            searchOptions={searchOptions}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <div
                                  className="form-group not-empty"
                                  style={{
                                    marginBottom: this.state.errors.address
                                      ? "0"
                                      : "20px",
                                  }}
                                >
                                  <label>Billing Address</label>
                                  <input
                                    onChange={this.handleChange1}
                                    value={this.state.address}
                                    type="text"
                                    class="form-control"
                                    aria-label=""
                                    aria-describedby="basic-addon1"
                                    {...getInputProps({
                                      placeholder: "Enter your address",
                                      className: "form-control",
                                    })}
                                  />
                                </div>
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                          {this.state.errors && this.state.errors.address && (
                            <p
                              style={{
                                fontSize: "12px",
                                lineHeight: "16px",
                                fontWeight: "400",
                                letterSpacing: "0.4px",
                                color: "#ff0c3e",
                                marginBottom: "5px",
                              }}
                            >
                              {this.state.errors.address}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-lg-6">
                          {this.renderInput("zipcode", "Zip code")}
                        </div>
                        <div className="col-lg-6 d-lg-flex align-items-lg-center justify-content-lg-center">
                          <a href="" onClick={this.doSubmitZip}>
                            <strong>Fetch City & State</strong>
                          </a>
                        </div>
                      </div> */}
                      {/* <div className="row">
                        <div className="col-lg-6">
                          {this.renderInput("city", "City", "text", true)}
                        </div>
                        <div className="col-lg-6">
                          {this.renderInput("state", "State", "text", true)}
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="checkout-btn">
                            {this.state.loader ? (
                              <button className="btn btn-default btn-block btn-loading">
                                <img src={loadingIcon} alt="" />
                              </button>
                            ) : (
                              <button
                                onClick={this.handleSubmit}
                                className="btn btn-default btn-block"
                              >
                                Review your order
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {localStorage.getItem("customerDddress") && (
                      <div className="cc-right-content">
                        <div className="address-box">
                          <div
                            className="ab-left"
                            style={{ cursor: "pointer" }}
                            onClick={this.chengeShipping}
                          >
                            <h5>Shipping Address</h5>

                            <div className="address-text-box">
                              {" "}
                              <h4>
                                {this.props.getCustomer.first_name
                                  ? this.props.getCustomer.first_name + " "
                                  : " "}

                                {this.props.getCustomer.last_name
                                  ? this.props.getCustomer.last_name
                                  : ""}
                              </h4>
                              <p>
                                {customerDeladdress.address +
                                  ", " +
                                  customerDeladdress.postal_code.long_name}
                                {/* {this.props.getCustomer.shipping_address
                                  .address_line2
                                  ? ", " +
                                    this.props.getCustomer.shipping_address
                                      .address_line2
                                  : ""}
                                <br /> */}
                                {/* {this.props.getCustomer.shipping_address.city +
                                  ", " +
                                  this.props.getCustomer.shipping_address
                                    .state +
                                  " " +
                                  this.props.getCustomer.shipping_address
                                    .zipcode}{" "}
                                <br /> */}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* {(this.props.getCustomer.billing_address ||
                      this.props.getCustomer.shipping_address) && (
                      <div className="cc-right-content">
                        {this.props.getCustomer.shipping_address && (
                          <div className="address-box">
                            <div
                              className="ab-left"
                              style={{ cursor: "pointer" }}
                              onClick={this.chengeShipping}
                            >
                              <h5>Shipping Address</h5>

                              <div className="address-text-box">
                                {" "}
                                <h4>
                                  {this.props.getCustomer.first_name +
                                    " " +
                                    this.props.getCustomer.last_name}
                                </h4>
                                <p>
                                  {
                                    this.props.getCustomer.shipping_address
                                      .address_line1
                                  }
                                  {this.props.getCustomer.shipping_address
                                    .address_line2
                                    ? ", " +
                                      this.props.getCustomer.shipping_address
                                        .address_line2
                                    : ""}
                                  <br />
                                  {this.props.getCustomer.shipping_address
                                    .city +
                                    ", " +
                                    this.props.getCustomer.shipping_address
                                      .state +
                                    " " +
                                    this.props.getCustomer.shipping_address
                                      .zipcode}{" "}
                                  <br />
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
            <footer className="footer" id="footer">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="footer-content text-center">
                      <Copyright />
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </main>
        <Modal
          show={this.state.popup}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="modal-header">
            <button
              type="button"
              className="close mt-2"
              data-dismiss="modal"
            ></button>
          </div>
          <div className="modal-body">
            <div className="modal-alert-box">
              <h3 className="mb-6">Alert!</h3>
              <p>
                Modifying delivery address will clear your cart and redirect you
                to product listing page.
              </p>
            </div>
            <div className="modal-action-btn">
              <a
                href="javascript:void(0)"
                data-dismiss="modal"
                className="btn btn-default"
                style={{ minWidth: "185px", float: "right" }}
                onClick={(e) => {
                  this.props.emptyCart(localStorage.getItem("user_device_id"));
                  this.props.history.push("/");
                }}
              >
                Ok
              </a>
              <a
                href="#!"
                className="btn btn-cancel"
                data-dismiss="modal"
                aria-label="Close"
                style={{ minWidth: "185px", float: "right" }}
                onClick={this.handlePopup}
              >
                Cancel
              </a>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  zipValidation: (data, callback) => dispatch(zipValidation(data, callback)),
  loadCustomerProfile: () => dispatch(loadCustomerProfile()),
  addressValidation: (data, callback) => dispatch(reviewOrder(data, callback)),
  loadCart: (id, data) => dispatch(loadCart(id, data)),
  emptyCart: (id) => dispatch(emptyCart(id)),
});
const mapStateToProps = (state) => ({
  getCustomer: getCustomer(state).profile,
  getCart: getCart(state),
});
export default GoogleApiWrapper({
  apiKey: "AIzaSyDHZd1NTh7pJw6-zGe1Mf_cUBx2cIhz46Y",
})(withRouter(connect(mapStateToProps, mapDispatchToProps)(Checkout)));
