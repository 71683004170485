import React, { Component } from "react";

import Header from "./header";
import SimpleMap from "./map";
import GridSearch from "./gridSearch";
import image1 from "../../include/images/no-result-img.svg";
import Copyright from "../common/copyright";

export default class NoResult extends Component {
  state = {
    tab: "A",
  };
  changeTabD = () => {
    this.setState({
      tab: "D",
    });
  };
  changeTabA = () => {
    this.setState({
      tab: "A",
    });
  };
  render() {
    return (
      <div className="has-map">
        <Header />
        <main id="main">
          <GridSearch
            tab={this.state.tab}
            changeTabA={this.changeTabA}
            changeTabD={this.changeTabD}
          />
          <div className="no-result-container">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="no-result-content">
                    <div className="no-result-box d-inline-block">
                      <div className="no-result-img">
                        <img src={image1} alt="" />
                      </div>
                      <div className="no-result-text">
                        <h2>No result found</h2>
                        <p>
                          Dorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor ut labore et dolore magna
                          aliqua.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer className="footer sticky" id="footer">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="footer-content text-center">
                    <Copyright />
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </main>
        <div className="map">
          <SimpleMap />
        </div>
      </div>
    );
  }
}
