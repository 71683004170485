import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "cities",
  initialState: {
    city: [],
    state: [],
    loading: true,
    lastFetch: null,
  },
  reducers: {
    cityRequested: (product, action) => {
      product.loading = true;
    },

    cityReceived: (product, action) => {
      product.city = action.payload.data;
      product.loading = false;
      product.lastFetch = Date.now();
    },

    cityRequestFailed: (product, action) => {
      product.loading = false;
    },

    stateRequested: (product, action) => {
      product.loading = true;
    },

    stateReceived: (product, action) => {
      product.state = action.payload.data;
      product.loading = false;
      product.lastFetch = Date.now();
    },

    stateRequestFailed: (product, action) => {
      product.loading = false;
    },
  },
});
export const {
  cityReceived,
  cityRequestFailed,
  cityRequested,
  stateRequested,
  stateReceived,
  stateRequestFailed,
} = slice.actions;

export default slice.reducer;

export const loadCity = (state_code) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: "city/city-list/" + state_code,
      onStart: cityRequested.type,
      onSuccess: cityReceived.type,
      onError: cityRequestFailed.type,
    })
  );
};

export const loadState = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: "city/state-list/230",
      onStart: stateRequested.type,
      onSuccess: stateReceived.type,
      onError: stateRequestFailed.type,
    })
  );
};

export const getCities = createSelector(
  (state) => state.entities.cities,
  (cities) => cities
);
