import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "product",
  initialState: {
    productCategory: [],
    category: [],
    categories: [],
    products: {
      data: [],
      pagination: {},
    },
    detail: {},
    product: [],
    totalItemsAllowed: null,
    sellers: [],
    sellerDetail: {},
    pagination: {},
    update: {},
    add: {},

    productImage: {},
    productImageLoading: false,
    productImageLastFetch: null,
    loading: true,
    lastFetch: null,
    productsLoading: true,
    detailLoading: true,
    productsLastFetch: null,
    categoryLoading: false,
    categoryLastFetch: null,

    bulkUpload: {},
    bulkUploadLoading: false,
    bulkUploadLastFetch: null,
  },
  reducers: {
    productsRequested: (product, action) => {
      product.productsLoading = true;
    },

    productsReceived: (product, action) => {
      product.products = action.payload;
      product.productsLoading = false;
      product.productsLastFetch = Date.now();
    },

    productsRequestFailed: (product, action) => {
      product.products = {
        data: [],
        pagination: {},
      };
      product.productsLoading = false;
    },

    categoriesRequested: (product, action) => {
      product.categoryLoading = true;
    },

    categoriesReceived: (product, action) => {
      product.categories = action.payload.data;
      product.categoryLoading = false;
      product.categoryLastFetch = Date.now();
    },

    categoriesRequestFailed: (product, action) => {
      product.categoryLoading = false;
    },

    categoryRequested: (product, action) => {
      product.categoryLoading = true;
    },

    categoryReceived: (product, action) => {
      product.category = action.payload.data;
      product.categoryLoading = false;
      product.categoryLastFetch = Date.now();
    },

    categoryRequestFailed: (product, action) => {
      product.categoryLoading = false;
    },

    productRequested: (product, action) => {
      product.loading = true;
    },

    productReceived: (product, action) => {
      product.product = action.payload.data;
      product.pagination = action.payload.pagination;
      product.totalItemsAllowed = action.payload.totalItemsAllowed;
      product.sellers = action.payload.sellers;
      product.sellerDetail = action.payload.seller_details;
      product.productCategory = action.payload.categories;
      product.loading = false;
      product.lastFetch = Date.now();
    },

    productRequestFailed: (product, action) => {
      product.loading = false;
    },
    productdetailRequested: (product, action) => {
      product.detailLoading = true;
    },

    productdetailReceived: (product, action) => {
      product.detail = action.payload;
      product.detailLoading = false;
      product.lastFetch = Date.now();
    },

    productdetailRequestFailed: (product, action) => {
      product.detailLoading = false;
    },
    updatedProduct: (product, action) => {
      product.update = action.payload;
      product.loading = false;
      product.lastFetch = Date.now();
    },

    updatedProductRequestFailed: (product, action) => {
      product.loading = false;
    },
    productAdded: (product, action) => {
      product.add = action.payload;
      product.loading = false;
      product.lastFetch = Date.now();
    },

    productAddRequestFailed: (product, action) => {
      product.loading = false;
    },
    pictureRequested: (product, action) => {
      product.productImageLoading = true;
    },
    pictureReceived: (product, action) => {
      product.productImage = action.payload;
      product.productImageLoading = false;
      product.productImageLastFetch = Date.now();
    },

    pictureRequestFailed: (product, action) => {
      product.productImageLoading = false;
    },
    bulkUploadRequested: (product, action) => {
      product.bulkUploadLoading = true;
    },
    bulkUploadReceived: (product, action) => {
      product.bulkUpload = action.payload;
      product.bulkUploadLoading = false;
      product.bulkUploadLastFetch = Date.now();
    },

    bulkUploadRequestFailed: (product, action) => {
      product.bulkUploadLoading = false;
    },
  },
});

export const {
  pictureRequested,
  pictureReceived,
  pictureRequestFailed,
  productsRequested,
  productsReceived,
  productsRequestFailed,
  categoryRequested,
  categoryReceived,
  categoryRequestFailed,
  categoriesRequested,
  categoriesReceived,
  categoriesRequestFailed,
  productRequested,
  productReceived,
  productRequestFailed,
  productdetailRequested,
  productdetailReceived,
  productdetailRequestFailed,
  updatedProduct,
  updatedProductRequestFailed,
  productAdded,
  productAddRequestFailed,
  bulkUploadReceived,
  bulkUploadRequested,
  bulkUploadRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "product/";

export const loadProducts = (data) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      method: "POST",
      url: url + "list",
      data,
      onStart: productsRequested.type,
      onSuccess: productsReceived.type,
      onError: productsRequestFailed.type,
    })
  );
};

export const loadCategories = (url, params) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url ? url : "category/allWithProducts",
      params,
      onStart: categoriesRequested.type,
      onSuccess: categoriesReceived.type,
      onError: categoriesRequestFailed.type,
    })
  );
};

export const loadCategory = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: "category",
      onStart: categoryRequested.type,
      onSuccess: categoryReceived.type,
      onError: categoryRequestFailed.type,
    })
  );
};

export const loadAllProducts = (data) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "product/search",
      method: "POST",
      data,
      onStart: productRequested.type,
      onSuccess: productReceived.type,
      onError: productRequestFailed.type,
    })
  );
};

export const loadAllProduct = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: productRequested.type,
      onSuccess: productReceived.type,
      onError: productRequestFailed.type,
    })
  );
};
export const loadProductDetail = (id, params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      params,
      onStart: productdetailRequested.type,
      onSuccess: productdetailReceived.type,
      onError: productdetailRequestFailed.type,
    })
  );
};

export const updateProduct = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: "PUT",
      data,
      callback,
      onSuccess: updatedProduct.type,
      onError: updatedProductRequestFailed.type,
    })
  );
};
export const addProduct = (param, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url,
      method: "post",
      data: param,
      onSuccess: productAdded.type,
      onError: productAddRequestFailed.type,
    })
  );
};

export const uploadProductPictures =
  (data, callback) => (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        method: "POST",
        url: url + "uploadPicture",
        data,
        callback,
        onStart: pictureRequested.type,
        onSuccess: pictureReceived.type,
        onError: pictureRequestFailed.type,
      })
    );
  };

export const bulkUploadProducts = (data, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      method: "POST",
      url: url + "bulkUpload",
      data,
      callback,
      onStart: bulkUploadRequested.type,
      onSuccess: bulkUploadReceived.type,
      onError: bulkUploadRequestFailed.type,
    })
  );
};

export const deletedProduct = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: "delete",
      callback,
    })
  );
};

export const getProduct = createSelector(
  (state) => state.entities.product,
  (product) => product
);
