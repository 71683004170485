import React, { Component } from "react";
import Sidebar from "../common/sideBar";
import { Link } from "react-router-dom";
import SubmitBtn from "../common/form/submitBtn";

import checkImg from "../../include/images/tick-bubble.svg";

class Verified extends Component {
  componentWillUnmount = () => {
    document.body.classList.remove("pt-0");
  };

  componentDidMount = () => {
    document.body.classList.add("pt-0");
  };
  render() {
    return (
      <div className="member-container">
        <Sidebar />
        <div className="member-right">
          <div className="signin-form-container">
            <div className="signin-form">
              <div className="mr-box text-center">
                <div className="mr-icon">
                  <img src={checkImg} alt="" />
                </div>
                <h2>Thank you for signing up</h2>
                <p>
                  You can now login and manage your products. Please make sure
                  to complete your profile.
                </p>
                <div className="form-button">
                  <Link to="/product-listing">
                    <SubmitBtn
                      label="Go to Dashboard"
                      btnClass="btn btn-default"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Verified;
