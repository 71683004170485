import React from "react";
import Form from "../form/form";
import SubmitBtn from "../form/submitBtn";
import SelectOptions from "../selectOptions";

import { toast } from "react-toastify";
import AlertError from "../alertError";
import Joi from "joi-browser";

import { bulkUploadProducts, getProduct } from "../../../store/product";
import { connect } from "react-redux";

class UploadModal2 extends Form {
  state = {
    loading: false,
    label: "Next",
    data: {
      product_name: "",
      description: "",
      category: "",
      quantity: "",
      price: "",
      product_id: "",
    },
    errors: {},
  };

  schema = {
    product_id: Joi.object()
      .required()
      .error(() => {
        return { message: "Product Id field is required" };
      }),
    product_name: Joi.object()
      .required()
      .error(() => {
        return { message: "Product name field is required" };
      }),
    category: Joi.object()
      .required()
      .error(() => {
        return { message: "Category field is required" };
      }),
    price: Joi.object()
      .required()
      .error(() => {
        return { message: "Price field is required" };
      }),
    quantity: Joi.object()
      .required()
      .error(() => {
        return { message: "Quantity field is required" };
      }),
    description: Joi.object()
      .required()
      .error(() => {
        return { message: "Description field is required" };
      }),
  };

  componentDidMount = () => {
    if (
      this.props.formData[0] == "Product name" &&
      this.props.formData[1] == "Description" &&
      this.props.formData[2] == "Product ID" &&
      this.props.formData[3] == "Category" &&
      this.props.formData[4] == "Quantity" &&
      this.props.formData[5] == "Price(In $)"
    )
      this.setState({
        data: {
          product_name: {
            value: this.props.formData[0],
            label: this.props.formData[0],
          },
          description: {
            value: this.props.formData[1],
            label: this.props.formData[1],
          },
          product_id: {
            value: this.props.formData[2],
            label: this.props.formData[2],
          },
          category: {
            value: this.props.formData[3],
            label: this.props.formData[3],
          },
          quantity: {
            value: this.props.formData[4],
            label: this.props.formData[4],
          },
          price: {
            value: this.props.formData[5],
            label: this.props.formData[5],
          },
        },
      });
  };

  doSubmit = () => {
    this.setState({ loading: true, label: "" });
    let uploadData = new FormData();
    const csv = this.props.file;
    uploadData.append("csv", csv);
    uploadData.append("product_name", this.state.data.product_name.value);
    uploadData.append("description", this.state.data.description.value);
    uploadData.append("category", this.state.data.category.value);
    uploadData.append("quantity", this.state.data.quantity.value);
    uploadData.append("price", this.state.data.price.value);
    uploadData.append("product_id", this.state.data.product_id.value);

    this.props.bulkUploadProducts(uploadData, this.callback);
  };

  callback = (res) => {
    this.setState({ loading: false, label: "Next" });

    if (res && res.status === 200) {
      this.props.setStep(3);
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  render() {
    return (
      <>
        <div className="modal-body">
          <div className="mob-product-nav d-block d-md-none">
            <ul className="product-nav-list d-flex align-items-center">
              <li className="active">
                <a href="#!" className="pn-link">
                  Choose
                </a>
              </li>
              <li>
                <a href="#!" className="pn-link">
                  Map
                </a>
              </li>
              <li>
                <a href="#!" className="pn-link">
                  Setup
                </a>
              </li>
            </ul>
          </div>
          <div className="map-content-box d-md-flex flex-md-wrap align-items-center">
            <div className="mcb-left">
              <label>Product name</label>
            </div>
            <div className="mcb-right">
              <SelectOptions
                width={"auto"}
                value={this.state.data.product_name}
                onChange={(e) => {
                  const { data, errors } = this.state;
                  data.product_name = e;
                  if (!e)
                    errors.product_name = "Product Name field is required";
                  this.setState({ data, errors });
                }}
                options={this.props.formData.map((e) => ({
                  value: e,
                  label: e,
                }))}
              />
              {this.state.errors.product_name && (
                <label
                  className="error"
                  style={{
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "400",
                    letterSpacing: "0.4px",
                    color: "#ff0c3e",
                    marginBottom: "0",
                  }}
                >
                  {this.state.errors.product_name}
                </label>
              )}
            </div>
          </div>
          <div className="map-content-box d-md-flex flex-md-wrap align-items-center">
            <div className="mcb-left">
              <label>Description</label>
            </div>
            <div className="mcb-right">
              <SelectOptions
                width={"auto"}
                value={this.state.data.description}
                onChange={(e) => {
                  const { data, errors } = this.state;
                  data.description = e;
                  if (!e) errors.description = "Description field is required";
                  this.setState({ data, errors });
                }}
                options={this.props.formData.map((e) => ({
                  value: e,
                  label: e,
                }))}
              />
              {this.state.errors.description && (
                <label
                  className="error"
                  style={{
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "400",
                    letterSpacing: "0.4px",
                    color: "#ff0c3e",
                    marginBottom: "0",
                  }}
                >
                  {this.state.errors.description}
                </label>
              )}
            </div>
          </div>
          <div className="map-content-box d-md-flex flex-md-wrap align-items-center">
            <div className="mcb-left">
              <label>Quantity</label>
            </div>
            <div className="mcb-right">
              <SelectOptions
                width={"auto"}
                value={this.state.data.quantity}
                onChange={(e) => {
                  const { data, errors } = this.state;
                  data.quantity = e;
                  if (!e) errors.quantity = "Quantity field is required";
                  this.setState({ data, errors });
                }}
                options={this.props.formData.map((e) => ({
                  value: e,
                  label: e,
                }))}
              />
              {this.state.errors.quantity && (
                <label
                  className="error"
                  style={{
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "400",
                    letterSpacing: "0.4px",
                    color: "#ff0c3e",
                    marginBottom: "0",
                  }}
                >
                  {this.state.errors.quantity}
                </label>
              )}
            </div>
          </div>
          <div className="map-content-box d-md-flex flex-md-wrap align-items-center">
            <div className="mcb-left">
              <label>Category</label>
            </div>
            <div className="mcb-right">
              <SelectOptions
                width={"auto"}
                value={this.state.data.category}
                onChange={(e) => {
                  const { data, errors } = this.state;
                  data.category = e;
                  if (!e) errors.category = "Category field is required";
                  this.setState({ data, errors });
                }}
                options={this.props.formData.map((e) => ({
                  value: e,
                  label: e,
                }))}
              />
              {this.state.errors.category && (
                <label
                  className="error"
                  style={{
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "400",
                    letterSpacing: "0.4px",
                    color: "#ff0c3e",
                    marginBottom: "0",
                  }}
                >
                  {this.state.errors.category}
                </label>
              )}
            </div>
          </div>
          <div className="map-content-box d-md-flex flex-md-wrap align-items-center">
            <div className="mcb-left">
              <label>Product ID</label>
            </div>
            <div className="mcb-right">
              <SelectOptions
                width={"auto"}
                value={this.state.data.product_id}
                onChange={(e) => {
                  const { data, errors } = this.state;
                  data.product_id = e;
                  if (!e) errors.product_id = "Product ID field is required";
                  this.setState({ data, errors });
                }}
                options={this.props.formData.map((e) => ({
                  value: e,
                  label: e,
                }))}
              />
              {this.state.errors.product_id && (
                <label
                  className="error"
                  style={{
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "400",
                    letterSpacing: "0.4px",
                    color: "#ff0c3e",
                    marginBottom: "0",
                  }}
                >
                  {this.state.errors.product_id}
                </label>
              )}
            </div>
          </div>
          <div className="map-content-box d-md-flex flex-md-wrap align-items-center">
            <div className="mcb-left">
              <label>Price (In $)</label>
            </div>
            <div className="mcb-right">
              <SelectOptions
                width={"auto"}
                value={this.state.data.price}
                onChange={(e) => {
                  const { data, errors } = this.state;
                  data.price = e;
                  if (!e) errors.price = "Price field is required";
                  this.setState({ data, errors });
                }}
                options={this.props.formData.map((e) => ({
                  value: e,
                  label: e,
                }))}
              />
              {this.state.errors.price && (
                <label
                  className="error"
                  style={{
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "400",
                    letterSpacing: "0.4px",
                    color: "#ff0c3e",
                    marginBottom: "0",
                  }}
                >
                  {this.state.errors.price}
                </label>
              )}
            </div>
          </div>
        </div>

        <form className="modal-footer" onSubmit={this.handleSubmit}>
          <SubmitBtn
            loading={this.state.loading}
            btnClass="btn btn-default btn-xs"
            label={this.state.label}
          />
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  bulkUpload: getProduct(state).bulkUpload,
});
const mapDispatchToProps = (dispatch) => ({
  bulkUploadProducts: (data, callback) =>
    dispatch(bulkUploadProducts(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UploadModal2);
