import React, { Component } from "react";
import image1 from "../../include/images/no-result-img.svg";

class ProductEmpty extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="no-result-content">
              <div className="no-result-box d-inline-block">
                <div className="no-result-img">
                  <img src={image1} alt="" />
                </div>
                <div className="no-result-text">
                  <h2>No results found </h2>

                  <p>
                    There are no results matching your search criteria. Please
                    modify your search parameters and try again.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductEmpty;
